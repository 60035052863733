import React from 'react'

import toast from "react-hot-toast";
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import { Tooltip } from 'react-bootstrap';
import { Donut } from "react-dial-knob";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from 'react';
import { useEffect } from "react";

const MainDisplayDetails = (props) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);

    const [tokenData, setTokenData] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (props.showMainDisplayDetails === false) return;
        if (props.mainDisplayInc > 0) {
            getMainDisplayData();
        }
    }, [props.kioskInc])

    const columns = [
        {
            label: 'Name',
            name: 'name',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: 'MAC',
            name: 'macAddress',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: 'Type',
            name: 'type',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: 'Updated',
            name: 'updatedDate',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: 'At',
            name: 'updatedTime',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: 'Status',
            name: 'id',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    if (tokenData.length < 1) return;
                    let item = tokenData.find(item => item.id === value);
                    return (
                        <>
                            {item.restricted === false ? (<span className="badge badge-success ml-1 mb-2">Active</span>)
                            : <span className="badge badge-default ml-1 mb-2">pending</span> }
                        </>
                    );
                }
            }
        }
    ];

    const columns_1 = [
        {
            label: 'Branch',
            name: 'branchName',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: 'Name',
            name: 'name',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: 'MAC',
            name: 'macAddress',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: 'Type',
            name: 'type',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: 'Updated',
            name: 'updatedDate',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: 'At',
            name: 'updatedTime',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: 'Status',
            name: 'id',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    if (tokenData.length < 1) return;
                    let item = tokenData.find(item => item.id === value);
                    return (
                        <>
                            {item.restricted === false ? (<span className="badge badge-success ml-1 mb-2">Active</span>)
                            : <span className="badge badge-default ml-1 mb-2">pending</span> }
                        </>
                    );
                }
            }
        }
    ];

    const options = {
        tableBodyHeight: '50vh',
        filter: false,
        responsive: 'vertical',
        serverSide: false,
        count: count,
        download: false,
        viewColumns: false,
        jumpToPage: true,
        selectableRows: 'none',
        print: false,
        rowsPerPage: rowsPerPage,
        onChangeRowsPerPage: (n) => {
            setRowsPerPage(n)
        },
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        }
    };

    const getMainDisplayData = () => {
        setLoading(true);

        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/get-main-display-details/branch/${props.selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('getServedTicketsData success');
            let data = res.data.data;
            // loopChartData(data.countAndPercentage);

            // setchartData(data.countAndPercentage);
            setTokenData(data);

            setCount(data.length);
            setLoading(false);

        }).catch((error) => {
            console.log('getTicketIssuedDetails error');
            console.log(error);
            setLoading(false);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    return (
        <>
            {props.showMainDisplayDetails ?
                <div id="megamenu" className={`megamenu particles_js details-page open `}>
                    <span
                        onClick={() => {
                            props.setSticky(true)
                            props.setShowMainDisplayDetails(false);
                        }}
                        className="megamenu_toggle btn btn-danger"><i className="icon-close"></i></span>
                    <div className="container">
                        <div className="row clearfix">
                            <div className="col-12">
                                <div className="card">
                                    <div className="body">
                                        <div style={{ textAlign: 'center' }}>
                                            <h4>Main display details</h4>
                                            <small>{props.date}</small>
                                        </div>
                                        <br></br>
                                        <div className="row clearfix">
                                            <div className="col-12">
                                                <div className="muidatatablecontainer">
                                                    {props.selectedBranchLevel === 4 ? (
                                                        <MUIDataTable
                                                            title={<>
                                                                {count}{count === 1 ? ' record' : ' records'}.
                                                                {isLoading &&
                                                                    <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                                                        size={20} /></>}
                                                            </>
                                                            }
                                                            data={tokenData}
                                                            columns={columns}
                                                            options={options}
                                                        />
                                                    ) : (
                                                        <MUIDataTable
                                                            title={<>
                                                                {count}{count === 1 ? ' record' : ' records'}.
                                                                {isLoading &&
                                                                    <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                                                        size={20} /></>}
                                                            </>
                                                            }
                                                            data={tokenData}
                                                            columns={columns_1}
                                                            options={options}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div id="particles-js"></div>
                </div>
                : null}
        </>
    )
}

export default MainDisplayDetails