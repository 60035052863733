import React, { useState, useEffect } from 'react'
import axios from 'axios';
import toast from "react-hot-toast";

const CountersCategoryDetails = (props) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');

    const [counters, setCounters] = useState([]);

    useEffect(() => {
        getCountersOfCategory();
    }, [])


    const getCountersOfCategory = () => {
        axios({
            method: 'GET',
            url: `${baseUrl}/counter/counter-category/category/${props.selectedCategory}/get-counters-category`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            let data = res.data.data;
            console.log("DATA: ", data);
            setCounters(data);
        }).catch((error) => {
            console.log(error);
            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    const pinning = (id) => {
        axios({
            method: 'POST',
            url: `${baseUrl}/counter/counter-category/${id}/pin`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            getCountersOfCategory();
        }).catch((error) => {
            console.log(error);
            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    return (
        <>
            {props.showCountersCategoryDetails ? (
                <div className={`modal fade d-block show modal-container`} id="counter-modal">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Pinning to: <b>{props.selectedCategoryName}</b></h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        props.setSticky(true)
                                        props.setShowCountersCategoryDetails(false);
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="body">

                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-12 p-l-5 p-r-5'>
                                                <div className='modal-list-scroll'>
                                                    <ul className="list-group">
                                                        {counters.length > 0 ? (
                                                            <>
                                                                {counters.map((data, i) =>
                                                                    <li className={`list-group-item d-flex justify-content-between align-items-center`}
                                                                        style={{ cursor: 'pointer' }}
                                                                        key={i}
                                                                        onClick={() => {
                                                                            pinning(data.id);
                                                                        }}
                                                                    >
                                                                        <div>{data.counterName}</div>

                                                                        <div>
                                                                            {
                                                                                data.pin === true ? (
                                                                                    <span className="badge badge-warning"><i className="fa fa-thumb-tack" /></span>

                                                                                ) : null
                                                                            }

                                                                            {
                                                                                data.loginStatus === true ? (
                                                                                    <span className="badge badge-success">online</span>
                                                                                ) : (<span className="badge badge-default">offline</span>)
                                                                            }
                                                                        </div>
                                                                    </li>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="alert alert-custom alert-dismissible" role="alert">
                                                                    <i className="fa fa-info-circle"></i> Empty records
                                                                </div>
                                                            </>)}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="modal-footer">

                            </div>

                        </div>
                    </div>
                </div>
            ) : null}


        </>
    )
}

export default CountersCategoryDetails