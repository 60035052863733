import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PersentageDonutChartWithBottomLegend
    from '../../ChartsImpl/DetailsPageCharts/PersentageDonutChartWithBottomLegend';
import axios from 'axios';
import toast from "react-hot-toast";
import MUIDataTable from 'mui-datatables';
import CircularProgress from "@mui/material/CircularProgress";


let myTimeout = null;

const WaitingTicketsDetails = ({ showWaitingTicketsDetails, setShowWaitingTicketsDetails,
    selectedBranchId, waitingInc, messageInc1000, messageInc1001, messageInc1002, date, selectedBranchLevel, setSticky }) => {

    const [category, setCategory] = useState([])
    const [percentage, setPercentage] = useState([])
    const [update, setUpdate] = useState('1s')

    const [chartValue, setChartValue] = useState(0)

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');

    const [isLoading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const current = new Date();

    const time = current.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false
    });

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {props}
        </Tooltip>
    );

    const [waitingTickets, setWaitingTickets] = useState([])
    const [waitingPercentage, setWaitingPercentage] = useState([])

    useEffect(() => {
        setChartValue(chartValue + 1);
    }, [])

    useEffect(() => {
        if (showWaitingTicketsDetails === false) return;

        if (waitingInc !== '') {
            getWaitingTicketDetails();
        }

    }, [waitingInc])

    useEffect(() => {
        if (showWaitingTicketsDetails === false) return;

        if (messageInc1000 !== '' || messageInc1001 !== '' || messageInc1002 !== '') {
            getWaitingTicketDetails();
        }

    }, [messageInc1000, messageInc1001, messageInc1001])


    useEffect(() => {

        const interval = setInterval(() => {
            if (waitingTickets !== null && waitingTickets.length > 0) {
                for (let index = 0; index < waitingTickets.length; index++) {
                    const element = waitingTickets[index];
                    element.exceptionTimeFromSec = element.exceptionTimeFromSec + 5;

                    let hours = Math.floor(element.exceptionTimeFromSec / 3600);
                    let minutes = Math.floor((element.exceptionTimeFromSec - (hours * 3600)) / 60);
                    let seconds = element.exceptionTimeFromSec - (hours * 3600) - (minutes * 60);

                    if (hours < 10) {
                        hours = "0" + hours;
                    }
                    if (minutes < 10) {
                        minutes = "0" + minutes;
                    }
                    if (seconds < 10) {
                        seconds = "0" + seconds;
                    }

                    element.waitTime = `${hours}:${minutes}:${seconds}`;

                    console.info(element.curTimeFromSec)
                    console.info(element.exceptionTimeFromSec)
                    console.info(element.issuedTimeFromSec)
                    console.info(element.curTimeFromSec + element.exceptionTimeFromSec - element.issuedTimeFromSec)
                    let percent = ((element.exceptionTimeFromSec) * 100 / element.wt);
                    console.error(percent)
                    element.waitExceptions = parseInt(percent);
                }
                setWaitingTickets(waitingTickets);
                setUpdate(new Date().toString());
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [waitingTickets]);

    const getWaitingTicketDetails = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/get-waiting-ticket-details/inner-data/${selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let data = res.data.data;
            setWaitingTickets(data.waitingTicketDetails);
            setCount(data.waitingTicketDetails.length);
            setWaitingPercentage(data.waitingPercentages);
            loopWaitingPercentages(data.waitingPercentages);
            setChartValue(chartValue + 1)

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    const loopWaitingPercentages = (data) => {
        const categories = [];
        const percentages = [];

        data.forEach(element => {
            categories.push(element.category);
            percentages.push(element.percentage);
        })

        setCategory(categories);
        setPercentage(percentages);
    }

    const options = {
        tableBodyHeight: '50vh',
        filter: false,
        responsive: 'vertical',
        serverSide: false,
        count: count,
        download: false,
        viewColumns: false,
        jumpToPage: true,
        selectableRows: 'none',
        print: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        }
    };

    const columns = [
        {
            label: 'Token No',
            name: 'ticketNumber',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: "Category",
            name: 'category',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Language',
            name: 'language',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'Issued time',
            name: 'issuedTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Progress %',
            name: 'ticketId',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    if (waitingTickets.length < 1) return;
                    let data = waitingTickets.find(item => item.ticketId === value);
                    return (
                        <>
                            <div className="progress progress-xs">
                                <OverlayTrigger
                                    placement="top"
                                    // delay={{ show: 250, hide: 100000000 }}
                                    overlay={renderTooltip(`${data.waitExceptions}%`)}
                                >
                                    <div className="progress-bar "
                                        data-original-title="title here"
                                        style={{
                                            backgroundColor: `${data.waitExceptions > 99 ? '#9a303a' : '#115c9e'}`,
                                            width: `${data.waitExceptions}%`
                                        }}>

                                    </div>
                                </OverlayTrigger>
                            </div>
                        </>);
                }
            }
        },
        {
            label: 'Waiting time',
            name: 'waitTime',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'KPI',
            name: 'kpi',
            options: {
                filter: false,
                sort: true,
            }
        },
        // {
        //     label: 'Method',
        //     name: 'method',
        //     options: {
        //         filter: false,
        //         sort: true
        //     }
        // },
        {
            label: 'VIP',
            name: 'vip',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    return (<>
                        {value + '' === 'true' ? '⭐' : 'NO'}
                    </>);
                }
            },
        },
        {
            label: 'Status',
            name: 'ticketId',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    if (waitingTickets === undefined) return;
                    let item = waitingTickets.find(item => item.ticketId === value);
                    return (<>
                        {item.category !== null ? (
                            <>
                                {item.transferred === true ? (<span className="badge badge-warning ml-1 mb-2">Transfered</span>)
                                    : (<span className="badge badge-default ml-1 mb-2">pending</span>)}
                            </>
                        ) : (null)}
                    </>
                    );
                }
            },
        }
    ];

    const columns_1 = [
        {
            label: "Branch",
            name: 'branchName',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Token No',
            name: 'ticketNumber',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: "Category",
            name: 'category',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Language',
            name: 'language',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'Issued time',
            name: 'issuedTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Progress %',
            name: 'ticketId',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    if (waitingTickets.length < 1) return;
                    let data = waitingTickets.find(item => item.ticketId === value);
                    return (
                        <>
                            <div className="progress progress-xs">
                                <OverlayTrigger
                                    placement="top"
                                    // delay={{ show: 250, hide: 100000000 }}
                                    overlay={renderTooltip(`${data.waitExceptions}%`)}
                                >
                                    <div className="progress-bar "
                                        data-original-title="title here"
                                        style={{
                                            backgroundColor: `${data.waitExceptions > 99 ? '#9a303a' : '#115c9e'}`,
                                            width: `${data.waitExceptions}%`
                                        }}>

                                    </div>
                                </OverlayTrigger>
                            </div>
                        </>);
                }
            }
        },
        {
            label: 'Waiting time',
            name: 'waitTime',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'KPI',
            name: 'kpi',
            options: {
                filter: false,
                sort: true,
            }
        },
        // {
        //     label: 'Method',
        //     name: 'method',
        //     options: {
        //         filter: false,
        //         sort: true
        //     }
        // },
        {
            label: 'VIP',
            name: 'vip',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    return (<>
                        {value + '' === 'true' ? '⭐' : 'NO'}
                    </>);
                }
            },
        },
        {
            label: 'Status',
            name: 'ticketId',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    if (waitingTickets === undefined) return;
                    let item = waitingTickets.find(item => item.ticketId === value);
                    return (<>
                        {item.category !== null ? (
                            <>
                                {item.transferred === true ? (<span className="badge badge-warning ml-1 mb-2">Transfered</span>)
                                    : (<span className="badge badge-default ml-1 mb-2">pending</span>)}
                            </>
                        ) : (null)}
                    </>
                    );
                }
            },
        }
    ];

    return (
        <>
            {showWaitingTicketsDetails ?
                <div id="megamenu" className={`megamenu particles_js details-page open`}>
                    <span
                        onClick={() => {
                            setSticky(true)
                            setShowWaitingTicketsDetails(false);
                        }}
                        className="megamenu_toggle btn btn-danger"><i className="icon-close" /></span>
                    <div className="container">

                        <div className="row clearfix">
                            <div className="col-12">
                                <div className="card">
                                    <div className="body">
                                        <div style={{ textAlign: 'center' }}>
                                            <h4>Waiting Tickets Data</h4>
                                            <small>{date}</small>
                                        </div>
                                        <br></br>
                                        <div className="row clearfix">
                                            <div className="col-4">
                                                {
                                                    category.length !== 0 && percentage.length !== 0 && chartValue > 0 ?
                                                        <PersentageDonutChartWithBottomLegend
                                                            lable={category} percentage={percentage}
                                                            chartValue={chartValue} />
                                                        :
                                                        <PersentageDonutChartWithBottomLegend lable={[]} percentage={[]}
                                                            chartValue={0} />
                                                }

                                                <div className='mt-4'>
                                                    <div style={{ overflowY: 'scroll', height: '200px' }}>
                                                        <table className="table table-hover table-custom">
                                                            <tbody>
                                                                {waitingPercentage.map((data, i) =>
                                                                    <tr key={i}>
                                                                        <td>{data.category}</td>
                                                                        <td>{data.waitingTickets}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-8">
                                                <div className="muidatatablecontainer">
                                                    {selectedBranchLevel === 4 ? (
                                                        <MUIDataTable
                                                            title={<>
                                                                {count}{count === 1 ? ' record' : ' records'}.
                                                                {isLoading &&
                                                                    <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                                                        size={20} /></>}
                                                            </>
                                                            }
                                                            data={waitingTickets}
                                                            columns={columns}
                                                            options={options}
                                                        />
                                                    ) : (
                                                        <MUIDataTable
                                                            title={<>
                                                                {count}{count === 1 ? ' record' : ' records'}.
                                                                {isLoading &&
                                                                    <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                                                        size={20} /></>}
                                                            </>
                                                            }
                                                            data={waitingTickets}
                                                            columns={columns_1}
                                                            options={options}
                                                        />
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div id="particles-js"></div>
                </div>
                : null}
        </>
    );
}


export default WaitingTicketsDetails;