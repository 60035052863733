import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import NoOfTicketsChart from '../ChartsImpl/HomePageCharts/NoOfTicketsChart';
import { useNavigate, useLocation } from 'react-router-dom';
import { Donut } from "react-dial-knob";


const HomePage = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [branchInfo, setBranchInfo] = useState({});
    const [tokenCount, setTokenCount] = useState([]);
    const [xLabel, setXLabel] = useState([]);

    const [issuedTokenCount, setIssuedTokenCount] = useState('');

    const [showChart, setShowChart] = useState(false);

    const [regionData, setRegionData] = useState(null);
    const [branchData, setBranchData] = useState({});


    // call getHomePageData - function
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            getHomePageData();
            getAllRegions();
        }
    }, [HomePage])


    // getHomePageData - function
    const getHomePageData = () => {
        axios({
            method: 'GET',
            url: `${baseUrl}/branch/get/home-page-data/branch/${userDetails.bid}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let data = res.data.data;



            let tokenCountArray = []
            let XLabelArray = []
            data.chartData.forEach((element, i) => {
                // let tokenCountSubArray = 

                if (userDetails.branchLevel === 4) {
                    tokenCountArray.push([i + 1, element.tokenCount])
                    XLabelArray.push(element.date);
                } else if (userDetails.branchLevel === 1) {
                    tokenCountArray.push([i + 1, element.sumOfToken])
                    XLabelArray.push(element.date);
                }

            });
            setTokenCount(tokenCountArray)
            setXLabel(XLabelArray)

            setShowChart(true);

            setIssuedTokenCount(data.tokensIssued);
            setBranchInfo(data);



        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }



    // getAllRegions - function
    const getAllRegions = () => {

        if (userDetails.branchLevel !== 4) {

            axios.get(`${baseUrl}/branch/summary/parent-branch/${0}/customer/${userDetails.cid}`, {
                method: "GET",
                headers: {
                    Authorization: token
                }
            }).then((response) => {
                let branchData = response.data.data.branchData;
                setBranchData(branchData)

            }).catch((error) => {
                console.log(error);
            })

        } else {

            axios.get(`${baseUrl}/branch/summary/branch/${userDetails.bid}`, {
                method: "GET",
                headers: {
                    Authorization: token
                }
            }).then((response) => {
                let branchData = response.data.data;
                setRegionData(branchData);

            }).catch((error) => {
                console.log(error);
            })
        }

    };

    // navigateToDashboard - function
    const navigateToDashboard = (selectedBranchId) => {
        // navigate.push(`/branch-dashboard`);
        navigate('/branch-dashboard', {
            state: {
                selectedBranchId: selectedBranchId,
            }
        })
    }

    // navigateToBranchTables - function
    const navigateToBranchTables = (selectedBranchId) => {
        navigate('/branch-tables-page', {
            state: {
                selectedBranchId: selectedBranchId,
            }
        });
    }



    return (
        <>
            <div className="container-fluid">
                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h1>Home</h1>
                        </div>
                    </div>
                </div>
                <div className="row clearfix">
                    <div className="col-lg-5 col-md-12 col-sm-12">
                        <div className="row clearfix">
                            <div className="col-lg-6 col-md-6 col-sm-12 p-r-10">
                                <div className="card w_card3 home-card">
                                    <div className="body">
                                        <div className="text-center">
                                            <i className="fa fa-ticket" aria-hidden="true"></i>
                                            {
                                                branchInfo.tokensIssued != undefined ? (
                                                    <h5 className="m-t-20 mb-0">{branchInfo.tokensIssued === null ? 0 : branchInfo.tokensIssued}</h5>
                                                ) : null
                                            }

                                            <p className="text-muted">Issued Token</p>
                                            {/* <a href="/" className="btn btn-info btn-round">Find more</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 p-l-10">
                                <div className="card w_card3 home-card">
                                    <div className="body">
                                        <div className="text-center">
                                            <i className="fa fa-street-view" aria-hidden="true"></i>
                                            {
                                                branchInfo.tokensServed != undefined ? (
                                                    <h5 className="m-t-20 mb-0">{branchInfo.tokensServed === null ? 0 : branchInfo.tokensServed}</h5>
                                                ) : null
                                            }
                                            <p className="text-muted">Served Token</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 p-r-10">
                                <div className="card w_card3 home-card ">
                                    <div className="body">
                                        <div className="text-center">
                                            <i className="fa fa-user" aria-hidden="true"></i>
                                            {
                                                branchInfo.userCount != undefined ? (
                                                    <h5 className="m-t-20 mb-0">{branchInfo.userCount === null ? 0 : branchInfo.userCount}</h5>
                                                ) : null
                                            }
                                            <p className="text-muted">Online Tellers</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 p-l-10">
                                <div className="card w_card3 home-card">
                                    <div className="body">
                                        <div className="text-center">
                                            <i className="fa fa-television" aria-hidden="true"></i>
                                            {
                                                branchInfo.kioskCount != undefined ? (
                                                    <h5 className="m-t-20 mb-0">{branchInfo.kioskCount === null ? 0 : branchInfo.kioskCount}</h5>
                                                ) : null
                                            }
                                            <p className="text-muted">Online Kiosks</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-7 col-md-12 col-sm-12">
                        <div className="card home-chart-card">
                            <div className="body" style={{ minHeight: '301px' }}>
                                <div className='m-b-5'>Served tickets count in last 7 days </div>
                                {/* <p className="text-muted">The global and country campaign stats show how popular your ad is relative to your other ads.</p> */}

                                {showChart ?
                                    <NoOfTicketsChart tokenCount={tokenCount} xLabel={xLabel} chartHeight={299} />

                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className='p-b-20'>
                    <div className="row clearfix">
                        {
                            branchData.length > 0 || regionData !== null ? (

                                <>
                                    {userDetails.branchLevel != 4 ? (
                                        <>
                                            {
                                                branchData.map((branch, index) => (
                                                    <>
                                                        <div className='col-md-12'>
                                                            <div className="card w_card3 home-list-card">
                                                                <div className="body">
                                                                    <div className='row horizontal-align-center-flex'>

                                                                        <div className='col-md-3'>
                                                                            <div className='link' onClick={() => {
                                                                                navigateToDashboard(branch.id)
                                                                            }}>{branch.name}</div>
                                                                            <p className="mb-0 text-muted">No of Tokens
                                                                                - {branch.ticketCount}</p>
                                                                            <p className="mb-0 text-muted">No of Counters
                                                                                - {branch.loginCounters}</p>
                                                                        </div>

                                                                        <div className='col-md-4'>
                                                                            <div className='donut-parent'>
                                                                                <div className='mr-3'>
                                                                                    <Donut
                                                                                        className="knob"
                                                                                        diameter={50}
                                                                                        min={0}
                                                                                        max={100}
                                                                                        step={1}
                                                                                        value={branch.serviceExceptions}
                                                                                        theme={{
                                                                                            donutThickness: 5,
                                                                                            donutColor: '#17C2D7',
                                                                                            centerColor: '#282B2F',
                                                                                            bgrColor: '#383b40'
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div className="wrapper-custom">
                                                                                    <a className="button1"></a><span>Service Count</span>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        <div className='col-md-4'>
                                                                            <div className='donut-parent'>
                                                                                <div className='mr-3'>
                                                                                    <Donut
                                                                                        className="knob"
                                                                                        diameter={50}
                                                                                        min={0}
                                                                                        max={100}
                                                                                        step={1}
                                                                                        value={branch.waitExceptions}
                                                                                        theme={{
                                                                                            donutThickness: 5,
                                                                                            donutColor: '#4caf50',
                                                                                            centerColor: '#282B2F',
                                                                                            bgrColor: '#383b40'
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div className="wrapper-custom">
                                                                                    <a className="button2"></a><span>Exception Count</span>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        {/* <div className='col-md-2'>
                                                                            <div className='action text-center'>

                                                                                <button type="button" className="btn btn-sm btn-default list-action-btn" data-toggle="tooltip"
                                                                                    data-placement="top"
                                                                                    title={`${branch.name} - Branch Table`}
                                                                                    onClick={() => {
                                                                                        navigateToBranchTables(branch.id)
                                                                                    }}
                                                                                >
                                                                                    <i className="fa fa-list" />
                                                                                </button>
                                                                                &nbsp;
                                                                                <button type="button" className="btn btn-sm btn-default list-action-btn" data-toggle="tooltip"
                                                                                    data-placement="top"
                                                                                    title={`${branch.name} - Dashboard`}
                                                                                    onClick={() => {
                                                                                        navigateToDashboard(branch.id);
                                                                                    }}
                                                                                >
                                                                                    <i className="fa fa-circle-o-notch" />
                                                                                </button>


                                                                            </div>
                                                                        </div> */}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </>
                                                ))

                                            }
                                        </>
                                    ) : (
                                        <div className='col-md-12'>
                                            <div className="card w_card3 home-list-card">
                                                <div className="body">
                                                    <div className='row horizontal-align-center-flex'>

                                                        <div className='col-md-3'>
                                                            <div className='link' onClick={() => {
                                                                navigateToDashboard(regionData.id)
                                                            }}>{regionData.name}</div>
                                                            <p className="mb-0 text-muted">No of Tokens
                                                                - {regionData.ticketCount}</p>
                                                            <p className="mb-0 text-muted">No of Counters
                                                                - {regionData.loginCounters}</p>
                                                        </div>

                                                        <div className='col-md-4'>
                                                            <div className='donut-parent'>
                                                                <div className='mr-3'>
                                                                    <Donut
                                                                        className="knob"
                                                                        diameter={50}
                                                                        min={0}
                                                                        max={100}
                                                                        step={1}
                                                                        value={regionData.serviceExceptions}
                                                                        theme={{
                                                                            donutThickness: 5,
                                                                            donutColor: '#17C2D7',
                                                                            centerColor: '#282B2F',
                                                                            bgrColor: '#383b40'
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="wrapper-custom">
                                                                    <a className="button1"></a><span>Service Count</span>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className='col-md-4'>
                                                            <div className='donut-parent'>
                                                                <div className='mr-3'>
                                                                    <Donut
                                                                        className="knob"
                                                                        diameter={50}
                                                                        min={0}
                                                                        max={100}
                                                                        step={1}
                                                                        value={regionData.waitExceptions}
                                                                        theme={{
                                                                            donutThickness: 5,
                                                                            donutColor: '#4caf50',
                                                                            centerColor: '#282B2F',
                                                                            bgrColor: '#383b40'
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="wrapper-custom">
                                                                    <a className="button2"></a><span>Exception Count</span>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        {/* <div className='col-md-2'>
                                                            <div className='action text-center'>

                                                                <button type="button" className="btn btn-sm btn-default list-action-btn" data-toggle="tooltip"
                                                                    data-placement="top"
                                                                    title={`${regionData.name} - Branch Table`}
                                                                    onClick={() => {
                                                                        navigateToBranchTables(regionData.id)
                                                                    }}
                                                                >
                                                                    <i className="fa fa-list" />
                                                                </button>
                                                                &nbsp;
                                                                <button type="button" className="btn btn-sm btn-default list-action-btn" data-toggle="tooltip"
                                                                    data-placement="top"
                                                                    title={`${regionData.name} - Dashboard`}
                                                                    onClick={() => {
                                                                        navigateToDashboard(regionData.id);
                                                                    }}
                                                                >
                                                                    <i className="fa fa-circle-o-notch" />
                                                                </button>


                                                            </div>
                                                        </div> */}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>

                            ) : (
                                <div className='col-md-12'>
                                    <div className="alert alert-custom alert-dismissible" role="alert" style={{ marginTop: '29px' }}>
                                        <i className="fa fa-info-circle"></i> No branches
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>






            </div>
        </>

    );

}


export default HomePage