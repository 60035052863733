import React, { useEffect, useState, useRef } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import MUIDataTable from 'mui-datatables';
import CircularProgress from "@mui/material/CircularProgress";
import axios from 'axios';


const AgentReport = ({ openAgentReport, selectedRegionIds, changeRegionIds }) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [allreportsNew, setAllReportsNew] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const timeoutRef = useRef(null);


    const [dataTable, setDataTable] = useState({
        page: 0,
        size: 10,
        search: '',
        order: null,
    });

    const setTable = (tableState) => {
        setDataTable({
            page: tableState.page,
            search: tableState.searchText,
            size: tableState.rowsPerPage,
            order: tableState.sortOrder
        });
    };

    const columns = [
        {
            label: 'Agent Name',
            name: 'userName',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Branch",
            name: 'branchName',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Region",
            name: 'regionName',
            options: {
                sort: true,
                filter: false,

            }
        },
        {
            label: "Status",
            name: 'status',
            options: {
                sort: true,
                filter: false,
                customBodyRender: (value) => {
                    return (<>
                        {value === 0 ? (
                            <p className='mb-0'>Idle</p>
                        ) : value === 1 ? (
                            <p className='mb-0'>Serving</p>
                        ) : value === 3 ? (
                            <p className='mb-0'>AUX</p>
                        ) :
                            <p className='mb-0'>Logout</p>
                        }
                    </>);
                }
            }
        },
        {
            label: "Counter",
            name: 'counter',
            options: {
                sort: false,
                filter: false,
            }
        },
        {
            label: "Current Service Time",
            name: 'totalTime',
            options: {
                sort: false,
                filter: false,
            }
        },
        {
            label: "Handling skill",
            name: 'skill',
            options: {
                sort: true,
                filter: false,
            }
        },

    ];

    const options = {
        // tableBodyHeight: '55vh',
        viewColumns: false,
        filterType: 'dropdown',
        filter: false,
        responsive: 'vertical',
        serverSide: true,
        count: count,
        download: false,
        jumpToPage: true,
        selectableRows: 'none',
        storageKey: '_rtr_ar_table',
        print: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        },
        onTableChange: (action, tableState) => {

            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want

            switch (action) {
                case 'changePage':
                    getAgentReport(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'sort':
                    getAgentReport(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'changeRowsPerPage':
                    setRowsPerPage(tableState.rowsPerPage);
                    getAgentReport(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'search':
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }
                    timeoutRef.current = setTimeout(() => getAgentReport(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage), 2000);
                    setTable(tableState);
                    break;
                default:
                    break;
            }
        },
    };



    // useEffect - getAgentReport
    useEffect(() => {
        if (openAgentReport) {
            getAgentReport(0, null, null, rowsPerPage);
        }
    }, [openAgentReport]);

    // when change selected region array, call getAgentReport - useEffect
    useEffect(() => {
        if (openAgentReport) {
            getAgentReport(0, null, null, rowsPerPage);
        }
    }, [changeRegionIds])


    // getAgentReport - function
    const getAgentReport = (page, sortOrder, search, size) => {
        setIsLoading(true);

        axios({
            method: 'POST',
            url: `${baseUrl}/real-time/agent-reports/inner-data/customer/${userDetails.cid}`,
            headers: {
                Authorization: token
            },
            data: {
                page: page,
                size: size,
                sort: sortOrder,
                searchText: search,
                branchIds: selectedRegionIds
            }

        }).then((res) => {

            let data = res.data.data;
            setAllReportsNew((data.content))
            setCount(data.totalElements);

            setIsLoading(false)

        }).catch((error) => {
            console.log(error);
            setIsLoading(false);
            if (error.response !== undefined) {
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }


    return (
        <>
            <div><ToastContainer /></div>
            {/* <div className='container-fluid'> */}

            {/* <div className='block-header'>
                    <div className='row clearfix'>
                        <div className="col-lg-9 mt-2 col-md-8 col-sm-12">
                            <h2>Agent Reports</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/reports">Reports</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Agent Report</li>
                                </ol>
                            </nav>
                        </div>

                    </div>
                </div> */}





            {/* <div className="row clealfix">
                    <div className="col-md-12 ">
                        <div className='muidatatablecontainer pb-3'> */}
            <MUIDataTable

                title={
                    <>
                        {isLoading ?
                            <CircularProgress color="secondary" size={20} /> : <>{count} records.</>}
                    </>
                }
                data={allreportsNew}
                columns={columns}
                options={options}
                className='text-center-table'
            />

            {/* </div>
                    </div>
                </div> */}

            {/* </div> */}





        </>
    )
}

export default AgentReport

