import React, { useState, useEffect } from 'react';
import { Col, Nav, Row, Tab, Tabs } from 'react-bootstrap';
import BasicBarChart from '../../ChartsImpl/DetailsPageCharts/BasicBarChart';
import MUIDataTable from 'mui-datatables';
import { createTheme } from "@mui/material";
import toast from "react-hot-toast";
import axios from 'axios';
import CircularProgress from "@mui/material/CircularProgress";

let myTimeout = null;

const BranchVIPTickets = (props) => {

    const [listType, setListType] = useState('all');

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [update, setUpdate] = useState('1s')

    const [tokenData, setTokenData] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (props.showVIPTicketsDetails === false) return;
        if (props.vipTicketsInc > 0 || props.messageInc1000 !== '' || props.messageInc1001 !== '') {
            getVipTicketData();
        }
    }, [props.vipTicketsInc, props.messageInc1000, props.messageInc1001]);

    const columns = [
        {
            label: 'Token No',
            name: 'ticketNumber',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: "Category",
            name: 'category',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Language',
            name: 'lang',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'Operator',
            name: 'firstName',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Counter',
            name: 'counterName',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'Issued time',
            name: 'issuedTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Called time',
            name: 'startTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Serving time',
            name: 'servedTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Status',
            name: 'id',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    if (tokenData === undefined) return;
                    let item = tokenData.find(item => item.id === value);
                    return (<>
                        {item.status === 1 ? 'Serving'
                            : item.status === 2 ? 'No Show'
                                : item.status === 3 ? 'Local Waiting'
                                    : item.status === 4 ? 'Served'
                                        : 'Pending'}
                    </>);
                }
            },
        }
    ];

    const columns_1 = [
        {
            label: "Branch",
            name: 'branchName',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Token No',
            name: 'ticketNumber',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: "Category",
            name: 'category',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Language',
            name: 'lang',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'Operator',
            name: 'firstName',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Counter',
            name: 'counterName',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'Issued time',
            name: 'issuedTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Called time',
            name: 'startTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Serving time',
            name: 'servedTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Status',
            name: 'id',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    if (tokenData === undefined) return;
                    let item = tokenData.find(item => item.id === value);
                    return (<>
                        {item.status === 1 ? 'Serving'
                            : item.status === 2 ? 'No Show'
                                : item.status === 3 ? 'Local Waiting'
                                    : item.status === 4 ? 'Served'
                                        : 'Pending'}
                    </>);
                }
            },
        }
    ];

    // useEffect(() => {
    //     if (myTimeout != null)
    //         clearTimeout(myTimeout);
    //     myTimeout = setTimeout(myGreeting, 5000);
    // }, [tokenData]);

    // useEffect(() => {
    //     if (myTimeout != null)
    //         clearTimeout(myTimeout);
    // }, []);

    // const myGreeting = () => {

    //     if (tokenData !== null && tokenData.length > 0) {
    //         for (let index = 0; index < tokenData.length; index++) {
    //             const element = tokenData[index];
    //             if (element.status === 1) {
    //                 element.exceptionTimeFromSec = element.exceptionTimeFromSec + 5;

    //                 var hours = Math.floor(element.exceptionTimeFromSec / 3600);
    //                 var minutes = Math.floor((element.exceptionTimeFromSec - (hours * 3600)) / 60);
    //                 var seconds = element.exceptionTimeFromSec - (hours * 3600) - (minutes * 60);

    //                 if (hours < 10) { hours = "0" + hours; }
    //                 if (minutes < 10) { minutes = "0" + minutes; }
    //                 if (seconds < 10) { seconds = "0" + seconds; }

    //                 element.waitTime = `${hours}:${minutes}:${seconds}`;
    //             }
    //         }
    //         setTokenData(tokenData);
    //         setUpdate(new Date().toString());
    //     }
    //     if (myTimeout != null)
    //         clearTimeout(myTimeout);
    //     myTimeout = setTimeout(myGreeting, 5000);
    // }

    const getVipTicketData = () => {
        setLoading(true);

        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/get-vip-ticket-details/inner-data/branch/${props.selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('getServedTicketsData success');
            let data = res.data.data;
            // loopChartData(data.countAndPercentage);

            // setchartData(data.countAndPercentage);
            setTokenData(data);

            setCount(data.length);
            setLoading(false);

        }).catch((error) => {
            console.log('getTicketIssuedDetails error');
            console.log(error);
            setLoading(false);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    };

    const closeModal = () => {
        props.setShowVIPTicketsDetails(false)

        // settokenData([]);
        // setchartData([]);
    }

    const options = {
        tableBodyHeight: '50vh',
        filter: false,
        responsive: 'vertical',
        serverSide: false,
        count: count,
        download: false,
        viewColumns: false,
        jumpToPage: true,
        selectableRows: 'none',
        print: false,
        rowsPerPage: rowsPerPage,
        onChangeRowsPerPage: (n) => {
            setRowsPerPage(n)
        },
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        }
    };

    return (
        <>
            {props.showVIPTicketsDetails ?
                <div id="megamenu" className={`megamenu particles_js details-page open `}>
                    <span
                        onClick={() => {
                            props.setSticky(true)
                            props.setShowVIPTicketsDetails(false);
                            closeModal();
                        }}
                        className="megamenu_toggle btn btn-danger"><i className="icon-close"></i></span>
                    <div className="container">
                        <div className="row clearfix">
                            <div className="col-12">
                                <div className="card">
                                    <div className="body">
                                        <div style={{ textAlign: 'center' }}>
                                            <h4>VIP Tickets Data</h4>
                                            <small>{props.date}</small>
                                        </div>
                                        <br></br>
                                        <div className="row clearfix">
                                            {/* <div className="col-4">

                                                {
                                                    chartData.length !== 0 && chartValue > 0 ? <PersentageDonutChartWithBottomLegend
                                                        lable={categoryList} percentage={percentageList} chartValue={chartValue} />
                                                        :
                                                        <PersentageDonutChartWithBottomLegend
                                                            lable={[]} percentage={[]} chartValue={0} />
                                                }

                                                <div className='mt-4'>
                                                    <div style={{ overflowY: 'scroll', height: '200px' }}>
                                                        <table className="table table-hover table-custom">
                                                            <tbody>
                                                                {chartData.map((data, i) =>
                                                                    <tr key={i}>
                                                                        <td>{data.category}</td>
                                                                        <td>{data.ticketCount}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div> */}

                                            <div className="col-12">
                                                <div className="muidatatablecontainer">
                                                    {props.selectedBranchLevel === 4 ? (
                                                        <MUIDataTable
                                                            title={<>
                                                                {count}{count === 1 ? ' record' : ' records'}.
                                                                {isLoading &&
                                                                    <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                                                        size={20} /></>}
                                                            </>
                                                            }
                                                            data={tokenData}
                                                            columns={columns}
                                                            options={options}
                                                        />
                                                    ) : (
                                                        <MUIDataTable
                                                            title={<>
                                                                {count}{count === 1 ? ' record' : ' records'}.
                                                                {isLoading &&
                                                                    <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                                                        size={20} /></>}
                                                            </>
                                                            }
                                                            data={tokenData}
                                                            columns={columns_1}
                                                            options={options}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div id="particles-js"></div>
                </div>
                : null}
        </>
    )
}

export default BranchVIPTickets