import axios from 'axios';
import React, { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Button from 'react-bootstrap-button-loader';

// used for add and edit
const AddProvinceModal = ({ addProvinceModal, setAddProvinceModal, getAll, selectedCountry, selectedProvince, setSelectedProvince, clearStates, modalType }) => {

  const [baseUrl, setBaseUrl] = useState('');
  const [token, setToken] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const [buttonLoader, setButtonLoader] = useState(false);

  const [provinceName, setProvinceName] = useState('');

  // if modal type is edit, set value
  useEffect(() => {
    if (modalType === 1) {
      setProvinceName(selectedProvince.name)
    }
  }, [selectedProvince]);

  // get base url & token
  useEffect(() => {
    setBaseUrl(localStorage.getItem('API_URL'));
    setToken(localStorage.getItem('user_token'));
    setUserDetails(JSON.parse(localStorage.getItem('user_details')));
  }, [AddProvinceModal])

  // addNewProvince - function
  const addNewProvince = () => {
    console.log('addNewProvince function start');
    setButtonLoader(true);

    // validate
    if (provinceName.length > 0) {

      axios({
        url: `${baseUrl}/branch/add/primary/parent/${selectedCountry.id}`,
        method: 'POST',
        headers: {
          Authorization: token
        },
        data: {
          name: provinceName,
          level: 2
        }
      }).then((res) => {

        console.log('>> addNewProvince success');
        setSelectedProvince(res.data.data);
        clearStates(2);

        setButtonLoader(false);
        closeModal();
        toast.remove();
        toast.success('Successfully added', {
          position: 'bottom-center',
        });

        getAll(1, 0);
        getAll(2, selectedCountry.id);

      }).catch((error) => {
        console.log('>> addNewProvince error');
        console.log(error);
        setButtonLoader(false);

        if (error.response !== undefined) {
          toast.remove();
          toast.error(error.response.data.message, {
            position: 'bottom-center',
          });
        } else {
          toast.remove();
          toast.error('Something went wrong', {
            position: 'bottom-center',
          });
        }
      })

    } else {
      setButtonLoader(false);
      toast.remove();
      toast.error('Province name can not be empty', {
        position: 'bottom-center',
      });
    }
  }

  // editProvince - function
  const editProvince = () => {
    console.log('editProvince function working');
    setButtonLoader(true);

    // validate
    if (provinceName.length > 0) {

      if (provinceName === selectedProvince.name) {
        setButtonLoader(false);
        toast.remove();
        toast.error('Same province name. No need to update', {
          position: 'bottom-center',
        });

      } else {

        axios({
          url: `${baseUrl}/branch/update/region`,
          method: 'POST',
          headers: {
            Authorization: token
          },
          data: {
            id: selectedProvince.id,
            name: provinceName,
            customer: {
              id: userDetails.cid
            }
          }
        }).then((res) => {

          console.log('>> editProvince success');
          closeModal();
          setButtonLoader(false);
          toast.remove();
          toast.success('Successfully updated', {
            position: 'bottom-center',
          });

          getAll(2, selectedCountry.id);

        }).catch((error) => {
          console.log('>> editProvince error');
          console.log(error);
          setButtonLoader(false);

          if (error.response !== undefined) {
            toast.remove();
            toast.error(error.response.data.message, {
              position: 'bottom-center',
            });
          } else {
            toast.remove();
            toast.error('Something went wrong', {
              position: 'bottom-center',
            });
          }
        })

      }

    } else {
      setButtonLoader(false);
      toast.remove();
      toast.error('Province name can not be empty', {
        position: 'bottom-center',
      });
    }

  }

  // closeModal - function
  function closeModal() {
    setAddProvinceModal(false);
  }

  // when click esc, close modal
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setAddProvinceModal(false);
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  return (
    <>
      <Toaster />
      {addProvinceModal ?
        <div className={`modal fade  d-block show modal-container`} id="exampleModal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">

                {modalType === 0 ? (
                  <h5 className="modal-title" id="exampleModalLabel"> Add New Province</h5>
                ) : (
                  <h5 className="modal-title" id="exampleModalLabel"> Edit Province</h5>
                )}

                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                  onClick={() => {
                    closeModal()
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">

                <div className="input-group input-group-sm mb-3 modal-input-group">
                  <p className='input-field-side-text'>Province Name : </p>

                  {modalType === 0 ? (
                    // add text field
                    <input autoFocus type="text" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                      onChange={(e) => {
                        setProvinceName(e.target.value);
                      }}
                    />
                  ) : (
                    // edit text field
                    <input autoFocus type="text" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                      value={provinceName}
                      onChange={(e) => {
                        setProvinceName(e.target.value);
                      }}
                    />
                  )}

                </div>

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal"
                  onClick={() => {
                    closeModal()
                  }}>Cancel</button>

                {modalType === 0 ? (
                  <Button loading={buttonLoader} type="button" className="btn btn-primary"
                    onClick={() => {
                      addNewProvince();
                    }}
                  >Add Provice</Button>
                ) : (
                  <Button loading={buttonLoader} type="button" className="btn btn-primary"
                    onClick={() => {
                      editProvince();
                    }}
                  >Edit Provice</Button>
                )}

              </div>
            </div>
          </div>
        </div>

        : ''}
    </>
  )
}

export default AddProvinceModal;