import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import AssignUserSkillsModal from "../ModalImpl/AssignUserSkillsModal";
import AssignTransferCategoryModal from '../ModalImpl/AssignTransferCategoryModal';
import AssignNotificationAccessibilityModal from '../ModalImpl/AssignNotificationAccessibilityModal';


const UserProfile = () => {

    const navigate = useNavigate();

    const location = useLocation();
    let history = useNavigate();
    const params = new URLSearchParams(location.search);

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const selectedBranch = JSON.parse(localStorage.getItem('selected_branch'));
    const loggedUser = JSON.parse(localStorage.getItem('user_details'));
    const userId = params.get('uid');

    const [userDetails, setUserDetails] = useState({});
    const [userType, setUserType] = useState({});
    const [userRoleFeatures, setUserRoleFeatures] = useState([]);
    const [userLogins, setUserLogins] = useState([]);
    // const [callingPadProfileBean, setCallingPadProfileBean] = useState([]);

    const [selectedImage, setSelectedImage] = useState({});

    const [showProfileImage, setShowProfileImage] = useState(false)

    const [showAssignNotificationAccessibility, setShowAssignNotificationAccessibility] = useState(false);

    const [gender, setGender] = useState(null)
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [contact, setContact] = useState(null)
    const [username, setUsername] = useState(null)
    const [email, setEmail] = useState(null)
    const [currentPassword, setCurrentPassword] = useState(null)
    const currentInviciblePassword = null;
    const [newPassword, setNewPassword] = useState(null)
    const [repeatPassword, setRepeatPassword] = useState(null)
    const [userBranch, setUserBranch] = useState(null)
    const [employeeNo, setEmployeeNo] = useState(null)

    const [allTsuProfiles, setAllTsuProfiles] = useState([]);
    const [selectedTsuProfile, setSelectedTsuProfile] = useState();

    const [buttonLoader, setButtonLoader] = useState(false);

    const [showSmsPassword, setShowSmsPassword] = useState(false)

    const [showCurrentPass, setshowCurrentPass] = useState(false)
    const [showNewPass, setshowNewPass] = useState(false)
    const [showRepeatPass, setshowRepeatPass] = useState(false)

    const [userSkillList, setUserSkillList] = useState({});
    const [showAssignUserSkillsModal, setShowAssignUserSkillsModal] = useState('');
    const [userCategoryList, setUserCategoryList] = useState({});

    const [showBreadcrumb, setShowBreadcrumb] = useState(false)
    const [allnotificationAccessibility, setllnotificationAccessibility] = useState({});

    const genderOptions = [
        {
            id: "1",
            label: "Male",
            value: "Male",
        },
        {
            id: "2",
            label: "Female",
            value: "Female",
        },
    ];

    const headers = {
        "Content-Type": "application/json",
        "Authorization": token
    }

    const imageChange = (e) => {

        setSelectedImage({
            /* contains the preview, if you want to show the picture to the user
                 you can access it with this.state.currentPicture
             */
            picturePreview: URL.createObjectURL(e.target.files[0]),
            /* this contains the file we want to send */
            pictureAsFile: e.target.files[0],


        });

        setShowProfileImage(true)

    };

    useEffect(() => {
        if (location.state != null)
            setShowBreadcrumb(location.state.breadcrumb);
    }, [UserProfile])



    // call getUserById when get userId
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            if (baseUrl.length > 0 && token.length > 0 && userId != undefined) {
                getUserById();
                getUserLogins();
                getSkillsByUserId();
                getTellerTransferCategories();
                getassignedaccesibiltiesbyuser();
                getAllTsuProfiles();
            }
        }
    }, [UserProfile])

    //renderTooltip - function
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {props}
        </Tooltip>
    );

    const disableUserSkills = (skillId) => {

        axios({
            method: 'POST',
            url: `${baseUrl}/skill/disable/skill/${skillId}/user/${userId}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            getSkillsByUserId();

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //getTellerTransferCategories - function
    const getTellerTransferCategories = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/user/transfer/get-list/user/${userId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let data = res.data.data;
            setUserCategoryList(data)


        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // getSkillsByUserId - function
    const getSkillsByUserId = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/user/get-skills/${userId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let data = res.data.data;
            setUserSkillList(data)

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    };

    // getUserById - function
    const getUserById = () => {

        if (userId != undefined) {

            axios({
                method: 'GET',
                url: `${baseUrl}/user/get-user-by-id/${userId}`,
                headers: {
                    Authorization: token
                }
            }).then((res) => {

                let data = res.data.data;
                setUserDetails(data);
                setUserType(data.userType);
                setUserRoleFeatures(data.roleFeatureData);

                setFirstName(data.firstName)
                setLastName(data.lastName)
                setGender(data.gender)
                setContact(data.contact)
                setUsername(data.userName)
                setCurrentPassword(data.password)
                setEmail(data.email)
                setUserBranch(data.bid)
                setEmployeeNo(data.employeeNumber)
                if (data.callingPadProfileBean !== undefined) {
                    setSelectedTsuProfile(data.callingPadProfileBean.id)
                } else {
                    setSelectedTsuProfile(null);
                }

            }).catch((error) => {

                console.log(error);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }
            })

        } else {
            toast.remove();
            toast.error('Can not find user', {
                position: 'bottom-center',
            });
        }
    }

    const updateUserData = () => {

        setButtonLoader(true);

        const body = {
            "id": userId,
            "firstName": firstName,
            "lastName": lastName,
            "gender": gender,
            "contact": contact,
            "employeeNo": employeeNo
        }

        axios({
            method: 'POST',
            url: `${baseUrl}/user/update/user-details`,
            data: body,
            headers: headers,

        }).then((res) => {
            setButtonLoader(false);

            getUserById();

            toast.remove();
            toast.success("Successfully Updated", {
                position: 'bottom-center',
            });

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    const updateUsername = () => {

        setButtonLoader(true);

        const body = {
            "id": userId,
            "username": username,
            "email": email
        }

        Swal.fire({
            position: 'center',
            icon: 'question',
            text: 'Do you want update these details?',
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            width: '400px',

        }).then((result) => {
            if (result.value === true) {
                axios({
                    method: 'POST',
                    url: `${baseUrl}/user/update/username`,
                    data: body,
                    headers: headers,

                }).then((res) => {
                    setButtonLoader(false);

                    userLogOut();

                    toast.remove();
                    toast.success("Successfully Updated", {
                        position: 'bottom-center',
                    });

                }).catch((error) => {
                    console.log(error);

                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                })
            }
        })
    }

    const userLogOut = () => {

        axios({
            method: 'POST',
            url: `${baseUrl}/user/logout`,
            headers: {
                Authorization: token
            },
            data: {
                "userId": userId,
                "operatorLoginId": loggedUser.operatorLoginId,
            }

        }).then(response => {

            toast.remove();
            toast.success('User Successfully Logout!',
                {
                    position: 'bottom-center',
                });
            localStorage.removeItem('user_token')
            localStorage.removeItem('user_details')

            navigate('/');

        }).catch((error) => {
            console.log(error);
            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                })
            }
        })
    };

    const updateUserProfileImage = () => {

        setButtonLoader(true);

        let formData = new FormData();
        formData.append('id', userId);

        console.log("image: ", selectedImage.pictureAsFile);

        if (selectedImage.pictureAsFile === undefined) {
            formData.append("profilePicture", userDetails.image);
        } else {
            formData.append("profilePicture", selectedImage.pictureAsFile);
            setShowProfileImage(false)
        }

        axios({
            method: 'POST',
            url: `${baseUrl}/user/update/user-profile-image`,
            data: formData,
            headers: headers,

        }).then((res) => {
            setButtonLoader(false);

            getUserById();

            toast.remove();
            toast.success("Successfully Updated", {
                position: 'bottom-center',
            });

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // getUserLogins - function
    const getUserLogins = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/user/get-user-login/${userId}/offset/0/limit/5`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let dataList = res.data.data.list;
            setUserLogins(dataList);

        }).catch((error) => {

            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

            // history.push('/not-found');
        })
    }

    // userActiveDeactive - function
    const userActiveDeactive = () => {

        Swal.fire({
            position: 'center',
            icon: '',
            text: 'Do you want to change user status ?',
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            width: '400px',

        }).then((result) => {
            if (result.value == true) {

                axios({
                    method: 'POST',
                    url: `${baseUrl}/user/active-inactive/user/${userId}`,
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {

                    let userStatus = res.data.data;

                    if (userStatus == false) {
                        toast.remove();
                        toast.success('User activated successfully ', {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.success('User deactivated successfully ', {
                            position: 'bottom-center',
                        });
                    }
                    getUserById();
                    getUserLogins();

                }).catch((error) => {
                    console.log(error);

                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                })
            }

        })


    }

    // getassignedaccesibiltiesbyuser - function
    const getassignedaccesibiltiesbyuser = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/alert/notification/get-list/accessibility/user/${userId}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            let dataList = res.data.data;
            setllnotificationAccessibility(dataList);


        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // remove user from branch - function
    const removeUserFromBranch = () => {
        Swal.fire({
            position: 'center',
            icon: '',
            text: 'Do you want to remove this user from branch ?',
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            width: '400px',

        }).then((result) => {
            if (result.value == true) {

                axios({
                    method: 'POST',
                    url: `${baseUrl}/user/remove-branch/user/${userId}`,
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {

                    toast.remove();
                    toast.success('User successfully removed', {
                        position: 'bottom-center',
                    });

                    history(`/branch-management/users`)


                }).catch((error) => {

                    console.log(error);

                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                })

            }

        })
    }

    const getAllTsuProfiles = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/branch/calling-pad-profile/get-list/customer/${loggedUser.cid}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let data = res.data.data;
            setAllTsuProfiles(data);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })

    }

    const updateTsuProfile = (callingPadProfile) => {

        axios({
            method: 'POST',
            url: `${baseUrl}/user/update/tsu-profile/${callingPadProfile}/user/${userId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let message = res.data.data;

            getUserById();

            toast.remove();
            toast.success(message, {
                position: 'bottom-center',
            });
        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })

    }



    const passwordValidate = (isUser) => {

        if (isUser && currentPassword.length === 0) {
            setButtonLoader(false)
            toast.remove();
            toast.error('Current password empty!', {
                position: 'bottom-center',
            });
            return false;
        } else if (newPassword.length === 0) {
            setButtonLoader(false)
            toast.remove();
            toast.error('New password empty!', {
                position: 'bottom-center',
            });
            return false;
        } else if (repeatPassword.length === 0) {
            setButtonLoader(false)
            toast.remove();
            toast.error('Confirm password empty!', {
                position: 'bottom-center',
            });
            return false;
        } else if (newPassword != repeatPassword) {
            setButtonLoader(false)
            toast.remove();
            toast.error('Please check confirm password!', {
                position: 'bottom-center',
            });
            return false;
        } else {
            return true;
        }
    }

    const resetPasswordByUser = () => {

        setButtonLoader(true)

        let isValidate = passwordValidate(true);

        if (isValidate) {

            Swal.fire({
                position: 'center',
                icon: '',
                text: 'Do you want to reset your password ?',
                showConfirmButton: true,
                confirmButtonText: "Yes",
                showCancelButton: true,
                cancelButtonText: "No",
                width: '400px',

            }).then((result) => {
                if (result.value == true) {

                    axios({
                        method: 'POST',
                        url: `${baseUrl}/user/reset-password`,
                        headers: headers,
                        data: {
                            "oldPassword": currentPassword,
                            "newPassword": newPassword
                        }
                    }).then((res) => {

                        let data = res.data.data.list;
                        toast.remove();
                        toast.success('Reset Password Successfully ', {
                            position: 'bottom-center',
                        });

                        userLogOut();


                    }).catch((error) => {
                        console.log(error);
                        if (error.response !== undefined) {
                            toast.remove();
                            toast.error(error.response.data.message, {
                                position: 'bottom-center',
                            });
                        } else {
                            toast.remove();
                            toast.error('Something went wrong', {
                                position: 'bottom-center',
                            });
                        }
                    })

                }

            })
        }

    };

    //resetPasswordByAdmin - function
    const resetPasswordByAdmin = () => {

        setButtonLoader(true)

        let isValidate = passwordValidate(false);

        if (isValidate) {

            Swal.fire({
                position: 'center',
                icon: '',
                text: 'Do you want to reset your password ?',
                showConfirmButton: true,
                confirmButtonText: "Yes",
                showCancelButton: true,
                cancelButtonText: "No",
                width: '400px',

            }).then((result) => {
                if (result.value == true) {

                    axios({
                        method: 'POST',
                        url: `${baseUrl}/user/reset-password/admin`,
                        headers: headers,
                        data: {
                            "userId": userId,
                            "newPassword": newPassword
                        }
                    }).then((res) => {
                        let data = res.data.data.list;
                        toast.remove();
                        toast.success('Password Changed Successfully ', {
                            position: 'bottom-center',
                        });

                        userLogOut();


                    }).catch((error) => {
                        console.log(error);
                        if (error.response !== undefined) {
                            toast.remove();
                            toast.error(error.response.data.message, {
                                position: 'bottom-center',
                            });
                        } else {
                            toast.remove();
                            toast.error('Something went wrong', {
                                position: 'bottom-center',
                            });
                        }
                    })

                }

            })
        }
    }

    //handlePasswordReset - function
    const handlePasswordReset = () => {

        if (loggedUser.userType !== 3) {
            resetPasswordByUser('');
        } else {
            resetPasswordByAdmin('');
        }
    }

    return (
        <>
            <div><Toaster /></div>
            <div className="container-fluid">

                <div className="block-header mb-4">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h2>User Profile</h2>

                            {
                                showBreadcrumb ? (
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">

                                            {/* <li className="breadcrumb-item"><Link to="/region-management">Region Management</Link></li> */}
                                            <li className="breadcrumb-item"><Link to={`/branch-landing?bid=${selectedBranch.id}`}>{selectedBranch.BranchName}</Link></li>
                                            <li className="breadcrumb-item"><Link to='/branch-management/users'>Users</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">User Profile</li>
                                        </ol>
                                    </nav>
                                ) : null
                            }

                        </div>
                    </div>
                </div>

                <div className="row clearfix">
                    <div className="col-lg-4 col-md-12">
                        <div>
                            {userDetails != null && loggedUser != null ? (
                                <div className='card c_grid'>
                                    <div className="body text-center ribbon">
                                        <div className={`ribbon-box ${userDetails.restricted ? 'red' : 'green'}`}>{userDetails.roleName}</div>
                                        {/* <label className="img-input-label">
                                            <input type="file" id="fileInput" name="image"
                                                onChange={imageChange}
                                                accept="image/*" />
                                            <div className="circle">
                                                {userDetails.image ?

                                                    <img
                                                        src={`${userDetails.image}?${Date.now()}`}
                                                        className="personal-avatar-user " alt="avatar" />
                                                    :
                                                    <img src={require('../../assets/images/others/no-profile.jpg')}
                                                        className="personal-avatar-user " alt="avatar" />

                                                }
                                                <div className="profile-figcaption">
                                                    <img src={require('../../assets/images/others/camera-white.png')}
                                                        alt="avatar"
                                                        style={{ width: '25px', height: '25px' }} />
                                                </div>
                                            </div>
                                        </label> */}
                                        <div className="personal-image">
                                            <label className="img-input-label">
                                                <input type="file" id="fileInput" name="image"
                                                    onChange={imageChange}
                                                    accept="image/*" />
                                                <div className="personal-figure">

                                                    {selectedImage.picturePreview ?
                                                        <>
                                                            <img src={selectedImage.picturePreview}
                                                                className="personal-avatar" alt="avatar"
                                                                style={{ width: '80px', height: '80px' }} />
                                                            <div className="personal-figcaption">
                                                                <img src={require('../../assets/images/others/camera-white.png')}
                                                                    alt="avatar"
                                                                    style={{ width: '25px', height: '25px' }} />
                                                            </div>
                                                        </> : <>

                                                            {userDetails.image ?

                                                                <img
                                                                    src={`${userDetails.image}?${Date.now()}`}
                                                                    className="personal-avatar-user " alt="avatar" />
                                                                :
                                                                <img src={require('../../assets/images/others/no-profile.jpg')}
                                                                    className="personal-avatar-user " alt="avatar" />

                                                            }
                                                            <div className="personal-figcaption">
                                                                <img src={require('../../assets/images/others/camera-white.png')}
                                                                    alt="avatar"
                                                                    style={{ width: '25px', height: '25px' }} />
                                                            </div>
                                                        </>
                                                    }


                                                </div>
                                            </label>
                                            {/* <button className='btn btn-primary ml-1' style={{ borderRadius: '20px' }}><i className="fa fa-save"></i></button> */}

                                            {
                                                selectedImage.pictureAsFile !== undefined ? (
                                                    <Button loading={buttonLoader} onClick={() => updateUserProfileImage()}
                                                        className="btn btn-primary ml-1" style={{ borderRadius: '20px' }}>
                                                        <i className="fa fa-save"></i>
                                                    </Button>
                                                ) : null
                                            }
                                        </div>
                                        {/* <h6 className="mt-3 mb-0">{userDetails.employeeNumber}</h6>
                                        <h6 className="mt-2 mb-0">{userDetails.firstName} {userDetails.lastName}</h6> */}

                                        {loggedUser.userType !== 3 ? (
                                            <div className='mt-2'>Assign to <span className="badge badge-success ml-1 mb-2">{userDetails.branchName}</span></div>
                                        ) : ( null )}

                                        {/* <ul className="mt-3 list-unstyled d-flex justify-content-center">
                                            <li className={` ${userDetails.restricted ? 'table-item-btn-disabled' : 'link'}`} title='Edit'
                                                onClick={() => {
                                                    history(`/user-update?uid=${userDetails.id}`)
                                                }}
                                            >
                                                <i className="fa fa-pencil-square-o p-2"></i>
                                            </li>
                                            {
                                                loggedUser.uid === +userId ? null : (
                                                    <li className={`ml-2 ${userDetails.restricted ? 'table-item-btn-disabled' : 'link'}`} title='Remove From Branch'
                                                        onClick={() => {
                                                            removeUserFromBranch();
                                                        }}
                                                    >
                                                        <i className="fa fa-trash-o action-icon p-2"></i>
                                                    </li>
                                                )
                                            }

                                        </ul>
                                        <div className="mt-3 d-flex justify-content-center">
                                            <button className="btn btn-default btn-sm" title={`${userDetails.restricted ? 'Active' : 'Deactivate'}`}
                                                onClick={() => {
                                                    userActiveDeactive()
                                                }}
                                            > {userDetails.restricted ? 'Active' : 'Deactivate'}</button>
                                        </div> */}

                                    </div>

                                </div>
                            ) : null}


                            <div className="card">
                                <div className="header">
                                    <h2>Login History</h2>
                                </div>
                                <div style={{ height: '610px', overflowY: 'auto' }}>
                                    <div className="body">
                                        <ul className="timeline">

                                            {
                                                userLogins.length > 0 ?
                                                    <>
                                                        {
                                                            userLogins.map((item, index) =>
                                                                <li className="timeline-item" key={index}>
                                                                    <div className="timeline-info">
                                                                        <span>{item.loginDate}</span>
                                                                        <p className="text-muted mt-0 mb-0">@ {item.loginTime} - {item.logoutTime} </p>
                                                                    </div>
                                                                    <div className="timeline-marker"></div>
                                                                    <div className="timeline-content">
                                                                        <h3 className="timeline-title">
                                                                            {item.loginType === 1 ? 'Portal Login' : 'Counter Login'}
                                                                        </h3>
                                                                        <p className="text-muted mt-0 mb-0">
                                                                            {item.counterName != undefined ? item.counterName : ''}
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                            )
                                                        }
                                                    </>
                                                    :
                                                    <li className="timeline-item">
                                                        <div className="timeline-marker"></div>
                                                        <div className="timeline-content">
                                                            <p className="text-muted mt-0 mb-0">No login details yet</p>
                                                        </div>
                                                    </li>

                                            }

                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="col-lg-8 col-md-12">
                        <div style={{ height: '960px', overflowY: 'auto' }}>
                            <div className="card">
                                <div className="header">
                                    <h2>Basic Information</h2>
                                </div>
                                <ul className="list-group">

                                    {userDetails.firstName == undefined ? '' :
                                        <li className="list-group-item">
                                            <small className="text-muted">First Name: </small>
                                            {/* <p className="mb-0">{userDetails.firstName} {userDetails.lastName}</p> */}
                                            <div style={{ display: 'flex' }}>
                                                <input type="text"
                                                    value={firstName}
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                    className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    style={{ backgroundColor: 'transparent' }}
                                                />
                                                <Button loading={buttonLoader} onClick={() => updateUserData()}
                                                    className="btn btn-primary ml-1 text-right">
                                                    <i className="fa fa-pencil"></i>
                                                </Button>
                                                {/* <button
                                                    className=' btn btn-primary ml-1' style={{ cursor: 'pointer' }}
                                                    onClick={() => updateUserData()}
                                                ><i className="fa fa-pencil"></i></button> */}
                                            </div>
                                        </li>
                                    }

                                    {userDetails.lastName == undefined ? '' :
                                        <li className="list-group-item">
                                            <small className="text-muted">Last Name: </small>
                                            {/* <p className="mb-0">{userDetails.firstName} {userDetails.lastName}</p> */}
                                            <div style={{ display: 'flex' }}>
                                                <input type="text" value={lastName}
                                                    onChange={(e) => setLastName(e.target.value)}
                                                    className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    style={{ backgroundColor: 'transparent' }}
                                                />
                                                <Button loading={buttonLoader} onClick={() => updateUserData()}
                                                    className="btn btn-primary ml-1 text-right">
                                                    <i className="fa fa-pencil"></i>
                                                </Button>
                                            </div>

                                        </li>
                                    }

                                    {userDetails.contact == undefined ? '' :
                                        <li className="list-group-item">
                                            <small className="text-muted">Contact No: </small>
                                            <div style={{ display: 'flex' }}>
                                                <input type="text" value={contact}
                                                    onChange={(e) => setContact(e.target.value)}
                                                    className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    style={{ backgroundColor: 'transparent' }}
                                                />
                                                <Button loading={buttonLoader} onClick={() => updateUserData()}
                                                    className="btn btn-primary ml-1 text-right">
                                                    <i className="fa fa-pencil"></i>
                                                </Button>
                                            </div>
                                        </li>
                                    }

                                    {userDetails.employeeNumber == undefined ? '' :
                                        <li className="list-group-item">
                                            <small className="text-muted">Employee No: </small>
                                            <div style={{ display: 'flex' }}>
                                                <input type="text" value={employeeNo}
                                                    onChange={(e) => setEmployeeNo(e.target.value)}
                                                    className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    style={{ backgroundColor: 'transparent' }}
                                                />
                                                <Button loading={buttonLoader} onClick={() => updateUserData()}
                                                    className="btn btn-primary ml-1 text-right">
                                                    <i className="fa fa-pencil"></i>
                                                </Button>
                                            </div>
                                        </li>
                                    }

                                    {userDetails.gender == undefined ? '' :
                                        <li className="list-group-item">
                                            <small className="text-muted">Gender: </small>
                                            <div style={{ display: 'flex' }}>
                                                <div className="fancy-radio">
                                                    <label>
                                                        <input
                                                            name="gender"
                                                            value="male"
                                                            type="radio"
                                                            checked={gender === 'male' || gender === 'Male'} // Check if gender is 'male'
                                                            onChange={(e) => setGender(e.target.value)} // Call handleGenderChange on change
                                                        />
                                                        <span><i></i>Male</span>
                                                    </label>
                                                </div>
                                                <div className="fancy-radio">
                                                    <label>
                                                        <input
                                                            name="gender"
                                                            value="female"
                                                            type="radio"
                                                            checked={gender === 'female' || gender === 'Female'} // Check if gender is 'female'
                                                            onChange={(e) => setGender(e.target.value)} // Call handleGenderChange on change
                                                        />
                                                        <span><i></i>Female</span>
                                                    </label>
                                                </div>
                                                {/* <button className=' btn btn-primary ml-1' style={{ cursor: 'pointer' }}><i className="fa fa-pencil"></i></button> */}
                                            </div>
                                        </li>
                                    }

                                    {userDetails.userType == undefined ? '' :
                                        <li className="list-group-item">
                                            <small className="text-muted">User Type: </small>
                                            <p className="mb-0">{userDetails.userType.description}</p>
                                        </li>
                                    }

                                    {userDetails.addedDate == undefined ? '' :
                                        <li className="list-group-item">
                                            <small className="text-muted">Member Since: </small>
                                            <p className="mb-0">{userDetails.addedDate}</p>
                                        </li>
                                    }

                                    {userDetails.updatedDate == undefined ? '' :
                                        <li className="list-group-item">
                                            <small className="text-muted">Last Profile Update: </small>
                                            <p className="mb-0">{userDetails.updatedDate}</p>
                                        </li>
                                    }
                                </ul>
                            </div>

                            <div className="card">
                                <div className="header">
                                    <h2>Edit Username & Email</h2>
                                </div>
                                <ul className="list-group">
                                    <li className="list-group-item">
                                        <small className="text-muted">Username: </small>
                                        {/* <p className="mb-0">{userDetails.firstName} {userDetails.lastName}</p> */}
                                        <div style={{ display: 'flex' }}>
                                            <input type="text" value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                                className="form-control" aria-label="Small"
                                                aria-describedby="inputGroup-sizing-sm"
                                                style={{ backgroundColor: 'transparent' }}
                                            />
                                            <Button loading={buttonLoader} onClick={() => updateUsername()}
                                                className="btn btn-primary ml-1 text-right">
                                                <i className="fa fa-pencil"></i>
                                            </Button>
                                        </div>

                                    </li>
                                    <li className="list-group-item">
                                        <small className="text-muted">Email: </small>
                                        {/* <p className="mb-0">{userDetails.firstName} {userDetails.lastName}</p> */}
                                        <div style={{ display: 'flex' }}>
                                            <input type="text" value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                className="form-control" aria-label="Small"
                                                aria-describedby="inputGroup-sizing-sm"
                                                style={{ backgroundColor: 'transparent' }}
                                            />
                                            <Button loading={buttonLoader} onClick={() => updateUsername()}
                                                className="btn btn-primary ml-1 text-right">
                                                <i className="fa fa-pencil"></i>
                                            </Button>
                                        </div>

                                    </li>
                                </ul>
                            </div>

                            <div className="card">
                                <div className="header">
                                    <h2>Reset Password</h2>
                                </div>
                                <ul className="list-group">
                                    <li className="list-group-item">
                                        <small className="text-muted">Current Password: </small>
                                        {/* <p className="mb-0">{userDetails.firstName} {userDetails.lastName}</p> */}
                                        <div style={{ display: 'flex' }}>
                                            <input type={showCurrentPass ? 'text' : 'password'}
                                                value={currentInviciblePassword}
                                                onChange={(e) => setCurrentPassword(e.target.value)}
                                                className="form-control" aria-label="Small"
                                                aria-describedby="inputGroup-sizing-sm"
                                                style={{ backgroundColor: 'transparent' }}
                                            />
                                            <span className='mt-1 ml-1' style={{ cursor: 'pointer' }}
                                                onClick={() => setshowCurrentPass(!showCurrentPass)}
                                            >
                                                {
                                                    showCurrentPass ? (
                                                        <i className='fa fa-eye-slash'></i>
                                                    ) : (
                                                        <i className='fa fa-eye'></i>
                                                    )
                                                }

                                            </span>
                                        </div>

                                    </li>
                                    <li className="list-group-item">
                                        <small className="text-muted">New Password: </small>
                                        {/* <p className="mb-0">{userDetails.firstName} {userDetails.lastName}</p> */}
                                        <div style={{ display: 'flex' }}>
                                            <input type={showNewPass ? 'text' : 'password'}
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                className="form-control" aria-label="Small"
                                                aria-describedby="inputGroup-sizing-sm"
                                                style={{ backgroundColor: 'transparent' }}
                                            />
                                            <span className='mt-1 ml-1' style={{ cursor: 'pointer' }}
                                                onClick={() => setshowNewPass(!showNewPass)}
                                            >
                                                {
                                                    showNewPass ? (
                                                        <i className='fa fa-eye-slash'></i>
                                                    ) : (
                                                        <i className='fa fa-eye'></i>
                                                    )
                                                }

                                            </span>
                                        </div>

                                    </li>
                                    <li className="list-group-item">
                                        <small className="text-muted">Repeat Password: </small>
                                        {/* <p className="mb-0">{userDetails.firstName} {userDetails.lastName}</p> */}
                                        <div style={{ display: 'flex' }}>
                                            <input type={showRepeatPass ? 'text' : 'password'}
                                                value={repeatPassword}
                                                onChange={(e) => setRepeatPassword(e.target.value)}
                                                className="form-control" aria-label="Small"
                                                aria-describedby="inputGroup-sizing-sm"
                                                style={{ backgroundColor: 'transparent' }}
                                            />
                                            <span className='mt-1 ml-1' style={{ cursor: 'pointer' }}
                                                onClick={() => setshowRepeatPass(!showRepeatPass)}
                                            >
                                                {
                                                    showRepeatPass ? (
                                                        <i className='fa fa-eye-slash'></i>
                                                    ) : (
                                                        <i className='fa fa-eye'></i>
                                                    )
                                                }

                                            </span>
                                            <Button loading={buttonLoader} type="submit"
                                                className="btn btn-primary ml-1 text-right"
                                                onClick={() => {
                                                    handlePasswordReset();
                                                }}
                                            ><i className="fa fa-pencil"></i></Button>
                                        </div>

                                    </li>
                                </ul>
                            </div>

                            <div className="card">
                                <div className="header">
                                    <h2>User Permissions - <span style={{ color: 'rgb(23, 194, 215)' }}>{userDetails.roleName}</span></h2>

                                </div>
                                <div className="body">
                                    <div className="row clearfix">
                                        <div className="col-lg-12">
                                            {userRoleFeatures.length > 0 ?
                                                <>
                                                    {userRoleFeatures.map((item, index) =>
                                                        <span className="badge badge-secondary" key={index}>{item.feature}</span>
                                                    )
                                                    }
                                                </>
                                                :
                                                <span className="badge badge-secondary">No role assigned</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {
                                userType.description === 'Teller' ? (
                                    <>
                                        <div className="card">
                                            <div className="header">
                                                <h2>User Skills</h2>
                                            </div>
                                            <div className="body">
                                                <div className="row clearfix">
                                                    <div className="col-lg-12">

                                                        {userSkillList.length > 0 ?

                                                            userSkillList.map((usl, i) => (
                                                                <>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={renderTooltip(usl.lang)}
                                                                    >
                                                                        <span className="badge badge-secondary user-skill-badge" >
                                                                            {usl.skillName}
                                                                            <div className='skill-level-span'>
                                                                                {usl.skillLevel}
                                                                            </div>
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </>
                                                            )
                                                            ) : (
                                                                <span className="badge badge-secondary">No data</span>
                                                            )
                                                        }

                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="card">
                                            <div className="header">
                                                <h2>Transfer Category</h2>
                                            </div>
                                            <div className="body">
                                                <div className="row clearfix">
                                                    <div className="col-lg-12">
                                                        {userCategoryList.length > 0 ?

                                                            userCategoryList.map((categories, i) => (
                                                                <>
                                                                    <span
                                                                        className="badge badge-secondary" >{categories.category}
                                                                    </span>
                                                                </>

                                                            )
                                                            ) : (
                                                                <p className="mb-0">No transfer category assigned</p>
                                                            )
                                                        }


                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </>

                                ) : null
                            }


                            {
                                userType.description === 'User' ? (

                                    <div className="card">
                                        <div className="header">
                                            <h2>Notification Accessbility</h2>
                                        </div>
                                        <div className="body">
                                            <div className="row clearfix">


                                                <div className="col-lg-11">

                                                    {allnotificationAccessibility.length > 0 ?

                                                        allnotificationAccessibility.map((accessibility, i) => (
                                                            <>
                                                                <span
                                                                    className="badge badge-secondary" >{accessibility.typeName}
                                                                </span>
                                                            </>

                                                        )
                                                        ) : (
                                                            <p className="mb-0"> No Assigned Accessibility</p>
                                                        )
                                                    }
                                                </div>
                                                <div className="col-lg-1">
                                                    <button type="button" className="btn btn-sm btn-default" title=""
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        onClick={() => {
                                                            setShowAssignNotificationAccessibility(true);
                                                        }}
                                                    ><i className="fa fa-pencil"> </i></button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                ) : null
                            }


                            {/* {userDetails.callingPadProfileBean !== undefined ?
                            

                            :

                            ''
                        } */}

                            {loggedUser.userType !== 3 ? (
                                <div className="card">
                                    <div className="header">
                                        <h2>TSU Profile</h2>
                                    </div>

                                    <div className="body">
                                        <div className="row clearfix">


                                            <div className="col-lg-12">

                                                {allTsuProfiles.length > 0 ?

                                                    allTsuProfiles.map((item, i) => (
                                                        <>
                                                            {
                                                                item.id === selectedTsuProfile ? (
                                                                    <span onClick={() => updateTsuProfile(item.id)}
                                                                        className="badge badge-secondary" style={{ cursor: 'pointer' }}>{item.name}
                                                                    </span>
                                                                ) : (
                                                                    <span onClick={() => updateTsuProfile(item.id)}
                                                                        className="badge badge-disable" style={{ cursor: 'pointer' }}>{item.name}
                                                                    </span>
                                                                )
                                                            }
                                                        </>

                                                    )
                                                    ) : (
                                                        <p className="mb-0"> No Assigned Accessibility</p>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>



                                </div>
                            ) : (
                                null
                            )}

                            {
                                userType.description === 'User' && selectedTsuProfile != null ? (

                                    <div className="card">
                                        <div className="header">
                                            <h2>User Skills</h2>
                                        </div>
                                        <div className="body">
                                            <div className="row clearfix">
                                                <div className="col-lg-11">

                                                    {userSkillList.length > 0 ?

                                                        userSkillList.map((usl, i) => (
                                                            <>
                                                                {usl.disable === false ? (
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={renderTooltip(usl.lang)}
                                                                    >
                                                                        <span className="badge badge-secondary user-skill-badge"
                                                                            onClick={() => disableUserSkills(usl.skillId)}
                                                                        >
                                                                            {usl.skillName}
                                                                            <div className='skill-level-span'>
                                                                                {usl.skillLevel}
                                                                            </div>
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                ) : (
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={renderTooltip(usl.lang)}
                                                                    >
                                                                        <span className="badge badge-default user-skill-badge"
                                                                            onClick={() => disableUserSkills(usl.skillId)}
                                                                        >
                                                                            {usl.skillName}
                                                                            <div className='skill-disable-span'>
                                                                                {usl.skillLevel}
                                                                            </div>
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                )}
                                                            </>
                                                        )
                                                        ) : (
                                                            <p className='mb-0'>No skills assigned</p>
                                                        )
                                                    }

                                                </div>

                                                <div className="col-lg-1">
                                                    <button type="button" className="btn btn-sm btn-default" title=""
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        onClick={() => {
                                                            setShowAssignUserSkillsModal(true);

                                                        }}
                                                    ><i className="fa fa-pencil"> </i></button>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                ) : null
                            }
                        </div>
                    </div>
                </div>
            </div>

            <AssignUserSkillsModal setShowAssignUserSkillsModal={setShowAssignUserSkillsModal} showAssignUserSkillsModal={showAssignUserSkillsModal}
                getSkillsByUserId={getSkillsByUserId} userSkillList={userSkillList} userId={userId} />

            <AssignTransferCategoryModal userCategoryList={userCategoryList} getTellerTransferCategories={getTellerTransferCategories} />

            <AssignNotificationAccessibilityModal
                showAssignNotificationAccessibility={showAssignNotificationAccessibility}
                setShowAssignNotificationAccessibility={setShowAssignNotificationAccessibility}
                userId={userId} getassignedaccesibiltiesbyuser={getassignedaccesibiltiesbyuser} userBid={userBranch} />
        </>

    );

}
export default UserProfile
