import React, { useState, useEffect } from 'react';
import AddSmsTemplateModal from "../ModalImpl/AddSmsTemplateModal";
import toast, { Toaster } from "react-hot-toast";
import axios from 'axios';
import Swal from "sweetalert2";

const SmsTemplate = () => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [ShowAddSmsModal, setShowAddSmsModal] = useState(false);
    const [modalType, setModalType] = useState(); // 0-add, 1-edit

    const [allSmsTemplatesList, setAllSmsTemplatesList] = useState('');
    const [selectedSmsTemplate, setSelectedSmsTemplate] = useState({});

    const [allBranchesList, setAllBranchesList] = useState([]);
    const [branchDropDValue, setBranchDropDValue] = useState(null);
    const [selectedBranchId, setSelectedBranchId] = useState(null)

    //call getallsmstemplate - useeffect
    useEffect(() => {
        // if (token !== null && userDetails !== null) {
        //     if (baseUrl.length > 0 && token.length > 0) {
        //         getallsmstemplate();

        //     }
        // }
        if (token !== null && userDetails !== null && selectedBranchId !== null) {
            getallsmstemplateByBranch();
        }
    }, [SmsTemplate, selectedBranchId])


    useEffect(() => {
        axios({
            method: 'GET',
            url: `${baseUrl}/branch/get-branches/customer/${userDetails.cid}`,
            headers: {
                Authorization: localStorage.getItem('TS_GEN_TOKEN')
            },
        }).then((res) => {

            const data = res.data.data;
            setSelectedBranchId(data[0].id);
            console.log(data);
            setAllBranchesList(data);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }, [])


    const getallsmstemplateByBranch = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/alert/sms-template/get-list/branch/${selectedBranchId}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            let data = res.data.data;
            setAllSmsTemplatesList(data);

            if (Object.keys(selectedSmsTemplate).length > 0) {
                data.forEach((item, i) => {
                    if (selectedSmsTemplate.id === item.id) {
                        setSelectedSmsTemplate(item);
                    }
                })
            }


        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //deleteSMStemplate - function
    const deleteSMStemplate = () => {

        Swal.fire({
            position: 'center',
            text: 'Do you want to delete this SMS template?',
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            width: '400px',

        }).then((result) => {
            if (result.value == true) {

                axios({
                    method: 'POST',
                    url: `${baseUrl}/alert/sms-template/delete/template/${selectedSmsTemplate.id}`,
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {

                    toast.remove();
                    toast.success('Deleted Successfully ', {
                        position: 'bottom-center',
                    });

                    setSelectedSmsTemplate({})
                    // getallsmstemplate();
                    getallsmstemplateByBranch();

                }).catch((error) => {
                    console.log(error);

                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                })
            }

        })

    };

    const handleSelectBranch = (branch) => {
        setSelectedBranchId(branch.id)
    }

    return (
        <>
            <div className="container-fluid">
                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12 ">
                            <h1>SMS Templates Management</h1>
                        </div>
                        <div className="col-md-6 col-sm-12 text-right hidden-xs">
                            <div className="row clearfix ml-5">
                                <div className="col-md-6 col-sm-12 text-right">
                                    <select className="custom-select modal-select-field mr-2 select-branch-dropdown" id="inputGroupSelect01"
                                        value={branchDropDValue}
                                        onChange={(e) => {
                                            setBranchDropDValue(e.target.value)
                                            handleSelectBranch(allBranchesList[e.target.value]);
                                        }}
                                    >
                                        {allBranchesList.map((item, index) =>
                                            <option value={index} key={index}>
                                                {item.name}
                                            </option>
                                        )}

                                    </select>
                                </div>
                                <div className="btn btn-sm btn-primary mr-1" title="Add New Sms Template"
                                    onClick={() => {
                                        setModalType(0);
                                        setShowAddSmsModal(true);
                                    }}
                                >Add New </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="row clearfix">
                    <div className="col-lg-3 col-md-3">
                        <ul className="list-group mb-4 right-custom-nav">
                            {
                                allSmsTemplatesList.length > 0 ? (
                                    allSmsTemplatesList.map((sms, i) => (
                                        <li className={sms.id === selectedSmsTemplate.id ? "list-group-item d-flex justify-content-between align-items-center country-select active" : "list-group-item d-flex justify-content-between align-items-center country-select"}
                                            key={i}
                                            onClick={() => {
                                                setSelectedSmsTemplate(sms);
                                            }}
                                        >{sms.name}

                                        </li>

                                    ))
                                ) : (
                                    <li className="list-group-item d-flex justify-content-between align-items-center">No Data</li>
                                )
                            }
                        </ul>
                    </div>


                    <div className="col-lg-9 col-md-9">

                        <div className="card">

                            {
                                allSmsTemplatesList.length > 0 ? (
                                    <>
                                        {
                                            Object.keys(selectedSmsTemplate).length > 0 ? (
                                                <div className="body top_counter">
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='vertically-align-center-flex m-b-15'>
                                                                <div>
                                                                    SMS type :
                                                        <div className='badge badge-warning sms-template-badge '> {selectedSmsTemplate.typeName}</div>
                                                    </div>
                                                    <div className=''>

                                                                    <button type="button" className="btn btn-sm btn-default"
                                                                        title="Edit"
                                                                        data-toggle="tooltip"
                                                                        data-placement="top"
                                                                        onClick={() => {
                                                                            setModalType(1);
                                                                            setShowAddSmsModal(true);
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-pencil-square-o"> </i>
                                                                    </button>
                                                                    &nbsp;
                                                                    <button type="button" className="btn btn-sm btn-default "
                                                                        title="Delete"
                                                                        data-toggle="tooltip" data-placement="top"
                                                                        onClick={() => {
                                                                            deleteSMStemplate();
                                                                        }}
                                                                    ><i className="icon-trash"> </i>
                                                                    </button>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <p>{selectedSmsTemplate.content}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) :
                                                <p>Select a template</p>
                                        }
                                    </>
                                ) : (null)
                            }
                        </div>
                    </div>

                </div>
            </div>

            <AddSmsTemplateModal
                setShowAddSmsModal={setShowAddSmsModal}
                ShowAddSmsModal={ShowAddSmsModal}
                modalType={modalType}
                selectedSmsTemplate={selectedSmsTemplate}
                setSelectedSmsTemplate={setSelectedSmsTemplate}
                getallsmstemplateByBranch={getallsmstemplateByBranch}
                selectedBranchId={selectedBranchId}
            />
        </>
    );

}
export default SmsTemplate
