import React, {useState} from "react";
import {Link} from "react-router-dom";
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import {Tabs, Tab, Row, Col, Nav} from 'react-bootstrap';


const showSecond = true;
const str = showSecond ? 'HH:mm:ss' : 'HH:mm';
const AddWorkflowModal = ({showWorkflowModal, setShowWorkflowModal}) => {

    // for time picker
    var date = "2022-06-02";
    var time = "00:00:00";
    var timeAndDate = moment(date + ' ' + time);
    const [timePickerDefaultValue, setTimePickerDefaultValue] = useState(timeAndDate);

    const closeModal = () => {
        setShowWorkflowModal(false);
    };

    function onChange(value) {
        console.log('******* ', value && value.format(str));
    }

    return (
        <>
            {showWorkflowModal ?

                <div className={`modal fade  d-block show modal-container`} id="exampleModal">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Add Workflow</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                        onClick={() => {
                                            closeModal()
                                        }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <form className='counter-add-form mt-3'>

                                    <div className="row clearfix">
                                        <div className="col-md-6">
                                            <div className="input-group mb-3">

                                                <p className="input-field-side-text1"> Name : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                       aria-describedby="inputGroup-sizing-sm"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <button type="button" className="btn btn-primary"
                                            ><i className="fa fa-plus" aria-hidden="true"></i></button>
                                        </div>

                                    </div>

                                    <div className="row clearfix">


                                        <div className="col-md-4">
                                            <Tab.Container id="" defaultActiveKey="first">
                                                {/*<Row>*/}

                                                <Nav variant="pills" className="flex-column">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="first" className="form_wizard_tab mb-1">1.
                                                            Account Opening</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="second" className="form_wizard_tab mb-1">2.
                                                            Deposit</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="third" className="form_wizard_tab mb-1">3.
                                                            Bill Payments </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="forth" className="form_wizard_tab mb-1">4.
                                                            Category </Nav.Link>
                                                    </Nav.Item>
                                                </Nav>

                                            </Tab.Container>

                                        </div>
                                        <div className="col-md-8">
                                            <div className="input-group mb-3">

                                                <p className="input-field-side-text"> Category : </p>
                                                <select className="custom-select modal-select-field"
                                                        id="inputGroupSelect01">
                                                    <option defaultValue>Choose...</option>
                                                    <option defaultValue="1">Report</option>
                                                    <option defaultValue="2">Two</option>
                                                    <option defaultValue="3">Three</option>
                                                </select>
                                            </div>

                                            <div className="input-group mb-3">
                                                <p className="input-field-side-text">Sms Template 1 : </p>
                                                <select className="custom-select modal-select-field"
                                                        id="inputGroupSelect01">
                                                    <option defaultValue>Choose...</option>
                                                    <option defaultValue="1"> 1</option>
                                                    <option defaultValue="2"> 2</option>
                                                    <option defaultValue="3"> 3</option>
                                                </select>
                                            </div>

                                            <div className="input-group mb-3">
                                                <p className="input-field-side-text">Sms Template 1 : </p>
                                                <select className="custom-select modal-select-field"
                                                        id="inputGroupSelect01">
                                                    <option defaultValue>Choose...</option>
                                                    <option defaultValue="1">Ts Template 1</option>
                                                    <option defaultValue="2">Ts Template 2</option>
                                                    <option defaultValue="3">Ts Template 3</option>
                                                </select>
                                            </div>

                                            <div className="input-group mb-3">
                                                <p className="input-field-side-text">Mandatary Time
                                                    : </p>
                                                <TimePicker
                                                    showSecond={showSecond}
                                                    placeholder="HH:mm:ss"
                                                    className="custom-select modal-select-field"
                                                    defaultOpenValue={timePickerDefaultValue}
                                                    onChange={onChange}
                                                />


                                            </div>

                                            <div className="row clearfix">
                                                <div className="col-md-6">
                                                    <div className="input-group mb-3">
                                                        <p className="input-field-side-text">Reject
                                                            : </p>
                                                        <label className="switch">
                                                            <input type="checkbox"/>
                                                            <span className="slider round"></span>
                                                        </label>


                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="input-group mb-3">
                                                        <p className="input-field-side-text">Back To Office
                                                            : </p>
                                                        <label className="switch">
                                                            <input type="checkbox"/>
                                                            <span className="slider round"></span>
                                                        </label>


                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>

                                </form>

                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal"
                                        onClick={() => {
                                            closeModal()
                                        }}
                                >Cancel
                                </button>
                                <button type="button" className="btn btn-primary"
                                    // onClick={addTeller}
                                >Add Workflow
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <></>
            }


        </>
    )

}
export default AddWorkflowModal;