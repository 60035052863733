import React, { Component } from 'react';
import ReactApexChart from "react-apexcharts";

class OperatorStatusCount extends Component { 

    constructor(props) {
        super(props);
 
        this.state = {
            h:((this.props.ticketCount.length*50)+100)+"px",
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: false
                    }

                },
                stroke: {
                    width: 2
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        borderRadius: 7,
                        borderRadiusApplication: 'around',
                        borderRadiusWhenStacked: 'last',
                        columnWidth: '70%',
                        barHeight: '70%',
                        distributed: false,
                        rangeBarOverlap: true,
                        rangeBarGroupRows: false,
                        colors: {
                            ranges: [{
                                from: 0,
                                to: 0,
                                color: undefined
                            }],
                            backgroundBarColors: [],
                            backgroundBarOpacity: 1,
                            backgroundBarRadius: 0,
                        },
                        dataLabels: {
                            position: 'top',
                            maxItems: 100,
                            hideOverflowingLabels: false,
                            orientation: 'horizontal',
                            total: {
                              enabled: false,
                              formatter: undefined,
                              offsetX: 0,
                              offsetY: 0,
                              style: {
                                color: '#373d3f',
                                fontSize: '12px',
                                fontFamily: undefined,
                                fontWeight: 600
                              }
                            }
                        }
                    }
                },
                colors: ['#4D4DFF'],
                
                grid : {
                    show: false
                },

                xaxis: {

                    title: {
                        text: 'Time',
                    },

                 

                    axisTicks: { show: false },
                    axisBorder: { show: false },
                    categories: this.props.hourInt

                },

                yaxis: [{
                    title: {
                        text: 'Ticket Count',
                    }
                }],

               
            },
            series: [
                {
                    name: "Income",
                    type: 'column',
                    data: this.props.ticketCount
                }
               
            ], 
        };
    }

    componentDidUpdate(prevProps,prevState) {
        if(prevProps.chartValue !== this.props.chartValue) {
            this.setState({
                h:((this.props.ticketCount.length*50)+100)+"px",
                options: {
                    chart: {
                        type: 'bar',
                        height: 350,
                        stacked: true,
                        toolbar: {
                            show: false
                        }
    
                    },
                    stroke: {
                        width: 2
                    },
                    plotOptions: {

                        bar: {
                            horizontal: true,
                            borderRadius: 7,
                            borderRadiusApplication: 'around',
                            borderRadiusWhenStacked: 'last',
                            columnWidth: 150,
                            barHeight: '70%',
                            distributed: false,
                            rangeBarOverlap: true,
                            rangeBarGroupRows: false,
                            colors: {
                                ranges: [{
                                    from: 0,
                                    to: 0,
                                    color: undefined
                                }],
                                backgroundBarColors: [],
                                backgroundBarOpacity: 1,
                                backgroundBarRadius: 0,
                            },
                            dataLabels: {
                                position: 'top',
                                maxItems: 100,
                                hideOverflowingLabels: false,
                                orientation: 'horizontal',
                                total: {
                                  enabled: false,
                                  formatter: undefined,
                                  offsetX: 0,
                                  offsetY: 0,
                                  style: {
                                    color: '#373d3f',
                                    fontSize: '12px',
                                    fontFamily: undefined,
                                    fontWeight: 600
                                  }
                                }
                            }
                        }
                    },
                    fill: {
                        colors: ['#4D4DFF']
                    },
                    yaxis: [{
                        title: {
                            text: 'Ticket Count',
                        },
    
                    }], 
                    xaxis: {

                        title: {
                            text: 'Time',
                        },
                        axisTicks: { show: false },
                        axisBorder: { show: false },
                        categories: this.props.hourInt
    
                    },
                    legend: {
                        show: false
                    },
                    grid: {
                        bottom: 20,
                        top: 0,
                        left: 6
                    }
                },
                series: [
                    {
                        name: "Income",
                        type: 'column',
                        data: this.props.ticketCount
                    }
                   
                ],
            });
        }
    }

    render() {
        return (
            <div>
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="bar"
                    height={ this.state.h}
                    width="600px"
                />
            </div>
        );
    }
}

export default OperatorStatusCount;
