import React, { useState, useEffect } from 'react';
import { MDBDataTable } from 'mdbreact';
import AddUserRoleModal from '../ModalImpl/AddUserRoleModal';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

const UserRolesManagement = () => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [showAddUserRoleModal, setShowAddUserRoleModal] = useState(false);
    const [modalType, setmodalType] = useState(); // 0-add, 1-edit
    const [selectedRow, setSelectedRow] = useState({});

    const [allRoleList, setAllRoleList] = useState([]);
    const [searchRoleText, setSearchRoleText] = useState('');
    const [allRoleForRender, setAllRoleForRender] = useState([]);
    const [allRoleTableBody, setAllRoleTableBody] = useState([]);


    // tableData
    const tableData = {
        columns: [
            {
                label: '#',
                field: 'index',
                width: 10
            },
            {
                label: 'User Role',
                field: 'role',
                width: 200,
            },
            {
                label: 'Permission',
                field: 'rolesPermissions',
            },
            {
                label: 'Action',
                field: 'action',
                width: 0,
            }
        ],
        rows: allRoleTableBody
    }

    // call getRollWithPermissions - useEffect
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            if (baseUrl.length > 0 && token.length > 0 && Object.keys(userDetails).length > 0) {
                getRollWithPermissions();
            }
        }
    }, [UserRolesManagement])

    // addActionColumn - useEffect
    useEffect(() => {
        let postsArray = JSON.parse(JSON.stringify(allRoleList));
        let newArray = [];
        postsArray.map((item, index) => {

            item.index = (
                <div>
                    {index + 1}
                </div>
            )

            item.rolesPermissions = (
                <>
                    {item.roleFeatures.map((permission, i) => (
                        <span className="badge badge-primary" key={i}>{permission.id}</span>
                    ))
                    }
                </>
            )

            item.action = (
                <>
                    <button type="button" className="btn btn-sm btn-default" title="Edit" data-toggle="tooltip"
                        data-placement="top"
                        onClick={() => {

                            setmodalType(1);
                            setShowAddUserRoleModal(true);
                            setSelectedRow(item);
                        }}
                    ><i className="fa fa-pencil-square-o"></i></button>
                </>
            )

            newArray.push(item);
        });
        setAllRoleForRender(newArray);
        setAllRoleTableBody(newArray);
    }, [allRoleList]);

    // getRollWithPermissions - function
    const getRollWithPermissions = () => {

        if (userDetails.userType !== 3) return;

        axios({
            method: 'GET',
            url: `${baseUrl}/user-role/get-all-roles-with-permissions/${userDetails.cid}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let data = res.data.data;
            setAllRoleList(data);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //search in table filter - function
    const filter = (e) => {
        const keyword = e.target.value;

        if (keyword !== '') {
            const results = allRoleForRender.filter((cat) => {
                return cat.role.toString().toLowerCase().includes(keyword.toLowerCase());
            });
            setAllRoleTableBody(results);
        } else {
            setAllRoleTableBody(allRoleForRender);
        }

        setSearchRoleText(keyword);
    };


    return (
        <>
            <div><Toaster /></div>
            <div className="container-fluid">

                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h1>User Roles Management</h1>
                        </div>
                        {userDetails.userType === 3 ? (
                            <div className="col-md-6 col-sm-12 text-right hidden-xs">
                                <div className="btn btn-sm btn-primary mr-1" title="Add New Category"
                                    onClick={() => {
                                        setmodalType(0);
                                        setShowAddUserRoleModal(true);
                                    }}
                                >Add New
                                </div>
                            </div>
                        ) : (
                            null
                        )}
                    </div>
                </div>

                {userDetails.userType === 3 ? (
                    <>
                        <div className="row clearfix">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="body">
                                        <div className="row">

                                            <div className="col-lg-3 col-md-6">
                                                <div className="input-group header-counter-center">
                                                    <h5 className="mb-0">
                                                        {
                                                            allRoleTableBody !== null && allRoleList !== null ?
                                                                <>
                                                                    {
                                                                        allRoleTableBody.length !== null && allRoleList.length !== null ?
                                                                            <>

                                                                                {searchRoleText.length > 0 ? `${allRoleTableBody.length} / ` : ''}
                                                                                <small className="text-muted"> {allRoleList.length}</small>
                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }

                                                                </>
                                                                :
                                                                <></>
                                                        }
                                                    </h5>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-group">
                                                </div>
                                            </div>

                                            <div className="col-lg-5 col-md-6 ">
                                                <div className="input-group">
                                                    <input
                                                        value={searchRoleText}
                                                        onChange={filter}
                                                        type="text" className="form-control"
                                                        placeholder="Search here..."
                                                    />

                                                    <div className="input-group-append">
                                                        <button className="btn btn-outline-secondary" type="button">
                                                            <i className="fa fa-search" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="body">
                                        <div className="table-responsive">

                                            <MDBDataTable
                                                noBottomColumns
                                                className="table table-hover js-basic-example dataTable table-custom spacing5 action-col-align categories-table stop-overflow-x stop-table-col-overflow "
                                                displayEntries={false}
                                                searching={false}
                                                paging
                                                pagesAmount={15}
                                                data={tableData}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="card">
                        <div className="body">
                            <div className="row">

                                <div className="col-lg-3 col-md-6">
                                    <div className="input-group header-counter-center">
                                        <h6 className="mb-0">
                                            <small className="text-muted">You have no permissions for see these data</small>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {userDetails.userType === 3 ? (
                <AddUserRoleModal
                    showAddUserRoleModal={showAddUserRoleModal}
                    setShowAddUserRoleModal={setShowAddUserRoleModal}
                    modalType={modalType}
                    getRollWithPermissions={getRollWithPermissions}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                />
            ) : (null)}

        </>
    );
}
export default UserRolesManagement
