import React, { useState, useEffect } from 'react'
import 'rc-time-picker/assets/index.css';
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Button from 'react-bootstrap-button-loader';
import moment from 'moment';
import TimePicker from 'rc-time-picker';

let today = new Date();
const showSecond = true;
const str = showSecond ? 'HH:mm:ss' : 'HH:mm';
const timeFormat = 'HH:mm:ss';


const AddCategoryMasterModal = ({ showAddCategoryMasterModal, setShowAddCategoryMasterModal, getAllCategories, modalType, selectedRow, setSelectedRow, dataTable }) => {


    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [catCode, setCatCode] = useState('');
    const [catName, setCatName] = useState('');
    const [shortCatName, setShortCatName] = useState('');
    const [wtException, setWTException] = useState('');
    const [stException, setSTException] = useState('');
    const [startTicket, setStartTicket] = useState('');
    const [endtTicket, setEndTicket] = useState('');
    const [queueSize, setqueueSize] = useState('')
    const [crmCategory, setCrmCategory] = useState('');
    const [crmSubCategory, setCrmSubCategory] = useState('')
    const [serviceType, setServiceType] = useState('')
    const [vip, setvip] = useState(false);

    const [refresh, setRefresh] = useState(1);
    const [buttonLoader, setButtonLoader] = useState(false);


    // for time picker
    var date = "2022-06-02";
    var time = "00:00:00";
    var timeAndDate = moment(date + ' ' + time);
    const [timePickerDefaultValue, setTimePickerDefaultValue] = useState(timeAndDate);



    // if view or edit - useEffect
    useEffect(() => {
        if (modalType === 1 || modalType === 2) {
            if (Object.keys(selectedRow).length > 0) {
                getCategoryById();
            }
        }
    }, [selectedRow]);



    // add new category - function
    const addCategoryMaster = () => {
        setButtonLoader(true);

        let isValidate = validate();

        if (isValidate) {

            axios({
                method: 'POST',
                url: `${baseUrl}/category-master/add`,
                headers: {
                    Authorization: token
                },
                data: {
                    "customer": userDetails.cid,
                    "category": catName,
                    "noRangeMin": startTicket,
                    "noRangeMax": endtTicket,
                    "categoryCode": catCode,
                    "waitTime": wtException,
                    "serviceTime": stException,
                    "shortCatName": shortCatName,
                    "queueSize": queueSize,
                    "vip": vip,
                    "crmCategory": crmCategory,
                    "crmSubCategory": crmSubCategory,
                    "serviceType": serviceType
                }
            }).then((response) => {
                console.log(response);

                toast.remove();
                toast.success('Category successfully added!', {
                    position: 'bottom-center',
                });
                setButtonLoader(false);
                closeModal();
                getAllCategories(0, null, null, dataTable.size);

            }).catch((error) => {
                console.log(error);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                    setButtonLoader(false);
                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                    setButtonLoader(false);
                }
            })

        }
    };

    // get master category by id - function
    const getCategoryById = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/category-master/get-category-by-id/${selectedRow.id}`,
            headers: {
                Authorization: token
            },
        }).then((resp) => {

            let data = resp.data.data;

            setCatName(data.category);
            setCatCode(data.categoryCode);
            setShortCatName(data.shortCatName);
            setStartTicket(data.noRangeMin);
            setEndTicket(data.noRangeMax);
            setWTException(data.waitTime);
            setSTException(data.serviceTime);
            setvip(data.vip);
            setqueueSize(data.queueSize);
            setCrmCategory(data.crmCategory);
            setCrmSubCategory(data.crmSubCategory);
            setServiceType(data.serviceType);

            setRefresh(refresh + 1);


        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // update  category master - function
    const updateCategoryMaster = () => {
        setButtonLoader(true);

        axios({
            method: 'POST',
            url: `${baseUrl}/category-master/update`,
            headers: {
                Authorization: token
            },
            data: {
                "id": selectedRow.id,
                "customer": {
                    "id": userDetails.cid
                },
                "category": catName,
                "noRangeMin": startTicket,
                "noRangeMax": endtTicket,
                "categoryCode": catCode,
                "waitTime": wtException,
                "serviceTime": stException,
                "shortCatName": shortCatName,
                "queueSize": queueSize,
                "vip": vip,
                "crmCategory": crmCategory,
                "crmSubCategory": crmSubCategory,
                "serviceType": serviceType
            }
        }).then((res) => {
            console.log(res.data.data);
            toast.remove();
            toast.success('Successfully updated', {
                position: 'bottom-center',
            });

            getAllCategories(dataTable.page, dataTable.order, dataTable.search, dataTable.size);
            closeModal();
            setButtonLoader(false);


        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
                setButtonLoader(false);
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
                setButtonLoader(false);
            }
        })

    };


    // validate data - function
    const validate = () => {

        if (catCode.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Category code empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (catName.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Category name empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (shortCatName.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Short category name empty', {
                position: 'bottom-center',
            });
            return false;

        } else if (wtException.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Wait time exception empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (stException.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Service time exception empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (startTicket.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Start ticket empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (endtTicket.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('End ticket empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (queueSize.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error(' Queue Size empty', {
                position: 'bottom-center',
            });
            return false;
        } else {
            return true;
        }

    }

    // closeModal - function
    const closeModal = () => {
        setCatName('');
        setCatCode('');
        setShortCatName('');
        setStartTicket('');
        setEndTicket('');
        setqueueSize('')
        setvip('');
        setWTException('');
        setSTException('');
        setCrmCategory('')
        setCrmSubCategory('')
        setServiceType('')

        if (modalType === 1 || modalType === 2) setSelectedRow({})

        setShowAddCategoryMasterModal(false);
    };


    return (
        <>
            <div><Toaster /></div>
            {showAddCategoryMasterModal ? (
                <div className={`modal fade d-block show modal-container`} id="category-modal">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    {modalType === 0 ? (<>Add New Category</>) : modalType === 1 ? <>Edit Category</> : <>View Category</>}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        closeModal()
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className={`modal-body ${modalType === 2 ? 'no-click' : ''}`}>


                                <form className='category-add-form mt-3'>

                                    <div className="container-fluid">

                                        <div className="row clearfix">

                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group input-group-sm mb-3">
                                                    <p className="input-field-side-text">Category Code : </p>

                                                    <input type="text" className="form-control disable disabled"
                                                        aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        readOnly={modalType === 2 ? true : false}
                                                        value={catCode}
                                                        onChange={(e) => {
                                                            setCatCode(e.target.value);
                                                        }} />
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group input-group-sm mb-3">
                                                    <p className="input-field-side-text">Category Name : </p>
                                                    <input type="text" className="form-control"
                                                        aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        readOnly={modalType === 2 ? true : false}
                                                        value={catName}
                                                        onChange={(e) => {
                                                            setCatName(e.target.value);
                                                        }} />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row clearfix">

                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group input-group-sm mb-3">
                                                    <p className="input-field-side-text">Short Category Name : </p>
                                                    <input type="text" className="form-control"
                                                        aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        readOnly={modalType === 2 ? true : false}
                                                        value={shortCatName}
                                                        onChange={(e) => {
                                                            setShortCatName(e.target.value)
                                                        }} />
                                                </div>
                                            </div>


                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group input-group-sm mb-3">
                                                    <p className="input-field-side-text">VIP </p>
                                                    <label
                                                        className="switch custom-swtch">
                                                        <input type="checkbox"
                                                            defaultchecked={selectedRow.vip === true}
                                                            disabled={modalType === 2 ? true : false}
                                                            checked={vip ? true : false}
                                                            onChange={(e) => {
                                                                setvip(prev => !prev);
                                                            }} />
                                                        <span className="slider"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row clearfix">

                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group mb-3">
                                                    <p className="input-field-side-text">Wait Time Exception : </p>

                                                    <TimePicker
                                                        value={wtException.length > 0 ? moment(wtException, timeFormat) : ''}
                                                        showSecond={true}
                                                        placeholder="HH:mm:ss"
                                                        className="custom-select modal-select-field"
                                                        defaultOpenValue={timePickerDefaultValue}
                                                        onChange={(value) => {
                                                            if (value === null) {
                                                                setWTException('');
                                                            } else {
                                                                setWTException(value.format("HH:mm:ss"));
                                                            }
                                                        }}
                                                    />

                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group mb-3">
                                                    <p className="input-field-side-text">Service Time Exception
                                                        : </p>

                                                    <TimePicker
                                                        value={stException.length > 0 ? moment(stException, timeFormat) : ''}
                                                        showSecond={true}
                                                        placeholder="HH:mm:ss"
                                                        className="custom-select modal-select-field"
                                                        defaultOpenValue={timePickerDefaultValue}
                                                        onChange={(value) => {
                                                            if (value === null) {
                                                                setSTException('');
                                                            } else {
                                                                setSTException(value.format("HH:mm:ss"));
                                                            }
                                                        }}
                                                    />

                                                </div>
                                            </div>

                                        </div>

                                        <div className="row clearfix">

                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group input-group-sm mb-3">
                                                    <p className="input-field-side-text">Default Start Ticket : </p>

                                                    <input type="number" className="form-control" aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        value={startTicket}
                                                        onChange={(e) => {
                                                            setStartTicket(e.target.value);
                                                        }} />
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group input-group-sm mb-3">
                                                    <p className="input-field-side-text">Default End Ticket : </p>

                                                    <input type="number" className="form-control" aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        value={endtTicket}
                                                        onChange={(e) => {
                                                            setEndTicket(e.target.value);
                                                        }} />
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group input-group-sm mb-3">
                                                    <p className="input-field-side-text">Default Queue Size : </p>

                                                    <input type="number" className="form-control" aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        value={queueSize}
                                                        onChange={(e) => {
                                                            setqueueSize(e.target.value);
                                                        }} />
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group input-group-sm mb-3">
                                                    <p className="input-field-side-text">CRM Category : </p>

                                                    <input type="text" className="form-control" aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        value={crmCategory}
                                                        onChange={(e) => {
                                                            setCrmCategory(e.target.value);
                                                        }} />
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group input-group-sm mb-3">
                                                    <p className="input-field-side-text">CRM Sub Category : </p>

                                                    <input type="text" className="form-control" aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        value={crmSubCategory}
                                                        onChange={(e) => {
                                                            setCrmSubCategory(e.target.value);
                                                        }} />
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group input-group-sm mb-3">
                                                    <p className="input-field-side-text">Service Type : </p>

                                                    <input type="text" className="form-control" aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        value={serviceType}
                                                        onChange={(e) => {
                                                            setServiceType(e.target.value);
                                                        }} />
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </form>

                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-default"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >Cancel
                                </button>

                                {modalType === 0 ?
                                    (<Button loading={buttonLoader} type="button" className="btn btn-primary"
                                        onClick={() => {
                                            addCategoryMaster();
                                        }}
                                    >Save changes</Button>)
                                    : modalType === 1 ?
                                        (<Button loading={buttonLoader} type="button" className="btn btn-primary"
                                            onClick={() => {
                                                updateCategoryMaster();
                                            }}
                                        >Update changes</Button>)
                                        :
                                        (null)
                                }


                            </div>

                        </div>
                    </div>
                </div >
            ) : null
            }

        </>

    )
}

export default AddCategoryMasterModal