import React, { useState , useEffect} from 'react'
import { Donut } from 'react-dial-knob';
import toast, { Toaster } from 'react-hot-toast';
import DatePicker from "react-datepicker";
import moment from 'moment';

import TokenBehavior from './CategoryAnalytics/TokenBehavior';
import TokenExceptionBehavior from './CategoryAnalytics/TokenExceptionBehavior';
import TokenExceptionProcess from './CategoryAnalytics/TokenExceptionProcess';


const CategoryAnalytics = () => {

    let curr = new Date;
    let first = curr.getDate() - curr.getDay();
    let max = curr.getDate() - 1;

    const [startDate, setStartDate] = useState(curr.setDate(first));
    const [endDate, setEndDate] = useState(new Date());
    const [minDate, setMinDate] = useState(startDate)
    const [maxDate, setMaxDate] = useState(curr.setDate(max))

    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');

    const [donutChartColors, setdonutChartColors] = useState(['#9237B3', '#45F073', '#09E6E6', '']);
    

    return (
        <>
            <div><Toaster /></div>
            <div className="container-fluid">
                <div className="block-header">
                    <div className="row clearfix">

                        <div className="col-md-12 col-sm-12">
                            <h1>Category Analytics</h1>
                            {/* <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">TS</a></li>
                                    <li className="breadcrumb-item"><a href="/">Maradana</a></li>
                                    <li className="breadcrumb-item"><a href="/">Branch Management</a></li>
                                    <li className="breadcrumb-item"><a href="/">Analytics page</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Category Analytics page</li>
                                </ol>
                            </nav> */}
                        </div>
                    </div>
                </div>
                <div className="row clearfix">
                    <div className="col-xl-7 col-lg-6 col-md-6">
                        <div className="analytic-cat-body">
                            <div>
                                <ul className="nav nav-tabs2">
                                    <li className="nav-item"><a className="nav-link active show" data-toggle="tab">This Week</a></li>
                                    <li className="nav-item"><a className="nav-link" data-toggle="tab">Last Week</a></li>
                                    <li className="nav-item"><a className="nav-link" data-toggle="tab">This Month</a></li>
                                    <li className="nav-item"><a className="nav-link" data-toggle="tab">Last Month</a></li>
                                    <li className="nav-item"><a className="nav-link" data-toggle="tab">Last 3 Months</a></li>
                                    <li className="nav-item"><a className="nav-link" data-toggle="tab">Last 6 Months</a></li>
                                    <li className="nav-item"><a className="nav-link" data-toggle="tab">This Year</a></li>
                                    <li className="nav-item"><a className="nav-link" data-toggle="tab">Last Year</a></li>
                                    <li className="nav-item"><a className="nav-link" data-toggle="tab">Last 3 Years</a></li>
                                    <li className="nav-item"><a className="nav-link" data-toggle="tab">Last Month</a></li>
                                    <li className="nav-item"><a className="nav-link" data-toggle="tab">Last 3 Months</a></li>
                                    <li className="nav-item"><a className="nav-link" data-toggle="tab">Last 6 Months</a></li>
                                    <li className="nav-item"><a className="nav-link" data-toggle="tab">This Year</a></li>
                                    <li className="nav-item"><a className="nav-link" data-toggle="tab">Last Year</a></li>
                                    <li className="nav-item"><a className="nav-link" data-toggle="tab">Last 3 Years</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 col-md-6">
                        <div className="input-daterange input-group" data-provide="datepicker">

                            <DatePicker className="input-sm form-control"
                                selected={startDate}
                                maxDate={maxDate}
                                onChange={(date) => {
                                    const dateFrom = moment(date).format("YYYY-MM-DD");
                                    setStartDate(date)
                                    const nextDate = moment(date).add(1, 'days').toDate();
                                    setMinDate(nextDate)
                                    setDateFrom(dateFrom)
                                }} 
                            />

                            <span className="input-group-addon range-to">to</span>

                            <DatePicker className="input-sm form-control"
                                selected={endDate}
                                minDate={minDate}
                                maxDate={new Date()}
                                onChange={(date) => {
                                    const dateTo = moment(date).format("YYYY-MM-DD");
                                    setEndDate(date)
                                    const prevDate = moment(date).subtract(1, 'days').toDate();
                                    setMaxDate(prevDate)
                                    setDateTo(dateTo)
                                }} 
                            />

                        </div>
                    </div>
                </div>

                <TokenBehavior />

                <div className="row clearfix">
                    <TokenExceptionBehavior />
                    <TokenExceptionProcess />
                </div>


            </div>

        </>
    )

}

export default CategoryAnalytics
