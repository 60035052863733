import toast, { Toaster } from 'react-hot-toast';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { MutatingDots } from 'react-loader-spinner';
import Dot from 'react-animated-dots';

const BranchCloneModal = (props) => {

    const customerId = props.customerId;
    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');

    const [refresh, setRefresh] = useState(1);
    const [refreshKiosks, setRefreshKiosks] = useState(1)

    const [clonableBranch, setClonableBranch] = useState([])
    const [clonableCategories, setClonableCategories] = useState([])
    const [cloneableSkills, setCloneableSkills] = useState([])
    const [clonableKioskDesigns, setClonableKioskDesigns] = useState([])

    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
    const [selectedMasterCategoryIds, setSelectedMasterCategoryIds] = useState([]);

    const [allCategoriesSelected, setAllCategoriesSelected] = useState(true);
    const [allSkillsSelected, setAllSkillsSelected] = useState(true);
    const [allKioskDesignsSelected, setAllKioskDesignsSelected] = useState(true);

    const [enableTicketDesignClone, setEnableTicketDesignClone] = useState(false)

    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        getClonableBranches(customerId);
    }, [BranchCloneModal])

    useEffect(() => {
        if (selectedBranch !== null) {
            getClonableCategories(selectedBranch);
        }
    }, [selectedBranch, allCategoriesSelected, allSkillsSelected])

    useEffect(() => {
        if (selectedMasterCategoryIds.length !== 0) {
            if (allCategoriesSelected) {
                console.log("masters: ", selectedMasterCategoryIds);
                getClonableKioskDesigns(selectedMasterCategoryIds);
            } else {
                getClonableKioskDesigns([])
            }
        }
    }, [selectedBranch, selectedMasterCategoryIds, allCategoriesSelected, allKioskDesignsSelected])

    useEffect(() => {
        if (!allCategoriesSelected) {
            setSelectedMasterCategoryIds([])
        }
    }, [allCategoriesSelected])


    useEffect(() => {
        const ids = []
        const masterCategoryIds = []

        if (clonableCategories.length !== 0) {
            clonableCategories.forEach(element => {
                if (element.selected) {
                    ids.push(element.categoryId);
                    masterCategoryIds.push(element.categoryMasterId);
                }
            })
            setSelectedCategoryIds(ids);
            setSelectedMasterCategoryIds(masterCategoryIds);
        }
    }, [clonableCategories, refresh])


    const getClonableBranches = (customerId) => {

        axios({
            method: 'GET',
            url: `${baseUrl}/branch/get-clone-allowed-list/customer/${customerId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            let data = res.data.data;
            setSelectedBranch(data[0].id)
            setClonableBranch(data);
            getClonableCategories(data[0].id);

        }).catch((error) => {
            console.log(error);
        })
    }

    const getClonableCategories = (selectedBranch) => {

        axios({
            method: 'GET',
            url: `${baseUrl}/category/get-all-categories-for-clone/branch/${props.branch}/from/branch/${selectedBranch}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            let data = res.data.data;

            if (allCategoriesSelected) {
                for (let j = 0; j < data.length; j++) {
                    data[j].selected = true;
                    if (allSkillsSelected) {
                        for (let k = 0; k < data[j].cloningSkills.length; k++) {
                            data[j].cloningSkills[k].skillSelected = true
                        }
                    }
                }
            } else {
                for (let j = 0; j < data.length; j++) {
                    data[j].selected = false;
                    for (let k = 0; k < data[j].cloningSkills.length; k++) {
                        data[j].cloningSkills[k].skillSelected = false
                    }
                }
            }

            setClonableCategories(data);

        }).catch((error) => {
            console.log(error);
        })
    }

    const getClonableKioskDesigns = (selectedMasterCategoryIds) => {

        if (selectedBranch !== null) {
            axios({
                method: 'POST',
                url: `${baseUrl}/category/get-cloneable-kiosk-designs`,
                headers: {
                    Authorization: token
                },
                data: {
                    branchId: props.branch,
                    cloningBranchId: selectedBranch,
                    selectedCategoryMasters: selectedMasterCategoryIds
                }
            }).then((res) => {
                let data = res.data.data;
                if (allKioskDesignsSelected) {
                    for (let j = 0; j < data.length; j++) {
                        data[j].selected = true;
                    }
                }
                setClonableKioskDesigns(data);

            }).catch((error) => {
                console.log(error);
            })
        }
    }

    const cloneBranch = () => {

        let categories = [];
        let designs = [];

        clonableCategories.forEach(element => {
            if (element.selected) {

                let categorySkill = {
                    categoryId: null,
                    skills: []
                }

                let ticketDesings = {
                    categoryId: null,
                    languages: []
                }

                categorySkill.categoryId = element.categoryId;
                ticketDesings.categoryId = element.categoryId;
                if (element.cloningSkills.length !== 0) {
                    element.cloningSkills.forEach(skill => {
                        if (skill.skillSelected) {
                            categorySkill.skills.push(skill.skillId)
                            if (skill.languageId !== null) {
                                ticketDesings.languages.push(skill.languageId);
                            }
                        }
                    })
                }
                categories.push(categorySkill)
                designs.push(ticketDesings)
            }
        })

        if (categories.length === 0) {
            toast.remove();
            toast.error('Please select atleast one category.', {
                position: 'bottom-center',
            });
            return false;
        }

        let kioskDesignIds = []

        clonableKioskDesigns.forEach(element => {
            if (element.selected) {
                kioskDesignIds.push(element.kioskDesignId)
            }
        })

        if (selectedBranch !== null) {

            setShowLoader(true)

            axios({
                method: 'POST',
                url: `${baseUrl}/branch/clone-branch`,
                headers: {
                    Authorization: token
                },
                data: {
                    branchId: props.branch,
                    cloningBranchId: selectedBranch,
                    categoryRequestList: categories,
                    cloneTicketDesigns: true,
                    branchCloneTicketDesignRequests: designs,
                    cloneKioskDesigns: true,
                    kioskDesigns: kioskDesignIds

                }
            }).then(() => {

                setShowLoader(false)

                toast.remove();
                toast.success('Branch Successfully Cloned', {
                    position: 'bottom-center',
                });

                getClonableCategories(selectedBranch);
                getClonableKioskDesigns(selectedMasterCategoryIds);
                props.getBranchDetails();

            }).catch((error) => {
                console.log(error);

                if (error.response !== undefined) {
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                } else {
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }
            })
        }
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {props}
        </Tooltip>
    );

    return (
        <>
            <div><Toaster /></div>
            {
                props.openBranchCloneModal ? (
                    <>
                        <div className={`modal fade bd-example-modal-lg d-block show modal-container`} id="exampleModal">
                            {
                                showLoader ? (
                                    <div style={{ marginLeft: '50%', marginTop: '20%' }}>
                                        <MutatingDots
                                            visible={true}
                                            height="100"
                                            width="100"
                                            color="#8A60DB"
                                            secondaryColor="#8A60DB"
                                            radius="12.5"
                                            ariaLabel="mutating-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                        />
                                        <h5>Cloning<span className='cloning-animation'> <b>...</b></span>
                                        </h5>
                                    </div>
                                ) : (
                                    <div className="modal-dialog modal-lg add-branch-modal clone-modal" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel">
                                                    Branch Clone
                                                </h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                                    onClick={() => {
                                                        props.setOpenBranchCloneModal(false)
                                                    }}
                                                >
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div className="modal-body lg-modal-body-scroll">
                                                {clonableBranch.length !== 0 ?
                                                    (
                                                        <div className="container">



                                                            {/* <div className="row clearfix">
                                                    <div className="col-12">
                                                        <div className="input-group input-group-sm mb-3 modal-input-group modal-body-section-title">
                                                            <p>Categories</p>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                            <div className="row clearfix">
                                                                <div className="col-7">
                                                                    <div className="input-group input-group-sm mb-3 modal-input-group">
                                                                        <p className='input-field-side-text'>Select Branch : </p>
                                                                        <select className="custom-select modal-select-field" id="inputGroupSelect01"
                                                                            onChange={(e) => {
                                                                                setSelectedBranch(e.target.value);
                                                                            }}
                                                                        >
                                                                            {clonableBranch.map((branch, index) =>
                                                                                <option value={branch.id} key={branch.id}>{branch.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-5">
                                                                    {/* <div className="selecte-all-container mb-3 ml-2 float-right">
                                                                        <p className="mb-0 m-r-15">Kiosk:</p>
                                                                        <label
                                                                            className="switch custom-swtch" title="Select All">
                                                                            <input type="checkbox"
                                                                                checked={allKioskDesignsSelected ? true : false}
                                                                                onChange={(e) => {
                                                                                    setAllKioskDesignsSelected(e.target.checked);
                                                                                }}

                                                                            />
                                                                            <span className='slider'
                                                                            > Select All</span>

                                                                        </label>
                                                                    </div>

                                                                    <div className="selecte-all-container mb-3 ml-2 float-right">
                                                                        <p className="mb-0 m-r-15">Skills:</p>
                                                                        <label
                                                                            className="switch custom-swtch" title="Select All">
                                                                            <input type="checkbox"
                                                                                checked={allSkillsSelected ? true : false}
                                                                                onChange={(e) => {
                                                                                    setAllSkillsSelected(e.target.checked);
                                                                                }}

                                                                            />
                                                                            <span className='slider'
                                                                            > Select All</span>

                                                                        </label>
                                                                    </div>

                                                                    <div className="selecte-all-container mb-3 ml-2 float-right">
                                                                        <p className="mb-0 m-r-15">Categories:</p>
                                                                        <label
                                                                            className="switch custom-swtch" title="Select All">
                                                                            <input type="checkbox"
                                                                                checked={allCategoriesSelected ? true : false}
                                                                                onChange={(e) => {
                                                                                    setAllCategoriesSelected(e.target.checked);
                                                                                }}
                                                                                disabled
                                                                            />
                                                                            <span className='slider'
                                                                            > Select All</span>

                                                                        </label>
                                                                    </div> */}
                                                                </div>
                                                            </div>

                                                            <div className="row clearfix">

                                                                <div className="col-lg-12">
                                                                    {clonableCategories.length > 0 ? (
                                                                        <>
                                                                            {clonableCategories.map((category) =>
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={renderTooltip(category.categoryId)}
                                                                                >
                                                                                    {
                                                                                        <span className='badge badge-secondary mt-1'

                                                                                        // onClick={() => {
                                                                                        //     skill.skillSelected = !skill.skillSelected
                                                                                        //     setRefresh(refresh + 1);
                                                                                        // }}
                                                                                        >
                                                                                            {category.category}
                                                                                        </span>
                                                                                    }

                                                                                </OverlayTrigger>
                                                                            )}
                                                                        </>

                                                                    ) : (<div className='ml-3 text-white'>Already Cloned Everything</div>)}
                                                                </div>
                                                            </div>

                                                            <div className="row clearfix">
                                                                <div className="col-12">
                                                                    <div className="input-group input-group-sm mt-4 mb-3 modal-input-group modal-body-section-title">
                                                                        <p>Clonable Kiosks Designs</p>
                                                                        {/* <div className="selecte-all-container mb-3 float-right">
                                                        <p className="mb-0 m-r-15 ml-1"></p>
                                                        <label
                                                            className="switch custom-swtch" title="Select All">
                                                            <input type="checkbox"
                                                                checked={enableTicketDesignClone ? true : false}
                                                                onChange={(e) => {
                                                                    setEnableTicketDesignClone(e.target.checked);
                                                                }}

                                                            />
                                                            <span className='slider'
                                                            > Select All</span>

                                                        </label>
                                                    </div> */}
                                                                    </div>
                                                                </div>

                                                                <div className='col-12 p-l-5 p-r-5'>
                                                                    <div className='modal-list-scroll'>
                                                                        {clonableKioskDesigns.length !== 0 ? (
                                                                            <>
                                                                                {
                                                                                    clonableKioskDesigns.map(item => (
                                                                                        // <ul className="list-group" key={item.kioskDesignId}>
                                                                                        //     <li className="list-group-item d-flex justify-content-between align-items-center"
                                                                                        //         onClick={() => {
                                                                                        //             // if (modalType === 0 || modalType === 1) {
                                                                                        //             //     item.selected = !item.selected;
                                                                                        //             //     setRefresh(refresh + 1);
                                                                                        //             //     checkAllCCSelectedOrNot()
                                                                                        //             // }
                                                                                        //         }}
                                                                                        //     >
                                                                                        //         {item.kioskDesign}

                                                                                        //         <div>
                                                                                        //             {item.requiredCategories.length !== 0 ? (
                                                                                        //                 <div>
                                                                                        //                     <p>Required: </p>
                                                                                        //                     {item.requiredCategories.map((req) => (
                                                                                        //                         <ul>
                                                                                        //                             <li className='text-danger'>{req}</li>
                                                                                        //                         </ul>
                                                                                        //                     ))}
                                                                                        //                 </div>
                                                                                        //             ) : (
                                                                                        //                 <span className="badge badge-success mt-1">Available</span>
                                                                                        //             )}
                                                                                        //         </div>
                                                                                        //     </li>

                                                                                        // </ul>
                                                                                        <OverlayTrigger
                                                                                            placement="top"
                                                                                            overlay={renderTooltip(item.kioskDesignId)}
                                                                                        >
                                                                                            {
                                                                                                <span className='badge badge-success mt-1'

                                                                                                // onClick={() => {
                                                                                                //     skill.skillSelected = !skill.skillSelected
                                                                                                //     setRefresh(refresh + 1);
                                                                                                // }}
                                                                                                >
                                                                                                    {item.kioskDesign}
                                                                                                </span>
                                                                                            }

                                                                                        </OverlayTrigger>

                                                                                    ))
                                                                                }
                                                                            </>
                                                                        ) : (<div className='ml-2 text-white'>Already Cloned Everything</div>)}


                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    ) : (<>No Cloneable Branches</>)
                                                }
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-default" data-dismiss="modal"
                                                    onClick={() => {
                                                        props.setOpenBranchCloneModal(false)
                                                    }}
                                                >Cancel</button>

                                                {
                                                    allCategoriesSelected ?
                                                        <>
                                                            {clonableCategories.length !== 0 ? <Button type="button" className="btn btn-primary"
                                                                onClick={() => { cloneBranch() }}
                                                            >Clone</Button> : null}
                                                        </> :
                                                        <Button type="button disabled" className="btn btn-primary"
                                                            onClick={() => {
                                                                toast.error('Select at least one category', {
                                                                    position: 'bottom-center',
                                                                });
                                                            }}
                                                        >Clone</Button>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </>
                ) : (<></>)
            }
        </>
    )
}

export default BranchCloneModal