import React, { useEffect, useState } from 'react';
import PersentageDonutChartWithBottomLegend
    from '../../ChartsImpl/DetailsPageCharts/PersentageDonutChartWithBottomLegend';

import toast from "react-hot-toast";
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme } from '@mui/material/styles';

const NoShowTicketsDetails = (props) => {

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');

    const [chartValue, setChartValue] = useState(0);

    const [tokenTableData, setTokenTableData] = useState([]);
    const [categoiesForRender, setCategoiesForRender] = useState([])

    const [tokenData, settokenData] = useState([]);
    const [chartData, setchartData] = useState([]);

    const [categoryList, setcategoryList] = useState([]);
    const [percentageList, setpercentageList] = useState([]);

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (props.showNoShowTicketsDetails === false) return;
        if (props.noShowInc > 0 || props.messageInc1001 !== ''
            || props.messageInc2000 !== ''
            || props.messageInc2001 !== ''
            || props.messageInc3001 !== '') {
            getNoSDetails();
        }
    }, [props.noShowInc, props.messageInc1001, props.messageInc2000, props.messageInc2001, props.messageInc3001]);

    useEffect(() => {
        setChartValue(chartValue + 1);
    }, []);

    const getNoSDetails = () => {
        setLoading(true);

        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/get-category-no-show/inner-data/branch/${props.selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('getServedTicketsData success');
            let data = res.data.data;
            loopChartData(data.countAndPercentage);

            setchartData(data.countAndPercentage);
            settokenData(data.tableData);

            setChartValue(chartValue + 1);
            setCount(data.tableData.length);
            setLoading(false);

        }).catch((error) => {
            console.log('getTicketIssuedDetails error');
            console.log(error);
            setLoading(false);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    };

    const loopChartData = (data) => {
        const categories = [];
        const percentages = [];

        data.forEach(element => {
            categories.push(element.category);
            percentages.push(element.percentage);
        });

        setcategoryList(categories);
        setpercentageList(percentages);
    };

    const closeModal = () => {
        props.setShowNoShowTicketsDetails(false)

        settokenData([]);
        setchartData([]);
    };

    const options = {
        tableBodyHeight: '50vh',
        filter: false,
        responsive: 'vertical',
        serverSide: false,
        count: count,
        download: false,
        viewColumns: false,
        jumpToPage: true,
        selectableRows: 'none',
        print: false,
        rowsPerPage: rowsPerPage,
        onChangeRowsPerPage: (n) => {
            setRowsPerPage(n)
        },
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        }
    };

    const columns = [
        {
            label: 'Token No',
            name: 'ticketNumber',
            options: {
                sort: true,
                filter: false,
                // customBodyRender: (value) => (
                //     <b>{value}</b>
                // )
            }
        },
        {
            label: "Branch",
            name: 'branchName',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Category",
            name: 'category',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Operator',
            name: 'firstName',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'Called Time',
            name: 'calledTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Status',
            name: 'id',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    if (tokenData === undefined) return;
                    let item = tokenData.find(item => item.id === value);
                    return (<>
                        {item.status === 1 ? 'Serving'
                            : item.status === 2 ? 'No Show'
                                : item.status === 4 ? 'Served'
                                    : ''}
                    </>);
                }
            },
        },
    ];

    const columns_1 = [
        {
            label: "Branch",
            name: 'branchName',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Token No',
            name: 'ticketNumber',
            options: {
                sort: true,
                filter: false,
                // customBodyRender: (value) => (
                //     <b>{value}</b>
                // )
            }
        },
        {
            label: "Category",
            name: 'category',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Operator',
            name: 'firstName',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'Called Time',
            name: 'calledTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Status',
            name: 'id',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    if (tokenData === undefined) return;
                    let item = tokenData.find(item => item.id === value);
                    return (<>
                        {item.status === 1 ? 'Serving'
                            : item.status === 2 ? 'No Show'
                                : item.status === 4 ? 'Served'
                                    : ''}
                    </>);
                }
            },
        },
    ];

    const filter = (e) => {
        const keyword = e.target.value;

        if (keyword !== '') {
            const results = categoiesForRender.filter((cat) => {
                return cat.category.toString().toLowerCase().includes(keyword.toLowerCase())
                    || cat.ticketNumber.includes(keyword)
                    || cat.firstName.toString().toLowerCase().includes(keyword.toLowerCase())
                    || cat.calledTime.toString().toLowerCase().includes(keyword.toLowerCase())
                    || cat.status.toString().toLowerCase().includes(keyword.toLowerCase());
            });
            setTokenTableData(results);
        } else {
            setTokenTableData(categoiesForRender);
        }
        // setSearchCategoryText(keyword);
    };

    return (
        <>
            {props.showNoShowTicketsDetails ?
                <div id="megamenu" className={`megamenu particles_js details-page open `}>
                    <span
                        onClick={() => {
                            props.setSticky(true)
                            props.setShowNoShowTicketsDetails(false);
                            closeModal();
                        }}
                        className="megamenu_toggle btn btn-danger"><i className="icon-close" /></span>
                    <div className="container">
                        <div className="row clearfix">
                            <div className="col-12">
                                <div className="card">
                                    <div className="body">
                                        <div style={{ textAlign: 'center' }}>
                                            <h4>No show tickets data</h4>
                                            <small>{props.date}</small>
                                        </div>
                                        <br />
                                        <div className="row clearfix">
                                            <div className="col-4">

                                                {
                                                    chartData.length !== 0 && chartValue > 0 ?
                                                        <PersentageDonutChartWithBottomLegend
                                                            lable={categoryList} percentage={percentageList}
                                                            chartValue={chartValue} />
                                                        :
                                                        <PersentageDonutChartWithBottomLegend
                                                            lable={[]} percentage={[]} chartValue={0} />
                                                }

                                                <div className='mt-4'>
                                                    <div style={{ overflowY: 'scroll', height: '200px' }}>
                                                        <table className="table table-hover table-custom">
                                                            <tbody>
                                                                {chartData.map((data, i) =>
                                                                    <tr key={i}>
                                                                        <td>{data.category}</td>
                                                                        <td>{data.ticketCount}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-8">
                                                <div  className="muidatatablecontainer">
                                                    {/*<ThemeProvider theme={darkTheme}>*/}
                                                    {/*<CssBaseline/>*/}
                                                    {props.selectedBranchLevel === 4 ? (
                                                        <MUIDataTable
                                                            title={<>
                                                                {count}{count === 1 ? ' record' : ' records'}.
                                                                {isLoading &&
                                                                    <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                                                        size={20} /></>}
                                                            </>
                                                            }
                                                            data={tokenData}
                                                            columns={columns}
                                                            options={options}
                                                        />
                                                    ) : (
                                                        <MUIDataTable
                                                            title={<>
                                                                {count}{count === 1 ? ' record' : ' records'}.
                                                                {isLoading &&
                                                                    <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                                                        size={20} /></>}
                                                            </>
                                                            }
                                                            data={tokenData}
                                                            columns={columns_1}
                                                            options={options}
                                                        />
                                                    )}

                                                    {/*</ThemeProvider>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div id="particles-js"></div>
                </div>
                : null}
        </>
    );
}

// const mapStateToProps = state => ({
//     isNoShowTicketsDetails: state.settings.isNoShowTicketsDetails
// })

// const mapDispatchToProps = dispatch => ({
//     toggleNoShowTicketsDetails: (e) => dispatch(toggleNoShowTicketsDetails(e))
// })
// export default connect(mapStateToProps, mapDispatchToProps)(NoShowTicketsDetails);
export default NoShowTicketsDetails;