import axios from 'axios';
import React, { useEffect, useState } from 'react';
import AssignCategoryGroupModal from '../ModalImpl/AssignCategoryGroupModal';
import AssignSubCategoryModal from '../ModalImpl/AssignSubCategoryModal';
import AssignSubSubCategoriesModal from '../ModalImpl/AssignSubSubCategoriesModal';
import Swal from 'sweetalert2';
import SubCategoriesDetailsViewModal from '../ModalImpl/SubCategoriesDetailsViewModal';
import SubSubCategoriesDetailsViewModal from '../ModalImpl/SubSubCategoriesDetailsViewModal';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';


const BranchCategoriesGroup = () => {


    const [baseUrl, setBaseUrl] = useState('');
    const [token, setToken] = useState('');
    const [userDetails, setUserDetails] = useState({});
    const [selectedBranch, setSelectedBranch] = useState('');
    const [subCategory, setSubCategory] = useState({})
    const [subSubCategories, setSubSubCategories] = useState([])

    //add modals
    const [showAssignCategoryGroupModal, setShowAssignCategoryGroupModal] = useState(false);
    const [showAssignSubCategoryModal, setShowAssignSubCategoryModal] = useState(false);
    const [showAssignSubSubCategoryModal, setShowAssignSubSubCategoryModal] = useState(false);
    const [showSubSubCategoriesModal, setShowSubSubCategoriesModal] = useState(false);
    const [showSubCategoriesModal, setShowSubCategoriesModal] = useState(false);


    const [allGroups, setAllGroups] = useState([]);
    const [allSubCategories, setAllSubCategories] = useState([]);

    const [selectedGroup, setSelectedGroup] = useState({});
    const [selectedSubCategory, setSelectedSubCategory] = useState({});
    const [selectedSubSubCategory, setSelectedSubSubCategory] = useState({});

    const [displayTipGroup, setDisplayTipGroup] = useState("Add new group");
    const [displayTipCategoryGroups, setDisplayTipCategoryGroups] = useState("Please select a group");
    const [displayTipSubCategory, setDisplayTipSubCategory] = useState("Please select a sub category");

    const [modalType, setmodalType] = useState(); // 0-add, 1-edit


    //get base url - useEffect
    useEffect(() => {
        setBaseUrl(localStorage.getItem('API_URL'));
        setToken(localStorage.getItem('user_token'));
        setUserDetails(JSON.parse(localStorage.getItem('user_details')));
        setSelectedBranch(JSON.parse(localStorage.getItem('selected_branch')));
    }, [BranchCategoriesGroup])

    //call getAllCategoryGroups
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            if (baseUrl.length > 0 && token.length > 0 && Object.keys(userDetails).length > 0) {
                getAllCategoryGroups();
            }
        }
    }, [baseUrl, token, userDetails])

    //when click one group, call getAllSubCategoryiesByGroup
    useEffect(() => {
        if (Object.keys(selectedGroup).length > 0) {
            getAllSubCategoryiesByGroup();
        }
    }, [selectedGroup])

    //when click one province, get relavant districts
    useEffect(() => {
        if (Object.keys(selectedSubCategory).length > 0) {
            getSubCategory();
        }
    }, [selectedSubCategory])


    //getAllCategoryGroups - function
    const getAllCategoryGroups = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/category/get-all/groups/${selectedBranch.id}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let dataList = res.data.data.list
            setAllGroups(dataList);

        }).catch((error) => {

            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    //getAllSubCategoryiesByGroup - function
    const getAllSubCategoryiesByGroup = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/category/get-sub-categories/group/${selectedGroup.id}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            const dataList = res.data.data.list

            if (dataList.length === 0) {
                setAllSubCategories([]);
                setDisplayTipCategoryGroups("Add new sub category")
            } else {
                setAllSubCategories(dataList);
            }

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    //getSubCategory - function
    const getSubCategory = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/category/get/sub-category/${selectedSubCategory.id}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            const data = res.data.data;
            setSubCategory(data);

            if (data.subSubCategories !== null) {
                setSubSubCategories(data.subSubCategories)
            } else {
                setSubSubCategories([])
                setDisplayTipSubCategory("Add sub sub categories to selected sub category")
            }

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    const deleteCategoryGroup = (id) => {

        Swal.fire({
            position: 'center',
            icon: 'question',
            text: 'Do you want to remove this category group?',
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            width: '400px',

        }).then((result) => {
            if (result.value == true) {
                axios({
                    method: 'POST',
                    url: `${baseUrl}/category/remove/category-group/${id}`,
                    data: {},
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    let removeSubStatus = res.data.data;
                    if (removeSubStatus == true) {
                        toast.remove();
                        toast.success('Category group removed successfully ', {
                            position: 'bottom-center',
                        });
                    }

                    getAllCategoryGroups();
                    getAllSubCategoryiesByGroup();
                    getSubCategory();

                }).catch((error) => {
                    console.log(error);

                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                })
            }
        })
    }

    //removeSubCategoryFromGroup - function
    //this function is currently not use. but don't remove this
    const removeSubCategoryFromGroup = (id) => {

        Swal.fire({
            position: 'center',
            icon: 'question',
            text: 'Do you want to remove this sub category?',
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            width: '400px',

        }).then((result) => {
            if (result.value == true) {
                axios({
                    method: 'POST',
                    url: `${baseUrl}/category/remove-from-group/sub-category/${id}`,
                    data: {},
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    let removeSubStatus = res.data.data;
                    if (removeSubStatus == true) {
                        toast.remove();
                        toast.success('Sub category removed successfully ', {
                            position: 'bottom-center',
                        });
                    }

                    getAllCategoryGroups();
                    getAllSubCategoryiesByGroup();
                    getSubCategory();
                }).catch((error) => {
                    console.log(error);

                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                })
            }
        })
    }

    const removeSubSubCategoryFromGroup = (id) => {

        Swal.fire({
            position: 'center',
            icon: 'question',
            text: 'Do you want to remove this sub sub category?',
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            width: '400px',

        }).then((result) => {
            if (result.value == true) {
                axios({
                    method: 'POST',
                    url: `${baseUrl}/category/remove-from-sub-cat-group/sub-sub-category/${id}`,
                    data: {},
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    let removeSubStatus = res.data.data;
                    if (removeSubStatus == true) {
                        toast.remove();
                        toast.success('Sub category removed successfully ', {
                            position: 'bottom-center',
                        });
                    }

                    getAllCategoryGroups();
                    getAllSubCategoryiesByGroup();
                    getSubCategory();

                }).catch((error) => {
                    console.log(error);

                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                })
            }
        })
    }

    return (

        <div className="container-fluid">
            <div><Toaster /></div>

            <div className="block-header">
                <div className="row clearfix">
                    <div className="col-md-12 col-sm-12">
                        <h2>Category Groups</h2>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                {/* <li className="breadcrumb-item"><Link to="/region-management">Region Management</Link></li> */}
                                <li className="breadcrumb-item"><Link to={`/branch-landing?bid=${selectedBranch.id}`}>{selectedBranch.BranchName}</Link></li>
                                <li className="breadcrumb-item"><Link to="/branch-management/categories">Categories</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Category Groups</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div className="row clearfix">

                <div className="col-lg-4 col-md-12">
                    <div className="card">
                        <div className="body taskboard b-cyan progress_task">
                            <h6 className="font300 mb-3">Category Groups
                                <span className="float-right">
                                    <i className="fa fa-plus-circle custom-icon" title='Assign New Category Group'
                                        onClick={() => {
                                            setmodalType(0);
                                            setShowAssignCategoryGroupModal(true);
                                        }}
                                    ></i>
                                </span>
                            </h6>
                            <div className="dd" data-plugin="nestable">
                                {allGroups.length > 0 ? (
                                    <>
                                        {allGroups.map((group, i) => (

                                            <div
                                                className={`dd-handle align-items-center ${selectedGroup === group ? 'active' : ''}`}
                                                key={group.id}
                                                onClick={() => {
                                                    if (selectedGroup !== group) {
                                                        setAllSubCategories([]);
                                                        setSelectedSubCategory([]);
                                                        setSubSubCategories([]);
                                                        setDisplayTipSubCategory("Please select a sub category");
                                                        setSelectedGroup(group);
                                                    }
                                                }}
                                            >
                                                <div className='d-flex justify-content-between mb-2'>
                                                    <div> {group.category} </div>
                                                    <div className="action" style={{ width: '45px' }}>
                                                        <i className="fa fa-edit action-icon" title='Edit'
                                                            onClick={() => {
                                                                setmodalType(1);
                                                                setShowAssignCategoryGroupModal(true);
                                                            }}
                                                        />
                                                        <i className="fa fa-trash-o ml-2" onClick={() => deleteCategoryGroup(group.id)}></i>
                                                    </div>

                                                </div>

                                                <div className='category-group-down'>
                                                    <span className="badge badge-secondary mr-1"> <i
                                                        className="zmdi zmdi-check-square" />{group.serviceTime}</span>

                                                    <span className="badge badge-secondary"> <i
                                                        className="zmdi zmdi-check-square" />{group.noRangeMin} - {group.noRangeMax}</span>

                                                    <span className="text-muted float-right mr-1"> <i
                                                        className="zmdi zmdi-check-square" />{group.subCategoriesCount}</span>
                                                </div>
                                            </div>

                                        ))}
                                    </>
                                ) : (
                                    <div className="dd-handle align-items-center text-center">
                                        <div className='d-flex justify-content-between'>
                                            <div> {displayTipGroup} </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-12">
                    <div className="card">
                        <div className="body taskboard b-orange completed_task">
                            <h6 className="font300 mb-3">Sub Categories
                                <span className="float-right">
                                    <i className="fa fa-plus-circle custom-icon" title='Assign New Sub Category'
                                        style={Object.keys(selectedGroup).length === 0 ? { display: 'none' } : {}}
                                        onClick={() => {
                                            setmodalType(0);
                                            setShowAssignSubCategoryModal(true);
                                        }}
                                    ></i>
                                </span>
                            </h6>
                            <div className="dd" data-plugin="nestable">

                                {allSubCategories.length > 0 ? (
                                    <>
                                        {
                                            allSubCategories.map((subCategory, i) => (

                                                <div
                                                    className={`dd-handle align-items-center ${selectedSubCategory === subCategory ? 'active' : ''}`}
                                                    key={subCategory.id}
                                                    onClick={() => {
                                                        setSelectedSubCategory(subCategory);
                                                    }}
                                                >
                                                    <div className='d-flex justify-content-between' style={{ alignItems: 'baseline' }}>
                                                        <div> {subCategory.categoryName} </div>
                                                        <div className="action catgroup-sub-cat">

                                                            {subCategory.subCategoryGroup == true ?
                                                                (<>
                                                                    <span className='group-span' style={{ color: 'green', marginRight: '4px' }}>&#71;</span>
                                                                    <i className="fa fa-eye action-icon"
                                                                        onClick={() => {
                                                                            setShowSubCategoriesModal(true);
                                                                        }}
                                                                    />
                                                                    <i className="fa fa-trash-o ml-2"
                                                                        onClick={() => removeSubCategoryFromGroup(subCategory.id)}></i>
                                                                </>) :
                                                                (<>
                                                                    <i className="fa fa-eye action-icon"
                                                                        onClick={() => {
                                                                            setShowSubCategoriesModal(true);
                                                                        }} />
                                                                    <i className="fa fa-trash-o ml-2"
                                                                        onClick={() => removeSubCategoryFromGroup(subCategory.id)}></i>
                                                                    {/* <i className="fa fa-trash-o action-icon" title='Delete'
                                                                    onClick={() => {
                                                                        removeSubCategoryFromGroup(subCategory.id);
                                                                    }}

                                                                /> */}
                                                                </>
                                                                )
                                                            }
                                                        </div>

                                                    </div>
                                                    <span href="/" className="text-muted"><i
                                                        className="zmdi zmdi-check-square" /> {subCategory.count} </span>
                                                </div>

                                            ))
                                        }
                                    </>
                                ) : (
                                    <div className="dd-handle align-items-center text-center">
                                        <div className='d-flex justify-content-between'>
                                            <div> {displayTipCategoryGroups} </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-12">
                    <div className="card">
                        <div className="body taskboard b-green completed_task">
                            <h6 className="font300 mb-3">Sub Sub Categories
                                <span className="float-right">
                                    <i className="fa fa-plus-circle custom-icon" title='Assign Sub Sub Category'
                                        style={Object.keys(selectedSubCategory).length === 0 ? { display: 'none' } : {}}
                                        onClick={() => {
                                            setmodalType(0);
                                            setShowAssignSubSubCategoryModal(true);
                                        }}
                                    ></i>
                                </span>
                            </h6>
                            <div className="dd" data-plugin="nestable">

                                {subSubCategories.length > 0 ? (
                                    <>
                                        {
                                            subSubCategories.map((subSubCategory, i) => (

                                                <div
                                                    className={`dd-handle align-items-center ${selectedSubSubCategory === subSubCategory ? 'active' : ''}`}
                                                    key={subSubCategory.id}

                                                >
                                                    <div className='d-flex justify-content-between'>
                                                        <div> {subSubCategory.category} </div>
                                                        <div className="action">
                                                            <i className="fa fa-eye action-icon"
                                                                onClick={() => {
                                                                    setSelectedSubSubCategory(subSubCategory)
                                                                    setShowSubSubCategoriesModal(true);
                                                                }}
                                                            />
                                                            <i className="fa fa-trash-o ml-2"
                                                                onClick={() => removeSubSubCategoryFromGroup(subSubCategory.id)}></i>
                                                        </div>
                                                    </div>

                                                </div>
                                            ))
                                        }
                                    </>
                                ) : (
                                    <div className="dd-handle align-items-center text-center">
                                        <div className='d-flex justify-content-between'>
                                            <div> {displayTipSubCategory} </div>
                                        </div>
                                    </div>
                                )}

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <AssignCategoryGroupModal showAssignCategoryGroupModal={showAssignCategoryGroupModal}
                setShowAssignCategoryGroupModal={setShowAssignCategoryGroupModal}
                modalType={modalType} selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup}
                getAllCategoryGroups={getAllCategoryGroups} />
            <AssignSubCategoryModal showAssignSubCategoryModal={showAssignSubCategoryModal}
                setShowAssignSubCategoryModal={setShowAssignSubCategoryModal}
                selectedGroup={selectedGroup}
                getAllSubCategoryiesByGroup={getAllSubCategoryiesByGroup} />
            <AssignSubSubCategoriesModal showAssignSubSubCategoryModal={showAssignSubSubCategoryModal}
                setShowAssignSubSubCategoryModal={setShowAssignSubSubCategoryModal}
                selectedSubGroup={selectedSubCategory}
                getSubCategory={getSubCategory}
                getAllSubCategoryiesByGroup={getAllSubCategoryiesByGroup} />
            <SubCategoriesDetailsViewModal showSubCategoriesModal={showSubCategoriesModal}
                setShowSubCategoriesModal={setShowSubCategoriesModal}
                modalType={modalType}
                selectedSubCategory={subCategory} />
            <SubSubCategoriesDetailsViewModal showSubSubCategoriesModal={showSubSubCategoriesModal}
                setShowSubSubCategoriesModal={setShowSubSubCategoriesModal}
                modalType={modalType}
                selectedSubSubCategory={selectedSubSubCategory}
                getSubCategory={getSubCategory}
            />
        </div>
    )
}

export default BranchCategoriesGroup;