import axios from 'axios';
import React, {useState, useEffect} from 'react';
import toast, {Toaster} from 'react-hot-toast';
import Button from 'react-bootstrap-button-loader';

const AssignSubSubCategoriesModal = ({showAssignSubSubCategoryModal, setShowAssignSubSubCategoryModal, selectedSubGroup,
                                         getSubCategory, getAllSubCategoryiesByGroup}) => {

    const [baseUrl, setBaseUrl] = useState('');
    const [token, setToken] = useState('');
    const [userDetails, setUserDetails] = useState({});
    const [selectedBranch, setSelectedBranch] = useState('');

    const [buttonLoader, setButtonLoader] = useState(false);
    const [refresh, setRefresh] = useState(1);

    const [allSubSubCategories, setAllSubSubCategories] = useState([]);

    // get base url & token - useEffect
    useEffect(() => {
        if (showAssignSubSubCategoryModal) {
            setBaseUrl(localStorage.getItem('API_URL'));
            setToken(localStorage.getItem('user_token'));
            setUserDetails(JSON.parse(localStorage.getItem('user_details')));
            setSelectedBranch(JSON.parse(localStorage.getItem('selected_branch')));
            console.log("Selected sub group id: ", selectedSubGroup.id);
        }
    }, [showAssignSubSubCategoryModal])

    // call getRemainingCategoriesByCustomer - useEffect
    useEffect(() => {
        if (baseUrl.length > 0 && token.length > 0 && Object.keys(userDetails).length > 0) {
            getRemainingCategoriesByCustomer();
        }
    }, [baseUrl, token, userDetails])

    // getRemainingCategoriesByCustomer - function
    const getRemainingCategoriesByCustomer = () => {
        console.log('getRemainingCategoriesByCustomer function start');

        axios({
            method: 'GET',
            url: `${baseUrl}/category/get-remaining-categories/branch/${selectedBranch.id}`,
            headers: {
                Authorization: token
            },
        }).then((res) => {

            console.log('getRemainingCategoriesByCustomer success');
            let dataList = res.data.data;

            for (let j = 0; j < dataList.length; j++) {
                dataList[j].selected = false;
            }

            console.log(dataList);
            setAllSubSubCategories(dataList);

        }).catch((error) => {

            console.log('getRemainingCategoriesByCustomer error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })

    }

    // assignSubCategory - function
    const assignSubCategory = () => {
        console.log('assignSubCategory function start');

        setButtonLoader(true);

        let selectedSubCategoriesList = filterSelectedSubCategories();

        console.log('selectedSubCategoriesList >>', selectedSubCategoriesList);

        if (selectedSubCategoriesList.length > 0) {

            axios({
                method: 'POST',
                url: `${baseUrl}/category/assign-sub-sub-categories/sub-category-group/${selectedSubGroup.id}`,
                headers: {
                    Authorization: token
                },
                data: {
                    subSubCategories: selectedSubCategoriesList
                }
            }).then((res) => {

                console.log('assignSubCategory success');
                console.log(res.data.data);
                toast.remove();
                toast.success('Successfully added', {
                    position: 'bottom-center',
                });

                setButtonLoader(false);
                closeModal();
                getSubCategory();
                getAllSubCategoryiesByGroup();

            }).catch((error) => {

                console.log('assignSubCategory error');
                console.log(error);

                setButtonLoader(false);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }

            })

        } else {
            setButtonLoader(false);

            toast.remove();
            toast.error('Please select one or more', {
                position: 'bottom-center',
            });

        }


    }

    // filterSelectedSubCategories - function
    const filterSelectedSubCategories = () => {
        let selectedSubCategoriesList = []
        for (let i = 0; i < allSubSubCategories.length; i++) {
            if (allSubSubCategories[i].selected === true) {
                selectedSubCategoriesList.push(allSubSubCategories[i].id);
            }
        }
        return selectedSubCategoriesList;
    }

    // closeModal - function
    const closeModal = () => {
        for (let i = 0; i < allSubSubCategories.length; i++) {
            allSubSubCategories[i].selected = false;
        }
        setShowAssignSubSubCategoryModal(false);
    }

  return (
    <>
            <div><Toaster/></div>
            {showAssignSubSubCategoryModal ?
                <div className={`modal fade d-block show`}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title h4" id="mySmallModalLabel">Assign Sub Sub Category</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                        onClick={() => {
                                            closeModal();
                                        }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="body">
                                    <ul className="list-group">
                                        {allSubSubCategories.map((item, index) =>
                                            <li className={`list-group-item d-flex justify-content-between align-items-center ${item.selected === true ? 'active' : ''}`}
                                                key={index}
                                                onClick={() => {
                                                    item.selected = !item.selected;
                                                    setRefresh(refresh + 1);
                                                }}
                                            >
                                                {item.categoryName}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-default"
                                        onClick={() => {
                                            closeModal();
                                        }}
                                >Cancel
                                </button>
                                <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                        onClick={() => {
                                            assignSubCategory();
                                        }}
                                >Save changes</Button>
                            </div>

                        </div>
                    </div>
                </div>
                : ''}
        </>
  )
}

export default AssignSubSubCategoriesModal