import React, { useState, useEffect } from 'react';
import { Col, Nav, Row, Tab, Tabs } from 'react-bootstrap';
import BasicBarChart from '../../ChartsImpl/DetailsPageCharts/BasicBarChart';
import MUIDataTable from 'mui-datatables';
import { createTheme } from "@mui/material";
import toast from "react-hot-toast";
import axios from 'axios';
import CircularProgress from "@mui/material/CircularProgress";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import DoNotStepIcon from '@mui/icons-material/DoNotStep';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const TransferTokenData = (props) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [count, setCount] = useState(0);
    const [ticketNumber, setTicketNumber] = useState(null);
    const [tokenId, setTokenId] = useState(null)
    const [showTimeLine, setShowTimeLine] = useState(false);
    const [list, setList] = useState([]);
    const [tokenData, setTokenData] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const [update, setUpdate] = useState('1s')

    useEffect(() => {
        if (props.showTransferTicketDetails === false) return;
        if (props.transferInc > 0 || props.messageInc9999 !== '' || props.messageInc1001 !== '') {
            getTransferTicket();
        }
    }, [props.transferInc, props.messageInc9999, props.messageInc1001]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (tokenData !== null && tokenData.length > 0 && tokenData.servingTimeToSec !== null) {
                for (let index = 0; index < tokenData.length; index++) {
                    const element = tokenData[index];
                    element.servingTimeToSec = element.servingTimeToSec + 5;

                    let hours = Math.floor(element.servingTimeToSec / 3600);
                    let minutes = Math.floor((element.servingTimeToSec - (hours * 3600)) / 60);
                    let seconds = element.servingTimeToSec - (hours * 3600) - (minutes * 60);

                    if (hours < 10) { hours = "0" + hours; }
                    if (minutes < 10) { minutes = "0" + minutes; }
                    if (seconds < 10) { seconds = "0" + seconds; }

                    element.servingTime = `${hours}:${minutes}:${seconds}`;
                }

                console.log("token data: ", tokenData);

                setTokenData(tokenData);
                setUpdate(new Date().toString());
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [tokenData]);

    const columns = [
        {
            label: 'Token No',
            name: 'ticketNumber',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: "Transfered Category",
            name: 'category',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Original Category",
            name: 'transferCategory',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Language',
            name: 'lang',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'Transfered User',
            name: 'firstName',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Issued time',
            name: 'issuedTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Transfered time',
            name: 'transferTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Type',
            name: 'transferType',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Status',
            name: 'id',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    if (tokenData === undefined) return;
                    let item = tokenData.find(item => item.id === value);
                    return (<>
                        {item.category !== null ? (
                            <>
                                {item.status === 1 ? (<span className="badge badge-info ml-1 mb-2">{item.servingTime}</span>)
                                    : item.status === 2 ? (<span className="badge badge-warning ml-1 mb-2">no show</span>)
                                        : item.status === 3 ? (<span className="badge badge-warning ml-1 mb-2">waiting</span>)
                                            : item.status === 4 ? (<span className="badge badge-success ml-1 mb-2">served</span>)
                                                : item.status === -1 ? (<span className="badge badge-primary ml-1 mb-2">transfer</span>)
                                                    : (<span className="badge badge-default ml-1 mb-2">pending</span>)}
                            </>
                        ) : (null)}
                    </>
                    );
                }
            },
        },
        {
            label: 'Time Line',
            name: 'id',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    let item = tokenData.find(item => item.id === value);
                    return (
                        <div style={{ cursor: 'pointer' }} onClick={() => { timeline(item.ticketId); setTokenId(item.ticketId); setTicketNumber(item.ticketNumber); setShowTimeLine(true) }}>
                            <i className="fa fa-long-arrow-right" aria-hidden="true" />
                        </div>
                    )
                }
            }
        }
    ];

    const columns_1 = [
        {
            label: 'Token No',
            name: 'ticketNumber',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: "Transfered Category",
            name: 'category',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Original Category",
            name: 'transferCategory',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Language',
            name: 'lang',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'Transfered User',
            name: 'firstName',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Issued time',
            name: 'issuedTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Transfered time',
            name: 'transferTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Type',
            name: 'transferType',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Status',
            name: 'id',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    if (tokenData === undefined) return;
                    let item = tokenData.find(item => item.id === value);
                    return (<>
                        {item.category !== null ? (
                            <>
                                {item.status === 1 ? (<span className="badge badge-info ml-1 mb-2">{item.servingTime}</span>)
                                    : item.status === 2 ? (<span className="badge badge-warning ml-1 mb-2">no show</span>)
                                        : item.status === 3 ? (<span className="badge badge-warning ml-1 mb-2">waiting</span>)
                                            : item.status === 4 ? (<span className="badge badge-success ml-1 mb-2">served</span>)
                                                : item.status === -1 ? (<span className="badge badge-primary ml-1 mb-2">transfer</span>)
                                                    : (<span className="badge badge-default ml-1 mb-2">pending</span>)}
                            </>
                        ) : (null)}
                    </>
                    );
                }
            },
        },
        {
            label: 'Time Line',
            name: 'id',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    let item = tokenData.find(item => item.id === value);
                    return (
                        <div style={{ cursor: 'pointer' }} onClick={() => { timeline(item.ticketId); setTokenId(item.ticketId); setTicketNumber(item.ticketNumber); setShowTimeLine(true) }}>
                            <i className="fa fa-long-arrow-right" aria-hidden="true" />
                        </div>
                    )
                }
            }
        }
    ];

    const options = {
        tableBodyHeight: '50vh',
        filter: false,
        responsive: 'vertical',
        serverSide: false,
        count: count,
        download: false,
        viewColumns: false,
        jumpToPage: true,
        selectableRows: 'none',
        print: false,
        rowsPerPage: rowsPerPage,
        onChangeRowsPerPage: (n) => {
            setRowsPerPage(n)
        },
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        }
    };

    const getTransferTicket = () => {
        setLoading(true);

        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/get-transfer-tickets/inner-data/branch/${props.selectedBranchId}/get-transfer-tickets-data`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('getTransferTicket success');
            let data = res.data.data;

            if (!showTimeLine) {
                timeline(res.data.data[0].ticketId)
                setTicketNumber(res.data.data[0].ticketNumber)
            } else {
                timeline(tokenId)
            }

            setTokenData(data);
            setCount(data.length);
            setLoading(false);

        }).catch((error) => {
            console.log('getTransferTicket error');
            console.log(error);
            setLoading(false);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    const timeline = (tid) => {

        axios({
            method: 'POST',
            url: `${baseUrl}/ticket/timeline/${tid}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            const data = res.data.data;
            setList(data)

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    };

    return (
        <>
            {props.showTransferTicketDetails ?
                <div id="megamenu" className={`megamenu particles_js details-page open `}>
                    <span
                        onClick={() => {
                            props.setSticky(true)
                            props.setShowTransferTicketDetails(false);
                        }}
                        className="megamenu_toggle btn btn-danger"><i className="icon-close"></i></span>
                    <div className="container">
                        <div className="row clearfix">
                            <div className="col-12">
                                <div className="card">
                                    <div className="body">
                                        <div style={{ textAlign: 'center' }}>
                                            <h4>Transfer Tickets Data</h4>
                                            <small>{props.date}</small>
                                        </div>
                                        <br></br>
                                        <div className="row clearfix">
                                            <div className="col-5">
                                                <Timeline position="">
                                                    <h6>Ticket No: <b>{ticketNumber}</b></h6>
                                                    {
                                                        list.length > 0 ?
                                                            (<TimelineItem>
                                                                <TimelineOppositeContent
                                                                    sx={{ m: 'auto 0' }}
                                                                    align="right"
                                                                    variant="body2"
                                                                >
                                                                    <b>{list[0].issuedTime}</b>
                                                                </TimelineOppositeContent>
                                                                <TimelineSeparator style={{ height: '95px' }}>
                                                                    <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                                                                    <TimelineDot color={"secondary"}>
                                                                        <ConfirmationNumberIcon />
                                                                    </TimelineDot>
                                                                    <TimelineConnector />
                                                                </TimelineSeparator>
                                                                <TimelineContent sx={{ py: '1px', px: 2, m: 'auto 0' }}>
                                                                    <Typography>{list[0].category}</Typography>
                                                                </TimelineContent>
                                                            </TimelineItem>
                                                            ) : '.'
                                                    }
                                                    {
                                                        list.map((item) =>
                                                            <TimelineItem>
                                                                <TimelineOppositeContent
                                                                    sx={{ m: 'auto 0' }}
                                                                    align="right"
                                                                    variant="body2"
                                                                >
                                                                    {item.counterNo !== undefined ? (
                                                                        <Typography>Counter {item.counterNo}</Typography>
                                                                    ) : (
                                                                        <Typography>{item.issuedTime}</Typography>
                                                                    )}
                                                                    <b>{item.startTime}</b> {item.endTime !== undefined ? <>- <b>{item.endTime}</b></> : ''}
                                                                    {item.timeDiff !== undefined ? (
                                                                        <p><small>{item.timeDiff}</small></p>
                                                                    ) : (null)}

                                                                </TimelineOppositeContent>
                                                                <TimelineSeparator style={{ height: '100px' }}>
                                                                    <TimelineConnector />
                                                                    <TimelineDot
                                                                        color={item.status === undefined ? "grey" : item.status === 1 ? "primary" : item.status === 2 ? "error" : item.status === 3 ? "info" : "success"}>
                                                                        {item.status === undefined ? <ConfirmationNumberIcon /> :
                                                                            item.status === 1 ? <RoomServiceIcon /> :
                                                                                item.status === 2 ? <DoNotStepIcon /> :
                                                                                    item.status === 3 ? <PauseCircleFilledIcon /> :
                                                                                        item.status === 4 ? <CheckCircleIcon /> : ''}
                                                                    </TimelineDot>
                                                                    <TimelineConnector />
                                                                </TimelineSeparator>
                                                                <TimelineContent sx={{ py: '1px', px: 2, m: 'auto 0' }}>
                                                                    <Typography variant="h6" component="span">
                                                                        {item.category}
                                                                    </Typography>
                                                                    <Typography>{item.teller}</Typography>
                                                                </TimelineContent>
                                                            </TimelineItem>
                                                        )
                                                    }
                                                </Timeline>
                                            </div>
                                            <div className="col-7">
                                                <div className="muidatatablecontainer">
                                                    {props.selectedBranchLevel === 4 ? (
                                                        <MUIDataTable
                                                            title={<>
                                                                {count}{count === 1 ? ' record' : ' records'}
                                                                <Tooltip title="Transfered tokens count with 'Additional Services' records. Therefore Transfer tokens count and Records count might different. Check it by 'Token No'">
                                                                    <IconButton size="small">
                                                                        {/* <DeleteIcon /> */}
                                                                        <i className='fa fa-exclamation-circle text-info' />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                {isLoading &&
                                                                    <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                                                        size={20} /></>}
                                                            </>
                                                            }
                                                            data={tokenData}
                                                            columns={columns}
                                                            options={options}
                                                        />
                                                    ) : (
                                                        <MUIDataTable
                                                            title={<>
                                                                {count}{count === 1 ? ' record' : ' records'}
                                                                <Tooltip title="Transfered tokens count with 'Additional Services' records. Therefore Transfer tokens count and Records count might different. Check it by 'Token No'">
                                                                    <IconButton size="small">
                                                                        {/* <DeleteIcon /> */}
                                                                        <i className='fa fa-exclamation-circle text-info' />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                {isLoading &&
                                                                    <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                                                        size={20} /></>}
                                                            </>
                                                            }
                                                            data={tokenData}
                                                            columns={columns_1}
                                                            options={options}
                                                        />
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div id="particles-js"></div>
                </div>
                : null}
        </>
    )
}

export default TransferTokenData