
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Accordion, Button } from 'react-bootstrap';
import axios from 'axios';

import DatePicker from "react-datepicker";
import moment from 'moment';

import RealTimeReports from '../ReportsImpl/RealTimeReports';
import QueueReports from '../ReportsImpl/QueueReports';
import TokenWiseReports from '../ReportsImpl/TokenWiseReports';
import SkillWiseReports from '../ReportsImpl/SkillWiseReports';
import AgentWiseReports from '../ReportsImpl/AgentWiseReports';
import BranchReports from '../ReportsImpl/BranchReports';
import CategoryReports from '../ReportsImpl/CategoryReports';


const Report = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');


    // filter by region
    const [countriesList, setCountriesList] = useState(undefined);
    const [provinceList, setProvinceList] = useState(undefined);
    const [districtList, setDistrictList] = useState(undefined);
    const [branchList, setBranchList] = useState(undefined);

    const [selectedCountry, setSelectedCountry] = useState({});
    const [selectedProvice, setSelectedProvince] = useState({});
    const [selectedDistrict, setSelectedDistrict] = useState({});
    const [selectedBranch, setSelectedBranch] = useState({});

    const [checkedBranchLevel, setCheckedBranchLevel] = useState(0);

    const [selectedRegionIds, setselectedRegionIds] = useState([]);
    const [selectedRegionNames, setselectedRegionNames] = useState([]);
    const [changeRegionIds, setChangeRegionIds] = useState(0);
    const [reportUrl, setReportUrl] = useState(null);

    const [refresh, setRefresh] = useState(0);


    // set today date
    useEffect(() => {
        setDateFrom(moment(startDate).format("YYYY-MM-DD"));
        setDateTo(moment(startDate).format("YYYY-MM-DD"));
        getReportUrl();
    }, [Report]);

    // call start up functions - useEffect
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            getAllRegion(1, 0);
            getUserBranchSequence();
        }
    }, [Report])

    // when select a country, get relavant provinces - useEffect
    useEffect(() => {
        if (Object.keys(selectedCountry).length > 0) {
            getAllRegion(2, selectedCountry.id);
        }
    }, [selectedCountry])

    // when select a province, get relavant district - useEffect
    useEffect(() => {
        if (Object.keys(selectedProvice).length > 0) {
            getAllRegion(3, selectedProvice.id);
        }
    }, [selectedProvice])

    // when select a district, get relavant branches - useEffect
    useEffect(() => {
        if (Object.keys(selectedDistrict).length > 0) {
            getAllRegion(4, selectedDistrict.id);
        }
    }, [selectedDistrict])


    // get all countries - function
    const getAllRegion = (level, parentId) => {

        axios({
            url: `${baseUrl}/branch/get-all/parent-branch/${parentId}`,
            method: 'GET',
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let result = res.data.data

            if (result === null) {
                result = []
            }

            // for (let j = 0; j < result.length; j++) {
            //     result[j].selected = false;
            // }

            if (level === 1) {
                setCountriesList(result);
            } else if (level === 2) {
                setProvinceList(result)
            } else if (level === 3) {
                setDistrictList(result)
            } else if (level === 4) {
                setBranchList(result)
            }

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })

    }


    // get user branch sequence - function
    const getUserBranchSequence = () => {
        axios({
            method: 'GET',
            url: `${baseUrl}/branch/get-sequence/branch/${userDetails.bid}`,
            // url: `${baseUrl}/branch/get-sequence/branch/${13}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let result = res.data.data;

            if (result.branchLevel === 1) {
                setSelectedCountry({
                    id: result.countryId,
                    name: result.countryName
                })
                setSelectedProvince({})
                setSelectedDistrict({})
                setSelectedBranch({})
            } else if (result.branchLevel === 2) {
                setSelectedCountry({
                    id: result.countryId,
                    name: result.countryName
                })
                setSelectedProvince({
                    id: result.provinceId,
                    name: result.provinceName
                })
                setSelectedDistrict({})
                setSelectedBranch({})
            } else if (result.branchLevel === 3) {
                setSelectedCountry({
                    id: result.countryId,
                    name: result.countryName
                })
                setSelectedProvince({
                    id: result.provinceId,
                    name: result.provinceName
                })
                setSelectedDistrict({
                    id: result.districtId,
                    name: result.districtName
                })
                setSelectedBranch({})
            } else if (result.branchLevel === 4) {
                setSelectedCountry({
                    id: result.countryId,
                    name: result.countryName
                })
                setSelectedProvince({
                    id: result.provinceId,
                    name: result.provinceName
                })
                setSelectedDistrict({
                    id: result.districtId,
                    name: result.districtName
                })
                setSelectedBranch({
                    id: result.branchId,
                    name: result.branchName
                })
            }

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }


    // clear states when click region - function
    const clearStates = (level) => {
        if (level === 1) {
            setDistrictList(undefined);
            setBranchList(undefined);

            setSelectedProvince({});
            setSelectedDistrict({});
        } else if (level === 2) {
            setBranchList(undefined);

            setSelectedDistrict({});
        }
    }

    // useEffect(() => {
    //     markCheckedBranchLevel(checkedBranchLevel);
    // }, [checkedBranchLevel])

    // mark checked branch level - function
    const markCheckedBranchLevel = (level) => {
        if (level === 1) {
            let selectedCountryList = [];
            for (let i = 0; i < countriesList.length; i++) {
                if (countriesList[i].selected === true) {
                    selectedCountryList.push(countriesList[i].id)
                }
            }
            if (selectedCountryList.length > 0) {
                setCheckedBranchLevel(1)
            } else {
                setCheckedBranchLevel(0)
            }
        } else if (level === 2) {
            let selectedProvinceList = [];
            for (let i = 0; i < provinceList.length; i++) {
                if (provinceList[i].selected === true) {
                    selectedProvinceList.push(provinceList[i].id)
                }
            }
            if (selectedProvinceList.length > 0) {
                setCheckedBranchLevel(2)
            } else {
                setCheckedBranchLevel(0)
            }
        } else if (level === 3) {
            let selectedDistrictList = [];
            for (let i = 0; i < districtList.length; i++) {
                if (districtList[i].selected === true) {
                    selectedDistrictList.push(districtList[i].id)
                }
            }
            if (selectedDistrictList.length > 0) {
                setCheckedBranchLevel(3)
            } else {
                setCheckedBranchLevel(0)
            }
        } else if (level === 4) {
            let selectedBranchList = [];
            for (let i = 0; i < branchList.length; i++) {
                if (branchList[i].selected === true) {
                    selectedBranchList.push(branchList[i].id)
                }
            }
            if (selectedBranchList.length > 0) {
                setCheckedBranchLevel(4)
            } else {
                setCheckedBranchLevel(0)
            }
        }
    }

    const addToSelectedRegionArray = (id, name) => {
        setselectedRegionIds(oldArray => [...oldArray, id]);
        setselectedRegionNames(oldArray => [...oldArray, { id: id, name: name }]);

        setChangeRegionIds(changeRegionIds + 1);
    }

    const removeFromSelectedRegionArray = (id) => {
        let tempIds = selectedRegionIds;
        let indexOfId = tempIds.indexOf(id);
        tempIds.splice(indexOfId, 1);
        setselectedRegionIds(tempIds);

        let tempNames = selectedRegionNames;
        let indexOfName = tempNames.findIndex(element => element.id === id);
        tempNames.splice(indexOfName, 1);
        setselectedRegionNames(tempNames);

        setChangeRegionIds(changeRegionIds + 1);
    }



    // download report excel - function
    const downloadExcel = (reportName, tableKey) => {
        let localStorageData = JSON.parse(localStorage.getItem(tableKey));

        axios({
            method: 'POST',
            url: `${reportUrl}/report-controller/get/excel/${reportName}/from/${dateFrom}/to/${dateTo}/${selectedRegionIds.length > 0 ? selectedRegionIds.toString() : userDetails.bid}`,
            headers: {
                Authorization: token
            },
            responseType: 'blob',
            data: {
                filterList: localStorageData.columns
            }
        }).then((response) => {

            // Create a URL for the blob data
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create a temporary link to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${reportName}.xlsx`); // Set the desired file name
            document.body.appendChild(link);

            // Trigger the download
            link.click();

            // Remove the temporary link from the DOM
            document.body.removeChild(link);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    const getReportUrl = () => {
        fetch(process.env.PUBLIC_URL + '/configuration.json')
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setReportUrl(data.REPORT_URL)
        })
    };

    return (
        <>

            <div className="container-fluid">

                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">

                            {location.pathname.includes('/real-time') ? (
                                <h2>Real-time Reports</h2>
                            ) : location.pathname.includes('/queue') ? (
                                <h2>Queue Reports</h2>
                            ) : location.pathname.includes('/token-wise') ? (
                                <h2>Token Wise Reports</h2>
                            ) : location.pathname.includes('/skill-wise') ? (
                                <h2>Skill Wise Reports</h2>
                            ) : location.pathname.includes('/agent-wise') ? (
                                <h2>Agent Wise Reports</h2>
                            ) : location.pathname.includes('/branch') ? (
                                <h2>Branch Reports</h2>
                            ) : location.pathname.includes('/category') ? (
                                <h2>Category Reports</h2>
                            ) :
                                <h2>Reports</h2>
                            }


                        </div>
                    </div>
                </div>


                {/* select region */}
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Accordion className="accordion" id="accordion" >
                                            <div>
                                                <div className="card-header " id="headingOne">
                                                    <h5 className="mb-0">
                                                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                                            Filter
                                                            {/* <div className='vertically-align-center-flex'>

                                                                <div>
                                                                    {
                                                                        selectedRegionNames.map((item, i) =>
                                                                            <button className="button-animated" type="button"
                                                                                onClick={() => {
                                                                                    removeFromSelectedRegionArray(item.id)
                                                                                }}
                                                                            >
                                                                                <span className="button__text">{item.name}</span>
                                                                                <span className="button__icon">x</span>
                                                                            </button>
                                                                        )
                                                                    }
                                                                </div> */}


                                                            <div className="float-right"><i className="fa fa-chevron-circle-down"></i></div>
                                                            {/* </div> */}

                                                        </Accordion.Toggle>
                                                    </h5>
                                                </div>
                                                <Accordion.Collapse eventKey="0" className="collapse" >
                                                    <div className="body">
                                                        <div className='row clearfix'>
                                                            <div className="col-12">
                                                                <div className='animated-btn-container mb-3'>
                                                                    {
                                                                        selectedRegionNames.map((item, i) =>

                                                                            <button className="button-animated" type="button"
                                                                                onClick={() => {
                                                                                    removeFromSelectedRegionArray(item.id)
                                                                                }}
                                                                            >
                                                                                <span className="button__text">{item.name}</span>
                                                                                <span className="button__icon">x</span>
                                                                            </button>

                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='row clearfix'>

                                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <div className="card">
                                                                    <div className='taskboard b-cyan progress_task'>
                                                                        <h6 className="font300 mb-3">Country </h6>
                                                                        <div className="dd" data-plugin="nestable">

                                                                            {
                                                                                countriesList === undefined ? (
                                                                                    <div className={`dd-handle align-items-center`} >
                                                                                        <div className='d-flex justify-content-between'>
                                                                                            <div>Empty countries</div>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    <>
                                                                                        {countriesList.length > 0 ? (
                                                                                            <>
                                                                                                {countriesList.map((item, i) =>
                                                                                                    <div className={`dd-handle align-items-center position-relative ${selectedCountry.id === item.id ? 'active' : ''} `}>
                                                                                                        <div className='d-flex justify-content-between'
                                                                                                            title={item.name}
                                                                                                            onClick={() => {
                                                                                                                if (userDetails.branchLevel === 1) {
                                                                                                                    setSelectedCountry(item)
                                                                                                                    clearStates(1);
                                                                                                                    // markCheckedBranchLevel(1)
                                                                                                                }

                                                                                                            }}
                                                                                                        >
                                                                                                            <div className='region-sort-ellipsis'>{item.name}</div>
                                                                                                        </div>

                                                                                                        {
                                                                                                            userDetails.branchLevel === 1 ? (
                                                                                                                <label className="fancy-checkbox select-region-checkbox">
                                                                                                                    <input type="checkbox"
                                                                                                                        checked={selectedRegionIds.includes(item.id)}
                                                                                                                        onChange={(e) => {

                                                                                                                            if (e.target.checked) {
                                                                                                                                // item.selected = true;
                                                                                                                                // markCheckedBranchLevel(1)
                                                                                                                                addToSelectedRegionArray(item.id, item.name);
                                                                                                                                setRefresh(refresh + 1);
                                                                                                                            } else {
                                                                                                                                // item.selected = false;
                                                                                                                                // markCheckedBranchLevel(1)
                                                                                                                                removeFromSelectedRegionArray(item.id);
                                                                                                                                setRefresh(refresh + 1);
                                                                                                                            }
                                                                                                                        }}
                                                                                                                    />
                                                                                                                    <span></span>
                                                                                                                </label>
                                                                                                            ) : null
                                                                                                        }

                                                                                                    </div>
                                                                                                )}
                                                                                            </>
                                                                                        ) : (
                                                                                            <div className={`dd-handle align-items-center`} >
                                                                                                <div className='d-flex justify-content-between'>
                                                                                                    <div>Empty countries</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </>
                                                                                )
                                                                            }




                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <div className="card">
                                                                    <div className='taskboard b-cyan progress_task'>
                                                                        <h6 className="font300 mb-3">Province </h6>
                                                                        <div className="dd" data-plugin="nestable">

                                                                            {
                                                                                provinceList === undefined ? (
                                                                                    <div className={`dd-handle align-items-center`} >
                                                                                        <div className='d-flex justify-content-between'>
                                                                                            <div>Select a country</div>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    <>
                                                                                        {provinceList.length > 0 ? (
                                                                                            <>
                                                                                                {provinceList.map((item, i) =>
                                                                                                    <div className={`dd-handle align-items-center position-relative ${selectedProvice.id === item.id ? 'active' : ''} `}>
                                                                                                        <div className='d-flex vertically-align-center-flex'
                                                                                                            title={item.name}
                                                                                                            onClick={() => {
                                                                                                                if (userDetails.branchLevel === 1) {
                                                                                                                    setSelectedProvince(item)
                                                                                                                    clearStates(2);
                                                                                                                    // markCheckedBranchLevel(2)
                                                                                                                }
                                                                                                            }}
                                                                                                        >
                                                                                                            <div className='region-sort-ellipsis'>{item.name}</div>
                                                                                                        </div>

                                                                                                        {
                                                                                                            userDetails.branchLevel === 1 ? (
                                                                                                                <label className="fancy-checkbox select-region-checkbox">
                                                                                                                    <input type="checkbox"
                                                                                                                        checked={selectedRegionIds.includes(item.id)}
                                                                                                                        onChange={(e) => {
                                                                                                                            if (e.target.checked) {
                                                                                                                                // item.selected = true;
                                                                                                                                // markCheckedBranchLevel(2)
                                                                                                                                addToSelectedRegionArray(item.id, item.name);
                                                                                                                                setRefresh(refresh + 1);
                                                                                                                            } else {
                                                                                                                                // item.selected = false;
                                                                                                                                // markCheckedBranchLevel(2)
                                                                                                                                removeFromSelectedRegionArray(item.id);
                                                                                                                                setRefresh(refresh + 1);
                                                                                                                            }
                                                                                                                        }}
                                                                                                                    />
                                                                                                                    <span></span>
                                                                                                                </label>
                                                                                                            ) : null
                                                                                                        }

                                                                                                    </div>
                                                                                                )}
                                                                                            </>
                                                                                        ) : (
                                                                                            <div className={`dd-handle align-items-center`} >
                                                                                                <div className='d-flex justify-content-between'>
                                                                                                    <div>Empty province</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </>
                                                                                )
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <div className="card">
                                                                    <div className='taskboard b-cyan progress_task'>
                                                                        <h6 className="font300 mb-3">District </h6>
                                                                        <div className="dd" data-plugin="nestable">

                                                                            {
                                                                                districtList === undefined ? (
                                                                                    <div className={`dd-handle align-items-center`} >
                                                                                        <div className='d-flex justify-content-between'>
                                                                                            <div>Select a province</div>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    <>
                                                                                        {districtList.length > 0 ? (
                                                                                            <>
                                                                                                {districtList.map((item, i) =>
                                                                                                    <div className={`dd-handle align-items-center position-relative ${selectedDistrict.id === item.id ? 'active' : ''} `}>
                                                                                                        <div className='d-flex justify-content-between'
                                                                                                            title={item.name}
                                                                                                            onClick={() => {
                                                                                                                if (userDetails.branchLevel === 1 || userDetails.branchLevel === 2) {
                                                                                                                    setSelectedDistrict(item)
                                                                                                                    clearStates(3);
                                                                                                                    // markCheckedBranchLevel(3)
                                                                                                                }
                                                                                                            }}
                                                                                                        >
                                                                                                            <div className='region-sort-ellipsis'>{item.name}</div>
                                                                                                        </div>
                                                                                                        {
                                                                                                            userDetails.branchLevel === 1 || userDetails.branchLevel === 2 ? (
                                                                                                                <label className="fancy-checkbox select-region-checkbox">
                                                                                                                    <input type="checkbox"
                                                                                                                        checked={selectedRegionIds.includes(item.id)}
                                                                                                                        onChange={(e) => {
                                                                                                                            if (e.target.checked) {
                                                                                                                                // item.selected = true;
                                                                                                                                // markCheckedBranchLevel(3)
                                                                                                                                addToSelectedRegionArray(item.id, item.name)
                                                                                                                                setRefresh(refresh + 1);
                                                                                                                            } else {
                                                                                                                                // item.selected = false;
                                                                                                                                // markCheckedBranchLevel(3)
                                                                                                                                removeFromSelectedRegionArray(item.id)
                                                                                                                                setRefresh(refresh + 1);
                                                                                                                            }
                                                                                                                        }}
                                                                                                                    />
                                                                                                                    <span></span>
                                                                                                                </label>
                                                                                                            ) : null
                                                                                                        }

                                                                                                    </div>
                                                                                                )}
                                                                                            </>
                                                                                        ) : (
                                                                                            <div className={`dd-handle align-items-center`} >
                                                                                                <div className='d-flex justify-content-between'>
                                                                                                    <div>Empty district</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </>
                                                                                )
                                                                            }



                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <div className="card">
                                                                    <div className='taskboard b-cyan progress_task'>
                                                                        <h6 className="font300 mb-3">Branch</h6>
                                                                        <div className="dd" data-plugin="nestable">

                                                                            {
                                                                                branchList === undefined ? (
                                                                                    <div className={`dd-handle align-items-center`} >
                                                                                        <div className='d-flex justify-content-between'>
                                                                                            <div>Select a district</div>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    <>
                                                                                        {branchList.length > 0 ? (
                                                                                            <>
                                                                                                {branchList.map((item, i) =>
                                                                                                    <div className={`dd-handle align-items-center position-relative ${selectedBranch.id === item.id ? 'active' : ''}`}>
                                                                                                        <div className='d-flex justify-content-between'
                                                                                                            title={item.name}
                                                                                                        >
                                                                                                            <div className='region-sort-ellipsis'>{item.name}</div>
                                                                                                        </div>

                                                                                                        {
                                                                                                            userDetails.branchLevel === 1 || userDetails.branchLevel === 2 || userDetails.branchLevel === 3 ? (

                                                                                                                <label className="fancy-checkbox select-region-checkbox">
                                                                                                                    <input type="checkbox"
                                                                                                                        checked={selectedRegionIds.includes(item.id)}
                                                                                                                        onChange={(e) => {
                                                                                                                            if (e.target.checked) {
                                                                                                                                // item.selected = true;
                                                                                                                                // markCheckedBranchLevel(4)
                                                                                                                                addToSelectedRegionArray(item.id, item.name)
                                                                                                                                setRefresh(refresh + 1);
                                                                                                                            } else {
                                                                                                                                // item.selected = false;
                                                                                                                                // markCheckedBranchLevel(4)
                                                                                                                                removeFromSelectedRegionArray(item.id);
                                                                                                                                setRefresh(refresh + 1);
                                                                                                                            }
                                                                                                                        }}
                                                                                                                    />
                                                                                                                    <span></span>
                                                                                                                </label>

                                                                                                            ) : userDetails.branchLevel === 4 ? (
                                                                                                                <>
                                                                                                                    {userDetails.bid === item.id ? (
                                                                                                                        <label className="fancy-checkbox select-region-checkbox">
                                                                                                                            <input type="checkbox"
                                                                                                                                checked={selectedRegionIds.includes(item.id)}
                                                                                                                                onChange={(e) => {
                                                                                                                                    if (e.target.checked) {
                                                                                                                                        // item.selected = true;
                                                                                                                                        // markCheckedBranchLevel(4)
                                                                                                                                        addToSelectedRegionArray(item.id, item.name);
                                                                                                                                        setRefresh(refresh + 1);
                                                                                                                                    } else {
                                                                                                                                        // item.selected = false;
                                                                                                                                        // markCheckedBranchLevel(4)
                                                                                                                                        removeFromSelectedRegionArray(item.id);
                                                                                                                                        setRefresh(refresh + 1);
                                                                                                                                    }
                                                                                                                                }}
                                                                                                                            />
                                                                                                                            <span></span>
                                                                                                                        </label>
                                                                                                                    ) : null}
                                                                                                                </>
                                                                                                            ) : null
                                                                                                        }

                                                                                                    </div>
                                                                                                )}
                                                                                            </>
                                                                                        ) : (
                                                                                            <div className={`dd-handle align-items-center`} >
                                                                                                <div className='d-flex justify-content-between'>
                                                                                                    <div>Empty branch</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </>
                                                                                )
                                                                            }



                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>



                                                        </div>
                                                    </div>
                                                </Accordion.Collapse>
                                            </div>
                                        </Accordion>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div >


                {/* date selector */}
                {location.pathname.includes('/real-time') ?
                    null
                    : (
                        <div className="row clearfix">
                            <div className="col-lg-12">

                                <div className='card'>
                                    <div className='body'>

                                        <div className='d-flex row horizontal-align-center-flex'>


                                            <div className='col-lg-6 col-md-6'>
                                                <div>

                                                    <div className="input-daterange input-group" data-provide="datepicker">

                                                        <DatePicker className="input-sm form-control"
                                                            selected={startDate}
                                                            onChange={(date) => {
                                                                const dateFrom = moment(date).format("YYYY-MM-DD");
                                                                setStartDate(date)
                                                                setDateFrom(dateFrom)
                                                            }} />



                                                        <span className="input-group-addon range-to">to</span>

                                                        <DatePicker className="input-sm form-control"
                                                            selected={endDate}
                                                            onChange={(date) => {
                                                                const dateTo = moment(date).format("YYYY-MM-DD");
                                                                setEndDate(date)
                                                                setDateTo(dateTo)
                                                            }} />

                                                    </div>

                                                </div>

                                            </div>


                                        </div>


                                    </div>
                                </div>

                            </div>
                        </div>
                    )}


                {location.pathname.includes('/real-time') ? (
                    <RealTimeReports selectedRegionIds={selectedRegionIds} changeRegionIds={changeRegionIds} />
                ) : location.pathname.includes('/queue') ? (
                    <QueueReports selectedRegionIds={selectedRegionIds}
                        changeRegionIds={changeRegionIds} dateFrom={dateFrom} dateTo={dateTo} downloadExcel={downloadExcel} reportUrl={reportUrl}  />
                ) : location.pathname.includes('/token-wise') ? (
                    <TokenWiseReports selectedRegionIds={selectedRegionIds}
                        changeRegionIds={changeRegionIds} dateFrom={dateFrom} dateTo={dateTo} downloadExcel={downloadExcel} reportUrl={reportUrl}  />
                ) : location.pathname.includes('/skill-wise') ? (
                    <SkillWiseReports selectedRegionIds={selectedRegionIds}
                        changeRegionIds={changeRegionIds} dateFrom={dateFrom} dateTo={dateTo} downloadExcel={downloadExcel} reportUrl={reportUrl}  />
                ) : location.pathname.includes('/agent-wise') ? (
                    <AgentWiseReports selectedRegionIds={selectedRegionIds}
                        changeRegionIds={changeRegionIds} dateFrom={dateFrom} dateTo={dateTo} downloadExcel={downloadExcel} reportUrl={reportUrl}  />
                ) : location.pathname.includes('/branch') ? (
                    <BranchReports selectedRegionIds={selectedRegionIds}
                        changeRegionIds={changeRegionIds} dateFrom={dateFrom} dateTo={dateTo} downloadExcel={downloadExcel} reportUrl={reportUrl}  />
                ) : location.pathname.includes('/category') ? (
                    <CategoryReports selectedRegionIds={selectedRegionIds}
                        changeRegionIds={changeRegionIds} dateFrom={dateFrom} dateTo={dateTo} downloadExcel={downloadExcel} reportUrl={reportUrl}  />
                ) :
                    null
                }


            </div >

        </>
    )
}

export default Report
