import axios from "axios";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';


const AssignUserCategoryModal = ({ showAddUserCategoryModal, setShowAddUserCategoryModal, selectedUser }) => {


    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));
    const selectedBranch = (JSON.parse(localStorage.getItem('selected_branch')));

    const [allCategories, setAllCategories] = useState('')
    const [refresh, setRefresh] = useState(1);
    const [selectedCategoryId, setSelectedCategoryId] = useState('');


    //getUserAssignedCategories - useeffect
    useEffect(() => {
        if (showAddUserCategoryModal) {
            getUserAssignedCategories();
        }
    }, [showAddUserCategoryModal])


    //getUserAssignedCategories - function
    const getUserAssignedCategories = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/user/get-list/assigned-categories/user/${selectedUser.id}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            let dataList = res.data.data;
            setAllCategories(dataList);


        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }


    //assignCategorytoUsernew - function
    const assignCategorytoUsernew = (id) => {

        axios({
            method: 'POST',
            url: `${baseUrl}/user/assign-category/user/${selectedUser.id}/category/${id}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }


    const closeModal = () => {
        setShowAddUserCategoryModal(false)
    }


    return (
        <>
            <div><Toaster /></div>
            {showAddUserCategoryModal ?
                <div className={`modal fade  d-block show modal-container`} id="exampleModal">
                    <div className="modal-dialog  modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Assign Category</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        closeModal()
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <div className="row clearfix">
                                    {
                                        allCategories.length > 0 ? (
                                            allCategories.map((categories, i) => (
                                                <div className='col-lg-4 col-md-4 p-l-5 p-r-5 mb-2' key={i}>
                                                    <div
                                                        className={`list-group-item d-flex justify-content-between align-items-center cursor-pointer ${categories.selected ? 'active' : ''}`}
                                                        title={categories.categoryName}
                                                        onClick={() => {
                                                            //get category id first
                                                            assignCategorytoUsernew(categories.categoryId);
                                                            setSelectedCategoryId(categories.selected);
                                                            categories.selected = !categories.selected;
                                                            setRefresh(refresh + 1);
                                                        }}
                                                    >
                                                        <p className="modal-lg-select-card-text">{categories.categoryName}</p>
                                                    </div>
                                                </div>
                                            ))

                                        ) : (
                                            <div className="col-md-12">
                                                <div className="alert alert-custom alert-dismissible" role="alert">
                                                    <i className="fa fa-info-circle"></i> Empty records
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal"
                                    onClick={() => {
                                        closeModal()
                                    }}
                                >Cancel
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
                : ''}
        </>
    )
}

export default AssignUserCategoryModal