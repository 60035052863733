import { max } from 'moment/moment';
import React, { Component } from 'react';
import ReactApexChart from "react-apexcharts";


class TokenPerHourSparkChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            series: [{
                name: 'Issued Tickets',
                data: this.props.hourlyIssuedTickets
            },{
                name: 'Served Tickets',
                data: this.props.hourlyServedTickets
            },{
                name: 'Local Waiting Tickets',
                data: this.props.hourlyLocalWaitingTickets
            },{
                name: 'No Show Tickets',
                data: this.props.hourlyNoShowTickets
            },{
                name: 'Waiting Exceptions',
                data: this.props.hourlyWaitingExceptions
            },{
                name: 'Service Exceptions',
                data: this.props.hourlyServingExceptions
            }],
            options: {
                chart: {
                    height: 285,
                    type: 'area',
                    toolbar: {
                        show: false
                    }
                },
                grid: {
                    show: false
                },
                dataLabels: {
                    enabled: false
                },
                colors: ['#84F5EE', '#83FB73', '#F5FB73','#7392FB','#F3C362','#F37862'],
                markers: {
                    size: 3,
                    strokeColor: ['#84F5EE', '#83FB73', '#F5FB73','#7392FB','#F3C362','#F37862'],
                  },
                stroke: {
                    width: 2,
                    curve: 'smooth',
                },
                xaxis: {
                    type: 'category',
                    axisTicks: { show: false },
                    axisBorder: { show: false },
                    categories: this.props.hourRanges,
                    labels: {
                        show: true
                    },
                    title: {
                        text: 'Hour Range',
                    },
                },
                yaxis: {
                    tickAmount: 5,
                    min: 0,
                    max: Math.max.apply(null, this.props.chartMaxValue),
                    crosshairs: {
                        show: true,
                        width:1
                    },
                    title: {
                        text: 'Ticket Count',
                    },
                },
                legend: {
                    show: true
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy HH:mm'
                    },
                },
            },
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.chartValue !== this.props.chartValue) {
            this.setState({
                series: [{
                    name: 'Issued Tickets',
                    data: this.props.hourlyIssuedTickets
                },{
                    name: 'Served Tickets',
                    data: this.props.hourlyServedTickets
                },{
                    name: 'Local Waiting Tickets',
                    data: this.props.hourlyLocalWaitingTickets
                },{
                    name: 'No Show Tickets',
                    data: this.props.hourlyNoShowTickets
                },{
                    name: 'Waiting Exceptions',
                    data: this.props.hourlyWaitingExceptions
                },{
                    name: 'Service Exceptions',
                    data: this.props.hourlyServingExceptions
                }],
                options: {
                    chart: {
                        height: 285,
                        type: 'area',
                        toolbar: {
                            show: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    colors: ['#84F5EE', '#83FB73', '#F5FB73','#7392FB','#F3C362','#F37862'],
                    markers: {
                        size: 3,
                        strokeColor: ['#84F5EE', '#83FB73', '#F5FB73','#7392FB','#F3C362','#F37862'],
                    },
                    stroke: {
                        width: 2,
                        curve: 'smooth',
                    },
                    xaxis: {
                        type: 'category',
                        axisTicks: { show: false },
                        axisBorder: { show: false },
                        categories: this.props.hourRanges,
                        labels: {
                            show: true
                        }
                    },
                    yaxis: {
                        tickAmount: 5,
                        min: 0,
                        max: Math.max.apply(null, this.props.chartMaxValue),
                        crosshairs: {
                            show: true,
                            width:1
                        }
                    },
                    legend: {
                        show: true
                    },
                    tooltip: {
                        x: {
                            format: 'dd/MM/yy HH:mm'
                        },
                    },
                },
            });
        }
    }

    render() {
        return (
            <div>
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="area"
                    height="344px"
                    width="100%"
                />
            </div>
        );
    }
}

export default TokenPerHourSparkChart;
