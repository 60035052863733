import axios from "axios";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Button from "react-bootstrap-button-loader";
import { useLocation } from "react-router-dom";

const AppointmentBookingBranchModal = ({
  showBranchModal,
  setShowBranchModal,
  data,
  updateBranch,
}) => {
  const location = useLocation();

  const baseUrl = localStorage.getItem("API_URL");
  const token = localStorage.getItem("user_token");
  const userDetails = JSON.parse(localStorage.getItem("user_details"));

  const [buttonLoader, setButtonLoader] = useState(false);
  const [allBranches, setAllBranches] = useState([]);

  const [selectedBranch, setSelectedBranch] = useState("");

  // get base url - useEffect
  useEffect(() => {
    if (showBranchModal) {
      if (
        baseUrl.length > 0 &&
        token.length > 0 &&
        Object.keys(userDetails).length > 0
      ) {
        getAllBranchsByCid();
      }

      if (data != null) {
        setSelectedBranch(data);
      }
    }
  }, [showBranchModal]);

  // get branches by customer id - function
  const getAllBranchsByCid = () => {
    axios({
      method: "GET",
      url: `${baseUrl}/branch/get-branches/customer/${userDetails.cid}`,
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        let data = res.data.data;
        setAllBranches(data);
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          toast.error(error.response.data.message, {
            position: "bottom-center",
          });
        } else {
          toast.error("Something went wrong", {
            position: "bottom-center",
          });
        }
      });
  };

  // closeModal - function
  const closeModal = () => {
    setButtonLoader(false);
    setShowBranchModal(false);
  };

  //update branch - function
  const UpdateBranch = () => {
    updateBranch(selectedBranch);
    closeModal();
  };

  return (
    <>
      <div>
        <Toaster />
      </div>
      {showBranchModal ? (
        <div className={`modal fade d-block show modal-container`}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Branches</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>

              <div className="modal-body">
                <div className="row clearfix">
                  <div className="col-lg-12 col-md-12">
                    <div
                      className="row clearfix"
                      style={{ maxHeight: "300px", overflowY: "scroll" }}
                    >
                      {allBranches.length > 0 ? (
                        allBranches.map((branch, index) => (
                          <div
                            className="col-lg-4 col-md-4 p-l-5 p-r-5 mb-2"
                            key={index}
                          >
                            <div
                              className={`list-group-item d-flex justify-content-between align-items-center cursor-pointer ${
                                branch.id === selectedBranch.id ? "active" : ""
                              }`}
                              title={branch.id}
                              onClick={() => {
                                setSelectedBranch(branch);
                              }}
                            >
                              <p className="modal-lg-select-card-text">
                                {branch.name}
                              </p>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div class="alert alert-custom alert-dismissible w-100 text-center">
                          <i class="fa fa-info-circle mr-2"></i> Empty records
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Cancel
                </button>

                <Button
                  loading={buttonLoader}
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    UpdateBranch();
                  }}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AppointmentBookingBranchModal;
