import React, { useEffect, useState, useRef } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import MUIDataTable from 'mui-datatables';
import CircularProgress from "@mui/material/CircularProgress";
import axios from 'axios';


const QueueDetailedReport = ({ openQueueDetailedReport, selectedRegionIds, changeRegionIds }) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [allreportsNew, setAllReportsNew] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const timeoutRef = useRef(null);


    const [dataTable, setDataTable] = useState({
        page: 0,
        size: 10,
        search: '',
        order: null,
    });

    const setTable = (tableState) => {
        setDataTable({
            page: tableState.page,
            search: tableState.searchText,
            size: tableState.rowsPerPage,
            order: tableState.sortOrder
        });
    };

    const columns = [
        {
            label: 'Branch',
            name: 'branch',
            options: {
                sort: true,
                filter: false,
                // customBodyRender: (value) => (
                //     <b>{value}</b>
                // )
            }
        },
        {
            label: "Region",
            name: 'region',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Token Created",
            name: 'tokensCreated',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Token Completed",
            name: 'tokensCompleted',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "No Tokens on hold",
            name: 'regionName',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "No abandoned tokens",
            name: 'tokensAbandoned',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "No Waiting Tokens",
            name: 'tokensWaiting',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Service Level",
            name: 'serviceLevel',
            options: {
                sort: true,
                filter: false,
                customBodyRender: (value) => {
                    return (<>

                        <p className='mb-0'>{value} %</p>

                    </>);
                }
            }
        },
        {
            label: "Abandonment Rate",
            name: 'abandonmentRate',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Average Waiting time",
            name: 'avgWaitTime',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Max Waiting time",
            name: 'maxWaitTime',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Average handled time",
            name: 'avgServiceTime',
            options: {
                sort: true,
                filter: false,
            }
        },


    ];

    const options = {
        // tableBodyHeight: '55vh',
        viewColumns: false,
        filterType: 'dropdown',
        filter: false,
        responsive: 'vertical',
        serverSide: true,
        count: count,
        download: false,
        jumpToPage: true,
        selectableRows: 'none',
        storageKey: '_rtr_qdr_table',
        print: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        },
        onTableChange: (action, tableState) => {


            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want

            switch (action) {
                case 'changePage':
                    getQueueReportDetailed(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'sort':
                    getQueueReportDetailed(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'changeRowsPerPage':
                    setRowsPerPage(tableState.rowsPerPage);
                    getQueueReportDetailed(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'search':
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }
                    timeoutRef.current = setTimeout(() => getQueueReportDetailed(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage), 2000);
                    setTable(tableState);
                    break;
                default:
                    break;
            }
        },
    };

    //useEffect - getQueueReportDetailed
    useEffect(() => {
        if (openQueueDetailedReport) {
            getQueueReportDetailed(0, null, null, rowsPerPage);
        }
    }, [openQueueDetailedReport]);

    // when change selected region array, call getQueueReportDetailed - useEffect
    useEffect(() => {
        if (openQueueDetailedReport) {
            getQueueReportDetailed(0, null, null, rowsPerPage);
        }
    }, [changeRegionIds])


    //getQueueReportDetailed - function
    const getQueueReportDetailed = (page, sortOrder, search, size) => {
        setIsLoading(true);

        axios({
            method: 'POST',
            url: `${baseUrl}/real-time/queue-report/inner-data/customer/${userDetails.cid}`,
            headers: {
                Authorization: token
            },
            data: {
                "page": page,
                "size": size,
                "sort": sortOrder,
                "searchText": search,
                branchIds: selectedRegionIds
            }

        }).then((res) => {

            let data = res.data.data;
            setAllReportsNew((data.content))
            setCount(data.totalElements);

            setIsLoading(false)

        }).catch((error) => {
            console.log(error);
            setIsLoading(false)

            if (error.response !== undefined) {
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    return (
        <>
            <div><ToastContainer /></div>

            <MUIDataTable
                title={
                    <>
                        {isLoading ?
                            <CircularProgress color="secondary" size={20} /> : <>{count} records.</>}
                    </>
                }
                data={allreportsNew}
                columns={columns}
                options={options}
                className='text-center-table'
            />

        </>
    )
}

export default QueueDetailedReport
