import React, {Component} from 'react';
import ReactApexChart from "react-apexcharts";

class LineChart extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            options: {
                chart: {
                    type: 'bar',
                    width: 100,
                    height: 83,
                    sparkline: {
                        enabled: true
                    }
                },
                colors: [this.props.color],
                tooltip: {
                    y: {
                        title: {
                            formatter: function () {
                                return 'Tickets: '
                            }
                        }
                    },
                    marker: {
                        show: true
                    }
                },

                xaxis: {
                    categories: this.props.hourRanges,
                }
            },
            series: [{
                data: this.props.data,
            }],
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.waitChartValue !== this.props.waitChartValue) {
            this.setState({
                options: {
                    chart: {
                        type: 'bar',
                        width: 100,
                        height: 83,
                        sparkline: {
                            enabled: true
                        }
                    },
                    colors: [this.props.color],
                    tooltip: {
                        y: {
                            title: {
                                formatter: function () {
                                    return 'Tickets: '
                                }
                            }
                        },
                        marker: {
                            show: true
                        }
                    },
    
                    xaxis: {
                        categories: this.props.hourRanges,
                    }
                },
                series: [{
                    data: this.props.data
                }],
                
            })
        }
    }

    render() {
        return (
            <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type="area"
                height={29}
                width="100%"
            />
        );
    }
}

export default LineChart;
