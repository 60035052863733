import React, { useEffect, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import SkillWiseReport from "../ReportsImpl/SkillWiseReportTables/SkillWiseReport";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const SkillWiseReports = ({
  selectedRegionIds,
  changeRegionIds,
  dateFrom,
  dateTo,
  reportUrl,
}) => {
  const baseUrl = localStorage.getItem("API_URL");
  const token = localStorage.getItem("user_token");
  const userDetails = JSON.parse(localStorage.getItem("user_details"));

  const [openSkillWiseReport, setOpenSkillWiseReport] = useState(false);

  const [reportsAccess, setReportsAccess] = useState([]);

  // call getReportAccess - useEffect
  useEffect(() => {
    if (reportUrl) {
      getReportAccess();
    }
  }, [SkillWiseReports, reportUrl]);

  // get report access - function
  const getReportAccess = () => {
    axios({
      method: "GET",
      url: `${reportUrl}/report-controller/get/report-access/customer/${userDetails.cid}/parent/Skill Wise Reports/get-report-access`,
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        let data = res.data.data;
        setReportsAccess(data);
      })
      .catch((error) => {
        console.log(error);

        if (error.response !== undefined) {
          toast.error(error.response.data.message, {
            position: "bottom-center",
          });
        } else {
          toast.remove();
          toast.error("Something went wrong", {
            position: "bottom-center",
          });
        }
      });
  };

  return (
    <>
      <div className="row clearfix">
        <div className="col-lg-12">
          <div className="card">
            <Accordion
              className="accordion report-accordion"
              id="accordion"
              defaultActiveKey=""
            >
              {reportsAccess.map((item, i) => {
                if (item.name === "Skill Wise Report" && item.active) {
                  return (
                    <div>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            className="btn btn-link vertically-align-center-flex"
                            onClick={() => {
                              if (openSkillWiseReport) {
                                setOpenSkillWiseReport(false);
                              } else {
                                setOpenSkillWiseReport(true);
                              }
                            }}
                          >
                            Skill Wise Report
                            <div className="float-right vertically-align-center-flex-2">
                              {userDetails !== null ? (
                                <>
                                  {/* <a href={`${baseUrl}/report-controller/download-pdf/original-ticket-details-report/${dateFrom}/${dateTo}/${selectedRegionIds.length > 0 ? selectedRegionIds.toString() : userDetails.bid}`}
                                                            target='_blank' className="btn btn-sm btn-default" title="View PDF"
                                                        ><i class="fa fa-file-pdf-o" aria-hidden="true"></i></a> */}

                                  {item.excel ? (
                                    <a
                                      href={`${reportUrl}/real-time/get/xl/skill-report/from/${dateFrom}/to/${dateTo}/${
                                        selectedRegionIds.length > 0
                                          ? selectedRegionIds.toString()
                                          : userDetails.bid
                                      }`}
                                      target="_blank"
                                      className="btn btn-sm btn-default"
                                      title="View Excel"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-excel-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  ) : null}
                                </>
                              ) : null}
                              <span className="p-l-20">
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </h5>
                      </div>
                      <Accordion.Collapse eventKey="0" className="collapse">
                        <SkillWiseReport
                          openSkillWiseReport={openSkillWiseReport}
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          selectedRegionIds={selectedRegionIds}
                          changeRegionIds={changeRegionIds}
                          reportUrl={reportUrl}
                        />
                      </Accordion.Collapse>
                    </div>
                  );
                }
              })}
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default SkillWiseReports;
