import React, { useEffect, useState } from "react";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import Button from 'react-bootstrap-button-loader';

const CreateUserModal = ({ createUser, setCreateUserModal, getUsersWithBranches, selectedBranchId }) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [buttonLoader, setButtonLoader] = useState(false);

    const [selectedImage, setSelectedImage] = useState({});
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [empId, setEmpId] = useState('');
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState('');
    const [genderType, setGenderType] = useState('');
    const [userType, setUserType] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [passWord, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [allTsuProfiles, setAllTsuProfiles] = useState([]);
    const [selectedTsuProfile, setSelectedTsuProfile] = useState('');

    const [allUserRoles, setAllUserRoles] = useState([]);
    const [selectedUserRole, setSelectedUserRole] = useState('');


    const config = {
        headers: {
            'content-type': 'multipart/form-data',
            "Authorization": token
        }
    }

    const genderOptions = [
        {
            label: "Select a gender",
            value: "Select a gender",
        },
        {
            label: "Male",
            value: "Male",
        },
        {
            label: "Female",
            value: "Female",
        },

    ];

    const userTypesOptions = [
        {
            label: "Teller",
            value: "1",
        },
        // {
        //     label: "Admin",
        //     value: "2",
        // },
        // {
        //     label: "Super Admin",
        //     value: "3",
        // },
        {
            label: "User",
            value: "4",
        },
        // {
        //     label: "Super User",
        //     value: "5",
        // },
    ]


    // call getAllRolesByCustomer function
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            if (baseUrl.length > 0 && token.length && Object.keys(userDetails).length > 0) {
                getAllRolesByCustomer();
            }
        }
    }, [CreateUserModal])

    // getAllRolesByCustomer
    const getAllRolesByCustomer = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/user-role/get-all-roles/${userDetails.cid}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let data = res.data.data;
            setAllUserRoles(data);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // when click user type to teller, get all tsu profiles
    useEffect(() => {
        if (userType === '1') {
            getAllTsuProfiles();
        }
    }, [userType])

    // getAllTsuProfiles - function
    const getAllTsuProfiles = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/branch/calling-pad-profile/get-list/customer/${userDetails.cid}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let data = res.data.data;
            setAllTsuProfiles(data);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })

    }

    // addNewUser - function
    let addNewUser = async (e) => {
        setButtonLoader(true);
        e.preventDefault();

        let isValidated = validation();

        if (isValidated) {

            let formData = new FormData();
            formData.append('cid', userDetails.cid);
            formData.append('userTypeId', userType);
            formData.append('username', displayName);
            formData.append('password', passWord);
            formData.append('firstName', firstName);
            formData.append('lastName', lastName);
            formData.append('employeeNumber', empId);
            formData.append('email', email);
            formData.append('contactNumber', contact);
            formData.append('gender', genderType);
            formData.append("profilePicture", selectedImage.pictureAsFile);
            if (userType === '1')
                formData.append('CPPId', selectedTsuProfile);
            formData.append('roleId', selectedUserRole);

            if (selectedBranchId === null) {
                selectedBranchId = 0;
            }

            formData.append('bid', selectedBranchId);


            axios.post(`${baseUrl}/user/add`, formData, config)
                .then(response => {
                    setButtonLoader(false);

                    toast.remove();
                    toast.success('User Successfully Added!', {
                        position: 'bottom-center',
                    });

                    closeModal();
                    getUsersWithBranches();

                })
                .catch(error => {
                    console.log(error);
                    setButtonLoader(false);

                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }


                });

        }



    }

    // validation - function
    const validation = () => {

        if (firstName.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('First name empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (lastName.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Last name empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (empId.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Employee id empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (genderType.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Gender empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (contact.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Contact number empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (email.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Email empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (userType.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('User type empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (userType === '1' && selectedTsuProfile.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('TSU profile empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (selectedUserRole.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('User role empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (displayName.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Display name empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (passWord.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Password empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (confirmPassword.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Confirm password empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (passWord !== confirmPassword) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Please check confirm password!', {
                position: 'bottom-center',
            });
            return false;
        } else {
            return true;
        }
    }

    // imageChangeHandle - function
    const imageChangeHandle = (e) => {
        setSelectedImage({
            picturePreview: URL.createObjectURL(e.target.files[0]),
            pictureAsFile: e.target.files[0],
        });
    };

    // close modal - function
    const closeModal = () => {
        setSelectedImage({});
        setFirstName('');
        setLastName('');
        setEmpId('');
        setEmail('');
        setContact('');
        setGenderType('');
        setUserType('');
        setDisplayName('');
        setPassword('');
        setConfirmPassword('');

        setCreateUserModal(false)
    };


    return (
        <>
            <div><Toaster /></div>
            {createUser ?
                <>

                    <div className={`modal fade  d-block show modal-container`} id="exampleModal">
                        <div className="modal-dialog " role="document">
                            <form className='user-form' onSubmit={addNewUser} encType="multipart/form-data">

                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Add User</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                            onClick={() => {
                                                closeModal()
                                            }}
                                        >
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">

                                        <div className="mb-3">
                                            <div className="circle text-center">
                                                <div className="personal-image">
                                                    <label className="label">
                                                        <input type="file" id="fileInput" name="image"
                                                            onChange={imageChangeHandle}
                                                            accept="image/*" />
                                                        <div className="personal-figure">

                                                            {selectedImage.picturePreview ?
                                                                <>
                                                                    <img src={selectedImage.picturePreview}
                                                                        className="personal-avatar" alt="avatar" />
                                                                    <div className="personal-figcaption">
                                                                        <img src={require('../../assets/images/others/camera-white.png')}
                                                                            alt="avatar" />
                                                                    </div>
                                                                </> : <>
                                                                    <img src={require('../../assets/images/others/no-profile.jpg')}
                                                                        className="personal-avatar" alt="avatar" />
                                                                    <div className="personal-figcaption">
                                                                        <img src={require('../../assets/images/others/camera-white.png')}
                                                                            alt="avatar" />
                                                                    </div>
                                                                </>
                                                            }

                                                        </div>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="row clearfix" style={{ height: '300px', overflow: 'auto' }}>

                                            <div className="container">
                                                <div className="controls">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group input-group-sm mb-3 modal-input-group">
                                                                <label htmlFor="form_name">First Name *</label>
                                                                <input type="text" className="form-control"
                                                                    value={firstName}
                                                                    onChange={(e) => setFirstName(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-group-sm mb-3 modal-input-group">
                                                                <label htmlFor="form_lastname">Last Name *</label>
                                                                <input type="text" className="form-control"
                                                                    value={lastName}
                                                                    onChange={(e) => setLastName(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div
                                                                className="form-group input-group-sm mb-3 modal-input-group">
                                                                <label htmlFor="form_name">Employee ID *</label>
                                                                <input id="form_name" type="text"
                                                                    name="name" className="form-control"
                                                                    value={empId}
                                                                    onChange={(e) => setEmpId(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-group-sm mb-3 modal-input-group">
                                                                <label htmlFor="form_need">Gender</label>
                                                                <select className="custom-select modal-select-field form-control"
                                                                    value={genderType}
                                                                    onChange={(e) => setGenderType(e.target.value)}>
                                                                    {genderOptions.map((option) => (
                                                                        <option key={option.value}>
                                                                            {option.value}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-group-sm mb-3 modal-input-group">
                                                                <label htmlFor="form_name">Contact Number *</label>
                                                                <input type="text" className="form-control"
                                                                    value={contact}
                                                                    onChange={(e) => setContact(e.target.value)}
                                                                />

                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-group-sm mb-3 modal-input-group">
                                                                <label htmlFor="form_lastname">Email *</label>
                                                                <input type="text"
                                                                    className="form-control"
                                                                    value={email}
                                                                    onChange={(e) => setEmail(e.target.value)} />
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="row">

                                                        <div className="col-md-6">
                                                            <div className="form-group input-group-sm mb-3 modal-input-group">
                                                                <label htmlFor="form_need">User Type *</label>
                                                                <select
                                                                    className="custom-select modal-select-field form-control"
                                                                    onChange={(e) => setUserType(e.target.value)}
                                                                    value={userType}>
                                                                    <option hidden>Select a user type</option>
                                                                    {userTypesOptions.map((option, i) => {
                                                                        return <option
                                                                            key={i}
                                                                            value={option.value}
                                                                        >
                                                                            {option.label}
                                                                        </option>
                                                                    })};
                                                                </select>
                                                            </div>
                                                        </div>

                                                        {userType === '1' ? (
                                                            <div className="col-md-6">
                                                                <div
                                                                    className="form-group input-group-sm mb-3 modal-input-group">
                                                                    <label htmlFor="form_need">TSU Profile *</label>
                                                                    <select className="custom-select modal-select-field form-control"
                                                                        onChange={(e) => setSelectedTsuProfile(e.target.value)}
                                                                        value={selectedTsuProfile}>
                                                                        <option hidden>Select TSU Profile</option>
                                                                        {allTsuProfiles.map((option) => (
                                                                            <option key={option.id} value={option.id}>
                                                                                {option.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        ) : null}

                                                    </div>


                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group input-group-sm mb-3 modal-input-group">
                                                                <label htmlFor="form_need">User Role *</label>
                                                                <select className="custom-select modal-select-field form-control"
                                                                    onChange={(e) => setSelectedUserRole(e.target.value)}
                                                                    value={selectedUserRole}>
                                                                    <option hidden>Select user role</option>
                                                                    {allUserRoles.map((role, i) => {
                                                                        return <option
                                                                            key={i}
                                                                            value={role.id}
                                                                        >
                                                                            {role.role}
                                                                        </option>
                                                                    })};
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-12">

                                                            <div
                                                                className="form-group input-group-sm mb-3 modal-input-group">
                                                                <label htmlFor="form_name">User Name
                                                                    *</label>
                                                                <input type="text" className="form-control"
                                                                    aria-label="Small"
                                                                    aria-describedby="inputGroup-sizing-sm"
                                                                    data-error="Display name is required."
                                                                    autoComplete="new-password"
                                                                    value={displayName}
                                                                    onChange={(e) => setDisplayName(e.target.value)} />

                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div
                                                                className="form-group input-group-sm mb-3 modal-input-group">
                                                                <label htmlFor="form_name">Password
                                                                    *</label>
                                                                <input type="password" aria-label="Small"
                                                                    aria-describedby="inputGroup-sizing-sm"
                                                                    name="pw"
                                                                    className="form-control"
                                                                    data-error="Password is required."
                                                                    autoComplete="new-password"
                                                                    value={passWord}
                                                                    onChange={(e) => setPassword(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div
                                                                className="form-group input-group-sm mb-3 modal-input-group">
                                                                <label htmlFor="form_lastname">Confirm Password
                                                                    *</label>
                                                                <input type="password" name="cP" aria-label="Small"
                                                                    aria-describedby="inputGroup-sizing-sm"
                                                                    className="form-control"
                                                                    data-error="Password is required."
                                                                    value={confirmPassword}
                                                                    onChange={(e) => setConfirmPassword(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-default" data-dismiss="modal"
                                            onClick={() => {
                                                closeModal()
                                            }}
                                        >Cancel
                                        </button>
                                        <Button loading={buttonLoader} type="submit" className="btn btn-primary">Add
                                            User</Button>
                                    </div>
                                </div>

                            </form>

                        </div>
                    </div>

                </>
                : ''}

        </>
    )
}

export default CreateUserModal