import React, { useEffect, useState, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

const AgentWisePerformanceSummaryReport = ({
  openAgentWisePerformanceSummaryReport,
  dateFrom,
  dateTo,
  selectedRegionIds,
  changeRegionIds,
  reportUrl
}) => {
  const baseUrl = localStorage.getItem("API_URL");
  const token = localStorage.getItem("user_token");
  const userDetails = JSON.parse(localStorage.getItem("user_details"));

  const [allreportsNew, setAllReportsNew] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const timeoutRef = useRef(null);

  const [dataTable, setDataTable] = useState({
    page: 0,
    size: 10,
    search: "",
    order: null,
  });

  const setTable = (tableState) => {
    setDataTable({
      page: tableState.page,
      search: tableState.searchText,
      size: tableState.rowsPerPage,
      order: tableState.sortOrder,
    });
  };

  const columns = [
    {
      label: "Branch",
      name: "branchName",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      label: "Agent Name",
      name: "firstName",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      label: "Tickets Assigned",
      name: "ticketAssigned",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      label: "Served Tickets",
      name: "servedTickets",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      label: "Transactions",
      name: "transactions",
      options: {
        sort: true,
        filter: false,
      },
    },    {
      label: "No Show",
      name: "noShow",
      options: {
        sort: true,
        filter: false,
      },
    },    {
      label: "Wait",
      name: "wait",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      label: "Avg. Service Time",
      name: "avgServiceTime",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      label: "Within Accepted Service",
      name: "withinAcceptServiceTime",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      label: "Max. Service",
      name: "maxServiceTime",
      options: {
        sort: true,
        filter: false,
        // customBodyRender: (value) => (
        //   <p className="m-0">{value !== null ? <>{value} %</> : <>-</>}</p>
        // ),
      },
    },
    {
      label: "Total System Login Time",
      name: "totalLoginTime",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      label: "Total Idle Time",
      name: "idleTime",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      label: "No. of Breaks",
      name: "noOfBreaks",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      label: "Total Break Time",
      name: "totalBreakTime",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      label: "Total Service Time",
      name: "totalServiceTime",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      label: "Overall Service Level",
      name: "serviceLevel",
      options: {
        sort: true,
        filter: false,
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    filter: false,
    responsive: "vertical",
    serverSide: false,
    count: count,
    pagination: false,
    download: false,
    jumpToPage: true,
    selectableRows: "none",
    storageKey: "_ar_awpsr_table",
    print: false,
    search: false,
    sort: false,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 20, 30, 50, 100],
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    onTableChange: (action, tableState) => {
      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want

      switch (action) {
        case "changePage":
          getAgentWisePerformanceSummaryReport(
            tableState.page,
            tableState.sortOrder,
            tableState.searchText,
            tableState.rowsPerPage
          );
          setTable(tableState);
          break;
        case "sort":
          getAgentWisePerformanceSummaryReport(
            tableState.page,
            tableState.sortOrder,
            tableState.searchText,
            tableState.rowsPerPage
          );
          setTable(tableState);
          break;
        case "changeRowsPerPage":
          setRowsPerPage(tableState.rowsPerPage);
          getAgentWisePerformanceSummaryReport(
            tableState.page,
            tableState.sortOrder,
            tableState.searchText,
            tableState.rowsPerPage
          );
          setTable(tableState);
          break;
        case "search":
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          timeoutRef.current = setTimeout(
            () =>
              getAgentWisePerformanceSummaryReport(
                tableState.page,
                tableState.sortOrder,
                tableState.searchText,
                tableState.rowsPerPage
              ),
            2000
          );
          setTable(tableState);
          break;
        default:
          break;
      }
    },
  };

  // when pick date, call getAgentWisePerformanceSummaryReport function
  useEffect(() => {
    if (openAgentWisePerformanceSummaryReport) {
      if (dateFrom.length > 0 && dateTo.length > 0) {
        getAgentWisePerformanceSummaryReport(0, null, null, rowsPerPage);
      }
    }
  }, [openAgentWisePerformanceSummaryReport, dateFrom, dateTo]);

  // when change selected region array, call getAgentWisePerformanceSummaryReport - useEffect
  useEffect(() => {
    if (openAgentWisePerformanceSummaryReport) {
      getAgentWisePerformanceSummaryReport(0, null, null, rowsPerPage);
    }
  }, [changeRegionIds]);

  // getAgentWisePerformanceSummaryReport - function
  const getAgentWisePerformanceSummaryReport = (
    page,
    sortOrder,
    search,
    size
  ) => {
    setIsLoading(true);

    axios({
      method: "POST",
      url: `${reportUrl}/report-controller/get/operator-wise-performance-report-data/paginated/customer/${userDetails.cid}`,
      headers: {
        Authorization: token,
      },
      data: {
        // page: page,
        // size: size,
        // sort: sortOrder,
        dateFrom: dateFrom,
        dateTo: dateTo,
        searchText: search,
        branchIds: selectedRegionIds,
      },
    })
      .then((res) => {
        let data = res.data.data;
        console.log(data);
        setAllReportsNew(data);
        setCount(data.length);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);

        if (error.response !== undefined) {
          toast.error(error.response.data.message, {
            position: "bottom-center",
          });
        } else {
          toast.remove();
          toast.error("Something went wrong", {
            position: "bottom-center",
          });
        }
      });
  };

  return (
    <>
      <div>
        <ToastContainer />
      </div>
      <MUIDataTable
        title={
          <>
            {isLoading ? (
              <CircularProgress color="secondary" size={20} />
            ) : (
              <>{count} records.</>
            )}
          </>
        }
        data={allreportsNew}
        columns={columns}
        options={options}
        className="text-center-table"
      />
    </>
  );
};

export default AgentWisePerformanceSummaryReport;
