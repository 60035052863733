import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { AppointmentUserDetailsModal } from "../ModalImpl/AppointmentUserDetailsModal";
import axios from "axios";
import MUIDataTable from "mui-datatables";
import CircularProgress from "@mui/material/CircularProgress";

export const AppointmentUserListPage = () => {
  const [showUserDetailsModal, setShowUserDetailsModal] = useState(false);
  const [isEditUser, setIsEditUser] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [sortOrder, setSortOrder] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);

  const baseUrl = localStorage.getItem("API_URL");
  const token = localStorage.getItem("user_token");

  const [dataTable, setDataTable] = useState({
    page: 0,
    size: 10,
    search: "",
    order: null,
  });

  const setTable = (tableState) => {
    setDataTable({
      page: tableState.page,
      search: tableState.searchText,
      size: tableState.rowsPerPage,
      order: tableState.sortOrder,
    });
  };

  const columns = [
    {
      label: "ID",
      name: "id",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      label: "Mobile No.",
      name: "mobile",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      label: "First Name",
      name: "firstName",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      label: "Last Name",
      name: "lastName",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      label: "NIC",
      name: "nic",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      label: "Ref. Number",
      name: "refNumber",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      label: "Actions",
      name: "actions",
      options: {
        sort: false,
        filter: false,

        customBodyRenderLite: (dataIndex, rowIndex) => {
          if (usersList === undefined) return;
          let item = usersList.find((item, i) => i === dataIndex);

          return (
            <>
              <Dropdown className="ml-0 ">
                <Dropdown.Toggle
                  as={CustomToggle}
                  id="dropdown-custom-components w-0"
                ></Dropdown.Toggle>

                <Dropdown.Menu className="menu-dropdown-menu">
                  <Link
                    className="dropdown-item dropdown-text-color my-1"
                    onClick={() => {
                      setSelectedItem(item);
                      setShowUserDetailsModal(true);
                      setIsEditUser(false);
                    }}
                  >
                    <i className="fa fa-eye mr-2"></i>View User
                  </Link>
                  <Link
                    className="dropdown-item dropdown-text-color my-1"
                    onClick={() => {
                      setSelectedItem(item);
                      setShowUserDetailsModal(true);
                      setIsEditUser(true);
                    }}
                  >
                    <i className="fa fa-pencil mr-2"></i>Edit User
                  </Link>
                  <Link
                    className="dropdown-item dropdown-text-color my-1"
                    to="/appointments/booking"
                    state={{ mobile: item.mobile }}
                    onClick={(e) => {}}
                  >
                    <i className="fa fa-ticket mr-2"></i>Book Ticket
                  </Link>
                  <Link
                    className="dropdown-item dropdown-text-color my-1"
                    to="/appointments/user-list/user-activity"
                    state={{ mobileNo: item.mobile, id: item.id }}
                    onClick={(e) => {
                      //   this.setState({ companyProfileModal: true });
                    }}
                  >
                    <i className="fa fa-external-link mr-2"></i>Reservations
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </>
          );
        },
      },
    },
  ];

  const options = {
    tableBodyHeight: "55vh",
    filterType: "dropdown",
    filter: false,
    responsive: "vertical",
    serverSide: true,
    count: count,
    download: false,
    jumpToPage: true,
    search: false,
    selectableRows: "none",
    print: false,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 20, 30, 50, 100],
    setTableProps: () => {
      return {
        size: "medium",
      };
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          getUsersWithPagination(
            tableState.page,
            tableState.sortOrder,
            tableState.searchText,
            tableState.rowsPerPage
          );
          setTable(tableState);
          break;
        case "sort":
          getUsersWithPagination(
            tableState.page,
            tableState.sortOrder,
            tableState.searchText,
            tableState.rowsPerPage
          );
          setTable(tableState);
          break;
        case "changeRowsPerPage":
          setRowsPerPage(tableState.rowsPerPage);
          getUsersWithPagination(
            tableState.page,
            tableState.sortOrder,
            tableState.searchText,
            tableState.rowsPerPage
          );
          setTable(tableState);
          break;
        case "search":
          getUsersWithPagination(
            tableState.page,
            tableState.sortOrder,
            tableState.searchText,
            tableState.rowsPerPage
          );
          setTable(tableState);
          break;
        default:
          break;
      }
    },
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
      type="button"
      ref={ref}
      className="btn btn-sm btn-default w-0"
      title="Edit"
      data-toggle="tooltip"
      data-placement="top"
      onClick={(e) => {
        onClick(e);
      }}
    >
      <i className="fa fa-angle-right"></i>
    </button>
  ));

  useEffect(() => {
    getUsersWithPagination(pageNumber, sortOrder, searchKey, dataTable.size);
  }, []);

  const onSearch = () => {
    let validatedSearchKey = `+94${
      searchKey[0] === "0" ? searchKey.slice(1) : searchKey
    }`;
    getUsersWithPagination(
      pageNumber,
      sortOrder,
      validatedSearchKey,
      dataTable.size
    );
  };

  const getUsersWithPagination = (page, sortOrder, search, size) => {
    setLoading(true);
    axios({
      method: "POST",
      url: `${baseUrl}/booking/get-all-ticket-customers`,
      headers: {
        Authorization: token,
      },
      data: {
        page: page,
        size: size,
        sort: sortOrder,
        searchText: search,
      },
    })
      .then((res) => {
        const data = res.data.data;
        setCount(data.totalElements);

        setUsersList(data.content);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);

        if (error.response !== undefined) {
          toast.remove();
          toast.error(error.response.data.message, {
            position: "bottom-center",
          });
        } else {
          toast.remove();
          toast.error("Something went wrong", {
            position: "bottom-center",
          });
        }
      });
  };

  const refreshPage = () => {
    getUsersWithPagination(pageNumber, sortOrder, searchKey, dataTable.size);
  };

  return (
    <>
      <div>
        <Toaster />
      </div>
      <div className="container-fluid">
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1> User List</h1>
            </div>
          </div>
        </div>

        <div className="row clearfix">
          <div className="col-12">
            <div className="card">
              <div className="body">
                <div className="row">
                  <div className=" col-lg-5 mb-2">
                    <div className="input-group input-feild-suffix">
                      <div className="suffix">+94</div>
                      <input
                        value={searchKey}
                        onChange={(e) => {
                          let inputText = e.target.value.replace(/\D/g, "");
                          setSearchKey(inputText);

                          if (e.target.value === "") {
                            getUsersWithPagination(
                              pageNumber,
                              sortOrder,
                              inputText,
                              dataTable.size
                            );
                          }
                        }}
                        type="text"
                        className="form-control"
                        placeholder="Type Mobile Number Here..."
                        maxLength={10}
                      />

                      <div className="input-group-append">
                        <button
                          className="btn btn-outline-secondary px-5"
                          type="button"
                          onClick={() => onSearch()}
                        >
                          <span>Search</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row clearfix">
          <div className="col-12">
            <div className="card">
              <div className="body p-0 appointment-userlist-table-container">
                <MUIDataTable
                  title={
                    <>
                      {count}
                      {count === 1 ? " record" : " records"}
                      {isLoading && (
                        <>
                          &nbsp;&nbsp;&nbsp;
                          <CircularProgress color="primary" size={10} />
                        </>
                      )}
                    </>
                  }
                  data={usersList}
                  columns={columns}
                  options={options}
                  className="text-center-table"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <AppointmentUserDetailsModal
        showUserDetailsModal={showUserDetailsModal}
        setShowUserDetailsModal={setShowUserDetailsModal}
        isEditUser={isEditUser}
        data={selectedItem}
        refreshPage={refreshPage}
      />
    </>
  );
};
