import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import tsLogo from "../../assets/images/TS-Logo.png"
import { Tab, Tabs } from "react-bootstrap";
import Button from 'react-bootstrap-button-loader';
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const Register = () => {
    // const history = useHistory();
    const [token, setToken] = useState('');
    const [baseUrl, setBaseUrl] = useState('');

    const [companyName, setCompanyName] = useState('');
    const [companyEmail, setCompanyEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [genderType, setGenderType] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [subscriptionId, setSubscriptionId] = useState(0);
    const [ConfirmUserPassword, setConfirmUserPassword] = useState('');
    const [buttonLoader, setButtonLoader] = useState(false);


    useEffect(() => {
        setBaseUrl(localStorage.getItem('API_URL'));
        setToken(localStorage.getItem('TS_GEN_TOKEN'));
    }, [Register]);

    const options = [
        {
            label: "Select a gender",
            value: "Select a gender",
        },
        {
            id: "1",
            label: "Male",
            value: "Male",
        },
        {
            id: "2",
            label: "Female",
            value: "Female",
        },

    ];

    const headers = {
        headers: {
            "Authorization": token
        }
    }

    // userRegister - function
    const userRegister = (e) => {
        e.preventDefault();
        let body = {
            "companyName": companyName,
            "companyEmail": companyEmail,
            "firstName": firstName,
            "lastName": lastName,
            "email": email,
            "gender": genderType,
            "password": userPassword,
            "subscriptionId": subscriptionId
        };
        setButtonLoader(true);

        let isValidate = validate();
        if (isValidate) {

            if (userPassword === ConfirmUserPassword) {
                axios.post(`${baseUrl}/user/register`, body, headers)
                    .then(response => {
                        setButtonLoader(false);

                        toast.remove();
                        toast.success('User Successfully Registered!',
                            {
                                position: 'bottom-center',
                            });
                        // history.push('/')

                    })
                    .catch(error => {
                        console.log(error);
                        toast.remove();
                        toast.error(error.response.data.message,
                            {
                                position: 'bottom-center',
                            });
                        setButtonLoader(false);
                    });
            } else {
                toast.remove();
                toast.error('Please check confirm password!',
                    {
                        position: 'bottom-center',
                    });
                setButtonLoader(false);
            }


            // alert(JSON.stringify(body))
        }
    };

    // validate - function
    const validate = () => {

        if (companyName.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Company Name Empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (companyEmail.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Company Email Empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (firstName.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('First Name Empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (lastName.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Last Name Empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (email.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Email Empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (genderType.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Selecte a gender', {
                position: 'bottom-center',
            });
            return false;
        } else if (userPassword.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('userPassword Empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (ConfirmUserPassword.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Confirm User Password ', {
                position: 'bottom-center',
            });
            return false;
        } else {
            return true;
        }
    }







    // getSubscriptionId - function
    const getSubscriptionId = (e) => {
        e.preventDefault();
        // alert(e.target.id)
        setSubscriptionId(e.target.id)
    }

    return (
        <>
            <div><Toaster /></div>
            <div className="pattern">
                <span className="red"></span>
                <span className="indigo"></span>
                <span className="blue"></span>
                <span className="green"></span>
                <span className="orange"></span>
            </div>
            <div className="auth-main particles_js" style={{ height: '0%' }}>
                <div className="auth_div vivify popIn register_div">
                    <div className="auth_brand">
                        <Link className="navbar-brand" to="/">
                            <img
                                src={tsLogo}
                                width="80" height="80"
                                className="d-inline-block align-top mr-2" alt="Ts logo" />
                        </Link>
                    </div>
                    <div className="card">
                        <div className="body">
                            <p className="lead">Create an account</p>
                            <Tabs defaultActiveKey="Details" id="uncontrolled-tab-example">
                                <Tab eventKey="Details" title="Details" tabClassName="custom_tab">
                                    <form className="register-form">
                                        <p className="text-left" style={{ color: '#17C2D7' }}>Company Details</p>
                                        <div className="row clearfix">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="signup-name"
                                                        className="control-label sr-only">Name</label>
                                                    <input type="text" className="form-control round" id="signup-name"
                                                        placeholder="Name" value={companyName}
                                                        onChange={(e) => setCompanyName(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="signup-email"
                                                        className="control-label sr-only">Email</label>
                                                    <input type="text" className="form-control round" id="signup-email"
                                                        placeholder="Email" value={companyEmail}
                                                        onChange={(e) => setCompanyEmail(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <p className="text-left" style={{ color: '#17C2D7' }}>User Details</p>
                                        <div className="row clearfix">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="signup-fname" className="control-label sr-only">First
                                                        Name</label>
                                                    <input type="text" className="form-control round" id="signup-fname"
                                                        placeholder="First Name" value={firstName}
                                                        onChange={(e) => setFirstName(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="signup-lname" className="control-label sr-only">Last
                                                        Name</label>
                                                    <input type="text" className="form-control round" id="signup-lname"
                                                        placeholder="Last Name" value={lastName}
                                                        onChange={(e) => setLastName(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="signup-email"
                                                        className="control-label sr-only">Email</label>
                                                    <input type="text" className="form-control round" id="signup-email"
                                                        placeholder="Email" value={email}
                                                        onChange={(e) => setEmail(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="signup-email"
                                                        className="control-label sr-only">Gender</label>
                                                    <select className="form-control round" value={genderType}
                                                        data-error="Please specify your need."
                                                        onChange={(e) => setGenderType(e.target.value)}>
                                                        {options.map((option) => (
                                                            <option key={option.value}>
                                                                {option.value}
                                                            </option>
                                                        ))}
                                                    </select>

                                                </div>
                                            </div>

                                        </div>

                                        <div className="row clearfix">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="signup-password"
                                                        className="control-label sr-only">Password</label>
                                                    <input type="password" className="form-control round"
                                                        id="signup-password"
                                                        placeholder="Password" value={userPassword}
                                                        onChange={(e) => setUserPassword(e.target.value)} />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="signup-password"
                                                        className="control-label sr-only">Confirm Password</label>
                                                    <input type="password" className="form-control round"
                                                        id="signup-password"
                                                        placeholder="Password" value={ConfirmUserPassword}
                                                        onChange={(e) => setConfirmUserPassword(e.target.value)} />
                                                </div>
                                            </div>


                                        </div>

                                        <Button loading={buttonLoader} type="submit"
                                            className="btn btn-primary btn-round btn-block"
                                            onClick={(e) => userRegister(e)}>CREATE
                                        </Button>

                                        <div className="bottom">
                                            {/*<span className="helper-text m-b-10"><i className="fa fa-lock"></i> */}
                                            {/*<Link*/}
                                            {/*to="/forgotpassword">Forgot password?</Link>*/}
                                            {/*</span>*/}
                                            <span>Already have an account? <Link to="/">Login</Link></span>
                                        </div>
                                        {/*<button type="submit" className="btn btn-primary btn-round btn-block">CREATE</button>*/}
                                    </form>
                                </Tab>


                                <Tab eventKey="Payments Details" title="Payments Details" tabClassName="custom_tab">
                                    <div className="row clearfix">
                                        <div className="col-lg-4 cool-md-4 ">
                                            <div className="card">
                                                <ul className="pricing body">
                                                    <li className="plan-img">
                                                        <img className="img-fluid rounded-circle"
                                                            src="../assets/images/plan-1.svg" alt="" />
                                                    </li>
                                                    <li className="price price_div">
                                                        <h3><span>$</span> 99 <small>/ mo</small></h3>
                                                        {/*<span>Freelance</span>*/}
                                                    </li>
                                                    {/*<li>1 GB of space</li>*/}
                                                    {/*<li>Support at $25/hour</li>*/}
                                                    {/*<li>Limited cloud access</li>*/}
                                                    <li className="plan-btn">
                                                        <button id="1" onClick={(e) => getSubscriptionId(e)}
                                                            className="btn btn-round btn-outline-secondary text-center">Purchase
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 cool-md-4 ">
                                            <div className="card">
                                                <ul className="pricing body">
                                                    <li className="plan-img"><img className="img-fluid rounded-circle"
                                                        src="../assets/images/plan-2.svg"
                                                        alt="" /></li>
                                                    <li className="price price_div">
                                                        <h3><span>$</span> 99 <small>/ mo</small></h3>
                                                        {/*<span>Freelance</span>*/}
                                                    </li>
                                                    {/*<li>1 GB of space</li>*/}
                                                    {/*<li>Support at $25/hour</li>*/}
                                                    {/*<li>Limited cloud access</li>*/}
                                                    <li className="plan-btn">
                                                        <button id="2" onClick={(e) => getSubscriptionId(e)}
                                                            className="btn btn-round btn-outline-secondary text-center">Purchase
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 cool-md-4 ">
                                            <div className="card">
                                                <ul className="pricing body">
                                                    <li className="plan-img"><img className="img-fluid rounded-circle"
                                                        src="../assets/images/plan-3.svg"
                                                        alt="" /></li>
                                                    <li className="price price_div">
                                                        <h3><span>$</span> 99 <small>/ mo</small></h3>
                                                        {/*<span>Freelance</span>*/}
                                                    </li>
                                                    {/*<li>1 GB of space</li>*/}
                                                    {/*<li>Support at $25/hour</li>*/}
                                                    {/*<li>Limited cloud access</li>*/}
                                                    <li className="plan-btn">
                                                        <button id="3" onClick={(e) => getSubscriptionId(e)}
                                                            className="btn btn-round btn-outline-secondary text-center">Purchase
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>

                            </Tabs>
                        </div>
                    </div>
                </div>
                <div id="particles-js"></div>
            </div>

        </>
    )
}

export default Register;