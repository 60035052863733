import {Link} from "react-router-dom";
import React, {useState} from "react";
import {MDBDataTable} from "mdbreact";
import AddWorkflowModal from "../ModalImpl/AddWorkflowModal";



const Workflow = () => {

    const selectedBranch = JSON.parse(localStorage.getItem('selected_branch'));
    
    const [showWorkflowModal, setShowWorkflowModal] = useState(false);


    // const [newDataList, setNewDataList] = useState([]);
    const data = {
        columns: [
            {
                label: '#',
                field: 'id',
                sort: 'asc',
                width: 10
            },
            {
                label: 'Workflow Name',
                field: 'workflowName',
                width: 150,
                sort: 'asc',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                },
            },
            {
                label: 'Start Category',
                field: 'startCategory',
                sort: 'asc',
                width: 270,
            },
            {
                label: 'Sms Template',
                field: 'smsTemplate',
                sort: 'asc',
                width: 200,
            },

            {
                label: 'Action',
                field: 'action',
                sort: 'disabled',
                width: 100,
            }
        ],
        rows:
        
        [
            {
                id: 1,
                workflowName: 'Mark 1',
                startCategory: 'Doctor Channeling',
                smsTemplate: 'T1',
                action: <>
                    <button type="button" className="btn btn-sm btn-default" title="Edit" data-toggle="tooltip"
                            data-placement="top"><i className="fa fa-pencil-square-o"></i></button>
                    &nbsp;
                    <button type="button" className="btn btn-sm btn-default" title="Active" data-toggle="tooltip"
                            data-placement="top"><i className="fa fa-eye"></i></button>
                    &nbsp;
                    <button type="button" className="btn btn-sm btn-default " title="Delete"
                            data-toggle="tooltip" data-placement="top"><i className="fa fa-trash"></i></button>
                </>
            },
        ]
    }


    return (
        <>
            <div className="container-fluid">
                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h2>Workflow</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/region-management">Region Management</Link></li>
                                    <li className="breadcrumb-item"><Link to={`/branch-landing?bid=${selectedBranch.id}`}>{selectedBranch.BranchName}</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Workflow</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-md-6 col-sm-12 text-right hidden-xs">
                            <Link className="btn btn-sm btn-primary" title="" onClick={() => {
                                setShowWorkflowModal(true);
                            }}>Add New</Link>
                        </div>
                    </div>
                </div>

                <div className="row clearfix">
                    <div className="col-lg-12 col-md-12">
                        <div className="card planned_task">
                            <div className="header">

                            </div>
                           
                            <div className="row clearfix">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="body">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-6">
                                                    <div className="input-group header-counter-center">
                                                       
                                                        <h5>12 / <small>50</small></h5>
                                                    </div>


                                                </div>
                                                <div className="col-lg-4 col-md-6">
                                                    <div className="input-group">
                                                    </div>
                                                </div>


                                                <div className="col-lg-5 col-md-6 ">
                                                    <div className="input-group">
                                                        <input type="text" className="form-control"
                                                               placeholder="Search here..."/>
                                                        <div className="input-group-append">
                                                            <button className="btn btn-outline-secondary" type="button">
                                                                <i
                                                                    className="fa fa-search"/></button>

                                                           
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row clearfix">


                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="card" style={{transition: "none"}}>

                                        <div className="body table-responsive ">
                                            <MDBDataTable
                                                className="table table-striped table-hover dataTable js-exportable assign-user-table assign-table"
                                                displayEntries={false}
                                                paging
                                                pagesAmount={15}
                                                data={data}
                                                searching={false}
                                              
                                            />
                                        </div>
                                    </div>
                                </div>


                            </div>
              
                        </div>
                    </div>

                </div>

                <AddWorkflowModal showWorkflowModal={showWorkflowModal} setShowWorkflowModal={setShowWorkflowModal}/>
            </div>
        </>
    )

}
export default Workflow