import React, {Component} from 'react';
import {connect} from 'react-redux';
import './App.css';
import Layout from './components/Shared/Layout';
import SignUp from './components/Authentication/signup';
import ForgotPassword from './components/Authentication/forgotpassword';
import {BrowserRouter, Redirect, Route, Routes} from 'react-router-dom';
import Register from "./components/PagesImpl/Register";
import LoginPage from "./components/PagesImpl/LoginPage";
import axios from "axios";

class App extends Component {

    componentWillMount() {
        this.saveConfigData();
    }

    // save configuration file data on localStorage 
    saveConfigData() {
        var responseClone;
        fetch(process.env.PUBLIC_URL + '/configuration.json')
            .then(function (response) {
                responseClone = response.clone();
                return response.json();
            })
            .then(function (data) {

                console.info("TSTPL: Welcome !");
                console.log('Version ', data.VERSION);

                // save on localStorage
                localStorage.setItem('API_URL', data.API_URL);
                localStorage.setItem('TS_GEN_TOKEN', data.TS_GEN_TOKEN);
                localStorage.setItem('APP_VERSION', data.VERSION);

                axios({
                    method: 'POST',
                    url: `${data.API_URL}/branch/get-configs`,
                    headers: {
                        Authorization: data.TS_GEN_TOKEN
                    },
                    data: {
                        response: [
                            "mq-password", "mqtt", "web-socket", "mq-username", "ip", "port", "use-ssl"
                        ]
                    }
                }).then((res) => {
                    let data = res.data.data;
                    localStorage.setItem('configuration_data', JSON.stringify(data));
                })

            }, function (rejectionReason) {
                console.log('Error parsing JSON from response');
                responseClone.text() // 5
                    .then(function (bodyText) {
                        console.log('Received the following instead of valid JSON');
                    });
            });

    }

    render() {
        const { themeColor, fontStyle, lightVersion, RtlVersion, offcanvas, miniSidebar, horizontalMenu, miniHover } = this.props
        document.getElementsByTagName('body')[0].className = `${themeColor} ${fontStyle}${lightVersion ? ' light_version' : ''}${RtlVersion ? ' rtl' : ''}${offcanvas ? ' offcanvas-active' : ''}${horizontalMenu ? ' h-menu' : ''}${miniSidebar ? ' mini_sidebar' : ''}${miniHover ? ' mini_hover' : ''}`;
        // var isLoggedIn = localStorage.getItem("isLoggedIn");
        return (
            <div
                ref={leftSidebar => {
                    this.leftSidebar = leftSidebar;
                }}
            >

                <BrowserRouter>
                    <main>
                        <Routes>
                            <Route path="/" exact element={<LoginPage />} />
                            {/*<Route path="/landing-page" element={<>*/}
                            {/*<ThemeSetting/>*/}
                            {/*<div className="overlay"/>*/}
                            {/*<div id="wrapper">*/}
                            {/*<Header/>*/}
                            {/*<Menu {...this.props} />*/}

                            {/*<div id="main-content">*/}
                            {/*<LandingPage/>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                            {/*</>}/>*/}
                            <Route path="/signup" element={<SignUp />} />
                            <Route path="/forgotpassword" element={<ForgotPassword />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="*" element={<Layout />} />
                        </Routes>
                    </main>

                </BrowserRouter>

                {/*<Router basename="/ts-queue-portal">*/}
                {/*<Switch>*/}
                {/*<Route path="/signup" component={SignUp} />*/}
                {/*<Route path="/login1" component={Login}/>*/}
                {/*<Route path={`ts-queue-portal/login`} component={LoginPage}/>*/}
                {/*<Route path="/register" component={Register}/>*/}
                {/*<Route path="/forgotpassword" component={ForgotPassword} />*/}
                {/*<Route path="/notfound" component={NotFound} />*/}
                {/*<Route path="/maintenance" component={Maintenance} />*/}
                {/*<Route component={Layout} />*/}
                {/*</Switch>*/}
                {/*</Router>*/}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    themeColor: state.settings.themeColor,
    fontStyle: state.settings.fontStyle,
    lightVersion: state.settings.lightVersion,
    RtlVersion: state.settings.RtlVersion,
    offcanvas: state.settings.offcanvas,
    horizontalMenu: state.settings.horizontalMenu,
    miniSidebar: state.settings.miniSidebar,
    miniHover: state.settings.miniHover,
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(App)