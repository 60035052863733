import React from 'react'
import {Image, Transformer} from 'react-konva';
import useImage from 'use-image';

const URLImage = ({shapeProps, isSelected, image, onSelect, onChange, grid, gridWidth, gridHeight}) => {
    const trRef = React.useRef();
    const shapeRef = React.useRef();
    const [img] = useImage(image);


    React.useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>

            <Image
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                {...shapeProps}
                image={img}
                draggable
                onDragEnd={e => {
                    let xCord = Math.max(Math.round(e.target.x() / grid) * grid, 0);
                    let yCord = Math.max(Math.round(e.target.y() / grid) * grid, 0);

                    //calc min x for boundary exceeds
                    if (xCord + shapeProps.width > gridWidth)
                        xCord = gridWidth - shapeProps.width;
                    //calc min y for boundary exceeds
                    if (yCord + shapeProps.height > gridHeight)
                        yCord = gridHeight - shapeProps.height;

                    if (xCord < 0)
                        xCord = 0;
                    if (yCord < 0)
                        yCord = 0;

                    e.target.to({
                        x: xCord,
                        y: yCord
                    });
                    //update parent
                    onChange({
                        ...shapeProps,
                        x: xCord,
                        y: yCord,
                    });
                }}
                onTransformEnd={(e) => {
                    // transformer is changing scale of the node
                    // and NOT its width or height
                    // but in the store we have only width and height
                    // to match the data better we will reset scale on transform end
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);
                    //update parent

                    let xCord = Math.max(Math.round(e.target.x() / grid) * grid, 0);
                    let yCord = Math.max(Math.round(e.target.y() / grid) * grid, 0);

                    let w = Math.max(Math.round(Math.max(grid, node.width() * scaleX) / grid) * grid, grid);
                    let h = Math.max(Math.round(Math.max(grid, node.height() * scaleY) / grid) * grid, grid);

                    if (xCord + w > gridWidth)
                        w = gridWidth - xCord;
                    if (h + yCord > gridHeight)
                        h = gridHeight - yCord;


                    onChange({
                        ...shapeProps,
                        x: xCord,
                        y: yCord,
                        // set minimal value
                        width: w,
                        height: h,
                    });
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    rotateEnabled={false}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>

    );
};


export default URLImage