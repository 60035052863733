import React, { useEffect, useState } from 'react';
import AddCounterModal from '../ModalImpl/AddCounterModal';
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import Swal from "sweetalert2";
import { Link } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import CircularProgress from "@mui/material/CircularProgress";
import { DropdownToggle, DropdownMenu, Card, UncontrolledDropdown, DropdownItem, Progress } from "reactstrap";
import CloneBranchCountersModal from '../ModalImpl/CloneBranchCountersModal';
import 'react-toastify/dist/ReactToastify.css';


const CountersManagement = () => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const selectedBranch = JSON.parse(localStorage.getItem('selected_branch'));

    const [showAddCounterModal, setShowAddCounterModal] = useState(false);
    const [modalType, setmodalType] = useState(0); // 0-add, 1-edit
    const [selectedRow, setSelectedRow] = useState({});

    const [showCloneCountersModal, setShowCloneCountersModal] = useState(false);

    const [allCounters, setAllCounter] = useState([]);
    const [allCounterTableBody, setAllCounterTableBody] = useState([]);
    const [countersForRender, setCountersForRender] = useState([]);
    const [searchText, setSearchText] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [isLoading, setLoading] = useState(false);


    // data table
    const [dataTable, setDataTable] = useState({
        page: 0,
        size: 10,
        search: '',
        order: null,
    });

    const setTable = (tableState) => {
        setDataTable({
            page: tableState.page,
            search: tableState.searchText,
            size: tableState.rowsPerPage,
            order: tableState.sortOrder
        });
    };

    const options = {
        tableBodyHeight: '50vh',
        filterType: 'dropdown',
        filter: false,
        responsive: 'vertical',
        serverSide: true,
        count: count,
        download: false,
        jumpToPage: true,
        selectableRows: 'none',
        print: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        },
        onTableChange: (action, tableState) => {

            switch (action) {
                case 'changePage':
                    getAllCountersByBranch(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'sort':
                    getAllCountersByBranch(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'changeRowsPerPage':
                    setRowsPerPage(tableState.rowsPerPage);
                    getAllCountersByBranch(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'search':
                    getAllCountersByBranch(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                default:
                    break;
            }
        },
    };

    const columns = [
        {
            label: '#',
            name: 'index',
            options: {
                sort: false,
                filter: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {

                    if (allCounters === undefined) return;
                    let item = allCounters.find((item, i) => i === dataIndex);

                    return (
                        <p className='m-0'>{dataIndex + 1}</p>
                    );
                }
            }
        },
        {
            label: 'Counter No',
            name: 'counterNo',
            options: {
                sort: true,
                filter: false,
                
            }
        },
        {
            label: "Counter Name",
            name: 'counterName',
            options: {
                sort: true,
                filter: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {

                    if (allCounters === undefined) return;
                    let item = allCounters.find((item, i) => i === dataIndex);

                    return (
                        <p className='m-0'>
                            {item.counterName}
                            {item.virtual ? <span className="vip-icon">Virtual</span> : null}
                        </p>
                    );
                }
            }
        },
        {
            label: 'Default Category',
            name: 'defaultCategoryName',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Action',
            name: '',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {

                    if (allCounters === undefined) return;
                    let item = allCounters.find((item, i) => i === dataIndex);

                    return (
                        <>

                            <UncontrolledDropdown>

                                <DropdownToggle type="button" className={`btn btn-sm btn-default table-action-btn`} data-toggle="tooltip"
                                    data-placement="top"
                                ><i className="fa fa-angle-right" aria-hidden="true"></i>
                                </DropdownToggle>

                                <DropdownMenu
                                    positionFixed={true}
                                    className="dropdown-menu-xs">
                                    <ul className="link-list-opt">
                                        <li>
                                            <DropdownItem
                                                tag="a"
                                                href="javascript:void(0);"
                                                onClick={() => {
                                                    setSelectedRow(item);
                                                    setmodalType(2);
                                                    setShowAddCounterModal(true);
                                                }}
                                            >
                                                <i className="fa fa-desktop mr-3" aria-hidden="true"></i>
                                                <span>View</span>
                                            </DropdownItem>
                                        </li>
                                        <li>
                                            <DropdownItem
                                                tag="a"
                                                href="javascript:void(0);"
                                                onClick={() => {
                                                    setSelectedRow(item);
                                                    setmodalType(1);
                                                    setShowAddCounterModal(true);
                                                }}
                                            >
                                                <i className="fa fa-pencil-square-o mr-3" aria-hidden="true"></i>
                                                <span>Edit</span>
                                            </DropdownItem>
                                        </li>
                                        <li>
                                            <DropdownItem
                                                tag="a"
                                                href="javascript:void(0);"
                                                onClick={() => {
                                                    deleteCounter(item.id);
                                                }}
                                            >
                                                <i className="icon-trash mr-3"> </i>
                                                <span>Delete</span>
                                            </DropdownItem>
                                        </li>


                                    </ul>
                                </DropdownMenu>
                            </UncontrolledDropdown>

                        </>
                    );
                }
            },
        },
    ];

    // call getAllCountersByBranch - useEffect
    useEffect(() => {
        if (token !== null) {
            if (baseUrl.length > 0 && token.length > 0 && Object.keys(selectedBranch).length > 0) {
                getAllCountersByBranch(0, null, null, rowsPerPage);
            }
        }
    }, [CountersManagement])

    // addActionColumn - useEffect
    useEffect(() => {
        let postsArray = JSON.parse(JSON.stringify(allCounters));
        let counterData = [];
        postsArray.map((item, index) => {
            item.index = (
                <div>
                    {index + 1}
                </div>
            )

            item.action = (
                <>
                    <button type="button" className="btn btn-sm btn-default" title="View" data-toggle="tooltip"
                        data-placement="top"
                        onClick={() => {
                            setSelectedRow(item);
                            setmodalType(2);
                            setShowAddCounterModal(true);
                        }}
                    >
                        <i className="fa fa-desktop"></i>
                    </button>
                    &nbsp;


                    <button type="button" className="btn btn-sm btn-default" title="Edit" data-toggle="tooltip"
                        data-placement="top"
                        onClick={() => {
                            setSelectedRow(item);
                            setmodalType(1);
                            setShowAddCounterModal(true);
                        }}
                    >
                        <i className="fa fa-pencil-square-o"></i>
                    </button>
                    &nbsp;

                    <button type="button" className="btn btn-sm btn-default" title="Delete" data-toggle="tooltip"
                        data-placement="top"
                        onClick={() => {
                            deleteCounter(item.id);
                        }}
                    ><i className="icon-trash"></i></button>
                </>
            );
            counterData.push(item);
        });
        setCountersForRender(counterData);
        setAllCounterTableBody(counterData);
    }, [allCounters]);

    // getAllCountersByBranch - function
    const getAllCountersByBranch = (page, sortOrder, search, size) => {

        setLoading(true);
        axios({
            method: 'POST',
            url: `${baseUrl}/counter/get-list-paginated/${selectedBranch.id}`,
            headers: {
                Authorization: token
            },
            data: {
                page: page,
                size: size,
                sort: sortOrder,
                searchText: search
            }
        }).then((res) => {

            let data = res.data.data;
            setAllCounter((data.content));
            setCount(data.totalElements);
            setLoading(false);

        }).catch((error) => {
            console.log(error);
            setLoading(false);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //deleteCounter - function
    const deleteCounter = (id) => {

        Swal.fire({
            position: 'center',
            text: 'Do you want to delete this counter?',
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            width: '400px',

        }).then((result) => {
            if (result.value == true) {

                axios({
                    method: 'POST',
                    url: `${baseUrl}/counter/delete-counter/counter/${id}`,
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {

                    toast.remove();
                    toast.success('Counter deleted Successfully ', {
                        position: 'bottom-center',
                    });
                    getAllCountersByBranch(dataTable.page, dataTable.order, dataTable.search, dataTable.size);

                }).catch((error) => {
                    console.log(error);

                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                })
            }
        })
    }




    return (
        <>
            <div><Toaster /></div>
            <div className="container-fluid">

                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h1>Counters</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    {/* <li className="breadcrumb-item"><Link to="/region-management">Region Management</Link></li> */}
                                    <li className="breadcrumb-item"><Link to={`/branch-landing?bid=${selectedBranch.id}`}>{selectedBranch.BranchName}</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Counters</li>
                                </ol>
                            </nav>
                        </div>

                        <div className="col-md-6 col-sm-12 text-right hidden-xs">
                            {/* {
                                allCounters.length === 0 ? (
                                    <div className="btn btn-sm btn-primary mr-1" title="Clone counters from another branch"
                                        onClick={() => {
                                            setShowCloneCountersModal(true);
                                        }}
                                    >Clone Counters
                                    </div>
                                ) : null
                            } */}

                            <div className="btn btn-sm btn-primary" title="Add new counter"
                                onClick={() => {
                                    setmodalType(0);
                                    setShowAddCounterModal(true);
                                }}
                            >Add New
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row clearfix">
                    <div className="col-lg-12">

                        <MUIDataTable
                            title={<>
                                {count}{count === 1 ? ' record' : ' records'}.
                                {isLoading &&
                                    <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                        size={10} /></>}
                            </>
                            }
                            data={allCounters}
                            columns={columns}
                            options={options}
                            className='text-center-table'
                        />

                    </div>
                </div>



            </div>

            <AddCounterModal
                showAddCounterModal={showAddCounterModal} setShowAddCounterModal={setShowAddCounterModal}
                getAllCountersByBranch={getAllCountersByBranch} modalType={modalType}
                selectedRow={selectedRow} setSelectedRow={setSelectedRow}
                dataTable={dataTable}
            />

            <CloneBranchCountersModal
                showCloneCountersModal={showCloneCountersModal}
                setShowCloneCountersModal={setShowCloneCountersModal}
                getAllCountersByBranch={getAllCountersByBranch}
                rowsPerPage={rowsPerPage}
            />
        </>
    );
}
export default CountersManagement
