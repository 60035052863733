import React, {Component} from 'react';
import ReactApexChart from "react-apexcharts";

class PersentageDonutChartWithBottomLegend extends Component {

    constructor(props) {
        console.log('PROPS >> ', props.donutChartColors);
        super(props);

        this.color = ['#CCCCFF', '#AAAAFF', '#6666FF','#4D4DFF','#3333FF', '#23238E', '#191970', '#000033'];

        this.state = {
            options: {
                chart: {
                    height: 300,
                    type: 'donut',
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center',
                    show: false,
                },
                labels: this.props.lable,
                colors: this.color,
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                stroke: {
                    show: false,
                    width: 0
                },
            },
            series: this.props.percentage,
        };
    }

    componentDidUpdate(prevProps, prevStates) {
        if(prevProps.chartValue !== this.props.chartValue) {
            this.setState({
                options: {
                    chart: {
                        height: 300,
                        type: 'donut',
                    },
                    legend: {
                        position: 'bottom',
                        horizontalAlign: 'center',
                        show: false,
                    },
                    labels: this.props.lable,
                    colors: this.color,
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }],
                    stroke: {
                        show: false,
                        width: 0
                    },
                },
                series: this.props.percentage,
            })
        }
    }

    render() {
        return (
            <div>
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    labels={this.state.labels}
                    type="donut"
                    height="250px"
                />
            </div>
        );
    }
}

export default PersentageDonutChartWithBottomLegend;

