import React, { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { Tab, Tabs } from 'react-bootstrap';
import axios from "axios";
import { lang } from 'moment/moment';

import RangeSlider from '../common/RangeSlider';
import Button from 'react-bootstrap-button-loader';
// import { Tab, Tabs } from 'react-bootstrap';



const AssignUserSkillsModal = ({ showAssignUserSkillsModal, setShowAssignUserSkillsModal, userId, getSkillsByUserId }) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));
    const [allGroups, setAllGroups] = useState([])
    const [selectedGroupId, setSelectedGroupId] = useState(null)

    const [buttonLoader, setButtonLoader] = useState(false);
    const [refresh, setRefresh] = useState(1);
    const [selAll, setSelAll] = useState([]);
    const [updateAll, setUpdateAll] = useState('');

    const [searchSkillsName, setSearchSkillsName] = useState('');
    const [searchAllSkillsByLanguage, setSearchAllSkillsByLanguage] = useState('');
    const [allSkillsByLanguage, setAllSkillsByLanguage] = useState([]);

    const [allAssignedSkills, setAllAssignedSkills] = useState([]);
    const [selectedAssignSkill, setSelectedAssignSkill] = useState({});

    const [stepperNo, setStepperNo] = useState(1);
    const [sliderValue, setSliderValue] = useState(3);
    const horizontalLabels = {
        0: '0',
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5'
    }



    //call getAllSkillsByLanguage
    useEffect(() => {
        if (showAssignUserSkillsModal) {
            if (baseUrl.length > 0 && token.length > 0 && Object.keys(userDetails).length > 0) {
                getAllSkillsByLanguage();
                getAllGroups();
            }
        }
    }, [showAssignUserSkillsModal])

    //call searchSkills
    useEffect(() => {
        if (searchSkillsName.length > 0) {
            searchSkills();
        }
    }, [searchSkillsName])

    //when go to second step, call getUserAssignedSkills
    useEffect(() => {
        if (stepperNo === 2) {
            getUserAssignedSkills();
        }
    }, [stepperNo])




    //getAllSkillsByLanguage - function
    const getAllSkillsByLanguage = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/user/get-skills/customer/${userDetails.cid}/current-user/${userId}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            let data = res.data.data
            checkedselectall(data)
            setAllSkillsByLanguage(data);
        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //assignUserSkillsToUser - Function
    const assignUserSkillsToUser = () => {
        setButtonLoader(true);

        let selectedSkills = filterSelectedSkills();

        axios({
            method: 'POST',
            url: `${baseUrl}/user/add/skills`,
            headers: {
                Authorization: token
            },
            data: {
                userId: userId,
                skills: selectedSkills,
            }

        }).then((res) => {
            setButtonLoader(false);

            // closeModal();
            // toast.remove();
            // toast.success('Skill successfully Save', {
            //     position: 'bottom-center',
            // });

            setStepperNo(2);

            getSkillsByUserId();

        }).catch((error) => {
            console.log(error);
            setButtonLoader(false);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });

            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })

    }

    //get user's assigned skills by user id - function
    const getUserAssignedSkills = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/user/get-assign-skills/customer/${userDetails.cid}/current-user/${userId}`,
            headers: {
                Authorization: token
            },
        }).then((res) => {

            let data = res.data.data
            setAllAssignedSkills(data);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })

    }

    //set level for user skill - function
    const setLevelForSkill = () => {

        axios({
            method: 'POST',
            url: `${baseUrl}/user/set-skills-level`,
            headers: {
                Authorization: token
            },
            data: {
                skillIds: [selectedAssignSkill.id],
                skillLevel: sliderValue
            }
        }).then((res) => {

            // let data = res.data.data
            // setAllAssignedSkills(data);
            getUserAssignedSkills();
            getSkillsByUserId();

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })

    }


    //filterSelectedSkills - function
    const filterSelectedSkills = () => {
        let selectedSkills = [];
        for (let i = 0; i < allSkillsByLanguage.length; i++) {

            let skills = allSkillsByLanguage[i].skills;
            for (let j = 0; j < skills.length; j++) {
                if (allSkillsByLanguage[i].skills[j].selected === true) {
                    selectedSkills.push(skills[j].id)
                }
            }
        }
        return selectedSkills;
    }

    //searchSkills - function
    const searchSkills = () => {

        let searchedSkillsWithLang = [];

        for (let i = 0; i < allSkillsByLanguage.length; i++) {

            let language = allSkillsByLanguage[i].language;
            let skills = allSkillsByLanguage[i].skills;
            let searchedSkillList = [];

            for (let j = 0; j < skills.length; j++) {

                let oneSkill = skills[j].skill.toLowerCase();

                if (oneSkill.includes(searchSkillsName)) {
                    searchedSkillList.push(skills[j]);
                }
            }

            if (searchedSkillList.length > 0) {
                searchedSkillsWithLang.push({
                    language: language,
                    skills: searchedSkillList
                }
                )
            }
        }
        setSearchAllSkillsByLanguage(searchedSkillsWithLang);
    }

    //selectAll - function
    const selectAll = (e, i) => {
        let temp = selAll;
        let skillset = allSkillsByLanguage[i].skills
        if (!temp[i].all) {
            for (let i = 0; i < skillset.length; i++) {
                skillset[i].selected = true;
            }

            temp[i].all = true;
            setSelAll(temp);
            setUpdateAll(new Date().toString())
        } else {
            for (let i = 0; i < skillset.length; i++) {
                skillset[i].selected = false;
            }
            temp[i].all = false;
            setSelAll(temp);
            setUpdateAll(new Date().toString())
        }

    }

    //checkedselectall - function
    const checkedselectall = (data) => {
        let x = [];
        for (let i = 0; i < data.length; i++) {

            let skillsArray = data[i].skills;
            let allSelected = true;

            for (let j = 0; j < skillsArray.length; j++) {
                if (skillsArray[j].selected) {
                } else {
                    allSelected = false;
                    break;
                }
            }
            x.push({
                "lang": data[i].language,
                "all": allSelected,
            })
            setUpdateAll(new Date().toString());
            data[i].allSelected = allSelected;
            setRefresh(refresh + 1);
        }
        setSelAll(x)

        setAllSkillsByLanguage(data);
    }

    const getAllGroups = () => {
        console.log('getAllGroups function start');

        axios({
            method: 'GET',
            url: `${baseUrl}/skill/get-skill-groups/customer/${userDetails.cid}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            console.log('getSkillByLang Success');
            let data = res.data.data;
            console.log('skillbylang', data);
            setAllGroups(data);


        }).catch((error) => {
            console.log('getSkillByLang Error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    const assignGroupToUser = () => {
        console.log('assignGroupToUser function start');

        axios({
            method: 'POST',
            url: `${baseUrl}/skill/add/group/${selectedGroupId}/user/${userId}`,
            headers: {
                Authorization: token
            },

        }).then(() => {
            toast.remove();
            toast.success('Group successfully assigned to user.', {
                position: 'bottom-center',
            });
            getAllSkillsByLanguage();


        }).catch((error) => {
            console.log('getSkillByLang Error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //close the add modal
    const closeModal = () => {
        setButtonLoader(false);
        for (let i = 0; i < allSkillsByLanguage.length; i++) {
            allSkillsByLanguage[i].selected = false;
            setRefresh(refresh + 1);
        }
        setSearchSkillsName('');
        setStepperNo(1);
        setSliderValue(3);
        setSelectedAssignSkill({});

        setShowAssignUserSkillsModal(false);
    }

    return (
        <>
            <div><Toaster /></div>
            {showAssignUserSkillsModal ? (
                <div className={`modal fade d-block show modal-container`} id="counter-modal">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Assign User Skills
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => closeModal()}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            {
                                stepperNo === 1 ? (

                                    <div className="modal-body">

                                        <div className="row clearfix">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="input-group mb-3">
                                                    <select className="custom-select modal-select-field" id="inputGroupSelect01"
                                                        value={selectedGroupId}
                                                        onChange={(e) => {
                                                            setSelectedGroupId(e.target.value);
                                                        }}
                                                    >
                                                        <option defaultValue hidden>Choose...</option>
                                                        {allGroups.map((item, index) =>
                                                            <option value={item.id} key={index}>
                                                                {item.name}
                                                            </option>
                                                        )}
                                                    </select>
                                                    <div className="input-group-append">
                                                        <button className="btn btn-outline-primary" type="button"
                                                            onClick={() => {
                                                                assignGroupToUser()
                                                            }}>
                                                            Assign Group
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="input-group mb-3">
                                                    <input type="text" className="form-control" placeholder="Search here..."
                                                        onKeyUp={(e) => {
                                                            setSearchSkillsName(e.target.value);
                                                        }}
                                                    />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-outline-secondary" type="button"
                                                            onClick={() => {

                                                            }}>
                                                            <i className="fa fa-search" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="assign-user-skill-cont">
                                            {searchSkillsName.length === 0 ?
                                                <>
                                                    {
                                                        allSkillsByLanguage.length > 0 ? (
                                                            allSkillsByLanguage.map((item, i) => (
                                                                <div className="row clearfix">
                                                                    <div className="col-lg-12 col-md-12" key={i}>

                                                                        <div className="card">
                                                                            <div className="body">
                                                                                <div class="header skill-header">
                                                                                    <h2>{item.language}
                                                                                    </h2>

                                                                                    <div class="selecte-all-container">
                                                                                        <label
                                                                                            className="switch custom-swtch">
                                                                                            <input type="checkbox"
                                                                                                checked={selAll[i].all}
                                                                                                onChange={(e) => {
                                                                                                    selectAll(e, i);
                                                                                                }}
                                                                                            />
                                                                                            <span className='slider'
                                                                                            > Select All</span>

                                                                                        </label>
                                                                                    </div>


                                                                                </div>
                                                                                <div className="user-skill-list">
                                                                                    {
                                                                                        item.skills.map((skill, i) => (
                                                                                            <span class={`badge ${skill.selected ? "badge-secondary " : "badge-default"} user-skill-badge`}
                                                                                                key={i}
                                                                                                onClick={() => {
                                                                                                    skill.selected = !skill.selected;
                                                                                                    setRefresh(refresh + 1);
                                                                                                    checkedselectall(allSkillsByLanguage);
                                                                                                }}
                                                                                            >{skill.skill}
                                                                                            </span>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <div className="row clearfix">
                                                                <div className='col-md-12'>
                                                                    <div class="alert alert-custom alert-dismissible" role="alert">
                                                                        <i class="fa fa-info-circle"></i> Empty all records
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                                :
                                                <>
                                                    {
                                                        searchAllSkillsByLanguage.length > 0 ? (
                                                            searchAllSkillsByLanguage.map((item, i) => (
                                                                <div className="col-lg-12 col-md-12" key={i}>

                                                                    <div className="card">
                                                                        <div className="body">
                                                                            <div class="header"><h2>{item.language}
                                                                            </h2></div>
                                                                            <div className="user-skill-list">
                                                                                {
                                                                                    item.skills.map((skill, i) => (
                                                                                        <span class={`badge ${skill.selected ? "badge-secondary " : "badge-default"} user-skill-badge`}
                                                                                            key={i}
                                                                                            onClick={() => {
                                                                                                skill.selected = !skill.selected;
                                                                                                setRefresh(refresh + 1);
                                                                                            }}
                                                                                        >{skill.skill}
                                                                                        </span>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <div className='col-md-12'>
                                                                <div class="alert alert-custom alert-dismissible" role="alert">
                                                                    <i class="fa fa-info-circle"></i> Empty search records
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            }
                                        </div>

                                    </div>

                                ) : stepperNo === 2 ? (

                                    <div className="modal-body">

                                        <div className="row clearfix">
                                            <div className="col-12">
                                                <div className='' style={{ padding: '0px 140px 30px' }}>
                                                    <RangeSlider
                                                        min={0}
                                                        max={5}
                                                        value={sliderValue}
                                                        labels={horizontalLabels}
                                                        onChangeComplete={(value) => {
                                                            if (Object.keys(selectedAssignSkill).length > 0) {
                                                                if (sliderValue != null) {
                                                                    setLevelForSkill();
                                                                }
                                                            }
                                                        }}
                                                        onChange={(value) => {
                                                            if (Object.keys(selectedAssignSkill).length > 0) {
                                                                setSliderValue(value)
                                                            } else {
                                                                toast.remove();
                                                                toast.error('Please select a skill', {
                                                                    position: 'bottom-center',
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="assign-user-skill-cont">
                                            {
                                                allAssignedSkills.length > 0 ? (
                                                    allAssignedSkills.map((item, i) => (
                                                        <div className="row clearfix">
                                                            <div className="col-lg-12 col-md-12" key={i}>

                                                                <div className="card">
                                                                    <div className="body">
                                                                        <div class="header skill-header">
                                                                            <h2>{item.language}
                                                                            </h2>
                                                                        </div>
                                                                        <div className="user-skill-list">
                                                                            {
                                                                                item.userSkills.map((skill, i) => (
                                                                                    <span class={`badge ${skill.id === selectedAssignSkill.id ? "badge-secondary " : "badge-default"} user-skill-badge`}
                                                                                        key={i}
                                                                                        onClick={() => {
                                                                                            if (skill.id === selectedAssignSkill.id) {
                                                                                                setSelectedAssignSkill({});
                                                                                            } else {
                                                                                                setSliderValue(skill.skillLevel);
                                                                                                setSelectedAssignSkill(skill);
                                                                                            }
                                                                                        }}
                                                                                    >{skill.skillName}
                                                                                        <div className='skill-level-span'>
                                                                                            {skill.skillLevel}
                                                                                        </div>
                                                                                    </span>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="row clearfix">
                                                        <div className='col-md-12'>
                                                            <div class="alert alert-custom alert-dismissible" role="alert">
                                                                <i class="fa fa-info-circle"></i> Empty records
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                        </div>

                                    </div>

                                ) : null
                            }








                            <div className="modal-footer">


                                {
                                    stepperNo === 1 ? (
                                        <>
                                            <button type="button" className="btn btn-default"
                                                onClick={() => {
                                                    closeModal();
                                                }}
                                            >Cancel
                                            </button>
                                            <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                                onClick={() => {
                                                    assignUserSkillsToUser();
                                                    // setStepperNo(2);
                                                }}
                                            >Next
                                            </Button>
                                        </>


                                    ) : stepperNo === 2 ? (
                                        <>
                                            <button type="button" className="btn btn-default"
                                                onClick={() => {
                                                    closeModal();
                                                }}
                                            >Cancel
                                            </button>

                                            <button type="button" className="btn btn-primary"
                                                onClick={() => {
                                                    setStepperNo(1);
                                                    setSliderValue(3);
                                                    setSelectedAssignSkill({});
                                                }}
                                            >Back
                                            </button>

                                        </>

                                    ) : null
                                }

                            </div>
                        </div>
                    </div>
                </div>
            ) : null
            }
        </>
    )
}

export default AssignUserSkillsModal