import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap-button-loader';
import axios from 'axios';
import toast, { Toaster } from "react-hot-toast";


const AddNotificationTemplateModal = ({ ShowAddNotificationModal, setShowAddNotificationModal, modalType, setSelectedNotificationTemplate, selectedNotificationTemplate, getallnotificationtemplate }) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [templateName, setTemplateName] = useState('');
    const [notificationContent, setNotificationContent] = useState('');
    const [selectedNotificationTypeId, setSelectedNotificationTypeId] = useState('');
    const [selectedNotificationActionId, setSelectedNotificationActionId] = useState('');

    const [allNotificationActions, setAllNotificationActions] = useState([]);

    const [allNotificationType, setAllNotificationType] = useState([]);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [selectedTag, setSelectedTag] = useState('');

    const tags = [
        {
            id: "1",
            label: "#TOKEN",
            value: "#TOKEN"
        },
        {
            id: "2",
            label: "#BOOKING_URL",
            value: "#BOOKING_URL"
        },
        {
            id: "3",
            label: "#CATEGORY",
            value: "#CATEGORY"
        },
        {
            id: "4",
            label: "#NETTED_SERVICES",
            value: "#NETTED_SERVICES"
        },

    ];

    //callgetallnotificationtype - useeffect
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            if (baseUrl.length > 0 && token.length > 0) {
                getallnotificationtype();
            }
        }
    }, [AddNotificationTemplateModal]);

    //combineTwoStrings - useEffect
    useEffect(() => {
        if (selectedTag.length > 0) {
            combineTwoStrings();
        }
    }, [selectedTag])

    //getallnotificationactiontype - useEffect
    useEffect(() => {
        if (selectedNotificationTypeId.length > 0) {
            getallnotificationactiontype();
        }
    }, [selectedNotificationTypeId])

    //is update - useEffect
    useEffect(() => {
        if (ShowAddNotificationModal) {
            if (modalType === 1) {
                if (Object.keys(selectedNotificationTemplate).length > 0) {
                    console.log('xxxxxx ', selectedNotificationTemplate);
                    setNotificationContent(selectedNotificationTemplate.content);
                }
            }
        }
    }, [ShowAddNotificationModal])

    //addNotificationTemplate - function
    const addNotificationTemplate = () => {
        console.log('addNotificationTemplate function start');

        axios({
            method: 'POST',
            url: `${baseUrl}/alert/add/notification-template`,
            headers: {
                Authorization: token
            },
            data: {
                "name": templateName,
                "content": notificationContent,
                "typeId": selectedNotificationTypeId,
                "actionId": selectedNotificationActionId,
            }

        }).then((res) => {
            console.log('addNotificationTemplate success');
            let data = res.data.data;
            console.log(res.data.data);
            setSelectedNotificationTemplate(data);
            getallnotificationtemplate();

            toast.remove();
            toast.success('Successfully added', {
                position: 'bottom-center',
            });

            closeModal();

        }).catch((error) => {
            console.log('addNotificationTemplate error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //getallnotificationtype - function
    const getallnotificationtype = () => {
        console.log('getallnotificationtype function start');

        axios({
            method: 'GET',
            url: `${baseUrl}/alert/notification-types/get-list/customer/${userDetails.cid}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            console.log('getallnotificationtype success',);
            let data = res.data.data;
            console.log(data);
            setAllNotificationType(data);


        }).catch((error) => {
            console.log('getallnotificationtype error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //getallnotificationactiontype - function
    const getallnotificationactiontype = () => {
        console.log('getallnotificationactiontypefunction start');

        axios({
            method: 'GET',
            url: `${baseUrl}/alert/notification-actions/get-list/type/${selectedNotificationTypeId}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            console.log('getallnotificationactiontypefunction success',);
            let data = res.data.data;
            console.log(data);
            setAllNotificationActions(data);

        }).catch((error) => {
            console.log('getallnotificationactiontypefunction error');
            console.log(error);
            console.log('----');

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //combineTwoStrings - function
    const combineTwoStrings = () => {
        console.log('combineTwoStrings function start');

        console.log(notificationContent);
        console.log(selectedTag);

        var String_3 = notificationContent.concat("", selectedTag);
        console.log(String_3);
        setNotificationContent(String_3)
    }

    //updatenotification - function
    const updatenotification = () => {
        console.log('updatenotification function start');

        axios({
            method: 'POST',
            url: `${baseUrl}/alert/notification/template/${selectedNotificationTemplate.id}/update`,
            headers: {
                Authorization: token
            },
            data: {
                "content": notificationContent,
            }

        }).then((res) => {
            console.log('updatenotification success',);
            let data = res.data.data;
            console.log(data);

            toast.remove();
            toast.success('Successfully added', {
                position: 'bottom-center',
            });

            getallnotificationtemplate();
            closeModal();


        }).catch((error) => {
            console.log('updatenotification error');
            console.log(error);


            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //closeModal- function
    const closeModal = () => {
        setShowAddNotificationModal(false);
        setTemplateName('');
        setNotificationContent('');
        setSelectedNotificationTypeId('');
        setSelectedNotificationActionId('');
        setSelectedTag('');

    }

    return (
        <>
            {ShowAddNotificationModal ? (
                <div className={`modal fade d-block show modal-container`} id="category-modal">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{modalType === 0 ?
                                    <>Add new Notification template</> : <>Edit Notification template</>}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        closeModal()
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">

                                {/* {modalType === 0 ? ( */}

                                <form className='sms-template-add-form '>

                                    <div className="row clearfix">
                                        <div className="col-lg-5 col-md-5">

                                            <div className="input-group input-group-sm mb-2">
                                                <p className="input-field-side-text">Notification Type :</p>
                                            </div>
                                            <div className="input-group input-group-sm mb-3">
                                                <select className="custom-select modal-select-field " id="inputGroupSelect01"
                                                    value={selectedNotificationTypeId}
                                                    disabled={modalType === 1 ? true : false}
                                                    onChange={(e) => {
                                                        setSelectedNotificationTypeId(e.target.value);
                                                    }}
                                                >
                                                    <option defaultValue hidden>Choose... </option>
                                                    {allNotificationType.map((item, index) =>
                                                        <option value={item.typeId} key={index}>
                                                            {item.type}
                                                        </option>
                                                    )}
                                                </select>
                                            </div>

                                            <div className='input-group input-group-sm mb-2'>
                                                <p className="input-field-side-text">Notification Action : </p>
                                            </div>
                                            <div className="input-group input-group-sm mb-3">
                                                <select className="custom-select modal-select-field " id="inputGroupSelect01"

                                                    value={selectedNotificationActionId}
                                                    disabled={modalType === 1 ? true : false}
                                                    onChange={(e) => {
                                                        setSelectedNotificationActionId(e.target.value);
                                                    }}
                                                >
                                                    <option defaultValue hidden>Choose... </option>
                                                    {allNotificationActions.map((item, index) =>
                                                        <option value={item.id} key={index}>
                                                            {item.name}
                                                        </option>
                                                    )}
                                                </select>
                                            </div>

                                            <div className="input-group input-group-sm mb-2">
                                                <p className="input-field-side-text"> Select Tags : </p>
                                            </div>

                                            {tags.map((item, i) => (
                                                <div className='badge badge-default'
                                                    style={{ cursor: 'pointer' }}
                                                    key={i}
                                                    onClick={() => {
                                                        console.log('====');
                                                        setSelectedTag(item.value)
                                                    }}
                                                >
                                                    {item.value}
                                                </div>
                                            ))}

                                        </div>


                                        <div className="col-lg-7 col-md-7">
                                            <div className="form-group">
                                                <textarea rows={10} className="form-control no-resize"
                                                    placeholder="Please type what you want..."
                                                    value={notificationContent}
                                                    onChange={(e) => {
                                                        console.log('00000', notificationContent);
                                                        setNotificationContent(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                </form>


                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >Cancel
                                </button>



                                {modalType === 0 ?
                                    <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                        onClick={() => {
                                            addNotificationTemplate();
                                        }}
                                    >Add</Button>
                                    : modalType === 1 ?
                                        <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                            onClick={() => {
                                                updatenotification();
                                            }}
                                        >Update changes</Button>
                                        :
                                        (null)
                                }
                            </div>
                        </div>
                    </div>
                </div >
            ) : null
            }

        </>
    )
}

export default AddNotificationTemplateModal;