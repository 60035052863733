import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';


class CategoryAnalyticslinecharts extends Component {
    constructor(props) {
        super(props);

        this.state = {

            series: [{
                name: "Served",
                data: [
                    [1358895600000, 325],
                    [1358982000000, 310],
                    [1359068400000, 332],
                    [1359327600000, 324],
                    [1359414000000, 382],
                    [1359500400000, 374],
                    [1359586800000, 383],
                    [1359673200000, 384],
                    [1359932400000, 380],
                    [1360018800000, 381],
                    [1360105200000, 380],
                    [1360191600000, 387],
                    [1360278000000, 391],
                    [1360537200000, 364],
                    [1360623600000, 389],
                    [1360710000000, 388],
                    [1360796400000, 361],
                    [1360882800000, 363],
                    [1361228400000, 399],
                    [1361314800000, 377],
                    [1361401200000, 334],
                    [1361487600000, 355],
                    [1361746800000, 311],
                    [1361833200000, 359],
                    [1361919600000, 360],
                ]
            }, {
                name: "Issued",
                data: [
                    [1358895600000, 132],
                    [1358982000000, 123],
                    [1359068400000, 243],
                    [1359327600000, 265],
                    [1359414000000, 235],
                    [1359500400000, 332],
                    [1359586800000, 312],
                    [1359673200000, 334],
                    [1359932400000, 234],
                    [1360018800000, 235],
                    [1360105200000, 234],
                    [1360191600000, 343],
                    [1360278000000, 224],
                    [1360537200000, 234],
                    [1360623600000, 234],
                    [1360710000000, 323],
                    [1360796400000, 234],
                    [1360882800000, 332],
                    [1361228400000, 389],
                    [1361314800000, 387],
                    [1361401200000, 384],
                    [1361487600000, 355],
                    [1361746800000, 311],
                    [1361833200000, 359],
                    [1361919600000, 360],
                ]
            }, {
                name: "Local Wait",
                data: [
                    [1358895600000, 12],
                    [1358982000000, 23],
                    [1359068400000, 12],
                    [1359327600000, 23],
                    [1359414000000, 43],
                    [1359500400000, 23],
                    [1359586800000, 12],
                    [1359673200000, 32],
                    [1359932400000, 42],
                    [1360018800000, 32],
                    [1360105200000, 32],
                    [1360191600000, 12],
                    [1360278000000, 43],
                    [1360537200000, 23],
                    [1360623600000, 43],
                    [1360710000000, 23],
                    [1360796400000, 43],
                    [1360882800000, 38],
                    [1361228400000, 28],
                    [1361314800000, 26],
                    [1361401200000, 12],
                    [1361487600000, 43],
                    [1361746800000, 23],
                    [1361833200000, 43],
                    [1361919600000, 54],
                ]
            }, {
                name: "No Show",
                data: [
                    [1358895600000, 12],
                    [1358982000000, 8],
                    [1359068400000, 3],
                    [1359327600000, 5],
                    [1359414000000, 6],
                    [1359500400000, 7],
                    [1359586800000, 6],
                    [1359673200000, 15],
                    [1359932400000, 11],
                    [1360018800000, 9],
                    [1360105200000, 10],
                    [1360191600000, 16],
                    [1360278000000, 13],
                    [1360537200000, 19],
                    [1360623600000, 12],
                    [1360710000000, 15],
                    [1360796400000, 4],
                    [1360882800000, 5],
                    [1361228400000, 13],
                    [1361314800000, 15],
                    [1361401200000, 12],
                    [1361487600000, 11],
                    [1361746800000, 10],
                    [1361833200000, 14],
                    [1361919600000, 13],
                ]
            }],
            options: {
                chart: {
                    id: 'area-datetime',
                    type: 'area',
                    height: 350,
                    zoom: {
                        autoScaleYaxis: true
                    },
                    toolbar: {
                        show: false
                    }
                },
                grid: {
                    show: false
                },
                annotations: {
                    yaxis: [{
                        y: 30,
                        axisBorder: { show: false }
                    }],
                    xaxis: [{
                        axisBorder: { show: false },
                        x: 1358895600000,
                    }]
                },
                dataLabels: {
                    enabled: false
                },
                yaxis: {
                    axisBorder: { show: false }
                },
                xaxis: {
                    axisBorder: { show: false },
                    type: 'datetime',
                    min: 1358895600000,
                    tickAmount: 4
                },
                tooltip: {
                    x: {
                        format: 'dd MMM yyyy'
                    }
                },
                fill: {
                    colors: ['#9237B3', '#45F073', '#09E6E6', '#4D1CEF']
                    // colors: ['#'#9237B3'', '#17C2D7'],
                    // gradient: {
                    //     shadeIntensity: 1,
                    //     opacityFrom: 0.7,
                    //     opacityTo: 0.9,
                    //     stops: [0, 100]
                    // }
                },
            }
        };
    }
    render() {
        return (
            <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type="line"
                height={320}
            />
        );
    }
}

export default CategoryAnalyticslinecharts;
