import axios from 'axios';
import React, { useState, useEffect } from 'react'
import hotToast, { Toaster } from 'react-hot-toast';
import Swal from "sweetalert2";
import { Slide, toast as toastify, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// used for add and edit
const CloneBranchCountersModal = ({ showCloneCountersModal, setShowCloneCountersModal, getAllCountersByBranch, rowsPerPage }) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));
    const selectedBranch = JSON.parse(localStorage.getItem('selected_branch'));

    const [allBranches, setAllBranches] = useState([]);
    const [branchesForRender, setBranchesForRender] = useState([]);

    const [refresh, setRefresh] = useState(1);


    // call getAllBranchesByCustomer - useEffect
    useEffect(() => {
        if (showCloneCountersModal) {
            getAllBranchesByCustomer();
        }
    }, [showCloneCountersModal])



    // getAllBranchesByCustomer - function
    const getAllBranchesByCustomer = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/branch/get-branches/customer/${userDetails.cid}`,
            headers: {
                Authorization: localStorage.getItem('TS_GEN_TOKEN')
            }
        }).then((res) => {

            let data = res.data.data;
            setAllBranches(data);
            setBranchesForRender(data);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                hotToast.remove();
                hotToast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                hotToast.remove();
                hotToast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // cloneCategories - function
    const cloneCategories = (fromBranch) => {

        // toastMessageCall(`Warning !`, `Please update default category of counters if necessary.`, "top-right", 'dark')
        // closeModal()

        Swal.fire({
            position: 'center',
            text: `Do you want to clone ${fromBranch.name} branch counters ?`,
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            width: '400px',

        }).then((result) => {
            if (result.value == true) {

                axios({
                    method: 'POST',
                    url: `${baseUrl}/branch/clone-counters/current/${selectedBranch.id}/from/${fromBranch.id}`,
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {

                    hotToast.remove();
                    hotToast.success('Counters successfully cloned', {
                        position: 'bottom-center',
                    });
                    getAllCountersByBranch(0, null, null, rowsPerPage);
                    closeModal();

                    toastMessageCall(`Warning !`, `Please update default category of counters if necessary.`, "top-right", 'dark')

                }).catch((error) => {
                    console.log(error);

                    if (error.response !== undefined) {
                        hotToast.remove();
                        hotToast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        hotToast.remove();
                        hotToast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                })
            }
        })

    }

    // seachBranch - function
    const seachBranch = (text) => {
        if (text !== '') {
            const result = allBranches.filter((branch) => {
                return branch.name.toString().toLowerCase().includes(text.toLowerCase());
            });
            setBranchesForRender(result);
        } else {
            setBranchesForRender(allBranches);
        }
    }

    // toast open - function
    const toastMessageCall = (title, content, position, type) => {
        toastify(
            <>
                <b className='toast-title'>{title}</b>
                <p className='toast-content'>{content}</p>
            </>,
            { type, autoClose: 8000, position, transition: Slide, closeButton: true });
    }

    // closeModal - function
    const closeModal = () => {
        setShowCloneCountersModal(false);
    }

    return (
        <>
            <ToastContainer />
            <div><Toaster /></div>
            {showCloneCountersModal ? (
                <div className={`modal fade d-block show modal-container`} id="counter-modal">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Clone Counters
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">

                                <div className='row clearfix'>


                                    <div className="col-lg-12 col-md-12">
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="Search here..."
                                                onKeyUp={(e) => {
                                                    seachBranch(e.target.value);
                                                }}
                                            />
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="button"
                                                    onClick={() => {

                                                    }}>
                                                    <i className="fa fa-search" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='modal-fix-height'>
                                    <div className='row clearfix'>
                                        {
                                            branchesForRender.length > 0 ? (

                                                branchesForRender.map((branch, i) => (
                                                    <div className='col-lg-6 col-md-6 mb-2' key={i}>
                                                        <div
                                                            className={`list-group-item d-flex justify-content-between align-items-center cursor-pointer `} //${permission.selected ? 'active' : ''}
                                                            onClick={() => {
                                                                cloneCategories(branch);
                                                            }}
                                                        >
                                                            {branch.name}
                                                        </div>
                                                    </div>
                                                ))

                                            ) : (
                                                <div className="col-md-12">
                                                    <div className="alert alert-custom alert-dismissible" role="alert">
                                                        <i className="fa fa-info-circle"></i> Empty records
                                                    </div>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>

                            </div>

                            <div className="modal-footer">

                                <button type="button" className="btn btn-default"
                                    onClick={() => {
                                        closeModal();
                                    }}>Cancel
                                </button>

                            </div>

                        </div>
                    </div>
                </div>
            ) : null
            }

        </>

    )
}

export default CloneBranchCountersModal