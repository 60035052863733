import axios from 'axios';
import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import Button from 'react-bootstrap-button-loader';
import { useLocation } from "react-router-dom";


const AddMainUserEditRoles = ({ showUserRolesModal, setShowUserRolesModal, userData, getMainUserById }) => {

    const location = useLocation();

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [buttonLoader, setButtonLoader] = useState(false);
    const [allUserRoles, setAllUserRoles] = useState([]);
    const [refresh, setRefresh] = useState(1);

    const [selectedUserRole, setSelectedUserRole] = useState('');


    // get base url - useEffect
    useEffect(() => {
        if (showUserRolesModal) {
            if (baseUrl.length > 0 && token.length > 0 && Object.keys(userDetails).length > 0) {
                getAllRolesByCid();
            }
        }
    }, [showUserRolesModal])

    // get current role id - useEffect
    useEffect(() => {
        if (userData != null) {
            setSelectedUserRole(userData.roleId);
        }
    }, [AddMainUserEditRoles])

    // get roled by customer id - function
    const getAllRolesByCid = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/user-role/get-all-roles/${userDetails.cid}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let data = res.data.data;
            setAllUserRoles(data)

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // closeModal - function
    const closeModal = () => {
        setButtonLoader(false);
        setShowUserRolesModal(false);
    };

    const validateData = () => {

        if (selectedUserRole.length <= 0) {
            toast.error('Please select user role', {
                position: 'bottom-center',
            });
            return false;
        } else if (userData.roleId === parseInt(selectedUserRole)) {
            toast.error('Same role. No need to assign', {
                position: 'bottom-center',
            });
            return false;
        } else {
            return true;
        }
    }

    // update user role - function
    const UpdateRole = () => {
        setButtonLoader(true);

        let isValidated = validateData();

        if (isValidated) {
            axios({
                method: 'POST',
                url: `${baseUrl}/user/assign-role/user/${userData.id}/role/${selectedUserRole}`,
                headers: {
                    Authorization: token
                }
            }).then((res) => {

                setButtonLoader(false);
                toast.success('User role successfully assigned', {
                    position: 'bottom-center',
                });

                setShowUserRolesModal(false)

                getAllRolesByCid();
                setRefresh(refresh + 1);
                getMainUserById();

            }).catch((error) => {
                console.log(error);
                setButtonLoader(false);

                if (error.response !== undefined) {
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                    setButtonLoader(false);
                } else {
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                    setButtonLoader(false);
                }
            })

        }
    }

    return (
        <>
            <div><Toaster /></div>
            {showUserRolesModal ? (
                <div className={`modal fade d-block show modal-container`} id="counter-modal">
                    <div className="modal-dialog modal-sm" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    User Roles
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">

                                <div className="row clearfix">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="card"
                                            style={{ height: '300px', overflowY: 'scroll' }}
                                        >

                                            {allUserRoles.length > 0 ?

                                                allUserRoles.map((role, index) =>
                                                    <div className="fancy-radio mb-3" key={index}>
                                                        <label>
                                                            <input name="gender" type="radio" value={role.id}
                                                                defaultChecked={userData.roleId === role.id}
                                                                onChange={(e) => {
                                                                    setSelectedUserRole(e.target.value);
                                                                }}
                                                            />
                                                            <span><i className="mr-2"></i>{role.role}</span>
                                                        </label>
                                                    </div>
                                                )
                                                :
                                                <div class="alert alert-custom alert-dismissible" role="alert">
                                                    <i class="fa fa-info-circle"></i> Empty records
                                                </div>
                                            }


                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-default"
                                    onClick={() => {
                                        closeModal();
                                    }}>Cancel
                                </button>


                                <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                    onClick={() => {
                                        UpdateRole();
                                    }}
                                >Update changes</Button>


                            </div>

                        </div>
                    </div>
                </div>
            ) : null
            }

        </>

    )
}

export default AddMainUserEditRoles