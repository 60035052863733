import axios from 'axios';
import React, { useState, useEffect } from 'react';
import MainDisplayAddModal from '../ModalImpl/MainDisplayAddModal';
import toast, { Toaster } from 'react-hot-toast';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';


const MainDisplayManagement = () => {

    const [baseUrl, setBaseUrl] = useState('');
    const [token, setToken] = useState('');
    const [selectedBranch, setSelectedBranch] = useState({});

    const [allMainDisplayList, setAllMainDisplayList] = useState([]);
    const [allMainDisplayTableBody, setAllMainDisplayTableBody] = useState([]);
    const [mainDisplaysForRender, setMainDisplaysForRender] = useState([]);
    const [searchText, setSearchText] = useState('');

    const [showAddMDModal, setShowAddMDModal] = useState(false);
    const [modalType, setmodalType] = useState(); // 0-add, 1-edit
    const [selectedMDId, setSelectedMDId] = useState('');


    // table body
    const body = {
        columns: [
            {
                label: '#',
                field: 'index',
                width: 10
            },
            {
                label: 'Display ID',
                field: 'displayId',
                width: 10
            },
            {
                label: 'Name',
                field: 'mainDisplayNameNew',
                width: 200,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                },
            },
            {
                label: 'Type',
                field: 'mainDisplayTypeNew',
                width: 500,
            },
            {
                label: 'Mac Address',
                field: 'mainDisplayMacAddressNew',
                width: 500,
            },
            {
                label: 'Status',
                field: 'status',
                width: 0,
            },
            {
                label: 'Action',
                field: 'action',
                width: 0,
            }
        ],
        rows: allMainDisplayTableBody
    }

    // get base url - useEffect
    useEffect(() => {
        setBaseUrl(localStorage.getItem('API_URL'));
        setToken(localStorage.getItem('user_token'));
        setSelectedBranch(JSON.parse(localStorage.getItem('selected_branch')));
    }, [MainDisplayManagement])

    // call getAllMainDisplays - useEffect
    useEffect(() => {
        if (token !== null) {
            if (baseUrl.length > 0 && token.length > 0 && Object.keys(selectedBranch).length > 0) {
                getAllMainDisplays();
            }
        }
    }, [baseUrl, token, selectedBranch]);

    // addActionColumn - useeffect
    useEffect(() => {
        let postsArray = JSON.parse(JSON.stringify(allMainDisplayList));
        let newDataArray = [];
        postsArray.map((item, index) => {

            item.index = (
                <div className={`${item.restricted ? 'row-text-diseble' : ''}`}>
                    {index + 1}
                </div>
            )

            item.displayId = (
                <div className={`${item.restricted ? 'row-text-diseble' : ''}`}>
                    <b>{item.id}</b>
                </div>
            )

            item.mainDisplayNameNew = (
                <div className={`${item.restricted ? 'row-text-diseble' : ''}`}>
                    {item.mainDisplayName}
                </div>
            )

            item.mainDisplayTypeNew = (
                <div className={`${item.restricted ? 'row-text-diseble' : ''}`}>
                    {item.mainDisplayType}
                </div>
            )

            item.mainDisplayMacAddressNew = (
                <div className={`${item.restricted ? 'row-text-diseble' : ''}`}>
                    {item.mainDisplayMacAddress}
                </div>
            )

            item.status = (
                <>
                    {item.restricted ? <span className="badge badge-danger">Disabled</span> : <span className="badge badge-success">Active</span>}
                </>
            )

            item.action = (
                <>
                    {
                        item.restricted ? (
                            <button type="button" className={`btn btn-sm btn-default disabled`} title="View" data-toggle="tooltip"
                                data-placement="top" style={{ cursor: 'not-allowed' }}
                            ><i className="fa fa-desktop"></i></button>
                        ) : (
                            <button type="button" className={`btn btn-sm btn-default`} title="View" data-toggle="tooltip"
                                data-placement="top"
                                onClick={() => {
                                    console.log("ID_1: ", item.id);
                                    setSelectedMDId(item.id);
                                    setmodalType(2);
                                    setShowAddMDModal(true);

                                }}
                            ><i className="fa fa-desktop"></i></button>
                        )
                    }
                    &nbsp;

                    {
                        item.restricted ? (
                            <button type="button" className={`btn btn-sm btn-default disabled`} title="Edit" data-toggle="tooltip"
                                data-placement="top" style={{ cursor: 'not-allowed' }}
                            ><i className="fa fa-pencil-square-o"></i></button>
                        ) : (
                            <button type="button" className={`btn btn-sm btn-default`} title="Edit" data-toggle="tooltip"
                                data-placement="top"
                                onClick={() => {
                                    console.log("ID_2: ", item.id);
                                    setSelectedMDId(item.id);
                                    setmodalType(1);
                                    setShowAddMDModal(true);
                                }}
                            ><i className="fa fa-pencil-square-o"></i></button>
                        )
                    }
                    &nbsp;
                    <button type="button" className="btn btn-sm btn-default" title={`${item.restricted ? 'Enable' : 'Disable'}`} data-toggle="tooltip"
                        data-placement="top"
                        onClick={() => {
                            enableOrDisableMD(item.id);
                        }}
                    >
                        {item.restricted ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                    </button>
                </>
            );
            newDataArray.push(item);
        });
        setMainDisplaysForRender(newDataArray);
        setAllMainDisplayTableBody(newDataArray);
    }, [allMainDisplayList])

    
    // getAllMainDisplays - function
    const getAllMainDisplays = () => {
        
        axios({
            method: 'GET',
            url: `${baseUrl}/main-display/get-all/${selectedBranch.id}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            let dataList = res.data.data.list;
            setAllMainDisplayList(dataList);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //filter - function
    const filter = (e) => {
        const keyword = e.target.value;

        if (keyword !== '') {
            const results = mainDisplaysForRender.filter((cat) => {
                return cat.mainDisplayName.toString().toLowerCase().includes(keyword.toLowerCase());
            });
            setAllMainDisplayTableBody(results);
        } else {
            setAllMainDisplayTableBody(mainDisplaysForRender);
        }

        setSearchText(keyword);
    };

    // enableOrDisableMD - function
    const enableOrDisableMD = (id) => {

        axios({
            method: 'POST',
            url: `${baseUrl}/main-display/enable-disable/display/${id}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            getAllMainDisplays()
        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }


    return (
        <>
            <div><Toaster /></div>
            <div className="container-fluid">

                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h1>Main Displays</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={`/branch-landing?bid=${selectedBranch.id}`}>{selectedBranch.BranchName}</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Main displays</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-md-6 col-sm-12 text-right hidden-xs">
                            <div className="btn btn-sm btn-primary" title="Add New Category"
                                onClick={() => {
                                    setmodalType(0);
                                    setShowAddMDModal(true);
                                }}>Add New
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="body">
                                <div className="row">

                                    <div className="col-lg-3 col-md-6">
                                        <div className="input-group header-counter-center">
                                            <h5 className="mb-0">
                                                {

                                                    allMainDisplayTableBody !== null && allMainDisplayList !== null ?
                                                        <>
                                                            {

                                                                allMainDisplayTableBody.length !== null && allMainDisplayList.length !== null ?
                                                                    <>
                                                                        {searchText.length > 0 ? `${allMainDisplayTableBody.length} /` : ''}
                                                                        <small className="text-muted">{allMainDisplayList.length}</small>
                                                                    </>
                                                                    :
                                                                    <></>

                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </h5>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <div className="input-group">
                                        </div>
                                    </div>

                                    <div className="col-lg-5 col-md-6 ">
                                        <div className="input-group">
                                            <input
                                                value={searchText}
                                                onChange={filter}
                                                type="text" className="form-control"
                                                placeholder="Search here..."
                                            />

                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="button"
                                                ><i className="fa fa-search" /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row clearfix">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="body">

                                <div className="table-responsive stop-overflow-x">

                                    <MDBDataTable
                                        noBottomColumns
                                        className="table table-hover js-basic-example dataTable table-custom spacing5 action-col-align categories-table"
                                        displayEntries={false}
                                        searching={false}
                                        paging
                                        pagesAmount={15}
                                        data={body}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <MainDisplayAddModal
                showAddMDModal={showAddMDModal}
                setShowAddMDModal={setShowAddMDModal}
                getAllMainDisplays={getAllMainDisplays}
                selectedMDId={selectedMDId}
                setSelectedMDId={setSelectedMDId}
                modalType={modalType}
            />
        </>
    )
}
export default MainDisplayManagement