import React from 'react'

import Splinechart from '../../ChartsImpl/Analytics/CategoryAnalyticssplinechart';

const TokenExceptionProcess = () => {
    return (
        <div className="col-xl-6 col-lg-6 col-md-6">
            <div className="card">
                <div className="body">
                    <h6 className="mb-4 font300">Monthly Exceptions process</h6>
                    <Splinechart />
                </div>
            </div>
        </div>
    )
}

export default TokenExceptionProcess