import React, { useEffect, useState } from 'react';
import PersentageDonutChartWithBottomLegend
    from '../../ChartsImpl/DetailsPageCharts/PersentageDonutChartWithBottomLegend';

import toast from "react-hot-toast";
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme } from '@mui/material/styles';


const LobbyDetails = (props) => {

    const [tokenData, settokenData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [isLoading, setLoading] = useState(false);

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');

    useEffect(() => {
        if (props.showLobbyDetails === false) return;
        if (props.messageIncLobby !== '' || props.messageInc1001 !== '') {
            getLobbyData();
        }
    }, [
        // props.messageInc9999, 
        props.messageIncLobby,
        props.messageInc1001
    ]);

    useEffect(() => {
      console.log(tokenData);
    }, [tokenData])
    

    const columns = [
        {
            label: 'Token No',
            name: 'ticketNumber',
            options: {
                sort: true,
                filter: false,
                // customBodyRender: (value) => (
                //     <b>{value}</b>
                // )
            }
        },
        {
            label: "Category",
            name: 'category',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Language',
            name: 'lang',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'Issued Time',
            name: 'issuedTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Waited For Lobby',
            name: 'waitForLobby',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Lobby Entering',
            name: 'startTime',
            options: {
                filter: false,
                sort: true,
            }
        }
    ];

    const options = {
        tableBodyHeight: '53vh',
        filter: false,
        responsive: 'vertical',
        serverSide: false,
        count: count,
        download: false,
        viewColumns: false,
        jumpToPage: true,
        selectableRows: 'none',
        fixedHeader: true,
        print: false,
        rowsPerPage: rowsPerPage,
        onChangeRowsPerPage: (n) => {
            setRowsPerPage(n)
        },
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        }
    };

    const getLobbyData = () => {
        console.log('getLobbyData started');
        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/get-lobby-details/branch/${props.selectedBranchId}/lobby-data-inner`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            let data = res.data.data;
            console.log('getLobbyData success');
            settokenData(data);
            setCount(data.length);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });

            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    const closeModal = () => {
        // setCounterData([]);
        props.setSticky(true)
        props.setShowLobbyDetails(false);
    }

    return (
        <>
            {props.showLobbyDetails ?
                <div id="megamenu" className={`megamenu particles_js details-page open`}>
                    <span
                        onClick={() => {
                            closeModal();
                        }}
                        className="megamenu_toggle btn btn-danger"><i className="icon-close"></i></span>

                    <div className="container">
                        <div className="row clearfix">
                            <div className="col-12">
                                <div className="card">
                                    <div className="body">
                                        <div style={{ textAlign: 'center' }}>
                                            <h4>Lobby Data</h4>
                                            <small>{props.date}</small>
                                            {/* <small>xxxx</small> */}
                                        </div>
                                        <br />
                                        <div className="row clearfix">

                                            <div className="col-12">
                                                <div className="muidatatablecontainer">
                                                    <MUIDataTable
                                                        title={<>
                                                            {count}{count === 1 ? ' record' : ' records'}.
                                                        </>
                                                        }
                                                        data={tokenData}
                                                        columns={columns}
                                                        options={options}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div id="particles-js"></div>
                </div>
                : null
            }
        </>
    )
}

export default LobbyDetails