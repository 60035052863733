import React, {Component} from 'react';
import Chart from "react-apexcharts";

class BasicBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    type: 'bar',
                },
                colors: ['#6435c9'],
                grid: {
                    yaxis: {
                        lines: {
                            show: false,
                        }
                    },
                    padding: {
                        top: 0,
                        right: 20,
                        bottom: 0,
                        left: 20
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        borderRadius: 20,
                        barHeight: 30
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: ['10-100', 'Breaking Time', 'Idle Time'],
                }
            },
            series: [{
                data: [400, 430, 448]
            }],

        };
    }

    render() {
        return (
            <div>
                <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="bar"
                    height="200px"
                />
            </div>
        );
    }
}

export default BasicBar;
