import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';
import Button from 'react-bootstrap-button-loader';

// used for add and edit
const AddCountryModal = ({ addCountryModal, setAddCountryModal, getAll, selectedCountry, setSelectedCountry, clearStates, modalType }) => {

  const [baseUrl, setBaseUrl] = useState('');
  const [token, setToken] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const [buttonLoader, setButtonLoader] = useState(false);

  const [countryName, setCountryName] = useState('');

  // if modal type is edit, set value
  useEffect(() => {
    if (modalType === 1) {
      setCountryName(selectedCountry.name)
    }
  }, [selectedCountry]);

  // get base url
  useEffect(() => {
    setBaseUrl(localStorage.getItem('API_URL'));
    setToken(localStorage.getItem('user_token'));
    setUserDetails(JSON.parse(localStorage.getItem('user_details')));
  }, [AddCountryModal])

  // addNewCountry - function
  const addNewCountry = () => {
    console.log('addNewCountry function start');
    setButtonLoader(true);
    // validate
    if (countryName.length > 0) {

      axios({
        url: `${baseUrl}/branch/add/global-region`,
        method: 'POST',
        headers: {
          Authorization: token
        },
        data: {
          name: countryName,
          level: 1,
          customer: {
            id: userDetails.cid
          }
        }
      }).then((res) => {

        console.log('>> addNewCountry success');

        setSelectedCountry(res.data.data);
        clearStates(1);

        setButtonLoader(false);
        closeModal();
        toast.remove();
        toast.success('Successfully added', {
          position: 'bottom-center',
        });

        getAll(1, 0);

      }).catch((error) => {
        console.log('>> addNewCountry error');
        console.log(error);
        setButtonLoader(false);

        if (error.response !== undefined) {
          toast.remove();
          toast.error(error.response.data.message, {
            position: 'bottom-center',
          });
        } else {
          toast.remove();
          toast.error('Something went wrong', {
            position: 'bottom-center',
          });
        }
      })

    } else {
      setButtonLoader(false);
      toast.remove();
      toast.success('Country name can not br empty', {
        position: 'bottom-center',
      });
    }

  }

  // editCountry - function
  const editCountry = () => {
    console.log('editCountry function start');
    setButtonLoader(true);

    // validate
    if (countryName.length > 0) {

      if (countryName === selectedCountry.name) {
        setButtonLoader(false);
        toast.remove();
        toast.error('Same country name. No need to update', {
          position: 'bottom-center',
        });

      } else {

        axios({
          url: `${baseUrl}/branch/update/region`,
          method: 'POST',
          headers: {
            Authorization: token
          },
          data: {
            id: selectedCountry.id,
            name: countryName,
            customer: {
              id: userDetails.cid
            }
          }
        }).then((res) => {

          console.log('>> editCountry success');
          setButtonLoader(false);
          closeModal();
          toast.remove();
          toast.success('Successfully updated', {
            position: 'bottom-center',
          });

          getAll(1, 0);

        }).catch((error) => {
          console.log('>> editCountry error');
          console.log(error);
          setButtonLoader(false);

          if (error.response !== undefined) {
            toast.remove();
            toast.error(error.response.data.message, {
              position: 'bottom-center',
            });
          } else {
            toast.remove();
            toast.error('Something went wrong', {
              position: 'bottom-center',
            });
          }
        })

      }

    } else {
      setButtonLoader(false);
      toast.remove();
      toast.error('Country name can not be empty', {
        position: 'bottom-center',
      });
    }

  }

  // SwalAlert - function
  function fireAlert(type, text) {
    Swal.fire({
      type: type,
      text: text,
      showConfirmButton: false,
      timer: 2000,
      width: 400
    })
  }

  // closeModal - function
  function closeModal() {
    setAddCountryModal(false);
  }

  // when click esc, close modal
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setAddCountryModal(false);
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  return (
    <>
      {addCountryModal ?
        <div className={`modal fade  d-block show modal-container`} id="exampleModal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">

                {modalType === 0 ? (
                  <h5 className="modal-title" id="exampleModalLabel"> Add New Country</h5>
                ) : (
                  <h5 className="modal-title" id="exampleModalLabel"> Edit Country</h5>
                )}

                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                  onClick={() => {
                    closeModal()
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">

                <div className="input-group input-group-sm mb-3 modal-input-group">
                  <p className='input-field-side-text'>Country Name : </p>

                  {modalType === 0 ? (
                    // add text field
                    <input autoFocus type="text" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                      onChange={(e) => {
                        setCountryName(e.target.value);
                      }}
                    />
                  ) : (
                    // edit text field
                    <input autoFocus type="text" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                      value={countryName}
                      onChange={(e) => {
                        setCountryName(e.target.value);
                      }}
                    />
                  )}

                </div>

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal"
                  onClick={() => {
                    closeModal()
                  }}>Cancel</button>

                {modalType === 0 ? (
                  <Button loading={buttonLoader} type="button" className="btn btn-primary"
                    onClick={() => {
                      addNewCountry();
                    }}
                  >Add Country</Button>
                ) : (
                  <Button loading={buttonLoader} type="button" className="btn btn-primary"
                    onClick={() => {
                      editCountry();
                    }}
                  >Edit Country</Button>
                )}

              </div>
            </div>
          </div>
        </div>
        : ''}
    </>
  )
}

export default AddCountryModal;