import React, { useEffect, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import HourlyCategoryPerformanceReport from "./CategoryReportTables/HourlyCategoryPerformanceReport";
import CategorySummaryReport from "./CategoryReportTables/CategorySummaryReport";
import CategoryWiseCustomerDetailsReport from "./CategoryReportTables/CategoryWiseCustomerDetailsReport";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import MonthlyCategoryPerformanceReport from "./CategoryReportTables/MonthlyCategoryPerformanceReport";

const CategoryReports = ({
  selectedRegionIds,
  changeRegionIds,
  dateFrom,
  dateTo,
  downloadExcel,
  reportUrl,
}) => {
  const baseUrl = localStorage.getItem("API_URL");
  const token = localStorage.getItem("user_token");
  const userDetails = JSON.parse(localStorage.getItem("user_details"));

  const [openHourlyCatePerformanceReport, setOpenHourlyCatePerformanceReport] =
    useState(false);
  const [openCateSummaryReport, setOpenCateSummaryReport] = useState(false);
  const [openCateWiseCusDetailsReport, setOpenCateWiseCusDetailsReport] =
    useState(false);
  const [
    openMonthlyCatePerformanceReport,
    setOpenMonthlyCatePerformanceReport,
  ] = useState(false);

  const [reportsAccess, setReportsAccess] = useState([]);

  // call getReportAccess - useEffect
  useEffect(() => {
    if (reportUrl) {
      getReportAccess();
    }
  }, [CategoryReports, reportUrl]);

  // get report access - function
  const getReportAccess = () => {
    axios({
      method: "GET",
      url: `${reportUrl}/report-controller/get/report-access/customer/${userDetails.cid}/parent/Category Reports/get-report-access`,
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        let data = res.data.data;
        setReportsAccess(data);
      })
      .catch((error) => {
        console.log(error);

        if (error.response !== undefined) {
          toast.error(error.response.data.message, {
            position: "bottom-center",
          });
        } else {
          toast.remove();
          toast.error("Something went wrong", {
            position: "bottom-center",
          });
        }
      });
  };

  return (
    <>
      <div className="row clearfix">
        <div className="col-lg-12">
          <div className="card">
            <Accordion
              className="accordion report-accordion"
              id="accordion"
              defaultActiveKey=""
            >
              {reportsAccess.map((item, i) => {
                if (
                  item.name === "Hourly Category Performance Report" &&
                  item.active
                ) {
                  return (
                    <div>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            className="btn btn-link vertically-align-center-flex"
                            onClick={() => {
                              if (openHourlyCatePerformanceReport) {
                                setOpenHourlyCatePerformanceReport(false);
                              } else {
                                setOpenHourlyCatePerformanceReport(true);
                                setOpenCateSummaryReport(false);
                                setOpenCateWiseCusDetailsReport(false);
                                setOpenMonthlyCatePerformanceReport(false);
                              }
                            }}
                          >
                            Hourly Category Performance Report
                            <div className="float-right vertically-align-center-flex-2">
                              {userDetails !== null ? (
                                <>
                                  {item.pdf ? (
                                    <a
                                      href={`${reportUrl}/report-controller/download-pdf/${dateFrom}/${dateTo}/${
                                        selectedRegionIds.length > 0
                                          ? selectedRegionIds.toString()
                                          : userDetails.bid
                                      }/hourly-category-perfomance-report`}
                                      target="_blank"
                                      className="btn btn-sm btn-default"
                                      title="View PDF"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-pdf-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  ) : null}

                                  {item.excel ? (
                                    <button
                                      className="btn btn-sm btn-default"
                                      title="View Excel"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        downloadExcel(
                                          "hourly-category-performance-report",
                                          "_cr_hcpr_table"
                                        );
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-excel-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : null}
                                </>
                              ) : null}
                              <span className="p-l-20">
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </h5>
                      </div>
                      <Accordion.Collapse eventKey="0" className="collapse">
                        <HourlyCategoryPerformanceReport
                          openHourlyCatePerformanceReport={
                            openHourlyCatePerformanceReport
                          }
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          selectedRegionIds={selectedRegionIds}
                          changeRegionIds={changeRegionIds}
                          reportUrl={reportUrl}
                        />
                      </Accordion.Collapse>
                    </div>
                  );
                } else if (
                  item.name === "Category Summary Report" &&
                  item.active
                ) {
                  return (
                    <div>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="1"
                            className="btn btn-link vertically-align-center-flex"
                            onClick={() => {
                              if (openCateSummaryReport) {
                                setOpenCateSummaryReport(false);
                              } else {
                                setOpenCateSummaryReport(true);
                                setOpenHourlyCatePerformanceReport(false);
                                setOpenCateWiseCusDetailsReport(false);
                                setOpenMonthlyCatePerformanceReport(false);
                              }
                            }}
                          >
                            Category Summary Report
                            <div className="float-right vertically-align-center-flex-2">
                              {userDetails !== null ? (
                                <>
                                  {item.pdf ? (
                                    <a
                                      href={`${reportUrl}/report-controller/download-pdf/${dateFrom}/${dateTo}/${
                                        selectedRegionIds.length > 0
                                          ? selectedRegionIds.toString()
                                          : userDetails.bid
                                      }/category-summary-report`}
                                      target="_blank"
                                      className="btn btn-sm btn-default"
                                      title="View PDF"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-pdf-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  ) : null}

                                  {item.excel ? (
                                    <button
                                      className="btn btn-sm btn-default"
                                      title="View Excel"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        downloadExcel(
                                          "category-summary-report",
                                          "_cr_csr_table"
                                        );
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-excel-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : null}
                                </>
                              ) : null}
                              <span className="p-l-20">
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </h5>
                      </div>
                      <Accordion.Collapse eventKey="1" className="collapse">
                        <CategorySummaryReport
                          openCateSummaryReport={openCateSummaryReport}
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          selectedRegionIds={selectedRegionIds}
                          changeRegionIds={changeRegionIds}
                          reportUrl={reportUrl}
                        />
                      </Accordion.Collapse>
                    </div>
                  );
                } else if (
                  item.name === "Category Wise Customer Details Report" &&
                  item.active
                ) {
                  return (
                    <div>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="2"
                            className="btn btn-link vertically-align-center-flex"
                            onClick={() => {
                              if (openCateWiseCusDetailsReport) {
                                setOpenCateWiseCusDetailsReport(false);
                              } else {
                                setOpenCateWiseCusDetailsReport(true);
                                setOpenCateSummaryReport(false);
                                setOpenHourlyCatePerformanceReport(false);
                                setOpenMonthlyCatePerformanceReport(false);
                              }
                            }}
                          >
                            Category Wise Customer Details Report
                            <div className="float-right vertically-align-center-flex-2">
                              {userDetails !== null ? (
                                <>
                                  {item.pdf ? (
                                    <a
                                      href={`${reportUrl}/report-controller/download-pdf/${dateFrom}/${dateTo}/${
                                        selectedRegionIds.length > 0
                                          ? selectedRegionIds.toString()
                                          : userDetails.bid
                                      }/category-wise-customer-detail`}
                                      target="_blank"
                                      className="btn btn-sm btn-default"
                                      title="View PDF"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-pdf-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  ) : null}

                                  {item.excel ? (
                                    <button
                                      className="btn btn-sm btn-default"
                                      title="View Excel"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        downloadExcel(
                                          "category-wise-customer-report",
                                          "_cr_cwcdr_table"
                                        );
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-excel-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : null}
                                </>
                              ) : null}
                              <span className="p-l-20">
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </h5>
                      </div>
                      <Accordion.Collapse eventKey="2" className="collapse">
                        <CategoryWiseCustomerDetailsReport
                          openCateWiseCusDetailsReport={
                            openCateWiseCusDetailsReport
                          }
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          selectedRegionIds={selectedRegionIds}
                          changeRegionIds={changeRegionIds}
                          reportUrl={reportUrl}
                        />
                      </Accordion.Collapse>
                    </div>
                  );
                } else if (
                  item.name === "Monthly Category Performance Report" &&
                  item.active
                ) {
                  return (
                    <div>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="3"
                            className="btn btn-link vertically-align-center-flex"
                            onClick={() => {
                              if (openMonthlyCatePerformanceReport) {
                                setOpenMonthlyCatePerformanceReport(false);
                              } else {
                                setOpenMonthlyCatePerformanceReport(true);
                                setOpenHourlyCatePerformanceReport(false);
                                setOpenCateSummaryReport(false);
                                setOpenCateWiseCusDetailsReport(false);
                              }
                            }}
                          >
                            Monthly Category Performance Report
                            <div className="float-right vertically-align-center-flex-2">
                              {userDetails !== null ? (
                                <>
                                  {/* {item.pdf ? (
                                                                        <a href={`${baseUrl}/report-controller/download-pdf/${dateFrom}/${dateTo}/${selectedRegionIds.length > 0 ? selectedRegionIds.toString() : userDetails.bid}/hourly-category-perfomance-report`}
                                                                            target='_blank' className="btn btn-sm btn-default" title="View PDF"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                            }}
                                                                        ><i class="fa fa-file-pdf-o" aria-hidden="true"></i></a>
                                                                    ) : null} */}

                                  {item.excel ? (
                                    <button
                                      className="btn btn-sm btn-default"
                                      title="View Excel"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        downloadExcel(
                                          "monthly-category-performance-report",
                                          "_cr_mcpr_table"
                                        );
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-excel-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : null}
                                </>
                              ) : null}
                              <span className="p-l-20">
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </h5>
                      </div>
                      <Accordion.Collapse eventKey="3" className="collapse">
                        <MonthlyCategoryPerformanceReport
                          openMonthlyCatePerformanceReport={
                            openMonthlyCatePerformanceReport
                          }
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          selectedRegionIds={selectedRegionIds}
                          changeRegionIds={changeRegionIds}
                          reportUrl={reportUrl}
                        />
                      </Accordion.Collapse>
                    </div>
                  );
                }
              })}
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryReports;
