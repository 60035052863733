import React, { useEffect, useState, useRef } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';
import CircularProgress from "@mui/material/CircularProgress";


const AgentWiseReport = ({ openAgentWiseReport, dateFrom, dateTo, selectedRegionIds, changeRegionIds, reportUrl }) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [allreportsNew, setAllReportsNew] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const timeoutRef = useRef(null);


    const [dataTable, setDataTable] = useState({
        page: 0,
        size: 10,
        search: '',
        order: null,
    });

    const setTable = (tableState) => {
        setDataTable({
            page: tableState.page,
            search: tableState.searchText,
            size: tableState.rowsPerPage,
            order: tableState.sortOrder
        });
    };

    const columns = [
        {
            label: 'User',
            name: 'userName',
            options: {
                sort: true,
                filter: false,
                // customBodyRender: (value) => (
                //     <b>{value}</b>
                // )
            }
        },
        {
            label: "Date",
            name: 'loginDate',
            options: {
                sort: true,
                filter: false,
                customBodyRender: (value) => (
                    <p style={{ margin: 0, width: '100px' }}>{value}</p>
                )
            }
        },
        {
            label: "Time",
            name: 'loginTime',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Branch",
            name: 'branchName',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Region",
            name: 'regionName',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Average Service time",
            name: 'avgServiceTime',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Tokens Assigned",
            name: 'tokensAssigned',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Tokens Completed",
            name: 'tokensCompleted',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "No tokens put on hold",
            name: 'tokensPutHold',
            options: {
                sort: true,
                filter: false,
            }
        }, {
            label: "No abandoned tokens",
            name: 'tokensAbandoned',
            options: {
                sort: true,
                filter: false,
            }
        },


    ];

    const options = {
        // tableBodyHeight: '80vh',
        viewColumns: false,
        filterType: 'dropdown',
        filter: false,
        responsive: 'vertical',
        serverSide: true,
        count: count,
        download: false,
        jumpToPage: true,
        selectableRows: 'none',
        storageKey: '_ar_awr_table',
        print: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        },
        onTableChange: (action, tableState) => {

            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want

            switch (action) {
                case 'changePage':
                    getAgentWiseReport(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'sort':
                    getAgentWiseReport(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'changeRowsPerPage':
                    setRowsPerPage(tableState.rowsPerPage);
                    getAgentWiseReport(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'search':
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }
                    timeoutRef.current = setTimeout(() => getAgentWiseReport(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage), 2000);
                    setTable(tableState);
                    break;
                default:
                    break;
            }
        },
    };



    // when pick date, call  getAgentWiseReport function
    useEffect(() => {
        if (openAgentWiseReport) {
            if (dateFrom.length > 0 && dateTo.length > 0) {
                getAgentWiseReport(0, null, null, rowsPerPage);
            }
        }
    }, [openAgentWiseReport, dateFrom, dateTo])

    // when change selected region array, call getAgentWiseReport - useEffect
    useEffect(() => {
        if (openAgentWiseReport) {
            getAgentWiseReport(0, null, null, rowsPerPage);
        }
    }, [changeRegionIds])


    // getAgentWiseReport - function
    const getAgentWiseReport = (page, sortOrder, search, size) => {
        setIsLoading(true);

        axios({
            method: 'POST',
            url: `${reportUrl}/real-time/agent-wise-reports/inner-data/customer/${userDetails.cid}`,
            headers: {
                Authorization: token
            },
            data: {
                "page": page,
                "size": size,
                "dateFrom": dateFrom,
                "dateTo": dateTo,
                "sort": sortOrder,
                "searchText": search,
                branchIds: selectedRegionIds
            }

        }).then((res) => {

            let data = res.data.data;

            setAllReportsNew((data.content))
            setCount(data.totalElements);
            setIsLoading(false);

        }).catch((error) => {
            console.log(error);
            setIsLoading(false);

            if (error.response !== undefined) {
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }


    return (
        <>
            <div><ToastContainer /></div>

            <MUIDataTable

                title={
                    <>
                        {isLoading ?
                            <CircularProgress color="secondary" size={20} /> : <>{count} records.</>}
                    </>
                }
                data={allreportsNew}
                columns={columns}
                options={options}
                className='text-center-table'
            />




        </>
    )
}

export default AgentWiseReport
