import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import Button from 'react-bootstrap-button-loader';


// used for add and edit
const AddCounterModal = ({ showAddCounterModal, setShowAddCounterModal, getAllCountersByBranch, modalType, selectedRow, setSelectedRow, dataTable }) => {

    const [baseUrl, setBaseUrl] = useState('');
    const [token, setToken] = useState('');
    const [selectedBranch, setSelectedBranch] = useState({});
    const [userDetails, setUserDetails] = useState({});

    // const baseUrl = localStorage.getItem('API_URL');
    // const token = localStorage.getItem('user_token');
    // const selectedBranch = localStorage.getItem('selected_branch');

    const [currentTab, setCurrentTab] = useState("1");
    const [nextTab, setNextTab] = useState();
    const [refresh, setRefresh] = useState(1);
    const [buttonLoader, setButtonLoader] = useState(false);

    const [allDefaultCategories, setAllDefaultCategories] = useState([]);
    const [allCounterSounds, setAllCounterSounds] = useState([]);
    const [allCounterCategories, setAllCounterCategories] = useState([]);
    const [allCounterTransfers, setAllCounterTransfers] = useState([]);
    const [allTsuProfiles, setAllTsuProfiles] = useState([]);

    const [counterNo, setCounterNo] = useState('');
    const [counterName, setCounterName] = useState('');
    const [selectedDefaultCategory, setSelectedDefaultCategory] = useState('');
    const [seletedSound, setSeletedSound] = useState('');
    const [isVirtual, setIsVirtual] = useState(false);
    const [selectedTsuId, setSelectedTsuId] = useState('');

    // for update
    const [counterId, setCounterId] = useState('');
    const [counterDetails, setCounterDetails] = useState({});

    // select all
    const [allCCSelected, setAllCCSelected] = useState(false);
    const [allTCSelected, setAllTCSelected] = useState(false);


    // get base url & token - useEffect
    useEffect(() => {
        setBaseUrl(localStorage.getItem('API_URL'));
        setToken(localStorage.getItem('user_token'));
        setSelectedBranch(JSON.parse(localStorage.getItem('selected_branch')));
        setUserDetails(JSON.parse(localStorage.getItem('user_details')));
    }, [AddCounterModal])

    // call functions - useEffect
    useEffect(() => {
        if (baseUrl.length > 0 && token.length > 0 && Object.keys(selectedBranch).length > 0) {
            getDefaultCategories();
            getCounterSounds();
            getCounterCategories();
            getCounterTransfer();
            getAllTsuProfiles();
        }
    }, [baseUrl, token, selectedBranch])

    // is update - useEffect
    useEffect(() => {
        if (modalType === 1 || modalType === 2) {
            setCounterNo(selectedRow.counterNo);
            setCounterName(selectedRow.counterName);
            if (selectedRow.defaultCategoryId != undefined) {
                setSelectedDefaultCategory(selectedRow.defaultCategoryId);
            }
            setCounterId(selectedRow.id);
            setSeletedSound(selectedRow.sound);
            setIsVirtual(selectedRow.virtual);
            if (selectedRow.callingPadProfileId != undefined) {
                setSelectedTsuId(selectedRow.callingPadProfileId);
            }
        }
    }, [selectedRow])

    // get counter details for update - useEffect
    useEffect(() => {
        if (showAddCounterModal) {
            if (modalType === 1 || modalType === 2) {
                console.log('getCounterDetails function start');

                axios({
                    method: 'GET',
                    url: `${baseUrl}/counter/get-counter/${counterId}`,
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {

                    console.log('getCounterDetails success');
                    let data = res.data.data
                    console.log(data);
                    if (data != null) {
                        setCounterDetails(data);
                    }

                }).catch((error) => {
                    console.log('getCounterDetails error');
                    console.log(error);

                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }


                })
            }
        }

    }, [counterId])

    // set counter details for update - useEffect
    useEffect(() => {

        if (counterDetails != null || allCounterCategories.length > 0) {
            let selectedCounterCategories = counterDetails.counterCategories;
            for (let i = 0; i < allCounterCategories.length; i++) {

                if (selectedCounterCategories.length > 0) {
                    for (let j = 0; j < selectedCounterCategories.length; j++) {

                        if (allCounterCategories[i].id === selectedCounterCategories[j].categoryId) {
                            allCounterCategories[i].selected = true
                        }

                    }
                }

            }
            checkAllCCSelectedOrNot();
        }

        if (counterDetails != null) {
            let selectedCounterTransfers = counterDetails.counterTransfers;
            for (let i = 0; i < allCounterTransfers.length; i++) {
                for (let j = 0; j < selectedCounterTransfers.length; j++) {

                    if (allCounterTransfers[i].id === selectedCounterTransfers[j].categoryId) {
                        allCounterTransfers[i].selected = true
                    }

                }

            }

            setRefresh(refresh + 1);
            checkAllTCSelectedOrNot();
        }
    }, [counterDetails])


    // getDefaultCategories - function
    const getDefaultCategories = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/category/get-categories/branch/${selectedBranch.id}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let dataList = res.data.data.list;
            setAllDefaultCategories(dataList);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    // getCounterSounds - function
    const getCounterSounds = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/counter-voice/get-all/type/2`, // hard code
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let dataList = res.data.data.list;
            setAllCounterSounds(dataList);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    // getCounterCategories - function
    const getCounterCategories = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/counter/get-list/without-sub-categories/${selectedBranch.id}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let dataList = res.data.data.list;

            for (let j = 0; j < dataList.length; j++) {
                dataList[j].selected = false;
            }

            setAllCounterCategories(dataList);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }


        })
    }

    // getCounterTransfer - function
    const getCounterTransfer = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/counter/get-list/without-category-groups/${selectedBranch.id}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let dataList = res.data.data.list;

            for (let i = 0; i < dataList.length; i++) {
                dataList[i].selected = false;
            }
            setAllCounterTransfers(dataList);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    // getAllTsuProfiles - function
    const getAllTsuProfiles = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/branch/calling-pad-profile/get-list/customer/${userDetails.cid}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let data = res.data.data;
            setAllTsuProfiles(data);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })

    }


    // addCounter - function
    const addCounter = () => {

        setButtonLoader(true);

        let selectedCounterCategoriesList = filterSelectedCounterCategories();

        let selectedCounterTransferList = filterSelectedCounterTransfer();

        let isValidate = validateData(selectedCounterCategoriesList, selectedCounterTransferList);

        if (isValidate) {

            axios({
                method: 'POST',
                url: `${baseUrl}/counter/add`,
                headers: {
                    Authorization: token
                },
                data: {
                    branch: selectedBranch.id,
                    counterNo: counterNo,
                    counterName: counterName,
                    defaultCategoryId: selectedDefaultCategory.length === 0 ? null : selectedDefaultCategory,
                    sound: seletedSound,
                    virtual: isVirtual,
                    fbTemplateId: 2, // hard code **
                    callingPadProfileId: selectedTsuId,
                    counterCategories: selectedCounterCategoriesList,
                    counterTransfer: selectedCounterTransferList
                }
            }).then((res) => {
                console.log(res.data.data);

                toast.remove();
                toast.success('Successfully added', {
                    position: 'bottom-center',
                });

                setButtonLoader(false);
                closeModal();
                getAllCountersByBranch(0, null, null, dataTable.size);

            }).catch((error) => {
                console.log(error);

                setButtonLoader(false);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                    setButtonLoader(false);
                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                    setButtonLoader(false);
                }
            })
        }
    }

    // updateCounter - function
    const updateCounter = () => {

        setButtonLoader(true);

        let selectedCounterCategoriesList = filterSelectedCounterCategories();

        let selectedCounterTransferList = filterSelectedCounterTransfer();

        let isValidate = validateData(selectedCounterCategoriesList, selectedCounterTransferList);

        if (isValidate) {

            axios({
                method: 'POST',
                url: `${baseUrl}/counter/update`,
                headers: {
                    Authorization: token
                },
                data: {
                    id: counterId,
                    branch: selectedBranch.id,
                    counterNo: counterNo,
                    counterName: counterName,
                    defaultCategoryId: selectedDefaultCategory === undefined ? null : selectedDefaultCategory,
                    sound: seletedSound,
                    virtual: isVirtual,
                    fbTemplateId: 2, // hard code **
                    callingPadProfileId: selectedTsuId,
                    counterCategories: selectedCounterCategoriesList,
                    counterTransfer: selectedCounterTransferList
                }
            }).then((res) => {
                toast.remove();
                toast.success('Successfully updated', {
                    position: 'bottom-center',
                });

                setButtonLoader(false);
                closeModal();
                getAllCountersByBranch(dataTable.page, dataTable.order, dataTable.search, dataTable.size);

            }).catch((error) => {
                setButtonLoader(false);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                    setButtonLoader(false);
                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                    setButtonLoader(false);
                }
            })
        }
    }

    // filterSelectedCounterCategories - function
    const filterSelectedCounterCategories = () => {
        let selectedCounterCategoriesList = []
        for (let i = 0; i < allCounterCategories.length; i++) {
            if (allCounterCategories[i].selected === true) {
                selectedCounterCategoriesList.push(allCounterCategories[i].id);
            }
        }
        return selectedCounterCategoriesList;
    }

    // filterSelectedCounterTransfer
    const filterSelectedCounterTransfer = () => {
        let selectedCounterTransferList = [];

        for (let i = 0; i < allCounterTransfers.length; i++) {
            if (allCounterTransfers[i].selected === true) {
                selectedCounterTransferList.push(allCounterTransfers[i].id);
            }
        }
        return selectedCounterTransferList;
    }

    // validateData - function
    const validateData = (selectedCounterCategoriesList, selectedCounterTransferList) => {
        if (counterNo.length === 0) {
            toast.remove();
            toast.error('Counter no empty', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            setNextTab("1");
            setCurrentTab("1");
            return false;
        } else if (counterName.length === 0) {
            toast.remove();
            toast.error('Counter name empty', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            setNextTab("1");
            setCurrentTab("1");
            return false;

            // } else if (selectedDefaultCategory.length === 0) {
            //     toast.remove();
            //     toast.error('Default category empty. Please select', {
            //         position: 'bottom-center',
            //     });
            //     setButtonLoader(false);
            //     setNextTab("1");
            //     setCurrentTab("1");
            //     return false;

        } else if (seletedSound.length === 0) {
            toast.remove();
            toast.error('Sound empty. Please select', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            setNextTab("1");
            setCurrentTab("1");
            return false;
        } else {
            return true;
        }
    }

    // handleTabNext - function
    const handleTabNext = () => {
        if (currentTab === '1') {
            setNextTab("2");
            setCurrentTab("2");
        } else if (currentTab === '2') {
            setNextTab("3");
            setCurrentTab("3");
        }
    }

    // handleTabBack - function
    const handleTabBack = () => {
        if (currentTab === '3') {
            setNextTab("2");
            setCurrentTab("2");
        } else if (currentTab === '2') {
            setNextTab("1");
            setCurrentTab("1");
        }
    }

    // closeModal - function
    const closeModal = () => {
        setCounterNo('');
        setCounterName('');
        setSelectedDefaultCategory('');
        setSeletedSound('');

        for (let i = 0; i < allCounterCategories.length; i++) {
            allCounterCategories[i].selected = false;
        }
        for (let i = 0; i < allCounterTransfers.length; i++) {
            allCounterTransfers[i].selected = false;
        }

        setCurrentTab("1");
        setNextTab(undefined);

        setShowAddCounterModal(false);

        setCounterId('');
        setSelectedRow({});

        setAllCCSelected(false)
        setAllTCSelected(false);
        setSelectedTsuId('');
    }


    // ------------------- select all -------------------

    useEffect(() => {
        if (allCounterCategories.length > 0) {
            checkAllCCSelectedOrNot();
        }
    }, [allCounterCategories])

    // select all counter categories - function
    const selectAllCC = (checked) => {
        if (checked) {
            allCounterCategories.forEach((element, i) => {
                element.selected = true;
            });
            setAllCCSelected(true);
        } else {
            allCounterCategories.forEach((element, i) => {
                element.selected = false;
            });
            setAllCCSelected(false);
        }
        setRefresh(refresh + 1)
    }

    // check all counter categories selected or not - function
    const checkAllCCSelectedOrNot = () => {

        for (let i = 0; i < allCounterCategories.length; i++) {
            const element = allCounterCategories[i];

            if (element.selected === false) {
                setAllCCSelected(false)
                return;
            } else {
                setAllCCSelected(true)
            }

        }

    }


    useEffect(() => {
        if (allCounterTransfers.length > 0) {
            checkAllCCSelectedOrNot();
        }
    }, [allCounterTransfers])


    // select all counter categories - function
    const selectAllTC = (checked) => {
        if (checked) {
            allCounterTransfers.forEach((element, i) => {
                element.selected = true;
            });
            setAllTCSelected(true);
        } else {
            allCounterTransfers.forEach((element, i) => {
                element.selected = false;
            });
            setAllTCSelected(false);
        }
        setRefresh(refresh + 1)
    }

    // check all transfer categories selected or not - function
    const checkAllTCSelectedOrNot = () => {

        for (let i = 0; i < allCounterTransfers.length; i++) {
            const element = allCounterTransfers[i];

            if (element.selected === false) {
                setAllTCSelected(false)
                return;
            } else {
                setAllTCSelected(true)
            }

        }

    }



    return (
        <>
            <div><Toaster /></div>
            {showAddCounterModal ? (
                <div className={`modal fade d-block show modal-container`} id="counter-modal">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    {modalType === 0 ? ('Add Counter') : modalType === 1 ? ('Update Counter') : ('View Counter')} </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">

                                <Tabs id="uncontrolled-tab-example"
                                    activeKey={nextTab}
                                    onSelect={(key) => {
                                        setCurrentTab(key);
                                        setNextTab(key);
                                    }}
                                >

                                    <Tab eventKey="1" title="Details" tabClassName="custom_tab">

                                        <form className='counter-add-form mt-3'>

                                            <div className="input-group input-group-sm mb-3 modal-input-group">
                                                <p className="input-field-side-text">Counter No : </p>
                                                <input type="number" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                    value={counterNo}
                                                    readOnly={modalType === 2 ? true : false}
                                                    onChange={(e) => {
                                                        setCounterNo(e.target.value);

                                                    }}
                                                />
                                            </div>

                                            <div className="input-group input-group-sm mb-3 modal-input-group">
                                                <p className="input-field-side-text">Counter Name : </p>
                                                <input type="text" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                    value={counterName}
                                                    readOnly={modalType === 2 ? true : false}
                                                    onChange={(e) => {
                                                        setCounterName(e.target.value);

                                                    }}
                                                />
                                            </div>

                                            <div className="input-group mb-3">
                                                <p className="input-field-side-text">TSU Profile : </p>
                                                <select className="custom-select modal-select-field" id="inputGroupSelect01"
                                                    value={selectedTsuId}
                                                    disabled={modalType === 2 ? true : false}
                                                    onChange={(e) => {
                                                        setSelectedTsuId(e.target.value);
                                                    }}
                                                >
                                                    <option hidden>Choose...</option>
                                                    {allTsuProfiles.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="input-group mb-3">
                                                <p className="input-field-side-text">Sound : </p>
                                                <select className="custom-select modal-select-field" id="inputGroupSelect01"
                                                    value={seletedSound}
                                                    disabled={modalType === 2 ? true : false}
                                                    onChange={(e) => {
                                                        setSeletedSound(e.target.value);
                                                    }}
                                                >
                                                    <option hidden>Choose...</option>
                                                    {
                                                        allCounterSounds.map((item, i) => (
                                                            <option value={item.id}>{item.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>

                                            <div className='input-group mb-3'>
                                                <p className="input-field-side-text">Virtual : </p>
                                                <label className="switch custom-swtch">
                                                    <input type="checkbox"
                                                        disabled={modalType === 2 ? true : false}
                                                        checked={isVirtual ? true : false}
                                                        onChange={(e) => {
                                                            setIsVirtual(e.target.checked);
                                                            if (e.target.checked === false) {
                                                                setSelectedDefaultCategory('');
                                                            }
                                                        }}
                                                    />
                                                    <span className='slider'
                                                    > Virtual</span>
                                                </label>
                                            </div>

                                            {
                                                isVirtual ? (
                                                    <div className="input-group mb-3">
                                                        <p className="input-field-side-text">Default Category : </p>
                                                        <select className="custom-select modal-select-field" id="inputGroupSelect01"
                                                            value={selectedDefaultCategory}
                                                            disabled={modalType === 2 ? true : false}
                                                            onChange={(e) => {
                                                                setSelectedDefaultCategory(e.target.value);
                                                            }}
                                                        >
                                                            <option hidden>Choose...</option>
                                                            {allDefaultCategories.map((item, index) =>
                                                                <option
                                                                    value={item.id}
                                                                    key={index}
                                                                >{item.category}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                ) : null
                                            }
                                        </form>
                                    </Tab>


                                    <Tab eventKey="2" title="Counter Category" tabClassName="custom_tab">

                                        <div className="body">

                                            <div className='container'>

                                                {allCounterCategories.length > 0 ?
                                                    <>

                                                        {modalType !== 2 ? (
                                                            <div className='row'>
                                                                <div className='col-12 p-l-5 p-r-5'>
                                                                    <div className="selecte-all-container mb-3 float-right">
                                                                        <p className="mb-0 m-r-15">Select All : </p>
                                                                        <label
                                                                            className="switch custom-swtch" title="Select All">
                                                                            <input type="checkbox"
                                                                                checked={allCCSelected ? true : false}
                                                                                onChange={(e) => {
                                                                                    selectAllCC(e.target.checked);
                                                                                }}

                                                                            />
                                                                            <span className='slider'
                                                                            > Select All</span>

                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : null}

                                                    </>
                                                    : null}

                                                <div className='row'>
                                                    <div className='col-12 p-l-5 p-r-5'>
                                                        <div className='modal-list-scroll'>
                                                            <ul className="list-group">

                                                                {allCounterCategories.length > 0 ?
                                                                    <>
                                                                        {allCounterCategories.map((item, index) =>
                                                                            <li className={`list-group-item d-flex justify-content-between align-items-center ${item.selected === true ? 'active' : ''}`} key={index}
                                                                                onClick={() => {
                                                                                    if (modalType === 0 || modalType === 1) {
                                                                                        item.selected = !item.selected;
                                                                                        setRefresh(refresh + 1);
                                                                                        checkAllCCSelectedOrNot()
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {item.category}
                                                                                {item.catGroup === true ? (<span style={{ color: 'green', marginRight: '4px' }}>&#71;</span>) : null}
                                                                            </li>
                                                                        )}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className="alert alert-custom alert-dismissible" role="alert">
                                                                            <i className="fa fa-info-circle"></i> Empty records
                                                                        </div>
                                                                    </>
                                                                }

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </Tab>


                                    <Tab eventKey="3" title="Transfer Category" tabClassName="custom_tab">

                                        <div className="body">

                                            <div className='container'>

                                                {allCounterTransfers.length > 0 ?
                                                    <>

                                                        {modalType !== 2 ? (
                                                            <div className='row'>
                                                                <div className='col-12 p-l-5 p-r-5'>
                                                                    <div className="selecte-all-container mb-3 float-right">
                                                                        <p className="mb-0 m-r-15">Select All : </p>
                                                                        <label
                                                                            className="switch custom-swtch" title="Select All">
                                                                            <input type="checkbox"
                                                                                checked={allTCSelected ? true : false}
                                                                                onChange={(e) => {
                                                                                    selectAllTC(e.target.checked);
                                                                                }}

                                                                            />
                                                                            <span className='slider'
                                                                            > Select All</span>

                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        ) : null}

                                                    </>
                                                    : null}

                                                <div className='row'>
                                                    <div className='col-12 p-l-5 p-r-5'>
                                                        <div className='modal-list-scroll'>
                                                            <ul className="list-group">

                                                                {allCounterTransfers.length > 0 ?
                                                                    <>
                                                                        {allCounterTransfers.map((item, index) =>
                                                                            <li className={`list-group-item d-flex justify-content-between align-items-center ${item.selected === true ? 'active' : ''}`}
                                                                                key={index}
                                                                                onClick={() => {
                                                                                    if (modalType === 0 || modalType === 1) {
                                                                                        item.selected = !item.selected;
                                                                                        setRefresh(refresh + 1);
                                                                                        checkAllTCSelectedOrNot();
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {item.category}
                                                                            </li>
                                                                        )}</>
                                                                    :
                                                                    <>
                                                                        <div className="alert alert-custom alert-dismissible" role="alert">
                                                                            <i className="fa fa-info-circle"></i> Empty records
                                                                        </div>
                                                                    </>
                                                                }


                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </Tab>

                                </Tabs>

                            </div>

                            <div className="modal-footer">
                                {currentTab === "2" || currentTab === "3" ? (
                                    <button type="button" className="btn btn-primary"
                                        onClick={() => {
                                            handleTabBack();
                                        }}
                                    ><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                                ) : null
                                }

                                {currentTab === "1" || currentTab === "2" ? (
                                    <button type="button" className="btn btn-primary"
                                        onClick={() => {
                                            handleTabNext();
                                        }}
                                    ><i className="fa fa-chevron-right" aria-hidden="true"></i></button>
                                ) : (
                                    <>
                                        {modalType === 0 ?
                                            (<Button loading={buttonLoader} type="button" className="btn btn-primary"
                                                onClick={() => {
                                                    addCounter();
                                                }}
                                            >Save</Button>)
                                            :
                                            modalType === 1 ?
                                                (<Button loading={buttonLoader} type="button" className="btn btn-primary"
                                                    onClick={() => {
                                                        updateCounter();
                                                    }}
                                                >Update</Button>)
                                                :
                                                null
                                        }
                                    </>

                                )
                                }
                            </div>

                        </div>
                    </div>
                </div>
            ) : null
            }

        </>

    )
}

export default AddCounterModal