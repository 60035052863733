import React, { useEffect, useState } from 'react';

import toast from "react-hot-toast";
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from "@mui/material/CircularProgress";
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const OperatorsAUXDetails = (props) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');

    const [auxDetails, setAuxDetails] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [update, setUpdate] = useState('1s')

    const [count, setCount] = useState(0);

    useEffect(() => {
        if (props.showOperatorAuxDetails === false) return;
        if (props.auxInc > 0 || props.messageInc9999 !== '' || props.messageInc9998 !== '') {
            getOperatorsAUXDetails();
        }
    }, [props.auxInc, props.messageInc9999, props.messageInc9998]);

    const options = {
        tableBodyHeight: '50vh',
        filter: false,
        responsive: 'vertical',
        serverSide: false,
        count: count,
        download: false,
        viewColumns: false,
        jumpToPage: true,
        selectableRows: 'none',
        print: false,
        rowsPerPage: rowsPerPage,
        onChangeRowsPerPage: (n) => {
            setRowsPerPage(n)
        },
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        }
    };

    const columns = [
        {
            label: 'Operator',
            name: 'user',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Counter",
            name: 'counter',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Mattercode',
            name: 'matterCode',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Added Time',
            name: 'addedTime',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            label: 'KPI',
            name: 'kpi',
            options: {
                filter: false,
                sort: true,
            },
        },
        // {
        //     label: 'End Time',
        //     name: 'endTime',
        //     options: {
        //         filter: false,
        //         sort: true,
        //     },
        // },
        {
            label: 'Exception',
            name: 'id',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    if (auxDetails.length < 1) return;
                    let data = auxDetails.find(item => item.id === value);
                    return (<div style={{
                        color: data.auxExceptionFromSec > data.kpiSec ? '#FF8A6E' : ''
                    }}>

                        {
                            data.auxExceptionFromSec > data.kpiSec ? '⚠️' : ''
                        }
                        {data.auxException}
                    </div>);
                }
            },
        }

    ];

    const columns_1 = [
        {
            label: "Branch",
            name: 'branchName',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Operator',
            name: 'user',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Counter",
            name: 'counter',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Mattercode',
            name: 'matterCode',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Added Time',
            name: 'addedTime',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            label: 'KPI',
            name: 'kpi',
            options: {
                filter: false,
                sort: true,
            },
        },
        // {
        //     label: 'End Time',
        //     name: 'endTime',
        //     options: {
        //         filter: false,
        //         sort: true,
        //     },
        // },
        {
            label: 'Exception',
            name: 'id',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    if (auxDetails.length < 1) return;
                    let data = auxDetails.find(item => item.id === value);
                    return (<div style={{
                        color: data.auxExceptionFromSec > data.kpiSec ? '#FF8A6E' : ''
                    }}>

                        {
                            data.auxExceptionFromSec > data.kpiSec ? '⚠️' : ''
                        }
                        {data.auxException}
                    </div>);
                }
            },
        }

    ];

    useEffect(() => {

        const interval = setInterval(() => {
            if (auxDetails !== null && auxDetails.length > 0) {
                for (let index = 0; index < auxDetails.length; index++) {
                    const element = auxDetails[index];
                    element.auxExceptionFromSec = element.auxExceptionFromSec + 5;

                    let hours = Math.floor(element.auxExceptionFromSec / 3600);
                    let minutes = Math.floor((element.auxExceptionFromSec - (hours * 3600)) / 60);
                    let seconds = element.auxExceptionFromSec - (hours * 3600) - (minutes * 60);

                    if (hours < 10) {
                        hours = "0" + hours;
                    }
                    if (minutes < 10) {
                        minutes = "0" + minutes;
                    }
                    if (seconds < 10) {
                        seconds = "0" + seconds;
                    }

                    element.auxException = `${hours}:${minutes}:${seconds}`;
                }
                setAuxDetails(auxDetails);
                setUpdate(new Date().toString());
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [auxDetails]);

    const getOperatorsAUXDetails = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/get-operator-aux-details/branch/${props.selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            let data = res.data.data;
            console.log('XXX >>>>>> ', data);
            setAuxDetails(data);
            setCount(data.length);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });

            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    };

    const closeModal = () => {
        props.setshowOperatorAuxDetails(false);
        props.setSticky(true)
    }

    return (
        <>
            {props.showOperatorAuxDetails ?
                <div id="megamenu" className={`megamenu particles_js details-page open`}>
                    <span
                        onClick={() => {
                            closeModal();

                        }}
                        className="megamenu_toggle btn btn-danger"><i className="icon-close"></i></span>

                    <div className="container">
                        <div className="row clearfix">
                            <div className="col-12">
                                <div className="card">
                                    <div className="body">
                                        <div style={{ textAlign: 'center' }}>
                                            <h4>Counter AUX Data</h4>
                                            {/* <small>xxxx</small> */}
                                        </div>
                                        <br />
                                        <div className="row clearfix">

                                            <div className="col-12">
                                                <div className="muidatatablecontainer">
                                                    {
                                                        props.selectedBranchLevel === 4 ? (
                                                            <MUIDataTable
                                                                title={<>
                                                                    {count}{count === 1 ? ' record' : ' records'}.
                                                                </>
                                                                }
                                                                data={auxDetails}
                                                                columns={columns}
                                                                options={options}
                                                            />
                                                        ) : (
                                                            <MUIDataTable
                                                                title={<>
                                                                    {count}{count === 1 ? ' record' : ' records'}.
                                                                </>
                                                                }
                                                                data={auxDetails}
                                                                columns={columns_1}
                                                                options={options}
                                                            />
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div id="particles-js"></div>
                </div>
                : null}
        </>
    )
}

export default OperatorsAUXDetails