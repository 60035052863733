import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap-button-loader';
import axios from 'axios';
import toast, { Toaster } from "react-hot-toast";


const AddSmsGroupModal = ({ setShowAddSmsGroupModal, showAddSmsGroupModal, getAllSmsGroups, setSelectedSmsGroup, modalType, selectedSmsGroupId }) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));
    const selectedBranch = JSON.parse(localStorage.getItem('selected_branch'));

    const [smsContent, setSmsContent] = useState('');
    const [selectedSmsTypeId, setSelectedSmsTypeId] = useState('');

    const [groupTypes, setGroupTypes] = useState([]);
    const [usersList, setUsersList] = useState([]);

    const [groupeName, setGroupName] = useState('');
    const [selectedGroupTypeId, setSelectedGroupTypeId] = useState('');
    const [selectedUsersList, setSelectedUsersList] = useState([]);

    const [buttonLoader, setButtonLoader] = useState(false);
    const [refresh, setRefresh] = useState(0);


    // call startup functions - useEffect
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            getSmsGroupTypes();
            getAllUsersForGroup();
        }
    }, [AddSmsGroupModal])

    // is update - useEffect
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            if (showAddSmsGroupModal && modalType === 1) {
                if (selectedSmsGroupId != '') {
                    getSmsGroupInfoById();
                }
            }
        }
    }, [showAddSmsGroupModal])



    // get sms group types - function
    const getSmsGroupTypes = () => {
        axios({
            method: 'GET',
            url: `${baseUrl}/alert/alert-group/get-all/customer/${userDetails.cid}`,
            headers: {
                Authorization: token
            },
        }).then((res) => {

            let data = res.data.data;
            setGroupTypes(data);

        }).catch((error) => {
            console.log('getSmsGroupTypes error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // get sms group types - function
    const getAllUsersForGroup = () => {
        axios({
            method: 'GET',
            url: `${baseUrl}/branch/get-list/users/branch/${selectedBranch.id}`,
            headers: {
                Authorization: token
            },
        }).then((res) => {

            let data = res.data.data;
            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                element.selected = false;
            }
            setUsersList(data);

        }).catch((error) => {
            console.log('getAllUsersForGroup error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // add Sms Group - function
    const addSmsGroup = () => {

        setButtonLoader(true);

        let selectedUsers = filterSelectedUsers();

        let isValidate = validate(selectedUsers);

        if (isValidate) {

            axios({
                method: 'POST',
                url: `${baseUrl}/alert/alert-group/add`,
                headers: {
                    Authorization: token
                },
                data: {
                    name: groupeName,
                    typeId: selectedGroupTypeId,
                    branchId: selectedBranch.id,
                }
            }).then((res) => {

                let data = res.data.data;
                addSmsGroupUsers(selectedUsers, data)

            }).catch((error) => {
                console.log('addSmsGroup error');
                console.log(error);

                setButtonLoader(false);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }
            })
        }
    }

    // add users when added sms group - function
    const addSmsGroupUsers = (selectedUsersList, newGroup) => {

        axios({
            method: 'POST',
            url: `${baseUrl}/alert/alert-group/add/users/group/${newGroup.id}`,
            headers: {
                Authorization: token
            },
            data: {
                userList: selectedUsersList,
            }
        }).then((res) => {
            console.log('addSmsGroupUsers success');
            let data = res.data.data;

            toast.remove();
            toast.success('Group successfully added', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            closeModal();
            getAllSmsGroups();
            setSelectedSmsGroup(newGroup);

        }).catch((error) => {
            console.log('addSmsGroupUsers error');
            console.log(error);

            setButtonLoader(false);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
                setButtonLoader(false);
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
                setButtonLoader(false);
            }
        })

    }

    // get sms group info by id - function
    const getSmsGroupInfoById = () => {
        axios({
            method: 'GET',
            url: `${baseUrl}/alert/alert-group/${selectedSmsGroupId}/branchId/${selectedBranch.id}`,
            headers: {
                Authorization: token
            },
        }).then((res) => {

            let data = res.data.data;

            setGroupName(data.groupName);
            setSelectedGroupTypeId(data.typeId);

            let infoUserArray = data.users;
            for (let i = 0; i < usersList.length; i++) {
                for (let j = 0; j < infoUserArray.length; j++) {
                    if (usersList[i].id === infoUserArray[j].id) {
                        usersList[i].selected = true;
                    }

                }
                setRefresh(refresh + 1);
            }
            setRefresh(refresh + 1);

        }).catch((error) => {
            console.log('getSmsGroupInfoById error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // update sms group - function
    const updateSmsGroup = () => {
        setButtonLoader(true);

        let selectedUsers = filterSelectedUsers();

        let isValidate = validate(selectedUsers);

        if (isValidate) {
            addSmsGroupUsers(selectedUsers, { id: selectedSmsGroupId });
        }
    }


    // filterSelectedUsers - function
    const filterSelectedUsers = () => {
        let selectedUsers = [];
        for (let i = 0; i < usersList.length; i++) {
            if (usersList[i].selected === true) {
                selectedUsers.push(usersList[i].id)
            }
        }
        return selectedUsers;
    }

    // validate - function
    const validate = (selectedUsers) => {
        if (groupeName.length === 0) {
            toast.remove();
            toast.error('Group name empty', {
                position: "bottom-center",
            });
            setButtonLoader(false);
            return false;
        } else if (selectedGroupTypeId.length === 0) {
            toast.remove();
            toast.error('Group type empty', {
                position: "bottom-center",
            });
            setButtonLoader(false);
            return false;
        } else if (selectedUsers.length === 0) {
            toast.remove();
            toast.error('Please select one or more users', {
                position: "bottom-center",
            });
            setButtonLoader(false);
            return false;
        } else {
            return true;
        }
    };

    // closemodal- function
    const closeModal = () => {
        setGroupName('');
        setSelectedGroupTypeId('');
        for (let i = 0; i < usersList.length; i++) {
            usersList[i].selected = false;
        }

        setButtonLoader(false);
        setShowAddSmsGroupModal(false);
    }

    return (
        <>
            {showAddSmsGroupModal ? (
                <div className={`modal fade d-block show modal-container`} id="sms-group-modal">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    {modalType === 0 ?
                                        <>Add new SMS group</> : <>Edit SMS group</>}
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        closeModal()
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body sms-group-modal-body">

                                <div className="row clearfix">

                                    <div className="col-md-12 col-sm-12">
                                        <div className="input-group input-group-sm mb-3">
                                            <p className="input-field-side-text">Group Name : </p>

                                            <input type="text" className="form-control disable disabled"
                                                aria-label="Small"
                                                aria-describedby="inputGroup-sizing-sm"
                                                readOnly={modalType === 1 ? true : false}
                                                value={groupeName}
                                                onChange={(e) => {
                                                    setGroupName(e.target.value);
                                                }} />
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="input-group input-group-sm mb-3">
                                            <p className="input-field-side-text">Type : </p>

                                            <select className="form-control disable disabled"
                                                aria-label="Small"
                                                aria-describedby="inputGroup-sizing-sm"
                                                // readOnly={modalType === 2 ? true : false}
                                                disabled={modalType === 1 ? true : false}
                                                value={selectedGroupTypeId}
                                                onChange={(e) => {
                                                    setSelectedGroupTypeId(e.target.value);
                                                }} >
                                                {groupTypes.length > 0 ? (
                                                    <>
                                                        < option hidden >Choose...</option>
                                                        {groupTypes.map((item, i) =>
                                                            < option value={item.id} >{item.type}</option>
                                                        )}
                                                    </>

                                                ) : null}

                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className='users-list p-t-20 p-l-5 p-r-5'>
                                    <div className="row clearfix">

                                        {usersList.length > 0 ? (
                                            <>
                                                {usersList.map((item, i) =>
                                                    <div className="col-md-4 p-l-5 p-r-5">
                                                        <div className={`sms-group-user-card ${item.selected ? 'active' : null}`}
                                                            title={`${item.firstName} ${item.lastName}`}
                                                            onClick={() => {
                                                                if (item.selected) {
                                                                    item.selected = false
                                                                } else {
                                                                    item.selected = true
                                                                }
                                                                setRefresh(refresh + 1);
                                                            }}
                                                        >
                                                            {item.image === undefined ? (
                                                                <img src={require('../../assets/images/others/no-profile.jpg')} className='user-avatar' />
                                                            ) : (
                                                                <img src={item.image} className='user-avatar' />
                                                            )

                                                            }
                                                            <small className='name-text m-l-5'>{item.firstName} {item.lastName}</small>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div className="col-md-12">
                                                <div class="alert alert-custom alert-dismissible" role="alert">
                                                    <i class="fa fa-info-circle"></i> No users
                                                </div>
                                            </div>

                                        )}

                                    </div>
                                </div>

                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-default"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >Cancel
                                </button>



                                {modalType === 0 ?
                                    <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                        onClick={() => {
                                            addSmsGroup();
                                        }}
                                    >Create Group</Button>
                                    : modalType === 1 ?
                                        <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                            onClick={() => {
                                                updateSmsGroup();
                                            }}
                                        >Update changes</Button>
                                        :
                                        (null)
                                }
                            </div>

                        </div>
                    </div>
                </div >
            ) : null
            }

        </>
    )
}

export default AddSmsGroupModal;