import React, {useEffect, useState} from 'react'
import toast, {Toaster} from 'react-hot-toast';
import {Button, Tab, Tabs} from 'react-bootstrap';
import axios from "axios";

// used for add and edit
const MainDisplayAddModal = ({showAddMDModal, setShowAddMDModal, getAllMainDisplays, selectedMDId, setSelectedMDId, modalType}) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const selectedBranch = JSON.parse(localStorage.getItem('selected_branch'));

    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [macAddress, setMacAddress] = useState('');
    const [isSound, setIsSound] = useState(false);

    const [allCounters, setAllCounters] = useState([]);
    const [mainDisplay, setMainDisplay] = useState({});
    // const [assignCounters, setAssignCounters] = useState({});

    const [tab, setTab] = useState("1");
    const [date, setDate] = useState(new Date().toString());
    const [nextTab, setNextTab] = useState();

    const [buttonLoader, setButtonLoader] = useState(false);
    const [refresh, setRefresh] = useState(1);


    // call getAllCountersByBranch - useEffect
    useEffect(() => {
        getAllCountersByBranch();
    }, [MainDisplayAddModal])

    // if edit or view, call getMainDisplayById - useEffect
    useEffect(() => {
        if (showAddMDModal) {
            let allCounterTemp = allCounters;
            for (let i = 0; i < allCounterTemp.length; i++) {
                allCounterTemp[i].documentCount = '';
            }
            setAllCounters(allCounterTemp);
            if (modalType === 1 || modalType === 2) {
                getMainDisplayById();
            }
        }
    }, [showAddMDModal])


    // get allCounters by branch id
    const getAllCountersByBranch = () => {

        axios({
            method: "GET",
            url: `${baseUrl}/counter/get-list/${selectedBranch.id}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let data = res.data.data;
            for (let j = 0; j < data.length; j++) {
                data[j].selected = false;
            }
            setAllCounters(data);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // add new main display
    const addMainDisplay = () => {
        setButtonLoader(true);

        let selectedCounters = filterCounters();
        let isValidated = validateData(selectedCounters);

        if (isValidated) {

            axios({
                method: "POST",
                url: `${baseUrl}/main-display/add`,
                headers: {
                    Authorization: token
                },
                data: {
                    branch: selectedBranch.id,
                    name: name,
                    type: type,
                    macAddress: macAddress,
                    sound: isSound,
                    counters: selectedCounters
                }

            }).then((res) => {

                setButtonLoader(false);
                toast.remove();
                toast.success('Main display successfully added.', {
                    position: 'bottom-center',
                });

                getAllMainDisplays();
                closeModal();

            }).catch((error) => {
                console.log(error);
                setButtonLoader(false);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }
            })
        }
    }

    // get main display by id
    const getMainDisplayById = () => {
        axios({
            method: 'GET',
            url: `${baseUrl}/main-display/get/${selectedMDId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let data = res.data.data;
            console.log('getMainDisplayById ', data);
            setMainDisplay(data);

            let allCounterTemp = allCounters;
            for (let i = 0; i < allCounterTemp.length; i++) {
                for (let j = 0; j < data.allCounters.length; j++) {

                    if (allCounterTemp[i].id === data.allCounters[j].counterId) {
                        allCounterTemp[i].selected = true;
                        allCounterTemp[i].documentCount = data.allCounters[j].documentCount;
                    }

                }
            }
            setAllCounters(allCounterTemp);

            setName(data.name);
            setType(data.type);
            setMacAddress(data.macAddress);
            setIsSound(data.sound);


        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // get counters by branch id
    // const getCountersByMDId = (displayId) => {

    //     axios({
    //         method: "GET",
    //         url: `${baseUrl}/main-display/counters-list/main-display/${displayId}`,
    //         headers: {
    //             Authorization: token
    //         }
    //     }).then((res) => {
    //         console.log('getAllUsers success');

    //         let data = res.data.data;
    //         console.log(data);
    //         setAssignCounters(data);

    //     }).catch((error) => {
    //         console.log(error);

    //         if (error.response !== undefined) {
    //             toast.remove();
    //             toast.error(error.response.data.message, {
    //                 position: 'bottom-center',
    //             });
    //         } else {
    //             toast.remove();
    //             toast.error('Something went wrong', {
    //                 position: 'bottom-center',
    //             });
    //         }
    //     })
    // }

    // update main display
    const updateMainDisplay = () => {
        setButtonLoader(true);

        let selectedCounters = filterCounters();
        let isValidated = validateData(selectedCounters);

        if (isValidated) {
            axios({
                method: 'POST',
                url: `${baseUrl}/main-display/update`,
                headers: {
                    "Authorization": token
                },
                data: {
                    id: mainDisplay.id,
                    branch: selectedBranch.id,
                    name: name,
                    type: type,
                    macAddress: macAddress,
                    sound: isSound,
                    counters: selectedCounters
                }

            }).then((res) => {
                console.log(res.data.data);
                setButtonLoader(false);

                toast.remove();
                toast.success('Successfully updated', {
                    position: 'bottom-center',
                });

                getAllMainDisplays();
                closeModal();

            }).catch((error) => {
                console.log(error);
                setButtonLoader(false);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }
            })
        }
    }


    // filter selected counters
    const filterCounters = () => {
        let selectedCounters = [];
        for (let i = 0; i < allCounters.length; i++) {
            if (allCounters[i].selected === true) {
                selectedCounters.push({
                    counterId: allCounters[i].id,
                    image: allCounters[i].documentCount,
                })
            }
        }
        return selectedCounters;
    };

    // filter selected counters
    const filterCountersForDirections = () => {
        return allCounters.filter(counter => counter.selected === true);
    };

    // request data validation
    const validateData = (selectedCounters) => {
        if (name.length === 0) {
            toast.error('Display name empty', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            return false;
        } else if (type.length === 0) {
            toast.error('Display type empty', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            return false;
        } else if (macAddress.length === 0) {
            toast.error('Display mac address empty', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            return false;
        } else {
            return true;
        }
    }

    // tab handling methods of modal
    const handleTabNext = () => {
        if (tab === '1') {
            setNextTab("2");
            setTab("2");
        }
    }

    const handleTabBack = () => {
        if (tab === '2') {
            setNextTab("1");
            setTab("1");
        }
    }

    const setSelectedX = (img, counterId) => {
        // let counter = allCounters.find(c => c.counterId === counterId);
        // if (counter !== undefined && counter !== null) {
        //     counter.documentCount = img;
        // }
        for (let i = 0; i < allCounters.length; i++) {
            if (allCounters[i].id === counterId) {
                allCounters[i].documentCount = img;
                setAllCounters(allCounters);
                setDate(new Date().toString());
                break;
            }
        }
    }

    // close the add modal
    const closeModal = () => {
        setName('');
        setType('');
        setMacAddress('');
        setIsSound(false);

        for (let i = 0; i < allCounters.length; i++) {
            allCounters[i].selected = false;
        }

        if (modalType === 1 || modalType === 2) {
            setSelectedMDId('');
            setMainDisplay({});
        }

        setTab("1");
        setShowAddMDModal(false);

    }


    return (
        <div>
            <div><Toaster/></div>
            {showAddMDModal ? (
                <div className={`modal fade d-block show modal-container`} id="counter-modal">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    {modalType === 0 ? <>Add Main Display</> : modalType === 1 ? <>Edit Main
                                        Display</> : <>View Main Display</>}
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                        onClick={() => closeModal()}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <Tabs id="uncontrolled-tab-example"
                                      activeKey={tab}
                                      onSelect={(key) => {
                                          setTab(key)
                                          setNextTab(key)
                                      }}
                                >
                                    <Tab eventKey="1" title="Details" tabClassName="custom_tab">

                                        <form className='counter-add-form mt-3'>

                                            <div className="input-group input-group-sm mb-3 modal-input-group">
                                                <p className="input-field-side-text">Display Name : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                       aria-describedby="inputGroup-sizing-sm"
                                                       value={name}
                                                       readOnly={modalType === 2 ? true : false}
                                                       onChange={(e) => {
                                                           setName(e.target.value);

                                                       }}
                                                />
                                            </div>

                                            <div className="input-group input-group-sm mb-3 modal-input-group">
                                                <p className="input-field-side-text">Type : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                       aria-describedby="inputGroup-sizing-sm"
                                                       value={type}
                                                       readOnly={modalType === 2 ? true : false}
                                                       onChange={(e) => {
                                                           setType(e.target.value);

                                                       }}

                                                />
                                            </div>

                                            <div className="input-group input-group-sm mb-3 modal-input-group">
                                                <p className="input-field-side-text">Mac Address : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                       aria-describedby="inputGroup-sizing-sm"
                                                       value={macAddress}
                                                       readOnly={modalType === 2 ? true : false}
                                                       onChange={(e) => {
                                                           setMacAddress(e.target.value);

                                                       }}

                                                />
                                            </div>

                                            <div className="input-group input-group-sm mb-3">
                                                <p className="input-field-side-text">Sound : </p>

                                                <label
                                                    className="switch custom-swtch">
                                                    <input type="checkbox"
                                                           checked={isSound}
                                                           disabled={modalType === 2 ? true : false}
                                                           onChange={(e) => {
                                                               setIsSound(e.target.checked);
                                                           }}/>
                                                    <span className="slider"></span>
                                                </label>
                                            </div>

                                        </form>
                                    </Tab>

                                    <Tab eventKey="2" title="Counters" tabClassName="custom_tab">
                                        <div className='add-counters-list'>
                                            <div className="container-fluid">
                                                <div className="row clearfix">
                                                    {allCounters.length > 0 ?
                                                        <>
                                                            {allCounters.map((data) =>

                                                                <div className="col-md-4 col-sm-12" key={data.id}>
                                                                    <div
                                                                        className={`counter ${data.selected ? 'reminder' : ''}`}
                                                                        onClick={(e) => {
                                                                            if (modalType === 0 || modalType === 1) {
                                                                                data.selected = !data.selected;
                                                                                setRefresh(refresh + 1)
                                                                            }
                                                                        }}
                                                                    >
                                                                        <h7>{data.counterNumber}</h7>
                                                                        <h6>{data.counterName}</h6>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                        :
                                                        <div className="col-md-12">
                                                            <div className="alert alert-custom alert-dismissible"
                                                                 role="alert">
                                                                <i className="fa fa-info-circle"></i> Empty records
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>

                                    <Tab eventKey="3" title="Directions" tabClassName="custom_tab">
                                        {/*<div className='add-counters-list'>*/}
                                        {/*<div className="container-fluid">*/}
                                        {/*<div className=" ">*/}
                                        {date && filterCountersForDirections().length > 0 ?
                                            <table className="table table-hover table-responsive-lg table-responsive">
                                                {/*<header>*/}
                                                {/*<td>Counter number</td>*/}
                                                {/*<td>Name</td>*/}
                                                {/*<td>Direction</td>*/}
                                                {/*<td>Action</td>*/}
                                                {/*</header>*/}
                                                <body>
                                                {filterCountersForDirections().map((data) =>
                                                    <tr key={new Date().toString() + data.id}>
                                                        {/*<td>{data.counterNumber}</td>*/}
                                                        <td>{data.counterName}</td>
                                                        <td>
                                                            <img
                                                                width={40}
                                                                src="../../assets/images/direction/continue_straight.jpg"
                                                                className={`rounded ${data.documentCount === '1' ? "active-xx" : ''}`}
                                                                onClick={() => {
                                                                    setSelectedX('1', data.id);
                                                                }}
                                                            />
                                                            &nbsp;
                                                            <img
                                                                width={40}
                                                                src="../../assets/images/direction/continue_straight-1.jpg"
                                                                className={`rounded ${data.documentCount === '2' ? "active-xx" : ''}`}
                                                                onClick={() => {
                                                                    setSelectedX('2', data.id);
                                                                }}/>
                                                            &nbsp;
                                                            <img
                                                                width={40}
                                                                src="../../assets/images/direction/continue_straight-2.jpg"
                                                                className={`rounded ${data.documentCount === '3' ? "active-xx" : ''}`}
                                                                onClick={() => {
                                                                    setSelectedX('3', data.id);
                                                                }}/>
                                                            &nbsp;
                                                            <img
                                                                width={40}
                                                                src="../../assets/images/direction/continue_straight-3.jpg"
                                                                className={`rounded ${data.documentCount === '4' ? "active-xx" : ''}`}
                                                                onClick={() => {
                                                                    setSelectedX('4', data.id);
                                                                }}/>
                                                            &nbsp;
                                                            <img
                                                                width={40}
                                                                src="../../assets/images/direction/slight.jpg"
                                                                className={`rounded ${data.documentCount === '5' ? "active-xx" : ''}`}
                                                                onClick={() => {
                                                                    setSelectedX('5', data.id);
                                                                }}/>
                                                            &nbsp;
                                                            <img
                                                                width={40}
                                                                src="../../assets/images/direction/slight1.jpg"
                                                                className={`rounded ${data.documentCount === '6' ? "active-xx" : ''}`}
                                                                onClick={() => {
                                                                    setSelectedX('6', data.id);
                                                                }}/>
                                                            &nbsp;
                                                            <img
                                                                width={40}
                                                                src="../../assets/images/direction/slight2.jpg"
                                                                className={`rounded ${data.documentCount === '7' ? "active-xx" : ''}`}
                                                                onClick={() => {
                                                                    setSelectedX('7', data.id);
                                                                }}/>
                                                            &nbsp;
                                                            <img
                                                                width={40}
                                                                src="../../assets/images/direction/slight3.jpg"
                                                                className={`rounded ${data.documentCount === '8' ? "active-xx" : ''}`}
                                                                onClick={() => {
                                                                    setSelectedX('8', data.id);
                                                                }}/>
                                                            &nbsp;
                                                            <img
                                                                width={40} src="../../assets/images/direction/turn.jpg"
                                                                className={`rounded ${data.documentCount === '9' ? "active-xx" : ''}`}
                                                                onClick={() => {
                                                                    setSelectedX('9', data.id);
                                                                }}/>
                                                            &nbsp;
                                                            <img
                                                                width={40} src="../../assets/images/direction/turn1.jpg"
                                                                className={`rounded ${data.documentCount === '10' ? "active-xx" : ''}`}
                                                                onClick={() => {
                                                                    setSelectedX('10', data.id);
                                                                }}/>
                                                            &nbsp;
                                                            <img
                                                                width={40} src="../../assets/images/direction/turn2.jpg"
                                                                className={`rounded ${data.documentCount === '11' ? "active-xx" : ''}`}
                                                                onClick={() => {
                                                                    setSelectedX('11', data.id);
                                                                }}/>
                                                            &nbsp;
                                                            <img
                                                                width={40} src="../../assets/images/direction/turn3.jpg"
                                                                className={`rounded ${data.documentCount === '12' ? "active-xx" : ''}`}
                                                                onClick={() => {
                                                                    setSelectedX('12', data.id);
                                                                }}/>
                                                        </td>
                                                    </tr>
                                                )}
                                                </body>
                                            </table>
                                            :
                                            <div className="col-md-12">
                                                <div className="alert alert-custom alert-dismissible" role="alert">
                                                    <i className="fa fa-info-circle"></i> Empty records
                                                </div>
                                            </div>
                                        }
                                        {/*</div>*/}
                                        {/*</div>*/}
                                        {/*</div>*/}
                                    </Tab>
                                </Tabs>
                            </div>
                            <div className="modal-footer">

                                {tab === "2" ? (
                                    <button type="button" className="btn btn-primary"
                                            onClick={() => {
                                                handleTabBack();
                                            }}
                                    ><i className="fa fa-chevron-left" aria-hidden="true"></i></button>
                                ) : null
                                }

                                {modalType === 0 ? (
                                    <>
                                        {tab === "1" ? (
                                            <button type="button" className="btn btn-primary"
                                                    onClick={() => {
                                                        handleTabNext();
                                                    }}
                                            ><i className="fa fa-chevron-right" aria-hidden="true"></i></button>
                                        ) : (

                                            <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                                    onClick={() => {
                                                        addMainDisplay();
                                                    }}
                                            >Save</Button>
                                        )
                                        }
                                    </>
                                ) : modalType === 1 ? (
                                        <>
                                            {tab === "1" ? (
                                                <button type="button" className="btn btn-primary"
                                                        onClick={() => {
                                                            handleTabNext();
                                                        }}
                                                ><i className="fa fa-chevron-right" aria-hidden="true"></i></button>
                                            ) : (

                                                <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                                        onClick={() => {
                                                            updateMainDisplay();
                                                        }}
                                                >Update</Button>
                                            )
                                            }</>)
                                    : (
                                        <>
                                            {tab === "1" ? (
                                                <button type="button" className="btn btn-primary"
                                                        onClick={() => {
                                                            handleTabNext();
                                                        }}
                                                ><i className="fa fa-chevron-right" aria-hidden="true"></i></button>
                                            ) : null
                                            }</>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            ) : null
            }
        </div>
    )
}

export default MainDisplayAddModal