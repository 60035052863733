import React, { Component } from 'react';
import Chart from "react-apexcharts";

class CategoryAnalyticsBarchart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    type: 'bar',
                    height: 280,
                    stacked: true,
                    toolbar: {
                        show: false
                    }
                },
                grid: {
                    show: false
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        width: 10,
                    },
                },
                fill: {
                    colors: ['#9367B4', '#17C2D7',]
                },
                yaxis: {
                    show: false
                },
                xaxis: {
                    axisTicks: { show: false },
                    axisBorder: { show: false },
                    categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
                },
                legend: {
                    show: true
                }
            },
            series: [
                {
                    name: "Tickets",
                    data: [110, 81, 158, 108, 149, 217, 0]
                },
                {
                    name: "Exceptions",
                    data: [70, 78, 75, 97, 120, 152, 0]
                }
            ]
        };
    }
    render() {
        return (
            <div>
                <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="bar"
                    // width="100%"
                    height="280px"
                />
            </div>
        );
    }
}

export default CategoryAnalyticsBarchart;
