import React, { useEffect, useState } from 'react'
import 'rc-time-picker/assets/index.css';
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';
import { useNavigate } from 'react-router-dom';

// used for add
const AddKiosksTemplateModal = ({ showAddKiosksTemplateModal, setShowAddKiosksTemplateModal, modalType, selectedTemplateId }) => {

    const history = useNavigate();

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const selectedBranch = JSON.parse(localStorage.getItem('selected_branch'));

    const [buttonLoader, setButtonLoader] = useState(false);

    const [ratioList, setRatioList] = useState([]);
    const [showLangPage, setShowLangPage] = useState(false);

    const [designName, setDesignName] = useState('');
    const [selectedRatio, setSelectedRatio] = useState('');
    const [isLandscape, setIsLandscape] = useState();



    // call getAllRatioList
    useEffect(() => {
        if (showAddKiosksTemplateModal) {
            if (baseUrl.length > 0 && token.length > 0) {
                getAllRatioList();
            }
        }
    }, [showAddKiosksTemplateModal])

    // if view modal, call get by id - useEffect
    useEffect(() => {
        if (showAddKiosksTemplateModal) {

            if (modalType === 1) {
                getKioskTemplateById();
            }
        }
    }, [showAddKiosksTemplateModal])



    // getAllLanguages - function
    const getAllRatioList = () => {
        console.log('getAllRatioList function start')

        axios({
            method: 'GET',
            url: `${baseUrl}/kiosk/ratio/get-all`,
            headers: {
                Authorization: token
            },
        }).then((res) => {

            console.log('getAllRatioList success');
            let data = res.data.data.list
            console.log(data);
            setRatioList(data);

        }).catch((error) => {
            console.log('getAllRatioList error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // addKiosksDesign - function
    const addKiosksDesign = () => {
        console.log('addKiosksDesign function start');

        // console.log('name', designName);
        // console.log('ration id ', selectedRatio);
        // console.log('landscape ', isLandscape);
        // console.log('branch ', selectedBranch.id);

        let isValidate = validate();

        if (isValidate) {

            axios({
                method: 'POST',
                url: `${baseUrl}/kiosk/kiosk-design/add`,
                headers: {
                    Authorization: token
                },
                data: {
                    name: designName,
                    ratio: {
                        id: selectedRatio
                    },
                    landscape: isLandscape,
                    firstPageLanguage: showLangPage,
                    branchId: selectedBranch.id
                }
            }).then((res) => {

                console.log('addKiosksDesign success');
                let data = res.data.data
                console.log(data);

                history(`/design-kiosks-template?t_id=${data.id}`)

            }).catch((error) => {
                console.log('addKiosksDesign error');
                console.log(error);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }
            })

        }
    }

    // get kiosk template by id - function
    const getKioskTemplateById = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/kiosk/kiosk-design/get/${selectedTemplateId}`,
            headers: {
                Authorization: token
            },
        }).then((res) => {

            let data = res.data.data
            console.log(data);
            setDesignName(data.name);
            setSelectedRatio(data.ratioId);
            setIsLandscape(data.landscape);
            setShowLangPage(data.firstPageLanguage)

        }).catch((error) => {

            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // update kiosk template info - function
    const updateKioskTemplateInfo = () => {
        axios({
            method: 'POST',
            url: `${baseUrl}/kiosk/update/first-page-language/kiosk-design/${selectedTemplateId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            toast.remove();
            toast.success('Successfully updated', {
                position: 'bottom-center',
            });
            closeModal();

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // validate - function
    const validate = () => {
        if (designName.length === 0) {
            toast.remove();
            toast.error('Name cannot be empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (selectedRatio.length === 0) {
            toast.remove();
            toast.error('Please select a ratio', {
                position: 'bottom-center',
            });
            return false;
        } else if (isLandscape === undefined) {
            toast.remove();
            toast.error('Please select orientation type', {
                position: 'bottom-center',
            });
            return false;
        } else {
            return true;
        }
    }

    //closeModal - function
    const closeModal = () => {
        setDesignName('');
        setSelectedRatio('');
        setIsLandscape(undefined);
        setShowAddKiosksTemplateModal(false);
        setShowLangPage(false);
    }


    return (
        <>
            <div><Toaster /></div>
            {showAddKiosksTemplateModal ? (
                <div className={`modal fade d-block show modal-container`} id="category-modal">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    {
                                        modalType === 0 ? <>Add New Kiosks Template</> : <>View Kiosks Template</>
                                    }

                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        closeModal()
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <form className='mt-3'>
                                    <div className="container-fluid mb-3">

                                        <div className="row clearfix">
                                            <div className="col-md-12 col-sm-12 mb-2">
                                                <div className="input-group input-group-sm mb-3">

                                                    <p className="input-field-side-text mr-3 kiosksdesign-label">Name : </p>
                                                    <input type="text" className="form-control" aria-label="Small" style={{ height: "33px" }}
                                                        value={designName}
                                                        disabled={modalType === 1}
                                                        onChange={(e) => {
                                                            setDesignName(e.target.value);
                                                        }}
                                                    >

                                                    </input>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="row clearfix">
                                            <div className="col-md-12 col-sm-12 mb-2">
                                                <div className='input-group input-group-sm mb-3 '>
                                                    <p className="input-field-side-text mr-3 kiosksdesign-label">Ratio : </p>
                                                    <select className="custom-select modal-select-field" id="inputGroupSelect01"
                                                        value={selectedRatio}
                                                        disabled={modalType === 1}
                                                        onChange={(e) => {
                                                            // console.log(e.target.value);
                                                            setSelectedRatio(e.target.value);
                                                        }}
                                                    >
                                                        <option defaultValue hidden> -select ratio-</option>

                                                        {
                                                            ratioList.map((ratio, i) => (
                                                                <option value={ratio.id} key={i}>{ratio.ratio}</option>
                                                            ))
                                                        }
                                                    </select>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="row clearfix">
                                            <div className="col-md-12 col-sm-12 mb-2">
                                                <div className="input-group input-group-sm mb-3">
                                                    <p className=" mr-3 kiosksdesign-label">Language: </p>
                                                    <div>
                                                        <label
                                                            className="switch custom-swtch">
                                                            <input type="checkbox"
                                                                checked={showLangPage ? true : false}
                                                                // disabled={modalType === 1}
                                                                onChange={(e) => {
                                                                    setShowLangPage(prev => !prev);
                                                                }} />
                                                            <span className="slider"></span>
                                                        </label>
                                                        <small className="function">Toggle on to skip language selection</small>
                                                    </div>

                                                </div>


                                            </div>

                                        </div>


                                        <div className="row clearfix">
                                            <div className="col-md-12 col-sm-12 mb-2">
                                                <div className="input-group input-group-sm mb-3">
                                                    <p className="input-field-side-text mr-3 kiosksdesign-label">Orientation: </p>
                                                </div>
                                                <div className={`orient ${modalType === 1 ? 'disable' : ''}`}>

                                                    <div className={`orient-box-vertical ${isLandscape === false ? `selected` : ``}`}
                                                        onClick={() => {
                                                            if (modalType === 0) {
                                                                setIsLandscape(false);
                                                            }
                                                        }}

                                                    ></div>
                                                    <div className={`orient-box-horizontal ${isLandscape === true ? `selected` : ``}`}
                                                        onClick={() => {
                                                            if (modalType === 0) {
                                                                setIsLandscape(true);
                                                            }
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                </form>
                            </div>
                            <div className='modal-footer'>
                                <button type="button" className="btn btn-default"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >Cancel
                                </button>
                                {
                                    modalType === 0 ? (
                                        <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                            onClick={() => {
                                                addKiosksDesign();
                                            }}
                                        >Create
                                        </Button>
                                    ) : (
                                        <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                            onClick={() => {
                                                updateKioskTemplateInfo();
                                            }}
                                        >Update
                                        </Button>
                                    )
                                }


                            </div>
                        </div>
                    </div>
                </div>
            ) : null
            }
        </>
    )
}

export default AddKiosksTemplateModal;