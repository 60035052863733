import React, { useEffect, useState } from 'react';

import toast from "react-hot-toast";
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from "@mui/material/CircularProgress";
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const CounterStatusDetails = (props) => {
    const [open, setOpen] = React.useState(false);

    const [loading1, setLoading1] = useState(false);
    const [selectedItem, setSelectedItem] = React.useState({});

    const [update, setUpdate] = useState('1s')

    const handleClickOpen = (item) => {
        setSelectedItem(item);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    // const [donutChartColors, setdonutChartColors] = useState(['Counter 1', 'Counter 2', 'Counter 3', 'Counter 4']);
    // const [listType, setListType] = useState('all');

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);

    const [counterData, setCounterData] = useState([]);

    useEffect(() => {
        if (props.showCounterStatusDetails === false) return;
        if (props.counterStatusInc > 0 || props.messageInc1001 !== '' || props.messageInc1002 !== ''
            || props.messageInc9999 !== '' || props.messageInc9998 !== '') {
            getBranchSummaryOperatorStatus();
        }
    }, [props.counterStatusInc, props.messageInc9999, props.messageInc9998, props.messageInc1001, props.messageInc1002]);


    const logout = () => {
        setLoading1(true);
        axios({
            method: 'POST',
            url: `${baseUrl}/operator-login/force-logout/user/${selectedItem.userId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            setLoading1(false);
            handleClose();
        }).catch((error) => {
            setLoading1(false);
            console.log(error);
            handleClose();
            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }
    // MUI table options
    const options = {
        tableBodyHeight: '53vh',
        filter: false,
        responsive: 'vertical',
        serverSide: false,
        count: count,
        download: false,
        viewColumns: false,
        jumpToPage: true,
        selectableRows: 'none',
        fixedHeader: true,
        print: false,
        rowsPerPage: rowsPerPage,
        onChangeRowsPerPage: (n) => {
            setRowsPerPage(n)
        },
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        }
    };


    // MUI table columns
    const columns = [
        {
            label: 'Counter No',
            name: 'counterNo',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Employee ID",
            name: 'employeeId',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Name',
            name: 'name',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Operator Status',
            name: 'id',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    if (counterData.length === 0) return;
                    let item = counterData.find(item => item.id === value);
                    return (<>
                        {item.status === 0 ? (
                            <p className='mb-0'>Idle</p>
                        ) : item.status === 1 ? (
                            <p className='mb-0'>Serving</p>
                        ) : item.status === 1 ? (
                            <p className='mb-0'>Serving</p>
                        ) :
                            <p className='mb-0'>{item.lastAux !== undefined && item.lastAux !== null ? item.lastAux : 'AUX'}</p>
                        }
                    </>);
                }
            }
        },
        {
            label: 'Now serving',
            name: 'nowServing',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            label: 'Serving Time',
            name: 'totalServingTime',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            label: 'Tickets served',
            name: 'ticketsServed',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            label: 'Total time log',
            name: 'totalTime',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            label: 'Time in serving',
            name: 'totalServiceTime',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            label: 'Login hits',
            name: 'loginHits',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            label: 'Time in idle',
            name: 'idleTime',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            label: 'Force Logout',
            name: 'id',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    if (counterData.length === 0) return;
                    let item = counterData.find(item => item.id === value);
                    return (<div style={{ cursor: 'pointer' }} onClick={() => handleClickOpen(item)}>
                        <i className="fa fa-sign-out" aria-hidden="true" />
                    </div>);
                }
            }
        }
    ];

    const columns_1 = [
        {
            label: "Branch",
            name: 'branchName',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Counter No',
            name: 'counterNo',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Employee ID",
            name: 'employeeId',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Name',
            name: 'name',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Operator Status',
            name: 'id',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    if (counterData.length === 0) return;
                    let item = counterData.find(item => item.id === value);
                    return (<>
                        {item.status === 0 ? (
                            <p className='mb-0'>Idle</p>
                        ) : item.status === 1 ? (
                            <p className='mb-0'>Serving</p>
                        ) : item.status === 1 ? (
                            <p className='mb-0'>Serving</p>
                        ) :
                            <p className='mb-0'>{item.lastAux !== undefined && item.lastAux !== null ? item.lastAux : 'AUX'}</p>
                        }
                    </>);
                }
            }
        },
        {
            label: 'Now serving',
            name: 'nowServing',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            label: 'Serving Time',
            name: 'totalServingTime',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            label: 'Tickets served',
            name: 'ticketsServed',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            label: 'Total time log',
            name: 'totalTime',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            label: 'Time in serving',
            name: 'totalServiceTime',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            label: 'Login hits',
            name: 'loginHits',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            label: 'Time in idle',
            name: 'idleTime',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            label: 'Force Logout',
            name: 'id',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    if (counterData.length === 0) return;
                    let item = counterData.find(item => item.id === value);
                    return (<div style={{ cursor: 'pointer' }} onClick={() => handleClickOpen(item)}>
                        <i className="fa fa-sign-out" aria-hidden="true" />
                    </div>);
                }
            }
        }
    ];


    // start up - useeffect
    useEffect(() => {
        getBranchSummaryOperatorStatus();
    }, [CounterStatusDetails]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (counterData !== null && counterData.length > 0) {
                for (let index = 0; index < counterData.length; index++) {
                    const element = counterData[index];
                    if (element.totalServingTimeSecs != null) {
                        element.totalServingTimeSecs = element.totalServingTimeSecs + 5;

                        let hours = Math.floor(element.totalServingTimeSecs / 3600);
                        let minutes = Math.floor((element.totalServingTimeSecs - (hours * 3600)) / 60);
                        let seconds = element.totalServingTimeSecs - (hours * 3600) - (minutes * 60);

                        if (hours < 10) { hours = "0" + hours; }
                        if (minutes < 10) { minutes = "0" + minutes; }
                        if (seconds < 10) { seconds = "0" + seconds; }

                        element.totalServingTime = `${hours}:${minutes}:${seconds}`;
                    }
                }

                console.log("token data: ", counterData);

                setCounterData(counterData);
                setUpdate(new Date().toString());
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [counterData]);

    // get branch operator status - function
    const getBranchSummaryOperatorStatus = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/get-branch-summary/operators-data/branch/${props.selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            let data = res.data.data;
            console.log('XXX >>>>>> ', data);
            setCounterData(data);
            setCount(data.length);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });

            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    };


    // close modal - function
    const closeModal = () => {
        setCounterData([]);
        props.setSticky(true)
        props.setShowCounterStatusDetails(false);
    }

    return (
        <>
            {props.showCounterStatusDetails ?
                <div id="megamenu" className={`megamenu particles_js details-page open counter-status-mega`}>
                    <span
                        onClick={() => {
                            closeModal();

                        }}
                        className="megamenu_toggle btn btn-danger"><i className="icon-close"></i></span>
                    <div className="container">
                        <div className="row clearfix">
                            <div className="col-12">
                                <div className="card">
                                    <div className="body">
                                        <div style={{ textAlign: 'center' }}>
                                            <h4>Counter Status</h4>
                                            <small>{props.date}</small>
                                            {/* <small>xxxx</small> */}
                                        </div>
                                        <br />
                                        <div className="row clearfix">

                                            <div className="col-12">
                                                <div className="muidatatablecontainer">
                                                    {props.selectedBranchLevel === 4 ? (
                                                        <MUIDataTable
                                                            title={<>
                                                                {count}{count === 1 ? ' record' : ' records'}.
                                                            </>
                                                            }
                                                            data={counterData}
                                                            columns={columns}
                                                            options={options}
                                                        />
                                                    ) : (
                                                        <MUIDataTable
                                                            title={<>
                                                                {count}{count === 1 ? ' record' : ' records'}.
                                                            </>
                                                            }
                                                            data={counterData}
                                                            columns={columns_1}
                                                            options={options}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div id="particles-js"></div>
                </div>
                : null
            }

            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{`Are you sure you want to force logout ${selectedItem.name}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        You are about to end the login session of <b>{selectedItem.name}</b> logged in
                        counter <b>{selectedItem.counterNo}</b>.
                        <br />System will notify the agent if the client is connected to network.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className={"secondary"} onClick={handleClose}>Cancel</Button>


                    {loading1 ?
                        <>&nbsp;&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                            size={20} />
                            &nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                        : <Button onClick={logout}>Continue</Button>
                    }

                </DialogActions>
            </Dialog>
        </>
    );
}

export default CounterStatusDetails;