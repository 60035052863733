// import React from 'react';
// import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import configureStore from './store';
import './index.css';
// import App from './App';
import * as serviceWorker from './serviceWorker';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import registerServiceWorker from './registerServiceWorker';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={configureStore()}>
        <App/>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
//
// ReactDOM.render(
//     <Provider store={configureStore()}>
//         <FiberProvider store={configureStore()}>
//             <App />
//         </FiberProvider>, <App />
//      </Provider>,
//     document.getElementById('root')
// );
// // registerServiceWorker();
// // ReactDOM.render(<App />, document.getElementById('root'));
//
// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
