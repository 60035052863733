import axios from "axios";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import Button from 'react-bootstrap-button-loader';


const AssignUserRoleModal = ({ showAssignUserRoleModal, setShowAssignUserRoleModal, selectedUser, refreshParentPage }) => {

    const [baseUrl, setBaseUrl] = useState('');
    const [token, setToken] = useState('');
    const [userDetails, setUserDetails] = useState({});
    const [buttonLoader, setButtonLoader] = useState(false);

    const [allRoles, setAllRoles] = useState([]);
    const [selectedUserRole, setSelectedUserRole] = useState('');


    // get base url - useEffect
    useEffect(() => {
        setBaseUrl(localStorage.getItem('API_URL'));
        setToken(localStorage.getItem('user_token'));
        setUserDetails(JSON.parse(localStorage.getItem('user_details')));
    }, [AssignUserRoleModal])

    // call getAllRoles - useEffect
    useEffect(() => {
        if (baseUrl.length > 0 && token.length > 0 && Object.keys(userDetails).length > 0) {
            getAllRoles();
        }
    }, [showAssignUserRoleModal])

    // get current role id - useEffect
    useEffect(() => {
        if (selectedUser != null) {
            setSelectedUserRole(selectedUser.roleId);
        }
    }, [showAssignUserRoleModal])


    // getAllRoles
    const getAllRoles = () => {
        console.log('getAllRoles function start');

        axios({
            method: 'GET',
            url: `${baseUrl}/user-role/get-all-roles/${userDetails.cid}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('getAllRoles success');
            let data = res.data.data;
            console.log(data);
            setAllRoles(data);

        }).catch((error) => {
            console.log('getAllRoles error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // assignUserRole
    const assignUserRole = () => {
        console.log('assignUserRole function start');

        setButtonLoader(true);

        let isValidate = validate();
        if (isValidate) {
            axios({
                method: 'POST',
                url: `${baseUrl}/user/assign-role/user/${selectedUser.id}/role/${selectedUserRole}`,
                headers: {
                    Authorization: token
                }
            }).then((res) => {

                console.log('assignUserRole success');
                setButtonLoader(false);
                toast.remove();
                toast.success('User role successfully assigned', {
                    position: 'bottom-center',
                });
                setShowAssignUserRoleModal(false);
                refreshParentPage();

            }).catch((error) => {
                console.log('assignUserRole error');
                console.log(error);
                setButtonLoader(false);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }
            })

        }
    }

    // validate
    const validate = () => {
        console.log('validate function start');

        if (selectedUserRole.length <= 0) {
            toast.error('Please select user role', {
                position: 'bottom-center',
            });
            return false;
        } else if (selectedUser.roleId === parseInt(selectedUserRole)) {
            toast.error('Same role. No need to assign', {
                position: 'bottom-center',
            });
            return false;
        } else {
            return true;
        }
    }

    const closeModal = () => {
        setShowAssignUserRoleModal(false)
    }

    return (
        <>
            <div><Toaster /></div>
            {showAssignUserRoleModal ?
                <div className={`modal fade  d-block show modal-container`} id="exampleModal">
                    <div className="modal-dialog modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Assign User Role</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        closeModal()
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <div className="row clearfix">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="card">

                                            {allRoles !== null ?

                                                <>
                                                    {allRoles.length > 0 ?

                                                        allRoles.map((role, index) =>
                                                            <div className="fancy-radio mb-3" key={index}>
                                                                <label>
                                                                    <input name="gender" type="radio" value={role.id}
                                                                        defaultChecked={selectedUser.roleId === role.id}
                                                                        onChange={(e) => {
                                                                            setSelectedUserRole(e.target.value);
                                                                        }}
                                                                    />
                                                                    <span><i className="mr-2"></i>{role.role}</span>
                                                                </label>
                                                            </div>
                                                        )

                                                        :
                                                        <div class="alert alert-custom alert-dismissible" role="alert">
                                                            <i class="fa fa-info-circle"></i> Empty records
                                                        </div>
                                                    }
                                                </>
                                                :
                                                <div class="alert alert-custom alert-dismissible" role="alert">
                                                    <i class="fa fa-info-circle"></i> Empty records
                                                </div>
                                            }

                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal"
                                    onClick={() => {
                                        closeModal()
                                    }}
                                >Cancel
                                </button>
                                <Button type="button" className="btn btn-primary" onClick={() => {
                                    assignUserRole();
                                }}>Assign Role</Button>
                            </div>
                        </div>
                    </div>
                </div>
                : ''}
        </>
    )
}

export default AssignUserRoleModal