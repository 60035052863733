import React, { useState } from 'react'
import axios from 'axios';
import toast, { Toaster } from "react-hot-toast";

const AddKiosksScreenModal = ({ showAddScreenModal, setShowAddKiosksScreenModal, newScreenIndex, selectedLang, templateId, getAllScreensByTemplate }) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [screenName, setScreenName] = useState('');


    // addNewScreen - function
    const addNewScreen = () => {
        console.log('addNewScreen function start');

        axios({
            method: 'POST',
            url: `${baseUrl}/kiosk/screen/add/customer/${userDetails.cid}`,
            headers: {
                Authorization: token
            },
            data: {
                name: screenName,
                languageId: selectedLang,
                index: newScreenIndex,
                designId: templateId
            }
        }).then((res) => {

            console.log('addNewScreen success');
            let data = res.data.data;
            console.log(data);

            toast.remove();
            toast.success('Screen added', {
                position: 'bottom-center',
            });

            getAllScreensByTemplate(); // when added remove this
            closeModal();

        }).catch((error) => {
            console.log('addNewScreen error')
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })

    }

    // close modal
    const closeModal = () => {
        setShowAddKiosksScreenModal(false);
    }

    return (
        <>
            <div><Toaster /></div>
            {showAddScreenModal ?
                <div className={`modal fade bd-example-modal-sm d-block show`} >
                    <div className="modal-dialog modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Add New Screen</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        setShowAddKiosksScreenModal(false);
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="input-group input-group-sm mb-3 modal-input-group">
                                    <p className='input-field-side-text'>Name : </p>

                                    <input autoFocus type="text" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                        onChange={(e) => {
                                            setScreenName(e.target.value);
                                        }}
                                    />

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal"
                                    onClick={() => {
                                        setShowAddKiosksScreenModal(false);
                                    }}
                                >Close</button>
                                <button type="button" className="btn btn-primary"
                                    onClick={() => {
                                        addNewScreen();
                                    }}
                                >Add new</button>
                            </div>
                        </div>
                    </div>
                </div>
                : ''
            }
        </>
    )
}

export default AddKiosksScreenModal