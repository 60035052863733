import React, { useEffect, useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

export const AppointmentUserDetailsModal = ({
  showUserDetailsModal,
  setShowUserDetailsModal,
  isEditUser,
  data,
  refreshPage,
}) => {
  const closeModal = () => {
    setShowUserDetailsModal(false);
  };

  const [userData, setUserData] = useState(null);
  const baseUrl = localStorage.getItem("API_URL");
  const token = localStorage.getItem("user_token");

  useEffect(() => {
    setUserData("");
    if (showUserDetailsModal) {
      setUserData(data);
    }
  }, [data, showUserDetailsModal]);

  const updateUser = () => {
    console.log(userData);
    axios({
      method: "POST",
      url: `${baseUrl}/booking/update-user-data`,
      headers: {
        Authorization: token,
      },
      data: {
        mobile: userData?.mobile,
        firstName: userData?.firstName,
        lastName: userData.lastName,
        nic: userData?.nic,
        refNumber: userData?.refNumber,
        address: userData?.address,
        branchId: userData?.branchId,
      },
    })
      .then((res) => {
        const data = res.data.data;
        setShowUserDetailsModal(false);
        refreshPage();
      })
      .catch((error) => {
        console.log(error);

        if (error.response !== undefined) {
          toast.remove();
          toast.error(error.response.data.message, {
            position: "bottom-center",
          });
        } else {
          toast.remove();
          toast.error("Something went wrong", {
            position: "bottom-center",
          });
        }
      });
  };

  return (
    <>
      {showUserDetailsModal ? (
        <div className={`modal fade  d-block show modal-container`}>
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {isEditUser ? "Edit User" : "View User"}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>

              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group input-group-sm mb-3">
                      <label htmlFor="form_name">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={userData?.firstName}
                        disabled={!isEditUser}
                        maxLength={100}
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            firstName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group input-group-sm mb-3">
                      <label htmlFor="form_name">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={userData?.lastName}
                        disabled={!isEditUser}
                        maxLength={100}
                        onChange={(e) =>
                          setUserData({ ...userData, lastName: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group input-group-sm mb-3">
                      <label htmlFor="form_name">NIC Number</label>
                      <input
                        type="text"
                        className="form-control"
                        value={userData?.nic}
                        disabled={!isEditUser}
                        maxLength={12}
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            nic: e.target.value.replace(/[^0-9Vv]+/g, ""),
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group input-group-sm mb-3">
                      <label htmlFor="form_name">Ref. Number</label>
                      <input
                        type="text"
                        className="form-control"
                        value={userData?.refNumber}
                        disabled={!isEditUser}
                        maxLength={100}
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            refNumber: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group input-group-sm mb-3">
                      <label htmlFor="form_name">Address</label>
                      <input
                        type="text"
                        className="form-control"
                        value={userData?.address}
                        disabled={!isEditUser}
                        onChange={(e) =>
                          setUserData({ ...userData, address: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Cancel
                </button>
                {isEditUser ? (
                  <button
                    // loading={buttonLoader}
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      updateUser();
                    }}
                  >
                    Save Changes
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
