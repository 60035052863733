import React, { useEffect, useRef, useState } from 'react'
import { Donut } from 'react-dial-knob';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Accordion, Button, ProgressBar } from 'react-bootstrap';
import axios from 'axios';
import toast, { Toaster } from "react-hot-toast";
import { ShimmerThumbnail } from "react-shimmer-effects";
import TokenPerHourSparkChart from '../ChartsImpl/BranchDashboardCharts/TokenPerHourSparkChart';
import LineChart from '../ChartsImpl/BranchDashboardCharts/LineChart';

import TicketIssuedDetails from '../DetailsPages/BranchDashboard/TicketIssuedDetails';
import TicketServedDetails from '../DetailsPages/BranchDashboard/TicketServedDetails';
import NoShowTicketsDetails from '../DetailsPages/BranchDashboard/NoShowTicketsDetails';
import WaitTimeExceptionDetails from '../DetailsPages/BranchDashboard/WaitTimeExceptionDetails';
import ServiceTimeExceptionDetails from '../DetailsPages/BranchDashboard/ServiceTimeExceptionDetails';
import LocalWaitingDetails from '../DetailsPages/BranchDashboard/LocalWaitingDetails';
import WaitingTicketsDetails from '../DetailsPages/BranchDashboard/WaitingTicketsDetails';
import CounterStatusDetails from '../DetailsPages/BranchDashboard/CounterStatusDetails';
import TicketServingDetails from '../DetailsPages/BranchDashboard/TicketServingDetails';
import OperatorStatusDetails from '../DetailsPages/BranchDashboard/OperatorStatusDetails';
import BranchVIPTickets from '../DetailsPages/BranchDashboard/BranchVIPTickets';
import OperatorsAUXDetails from '../DetailsPages/BranchDashboard/OperatorsAUXDetails';
import LobbyDetails from '../DetailsPages/BranchDashboard/LobbyDetails';
import CountersCategoryDetails from '../DetailsPages/BranchDashboard/CountersCategoryDetails';
import TransferTokenData from '../DetailsPages/BranchDashboard/TransferTokenData';
import KioskDetails from '../DetailsPages/BranchDashboard/KioskDetails';
import MainDisplayDetails from '../DetailsPages/BranchDashboard/MainDisplayDetails';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

//mqtt conf
import Paho from 'paho-mqtt';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

let messageCount = 0;


const BranchDashboard = () => {
    let client = null;

    let configs = JSON.parse(localStorage.getItem("configuration_data"));
    let userDetails = JSON.parse(localStorage.getItem("user_details"));

    const location = useLocation();
    const history = useNavigate();

    const current = new Date();
    const date = `${current.getFullYear()} / ${current.getMonth() + 1} / ${current.getDate()}`;

    const [sticky, setSticky] = useState(false);

    const [showTicketIssuedDetails, setShowTicketIssuedDetails] = useState(false);
    const [showServedTicketsDetails, setShowServedTicketsDetails] = useState(false);
    const [showOperatorStatusDetails, setShowOperatorStatusDetails] = useState(false);
    const [showNoShowTicketsDetails, setShowNoShowTicketsDetails] = useState(false);
    const [showTicketServingDetails, setShowTicketServingDetails] = useState(false);
    const [showWaitTimeExceptionDetails, setShowWaitTimeExceptionDetails] = useState(false);
    const [showServiceTimeExDetails, setShowServiceTimeExDetails] = useState(false);
    const [showWaitingTicketsDetails, setShowWaitingTicketsDetails] = useState(false);
    const [showLocalWaitingDetails, setShowLocalWaitingDetails] = useState(false);
    const [showCounterStatusDetails, setShowCounterStatusDetails] = useState(false);
    const [showVIPTicketsDetails, setShowVIPTicketsDetails] = useState(false);
    const [showOperatorAuxDetails, setshowOperatorAuxDetails] = useState(false);
    const [showLobbyDetails, setShowLobbyDetails] = useState(false);
    const [showCountersCategoryDetails, setShowCountersCategoryDetails] = useState(false);
    const [showTransferTicketDetails, setShowTransferTicketDetails] = useState(false);
    const [showKioskDetails, setShowKioskDetails] = useState(false);
    const [showMainDisplayDetails, setShowMainDisplayDetails] = useState(false);

    // const [totalWaitingCount, setTotalWaitingCount] = useState(0)
    // const [waitingWithoutTransferCount, setWaitingWithoutTransferCount] = useState(0)
    // const [transferWaitingCount, settransferWaitingCount] = useState(0)

    const [operatorLoginInc, setOperatorLoginInc] = useState(0);
    const [noShowInc, setNoShowInc] = useState(0);
    const [waitingInc, setWaitingInc] = useState(0);
    const [watingExeptionInc, setWatingExeptionInc] = useState(0);
    const [serviceExceptionInc, setServiceExceptionInc] = useState(0);
    const [localWaitingInc, setLocalWaitingInc] = useState(0)
    const [servedTicketsInc, setServedTicketsInc] = useState(0);
    const [ticketIssuedInc, setTicketIssuedInc] = useState(0);
    const [vipTicketsInc, setVipTicketsInc] = useState(0);
    const [servingTicketsInc, setServingTicketsInc] = useState(0)
    const [counterStatusInc, setcounterStatusInc] = useState(0)
    const [kioskInc, setKioskInc] = useState(0)
    const [mainDisplayInc, setMainDisplayInc] = useState(0)
    const [auxInc, setauxInc] = useState(0)
    const [transferInc, setTransferInc] = useState(0)

    const [allDataLoaded, setAllDataLoaded] = useState(false);

    const [hourRangesBehave, setHourRangesBehave] = useState([]);
    const [ticketIssuedDetails, setTicketIssuedDetails] = useState({});
    const [ticketServedDetails, setTicketServedDetails] = useState({});
    const [ticketOperatorDetails, setTicketOperatorDetails] = useState({});
    const [ticketWaitingDetails, setTicketWaitingDetails] = useState({})
    const [ticketDetails, setTicketDetails] = useState({});
    const [mostServedTellerDetails, setMostServedTellerDetails] = useState({});
    const [ticketSummaryDetails, setTicketSummaryDetails] = useState({});
    const [categoryLoadDetails, setCategoryLoadDetails] = useState([]);
    const [categoryUsageList, setCategoryUsageList] = useState([]);
    const [branchSqeuenceData, setBranchSqeuenceData] = useState({});
    const [noSkillsTicket, setNoSkillsTicket] = useState([]);

    const [hourRanges, setHourRanges] = useState([]);
    const [hourlyTickets, setHourlyTickets] = useState([])

    //mqtt
    const [messageArrived, setMessageArrived] = useState(false)
    const [messageData, setMessageData] = useState('')
    const [messageIncLobby, setMessageIncLobby] = useState('')
    const [messageInc1000, setMessageInc1000] = useState('')
    const [messageInc1001, setMessageInc1001] = useState('')
    const [messageInc1002, setMessageInc1002] = useState('')
    const [messageInc1010, setMessageInc1010] = useState('')
    const [messageInc1011, setMessageInc1011] = useState('')
    const [messageInc2000, setMessageInc2000] = useState('')
    const [messageInc2001, setMessageInc2001] = useState('')
    const [messageInc3000, setMessageInc3000] = useState('')
    const [messageInc3001, setmessageInc3001] = useState('')
    const [messageInc9999, setMessageInc9999] = useState('')
    const [messageInc9998, setMessageInc9998] = useState('')
    const [messageInc4000, setMessageInc4000] = useState('')

    //ticket behavior chart
    const [chartValue, setChartValue] = useState(0);
    const refChartValue = useRef(0)

    //waiting tickets
    const [waitChartValue, setWaitChartValue] = useState(0);
    const [waitingAndTransfer, setWaitingAndTransfer] = useState([]);
    const [operatorsLoginChartValue, setOperatorsLoginChartValue] = useState(0)
    const refWaitingChartValue = useRef(0)

    const [chartMaxValue, setChartMaxValue] = useState([])
    const [hourlyIssuedTicketsCount, setHourlyIssuedTicketsCount] = useState([]);
    const [hourlyServedTicketsCount, setHourlyServedTicketsCount] = useState([]);
    const [hourlyLocalWaitingTicketsCount, setHourlyLocalWaitingTicketsCount] = useState([]);
    const [hourlyNoShowTicketsCount, setHourlyNoShowTicketsCount] = useState([]);
    const [hourlyWaitingExceptionTicketsCount, setHourlyWaitingExceptionTicketsCount] = useState([]);
    const [hourlyServiceExceptionTicketsCount, setHourlyServiceExceptionTicketsCount] = useState([]);

    const [isTokenPerHourProcessChartFullScreen, setTokenPerHourProcessChartFullScreen] = useState(false)
    const [isTokenIssueChartFullScreen, setTokenIssueChartFullScreen] = useState(false)

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    // const selectedBranchId = location.state.selectedBranchId;
    const [selectedCategoryName, setselectedCategoryName] = useState(null);
    const [selectedCategory, setselectedCategory] = useState(null);

    // const [selectedBranchId, setSelectedBranchId] = useState(location.state.selectedBranchId);
    // const [selectedBranchName, setSelectedBranchName] = useState(location.state.selectedBranchName);
    // const [selectedBranchLevel, setSelectedBranchLevel] = useState(location.state.selectedBranchLevel)
    // const [selectedBranchQueueType, setSelectedBranchQueueType] = useState(location.state.selectedBranchQueueType)

    const [selectedBranchId, setSelectedBranchId] = useState(userDetails['bid']);
    const [selectedBranchName, setSelectedBranchName] = useState(userDetails['branchName']);
    const [selectedBranchLevel, setSelectedBranchLevel] = useState(userDetails['branchLevel'])
    const [selectedBranchQueueType, setSelectedBranchQueueType] = useState(userDetails['queueTypeId'])
    const homeBranchName = userDetails['branchName'];
    // const selectedBranchName = location.state.selectedBranchName;

    const [branchQueueType, setBranchQueueType] = useState();
    const [country, setCountry] = useState({});
    const [selectedCountry, setselectedCountry] = useState(null)
    const [province, setprovince] = useState({});
    const [provinces, setProvinces] = useState([]);
    const [selectedProvince, setselectedProvince] = useState(null);
    const [district, setdistrict] = useState({});
    const [districts, setDistricts] = useState([]);
    const [selectedDistrict, setselectedDistrict] = useState(null);
    const [userBranch, setuserBranch] = useState({});
    const [branches, setBranches] = useState([]);
    const [selectedUserBranch, setSelectedUserBranch] = useState(null)

    // initial useEffect
    useEffect(() => {
        renderFunction();
        getQueueTypeByCustomer();
    }, [BranchDashboard])

    const renderFunction = () => {
        if (location.state !== null) {
            setSelectedBranchId(location.state.selectedBranchId);
            setSelectedBranchName(location.state.selectedBranchName);
            setSelectedBranchLevel(location.state.selectedBranchLevel);
            setSelectedBranchQueueType(location.state.selectedBranchQueueType);
        }
    }

    //navigateToBranchTables - function
    const navigateToBranchTables = (selectedBranchId, selectedBranchName, selectedBranchLevel, selectedBranchQueueType) => {
        history('/branch-tables-page', {
            state: {
                selectedBranchId: selectedBranchId,
                selectedBranchName: selectedBranchName,
                selectedBranchLevel: selectedBranchLevel,
                selectedBranchQueueType: selectedBranchQueueType
            }
        });
    }

    // mqtt config - useEffect
    useEffect(() => {

        // client = new Paho.Client('103.1.179.245', Number(9005), 'mqttjs_' + Math.random().toString(16).substr(2, 8));
        client = new Paho.Client(configs['ip'], Number(configs['port']), 'mqttjs_' + Math.random().toString(16).substr(2, 8));

        // set callback handlers (mqtt)
        client.onConnectionLost = onConnectionLost;
        client.onMessageArrived = onMessageArrived;
        console.log("Message count: ", messageCount);
        // connect the client (mqtt)
        client.connect({
            onSuccess: onConnect,
            userName: configs['mq-username'],
            password: configs['mq-password'],
            reconnect: true,
            useSSL: configs['use-ssl'] === 'true'
        });
        return () => {
            if (client !== null && client.isConnected()) {
                client.unsubscribe(`ticket/dashboard/branch/${selectedBranchId}`)
                client.unsubscribe(`ticket/lobby/branch/${selectedBranchId}`);
                client.unsubscribe(`user/dashboard/branch/${selectedBranchId}`)
                client.disconnect();
                client = null;
            }
        }
    }, [BranchDashboard, selectedBranchId])

    useEffect(() => {
        // returned function will be called on component unmount
        return () => {
            if (client !== null && client.isConnected()) {
                client.unsubscribe(`ticket/dashboard/branch/${selectedBranchId}`)
                client.unsubscribe(`ticket/lobby/branch/${selectedBranchId}`);
                client.unsubscribe(`user/dashboard/branch/${selectedBranchId}`)
                client.disconnect();
                client = null;
            }
        }
    }, []);

    // called when the client connects (mqtt)
    const onConnect = () => {
        // Once a connection has been made, make a subscription and send a message.
        client.subscribe(`ticket/dashboard/branch/${selectedBranchId}`);
        client.subscribe(`ticket/lobby/branch/${selectedBranchId}`);
        client.subscribe(`user/dashboard/branch/${selectedBranchId}`);
    }

    // called when the client loses its connection (mqtt)
    const onConnectionLost = (responseObject) => {
        if (responseObject.errorCode !== 0) {
            console.log("onConnectionLost:" + responseObject.errorMessage);
        }
    }

    // called when a message arrives (mqtt)
    const onMessageArrived = (message) => {
        console.log("onMessageArrived:" + message.payloadString);
        // alert(message.payloadString)
        setMessageData(message.payloadString)
        setMessageArrived(true);

        executeFunctions(message.payloadString);
    }

    // set chart value
    useEffect(() => {
        setChartValue(chartValue + 1);
        setWaitChartValue(waitChartValue + 1);
        setOperatorsLoginChartValue(operatorsLoginChartValue + 1);
    }, [])

    // set charts values
    useEffect(() => {
        if (messageArrived === true) {
            setChartRefValueToTicketBehaveChart();
            setWaitingChartRefValueToWaitingTicketsChart();

        }
    }, [messageArrived, hourlyIssuedTicketsCount, hourlyServedTicketsCount, hourlyLocalWaitingTicketsCount,
        hourlyNoShowTicketsCount, hourlyWaitingExceptionTicketsCount, hourlyServiceExceptionTicketsCount,
        hourRanges, hourlyTickets])

    // call get data functions - useEffect
    useEffect(() => {
        if (baseUrl.length > 0 && token.length > 0) {
            executeFunctions('');
        }
    }, [baseUrl, token, selectedBranchId, refChartValue])

    // For operator status. Reason for this is when at very first time messageInc9999 is empty. When a message comming from 9999,
    // set a string (probably current date) to messageInc9999. This will affect to inner modals, when modals open two time.

    // First time modal props.messageInc9999 is empty and it will set a string will a message comming from 9999. Then close the
    // modal and re-open it. Now it's props.messageInc9999 is not empty.

    //For prevent this problem.. add below useEffect.
    useEffect(() => {
        setMessageInc9999('');
    }, [messageInc9999])

    useEffect(() => {
        setMessageInc9998('');
    }, [messageInc9998])

    useEffect(() => {
        if (selectedProvince !== undefined && selectedProvince !== null && userDetails['branchLevel'] !== 4) {
            getDistricts(selectedProvince, 0);
        }
    }, [selectedProvince])

    useEffect(() => {
        if (selectedDistrict !== undefined && selectedDistrict !== null) {
            getBranches(selectedDistrict);
        }
    }, [selectedDistrict])

    useEffect(() => {
        if (showTicketIssuedDetails === false &&
            showServedTicketsDetails === false &&
            showOperatorStatusDetails === false &&
            showNoShowTicketsDetails === false &&
            showTicketServingDetails === false &&
            showWaitTimeExceptionDetails === false &&
            showServiceTimeExDetails === false &&
            showWaitingTicketsDetails === false &&
            showLocalWaitingDetails === false &&
            showCounterStatusDetails === false &&
            showVIPTicketsDetails === false &&
            showOperatorAuxDetails === false &&
            showLobbyDetails === false &&
            showCountersCategoryDetails === false &&
            showTransferTicketDetails === false &&
            showKioskDetails === false &&
            showMainDisplayDetails === false) {
            const handleScroll = () => {
                setSticky(window.scrollY > 70);
            };
            window.addEventListener("scroll", handleScroll);
            return () => window.removeEventListener("scroll", handleScroll);
        }
    });

    //execute all functions.
    const executeFunctions = (messageData) => {

        getTicketBehaveDetailsByBranch();
        getBranchSequence();

        if (messageData === null || messageData === '') {

            getTicketSummaryDetails();
            getTicketIssuedDetails();
            getTicketServedDetails();
            getOperatorDetails();
            // getWaitingTicketDetails();
            noSkillTicketsDetails();
            getTicketDetailsByBranch();
            getTicketBehaveDetailsByBranch();
            getMostTicketServedTeller();
            categoryLoadOptimizer();
            categoryUsageMonitorer();

        } else {

            console.info("message data: ", messageData)
            setMessages(messageData);

            if (messageData === "1000" ||
                messageData === '1001') {
                noSkillTicketsDetails();
            }

            if (messageData === '1' ||
                messageData === '1000' ||
                messageData === '1001' ||
                messageData === '1002' ||
                messageData === '2001' ||
                messageData === '3000' ||
                messageData === '3001' ||
                messageData === '9998' ||
                messageData === '9999') {
                getTicketSummaryDetails();
            }

            if (messageData === '1000' ||
                messageData === '1001') {
                getTicketIssuedDetails();
            }

            if (messageData === '1001') {
                getTicketServedDetails();
            }

            // if (messageData === '1000' ||
            //     messageData === '1001') {
            //     getWaitingTicketDetails();
            // }

            if (messageData === '1000' ||
                messageData === '1001' ||
                messageData === '2000' ||
                messageData === '2001' ||
                messageData === '3001') {
                getTicketDetailsByBranch();
            }

            if (messageData === '1001') {
                getMostTicketServedTeller();
            }

            if (messageData === '1000' ||
                messageData === '1001' ||
                messageData === '2000' ||
                messageData === '2001' ||
                messageData === '3000' ||
                messageData === '3001') {
                categoryLoadOptimizer();
            }

            if (messageData === '1001') {
                categoryUsageMonitorer();
            }

            if (messageData === '1001' ||
                messageData === '1010' ||
                messageData === '1011' ||
                messageData === '9999') {
                getOperatorDetails();
            }
        }
    }

    const setMessages = (messageData) => {
        if (messageData === '1') {
            setMessageIncLobby(new Date().toString())
        } else if (messageData === '1000') {
            setMessageInc1000(new Date().toString());
        } else if (messageData === '1001') {
            setMessageInc1001(new Date().toString());
        } else if (messageData === '1002') {
            setMessageInc1002(new Date().toString());
        } else if (messageData === '9999') {
            setMessageInc9999(new Date().toString());
        } else if (messageData === '2000') {
            setMessageInc2000(new Date().toString());
        } else if (messageData === '2001') {
            setMessageInc2001(new Date().toString());
        } else if (messageData === '3000') {
            setMessageInc3000(new Date().toString());
        } else if (messageData === '3001') {
            setmessageInc3001(new Date().toString());
        } else if (messageData === '1010') {
            setMessageInc1010(new Date().toString());
        } else if (messageData === '1011') {
            setMessageInc1011(new Date().toString());
        } else if (messageData === '9998') {
            setMessageInc9998(new Date().toString());
        }
    }

    const getBranchSequence = () => {
        // userDetails['userType']
        axios({
            method: 'GET',
            url: `${baseUrl}/branch/get-sequence/branch/${selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('getBranchSequence success');
            let data = res.data.data;
            setBranchSqeuenceData(data);

            if (data.countryId !== undefined) {
                setselectedCountry(data.countryId);
                getCountry(data.countryId);
                getProvinces(data.countryId, data.provinceId);
            }

            if (data.provinceId !== undefined) {
                setselectedProvince(data.provinceId);
                getDistricts(data.provinceId, data.districtId);
            }

            if (data.districtId !== undefined) {
                setselectedDistrict(data.districtId)
                getBranches(data.districtId);
            }

            if (data.branchId !== undefined) {
                setSelectedUserBranch(data.branchId);
            }

        }).catch((error) => {
            console.log('getBranchSequence error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    const getCountry = (country) => {
        axios({
            method: 'GET',
            url: `${baseUrl}/branch/get-branch/${country}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('getCountry success');
            let data = res.data.data;
            setCountry(data);

        }).catch((error) => {
            console.log('getCountry error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    const getProvinces = (country, province) => {

        let url = ``;

        if (userDetails['branchLevel'] === 1) {

            url = `${baseUrl}/branch/get-all/parent-branch/${country}`;

        } else if (userDetails['branchLevel'] === 3 || userDetails['branchLevel'] === 4) {

            url = `${baseUrl}/branch/get-branch/${province}`

        } else {

            url = `${baseUrl}/branch/get-branch/${userDetails['bid']}`;

        }

        axios({
            method: 'GET',
            url: url,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('getProvinces success');
            let data = res.data.data;
            console.log("province data: ", data);
            if (userDetails['branchLevel'] === 1) {
                setProvinces(data);
            } else {
                setprovince(data);
            }

        }).catch((error) => {
            console.log('getProvinces error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    const getDistricts = (province, district) => {
        let url = ``;

        if (userDetails['branchLevel'] === 1 || userDetails['branchLevel'] === 2) {

            url = `${baseUrl}/branch/get-all/parent-branch/${province}`;

        } else if (userDetails['branchLevel'] === 4) {

            url = `${baseUrl}/branch/get-branch/${district}`

        } else {

            url = `${baseUrl}/branch/get-branch/${userDetails['bid']}`;

        }

        axios({
            method: 'GET',
            url: url,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('getDistricts success');
            let data = res.data.data;

            if (userDetails['branchLevel'] === 1
                || userDetails['branchLevel'] === 2) {
                setDistricts(data);
            } else {
                setdistrict(data);
            }

        }).catch((error) => {
            console.log('getDistricts error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    const getBranches = (district) => {
        let url = ``;

        if (userDetails['branchLevel'] === 4) {
            url = `${baseUrl}/branch/get-branch/${userDetails['bid']}`;
        } else {
            url = `${baseUrl}/branch/get-all/parent-branch/${district}`;
        }

        axios({
            method: 'GET',
            url: url,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('getBranches success');
            let data = res.data.data;

            if (userDetails['branchLevel'] === 4) {
                setuserBranch(data);
            } else {
                if (data !== null) {
                    setBranches(data);
                }
            }

        }).catch((error) => {
            console.log('getBranches error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    // getTicketIssuedDetails - function
    const getTicketSummaryDetails = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/get-branch-exceptions/branch/${selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('getTicketSummaryDetails success');
            let data = res.data.data;
            // setTotalWaitingCount(data.waitingTickets);
            // settransferWaitingCount(data.transferWaitingTickets);
            // setWaitingWithoutTransferCount(data.waitingWithoutTransferTickets);
            setTicketSummaryDetails(data);

        }).catch((error) => {
            console.log('getTicketSummaryDetails error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    // getTicketIssuedDetails - function
    const getTicketIssuedDetails = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/get-issued-ticket-details/branch/${selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('getTicketIssuedDetails success');
            let data = res.data.data;
            setTicketIssuedDetails(data);

        }).catch((error) => {
            console.log('getTicketIssuedDetails error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    // getTicketServedDetails - function
    const getTicketServedDetails = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/get-served-ticket-details/branch/${selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('getTicketServedDetails success');
            let data = res.data.data;
            setTicketServedDetails(data);

        }).catch((error) => {
            console.log('getTicketServedDetails error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })

    }

    // getWaitingTicketDetails - function
    // const getWaitingTicketDetails = () => {

    //     axios({
    //         method: 'GET',
    //         url: `${baseUrl}/real-time/get-waiting-ticket-details/branch/${selectedBranchId}`,
    //         headers: {
    //             Authorization: token
    //         }
    //     }).then((res) => {

    //         console.log('getWaitingTicketDetails success');
    //         let data = res.data.data;
    //         setTicketWaitingDetails(data);
    //         loopHourRangesAndTickets(data.hourlyWaiting);
    //         setWaitChartValue(waitChartValue + 1);

    //     }).catch((error) => {
    //         console.log('getWaitingTicketDetails error');
    //         console.log(error);

    //         if (error.response !== undefined) {
    //             toast.remove();
    //             toast.error(error.response.data.message, {
    //                 position: 'bottom-center',
    //             });
    //         } else {
    //             toast.remove();
    //             toast.error('Something went wrong', {
    //                 position: 'bottom-center',
    //             });
    //         }

    //     })

    // }

    // getOperatorDetails - function
    const getOperatorDetails = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/get-counter-operation-details/branch/${selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('getOperatorDetails success');
            let data = res.data.data;
            setTicketOperatorDetails(data);

        }).catch((error) => {
            console.log('getOperatorDetails error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })

    }

    // getTicketDetailsByBranch - function
    const getTicketDetailsByBranch = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/get-ticket-details/branch/${selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('getTicketDetailsByBranch success');
            let data = res.data.data;
            setTicketDetails(data);

            setAllDataLoaded(true);

        }).catch((error) => {
            console.log('getTicketDetailsByBranch error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    // getTicketBehaveDetailsByBranch - function
    const getTicketBehaveDetailsByBranch = () => {
        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/get-ticket-behaviour/branch/${selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            let data = res.data.data;
            if (data.length !== 0) {
                loopTokenIssuedProcessData(data.hourlyTicketBehaviour);
            } else {
                loopTokenIssuedProcessData([])
            }
            setChartValue(chartValue + 1);
            setAllDataLoaded(true);
        }).catch((error) => {
            console.log('getTicketBehaveDetailsByBranch error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    // getWaitingTicketDetails - function
    const getMostTicketServedTeller = () => {
        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/get-most-served-user/branch/${selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('getMostTicketServedTeller success');
            let data = res.data.data;
            setMostServedTellerDetails(data);

        }).catch((error) => {
            console.log('getMostTicketServedTeller error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })

    }

    // getWaitingTicketDetails - function
    const categoryLoadOptimizer = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/category-load-optimizing/branch/${selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('getMostTicketServedTeller success');
            let data = res.data.data;
            setCategoryLoadDetails(data);

        }).catch((error) => {
            console.log('getMostTicketServedTeller error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })

    }

    // categoryUsageMonitorer - function
    const categoryUsageMonitorer = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/category-usage-monitor/branch/${selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('categoryUsageMonitorer success');
            let data = res.data.data;
            setCategoryUsageList(data);

        }).catch((error) => {
            console.log('categoryUsageMonitorer error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })

    }

    // noSkillTicketsDetails - function
    const noSkillTicketsDetails = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/no-skills/inner-data/branch/${selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('noSkillTicketsDetails success');
            let data = res.data.data;
            setNoSkillsTicket(data);

        }).catch((error) => {
            console.log('noSkillTicketsDetails error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })

    }

    const setChartRefValueToTicketBehaveChart = () => {
        console.log("setChartRefValueToTicketBehaveChart");
        refChartValue.current += 1;
        if (chartValue <= refChartValue.current) {
            setChartValue(refChartValue.current)
        }
    }

    const setWaitingChartRefValueToWaitingTicketsChart = () => {
        refWaitingChartValue.current += 1;
        console.log("setWaitingChartRefValueToWaitingTicketsChart");
        if (waitChartValue <= refWaitingChartValue.current) {
            setWaitChartValue(refWaitingChartValue.current)
        }
    }

    const loopHourRangesAndTickets = (hourlyWaiting) => {

        console.log("loopHourRangesAndTickets started.");
        const hours = [];
        const ticketsInHours = [];

        hourlyWaiting.forEach(element => {
            hours.push(element.hourRange)
            ticketsInHours.push(element.waitingTicketCount)
        });

        setHourRanges(hours)
        setHourlyTickets(ticketsInHours)
    }

    const loopTokenIssuedProcessData = (hourlyTicketBehaviour) => {

        const hours = [];
        const chartMax = [];
        const hourlyIssuedTickets = [];
        const hourlyServedTickets = [];
        const hourlyLocalWaitingTickets = [];
        const hourlyNoShowTickets = [];
        const hourlyWaitingExceptionsTickets = [];
        const hourlyServiceExceptionsTickets = [];

        if (hourlyTicketBehaviour.length !== 0) {
            console.log("loopTokenIssuedProcessData started.");
            hourlyTicketBehaviour.forEach(element => {
                hours.push(element.hourRange);
                chartMax.push(element.issuedTicketsCount + 10);
                hourlyIssuedTickets.push(element.issuedTicketsCount);
                hourlyServedTickets.push(element.servedCount);
                hourlyLocalWaitingTickets.push(element.localWaitingCount);
                hourlyNoShowTickets.push(element.noShowCount);
                hourlyWaitingExceptionsTickets.push(element.waitExceptionsCount);
                hourlyServiceExceptionsTickets.push(element.serviceExceptionsCount);
            });

            setHourRangesBehave(hours);
            setChartMaxValue(chartMax)
            setHourlyIssuedTicketsCount(hourlyIssuedTickets);
            setHourlyServedTicketsCount(hourlyServedTickets);
            setHourlyLocalWaitingTicketsCount(hourlyLocalWaitingTickets);
            setHourlyNoShowTicketsCount(hourlyNoShowTickets);
            setHourlyWaitingExceptionTicketsCount(hourlyWaitingExceptionsTickets);
            setHourlyServiceExceptionTicketsCount(hourlyServiceExceptionsTickets);
        } else {
            setHourlyIssuedTicketsCount([]);
            setHourlyServedTicketsCount([]);
            setHourlyLocalWaitingTicketsCount([]);
            setHourlyNoShowTicketsCount([]);
            setHourlyWaitingExceptionTicketsCount([]);
            setHourlyServiceExceptionTicketsCount([]);
        }
    }

    //get queuetypebyCustomer - function
    const getQueueTypeByCustomer = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/queue/branch/${selectedBranchId}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {

            console.log('getQueueTypeByCustomer success');
            const data = res.data.data;
            data.forEach(element => {
                if (element.selected) {
                    setBranchQueueType(element.type)
                    return;
                }
            });

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    return (
        <>
            <div><Toaster /></div>

            <>
                <div className={`${sticky ? "block-header block-header-padding sticky" : "block-header block-header-padding"}`}>
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h1>Branch Dashboard</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item active" aria-current="page">{selectedBranchName}</li>
                                </ol>
                            </nav>
                        </div>

                        {selectedBranchLevel === 4 ? (
                            <div className="col-md-6 col-sm-12 text-right hidden-xs">
                                <button className="btn btn-sm btn-primary mb-2"
                                    type="button"
                                    title={`View Branch Table`}
                                    onClick={() => {
                                        navigateToBranchTables(selectedBranchId, selectedBranchName, selectedBranchLevel, selectedBranchQueueType)
                                    }}
                                >Branch Tables</button>
                            </div>
                        ) : (
                            <div className="col-md-6 col-sm-12 text-right hidden-xs">
                                <button className="btn btn-sm btn-primary mb-2"
                                    type="button"
                                    title={`View Branch Table`}
                                    onClick={() => {
                                        executeFunctions('')
                                    }}
                                >Refresh <i className="fa fa-refresh"></i></button>
                            </div>
                        )}

                    </div>
                </div>

                <div className="container-fluid branch-dashboard">
                    {
                        allDataLoaded === false ? (
                            <>
                                {/* <div className="block-header">
                                <div className="row clearfix">

                                    <div className="col-md-6 col-sm-12">
                                        <h1>Branch Dashboard</h1>
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><Link to='/branch-summary'>Branch Summary</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page"> {selectedBranchName}</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div> */}

                                {/* 1st row */}
                                <div className="row clearfix">

                                    {/* 1st row | 1st col*/}
                                    <div className="col-lg-3 col-md-12 col-sm-12 p-l-5 p-r-5">

                                        <ShimmerThumbnail height={'100%'}>
                                            <div className="card" style={{ cursor: 'pointer' }}>
                                                <div className="body">
                                                    <div>Issued Tickets</div>
                                                    <div className="py-4 m-0 text-center h1 text-light-green">
                                                    </div>
                                                    <div className="d-flex">
                                                        <small className="text-muted">Hourly update</small>
                                                        <div className="ml-auto">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ShimmerThumbnail>

                                        <ShimmerThumbnail height={'100%'}>
                                            <div className="card" style={{ cursor: 'pointer' }}>
                                                <div className="body">
                                                    <div>Ticket Served</div>
                                                    <div className="py-4 m-0 text-center h1 text-light-green">258</div>
                                                    <div className="d-flex">
                                                        <small className="text-muted">Hourly update</small>
                                                        <div className="ml-auto">
                                                            <i className="fa fa-caret-up text-light-green mr-1"></i>4%
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ShimmerThumbnail>

                                        <ShimmerThumbnail height={'100%'}>
                                            <div className="card" style={{ cursor: 'pointer' }}
                                            // onClick={() => toggleOperatorStatusDetails(!isOperatorStatusDetails, 'this is operator status')}
                                            >
                                                <div className="body">
                                                    <div>Operator Status</div>
                                                    <div className="py-4 m-0 text-center h4">
                                                        <div className='display-inline-block mr-4 text-light-green'>
                                                            <i className='fa fa-eye mr-1'></i> 05
                                                        </div>
                                                        <div className='display-inline-block text-diseble'>
                                                            <i className='fa fa-eye-slash mr-1'></i> 19
                                                        </div>
                                                    </div>
                                                    <div className="d-flex">
                                                        <small className="text-muted">Hourly update</small>
                                                        <div className="ml-auto">
                                                            <i className="fa fa-caret-up text-light-green mr-1"></i>4%
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ShimmerThumbnail>

                                    </div>

                                    {/* 1st row | 2st col*/}
                                    <div className="col-lg-3 col-md-6 col-sm-12 p-l-5 p-r-5">

                                        <ShimmerThumbnail height={'100%'}>
                                            <div className="card" style={{ cursor: 'pointer' }}>
                                                <div className="body top_counter">
                                                    <div className="content vertically-align-center-flex">
                                                        <span>No show tickets</span>
                                                        <h5 className="number mb-0 text-light-green">53</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </ShimmerThumbnail>

                                        <ShimmerThumbnail height={'100%'}>
                                            <div className="card" style={{ cursor: 'pointer' }}>
                                                <div className="body top_counter">
                                                    <div className="content vertically-align-center-flex">
                                                        <span>Serving ticket</span>
                                                        <h5 className="number mb-0 text-light-green">62</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </ShimmerThumbnail>

                                        <ShimmerThumbnail height={'100%'}>
                                            <div className="card" style={{ cursor: 'pointer' }}>
                                                <div className="body top_counter">
                                                    <div className="content vertically-align-center-flex">
                                                        <span>Wait time exception</span>
                                                        <h5 className="number mb-0 text-maroon">32</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </ShimmerThumbnail>

                                        <ShimmerThumbnail height={'100%'}>
                                            <div className="card" style={{ cursor: 'pointer' }}>
                                                <div className="body top_counter">
                                                    <div className="content vertically-align-center-flex">
                                                        <span>Service time exception</span>
                                                        <h5 className="number mb-0 text-maroon">17</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </ShimmerThumbnail>

                                        <ShimmerThumbnail height={'100%'}>
                                            <div className="card" style={{ cursor: 'pointer' }}>
                                                <div className="body top_counter">
                                                    <div className="content vertically-align-center-flex">
                                                        <span>Transfered tickets</span>
                                                        <h5 className="number mb-0 text-maroon">17</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </ShimmerThumbnail>

                                    </div>

                                    {/* 1st row | 3st col*/}
                                    <div className="col-lg-3 col-md-6 col-sm-12 p-l-5 p-r-5">

                                        <ShimmerThumbnail height={'100%'}>
                                            <div className="card" style={{ cursor: 'pointer' }}>
                                                <div className="body text-center">
                                                    <div className=' text-center d-flex justify-content-center mb-3'>
                                                        <Donut
                                                            style={{ fontSize: '20px' }}
                                                            className="knob"
                                                            diameter={80}
                                                            min={0}
                                                            max={100}
                                                            step={1}
                                                            value={50}
                                                            theme={{
                                                                donutThickness: 3,
                                                                donutColor: '#5EB902',
                                                                centerColor: '#282B2F',
                                                                bgrColor: '#383b40',
                                                            }}
                                                        />
                                                    </div>
                                                    <h4 className="mb-3">Waiting Tickets</h4>
                                                    <div className="d-flex">
                                                        <small>Hourly update</small>
                                                        <div className="ml-auto">
                                                            <i className="fa fa-caret-up mr-1"></i>3%
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-chart-bg">
                                                    <LineChart data={[0, 0, 0, 0, 0, 0, 0]} color="#5EB902" />
                                                </div>
                                            </div>
                                        </ShimmerThumbnail>

                                        <ShimmerThumbnail height={'100%'}>
                                            <div className="card" style={{ cursor: 'pointer' }}>
                                                <div className="body">

                                                    <div className='card-with-right-donut'>
                                                        <div>
                                                            <h6 className="mb-3">Local waiting</h6>
                                                            <div className="d-flex">
                                                                <small className='mr-3'>Hourly update</small>
                                                                <div className="ml-auto">
                                                                    <i className="fa fa-caret-up mr-1"></i>3%
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Donut
                                                            style={{ fontSize: '20px' }}
                                                            className="knob"
                                                            diameter={60}
                                                            min={0}
                                                            max={100}
                                                            step={1}
                                                            value={50}
                                                            theme={{
                                                                donutThickness: 3,
                                                                donutColor: '#F1C40E',
                                                                centerColor: '#282B2F',
                                                                bgrColor: '#383b40',
                                                            }}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </ShimmerThumbnail>

                                        <ShimmerThumbnail height={'100%'}>
                                            <div className="card" style={{ cursor: 'pointer' }}>
                                                <div className="body">

                                                    <div className='card-with-right-donut'>
                                                        <div>
                                                            <h6 className='m-0'>Counter status</h6>
                                                        </div>
                                                        <Donut
                                                            style={{ fontSize: '20px' }}
                                                            className="knob"
                                                            diameter={60}
                                                            min={0}
                                                            max={100}
                                                            step={1}
                                                            value={50}
                                                            theme={{
                                                                donutThickness: 3,
                                                                donutColor: '#F1C40E',
                                                                centerColor: '#282B2F',
                                                                bgrColor: '#383b40',
                                                            }}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </ShimmerThumbnail>

                                    </div>
                                </div>
                            </>

                        ) : (
                            <>

                                {userDetails['branchLevel'] !== 4 ? (
                                    <div className="row clearfix">
                                        <div className="col-md-12 col-sm-8 p-l-5 p-r-5 mb-4">
                                            <div className="card">
                                                <div className="body">
                                                    <div className="row">
                                                        <div className="col-md-12 col-sm-12 p-l-5 p-r-5">
                                                            <Accordion className="accordion" id="accordion" >
                                                                <div>
                                                                    <div className="card-header " id="headingOne">
                                                                        <h5 className="mb-0">
                                                                            <Accordion.Toggle as={Button} variant="link" eventKey="0"
                                                                                className={selectedBranchLevel === 4 ? 'acc-color-branch'
                                                                                    : selectedBranchLevel === 3 ? 'acc-color-district'
                                                                                        : selectedBranchLevel === 2 ? 'acc-color-province'
                                                                                            : 'acc-color-country'}
                                                                            >
                                                                                {branchSqeuenceData.name}
                                                                                <div className="float-right"><i className="fa fa-chevron-circle-down"></i></div>
                                                                            </Accordion.Toggle>
                                                                        </h5>
                                                                    </div>
                                                                    <Accordion.Collapse eventKey="0" className="collapse" >
                                                                        <div className="body">
                                                                            <div className='row clearfix'>
                                                                                <div className="col-lg-3 col-md-6 col-sm-12">
                                                                                    <div className="card">
                                                                                        <div className='taskboard b-cyan progress_task'>
                                                                                            <h6 className="font300 mb-3">Country
                                                                                            </h6>
                                                                                            <div className="dd" data-plugin="nestable">
                                                                                                {userDetails['branchLevel'] === 1 ? (
                                                                                                    <div
                                                                                                        className={`dd-handle align-items-center ${selectedCountry === country.id ? 'active' : ''}`}
                                                                                                        // key={country.id}
                                                                                                        onClick={() => {
                                                                                                            setselectedCountry(country.id)
                                                                                                            setselectedProvince(null)
                                                                                                            setselectedDistrict(null)
                                                                                                            setSelectedUserBranch(null)
                                                                                                            setDistricts([])
                                                                                                            setBranches([])

                                                                                                        }}

                                                                                                    >
                                                                                                        <div className='d-flex justify-content-between'>
                                                                                                            <div>{country.name}</div>
                                                                                                            <div className={selectedBranchId === country.id ? 'btn btn-prymary select-btn external-link-active'
                                                                                                                : 'btn btn-prymary select-btn'}
                                                                                                                onClick={() => {
                                                                                                                    setSelectedBranchId(country.id)
                                                                                                                    setSelectedBranchName(country.name)
                                                                                                                    setSelectedBranchLevel(country.level)
                                                                                                                }}
                                                                                                            >
                                                                                                                <i className="fa fa-external-link float-left"></i>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <div
                                                                                                        className='dd-handle align-items-center' style={{ cursor: 'default' }}
                                                                                                    >
                                                                                                        <div className='d-flex justify-content-between'>
                                                                                                            <div>{country.name}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-3 col-md-6 col-sm-12">
                                                                                    <div className="card">
                                                                                        <div className='taskboard b-cyan progress_task'>
                                                                                            <h6 className="font300 mb-3">Province
                                                                                            </h6>
                                                                                            <div className="dd" data-plugin="nestable">
                                                                                                {provinces !== null ? (
                                                                                                    <>
                                                                                                        {userDetails['branchLevel'] === 1 ? (
                                                                                                            <>
                                                                                                                {provinces.map((data, i) =>
                                                                                                                    <div
                                                                                                                        className={`dd-handle align-items-center ${selectedProvince === data.id ? 'active' : ''}`}
                                                                                                                        key={i}
                                                                                                                        onClick={() => {
                                                                                                                            setselectedProvince(data.id)
                                                                                                                            setselectedDistrict(null)
                                                                                                                            setSelectedUserBranch(null)
                                                                                                                            setBranches([])
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <div className='d-flex justify-content-between'>
                                                                                                                            <div>{data.name}</div>
                                                                                                                            <div className={selectedBranchId === data.id ? 'btn btn-prymary select-btn external-link-active'
                                                                                                                                : 'btn btn-prymary select-btn'}
                                                                                                                                onClick={() => {
                                                                                                                                    setSelectedBranchId(data.id)
                                                                                                                                    setSelectedBranchName(data.name)
                                                                                                                                    setSelectedBranchLevel(data.level)
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <i className="fa fa-external-link float-left"></i>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </>
                                                                                                        ) : userDetails['branchLevel'] === 2 ? (
                                                                                                            <div
                                                                                                                className={`dd-handle align-items-center ${selectedProvince === province.id ? 'active' : ''}`}
                                                                                                                onClick={() => {
                                                                                                                    setselectedProvince(province.id)
                                                                                                                    setselectedDistrict(null)
                                                                                                                    setSelectedUserBranch(null)
                                                                                                                    setBranches([])
                                                                                                                }}
                                                                                                            >
                                                                                                                <div className='d-flex justify-content-between'>
                                                                                                                    <div>{province.name}</div>
                                                                                                                    <div className={selectedBranchId === province.id ? 'btn btn-prymary select-btn external-link-active'
                                                                                                                        : 'btn btn-prymary select-btn'}
                                                                                                                        onClick={() => {
                                                                                                                            setSelectedBranchId(province.id)
                                                                                                                            setSelectedBranchName(province.name)
                                                                                                                            setSelectedBranchLevel(province.level)
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <i className="fa fa-external-link float-left"></i>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ) : (
                                                                                                            <div
                                                                                                                className='dd-handle align-items-center' style={{ cursor: 'default' }}
                                                                                                            >
                                                                                                                <div className='d-flex justify-content-between'>
                                                                                                                    <div>{province.name}</div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <div className={`dd-handle align-items-center`}>
                                                                                                        <div className='d-flex justify-content-between'>
                                                                                                            <div> No provices yet... </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-lg-3 col-md-6 col-sm-12">
                                                                                    <div className="card">
                                                                                        <div className='taskboard b-cyan progress_task'>
                                                                                            <h6 className="font300 mb-3">District
                                                                                            </h6>
                                                                                            <div className="dd" data-plugin="nestable">
                                                                                                {userDetails['branchLevel'] === 1 || userDetails['branchLevel'] === 2 ? (
                                                                                                    <>
                                                                                                        {districts !== null ? (
                                                                                                            <>
                                                                                                                {districts.length > 0 ? (
                                                                                                                    <>
                                                                                                                        {districts.map((data, i) =>
                                                                                                                            <div
                                                                                                                                className={`dd-handle align-items-center ${selectedDistrict === data.id ? 'active' : ''}`}
                                                                                                                                key={i}
                                                                                                                                onClick={() => {
                                                                                                                                    setselectedDistrict(data.id);
                                                                                                                                    setSelectedUserBranch(null);
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <div className='d-flex justify-content-between'>
                                                                                                                                    <div>{data.name}</div>
                                                                                                                                    <div className={selectedBranchId === data.id ? 'btn btn-prymary select-btn external-link-active'
                                                                                                                                        : 'btn btn-prymary select-btn'}
                                                                                                                                        onClick={() => {
                                                                                                                                            setSelectedBranchId(data.id)
                                                                                                                                            setSelectedBranchName(data.name)
                                                                                                                                            setSelectedBranchLevel(data.level)
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <i className="fa fa-external-link float-left"></i>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                ) : (<div className={`dd-handle align-items-center`}>
                                                                                                                    <div className='d-flex justify-content-between'>
                                                                                                                        <div> Select Provice </div>
                                                                                                                    </div>
                                                                                                                </div>)}
                                                                                                            </>
                                                                                                        ) : (<div className={`dd-handle align-items-center`}>
                                                                                                            <div className='d-flex justify-content-between'>
                                                                                                                <div> No districts yet... </div>
                                                                                                            </div>
                                                                                                        </div>)}
                                                                                                    </>
                                                                                                ) : userDetails['branchLevel'] === 3 ? (
                                                                                                    <div
                                                                                                        className={`dd-handle align-items-center ${selectedDistrict === district.id ? 'active' : ''}`}

                                                                                                        onClick={() => {
                                                                                                            setselectedDistrict(district.id);
                                                                                                            setSelectedUserBranch(null);
                                                                                                        }}
                                                                                                    >
                                                                                                        <div className='d-flex justify-content-between'>
                                                                                                            <div>{district.name}</div>
                                                                                                            <div className={selectedBranchId === district.id ? 'btn btn-prymary select-btn external-link-active'
                                                                                                                : 'btn btn-prymary select-btn'}
                                                                                                                onClick={() => {
                                                                                                                    setSelectedBranchId(district.id)
                                                                                                                    setSelectedBranchName(district.name)
                                                                                                                    setSelectedBranchLevel(district.level)
                                                                                                                }}
                                                                                                            >
                                                                                                                <i className="fa fa-external-link float-left"></i>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ) : (<div
                                                                                                    className='dd-handle align-items-center' style={{ cursor: 'default' }}
                                                                                                >
                                                                                                    <div className='d-flex justify-content-between'>
                                                                                                        <div>{district.name}</div>
                                                                                                    </div>
                                                                                                </div>)}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-3 col-md-6 col-sm-12">
                                                                                    <div className="card">
                                                                                        <div className='taskboard b-cyan progress_task'>
                                                                                            <h6 className="font300 mb-3">Branch
                                                                                            </h6>
                                                                                            <div className="dd" data-plugin="nestable">
                                                                                                {userDetails['branchLevel'] === 1 || userDetails['branchLevel'] === 2 || userDetails['branchLevel'] === 3 ? (
                                                                                                    <>
                                                                                                        {branches !== null ? (
                                                                                                            <>
                                                                                                                {branches.length > 0 ? (
                                                                                                                    <>
                                                                                                                        {branches.map((data, i) =>
                                                                                                                            <div
                                                                                                                                className={`dd-handle align-items-center ${selectedUserBranch === data.id ? 'active' : ''}`}
                                                                                                                                key={i}
                                                                                                                                onClick={() => {
                                                                                                                                    setSelectedUserBranch(data.id)
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <div className='d-flex justify-content-between'>
                                                                                                                                    <div>{data.name}</div>
                                                                                                                                    <div className={selectedBranchId === data.id ? 'btn btn-prymary select-btn external-link-active' : 'btn btn-prymary select-btn'}
                                                                                                                                        onClick={() => {
                                                                                                                                            setSelectedBranchId(data.id)
                                                                                                                                            setSelectedBranchName(data.name)
                                                                                                                                            setSelectedBranchLevel(data.level)
                                                                                                                                            setSelectedBranchQueueType(data.branchQueueType)
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <i className="fa fa-external-link float-left"></i>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <div className={`dd-handle align-items-center`}>
                                                                                                                        <div className='d-flex justify-content-between'>
                                                                                                                            <div> Select District </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <div className={`dd-handle align-items-center`}>
                                                                                                                <div className='d-flex justify-content-between'>
                                                                                                                    <div> No branches yet... </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </>
                                                                                                ) : userDetails['branchLevel'] === 4 ? (
                                                                                                    <div
                                                                                                        className={`dd-handle align-items-center ${selectedUserBranch === userBranch.id ? 'active' : ''}`}
                                                                                                        onClick={() => {
                                                                                                            setSelectedUserBranch(userBranch.id)
                                                                                                            setSelectedBranchId(userBranch.id)
                                                                                                            setSelectedBranchName(userBranch.name)
                                                                                                            setSelectedBranchLevel(userBranch.level)
                                                                                                            setSelectedBranchQueueType(userBranch.branchQueueType)
                                                                                                        }}
                                                                                                    >
                                                                                                        <div className='d-flex justify-content-between'>
                                                                                                            <div>{userBranch.name}</div>
                                                                                                            <div className={selectedBranchId === userBranch.id ? 'btn btn-prymary select-btn external-link-active'
                                                                                                                : 'btn btn-prymary select-btn'}>
                                                                                                                <i className="fa fa-external-link float-left"></i>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ) : (<div
                                                                                                    className='dd-handle align-items-center' style={{ cursor: 'default' }}
                                                                                                >
                                                                                                    <div className='d-flex justify-content-between'>
                                                                                                        <div>{userBranch.name}</div>
                                                                                                    </div>
                                                                                                </div>)}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Accordion.Collapse>
                                                                </div>
                                                            </Accordion>
                                                        </div>

                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (null)}

                                {/* 1st row */}
                                <div className="row clearfix">

                                    {/* 1st row | 1st col*/}
                                    <div className="col-lg-3 col-md-12 col-sm-12 p-l-5 p-r-5">

                                        {ticketSummaryDetails.ticketCount !== 0 ? (
                                            <div className="card" style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    // toggleTicketIssuedDetails(!isTicketIssuedDetails, 'hi')
                                                    setShowTicketIssuedDetails(true);
                                                    setSticky(false);
                                                    setTicketIssuedInc(ticketIssuedInc + 1);
                                                }}
                                            >
                                                <div className="body">
                                                    <div>Issued Tickets</div>
                                                    <div className="py-4 m-0 text-center h1 text-light-green">
                                                        {Object.keys(ticketSummaryDetails).length > 0 ?
                                                            <>{ticketSummaryDetails.ticketCount}</>
                                                            :
                                                            <>00</>
                                                        }
                                                    </div>
                                                    <div className="d-flex">
                                                        {selectedBranchLevel === 4 ? (
                                                            <>
                                                                <small className="text-muted">Hourly update</small>
                                                                <div className="ml-auto">
                                                                    {ticketIssuedDetails.hourlyUpdatingPercentage < 0 ? (
                                                                        <>
                                                                            <i className="fa fa-caret-down text-red mr-1"></i>
                                                                            {Object.keys(ticketIssuedDetails).length > 0 ?
                                                                                <>{ticketIssuedDetails.hourlyUpdatingPercentage}%</>
                                                                                :
                                                                                <>00%</>
                                                                            }
                                                                        </>
                                                                    ) : ticketIssuedDetails.hourlyUpdatingPercentage > 0 ? (
                                                                        <>
                                                                            <i className="fa fa-caret-up text-light-green mr-1"></i>
                                                                            {Object.keys(ticketIssuedDetails).length > 0 ?
                                                                                <>{ticketIssuedDetails.hourlyUpdatingPercentage}%</>
                                                                                :
                                                                                <>00%</>
                                                                            }
                                                                        </>
                                                                    ) : <>
                                                                        {Object.keys(ticketIssuedDetails).length > 0 ?
                                                                            <>{ticketIssuedDetails.hourlyUpdatingPercentage}%</>
                                                                            :
                                                                            <>00%</>
                                                                        }
                                                                    </>
                                                                    }
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (<div className="card" style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                toast.remove();
                                                toast("No tickets yet.", {
                                                    position: 'bottom-center',
                                                    icon: '⛔'
                                                })
                                            }}
                                        >
                                            <div className="body">
                                                <div>Issued Tickets</div>
                                                <div className="py-4 m-0 text-center h1 text-light-green">
                                                    {Object.keys(ticketSummaryDetails).length > 0 ?
                                                        <>{ticketSummaryDetails.ticketCount}</>
                                                        :
                                                        <>00</>
                                                    }
                                                </div>
                                                <div className="d-flex">
                                                    {selectedBranchLevel === 4 ? (
                                                        <>
                                                            <small className="text-muted">Hourly update</small>
                                                            <div className="ml-auto">
                                                                {ticketIssuedDetails.hourlyUpdatingPercentage < 0 ? (
                                                                    <>
                                                                        <i className="fa fa-caret-down text-red mr-1"></i>
                                                                        {Object.keys(ticketIssuedDetails).length > 0 ?
                                                                            <>{ticketIssuedDetails.hourlyUpdatingPercentage}%</>
                                                                            :
                                                                            <>00%</>
                                                                        }
                                                                    </>
                                                                ) : ticketIssuedDetails.hourlyUpdatingPercentage > 0 ? (
                                                                    <>
                                                                        <i className="fa fa-caret-up text-light-green mr-1"></i>
                                                                        {Object.keys(ticketIssuedDetails).length > 0 ?
                                                                            <>{ticketIssuedDetails.hourlyUpdatingPercentage}%</>
                                                                            :
                                                                            <>00%</>
                                                                        }
                                                                    </>
                                                                ) : <>
                                                                    {Object.keys(ticketIssuedDetails).length > 0 ?
                                                                        <>{ticketIssuedDetails.hourlyUpdatingPercentage}%</>
                                                                        :
                                                                        <>00%</>
                                                                    }
                                                                </>
                                                                }
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>
                                        </div>)}


                                        {ticketSummaryDetails.servedTickets !== 0 ? (
                                            <>
                                                <div className="card" style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setShowServedTicketsDetails(true);
                                                        setSticky(false);
                                                        setServedTicketsInc(servedTicketsInc + 1);
                                                    }}>
                                                    <div className="body">
                                                        <div>Served Tickets</div>
                                                        <div className="py-4 m-0 text-center h1 text-light-green">
                                                            {Object.keys(ticketSummaryDetails).length > 0 ?
                                                                <>{ticketSummaryDetails.servedTickets}</>
                                                                :
                                                                <>00</>
                                                            }
                                                        </div>
                                                        <div className="d-flex">
                                                            {selectedBranchLevel === 4 ? (
                                                                <>
                                                                    <small className="text-muted">Hourly update</small>
                                                                    <div className="ml-auto">
                                                                        {ticketServedDetails.hourlyUpdatingPercentage < 0 ? (
                                                                            <>
                                                                                <i className="fa fa-caret-down text-red mr-1"></i>
                                                                                {Object.keys(ticketServedDetails).length > 0 ?
                                                                                    <>{ticketServedDetails.hourlyUpdatingPercentage}%</>
                                                                                    :
                                                                                    <>00%</>
                                                                                }
                                                                            </>
                                                                        ) : ticketServedDetails.hourlyUpdatingPercentage > 0 ? (
                                                                            <>
                                                                                <i className="fa fa-caret-up text-light-green mr-1"></i>
                                                                                {Object.keys(ticketServedDetails).length > 0 ?
                                                                                    <>{ticketServedDetails.hourlyUpdatingPercentage}%</>
                                                                                    :
                                                                                    <>00%</>
                                                                                }
                                                                            </>
                                                                        ) : <>
                                                                            {Object.keys(ticketServedDetails).length > 0 ?
                                                                                <>{ticketServedDetails.hourlyUpdatingPercentage}%</>
                                                                                :
                                                                                <>00%</>
                                                                            }
                                                                        </>
                                                                        }
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="card" style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        toast.remove();
                                                        toast("No tickets yet.", {
                                                            position: 'bottom-center',
                                                            icon: '⛔'
                                                        })
                                                    }}
                                                >
                                                    <div className="body">
                                                        <div>Served Tickets</div>
                                                        <div className="py-4 m-0 text-center h1 text-light-green">
                                                            {Object.keys(ticketSummaryDetails).length > 0 ?
                                                                <>{ticketSummaryDetails.servedTickets}</>
                                                                :
                                                                <>00</>
                                                            }
                                                        </div>
                                                        <div className="d-flex">
                                                            {selectedBranchLevel === 4 ? (
                                                                <>
                                                                    <small className="text-muted">Hourly update</small>
                                                                    <div className="ml-auto">
                                                                        {ticketServedDetails.hourlyUpdatingPercentage < 0 ? (
                                                                            <>
                                                                                <i className="fa fa-caret-down text-red mr-1"></i>
                                                                                {Object.keys(ticketServedDetails).length > 0 ?
                                                                                    <>{ticketServedDetails.hourlyUpdatingPercentage}%</>
                                                                                    :
                                                                                    <>00%</>
                                                                                }
                                                                            </>
                                                                        ) : ticketServedDetails.hourlyUpdatingPercentage > 0 ? (
                                                                            <>
                                                                                <i className="fa fa-caret-up text-light-green mr-1"></i>
                                                                                {Object.keys(ticketServedDetails).length > 0 ?
                                                                                    <>{ticketServedDetails.hourlyUpdatingPercentage}%</>
                                                                                    :
                                                                                    <>00%</>
                                                                                }
                                                                            </>
                                                                        ) : <>
                                                                            {Object.keys(ticketServedDetails).length > 0 ?
                                                                                <>{ticketServedDetails.hourlyUpdatingPercentage}%</>
                                                                                :
                                                                                <>00%</>
                                                                            }
                                                                        </>
                                                                        }
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {ticketOperatorDetails.loggedInOperatorCount !== 0 ? (
                                            <div className="card" style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setSticky(false);
                                                    setShowOperatorStatusDetails(!showOperatorStatusDetails);
                                                    setOperatorLoginInc(operatorLoginInc + 1);
                                                }}
                                            >
                                                <div className="body">
                                                    <div>Operator Status</div>
                                                    <div className="py-4 m-0 text-center h4">
                                                        <div className='display-inline-block mr-4 text-light-green'>
                                                            <i className='fa fa-user mr-1'></i>
                                                            {Object.keys(ticketOperatorDetails).length > 0 ?
                                                                <> {ticketOperatorDetails.loggedInOperatorCount}</>
                                                                :
                                                                <>00</>
                                                            }
                                                        </div>
                                                        <div className='display-inline-block text-diseble'>
                                                            <i className='fa fa-user mr-1'></i>
                                                            {Object.keys(ticketOperatorDetails).length > 0 ?
                                                                <>{ticketOperatorDetails.notLoggedInOperatorCount}</>
                                                                :
                                                                <>00</>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                {ticketSummaryDetails.servedTickets !== 0 ? (
                                                    <div className="card" style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setSticky(false);
                                                            setShowOperatorStatusDetails(!showOperatorStatusDetails);
                                                            setOperatorLoginInc(operatorLoginInc + 1);
                                                        }}
                                                    >
                                                        <div className="body">
                                                            <div>Operator Status</div>
                                                            <div className="py-4 m-0 text-center h4">
                                                                <div className='display-inline-block mr-4 text-light-green'>
                                                                    <i className='fa fa-user mr-1'></i>
                                                                    {Object.keys(ticketOperatorDetails).length > 0 ?
                                                                        <> {ticketOperatorDetails.loggedInOperatorCount}</>
                                                                        :
                                                                        <>00</>
                                                                    }
                                                                </div>
                                                                <div className='display-inline-block text-diseble'>
                                                                    <i className='fa fa-user mr-1'></i>
                                                                    {Object.keys(ticketOperatorDetails).length > 0 ?
                                                                        <>{ticketOperatorDetails.notLoggedInOperatorCount}</>
                                                                        :
                                                                        <>00</>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="card" style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            toast.remove();
                                                            toast("No operator data yet.", {
                                                                position: 'bottom-center',
                                                                icon: '⛔'
                                                            })
                                                        }}
                                                    >
                                                        <div className="body">
                                                            <div>Operator Status</div>
                                                            <div className="py-4 m-0 text-center h4">
                                                                <div className='display-inline-block mr-4 text-light-green'>
                                                                    <i className='fa fa-eye mr-1'></i>
                                                                    {Object.keys(ticketOperatorDetails).length > 0 ?
                                                                        <>{ticketOperatorDetails.loggedInOperatorCount}</>
                                                                        :
                                                                        <>00</>
                                                                    }
                                                                </div>
                                                                <div className='display-inline-block text-diseble'>
                                                                    <i className='fa fa-eye-slash mr-1'></i>
                                                                    {Object.keys(ticketOperatorDetails).length > 0 ?
                                                                        <>{ticketOperatorDetails.notLoggedInOperatorCount}</>
                                                                        :
                                                                        <>00</>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )}



                                    </div>

                                    {/* 1st row | 2st col*/}
                                    <div className="col-lg-3 col-md-6 col-sm-12 p-l-5 p-r-5">

                                        {ticketSummaryDetails.servingTicketCount !== 0 ? (
                                            <div className="card" style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setShowTicketServingDetails(true);
                                                    setSticky(false);
                                                    setServingTicketsInc(servingTicketsInc + 1);
                                                }}>
                                                <div className={selectedBranchLevel === 4 ? "body top_counter card-body-dashboard" : "body top_counter card-body-dashboard-region"}>
                                                    <div className="content vertically-align-center-flex">
                                                        <h6>Serving ticket</h6>
                                                        {Object.keys(ticketSummaryDetails).length > 0 ?
                                                            <h5 className="number mb-0 text-light-green">{ticketSummaryDetails.servingTicketCount}</h5>
                                                            :
                                                            <h5 className="number mb-0 text-light-green">00</h5>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="card" style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    toast.remove();
                                                    toast("No serving tickets yet.", {
                                                        position: 'bottom-center',
                                                        icon: '⛔'
                                                    })
                                                }}
                                            >
                                                <div className={selectedBranchLevel === 4 ? "body top_counter card-body-dashboard" : "body top_counter card-body-dashboard-region"}>
                                                    <div className="content vertically-align-center-flex">
                                                        <h6>Serving ticket</h6>
                                                        {Object.keys(ticketSummaryDetails).length > 0 ?
                                                            <h5 className="number mb-0 text-light-green">{ticketSummaryDetails.servingTicketCount}</h5>
                                                            :
                                                            <h5 className="number mb-0 text-light-green">00</h5>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <div className="card" style={{ cursor: 'pointer' }}>
                                            {ticketOperatorDetails.transferredTicketCount !== 0 ? (
                                                <div className={selectedBranchLevel === 4 ? "body top_counter card-body-dashboard" : "body top_counter card-body-dashboard-region"}
                                                    onClick={() => {
                                                        setShowTransferTicketDetails(true);
                                                        setSticky(false);
                                                        setTransferInc(transferInc + 1);
                                                    }}
                                                >
                                                    <div className="content vertically-align-center-flex">
                                                        <h6>Transfered tickets
                                                            <Tooltip title="Transfered token count only">
                                                                <IconButton size="small">
                                                                    {/* <DeleteIcon /> */}
                                                                    <i className='fa fa-question-circle text-info' />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </h6>
                                                        <h5 className="number mb-0 text-light-green">{ticketOperatorDetails.transferredTicketCount}</h5>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className={selectedBranchLevel === 4 ? "body top_counter card-body-dashboard" : "body top_counter card-body-dashboard-region"}>
                                                    <div className="content vertically-align-center-flex"
                                                        onClick={() => {
                                                            toast.remove();
                                                            toast("No transfer tickets yet.", {
                                                                position: 'bottom-center',
                                                                icon: '⛔'
                                                            })
                                                        }}
                                                    >
                                                        <h6>Transfered tickets
                                                            <Tooltip title="Transfered token count only">
                                                                <IconButton size="small">
                                                                    {/* <DeleteIcon /> */}
                                                                    <i className='fa fa-question-circle text-info' />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </h6>
                                                        <h5 className="number mb-0 text-light-green">{ticketOperatorDetails.transferredTicketCount}</h5>
                                                    </div>
                                                </div>
                                            )}

                                        </div>

                                        {ticketOperatorDetails.waitExceptions !== 0 ? (
                                            <div className="card" style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setShowWaitTimeExceptionDetails(true);
                                                    setSticky(false);
                                                    setWatingExeptionInc(watingExeptionInc + 1);
                                                }}>
                                                <div className={selectedBranchLevel === 4 ? "body top_counter card-body-dashboard" : "body top_counter card-body-dashboard-region"}>
                                                    <div className="content vertically-align-center-flex">
                                                        <h6>Wait time exception</h6>
                                                        {Object.keys(ticketSummaryDetails).length > 0 ?
                                                            <h5 className="number mb-0 text-maroon">{ticketSummaryDetails.waitExceptions}</h5>
                                                            :
                                                            <h5 className="number mb-0 text-light-green">00</h5>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="card" style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    toast.remove();
                                                    toast("No serving tickets yet.", {
                                                        position: 'bottom-center',
                                                        icon: '⛔'
                                                    })
                                                }}
                                            >
                                                <div className={selectedBranchLevel === 4 ? "body top_counter card-body-dashboard" : "body top_counter card-body-dashboard-region"}>
                                                    <div className="content vertically-align-center-flex">
                                                        <h6>Wait time exception</h6>
                                                        {Object.keys(ticketSummaryDetails).length > 0 ?
                                                            <h5 className="number mb-0 text-maroon">{ticketSummaryDetails.waitExceptions}</h5>
                                                            :
                                                            <h5 className="number mb-0 text-light-green">00</h5>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {ticketOperatorDetails.serviceExceptions !== 0 ? (
                                            <div className="card" style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setShowServiceTimeExDetails(true);
                                                    setSticky(false);
                                                    setServiceExceptionInc(serviceExceptionInc + 1);
                                                }}>
                                                <div className={selectedBranchLevel === 4 ? "body top_counter card-body-dashboard" : "body top_counter card-body-dashboard-region"}>
                                                    <div className="content vertically-align-center-flex">
                                                        <h6>Service time exception</h6>
                                                        {Object.keys(ticketOperatorDetails).length > 0 ?
                                                            <h5 className="number mb-0 text-maroon">{ticketOperatorDetails.serviceExceptions}</h5>
                                                            :
                                                            <h5 className="number mb-0 text-light-green">00</h5>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (<div className="card" style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                toast.remove();
                                                toast("No exceptions yet.", {
                                                    position: 'bottom-center',
                                                    icon: '⛔'
                                                })
                                            }}
                                        >
                                            <div className={selectedBranchLevel === 4 ? "body top_counter card-body-dashboard" : "body top_counter card-body-dashboard-region"}>
                                                <div className="content vertically-align-center-flex">
                                                    <h6>Service time exception</h6>
                                                    {Object.keys(ticketOperatorDetails).length > 0 ?
                                                        <h5 className="number mb-0 text-maroon">{ticketOperatorDetails.serviceExceptions}</h5>
                                                        :
                                                        <h5 className="number mb-0 text-light-green">00</h5>
                                                    }
                                                </div>
                                            </div>
                                        </div>)}
                                        <div className="card">
                                            <div className="body">
                                                <div>Counter / Kiosk / Display Status</div>
                                                <div>
                                                    <div className="slider-container" style={{ cursor: 'pointer' }}>
                                                        <Carousel showArrows={true} autoPlay={true} infiniteLoop={true} interval={5000}>
                                                            {ticketSummaryDetails.loginCounters !== 0 ? (
                                                                <div
                                                                    onClick={() => {
                                                                        setShowCounterStatusDetails(true)
                                                                        setSticky(false);
                                                                        setcounterStatusInc(counterStatusInc + 1)
                                                                    }}
                                                                >
                                                                    <div className="py-4 m-0 text-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                                                        <small className='mr-3'>Counters: </small>
                                                                        <div className='display-inline-block mr-4 text-light-green h4 counter-status-margin'>
                                                                            <i className='fa fa-eye mr-1'></i>
                                                                            {Object.keys(ticketSummaryDetails).length > 0 ?
                                                                                <> {ticketSummaryDetails.loginCounters}</>
                                                                                :
                                                                                <>00</>
                                                                            }
                                                                        </div>
                                                                        <div className='display-inline-block text-diseble h4 counter-status-margin'>
                                                                            <i className='fa fa-eye-slash mr-1'></i>
                                                                            {Object.keys(ticketSummaryDetails).length > 0 ?
                                                                                <>{ticketSummaryDetails.allCounters}</>
                                                                                :
                                                                                <>00</>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    onClick={() => {
                                                                        toast.remove();
                                                                        toast("No one loged-in yet.", {
                                                                            position: 'bottom-center',
                                                                            icon: '⛔'
                                                                        })
                                                                    }}
                                                                >
                                                                    <div className="py-4 m-0 text-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                                                        <small className='mr-3'>Counters: </small>
                                                                        <div className='display-inline-block mr-4 text-light-green h4 counter-status-margin'>
                                                                            <i className='fa fa-eye mr-1'></i>
                                                                            {Object.keys(ticketSummaryDetails).length > 0 ?
                                                                                <> {ticketSummaryDetails.loginCounters}</>
                                                                                :
                                                                                <>00</>
                                                                            }
                                                                        </div>
                                                                        <div className='display-inline-block text-diseble h4 counter-status-margin'>
                                                                            <i className='fa fa-eye-slash mr-1'></i>
                                                                            {Object.keys(ticketSummaryDetails).length > 0 ?
                                                                                <>{ticketSummaryDetails.allCounters}</>
                                                                                :
                                                                                <>00</>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {ticketSummaryDetails.totalKiosk !== 0 ? (
                                                                <div
                                                                    onClick={() => {
                                                                        setShowKioskDetails(true)
                                                                        setSticky(false);
                                                                        setKioskInc(kioskInc + 1)
                                                                    }}
                                                                >
                                                                    <div className="py-4 m-0 text-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                                                        <small className='mr-3'>Kiosk: </small>
                                                                        <div className='display-inline-block mr-4 text-light-green h4 counter-status-margin'>
                                                                            <i className='fa fa-eye mr-1'></i>
                                                                            {Object.keys(ticketSummaryDetails).length > 0 ?
                                                                                <> {ticketSummaryDetails.activeKiosk}</>
                                                                                :
                                                                                <>00</>
                                                                            }
                                                                        </div>
                                                                        <div className='display-inline-block text-diseble h4 counter-status-margin'>
                                                                            <i className='fa fa-eye-slash mr-1'></i>
                                                                            {Object.keys(ticketSummaryDetails).length > 0 ?
                                                                                <>{ticketSummaryDetails.totalKiosk}</>
                                                                                :
                                                                                <>00</>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    onClick={() => {
                                                                        toast.remove();
                                                                        toast("No Kiosks.", {
                                                                            position: 'bottom-center',
                                                                            icon: '⛔'
                                                                        })
                                                                    }}
                                                                >
                                                                    <div className="py-4 m-0 text-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                                                        <small className='mr-3'>Kiosk: </small>
                                                                        <div className='display-inline-block mr-4 text-light-green h4 counter-status-margin'>
                                                                            <i className='fa fa-eye mr-1'></i>
                                                                            {Object.keys(ticketSummaryDetails).length > 0 ?
                                                                                <> {ticketSummaryDetails.activeKiosk}</>
                                                                                :
                                                                                <>00</>
                                                                            }
                                                                        </div>
                                                                        <div className='display-inline-block text-diseble h4 counter-status-margin'>
                                                                            <i className='fa fa-eye-slash mr-1'></i>
                                                                            {Object.keys(ticketSummaryDetails).length > 0 ?
                                                                                <>{ticketSummaryDetails.totalKiosk}</>
                                                                                :
                                                                                <>00</>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {ticketSummaryDetails.totalMainDisplays !== 0 ? (
                                                                <div
                                                                    onClick={() => {
                                                                        setShowMainDisplayDetails(true)
                                                                        setSticky(false);
                                                                        setMainDisplayInc(mainDisplayInc + 1)
                                                                    }}
                                                                >
                                                                    <div className="py-4 m-0 text-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                                                        <small className='mr-3'>Display: </small>
                                                                        <div className='display-inline-block mr-4 text-light-green h4 counter-status-margin'>
                                                                            <i className='fa fa-eye mr-1'></i>
                                                                            {Object.keys(ticketSummaryDetails).length > 0 ?
                                                                                <> {ticketSummaryDetails.activeMainDisplays}</>
                                                                                :
                                                                                <>00</>
                                                                            }
                                                                        </div>
                                                                        <div className='display-inline-block text-diseble h4 counter-status-margin'>
                                                                            <i className='fa fa-eye-slash mr-1'></i>
                                                                            {Object.keys(ticketSummaryDetails).length > 0 ?
                                                                                <>{ticketSummaryDetails.totalMainDisplays}</>
                                                                                :
                                                                                <>00</>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <div className="py-4 m-0 text-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                                                        <small className='mr-3'>Display: </small>
                                                                        <div className='display-inline-block mr-4 text-light-green h4 counter-status-margin'>
                                                                            <i className='fa fa-eye mr-1'></i>
                                                                            {Object.keys(ticketSummaryDetails).length > 0 ?
                                                                                <> {ticketSummaryDetails.activeMainDisplays}</>
                                                                                :
                                                                                <>00</>
                                                                            }
                                                                        </div>
                                                                        <div className='display-inline-block text-diseble h4 counter-status-margin'>
                                                                            <i className='fa fa-eye-slash mr-1'></i>
                                                                            {Object.keys(ticketSummaryDetails).length > 0 ?
                                                                                <>{ticketSummaryDetails.totalMainDisplays}</>
                                                                                :
                                                                                <>00</>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Carousel>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* {ticketSummaryDetails.loginCounters !== 0 ? (
                                            <div className="card" style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setShowCounterStatusDetails(true)
                                                    setSticky(false);
                                                    setcounterStatusInc(counterStatusInc + 1)
                                                }}
                                            >
                                                <div className="body">
                                                    <div>Counter Status</div>
                                                    <div className="py-4 m-0 text-center h4">
                                                        <div className='display-inline-block mr-4 text-light-green'>
                                                            <i className='fa fa-eye mr-1'></i>
                                                            {Object.keys(ticketSummaryDetails).length > 0 ?
                                                                <> {ticketSummaryDetails.loginCounters}</>
                                                                :
                                                                <>00</>
                                                            }
                                                        </div>
                                                        <div className='display-inline-block text-diseble'>
                                                            <i className='fa fa-eye-slash mr-1'></i>
                                                            {Object.keys(ticketSummaryDetails).length > 0 ?
                                                                <>{ticketSummaryDetails.allCounters}</>
                                                                :
                                                                <>00</>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="card" style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    toast.remove();
                                                    toast("No one loged-in yet.", {
                                                        position: 'bottom-center',
                                                        icon: '⛔'
                                                    })
                                                }}
                                            >
                                                <div className="body">
                                                    <div>Counter Status</div>
                                                    <div className="py-4 m-0 text-center h4">
                                                        <div className='display-inline-block mr-4 text-light-green'>
                                                            <i className='fa fa-eye mr-1'></i>
                                                            {Object.keys(ticketSummaryDetails).length > 0 ?
                                                                <> {ticketSummaryDetails.loginCounters}</>
                                                                :
                                                                <>00</>
                                                            }
                                                        </div>
                                                        <div className='display-inline-block text-diseble'>
                                                            <i className='fa fa-eye-slash mr-1'></i>
                                                            {Object.keys(ticketSummaryDetails).length > 0 ?
                                                                <>{ticketSummaryDetails.allCounters}</>
                                                                :
                                                                <>00</>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )} */}


                                    </div>

                                    {/* 1st row | 3st col*/}
                                    <div className="col-lg-3 col-md-6 col-sm-12 p-l-5 p-r-5">

                                        {ticketSummaryDetails.waitingTickets !== 0 ? (
                                            <div className="card" style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setShowWaitingTicketsDetails(true);
                                                    setSticky(false);
                                                    setWaitingInc(waitingInc + 1);
                                                }}>
                                                <div className="body text-center">
                                                    <div className=' text-center d-flex justify-content-center mb-3'>

                                                        {
                                                            ticketSummaryDetails.waitingTickets === 0 ?
                                                                <>
                                                                    {ticketSummaryDetails.capacityPercentage > 85 ? (
                                                                        <Donut
                                                                            style={{ fontSize: '20px' }}
                                                                            className="knob"
                                                                            diameter={80}
                                                                            min={0}
                                                                            max={1}
                                                                            step={1}
                                                                            value={ticketSummaryDetails.waitingTickets}
                                                                            theme={{
                                                                                donutThickness: 3,
                                                                                donutColor: '#dc3545',
                                                                                centerColor: '#42DEB0',
                                                                                bgrColor: '#383b40',
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <Donut
                                                                            style={{ fontSize: '20px' }}
                                                                            className="knob"
                                                                            diameter={80}
                                                                            min={0}
                                                                            max={1}
                                                                            step={1}
                                                                            value={ticketSummaryDetails.waitingTickets}
                                                                            theme={{
                                                                                donutThickness: 3,
                                                                                donutColor: '#42DEB0',
                                                                                centerColor: '#42DEB0',
                                                                                bgrColor: '#383b40',
                                                                            }}
                                                                        />
                                                                    )}
                                                                </>

                                                                :
                                                                <>
                                                                    {ticketSummaryDetails.capacityPercentage > 85 ? (
                                                                        <Donut
                                                                            style={{ fontSize: '20px' }}
                                                                            className="knob"
                                                                            diameter={80}
                                                                            min={0}
                                                                            max={ticketSummaryDetails.ticketCount}
                                                                            step={1}
                                                                            spaceMaxFromZero={false}
                                                                            value={ticketSummaryDetails.waitingTickets}
                                                                            theme={{
                                                                                donutThickness: 3,
                                                                                donutColor: '#dc3545',
                                                                                centerColor: '#42DEB0',
                                                                                bgrColor: '#383b40',
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <Donut
                                                                            style={{ fontSize: '20px' }}
                                                                            className="knob"
                                                                            diameter={80}
                                                                            min={0}
                                                                            max={ticketSummaryDetails.ticketCount}
                                                                            step={1}
                                                                            spaceMaxFromZero={false}
                                                                            value={ticketSummaryDetails.waitingTickets}
                                                                            theme={{
                                                                                donutThickness: 3,
                                                                                donutColor: '#42DEB0',
                                                                                centerColor: '#42DEB0',
                                                                                bgrColor: '#383b40',
                                                                            }}
                                                                        />
                                                                    )}
                                                                </>

                                                        }
                                                    </div>
                                                    {/* <div className="card-value float-right text-yellow">9%</div> */}
                                                    <h4 className="mb-1">Total Waiting Tickets</h4>
                                                    <div className="p-1 mb-1">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <div>Waiting:
                                                                            {ticketSummaryDetails.waitingWithoutTransferTickets !== 0 ?
                                                                                (
                                                                                    <>
                                                                                        {ticketSummaryDetails.capacityPercentage > 85 ? (
                                                                                            <span className="badge badge-danger">{ticketSummaryDetails.waitingWithoutTransferTickets}</span>
                                                                                        ) : (
                                                                                            <span className="badge badge-primary">{ticketSummaryDetails.waitingWithoutTransferTickets}</span>
                                                                                        )}
                                                                                    </>

                                                                                ) : (
                                                                                    <span className="badge badge-primary"> - </span>
                                                                                )}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div>Transfered:
                                                                            {ticketSummaryDetails.transferWaitingTickets !== 0 ?
                                                                                (
                                                                                    <span className="badge badge-warning">{ticketSummaryDetails.transferWaitingTickets}</span>
                                                                                ) : (
                                                                                    <span className="badge badge-warning"> - </span>
                                                                                )}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {selectedBranchLevel === 4 ? (
                                                        <div>Branch Seat Capacity
                                                            <Tooltip title="You can edit that in Branch Management -> Select branch -> Edit Branch -> Seating Capacity. If total waiting ticket count is getting larger, then the dashboard will indicate that to you by blinking waiting ticket bar which is below">
                                                                <IconButton size="small">
                                                                    {/* <DeleteIcon /> */}
                                                                    <i className='fa fa-question-circle text-info' />
                                                                </IconButton>
                                                            </Tooltip> : {ticketSummaryDetails.capacity}</div>
                                                    ) : (null)}
                                                </div>
                                                <div className="card-chart-bg">
                                                    {selectedBranchLevel === 4 ? (
                                                        <>
                                                            <div className="progress-bar-branch-waitings">
                                                                {ticketSummaryDetails.capacityPercentage > 85 ? (
                                                                    <div className="progress-bar bg-danger progress-bar-danger-animation" role="progressbar" aria-valuenow={60} aria-valuemin={0} aria-valuemax={100}
                                                                        style={{ width: `${ticketSummaryDetails.capacityPercentage}%` }}>
                                                                        <span className="sr-only">60% Complete (warning)</span>
                                                                    </div>
                                                                ) : ticketSummaryDetails.capacityPercentage < 85 && ticketSummaryDetails.capacityPercentage > 60 ? (
                                                                    <div className="progress-bar bg-warning progress-bar-warning-animation" role="progressbar" aria-valuenow={60} aria-valuemin={0} aria-valuemax={100}
                                                                        style={{ width: `${ticketSummaryDetails.capacityPercentage}%` }}>
                                                                        <span className="sr-only">60% Complete (warning)</span>
                                                                    </div>
                                                                ) : (
                                                                    <div className="progress-bar bg-light-green" role="progressbar" aria-valuenow={60} aria-valuemin={0} aria-valuemax={100}
                                                                        style={{ width: `${ticketSummaryDetails.capacityPercentage}%` }}>
                                                                        <span className="sr-only">60% Complete (warning)</span>
                                                                    </div>
                                                                )}

                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>
                                        ) :
                                            (<div className="card" style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    toast.remove();
                                                    toast("No Waiting tickets yet.", {
                                                        position: 'bottom-center',
                                                        icon: '⛔'
                                                    })
                                                }}
                                            >
                                                <div className="body text-center">
                                                    <div className=' text-center d-flex justify-content-center mb-3'>

                                                        {
                                                            ticketSummaryDetails.waitingTickets === 0 ?
                                                                <Donut
                                                                    style={{ fontSize: '20px' }}
                                                                    className="knob"
                                                                    diameter={80}
                                                                    min={0}
                                                                    max={1}
                                                                    step={1}
                                                                    value={ticketSummaryDetails.waitingTickets}
                                                                    theme={{
                                                                        donutThickness: 3,
                                                                        donutColor: '#42DEB0',
                                                                        centerColor: '#42DEB0',
                                                                        bgrColor: '#383b40',
                                                                    }}
                                                                /> : <Donut
                                                                    style={{ fontSize: '20px' }}
                                                                    className="knob"
                                                                    diameter={80}
                                                                    min={0}
                                                                    max={ticketSummaryDetails.ticketCount}
                                                                    step={1}
                                                                    spaceMaxFromZero={false}
                                                                    value={ticketSummaryDetails.waitingTickets}
                                                                    theme={{
                                                                        donutThickness: 3,
                                                                        donutColor: '#42DEB0',
                                                                        centerColor: '#42DEB0',
                                                                        bgrColor: '#383b40',
                                                                    }}
                                                                />
                                                            // <WaitingTicketsMultipleChart />
                                                            // :
                                                            // <WaitingTicketsMultipleChart />
                                                        }
                                                    </div>
                                                    {/* <div className="card-value float-right text-yellow">9%</div> */}
                                                    <h4 className="mb-1">Total Waiting Tickets</h4>
                                                    <div className="p-1 mb-1">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <div>Waiting:
                                                                            {ticketSummaryDetails.waitingWithoutTransferTickets !== 0 ?
                                                                                (
                                                                                    <span className="badge badge-info">{ticketSummaryDetails.waitingWithoutTransferTickets}</span>
                                                                                ) : (
                                                                                    <span className="badge badge-info"> - </span>
                                                                                )}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div>Transfered:
                                                                            {ticketSummaryDetails.transferWaitingTickets !== 0 ?
                                                                                (
                                                                                    <span className="badge badge-warning">{ticketSummaryDetails.transferWaitingTickets}</span>
                                                                                ) : (
                                                                                    <span className="badge badge-warning"> - </span>
                                                                                )}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {selectedBranchLevel === 4 ? (
                                                        <div>Branch Seat Capasity : {ticketSummaryDetails.capacity}</div>
                                                    ) : (null)}
                                                </div>
                                                <div className="card-chart-bg">
                                                    {selectedBranchLevel === 4 ? (
                                                        <>
                                                            <div className="progress-bar-branch-waitings">
                                                                {ticketSummaryDetails.capacityPercentage > 0 ? (
                                                                    <div className="progress-bar bg-danger progress-bar-danger-animation" role="progressbar" aria-valuenow={60} aria-valuemin={0} aria-valuemax={100} style={{ width: `${ticketSummaryDetails.capacityPercentage}%` }}>
                                                                        <span className="sr-only">60% Complete (warning)</span>
                                                                    </div>
                                                                ) : ticketSummaryDetails.capacityPercentage < 85 && ticketSummaryDetails.capacityPercentage > 60 ? (
                                                                    <div className="progress-bar bg-warning progress-bar-warning-animation" role="progressbar" aria-valuenow={60} aria-valuemin={0} aria-valuemax={100} style={{ width: `${ticketSummaryDetails.capacityPercentage}%` }}>
                                                                        <span className="sr-only">60% Complete (warning)</span>
                                                                    </div>
                                                                ) : (
                                                                    <div className="progress-bar bg-light-green" role="progressbar" aria-valuenow={60} aria-valuemin={0} aria-valuemax={100} style={{ width: `${ticketSummaryDetails.capacityPercentage}%` }}>
                                                                        <span className="sr-only">60% Complete (warning)</span>
                                                                    </div>
                                                                )}

                                                            </div>
                                                        </>
                                                    ) : (<></>)}
                                                </div>
                                            </div>)}

                                        {ticketSummaryDetails.localWaitingTicketCount !== 0 ? (
                                            <div className="card" style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setShowLocalWaitingDetails(true);
                                                    setSticky(false);
                                                    setLocalWaitingInc(localWaitingInc + 1);
                                                }}>
                                                <div className={selectedBranchLevel === 4 ? 'dashboard-local-waiting-card-body' : 'dashboard-local-waiting-card-body-region'}>

                                                    <div className='card-with-right-donut'>
                                                        <div>
                                                            <h6 className="mb-2 py-3">Local waiting</h6>
                                                        </div>

                                                        {
                                                            ticketSummaryDetails.ticketCount === 0 ?
                                                                <Donut
                                                                    style={{ fontSize: '20px' }}
                                                                    className="knob"
                                                                    diameter={60}
                                                                    min={0}
                                                                    max={1}
                                                                    step={1}
                                                                    value={ticketSummaryDetails.totalLocalWaitingTicketCount}
                                                                    theme={{
                                                                        donutThickness: 3,
                                                                        donutColor: '#42DEB0',
                                                                        centerColor: '#282B2F',
                                                                        bgrColor: '#383b40',
                                                                    }}
                                                                /> :
                                                                <Donut
                                                                    style={{ fontSize: '20px' }}
                                                                    className="knob"
                                                                    diameter={60}
                                                                    min={0}
                                                                    max={ticketSummaryDetails.totalLocalWaitingTicketCount}
                                                                    step={1}
                                                                    spaceMaxFromZero={false}
                                                                    value={ticketSummaryDetails.localWaitingTicketCount}
                                                                    theme={{
                                                                        donutThickness: 3,
                                                                        donutColor: '#42DEB0',
                                                                        centerColor: '#282B2F',
                                                                        bgrColor: '#383b40',
                                                                    }}
                                                                />
                                                        }
                                                    </div>
                                                    <div>Total : {ticketSummaryDetails.totalLocalWaitingTicketCount}</div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="card" style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    toast.remove();
                                                    toast("No local waiting tickets yet.", {
                                                        position: 'bottom-center',
                                                        icon: '⛔'
                                                    })
                                                }}
                                            >
                                                <div className={selectedBranchLevel === 4 ? 'dashboard-local-waiting-card-body' : 'dashboard-local-waiting-card-body-region'}>

                                                    <div className='card-with-right-donut'>
                                                        <div>
                                                            <h6 className="mb-2 py-3">Local waiting</h6>
                                                        </div>

                                                        {
                                                            ticketSummaryDetails.localWaitingTicketCount === 0 ?
                                                                <Donut
                                                                    style={{ fontSize: '20px' }}
                                                                    className="knob"
                                                                    diameter={60}
                                                                    min={0}
                                                                    max={1}
                                                                    step={1}
                                                                    value={ticketSummaryDetails.localWaitingTicketCount}
                                                                    theme={{
                                                                        donutThickness: 3,
                                                                        donutColor: '#42DEB0',
                                                                        centerColor: '#282B2F',
                                                                        bgrColor: '#383b40',
                                                                    }}
                                                                /> :
                                                                <Donut
                                                                    style={{ fontSize: '20px' }}
                                                                    className="knob"
                                                                    diameter={60}
                                                                    min={0}
                                                                    max={ticketSummaryDetails.totalLocalWaitingTicketCount}
                                                                    step={1}
                                                                    spaceMaxFromZero={false}
                                                                    value={ticketWaitingDetails.localWaitingTicketCount}
                                                                    theme={{
                                                                        donutThickness: 3,
                                                                        donutColor: '#42DEB0',
                                                                        centerColor: '#282B2F',
                                                                        bgrColor: '#383b40',
                                                                    }}
                                                                />
                                                        }
                                                    </div>
                                                    <div>Total : {ticketSummaryDetails.totalLocalWaitingTicketCount}</div>
                                                </div>
                                            </div>
                                        )}

                                        {ticketSummaryDetails.noShowTicketCount !== 0 ? (
                                            <div className="card" style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setShowNoShowTicketsDetails(true);
                                                    setSticky(false);
                                                    setNoShowInc(noShowInc + 1);
                                                }}>
                                                <div className={selectedBranchLevel === 4 ? 'dashboard-local-waiting-card-body' : 'dashboard-local-waiting-card-body-region'}>

                                                    <div className='card-with-right-donut'>
                                                        <div>
                                                            <h6 className="mb-2 py-3">No Show</h6>
                                                        </div>

                                                        {
                                                            ticketSummaryDetails.noShowTicketCount === 0 ?
                                                                <Donut
                                                                    style={{ fontSize: '20px' }}
                                                                    className="knob"
                                                                    diameter={60}
                                                                    min={0}
                                                                    max={1}
                                                                    step={1}
                                                                    value={ticketSummaryDetails.totalNoShowTicketCount}
                                                                    theme={{
                                                                        donutThickness: 3,
                                                                        donutColor: '#42DEB0',
                                                                        centerColor: '#282B2F',
                                                                        bgrColor: '#383b40',
                                                                    }}
                                                                /> :
                                                                <Donut
                                                                    style={{ fontSize: '20px' }}
                                                                    className="knob"
                                                                    diameter={60}
                                                                    min={0}
                                                                    max={ticketSummaryDetails.totalNoShowTicketCount}
                                                                    step={1}
                                                                    spaceMaxFromZero={false}
                                                                    value={ticketSummaryDetails.noShowTicketCount}
                                                                    theme={{
                                                                        donutThickness: 3,
                                                                        donutColor: '#42DEB0',
                                                                        centerColor: '#282B2F',
                                                                        bgrColor: '#383b40',
                                                                    }}
                                                                />
                                                        }
                                                    </div>
                                                    <div>Total : {ticketSummaryDetails.totalNoShowTicketCount}</div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="card" style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    toast.remove();
                                                    toast("No no show tickets yet.", {
                                                        position: 'bottom-center',
                                                        icon: '⛔'
                                                    })
                                                }}
                                            >
                                                <div className={selectedBranchLevel === 4 ? 'dashboard-local-waiting-card-body' : 'dashboard-local-waiting-card-body-region'}>

                                                    <div className='card-with-right-donut'>
                                                        <div>
                                                            <h6 className="mb-2 py-3">No Show</h6>
                                                        </div>

                                                        {
                                                            ticketSummaryDetails.noShowTicketCount === 0 ?
                                                                <Donut
                                                                    style={{ fontSize: '20px' }}
                                                                    className="knob"
                                                                    diameter={60}
                                                                    min={0}
                                                                    max={1}
                                                                    step={1}
                                                                    value={ticketSummaryDetails.noShowTicketCount}
                                                                    theme={{
                                                                        donutThickness: 3,
                                                                        donutColor: '#42DEB0',
                                                                        centerColor: '#282B2F',
                                                                        bgrColor: '#383b40',
                                                                    }}
                                                                /> :
                                                                <Donut
                                                                    style={{ fontSize: '20px' }}
                                                                    className="knob"
                                                                    diameter={60}
                                                                    min={0}
                                                                    max={ticketSummaryDetails.totalNoShowTicketCount}
                                                                    step={1}
                                                                    spaceMaxFromZero={false}
                                                                    value={ticketWaitingDetails.noShowTicketCount}
                                                                    theme={{
                                                                        donutThickness: 3,
                                                                        donutColor: '#42DEB0',
                                                                        centerColor: '#282B2F',
                                                                        bgrColor: '#383b40',
                                                                    }}
                                                                />
                                                        }
                                                    </div>
                                                    <div>Total : {ticketSummaryDetails.totalNoShowTicketCount}</div>
                                                </div>
                                            </div>
                                        )}

                                        {/* {ticketSummaryDetails.loginCounters !== 0 ? (
                                            <div className="card" style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setShowCounterStatusDetails(true)
                                                    setSticky(false);
                                                    setcounterStatusInc(counterStatusInc + 1)
                                                }}>
                                                <div className="body">

                                                    <div className={selectedBranchLevel === 4 ? 'card-with-right-donut' : 'card-with-right-donut-region'}>
                                                        <div>
                                                            <h6 className='m-0'>Counter status</h6>
                                                        </div>
                                                        {
                                                            ticketSummaryDetails.allCounters === 0 ?
                                                                <Donut
                                                                    style={{ fontSize: '20px' }}
                                                                    className="knob"
                                                                    diameter={60}
                                                                    min={0}
                                                                    max={1}
                                                                    step={1}
                                                                    value={ticketSummaryDetails.loginCounters}
                                                                    theme={{
                                                                        donutThickness: 3,
                                                                        donutColor: '#42DEB0',
                                                                        centerColor: '#282B2F',
                                                                        bgrColor: '#383b40',
                                                                    }}
                                                                /> : <Donut
                                                                    style={{ fontSize: '20px' }}
                                                                    className="knob"
                                                                    diameter={60}
                                                                    min={0}
                                                                    max={ticketSummaryDetails.allCounters}
                                                                    spaceMaxFromZero={false}
                                                                    step={1}
                                                                    value={ticketSummaryDetails.loginCounters}
                                                                    theme={{
                                                                        donutThickness: 3,
                                                                        donutColor: '#42DEB0',
                                                                        centerColor: '#282B2F',
                                                                        bgrColor: '#383b40',
                                                                    }}
                                                                />
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="card" style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    toast.remove();
                                                    toast("No counter open yet.", {
                                                        position: 'bottom-center',
                                                        icon: '⛔'
                                                    })
                                                }}
                                            >
                                                <div className="body">

                                                    <div className={selectedBranchLevel === 4 ? 'card-with-right-donut' : 'card-with-right-donut-region'}>
                                                        <div>
                                                            <h6 className='m-0'>Counter status</h6>
                                                        </div>
                                                        {
                                                            ticketSummaryDetails.allCounters === 0 ?
                                                                <Donut
                                                                    style={{ fontSize: '20px' }}
                                                                    className="knob"
                                                                    diameter={60}
                                                                    min={0}
                                                                    max={1}
                                                                    step={1}
                                                                    value={ticketSummaryDetails.loginCounters}
                                                                    theme={{
                                                                        donutThickness: 3,
                                                                        donutColor: '#42DEB0',
                                                                        centerColor: '#282B2F',
                                                                        bgrColor: '#383b40',
                                                                    }}
                                                                /> : <Donut
                                                                    style={{ fontSize: '20px' }}
                                                                    className="knob"
                                                                    diameter={60}
                                                                    min={0}
                                                                    max={ticketSummaryDetails.allCounters}
                                                                    spaceMaxFromZero={false}
                                                                    step={1}
                                                                    value={ticketSummaryDetails.loginCounters}
                                                                    theme={{
                                                                        donutThickness: 3,
                                                                        donutColor: '#42DEB0',
                                                                        centerColor: '#282B2F',
                                                                        bgrColor: '#383b40',
                                                                    }}
                                                                />
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        )} */}



                                    </div>

                                    {/* 1st row | 4st col*/}
                                    <div className="col-lg-3 col-md-6 col-sm-12 p-l-5 p-r-5">

                                        {selectedBranchLevel === 4 ? (
                                            <>
                                                <div className="card c_grid">
                                                    <div className="most-served-tickets-body">
                                                        <h6 className="mb-2 mt-2 ml-2" style={{ textAlign: 'center' }}>Most Transactions
                                                            <Tooltip title="This is indicating the teller who completed the most transactions at today. Transactions are sum of total tickets and total additional services that user has already completed">
                                                                <IconButton size="small">
                                                                    {/* <DeleteIcon /> */}
                                                                    <i className='fa fa-question-circle text-info' />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </h6>
                                                        <div className="media" style={{ margin: '0px' }}>
                                                        </div>
                                                        <table id="row-table" className="table" style={{ margin: '0px' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ background: 'transparent', borderBottom: '0px', borderTop: '0px' }}></th>
                                                                    <th style={{ background: 'transparent', borderBottom: '0px', borderTop: '0px' }}>Transactions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className='pl-1'>
                                                                <tr>
                                                                    <td style={{ borderTop: '0px' }}>
                                                                        <div className="circle align-center">
                                                                            {mostServedTellerDetails.imageUrl !== undefined ?
                                                                                <img className="rounded-circle" style={{ height: '100%', width: '60px', objectFit: 'cover' }} src={mostServedTellerDetails.imageUrl} alt="" /> :
                                                                                <img className="rounded-circle" style={{ height: '100%', width: '60px', objectFit: 'cover' }} src={require('../../assets/images/others/no-profile.jpg')} alt="avatar" />
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td style={{ borderTop: '0px' }}>
                                                                        <div className="media-body text-light-green">
                                                                            {mostServedTellerDetails.ticketCount < 10 ? (
                                                                                <div className='text-center'>
                                                                                    <div className='most-served-count-body ml-3'>
                                                                                        <h1 style={{ padding: '0 5px 0 0' }}>0{mostServedTellerDetails.ticketCount}</h1>
                                                                                        <small className='text-success'>{mostServedTellerDetails.servedTicketPercentage}%</small>
                                                                                    </div>
                                                                                    <ProgressBar variant="success" now={mostServedTellerDetails.servedTicketPercentage} style={{ height: 2 }} />
                                                                                </div>
                                                                            ) : (
                                                                                <div className='text-center'>
                                                                                    <div className='most-served-count-body ml-3'>
                                                                                        {/* <i className='fa fa-ticket text-success mr-2'>-</i> */}
                                                                                        <h1 style={{ padding: '0 5px 0 0' }}>{mostServedTellerDetails.ticketCount}</h1>
                                                                                        <small className='text-success'>{mostServedTellerDetails.servedTicketPercentage}%</small>
                                                                                    </div>
                                                                                    <ProgressBar variant="success" now={mostServedTellerDetails.servedTicketPercentage} style={{ height: 2 }} />
                                                                                </div>
                                                                            )}

                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ borderTop: '0px' }}>
                                                                        {
                                                                            mostServedTellerDetails.ticketCount !== 0 ? (
                                                                                <div className="media-body text-center">
                                                                                    <h6 className="m-0">{mostServedTellerDetails.tellerName}</h6>
                                                                                    <span className="badge badge-primary" style={{ margin: '0px' }}>
                                                                                        {mostServedTellerDetails.counter}</span>
                                                                                </div>
                                                                            ) : (<div className="media-body text-center">
                                                                                <h6 className="m-0">Not served yet.</h6>
                                                                                <span className="badge badge-disabled" style={{ margin: '0px' }}> -- </span>
                                                                            </div>
                                                                            )
                                                                        }
                                                                    </td>
                                                                    <td style={{ borderTop: '0px' }}></td>
                                                                </tr>
                                                                <tr className='p-0'>
                                                                    <td style={{ borderTop: '0px' }}>Avg. service time</td>
                                                                    {mostServedTellerDetails.ticketCount !== 0 ? (
                                                                        <td style={{ borderTop: '0px', textAlign: 'center' }}>{mostServedTellerDetails.averageServingTime} <i className="fa fa-clock-o ml-2 text-light-green"></i></td>
                                                                    ) : (
                                                                        <td style={{ borderTop: '0px', textAlign: 'center' }}>00:00:00 <i className="fa fa-clock-o ml-2 text-light-green"></i></td>
                                                                    )}
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div>

                                                    <div className='most_used_categories'>
                                                        <h6>Most Used Categories</h6>
                                                        <div className="body" style={{ height: '270px', width: '100%', overflowY: 'scroll' }}>
                                                            <table className="table table-hover table-custom spacing8 mb-0 most-used-cat">
                                                                <tbody>
                                                                    {categoryUsageList.length !== 0 ?
                                                                        (
                                                                            <>
                                                                                {categoryUsageList.map((data) =>
                                                                                    <tr data-status="approved" title={`${data.category} - ${data.regionTicketCount}`}>
                                                                                        <td className='most-used-cat-name'>{data.category}</td>
                                                                                        <td className={data.regionTicketCount !== 0 ? 'text-white bg-info text-center' : 'text-center'}>{data.regionTicketCount}</td>
                                                                                    </tr>
                                                                                )}
                                                                            </>
                                                                        ) : (<>No Categories for this branch</>)
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <div>
                                            {
                                                ticketSummaryDetails.vipTickets !== 0 ? (
                                                    <div className="card" style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setShowVIPTicketsDetails(true);
                                                            setSticky(false);
                                                            setVipTicketsInc(vipTicketsInc + 1);
                                                        }}
                                                    >
                                                        <div className={selectedBranchLevel === 4 ? "body top_counter vip-aux-padding" : "body top_counter vip-aux-padding-region"}>
                                                            <div className="icon bg-warning text-white">
                                                                <i className="fa fa-building"></i>
                                                            </div>
                                                            <div className="content vertically-align-center-flex">
                                                                <h6>VIP</h6>
                                                                <h5 className="number mb-0">{ticketSummaryDetails.vipTickets}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="card" style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            toast.remove();
                                                            toast("No VIP tickets yet.", {
                                                                position: 'bottom-center',
                                                                icon: '⛔'
                                                            })
                                                        }}
                                                    >
                                                        <div className={selectedBranchLevel === 4 ? "body top_counter vip-aux-padding" : "body top_counter vip-aux-padding-region"}>
                                                            <div className="icon bg-warning text-white">
                                                                <i className="fa fa-building"></i>
                                                            </div>
                                                            <div className="content vertically-align-center-flex">
                                                                <h6>VIP</h6>
                                                                <h5 className="number mb-0">{ticketSummaryDetails.vipTickets}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>

                                        {ticketSummaryDetails.auxCount !== 0 ? (
                                            <div className="card" style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setshowOperatorAuxDetails(true);
                                                    setSticky(false);
                                                    setauxInc(auxInc + 1);
                                                }}
                                            >
                                                <div className={selectedBranchLevel === 4 ? "body top_counter vip-aux-padding" : "body top_counter vip-aux-padding-region"}>
                                                    <div className="icon bg-info text-white">
                                                        <i className="fa fa-hourglass"></i>
                                                    </div>
                                                    <div className="content vertically-align-center-flex">
                                                        <h6>AUX / After Call</h6>
                                                        {/* <h5 className="number mb-0">{ticketSummaryDetails.vipTickets}</h5> */}
                                                        <h5 className="number mb-0">{ticketSummaryDetails.auxCount}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="card" style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    toast.remove();
                                                    toast("No after calls yet.", {
                                                        position: 'bottom-center',
                                                        icon: '⛔'
                                                    })
                                                }}
                                            >
                                                <div className={selectedBranchLevel === 4 ? "body top_counter vip-aux-padding" : "body top_counter vip-aux-padding-region"}>
                                                    <div className="icon bg-info text-white">
                                                        <i className="fa fa-hourglass"></i>
                                                    </div>
                                                    <div className="content vertically-align-center-flex">
                                                        <h6>AUX / After Call</h6>
                                                        {/* <h5 className="number mb-0">{ticketSummaryDetails.vipTickets}</h5> */}
                                                        <h5 className="number mb-0">{ticketSummaryDetails.auxCount}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {selectedBranchLevel === 4 ? (
                                            <>
                                                {ticketSummaryDetails.lobbyCount > 0 ? (
                                                    <div className="card" style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setShowLobbyDetails(true);
                                                            setSticky(false);
                                                        }}
                                                    >
                                                        <div className="body top_counter vip-aux-padding">
                                                            <div className="icon bg-info text-white">
                                                                <i className="fa fa-group"></i>
                                                            </div>
                                                            <div className="content vertically-align-center-flex">
                                                                <h6>Lobby</h6>
                                                                {/* <h5 className="number mb-0">{ticketSummaryDetails.vipTickets}</h5> */}
                                                                <h5 className="number mb-0">{ticketSummaryDetails.lobbyCount}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="card" style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            toast.remove();
                                                            toast("No tickets yet.", {
                                                                position: 'bottom-center',
                                                                icon: '⛔'
                                                            })
                                                        }}
                                                    >
                                                        <div className="body top_counter vip-aux-padding">
                                                            <div className="icon bg-info text-white">
                                                                <i className="fa fa-group"></i>
                                                            </div>
                                                            <div className="content vertically-align-center-flex">
                                                                <h6>Lobby</h6>
                                                                {/* <h5 className="number mb-0">{ticketSummaryDetails.vipTickets}</h5> */}
                                                                <h5 className="number mb-0">{ticketSummaryDetails.lobbyCount}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>

                                        ) : (null)}
                                    </div>

                                </div>


                                {/* 2st row */}
                                {selectedBranchLevel === 4 ? (
                                    <div className="row clearfix mt-4">
                                        <div className="col-lg-6 col-md-6">
                                            <div className={`card ${isTokenIssueChartFullScreen ? " fullscreen" : ""}`}>
                                                <div className="header card-header-align m-0">
                                                    <h2 style={{ fontSize: '15px', color: '#17C2D7', margin: '0px' }}>Token Status -hourly</h2>
                                                    <div className='btn btn-sm btn-default' >
                                                        <ul className="header-dropdown dropdown">
                                                            <li><span onClick={() => { setTokenIssueChartFullScreen(!isTokenIssueChartFullScreen); setSticky(false); }}
                                                                className="full-screen"><i
                                                                    className={`icon-frame ${isTokenIssueChartFullScreen ? 'icon-color-white' : ''}`}></i></span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="body">
                                                    {hourlyIssuedTicketsCount.length > 0 && chartValue > 0 ?
                                                        <TokenPerHourSparkChart
                                                            chartValue={chartValue}
                                                            chartMaxValue={chartMaxValue}
                                                            hourRanges={hourRangesBehave}
                                                            hourlyIssuedTickets={hourlyIssuedTicketsCount}
                                                            hourlyServedTickets={hourlyServedTicketsCount}
                                                            hourlyLocalWaitingTickets={hourlyLocalWaitingTicketsCount}
                                                            hourlyNoShowTickets={hourlyNoShowTicketsCount}
                                                            hourlyWaitingExceptions={hourlyWaitingExceptionTicketsCount}
                                                            hourlyServingExceptions={hourlyServiceExceptionTicketsCount}
                                                        />
                                                        :
                                                        <TokenPerHourSparkChart
                                                            hourRanges={[]}
                                                            hourlyIssuedTickets={[]}
                                                            hourlyServedTickets={[]}
                                                            hourlyLocalWaitingTickets={[]}
                                                            hourlyNoShowTickets={[]}
                                                            hourlyWaitingExceptions={[]}
                                                            hourlyServingExceptions={[]}
                                                        />
                                                    }

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-3 col-md-4 col-sm-11">
                                            <div className="header card-header-align mb-3">
                                                <h2 style={{ fontSize: '15px', color: '#17C2D7', marginBottom: '0px', fontWeight: '400' }}>Queue Length</h2>

                                                {selectedBranchQueueType === 4 ? (
                                                    <div className='btn btn-sm btn-default' title='Category Assign Page'
                                                        onClick={() => {
                                                            setSticky(false);
                                                            history('/branch-dashboard/realtime-users-by-skill', {
                                                                state: {
                                                                    selectedBranchId: selectedBranchId,
                                                                    selectedBranchName: selectedBranchName,
                                                                    selectedBranchQueueType: selectedBranchQueueType
                                                                }
                                                            }
                                                            )
                                                        }}
                                                    >
                                                        <i className=" fa fa-external-link"></i>
                                                    </div>
                                                ) : (
                                                    <div className='mt-3' style={{ width: '15px', height: '15px' }}>
                                                    </div>
                                                )}

                                            </div>
                                            <div className="body" style={{ height: '400px', overflowY: 'scroll' }}>
                                                {categoryLoadDetails.length !== 0 ?
                                                    <>
                                                        {categoryLoadDetails.map((data) =>
                                                            <>
                                                                {data.exceededStatus === 1 ?
                                                                    <div className='card' style={{ marginBottom: '10px' }}>
                                                                        <div className='body' key={data.id}>
                                                                            <div className='content vertically-align-center-flex mb-3'>
                                                                                <p style={{ margin: '0px' }}>{data.categoryName}</p>
                                                                                {selectedBranchQueueType === 4 ? (<button className='btn btn-default fix-color'
                                                                                    onClick={() => {
                                                                                        setSticky(false);
                                                                                        if (selectedBranchQueueType === 4) {
                                                                                            if (data.skillCount !== 0 && data.ticketCount !== 0) {
                                                                                                history('/branch-dashboard/realtime-users-by-skill', {
                                                                                                    state: {
                                                                                                        selectedBranchId: selectedBranchId,
                                                                                                        selectedBranchName: selectedBranchName,
                                                                                                        selectedCategoryId: data.cid,
                                                                                                        selectedLanguage: data.languageId,
                                                                                                        selectedBranchQueueType: selectedBranchQueueType
                                                                                                    }
                                                                                                }
                                                                                                )
                                                                                            } else {
                                                                                                toast.remove();
                                                                                                toast("No Skills or tickets.", {
                                                                                                    position: 'bottom-center',
                                                                                                    icon: '⛔'
                                                                                                })
                                                                                            }
                                                                                        } else {
                                                                                            toast.remove();
                                                                                            toast("Queue type not maped.", {
                                                                                                position: 'bottom-center',
                                                                                                icon: '⛔'
                                                                                            })
                                                                                        }
                                                                                    }}
                                                                                ><i className="fa fa-wrench"></i></button>) : selectedBranchQueueType === 1 ? (
                                                                                    <button className='btn btn-default fix-color'
                                                                                        onClick={() => {
                                                                                            setselectedCategory(data.id);
                                                                                            setselectedCategoryName(data.categoryName);
                                                                                            setShowCountersCategoryDetails(true);
                                                                                            setSticky(false);
                                                                                        }}
                                                                                    ><i className="fa fa-wrench"></i></button>
                                                                                ) : null}
                                                                            </div>
                                                                            <div>
                                                                                <div className="progress progress-xs">
                                                                                    <div className="progress-bar bg-danger" style={{ width: `${data.percentage}%` }}>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex bd-highlight mt-2">
                                                                                    <div className="flex-fill bd-highlight text-left">
                                                                                        <small>Remaining Seats : <span className='remain-danger'>{data.remaining}</span></small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> :
                                                                    <div className='card' style={{ marginBottom: '10px' }}>
                                                                        <div className='body' key={data.id}>
                                                                            <div className='content vertically-align-center-flex mb-3'>
                                                                                <p style={{ margin: '0px' }}>{data.categoryName}</p>
                                                                                {selectedBranchQueueType === 4 ? (
                                                                                    <button className='btn btn-default fix-color'
                                                                                        onClick={() => {
                                                                                            setSticky(false);
                                                                                            if (selectedBranchQueueType === 4) {
                                                                                                if (data.skillCount !== 0 && data.ticketCount !== 0) {
                                                                                                    history('/branch-dashboard/realtime-users-by-skill', {
                                                                                                        state: {
                                                                                                            selectedBranchId: selectedBranchId,
                                                                                                            selectedBranchName: selectedBranchName,
                                                                                                            selectedCategoryId: data.cid,
                                                                                                            selectedLanguage: data.languageId,
                                                                                                            selectedBranchQueueType: selectedBranchQueueType
                                                                                                        }
                                                                                                    }
                                                                                                    )
                                                                                                } else {
                                                                                                    toast.remove();
                                                                                                    toast("No Skills or tickets.", {
                                                                                                        position: 'bottom-center',
                                                                                                        icon: '⛔'
                                                                                                    })
                                                                                                }
                                                                                            } else {
                                                                                                toast.remove();
                                                                                                toast("Queue type not maped.", {
                                                                                                    position: 'bottom-center',
                                                                                                    icon: '⛔'
                                                                                                })
                                                                                            }
                                                                                        }}
                                                                                    ><i className="fa fa-wrench"></i></button>
                                                                                ) : selectedBranchQueueType === 1 ? (
                                                                                    <button className='btn btn-default fix-color'
                                                                                        onClick={() => {
                                                                                            setselectedCategory(data.cid);
                                                                                            setselectedCategoryName(data.categoryName);
                                                                                            setShowCountersCategoryDetails(true);
                                                                                            setSticky(false);
                                                                                        }}
                                                                                    ><i className="fa fa-wrench"></i></button>
                                                                                ) : null}
                                                                            </div>
                                                                            <div>
                                                                                <div className="progress progress-xs">
                                                                                    <div className="progress-bar bg-azura" style={{ width: `${data.percentage}%` }}>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex bd-highlight mt-2">
                                                                                    <div className="flex-fill bd-highlight text-left">
                                                                                        <small>Remaining Seats : <span style={{ color: '#42DEB0' }}>{data.remaining}</span></small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>
                                                        )}
                                                    </>
                                                    :
                                                    <><h6>No Categories added</h6></>
                                                }</div>

                                        </div>

                                        <div className="col-lg-3 col-md-4 col-sm-11">
                                            <div className="header card-header-align mb-3">
                                                <h2 style={{ fontSize: '15px', color: '#17C2D7', padding: '6px 0', margin: '0px', fontWeight: '400' }}>Most Used Categories</h2>
                                                <div style={{ width: '15px', height: '15px' }}>
                                                </div>
                                            </div>

                                            <div className="body pt-2" style={{ height: '400px', overflowY: 'scroll' }}>
                                                {categoryUsageList.length !== 0 ?
                                                    <>
                                                        {categoryUsageList.map((data) =>
                                                            <div className='card' style={{ marginBottom: '10px' }}>
                                                                <div className='body'>
                                                                    <p>{data.category}</p>
                                                                    <div className="py-1 m-0 text-center h4 text-light-green">
                                                                        {data.ticketCount}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                    :
                                                    <><h6>No Categories added</h6></>
                                                }</div>

                                        </div>

                                    </div>
                                ) : (null)}
                            </>
                        )
                    }
                </div>
            </>
            {
                showTicketIssuedDetails &&
                <TicketIssuedDetails
                    showTicketIssuedDetails={showTicketIssuedDetails} setShowTicketIssuedDetails={setShowTicketIssuedDetails}
                    selectedBranchId={selectedBranchId} ticketIssuedInc={ticketIssuedInc} messageInc1000={messageInc1000}
                    messageInc1001={messageInc1001} messageInc2000={messageInc2000} messageInc2001={messageInc2001}
                    messageInc3000={messageInc3000} messageInc3001={messageInc3001} messageInc1002={messageInc1002}
                    messageInc1011={messageInc1011}
                    messageInc4000={messageInc4000} date={date} selectedBranchLevel={selectedBranchLevel} setSticky={setSticky}
                />
            }
            {
                showServedTicketsDetails &&
                <TicketServedDetails
                    showServedTicketsDetails={showServedTicketsDetails} setShowServedTicketsDetails={setShowServedTicketsDetails}
                    selectedBranchId={selectedBranchId} servedTicketsInc={servedTicketsInc} messageInc1001={messageInc1001}
                    messageInc2001={messageInc2001} date={date} selectedBranchLevel={selectedBranchLevel} setSticky={setSticky}
                />
            }
            {
                showOperatorStatusDetails &&
                <OperatorStatusDetails
                    showOperatorStatusDetails={showOperatorStatusDetails} setShowOperatorStatusDetails={setShowOperatorStatusDetails}
                    selectedBranchId={selectedBranchId} operatorLoginInc={operatorLoginInc} messageInc9999={messageInc9999}
                    selectedBranchLevel={selectedBranchLevel}
                    messageInc1001={messageInc1001} messageInc1002={messageInc1002} messageInc9998={messageInc9998} date={date}
                    messageInc2000={messageInc2000} messageInc2001={messageInc2001} messageInc3000={messageInc3000}
                    messageInc3001={messageInc3001} setSticky={setSticky}
                />
            }

            {
                showNoShowTicketsDetails &&
                <NoShowTicketsDetails
                    showNoShowTicketsDetails={showNoShowTicketsDetails} setShowNoShowTicketsDetails={setShowNoShowTicketsDetails}
                    selectedBranchId={selectedBranchId} noShowInc={noShowInc} messageInc1001={messageInc1001} messageInc2000={messageInc2000}
                    messageInc2001={messageInc2001} messageInc3001={messageInc3001} date={date} selectedBranchLevel={selectedBranchLevel}
                    setSticky={setSticky}
                />
            }

            {showTicketServingDetails &&
                <TicketServingDetails
                    showTicketServingDetails={showTicketServingDetails} setShowTicketServingDetails={setShowTicketServingDetails}
                    selectedBranchId={selectedBranchId} date={date} messageInc1001={messageInc1001} messageInc2001={messageInc2001}
                    messageInc3001={messageInc3001} servingTicketsInc={servingTicketsInc} selectedBranchLevel={selectedBranchLevel}
                    setSticky={setSticky} messageInc1002={messageInc1002}
                />
            }

            {
                showWaitTimeExceptionDetails &&
                <WaitTimeExceptionDetails
                    showWaitTimeExceptionDetails={showWaitTimeExceptionDetails} setShowWaitTimeExceptionDetails={setShowWaitTimeExceptionDetails}
                    selectedBranchId={selectedBranchId} watingExeptionInc={watingExeptionInc} messageInc1000={messageInc1000}
                    messageInc1001={messageInc1001} date={date} messageInc1010={messageInc1010} selectedBranchLevel={selectedBranchLevel}
                    setSticky={setSticky}
                />
            }

            {
                showServiceTimeExDetails &&
                <ServiceTimeExceptionDetails
                    showServiceTimeExDetails={showServiceTimeExDetails} setShowServiceTimeExDetails={setShowServiceTimeExDetails}
                    selectedBranchId={selectedBranchId} serviceExceptionInc={serviceExceptionInc} messageInc1000={messageInc1000}
                    messageInc1001={messageInc1001} date={date} messageInc1011={messageInc1011} selectedBranchLevel={selectedBranchLevel}
                    setSticky={setSticky}
                />
            }

            {
                showWaitingTicketsDetails &&
                <WaitingTicketsDetails
                    showWaitingTicketsDetails={showWaitingTicketsDetails} setShowWaitingTicketsDetails={setShowWaitingTicketsDetails}
                    selectedBranchId={selectedBranchId} waitingInc={waitingInc} messageInc1000={messageInc1000}
                    messageInc1001={messageInc1001} date={date} selectedBranchLevel={selectedBranchLevel} setSticky={setSticky}
                />
            }

            {
                showLocalWaitingDetails &&
                <LocalWaitingDetails
                    showLocalWaitingDetails={showLocalWaitingDetails} setShowLocalWaitingDetails={setShowLocalWaitingDetails}
                    selectedBranchId={selectedBranchId} messageInc1001={messageInc1001} messageInc3000={messageInc3000}
                    messageInc3001={messageInc3001} date={date} localWaitingInc={localWaitingInc} selectedBranchLevel={selectedBranchLevel}
                    setSticky={setSticky}
                />
            }

            {
                showCounterStatusDetails &&
                <CounterStatusDetails
                    showCounterStatusDetails={showCounterStatusDetails} setShowCounterStatusDetails={setShowCounterStatusDetails}
                    selectedBranchId={selectedBranchId} messageInc9999={messageInc9999} messageInc9998={messageInc9998} messageInc1002={messageInc1002}
                    messageInc1001={messageInc1001}
                    counterStatusInc={counterStatusInc} selectedBranchLevel={selectedBranchLevel} setSticky={setSticky} date={date}
                />
            }

            {
                showVIPTicketsDetails &&
                <BranchVIPTickets
                    showVIPTicketsDetails={showVIPTicketsDetails} setShowVIPTicketsDetails={setShowVIPTicketsDetails}
                    selectedBranchId={selectedBranchId} date={date} vipTicketsInc={vipTicketsInc} messageInc1000={messageInc1000}
                    messageInc1001={messageInc1001} selectedBranchLevel={selectedBranchLevel} setSticky={setSticky}
                />
            }

            {
                showOperatorAuxDetails &&
                <OperatorsAUXDetails
                    showOperatorAuxDetails={showOperatorAuxDetails} setshowOperatorAuxDetails={setshowOperatorAuxDetails}
                    selectedBranchId={selectedBranchId} date={date} auxInc={auxInc} messageInc9998={messageInc9998}
                    messageInc9999={messageInc9999} selectedBranchLevel={selectedBranchLevel} setSticky={setSticky}
                />
            }

            {
                showLobbyDetails &&
                <LobbyDetails showLobbyDetails={showLobbyDetails} setShowLobbyDetails={setShowLobbyDetails} selectedBranchId={selectedBranchId}
                    date={date} selectedBranchLevel={selectedBranchLevel} setSticky={setSticky} messageIncLobby={messageIncLobby} messageInc1001={messageInc1001}
                />
            }

            {
                showCountersCategoryDetails &&
                <CountersCategoryDetails showCountersCategoryDetails={showCountersCategoryDetails} setShowCountersCategoryDetails={setShowCountersCategoryDetails}
                    date={date} selectedBranchLevel={selectedBranchLevel} setSticky={setSticky} selectedCategory={selectedCategory} selectedCategoryName={selectedCategoryName}
                />

            }

            {
                showTransferTicketDetails &&
                <TransferTokenData showTransferTicketDetails={showTransferTicketDetails} setShowTransferTicketDetails={setShowTransferTicketDetails}
                    date={date} selectedBranchLevel={selectedBranchLevel} setSticky={setSticky} messageInc9999={messageInc9999} transferInc={transferInc}
                    selectedBranchId={selectedBranchId} messageInc1001={messageInc1001}
                />

            }

            {
                showKioskDetails &&
                <KioskDetails showKioskDetails={showKioskDetails} setShowKioskDetails={setShowKioskDetails} date={date} selectedBranchId={selectedBranchId}
                    selectedBranchLevel={selectedBranchLevel} setSticky={setSticky} kioskInc={kioskInc} />
            }

            {
                showMainDisplayDetails &&
                <MainDisplayDetails showMainDisplayDetails={showMainDisplayDetails} setShowMainDisplayDetails={setShowMainDisplayDetails}
                    selectedBranchId={selectedBranchId} selectedBranchLevel={selectedBranchLevel} setSticky={setSticky} mainDisplayInc={mainDisplayInc}
                    date={date}
                />
            }
        </>
    );

}

export default BranchDashboard;