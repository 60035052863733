import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import Swal from "sweetalert2";

const NoSkill = () => {

    const location = useLocation();
    const history = useNavigate();

    const baseUrl = localStorage.getItem('API_URL')
    const token = localStorage.getItem('user_token')
    const selectedBranchId = location.state.selectedBranchId
    const selectedBranchName = location.state.selectedBranchName
    const noSkillTickets = location.state.noSkillTickets
    const selectedCategoryId = location.state.selectedCategoryId
    const selectedLanguage = location.state.selectedLanguage
    const selectedBranchQueueType = location.state.selectedBranchQueueType
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [clickedSortBtn, setClickedSortBtn] = useState(0);
    const [userListForRender, setUserListForRender] = useState([]);
    const [usersList, setUserList] = useState([]);
    const [selectedSkill, setSelectedSkill] = useState(0)
    const [refresh, setRefresh] = useState(0);

    // sort user list - useEffect
    useEffect(() => {
        filterAssignedUsers();
    }, [clickedSortBtn])

    useEffect(() => {
        if (selectedSkill !== 0) {
            noSkillTickets.forEach(element => {
                if (selectedSkill === element.skillId) {
                    setSelectedSkill(element.skillId);
                }
            });
        } else {
            setSelectedSkill(noSkillTickets[0].skillId);
            getUsersBySkillId(noSkillTickets[0].skillId)
        }
    }, [])


    const filterAssignedUsers = (e) => {

        if (clickedSortBtn === 0) {
            setUserListForRender(usersList);
        } else {
            const result = usersList.filter((user) => {
                return user.selected === true;
            });
            setUserListForRender(result);
        }

    };

    const getUsersBySkillId = (id) => {

        axios({
            method: 'GET',
            url: `${baseUrl}/skill/get-users/branch/${selectedBranchId}/skill/${id}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let data = res.data.data;
            setUserList(data);
            setUserListForRender(data);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    const assignUserToSkill = (user) => {

        Swal.fire({
            position: 'center',
            text: `${user.selected ? 'Do you want to unassigned this skill ? ' : 'Do you want to assigned this skill ? '}`,
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            width: '400px',

        }).then((result) => {
            if (result.value === true) {

                axios({
                    method: 'POST',
                    url: `${baseUrl}/user/assign-skill/user/${user.userId}/skill/${selectedSkill}`,
                    headers: {
                        Authorization: token
                    },

                }).then((res) => {
                    toast.remove();
                    toast.success(`${user.selected ? 'User successfully unassigned' : 'User successfully assigned'} `, {
                        position: 'bottom-center',
                    });

                    for (let i = 0; i < usersList.length; i++) {
                        const userObject = usersList[i];

                        if (userObject.userId === user.userId) {

                            if (userObject.selected === true) {
                                userObject.selected = false;
                                setRefresh(refresh + 1)
                            } else {
                                userObject.selected = true;
                                setRefresh(refresh + 1)
                            }
                            setRefresh(refresh + 1)
                        }
                    }


                }).catch((error) => {
                    console.log(error);

                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });

                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                })

            }

        })
    };

    return (
        <>
            <div><Toaster /></div>
            <div className="container-fluid">

                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h1>Skill optimizing</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to='/branch-summary'>Branch Summary</Link></li>
                                    <li className="breadcrumb-item link"
                                        onClick={() => {
                                            history('/branch-dashboard', {
                                                state: {
                                                    selectedBranchId: selectedBranchId,
                                                    selectedBranchName: selectedBranchName,
                                                    selectedBranchQueueType: selectedBranchQueueType,
                                                    selectedBranchLevel: 4
                                                }
                                            }
                                            )
                                        }}>{selectedBranchName} Dashboard</li>
                                    <li className="breadcrumb-item active" aria-current="page">Skills</li>

                                </ol>
                            </nav>
                        </div>

                    </div>
                </div>

                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="body">
                                <div className="row">

                                    <div className="col-md-7">
                                        <div className="input-group header-counter-center">
                                            <h5 className="mb-0">
                                                {/* {
                                                    usersList !== null && userListForRender !== null ?
                                                        <>
                                                            {
                                                                usersList.length !== null && userListForRender.length !== null ?
                                                                    <>
                                                                        {searchText.length > 0 ? `${userListForRender.length} / ` : ''}
                                                                        <small className="text-muted"> {usersList.length}</small>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                } */}
                                                <>
                                                    <>
                                                        {/* {searchText.length > 0 ? `${userListForRender.length} / ` : ''} */}
                                                        <small className="text-muted"> 50</small>
                                                    </>
                                                </>
                                            </h5>
                                        </div>
                                    </div>


                                    <div className="col-md-5">
                                        <div className="input-group">
                                            <input
                                                // value={searchText}
                                                // onChange={filter}
                                                type="text" className="form-control"
                                                placeholder="Search here..."
                                            />

                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="button">
                                                    <i className="fa fa-search" />
                                                </button>
                                                &nbsp;&nbsp;

                                                <button
                                                    className={`btn mr-1 ${clickedSortBtn === 0 ? 'btn-primary' : 'btn-secondary'} `}
                                                    type="button"
                                                    title="list view"
                                                    onClick={() => {
                                                        setClickedSortBtn(0);
                                                    }}
                                                >
                                                    All
                                                </button>

                                                <button
                                                    className={`btn ${clickedSortBtn === 1 ? 'btn-primary' : 'btn-secondary'}`}
                                                    type="button"
                                                    title="grid view"
                                                    style={{ borderRadius: 0 }}
                                                    onClick={() => {
                                                        setClickedSortBtn(1);
                                                    }}
                                                >
                                                    Assigned
                                                </button>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row clearfix">

                    <div className="col-lg-3 col-md-4 col-sm-12">
                        <small className="text-muted text-left">Missing Skills</small>


                        {/* {
                            noSkillTickets.map((data, i) => { */}
                        <ul className="list-group mt-2 mb-4 hovering-zoom" >
                            <div>
                                <div className='body'>
                                    <ul className="list-group mt-3 mb-4 right-custom-nav">
                                        {noSkillTickets.length > 0 ? (
                                            <>
                                                {
                                                    noSkillTickets.map((data, i) =>
                                                        <li className={`list-group-item d-flex justify-content-between align-items-center ${data.skillId === selectedSkill ? 'active' : ''}`} key={i}
                                                            onClick={() => { setSelectedSkill(data.skillId); getUsersBySkillId(data.skillId) }}>
                                                            <label >{data.skill}</label>
                                                            <span className="badge badge-info badge-hover" style={{ margin: '3px 0' }}>{data.lang}</span>
                                                        </li>
                                                    )
                                                }
                                            </>
                                        ) : (null)}
                                    </ul>
                                </div>
                            </div>
                        </ul>
                        {/* })
                        } */}

                    </div>


                    <div className="col-lg-9 col-md-8 col-sm-12">
                        <div className="row clearfix">
                            {usersList.map((data, i) =>
                                <div className="col-lg-3 col-md-15 p-l-5 p-r-5">
                                    <div className={`card c_grid ${data.status ? 'c_green' : 'c_red'} `} title={`${data.firstName} ${data.lastName}`}>
                                        <div className="body text-center ribbon" style={{ height: '180px' }}
                                            onClick={() => {
                                                assignUserToSkill(data)
                                            }}
                                        >

                                            {data.selected ?
                                                <div className="ribbon-box indigo">Assigned</div>
                                                : ""
                                            }

                                            <div className="circle sm">

                                                {data.image ?
                                                    <img
                                                        src={(data.image)}
                                                        className="personal-avatar-user " alt="avatar" />
                                                    :
                                                    <img src={require('../../assets/images/others/no-profile.jpg')}
                                                        className="personal-avatar-user " alt="avatar" />
                                                }
                                            </div>

                                            <h6 className="mt-3 mb-0 card-user-no user-card-fit-width" title={`Employee No ${data.employeeNo}`}>{data.employeeNo}</h6>
                                            <h6 className="mt-2">{data.firstName}</h6>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                </div>

            </div>



        </>
    )
}

export default NoSkill