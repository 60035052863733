import React from 'react';
import { Link } from 'react-router-dom';

const PageComingSoon = () => {
    return (
        <>
            <div className="coming-soon particles_js">
                <div className="auth_div vivify fadeInTop">
                    <div className="card">
                        <div className="body">
                            <div className="card page-400">
                                <div className="body" style={{justifyContent: 'center'}}>
                                    <p className="lead mb-3"><span className="number left">404 </span><span className="text">Oops! <br />Page Not Found</span></p>
                                    <p>The page you were looking for could not be found, please <Link to="/">contact us</Link> to report this issue.</p>
                                    <div className="margin-top-30">
                                        <Link to="/" className="btn btn-round btn-default btn-block"><i className="fa fa-arrow-left"></i> <span>Go Back</span></Link>
                                        <Link to="/" className="btn btn-round btn-primary btn-block"><i className="fa fa-home"></i> <span>Home</span></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="pattern">
                                <span className="red"></span>
                                <span className="indigo"></span>
                                <span className="blue"></span>
                                <span className="green"></span>
                                <span className="orange"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="particles-js"></div>
            </div>
        </>
    );

}
export default PageComingSoon
