import React, {Component} from 'react';
import ReactApexChart from "react-apexcharts";

class NoOfTicketsChart extends Component {
    constructor(props) {

        console.log('home chart ', props);
        console.info(props.tokenCount);
        console.info(props.xLabel);


        super(props);

        this.state = {
            series: [{
                data: props.tokenCount,
                name: "No of Tokens",
            }],
            options: {
                chart: {
                    height: 300,
                    type: 'area',
                    stacked: false,
                    animations: {
                        enabled: true,
                        easing: 'linear',
                        dynamicAnimation: {
                            speed: 1000
                        }
                    },
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: 2
                },
                grid: {
                    show: false
                },
                markers: {
                    size: 0
                },
                xaxis: {
                    type: 'category',
                    axisTicks: {show: true},
                    axisBorder: {show: false},
                    show: true,
                    labels: {show: false},
                },
                labels: props.xLabel,
                yaxis: {
                    // max: 90,
                    min: 0,
                    tickAmount: 6,
                    labels: {
                        formatter: function (value) {
                            return parseInt(value);
                        }
                    },
                    show: true,
                },
                legend: {
                    show: false
                },
            },
        };
    }

    // componentDidMount() {
    //     window.setInterval(() => {
    //         getNewSeries(lastDate, {
    //             min: 10,
    //             max: 90
    //         })
    //         ApexCharts.exec('realtime', 'updateSeries', [{
    //             data: data
    //         }])
    //     }, 1000)
    // }

    render() {
        return (
            <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type="area"
                height={this.props.chartHeight}
            />
        );
    }
}

export default NoOfTicketsChart;
