import React, {useEffect, useRef, useState} from 'react'
import {toast, ToastContainer} from 'react-toastify';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';
import CircularProgress from "@mui/material/CircularProgress";


const CustomerDataReport = ({openCustomerDetailsReport, dateFrom, dateTo, selectedRegionIds, changeRegionIds, reportUrl}) => {


    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [dynamicColsLoad, setDynamic] = useState(false);
    const [allreportsNew, setAllReportsNew] = useState([]);
    const [columns, setColumns] = useState([
        {
            label: "Date",
            name: 'issuedDate',
            options: {
                sort: true,
                filter: false,
                customBodyRender: (value) => (
                    <p style={{margin: 0, width: '100px', display: 'inline-block'}}>{value}</p>
                )
            }
        },
        {
            label: 'Branch',
            name: 'branchName',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Counter No',
            name: 'counterNo',
            options: {
                sort: true,
                filter: false,
            }
        },
        // {
        //     label: 'Counter Name',
        //     name: 'counterName',
        //     options: {
        //         sort: true,
        //         filter: false,
        //     }
        // },
        {
            label: 'Operator',
            name: 'teller',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Category",
            name: 'ticketCategory',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Ticket No",
            name: 'ticketNumber',
            options: {
                sort: true,
                filter: false,
                customBodyRender: (value) => (
                    <b>{value}</b>
                )
            }
        },
        {
            label: "Mobile No",
            name: 'mobile',
            options: {
                sort: false,
                filter: false,
                customBodyRender: (value) => (
                    <b>{value}</b>
                )
            }
        }

    ])
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const timeoutRef = useRef(null);


    const [dataTable, setDataTable] = useState({
        page: 0,
        size: 10,
        search: '',
        order: null,
    });

    const setTable = (tableState) => {
        setDataTable({
            page: tableState.page,
            search: tableState.searchText,
            size: tableState.rowsPerPage,
            order: tableState.sortOrder
        });
    };

    const options = {
        // tableBodyHeight: '55vh',
        filterType: 'dropdown',
        filter: false,
        responsive: 'vertical',
        serverSide: true,
        count: count,
        download: false,
        jumpToPage: true,
        selectableRows: 'none',
        storageKey: '_qr_cd_table',
        print: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        },
        onTableChange: (action, tableState) => {

            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want

            switch (action) {
                case 'changePage':
                    getCustomerDetailsReport(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'sort':
                    getCustomerDetailsReport(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'changeRowsPerPage':
                    setRowsPerPage(tableState.rowsPerPage);
                    getCustomerDetailsReport(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'search':
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }
                    timeoutRef.current = setTimeout(() => getCustomerDetailsReport(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage), 2000);
                    setTable(tableState);
                    break;
                default:
                    break;
            }
        },
    };


    // call getCustomerDetailsReport - useEffect
    useEffect(() => {
        if (openCustomerDetailsReport) {
            if (dateFrom.length > 0 && dateTo.length > 0) {
                getCustomerDetailsReport(0, null, null, rowsPerPage);
            }
        }
    }, [openCustomerDetailsReport, dateFrom, dateTo])

    // when change selected region array, call getCustomerDetailsReport - useEffect
    useEffect(() => {
        if (openCustomerDetailsReport) {
            getCustomerDetailsReport(0, null, null, rowsPerPage);
        }
    }, [changeRegionIds])


    // getCustomerDetailsReport - function
    const getCustomerDetailsReport = (page, sortOrder, search, size) => {
        setIsLoading(true);

        axios({
            method: 'POST',
            url: `${reportUrl}/report-controller/get-customer-data/branch/paginated/customer`,
            headers: {
                Authorization: token
            },
            data: {
                "page": page,
                "size": size,
                "dateFrom": dateFrom,
                "dateTo": dateTo,
                "sort": sortOrder,
                "searchText": search,
                branchIds: selectedRegionIds
            }

        }).then((res) => {

            let data = res.data.data;
            setAllReportsNew((data.customerFeatures.content))
            setCount(data.customerFeatures.totalElements);

            setIsLoading(false)

            if (!dynamicColsLoad && data.featureList != null) {
                setDynamic(true);

                let dynamicCols = [];
                for (let i = 0; i < data.featureList.length; i++) {
                    const label = data.featureList[i].label || (data.featureList[i].id); // If label is null or undefined, set it to id
                    dynamicCols.push({
                        label: label,
                        name: data.featureList[i].id,
                        options: {
                            sort: false,
                            filter: false,
                        }
                    });
                }

                setColumns(prevColumns => [
                    ...prevColumns,
                    ...dynamicCols
                ]);
            }


        }).catch((error) => {
            console.log(error);
            setIsLoading(false)

            if (error.response !== undefined) {
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    return (
        <>
            <div><ToastContainer/></div>

            <MUIDataTable
                title={
                    <>
                        {isLoading ?
                            <CircularProgress color="secondary" size={20}/> : <>{count} records.</>}
                    </>
                }
                data={allreportsNew}
                columns={columns}
                options={options}
                className='text-center-table'
            />

        </>
    )
}

export default CustomerDataReport
