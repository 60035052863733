const metisMenu = [
	{
		"id": 'main',
		"label": "Main"
	},
	// {
	// 	"id": 'home',
	// 	"icon": "icon-home",
	// 	"label": "Home",
	// 	"to": "/home",
	// },
	{
		"id": 'dashboard',
		"icon": "fa fa-tachometer",
		"label": "Dashboard",
		"to": "/branch-dashboard",
	},
	{
		"id": 'branch_summary',
		"icon": "icon-docs",
		"label": "Branch Summary",
		"to": "/branch-summary",
	},
	{
		"id": 'tsu_profile',
		"icon": "icon-tsu-profile",
		"label": "TSU Profile",
		"to": "/tsu-profile",
	},
	{
		"id": 'user_management',
		"icon": "icon-user",
		"label": "User Management",
		"to": "/",
		content: [
			{
				"id": 99,
				"label": "Main Users",
				"to": "/user-management/main-users"
			},
			{
				"id": 100,
				"label": "User Roles",
				"to": "/user-management/user-roles"
			}
		]
	},
	{
		"id": 'category_management',
		"icon": "icon-layers",
		"label": "Master Categories",
		"to": "/master-categories",
	},
	{
		"id": 'region_management',
		"icon": "icon-globe",
		"label": "Region Management",
		"to": "/region-management",
	},
	{
		"id": 'branch_management',
		"icon": "icon-branch",
		"label": "Branch Management",
		"to": "/branch-landing",
	},
	{
		"id": 'alert',
		"icon": "icon-notification",
		"label": "Alert",
		"to": "/",
		content: [
			{
				"id": 'sms_template',
				"label": "SMS Template",
				"to": "/alert/sms-template",
			},
			// {
			// 	"id": 'sms_group',
			// 	"label": "SMS Group",
			// 	"to": "/branch-management/sms-group",
			// },
			{
				"id": 'notification_template',
				"label": "Notification Template",
				"to": "/alert/notification-template",
			}
		]
	},
	// {
	// 	"id": 'sms_template',
	//     "icon": "icon-envelope",
	// 	"label": "SMS Template",
	// 	"to": "/sms-template",
	// },
	{
		"id": 'mattercode',
		"icon": "icon-sms-template",
		"label": "Mattercode",
		"to": "/matter-code",
	},
	{
		"id": 'analytics',
		"icon": "icon-chart",
		"label": "Analytics",
		"to": "/analytics",
	},
	// {
	// 	"id": 'notification_template',
	// 	"icon": "icon-notification",
	// 	"label": "Notification Template",
	// 	"to": "/alert/notification-template",
	// },
	{
		"id": 'reports',
		"icon": "fa fa-file-text-o",
		"label": "Reports",
		// "to": "/reports",
		"to": "/",
		content: [
			{
				"id": 'real_time_report',
				"label": "Real-time Reports",
				"to": "/reports/real-time",
			},
			{
				"id": 'queue_report',
				"label": "Queue Reports",
				"to": "/reports/queue",
			},
			{
				"id": 'token_wise_report',
				"label": "Token Wise Reports",
				"to": "/reports/token-wise",
			},
			{
				"id": 'skill_wise_report',
				"label": "Skill Wise Reports",
				"to": "/reports/skill-wise",
			},
			{
				"id": 'agent_wise_report',
				"label": "Agent Wise Reports",
				"to": "/reports/agent-wise",
			},
			{
				"id": 'branch_report',
				"label": "Branch Reports",
				"to": "/reports/branch",
			},
			{
				"id": 'category_report',
				"label": "Category Reports",
				"to": "/reports/category",
			},
		]
	},


	// -----------------------------------------------------------------------------------


	{
		"id": 'main1',
		"label": "Main1"
	},
	{
		"id": 1,
		"icon": "icon-home",
		"label": "My Page",
		"to": "/",
		content: [
			{
				"id": 2,
				"label": "My Dashboard",
				"to": "/"
			},
			{
				"id": 3,
				"label": "Web Analytics",
				"to": "/dashboard4"
			},
			{
				"id": 4,
				"label": "Event Monitoring",
				"to": "/dashboard5"
			},
			{
				"id": 5,
				"label": "Finance Performance",
				"to": "/dashboard6"
			},
			{
				"id": 6,
				"label": "Sales Monitoring",
				"to": "/dashboard7"
			},
			{
				"id": 7,
				"label": "Campaign Monitoring",
				"to": "/dashboard9"
			},
			{
				"id": 9,
				"label": "University Analytics",
				"to": "/dashboard10"
			},
			{
				"id": 10,
				"label": "eCommerce Analytics",
				"to": "/dashboard11"
			}
		]
	},
	{
		"id": 11,
		"icon": "icon-speedometer",
		"label": "Dashboard",
		"to": "/dashboard12",
	},
	{
		"id": 'app',
		"label": "App"
	},
	{
		"id": 12,
		"icon": "icon-book-open",
		"label": "Contact",
		"to": "/",
		content: [
			{
				"id": 13,
				"label": "List View",
				"to": "/app-contact"
			},
			{
				"id": 14,
				"label": "Grid View",
				"to": "/app-contact2"
			}
		]
	},
	{
		"id": 15,
		"icon": "icon-envelope",
		"label": "Email",
		"to": "/app-inbox"//
	},
	{
		"id": 16,
		"icon": "icon-bubbles",
		"label": "Messenger",
		"to": "/app-chat"
	},
	{
		"id": 17,
		"icon": "icon-bubbles",
		"label": "Project	",
		"to": "/",
		content: [
			{
				"id": 18,
				"label": "Taskboard",
				"to": "/task-board"
			},
			{
				"id": 19,
				"label": "Project List",
				"to": "/app-project-list"
			},
			{
				"id": 20,
				"label": "Ticket List",
				"to": "/app-ticket"
			},
			{
				"id": 201,
				"label": "Ticket Details",
				"to": "/app-ticket-details"
			},
			{
				"id": 202,
				"label": "Clients",
				"to": "/app-clients"
			},
			{
				"id": 203,
				"label": "Todo List",
				"to": "/app-todo"
			},
		]
	},
	{
		"id": 21,
		"icon": "icon-calendar",
		"label": "Calendar",
		"to": "/app-calendar"
	},
	{
		"id": 'ui',
		"label": "UI Elements"
	},
	{
		"id": 22,
		"icon": "icon-tag",
		"label": "Icons",
		"to": "/",
		content: [
			{
				"id": 23,
				"label": "FontAwesome",
				"to": "/ui-icons"
			},
		]
	},
	{
		"id": 26,
		"icon": "icon-diamond",
		"label": "Components",
		"to": "/",
		content: [
			{
				"id": 27,
				"label": "Bootstrap UI",
				"to": "/ui-bootstrap"
			},
			{
				"id": 28,
				"label": "Typography",
				"to": "/ui-typography"
			},
			{
				"id": 72,
				"label": "Colors",
				"to": "/ui-colors"
			},
			{
				"id": 73,
				"label": "Buttons",
				"to": "/ui-buttons"
			},
			{
				"id": 29,
				"label": "Tabs",
				"to": "/tabs"
			},
			{
				"id": 30,
				"label": "Progress Bars",
				"to": "/progressbars"
			},
			{
				"id": 31,
				"label": "Modals",
				"to": "/modals"
			},
			{
				"id": 32,
				"label": "Notifications",
				"to": "/notifications"
			},
			{
				"id": 74,
				"label": "Dialogs",
				"to": "/ui-dialogs"
			},
			{
				"id": 75,
				"label": "List Group",
				"to": "/ui-list-groups"
			},
			{
				"id": 76,
				"label": "Media Object",
				"to": "/ui-media-object"
			},
			{
				"id": 77,
				"label": "Nestable",
				"to": "/ui-nestable"
			},

			{
				"id": 33,
				"label": "Range Sliders",
				"to": "/ui-range-sliders"
			}
		]
	},
	{
		"id": 34,
		"icon": "icon-pencil",
		"label": "Forms",
		"to": "/",
		content: [
			{
				"id": 35,
				"label": "Basic Elements",
				"to": "/form/Form-basic"
			},
			{
				"id": 36,
				"label": "Advanced Elements",
				"to": "/form/form-advanced"
			},
			{
				"id": 37,
				"label": "Form Validation",
				"to": "/form/form-validation"
			},
			{
				"id": 38,
				"label": "Form Wizard",
				"to": "/form/form-wizard"
			},
			{
				"id": 39,
				"label": "Summernote",
				"to": "/form/form-summernote"
			},
			{
				"id": 40,
				"label": "Drag & Drop Upload",
				"to": "/form/form-dragdropupload"
			},
			{
				"id": 41,
				"label": "CKEditor",
				"to": "/form/form-editors"
			},
			{
				"id": 43,
				"label": "Image Cropping",
				"to": "/form/form-cropping"
			},
		]
	},
	{
		"id": 44,
		"icon": "icon-layers",
		"label": "Tables",
		"to": "/",
		content: [
			{
				"id": 45,
				"label": "Normal Tables",
				"to": "/table/table-normal"
			},
			{
				"id": 46,
				"label": "Jquery Datatables",
				"to": "/table/table-jquery-datatable"
			},
			{
				"id": 47,
				"label": "Table Filter",
				"to": "/table/table-filter"
			},
			{
				"id": 48,
				"label": "Editable Tables",
				"to": "/table/table-editable"
			},
			{
				"id": 49,
				"label": "Table dragger",
				"to": "/table/table-dragger"
			},
			{
				"id": 50,
				"label": "Tables Color",
				"to": "/table/table-color"
			},
		]
	},
	{
		"id": 51,
		"icon": "icon-pie-chart",
		"label": "Charts",
		"to": "/",
		content: [
			{
				"id": 52,
				"label": "Apex Charts",
				"to": "/chart/chart-apex"
			},
		]
	},
	{
		"id": 53,
		"icon": "icon-map",
		"label": "jVector Map",
		"to": "/app-jVector-map"
	},
	{
		"id": 'extra',
		"label": "Extra"
	},
	{
		"id": 54,
		"icon": "icon-puzzle",
		"label": "Widgets",
		"to": "/widgets"
	},
	{
		"id": 55,
		"icon": "icon-lock",
		"label": "Authentication",
		"to": "/",
		content: [
			{
				"id": 56,
				"label": "Login",
				"to": "/login1"
			},
			{
				"id": 57,
				"label": "Register",
				"to": "/signup"
			},
			{
				"id": 58,
				"label": "Forgot Password",
				"to": "/forgotpassword"
			},
			{
				"id": 59,
				"label": "Page 404",
				"to": "/notfound"
			},
			{
				"id": 60,
				"label": "Maintenance",
				"to": "/maintenance"
			},
		]
	},
	{
		"id": 61,
		"icon": "icon-docs",
		"label": "Pages",
		"to": "/",
		content: [
			{
				"id": 62,
				"label": "Page Blank",
				"to": "/page-blank"
			},
			{
				"id": 63,
				"label": "Page Profile",
				"to": "/page-profile"
			},
			{
				"id": 64,
				"label": "Page User List",
				"to": "/page-user-list"
			},
			{
				"id": 65,
				"label": "Page Testimonials",
				"to": "/page-testimonials"
			},
			{
				"id": 66,
				"label": "Page Invoices",
				"to": "/page-invoices"
			},
			{
				"id": 67,
				"label": "Page Timeline",
				"to": "/page-timeline"
			},
			{
				"id": 68,
				"label": "Page Search Results",
				"to": "/page-search-results"
			},
			{
				"id": 69,
				"label": "Page Gallery",
				"to": "/page-gallery"
			},
			{
				"id": 70,
				"label": "Page Pricing",
				"to": "/page-pricing"
			},
			{
				"id": 71,
				"label": "Page Coming Soon",
				"to": "/page-coming-soon"
			},
		]
	},
	{
		"id": 'services',
		"label": "Services"
	},
	{
		"id": 78,
		"icon": "icon-book-open",
		"label": "Implement Pages",
		"to": "/",
		content: [
			{
				"id": 79,
				"label": "Region",
				"to": "/region-management"
			},
			{
				"id": 80,
				"label": "Branch Landing",
				"to": "/branch-landing"
			},
			{
				"id": 81,
				"label": "Counters",
				"to": "/branch-management/counters"
			},
			{
				"id": 82,
				"label": "Categories",
				"to": "/branch-management/categories"
			},
			{
				"id": 83,
				"label": "Categories Master",
				"to": "/category-management/categories-master"
			},
			{
				"id": 84,
				"label": "Sub Categories",
				"to": "/category-management/sub-categories"
			},
			{
				"id": 85,
				"label": "User Roles",
				"to": "/user-management/user-roles"
			},
			{
				"id": 86,
				"label": "Users",
				"to": "/branch-management/users"
			},
			{
				"id": 87,
				"label": "User Profile",
				"to": "/user-profile"
			},
			{
				"id": 88,
				"label": "Main Users",
				"to": "/user-management/main-users"
			},
			{
				"id": 89,
				"label": "Workflow",
				"to": "/branch-management/workflow"
			},
			{
				"id": 91,
				"label": "Login-page",
				"to": "/login"
			},
			{
				"id": 92,
				"label": "Register",
				"to": "/register"
			},
			{
				"id": 93,
				"label": "SMS Template",
				"to": "/alert/sms-template"
			},
			{
				"id": 94,
				"label": "Branch Summary",
				"to": "/branch-summary"
			},
			{
				"id": 95,
				"label": "Branch Dashboard Old",
				"to": "/branch-dashboard-old"
			},
			{
				"id": 112,
				"label": "Main Displays",
				"to": "/branch-management/main-displays"
			}
		]
	},

	{
		"id": 'audit',
		"icon": "fa fa-search",
		"label": "Audit Log",
		"to": "/audit",
	},
	{
		"id": 'appointments',
		"icon": "fa fa-ticket",
		"label": "Appointments",
		"to": "/",
		content: [
			{
				"id": 'appointment_booking',
				"label": "Appointment Booking",
				"to": "/appointments/booking",
			},
			{
				"id": 'userlist',
				"label": "User List",
				"to": "/appointments/user-list",
			}
		]
	},


	// ----------------------------------------





];

export default metisMenu