import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, useNavigate} from "react-router-dom";
import {setOffcanvas, toggleMegamenu, toggleNotificationBar, toggleSearchBar} from '../../actions/settingsAction';
import axios from "axios";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
//import Notifications from '../Components/Notifications';
import {Slide, toast as toastify, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Paho from 'paho-mqtt'


const Header = ({ toggleMegamenu, isMegaMenu, toggleNotificationBar, toggleSearchBar, setOffcanvas, offcanvas }) => {

    let client = null;
    let configs = JSON.parse(localStorage.getItem("configuration_data"));

    const navigate = useNavigate();

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');

    const mqttIp = configs !== null ? configs['ip'] : '';
    const mqttPort = configs !== null ? configs['port'] : '';
    const mqttUsername = configs !== null ? configs['mq-username'] : '';
    const mqttPassword = configs !== null ? configs['mq-password'] : '';
    const ssl = configs !== null ? configs['use-ssl'] === 'true' : false;

    const userDetails = JSON.parse(localStorage.getItem('user_details'))

    const [noOfNotifications, setNoOfNotifications] = useState([]);
    const [fourNotifications, setFourNotifications] = useState([]);


    // check user token and user details
    useEffect(() => {
        if (token === null || userDetails === null) {
            navigate('/');
        }
        if (localStorage.getItem("configuration_data") === undefined)
            configurationData();
    }, [Header]);



    // mqtt config - useEffect
    useEffect(() => {

        connect();
        return () => {
            if (client !== null && client.isConnected()) {
                client.unsubscribe(`alert/notify/user/${userDetails.uid}`)
                client.disconnect();
                client = null;
            }
        }
    }, [Header])

    useEffect(() => {
        if (token !== null && userDetails !== null) {
            getNotifications()
            getCount()
        }

        // returned function will be called on component unmount
        return () => {
            if (client !== null && client.isConnected()) {
                client.unsubscribe(`alert/notify/user/${userDetails.uid}`)
                client.unsubscribe(`alert/update/notification/user/${userDetails.uid}`)
                client.disconnect();
                client = null;
            }
        }
    }, [])

    const onConnect = () => {
        // Once a connection has been made, make a subscription and send a message.
        client.subscribe(`alert/notify/user/${userDetails.uid}`);
        client.subscribe(`alert/update/notification/user/${userDetails.uid}`);
    }

    // called when the client loses its connection (mqtt)
    const onConnectionLost = (responseObject) => {
        if (responseObject.errorCode !== 0) {
            // console.log("onConnectionLost:" + responseObject.errorMessage);
        }
    }

    // called when a message arrives (mqtt)
    const onMessageArrived = (message) => {
        // console.log("onMessageArrived:" + message.payloadString);
        executeFunctions(message.payloadString);
    }

    const toastMessageCall = (title, content, position, type) => {
        // console.log("toastMessageCall");
        toastify(
            <><b className='toast-title'>{title}</b><p className='toast-content'>{content}</p></>,
            { type, autoClose: false, position, transition: Slide, closeButton: true });
    }

    // navigate to notification manager
    const navigateToNotificationManager = () => {
        navigate('/notification-manager')
    }

    const connect = () => {
        // client = new Paho.Client('103.1.179.245', Number(9005), 'mqttjs_' + Math.random().toString(16).substr(2, 8));
        client = new Paho.Client(mqttIp, Number(mqttPort), 'mqttjs_' + Math.random().toString(16).substr(2, 8));

        // set callback handlers (mqtt)
        client.onConnectionLost = onConnectionLost;
        client.onMessageArrived = onMessageArrived;
        client.connect({
            onSuccess: onConnect,
            userName: mqttUsername,
            password: mqttPassword,
            reconnect: true,
            useSSL: ssl
        });
    }

    const configurationData = () => {
        axios({
            method: 'POST',
            url: `${baseUrl}/branch/get-configs`,
            headers: {
                Authorization: localStorage.getItem("user_token")
            },
            data: {
                response: [
                    "mq-password", "mqtt", "web-socket", "mq-username", "ip", "port", "use-ssl"
                ]
            }

        }).then((res) => {
            let data = res.data.data;
            localStorage.setItem('configuration_data', JSON.stringify(data));
            configs = data;
            //connect();
        }).catch((error) => {
            console.log(error);

            if (error.response.status === 401) {
                navigate('/');
                toast.remove();
                toast.error('Unauthorized', {
                    position: 'bottom-center',
                });
                return;
            }
        })
    }


    // scroll progress bar
    const [scrolled, setScrolled] = useState(0);

    const scrollProgress = () => {
        const scrollPx = document.documentElement.scrollTop;
        const winHeightPx =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight;
        const scrolleda = `${scrollPx / winHeightPx * 100}%`;
        setScrolled(scrolleda)
    };
    window.addEventListener("scroll", scrollProgress);

    const progressContainerStyle = {
        background: "#f8bbd0",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
        height: "2px",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        zIndex: 99
    };

    const progressBarStyle = {
        width: scrolled
    };


    // get notification - function
    const getNotifications = () => {
        axios({
            method: 'GET',
            url: `${baseUrl}/alert/notification/get-list/latest/user/${userDetails.uid}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let data = res.data.data;
            setFourNotifications(data);

        }).catch((error) => {
            console.log(error);

            if (error.response.status === 401) {
                navigate('/');
                toast.remove();
                toast.error('Unauthorized', {
                    position: 'bottom-center',
                });
                return;
            }

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // get notification count - functionn
    const getCount = () => {
        axios({
            method: 'GET',
            url: `${baseUrl}/alert/notification/get-count/unread/user/${userDetails.uid}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            let count = res.data.data;
            setNoOfNotifications(count.unreadCount);
        }).catch((error) => {
            console.log(error);
            if (error.response !== undefined) {

                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {

                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // user logout - function
    const userLogOut = (e) => {
        e.preventDefault();

        Swal.fire({
            position: 'center',
            icon: 'question',
            text: 'Do you want to Logout?',
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            width: '400px',

        }).then((result) => {
            if (result.value === true) {
                axios({
                    method: 'POST',
                    url: `${baseUrl}/user/logout`,
                    headers: {
                        Authorization: token
                    },
                    data: {
                        "userId": userDetails.uid,
                        "operatorLoginId": userDetails.operatorLoginId,
                    }

                })
                    .then(response => {

                        toast.remove();
                        toast.success('User Successfully Logout!',
                            {
                                position: 'bottom-center',
                            });
                        localStorage.removeItem('user_token')
                        localStorage.removeItem('user_details')

                        navigate('/');

                    }).catch((error) => {
                        console.log(error);
                        if (error.response !== undefined) {
                            toast.remove();
                            toast.error(error.response.data.message, {
                                position: 'bottom-center',
                            });
                        } else {
                            toast.remove();
                            toast.error('Something went wrong', {
                                position: 'bottom-center',
                            })
                        }
                    })
            }
        })


    };

    const executeFunctions = (messageData) => {
        let message = JSON.parse(messageData);
        if (messageData != '4') {
            toastMessageCall(`${message.title}`, `${message.content}`, "top-right", 'dark')
        }
        getNotifications()
        getCount()
    }


    return (
        <>
            <ToastContainer />
            <nav className="navbar top-navbar">
                <div className="container-fluid">
                    <div className="navbar-left">
                        <div className="navbar-btn">
                            <Link to="/home"><img src={'../../assets/images/TS-Logo.png'} alt="TS Logo"
                                className="img-fluid logo" /></Link>
                            <button type="button" className="btn-toggle-offcanvas"
                                onClick={() => setOffcanvas(!offcanvas)}><i className="lnr lnr-menu fa fa-bars"></i>
                            </button>
                        </div>

                        {userDetails !== null ? (
                            <>
                                {
                                    userDetails.branchLevel === 4 ? (
                                        <ul className="nav navbar-nav">


                                            <li className="dropdown">
                                                <span className="dropdown-toggle icon-menu" data-toggle="dropdown">
                                                    <i className="icon-bell"></i>
                                                    {
                                                        noOfNotifications != 0 ? (<span className="notification-dot bg-azura">{noOfNotifications}</span>)
                                                            :
                                                            null
                                                    }

                                                </span>
                                                <ul className="dropdown-menu feeds_widget vivify fadeIn">
                                                    <li className="header blue">You have {noOfNotifications} New Notifications</li>
                                                    {

                                                        fourNotifications.length > 0 ? (
                                                            fourNotifications.map((item, index) => (
                                                                <li key={index} className={`${item.readStatus === false ? 'header-unread-notifi' : ''}`}>
                                                                    <a href="/notification-manager">
                                                                        <div className={`feeds-left icon-notificationtype${item.notificationType}`} ><i className="fa fa-check"></i></div>
                                                                        <div className="feeds-body">
                                                                            <h4 className={`title text-notificationtype${item.notificationType}`}>{item.notificationType}<small
                                                                                className="float-right text-muted">{item.timeAgo}</small></h4>
                                                                            <small>{item.content}</small>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                            ))
                                                        ) : (

                                                            <p className="notification-shade-empty ">No Notifications</p>

                                                        )
                                                    }
                                                    <a className="read-more-link" onClick={() => { navigateToNotificationManager() }}>See more</a>
                                                    {/* <button onClick={() => { navigateToNotificationManager() }}>See more </button> */}
                                                </ul>

                                            </li>

                                        </ul>
                                    ) : null
                                }
                            </>
                        ) : null}


                    </div>

                    <div className="navbar-right">
                        <div id="navbar-menu">
                            <ul className="nav navbar-nav">

                                {/* <li><span onClick={() => toggleSearchBar(true)} className="search_toggle icon-menu"
                                          title="Search Result"><i className="icon-magnifier"></i></span></li>
                                <li><span onClick={() => toggleNotificationBar(true)} className="right_toggle icon-menu"
                                          title="Right Menu"><i className="icon-bubbles"></i><span
                                    className="notification-dot bg-pink">2</span></span></li> */}

                                <li><span onClick={(e) => userLogOut(e)} className=" icon-menu">
                                    <i className="fa fa-sign-out"></i></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="progress-container">
                    <div style={progressBarStyle} className="progress-bar" id="myBar"></div>
                </div>
            </nav>
        </>
    );
}

const mapStateToProps = state => ({
    isMegaMenu: state.settings.isMegaMenu,
    offcanvas: state.settings.offcanvas,
})

const mapDispatchToProps = dispatch => ({
    toggleMegamenu: (e, t) => dispatch(toggleMegamenu(e, t)),
    toggleSearchBar: (e) => dispatch(toggleSearchBar(e)),
    toggleNotificationBar: (e) => dispatch(toggleNotificationBar(e)),
    setOffcanvas: (e) => dispatch(setOffcanvas(e)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Header);