import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import toast from 'react-hot-toast';
import Button from 'react-bootstrap-button-loader';
import axios from 'axios';

const timeFormat = 'HH:mm:ss';

// used for add and edit
const AssignCategoryTimeModal = ({ showAssignCategoryTimeModal, setShowAssignCategoryTimeModal, getAllCategoriesByBranch, selectedRow, setSelectedRow }) => {


    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const selectedBranch = JSON.parse(localStorage.getItem('selected_branch'));

    const [buttonLoader, setButtonLoader] = useState(false);

    const [isAllDays, setIsAllDays] = useState(false);
    const [monOpenTime, setMonOpenTime] = useState('');
    const [monCloseTime, setMonCloseTime] = useState('');
    const [tuesOpenTime, setTuesOpenTime] = useState('');
    const [tuesCloseTime, setTuesCloseTime] = useState('');
    const [wedOpenTime, setWedOpenTime] = useState('');
    const [wedCloseTime, setWedCloseTime] = useState('');
    const [thursOpenTime, setThursOpenTime] = useState('');
    const [thursCloseTime, setThursCloseTime] = useState('');
    const [friOpenTime, setFriOpenTime] = useState('');
    const [friCloseTime, setFriCloseTime] = useState('');
    const [satOpenTime, setSatOpenTime] = useState('');
    const [satCloseTime, setSatCloseTime] = useState('');
    const [sunOpenTime, setSunOpenTime] = useState('');
    const [sunCloseTime, setSunCloseTime] = useState('');

    const [getData, setGetData] = useState({});
    const [dateTimes, setDateTimes] = useState([]);

    // for time picker
    var date = "2022-06-02";
    var time = "00:00:00";
    var timeAndDate = moment(date + ' ' + time);
    const [timePickerDefaultValue, setTimePickerDefaultValue] = useState(timeAndDate);


    // call getDateTimeOfCategory - useEffect
    useEffect(() => {
        if (showAssignCategoryTimeModal) {
            if (Object.keys(selectedRow).length > 0) {
                if (baseUrl.length > 0 && token.length > 0) {
                    getDateTimeOfCategory();
                }
            }
        }
    }, [showAssignCategoryTimeModal])

    // set getDateTimeOfCategory - useEffect
    useEffect(() => {
        if (dateTimes.length > 0) {
            if (dateTimes[0].openTime !== undefined) setSunOpenTime(dateTimes[0].openTime);
            if (dateTimes[0].closeTime !== undefined) setSunCloseTime(dateTimes[0].closeTime);
            if (dateTimes[1].openTime !== undefined) setMonOpenTime(dateTimes[1].openTime);
            if (dateTimes[1].closeTime !== undefined) setMonCloseTime(dateTimes[1].closeTime);
            if (dateTimes[2].openTime !== undefined) setTuesOpenTime(dateTimes[2].openTime);
            if (dateTimes[2].closeTime !== undefined) setTuesCloseTime(dateTimes[2].closeTime);
            if (dateTimes[3].openTime !== undefined) setWedOpenTime(dateTimes[3].openTime);
            if (dateTimes[3].closeTime !== undefined) setWedCloseTime(dateTimes[3].closeTime);
            if (dateTimes[4].openTime !== undefined) setThursOpenTime(dateTimes[4].openTime);
            if (dateTimes[4].closeTime !== undefined) setThursCloseTime(dateTimes[4].closeTime);
            if (dateTimes[5].openTime !== undefined) setFriOpenTime(dateTimes[5].openTime);
            if (dateTimes[5].closeTime !== undefined) setFriCloseTime(dateTimes[5].closeTime);
            if (dateTimes[6].openTime !== undefined) setSatOpenTime(dateTimes[6].openTime);
            if (dateTimes[6].closeTime !== undefined) setSatCloseTime(dateTimes[6].closeTime);
        }
        setIsAllDays(getData.allDay);
    }, [dateTimes])



    // getDateTimeOfCategory - function
    const getDateTimeOfCategory = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/category-time/get-date-time-details/category/${selectedRow.id}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            let data = res.data.data;
            setGetData(data);
            setDateTimes(data.dateTimes);

        }).catch((error) => {

            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })

    }

    // save button action handle - function
    const handleAction = () => {
        if (isAllDays) {
            assignCategoryTimeAll();
        } else {
            assignCategoryTimeManually();
        }
    }

    // assignCategoryTimeManually - function
    const assignCategoryTimeManually = () => {

        setButtonLoader(true);

        axios({
            method: 'POST',
            url: `${baseUrl}/category-time/add`,
            headers: {
                Authorization: token
            },
            data: {
                "categoryId": selectedRow.id,
                "monday": {
                    "closeTime": monCloseTime,
                    "openTime": monOpenTime
                },
                "tuesday": {
                    "closeTime": tuesCloseTime,
                    "openTime": tuesOpenTime
                },
                "wednesday": {
                    "closeTime": wedCloseTime,
                    "openTime": wedOpenTime
                },
                "thursday": {
                    "closeTime": thursCloseTime,
                    "openTime": thursOpenTime
                },
                "friday": {
                    "closeTime": friCloseTime,
                    "openTime": friOpenTime
                },
                "saturday": {
                    "closeTime": satCloseTime,
                    "openTime": satOpenTime
                },
                "sunday": {
                    "closeTime": sunCloseTime,
                    "openTime": sunOpenTime
                }
            }
        }).then((res) => {

            setButtonLoader(false);
            closeModal();
            // getAllCategoriesByBranch();
            toast.remove();
            toast.success('Category time successfully assigned', {
                position: 'bottom-center',
            });

        }).catch((error) => {
            console.log(error);
            setButtonLoader(false);

            if (error.response !== undefined) {
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })

    }

    // assignCategoryTimeAll - function
    const assignCategoryTimeAll = () => {
        setButtonLoader(true);

        axios({
            method: 'POST',
            url: `${baseUrl}/category/set-all-day/category/${selectedRow.id}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            setButtonLoader(false);
            closeModal();
            // getAllCategoriesByBranch();
            toast.remove();
            toast.success('Category time successfully assigned', {
                position: 'bottom-center',
            });

        }).catch((error) => {

            console.log(error);
            setButtonLoader(false);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    // closeModal - function
    const closeModal = () => {
        setIsAllDays(false);
        setMonOpenTime('');
        setMonCloseTime('');
        setTuesOpenTime('');
        setTuesCloseTime('');
        setWedOpenTime('');
        setWedCloseTime('');
        setThursOpenTime('');
        setThursCloseTime('');
        setFriOpenTime('');
        setFriCloseTime('');
        setSatOpenTime('');
        setSatCloseTime('');
        setSunOpenTime('');
        setSunCloseTime('');

        setSelectedRow({});

        setShowAssignCategoryTimeModal(false);
    }

    return (
        <>
            {showAssignCategoryTimeModal ? (
                <div className={`modal fade d-block show modal-container`} id="category-modal">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Assign Category Time</h5>

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">

                                <div>
                                    <p className='input-field-side-text display-inline-block float-left'>Available 24/7 : </p>
                                    <div className="display-inline-block">
                                        <label className="switch">
                                            <input type="checkbox"
                                                defaultChecked={dateTimes.allDay === true}
                                                checked={isAllDays ? true : false}
                                                onChange={(e) => {
                                                    setIsAllDays(prev => !prev);
                                                }} />
                                            <span className="slider"></span>
                                        </label>
                                    </div>
                                </div>

                                {isAllDays ?
                                    <></>
                                    :

                                    <Tabs id="uncontrolled-tab-example" className="mt-3">

                                        <Tab eventKey="1" title="Monday" tabClassName="custom_tab">
                                            <div className="container-fluid">

                                                <div className="row clearfix">

                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="input-group mb-3">
                                                            <p className="input-field-side-text">Opening Time
                                                                : </p>
                                                            <TimePicker
                                                                value={monOpenTime.length > 0 ? moment(monOpenTime, timeFormat) : ''}
                                                                showSecond={true}
                                                                placeholder="HH:mm:ss"
                                                                className="custom-select modal-select-field"
                                                                defaultOpenValue={timePickerDefaultValue}
                                                                onChange={(value) => {
                                                                    if (value === null) {
                                                                        setMonOpenTime('');
                                                                    } else {
                                                                        setMonOpenTime(value.format("HH:mm:ss"));
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="input-group mb-3">
                                                            <p className="input-field-side-text">Close Time
                                                                : </p>
                                                            <TimePicker
                                                                value={monCloseTime.length > 0 ? moment(monCloseTime, timeFormat) : ''}
                                                                showSecond={true}
                                                                placeholder="HH:mm:ss"
                                                                className="custom-select modal-select-field"
                                                                defaultOpenValue={timePickerDefaultValue}
                                                                onChange={(value) => {
                                                                    if (value === null) {
                                                                        setMonCloseTime('');
                                                                    } else {
                                                                        setMonCloseTime(value.format("HH:mm:ss"));
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>


                                                </div>

                                            </div>
                                        </Tab>
                                        <Tab eventKey="2" title="Tuesday" tabClassName="custom_tab">
                                            <div className="container-fluid">

                                                <div className="row clearfix">

                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="input-group mb-3">
                                                            <p className="input-field-side-text">Opening Time
                                                                : </p>

                                                            <TimePicker
                                                                value={tuesOpenTime.length > 0 ? moment(tuesOpenTime, timeFormat) : ''}
                                                                showSecond={true}
                                                                placeholder="HH:mm:ss"
                                                                className="custom-select modal-select-field"
                                                                defaultOpenValue={timePickerDefaultValue}
                                                                onChange={(value) => {
                                                                    if (value === null) {
                                                                        setTuesOpenTime('');
                                                                    } else {
                                                                        setTuesOpenTime(value.format("HH:mm:ss"));
                                                                    }
                                                                }}
                                                            />

                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="input-group mb-3">
                                                            <p className="input-field-side-text">Close Time
                                                                : </p>

                                                            <TimePicker
                                                                value={tuesCloseTime.length > 0 ? moment(tuesCloseTime, timeFormat) : ''}
                                                                showSecond={true}
                                                                placeholder="HH:mm:ss"
                                                                className="custom-select modal-select-field"
                                                                defaultOpenValue={timePickerDefaultValue}
                                                                onChange={(value) => {
                                                                    if (value === null) {
                                                                        setTuesCloseTime('');
                                                                    } else {
                                                                        setTuesCloseTime(value.format("HH:mm:ss"));
                                                                    }
                                                                }}
                                                            />

                                                        </div>
                                                    </div>


                                                </div>

                                            </div>
                                        </Tab>
                                        <Tab eventKey="3" title="Wednesday" tabClassName="custom_tab">
                                            <div className="container-fluid">

                                                <div className="row clearfix">

                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="input-group mb-3">
                                                            <p className="input-field-side-text">Opening Time
                                                                : </p>

                                                            <TimePicker
                                                                value={wedOpenTime.length > 0 ? moment(wedOpenTime, timeFormat) : ''}
                                                                showSecond={true}
                                                                placeholder="HH:mm:ss"
                                                                className="custom-select modal-select-field"
                                                                defaultOpenValue={timePickerDefaultValue}
                                                                onChange={(value) => {
                                                                    if (value === null) {
                                                                        setWedOpenTime('');
                                                                    } else {
                                                                        setWedOpenTime(value.format("HH:mm:ss"));
                                                                    }
                                                                }}
                                                            />

                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="input-group mb-3">
                                                            <p className="input-field-side-text">Close Time
                                                                : </p>

                                                            <TimePicker
                                                                value={wedCloseTime.length > 0 ? moment(wedCloseTime, timeFormat) : ''}
                                                                showSecond={true}
                                                                placeholder="HH:mm:ss"
                                                                className="custom-select modal-select-field"
                                                                defaultOpenValue={timePickerDefaultValue}
                                                                onChange={(value) => {
                                                                    if (value === null) {
                                                                        setWedCloseTime('');
                                                                    } else {
                                                                        setWedCloseTime(value.format("HH:mm:ss"));
                                                                    }
                                                                }}
                                                            />

                                                        </div>
                                                    </div>


                                                </div>

                                            </div>
                                        </Tab>
                                        <Tab eventKey="4" title="Thursday" tabClassName="custom_tab">
                                            <div className="container-fluid">

                                                <div className="row clearfix">

                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="input-group mb-3">
                                                            <p className="input-field-side-text">Opening Time
                                                                : </p>

                                                            <TimePicker
                                                                value={thursOpenTime.length > 0 ? moment(thursOpenTime, timeFormat) : ''}
                                                                showSecond={true}
                                                                placeholder="HH:mm:ss"
                                                                className="custom-select modal-select-field"
                                                                defaultOpenValue={timePickerDefaultValue}
                                                                onChange={(value) => {
                                                                    if (value === null) {
                                                                        setThursOpenTime('');
                                                                    } else {
                                                                        setThursOpenTime(value.format("HH:mm:ss"));
                                                                    }
                                                                }}
                                                            />

                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="input-group mb-3">
                                                            <p className="input-field-side-text">Close Time
                                                                : </p>

                                                            <TimePicker
                                                                value={thursCloseTime.length > 0 ? moment(thursCloseTime, timeFormat) : ''}
                                                                showSecond={true}
                                                                placeholder="HH:mm:ss"
                                                                className="custom-select modal-select-field"
                                                                defaultOpenValue={timePickerDefaultValue}
                                                                onChange={(value) => {
                                                                    if (value === null) {
                                                                        setThursCloseTime('');
                                                                    } else {
                                                                        setThursCloseTime(value.format("HH:mm:ss"));
                                                                    }
                                                                }}
                                                            />

                                                        </div>
                                                    </div>


                                                </div>

                                            </div>
                                        </Tab>
                                        <Tab eventKey="5" title="Friday" tabClassName="custom_tab">
                                            <div className="container-fluid">

                                                <div className="row clearfix">

                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="input-group mb-3">
                                                            <p className="input-field-side-text">Opening Time
                                                                : </p>

                                                            <TimePicker
                                                                value={friOpenTime.length > 0 ? moment(friOpenTime, timeFormat) : ''}
                                                                showSecond={true}
                                                                placeholder="HH:mm:ss"
                                                                className="custom-select modal-select-field"
                                                                defaultOpenValue={timePickerDefaultValue}
                                                                onChange={(value) => {
                                                                    if (value === null) {
                                                                        setFriOpenTime('');
                                                                    } else {
                                                                        setFriOpenTime(value.format("HH:mm:ss"));
                                                                    }
                                                                }}
                                                            />

                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="input-group mb-3">
                                                            <p className="input-field-side-text">Close Time
                                                                : </p>

                                                            <TimePicker
                                                                value={friCloseTime.length > 0 ? moment(friCloseTime, timeFormat) : ''}
                                                                showSecond={true}
                                                                placeholder="HH:mm:ss"
                                                                className="custom-select modal-select-field"
                                                                defaultOpenValue={timePickerDefaultValue}
                                                                onChange={(value) => {
                                                                    if (value === null) {
                                                                        setFriCloseTime('');
                                                                    } else {
                                                                        setFriCloseTime(value.format("HH:mm:ss"));
                                                                    }
                                                                }}
                                                            />

                                                        </div>
                                                    </div>


                                                </div>

                                            </div>
                                        </Tab>
                                        <Tab eventKey="6" title="Saturday" tabClassName="custom_tab">
                                            <div className="container-fluid">

                                                <div className="row clearfix">

                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="input-group mb-3">
                                                            <p className="input-field-side-text">Opening Time
                                                                : </p>

                                                            <TimePicker
                                                                value={satOpenTime.length > 0 ? moment(satOpenTime, timeFormat) : ''}
                                                                showSecond={true}
                                                                placeholder="HH:mm:ss"
                                                                className="custom-select modal-select-field"
                                                                defaultOpenValue={timePickerDefaultValue}
                                                                onChange={(value) => {
                                                                    if (value === null) {
                                                                        setSatOpenTime('');
                                                                    } else {
                                                                        setSatOpenTime(value.format("HH:mm:ss"));
                                                                    }
                                                                }}
                                                            />

                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="input-group mb-3">
                                                            <p className="input-field-side-text">Close Time
                                                                : </p>

                                                            <TimePicker
                                                                value={satCloseTime.length > 0 ? moment(satCloseTime, timeFormat) : ''}
                                                                showSecond={true}
                                                                placeholder="HH:mm:ss"
                                                                className="custom-select modal-select-field"
                                                                defaultOpenValue={timePickerDefaultValue}
                                                                onChange={(value) => {
                                                                    if (value === null) {
                                                                        setSatCloseTime('');
                                                                    } else {
                                                                        setSatCloseTime(value.format("HH:mm:ss"));
                                                                    }
                                                                }}
                                                            />

                                                        </div>
                                                    </div>


                                                </div>

                                            </div>
                                        </Tab>
                                        <Tab eventKey="7" title="Sunday" tabClassName="custom_tab">
                                            <div className="container-fluid">

                                                <div className="row clearfix">

                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="input-group mb-3">
                                                            <p className="input-field-side-text">Opening Time
                                                                : </p>

                                                            <TimePicker
                                                                value={sunOpenTime.length > 0 ? moment(sunOpenTime, timeFormat) : ''}
                                                                showSecond={true}
                                                                placeholder="HH:mm:ss"
                                                                className="custom-select modal-select-field"
                                                                defaultOpenValue={timePickerDefaultValue}
                                                                onChange={(value) => {
                                                                    if (value === null) {
                                                                        setSunOpenTime('');
                                                                    } else {
                                                                        setSunOpenTime(value.format("HH:mm:ss"));
                                                                    }
                                                                }}
                                                            />

                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="input-group mb-3">
                                                            <p className="input-field-side-text">Close Time
                                                                : </p>

                                                            <TimePicker
                                                                value={sunCloseTime.length > 0 ? moment(sunCloseTime, timeFormat) : ''}
                                                                showSecond={true}
                                                                placeholder="HH:mm:ss"
                                                                className="custom-select modal-select-field"
                                                                defaultOpenValue={timePickerDefaultValue}
                                                                onChange={(value) => {
                                                                    if (value === null) {
                                                                        setSunCloseTime('');
                                                                    } else {
                                                                        setSunCloseTime(value.format("HH:mm:ss"));
                                                                    }
                                                                }}
                                                            />

                                                        </div>
                                                    </div>


                                                </div>

                                            </div>
                                        </Tab>

                                    </Tabs>
                                }


                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-default"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >Cancel
                                </button>

                                <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                    onClick={() => {
                                        handleAction();
                                    }}
                                >Save changes</Button>

                            </div>

                        </div>
                    </div >
                </div >
            ) : null
            }

        </>

    )
}

export default AssignCategoryTimeModal