import React, { useState, useEffect, forwardRef } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Button from "react-bootstrap-button-loader";
import AppointmentBookingBranchModal from "../ModalImpl/AppointmentBookingBranchModal";
import DatePicker from "react-datepicker";
import AppointmentBookingServiceModal from "../ModalImpl/AppointmentBookingServiceModal";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";

const AppointmentBookingPage = () => {
  const baseUrl = localStorage.getItem("API_URL");
  const token = localStorage.getItem("user_token");

  const { state } = useLocation();

  const [showBranchModal, setShowBranchModal] = useState(false);
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [mobileNo, setMobileNo] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [advancedMode, setAdvancedMode] = useState(false);
  const [isUserAvailable, setIsUserAvailable] = useState(false);
  const [branch, setBranch] = useState(null);
  const [service, setService] = useState();
  const [nextTicketData, setNextTicketData] = useState();
  const [displayMsg, setDisplayMsg] = useState("");

  const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="btn btn-outline-secondary px-5"
      type="button"
      onClick={onClick}
      ref={ref}
    >
      <i className="fa fa-angle-right"></i>
    </button>
  ));

  useEffect(() => {
    const { mobile } = state || {};
    if (mobile) {
      const mobileValidated = mobile.replace(/\+94/g, "");
      setMobileNo(mobileValidated);
      getUserDetails(mobileValidated);
    }
  }, [state?.mobile]);

  const getUserDetails = (mobile) => {
    let mobileValidated = mobile[0] === "0" ? mobile.slice(1) : mobile;

    if (mobile !== "") {
      axios({
        method: "GET",
        url: `${baseUrl}/booking/get-user-data/+94${mobileValidated}`,
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          const data = res.data.data;
          setUserDetails(data);

          data.mobile === null ? setAdvancedMode(true) : setAdvancedMode(false);

          data.mobile === null
            ? setIsUserAvailable(false)
            : setIsUserAvailable(true);

          data.mobile === null
            ? setDisplayMsg("User Not Available")
            : setDisplayMsg(data.mobile);
        })

        .catch((error) => {
          console.log(error);

          if (error.response !== undefined) {
            toast.remove();
            toast.error(error.response.data.message, {
              position: "bottom-center",
            });
          } else {
            toast.remove();
            toast.error("Something went wrong", {
              position: "bottom-center",
            });
          }
        });
    } else {
      toast.error("Mobile number empty", {
        position: "bottom-center",
      });
    }
  };

  const updateBranch = (newBranch) => {
    setBranch(newBranch);
    setService({ id: "", category: "" });
    setNextTicketData("");
  };

  const updateService = (newService) => {
    getNextTicket(newService);
    setService(newService);
  };

  const getNextTicket = (newService) => {
    if (newService?.id) {
      axios({
        method: "GET",
        url: `${baseUrl}/ticket/live-data/category/${newService.id}`,
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          const data = res.data.data;
          setNextTicketData(data);
        })
        .catch((error) => {
          console.log(error);

          if (error.response !== undefined) {
            toast.remove();
            toast.error(error.response.data.message, {
              position: "bottom-center",
            });
          } else {
            toast.remove();
            toast.error("Something went wrong", {
              position: "bottom-center",
            });
          }
        });
    }
  };

  const bookTicket = () => {
    Swal.fire({
      position: "center",
      text: "Do you want to add a ticket?",
      showConfirmButton: true,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
      width: "400px",
    }).then((result) => {
      if (result.value == true) {
        if (isUserAvailable) {
          axios({
            method: "POST",
            url: `${baseUrl}/ticket/add`,
            headers: {
              Authorization: token,
            },
            data: {
              bid: branch.id,
              categoryId: service.id,
              vip: false,
              customer: {
                name: userDetails?.fname + " " + userDetails?.lname,
                mobile: userDetails?.mobile,
                nic: userDetails?.nic,
                email: userDetails?.email,
              },
              origin: null,
              nestedCategories: null,
              sendSMS: false,
              token: {
                language: "1",
              },
            },
          })
            .then((res) => {
              toast.remove();
              toast.success("Ticket Added Successfully", {
                position: "bottom-center",
              });
              resetForm();
            })
            .catch((error) => {
              console.log(error);

              if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                  position: "bottom-center",
                });
              } else {
                toast.remove();
                toast.error("Something went wrong", {
                  position: "bottom-center",
                });
              }
            });
        } else {
          axios({
            method: "POST",
            url: `${baseUrl}/booking/add-user-data`,
            headers: {
              Authorization: token,
            },
            data: {
              branchId: branch.id,
              firstName: userDetails?.fname,
              lastName: userDetails?.lname,
              mobile: "+94" + mobileNo,
              nic: userDetails?.nic,
              refNumber: userDetails?.refNumber,
              address: userDetails?.address,
            },
          })
            .then((res) => {
              axios({
                method: "POST",
                url: `${baseUrl}/ticket/add`,
                headers: {
                  Authorization: token,
                },
                data: {
                  bid: branch.id,
                  categoryId: service.id,
                  vip: false,
                  customer: {
                    name: userDetails?.fname + " " + userDetails?.lname,
                    mobile: mobileNo,
                    nic: userDetails?.nic,
                    email: userDetails?.email,
                  },
                  origin: null,
                  nestedCategories: null,
                  sendSMS: false,
                  token: {
                    language: "1",
                  },
                },
              })
                .then((res) => {
                  toast.remove();
                  toast.success("Ticket Added Successfully", {
                    position: "bottom-center",
                  });
                  resetForm();
                })
                .catch((error) => {
                  console.log(error);

                  if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                      position: "bottom-center",
                    });
                  } else {
                    toast.remove();
                    toast.error("Something went wrong", {
                      position: "bottom-center",
                    });
                  }
                });
            })
            .catch((error) => {
              console.log(error);

              if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                  position: "bottom-center",
                });
              } else {
                toast.remove();
                toast.error("Something went wrong", {
                  position: "bottom-center",
                });
              }
            });
        }
      }
    });
  };

  const resetForm = () => {
    setMobileNo("");
    setUserDetails("");
    setAdvancedMode(false);
    setIsUserAvailable(false);
    setBranch({
      id: "",
      name: "",
    });
    setService({ id: "", category: "" });
    setNextTicketData("");
    setDisplayMsg("");
  };

  return (
    <>
      <div>
        <Toaster />
      </div>
      <div className="container-fluid">
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1>Appointment Booking</h1>
            </div>
          </div>
        </div>

        <div className="row clearfix mb-4">
          <div className="col-md-8">
            <div className="card h-100 m-0">
              <div className="body h-100">
                <div className="row">
                  <div className=" col-lg-7 col-md-8 mb-4">
                    <div className="input-group input-feild-suffix">
                      <div className="suffix">+94</div>

                      <input
                        value={mobileNo}
                        onChange={(e) => {
                          setMobileNo(e.target.value.replace(/\D/g, ""));
                          setAdvancedMode(false);
                          setDisplayMsg("");
                        }}
                        type="text"
                        className="form-control"
                        placeholder="Search User..."
                        maxLength={10}
                      />

                      <div className="input-group-append">
                        <button
                          className="btn btn-outline-secondary px-5"
                          type="button"
                          onClick={() => {
                            getUserDetails(mobileNo);
                          }}
                        >
                          <span>Search</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-4">
                    {/* Advanced mode button */}

                    {/* <div className="selecte-all-container mb-3 float-right">
                      <p className="mb-0 m-r-15">Advanced Mode </p>
                      <label className="switch custom-swtch" title="Select All">
                        <input
                          type="checkbox"
                          checked={advancedMode ? true : false}
                          onChange={(e) => {
                            setAdvancedMode(e.target.checked);
                          }}
                          disabled={!!userDetails?.mobile}
                        />
                        <span className="slider"> Advanced Mode</span>
                      </label>
                    </div> */}
                  </div>
                  <div className="col-12 mt-4">
                    <p>{`Mobile Number: ${displayMsg}`}</p>
                  </div>
                  <div className="col-12 mt-4">
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group input-group-sm mb-3">
                          <label htmlFor="form_name">First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              userDetails?.fname || (isUserAvailable ? "-" : "")
                            }
                            onChange={(e) =>
                              setUserDetails({
                                ...userDetails,
                                fname: e.target.value,
                              })
                            }
                            maxLength={100}
                            readOnly={!advancedMode}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group input-group-sm mb-3">
                          <label htmlFor="form_name">Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              userDetails?.lname || (isUserAvailable ? "-" : "")
                            }
                            onChange={(e) =>
                              setUserDetails({
                                ...userDetails,
                                lname: e.target.value,
                              })
                            }
                            maxLength={100}
                            readOnly={!advancedMode}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group input-group-sm mb-3">
                          <label htmlFor="form_name">NIC Number</label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              userDetails?.nic || (isUserAvailable ? "-" : "")
                            }
                            maxLength={12}
                            minLength={10}
                            readOnly={!advancedMode}
                            onChange={(e) =>
                              setUserDetails({
                                ...userDetails,
                                nic: e.target.value.replace(/[^0-9Vv]+/g, ""),
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group input-group-sm mb-3">
                          <label htmlFor="form_name">Ref. Number</label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              userDetails?.refNumber ||
                              (isUserAvailable ? "-" : "")
                            }
                            readOnly={!advancedMode}
                            onChange={(e) =>
                              setUserDetails({
                                ...userDetails,
                                refNumber: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group input-group-sm mb-3">
                          <label htmlFor="form_name">Address</label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              userDetails?.address ||
                              (isUserAvailable ? "-" : "")
                            }
                            readOnly={!advancedMode}
                            onChange={(e) =>
                              setUserDetails({
                                ...userDetails,
                                address: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 my-2">
                    <div className="row mt-4">
                      <div className="col-lg-6 col-md-6">
                        <label htmlFor="form_name">Branch: </label>
                        <div className="form-group input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            value={branch?.name}
                            readOnly
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-outline-secondary px-5"
                              type="button"
                              onClick={() => setShowBranchModal(true)}
                            >
                              <i className="fa fa-angle-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <label htmlFor="form_name">Service:</label>
                        <div className="form-group input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            value={service?.category}
                            readOnly
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-outline-secondary px-5"
                              type="button"
                              onClick={() => setShowServiceModal(true)}
                              disabled={!branch}
                            >
                              <i className="fa fa-angle-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* DATE */}
                      {/* <div className="col-lg-6 col-md-6">
                        <label htmlFor="form_name">Date:</label>
                        <div className="form-group input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            // value={contact}
                            // onChange={(e) => setContact(e.target.value)}
                            readOnly
                          />

                          <div className="input-group-append">
                            <DatePicker
                              className=" form-control w-100"
                              //   selected={startDate}
                              //   onChange={(date) => {
                              //     const dateFrom =
                              //       moment(date).format("YYYY-MM-DD");
                              //     setStartDate(date);
                              //     setDateFrom(dateFrom);
                              //   }}
                              customInput={<DateCustomInput />}
                              popperPlacement="right"
                            />
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card h-100">
              <div className="body h-100">
                <div>
                  <div>
                    <p className="text-center m-0 text-muted">Available Number</p>
                    <p className="text-center h2 text-light-green mt-2 fw-bold">
                      {nextTicketData?.nextTicketNumber || "N/A"}
                    </p>
                  </div>
                  <div>
                    <p className="text-center mb-0 mt-5 text-muted">Estimated Call Time</p>
                    <div
                      className=" vertically-align-center-flex-2 justify-content-center pt-2 text-light-green"
                      style={{ fontSize: "18px", fontWeight: "500" }}
                    >
                      <i className="fa fa-clock-o p-r-5" />
                      <p className="text-center p-l-5 m-0 fw-bold">
                        {nextTicketData?.est || "N/A"}
                      </p>
                    </div>
                  </div>

                  <div>
                    <p className="text-center mb-0 mt-5 text-muted">Now Serving</p>
                    <div className=" vertically-align-center-flex-2 justify-content-center pt-2">
                      <p className="text-center p-l-5 m-0 text-light-green fw-bold">
                        {nextTicketData?.nowServingToken || "N/A"}
                      </p>
                    </div>
                  </div>

                  <div>
                    <p className="text-center mb-0 mt-5 text-muted">No. of Waitings</p>
                    <div className=" vertically-align-center-flex-2 justify-content-center pt-2">
                      <p className="text-center p-l-5 m-0 text-light-green fw-bolder">
                        {nextTicketData?.waitingTicketCount || "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 my-2 mt-5">
                  <div className="d-flex justify-content-center">
                    <button
                      //   loading={buttonLoader}
                      type="submit"
                      className="btn btn-primary px-4 mt-4 ticket-booking-button"
                      onClick={() => bookTicket()}
                      disabled={
                        !mobileNo ||
                        !service?.category ||
                        !nextTicketData?.nextTicketNumber
                      }
                    >
                      Book Ticket
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppointmentBookingBranchModal
        setShowBranchModal={setShowBranchModal}
        showBranchModal={showBranchModal}
        data={branch}
        updateBranch={updateBranch}
      />

      <AppointmentBookingServiceModal
        setShowServiceModal={setShowServiceModal}
        showServiceModal={showServiceModal}
        branch={branch}
        data={service}
        updateService={updateService}
        // getMainUserById={getMainUserById}
      />
    </>
  );
};
export default AppointmentBookingPage;
