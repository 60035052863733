import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import Swal from "sweetalert2";

const RealtimeUsersBySkill = () => {

    const location = useLocation();
    const history = useNavigate();

    const baseUrl = localStorage.getItem('API_URL')
    const token = localStorage.getItem('user_token')
    const selectedBranchId = location.state.selectedBranchId
    const selectedBranchName = location.state.selectedBranchName
    const selectedCategoryId = location.state.selectedCategoryId
    const selectedLanguage = location.state.selectedLanguage
    const selectedBranchQueueType = location.state.selectedBranchQueueType
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [allUsers, setAllUsers] = useState([]);
    const [newUsersForRender, setNewUsersForRender] = useState([]);
    const [searchText, setSearchText] = useState('');

    const [allLanguages, setAllLanguage] = useState([]);

    const [refresh, setRefresh] = useState(0);
    const [skillsList, setSkillsList] = useState([])
    const [selectedSkillId, setSelectedSkillId] = useState('');

    const [usersList, setUserList] = useState([]);
    const [searchedUsersList, setSearchedUserList] = useState([]);
    const [userListForRender, setUserListForRender] = useState([]);

    const [clickedSortBtn, setClickedSortBtn] = useState(0);


    // call getAllLanguages
    useEffect(() => {
        if (baseUrl.length > 0 && token.length > 0 && Object.keys(userDetails).length > 0) {
            getAllLanguages();
        }
    }, [RealtimeUsersBySkill])

    // sort user list - useEffect
    useEffect(() => {
        filterAssignedUsers();
    }, [clickedSortBtn])


    // getAllLanguages - function
    const getAllLanguages = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/skill/get-languages/customer/${userDetails.cid}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            let data = res.data.data
            setAllLanguage(data);

            if(selectedLanguage !== undefined) {
                getSkillsByLanguage(selectedLanguage)
            } else {
                getSkillsByLanguage(data[0].id)
            }


        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // getSkillsByLanguage - function
    const getSkillsByLanguage = (lang) => {

        axios({
            method: 'GET',
            url: `${baseUrl}/skill/get-tickets/count/language/${lang}/branch/${selectedBranchId}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            let data = res.data.data;
            setSkillsList(data);
            console.log(selectedCategoryId);
            if (data.length !== 0) {
                if(selectedCategoryId !== undefined) {
                    data.forEach(element => {
                        if(selectedCategoryId === element.categoryId){
                            setSelectedSkillId(element.skillId)
                            getUsersBySkillId(element.skillId);
                        }
                    });
                } else {
                    setSelectedSkillId(data[0].skillId);
                    getUsersBySkillId(data[0].skillId);
                }

            } else {
                setUserList([])
                setUserListForRender([])
            }

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }


    // getUsersBySkillId - function
    const getUsersBySkillId = (id) => {
        axios({
            method: 'GET',
            url: `${baseUrl}/skill/get-users/branch/${selectedBranchId}/skill/${id}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let data = res.data.data;
            setUserList(data);
            setUserListForRender(data);
            console.log("Users: ", data);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    const disableUserSkills = (userId, skillId) => {

        axios({
            method: 'POST',
            url: `${baseUrl}/skill/disable/skill/${skillId}/user/${userId}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            toast.remove();
            toast.success("Success", {
                position: 'bottom-center',
            });
            getUsersBySkillId(skillId);
        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // assignUserToSkill - function
    const assignUserToSkill = (user) => {

        Swal.fire({
            position: 'center',
            text: `${user.selected ? 'Do you want to unassigned this skill ? ' : 'Do you want to assigned this skill ? '}`,
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            width: '400px',

        }).then((result) => {
            if (result.value === true) {

                axios({
                    method: 'POST',
                    url: `${baseUrl}/user/assign-skill/user/${user.userId}/skill/${selectedSkillId}`,
                    headers: {
                        Authorization: token
                    },

                }).then((res) => {
                        toast.remove();
                        toast.success(`${user.selected ? 'User successfully unassigned' : 'User successfully assigned'} `, {
                            position: 'bottom-center',
                        });

                    for (let i = 0; i < usersList.length; i++) {
                        const userObject = usersList[i];

                        if (userObject.userId === user.userId) {

                            if (userObject.selected === true) {
                                userObject.selected = false;
                                setRefresh(refresh + 1)
                            } else {
                                userObject.selected = true;
                                setRefresh(refresh + 1)
                            }
                            setRefresh(refresh + 1)
                        }
                    }


                }).catch((error) => {
                    console.log(error);

                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });

                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                })

            }

        })
    };


    // search in table filter - function
    const filter = (e) => {
        const keyword = e.target.value;
        setSearchText(keyword);
        if (keyword !== '') {
            const result = usersList.filter((user) => {
                return user.firstName.toString().toLowerCase().includes(keyword.toLowerCase());
            });
            setUserListForRender(result);
        } else {
            setUserListForRender(usersList);
        }


    };

    // filter assigned users - function
    const filterAssignedUsers = (e) => {

        if (clickedSortBtn === 0) {
            setUserListForRender(usersList);
        } else {
            const result = usersList.filter((user) => {
                return user.selected === true;
            });
            setUserListForRender(result);
        }

    };


    return (
        <>
            <div><Toaster /></div>
            <div className="container-fluid">

                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h1>User Skills</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to='/branch-summary'>Branch Summary</Link></li>
                                    <li className="breadcrumb-item link"
                                        onClick={() => {
                                            history('/branch-dashboard', {
                                                    state: {
                                                        selectedBranchId: selectedBranchId,
                                                        selectedBranchName: selectedBranchName,
                                                        selectedBranchQueueType: selectedBranchQueueType,
                                                        selectedBranchLevel: 4
                                                    }
                                                }
                                            )
                                        }}>{selectedBranchName} Dashboard</li>
                                    <li className="breadcrumb-item active" aria-current="page">User Skills</li>

                                </ol>
                            </nav>
                        </div>

                    </div>
                </div>

                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="body">
                                <div className="row">

                                    <div className="col-md-7">
                                        <div className="input-group header-counter-center">
                                            <h5 className="mb-0">
                                                {
                                                    usersList !== null && userListForRender !== null ?
                                                        <>
                                                            {
                                                                usersList.length !== null && userListForRender.length !== null ?
                                                                    <>
                                                                        {searchText.length > 0 ? `${userListForRender.length} / ` : ''}
                                                                        <small className="text-muted"> {usersList.length}</small>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </h5>
                                        </div>
                                    </div>


                                    <div className="col-md-5">
                                        <div className="input-group">
                                            <input
                                                value={searchText}
                                                onChange={filter}
                                                type="text" className="form-control"
                                                placeholder="Search here..."
                                            />

                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="button">
                                                    <i className="fa fa-search" />
                                                </button>
                                                &nbsp;&nbsp;

                                                <button
                                                    className={`btn mr-1 ${clickedSortBtn === 0 ? 'btn-primary' : 'btn-secondary'} `}
                                                    type="button"
                                                    title="list view"
                                                    onClick={() => {
                                                        setClickedSortBtn(0);
                                                    }}
                                                >
                                                    All
                                                </button>

                                                <button
                                                    className={`btn ${clickedSortBtn === 1 ? 'btn-primary' : 'btn-secondary'}`}
                                                    type="button"
                                                    title="grid view"
                                                    style={{ borderRadius: 0 }}
                                                    onClick={() => {
                                                        setClickedSortBtn(1);
                                                    }}
                                                >
                                                    Assigned
                                                </button>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row clearfix">

                    <div className="col-lg-3 col-md-4 col-sm-12">

                        <>
                            <small className="text-muted text-left">Skills</small>

                            <ul className="list-group mt-2 mb-4 hovering-zoom">
                                {<>

                                    {selectedLanguage !== undefined ? (
                                        <div>
                                            <Tabs id="uncontrolled-tab-example"
                                                  onSelect={(key) => {
                                                      setClickedSortBtn(0);
                                                      getSkillsByLanguage(key);
                                                  }}

                                                  defaultActiveKey={selectedLanguage}
                                            >
                                                {allLanguages.map((item, i) =>
                                                    <Tab eventKey={item.id} title={item.lang} tabClassName="custom_tab">

                                                        <div className='body'>
                                                            <ul className="list-group mt-3 mb-4 right-custom-nav">
                                                                {skillsList.length > 0 ?
                                                                    <>
                                                                        {skillsList.map((item, i) =>
                                                                            <li
                                                                                className={`list-group-item d-flex justify-content-between align-items-center ${item.skillId === selectedSkillId ? 'active' : ''}`} key={i}

                                                                                onClick={() => {
                                                                                    setClickedSortBtn(0);
                                                                                    setSelectedSkillId(item.skillId);
                                                                                    getUsersBySkillId(item.skillId)
                                                                                }}
                                                                            >
                                                                                <label >{item.skill}</label>
                                                                            </li>
                                                                        )}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <span>No skills avaialble</span>
                                                                    </>
                                                                }

                                                            </ul>

                                                        </div>
                                                    </Tab>
                                                )}
                                            </Tabs>
                                        </div>
                                    ) : (<div>
                                        <Tabs id="uncontrolled-tab-example"
                                              onSelect={(key) => {
                                                  setClickedSortBtn(0);
                                                  getSkillsByLanguage(key);
                                              }}
                                        >
                                            {allLanguages.map((item, i) =>
                                                <Tab eventKey={item.id} title={item.lang} tabClassName="custom_tab" activeKey={selectedLanguage}>

                                                    <div className='body'>
                                                        <ul className="list-group mt-3 mb-4 right-custom-nav">
                                                            {skillsList.length > 0 ?
                                                                <>
                                                                    {skillsList.map((item, i) =>
                                                                        <li
                                                                            className={`list-group-item d-flex justify-content-between align-items-center ${item.skillId === selectedSkillId ? 'active' : ''}`} key={i}

                                                                            onClick={() => {
                                                                                setClickedSortBtn(0);
                                                                                setSelectedSkillId(item.skillId);
                                                                                getUsersBySkillId(item.skillId)
                                                                            }}
                                                                        >
                                                                            <label >{item.skill}</label>
                                                                        </li>
                                                                    )}
                                                                </>
                                                                :
                                                                <>
                                                                    <span>No skills avaialble</span>
                                                                </>
                                                            }

                                                        </ul>

                                                    </div>
                                                </Tab>
                                            )}
                                        </Tabs>
                                    </div>)}


                                </>
                                }
                            </ul>
                        </>

                    </div>


                    <div className="col-lg-9 col-md-8 col-sm-12">
                        <div className="row clearfix">
                            {userListForRender.length > 0 ?
                                <>
                                    {userListForRender.map((user, i) =>
                                        <div className="col-lg-3 col-md-6 p-l-5 p-r-5" key={user.id}>
                                            <div className={`card c_grid ${user.status ? 'c_green' : 'c_red'} `} title={`${user.firstName} ${user.lastName}`}>
                                                
                                                <div className="body text-center ribbon"
                                                     style={{ paddingTop: '45px' }}
                                                     onClick={() => {
                                                        disableUserSkills(user.userId, user.skillId)
                                                     }}

                                                >

                                                    {user.selected ?
                                                        <div className="ribbon-box indigo">Assigned</div>
                                                        : ""
                                                    }

                                                    <div className="circle sm">

                                                        {user.image ?
                                                            <img
                                                                src={(user.image)}
                                                                className="personal-avatar-user " alt="avatar" />
                                                            :
                                                            <img src={require('../../assets/images/others/no-profile.jpg')}
                                                                 className="personal-avatar-user " alt="avatar" />
                                                        }
                                                    </div>

                                                    <h6 className="mt-3 mb-0 card-user-no user-card-fit-width" title={`Employee No ${user.employeeNo}`}>{user.employeeNo}</h6>
                                                    <h6 className="mt-2">{user.firstName}</h6>
                                                    {/* <div  className="text-center text-muted mb-3">{user.roleName}</div> */}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                                :
                                <>
                                    <div className="col-lg-12 col-md-12">
                                        <span className=''>No Users Avaialble</span>
                                    </div>
                                </>
                            }
                        </div>
                    </div>

                </div>

            </div>



        </>
    )
}

export default RealtimeUsersBySkill