import axios from 'axios';
import React, { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import Button from 'react-bootstrap-button-loader';

// used for add and edit
const AddUserRoleModal = ({ showAddUserRoleModal, setShowAddUserRoleModal, modalType, getRollWithPermissions, selectedRow, setSelectedRow }) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [allPermissions, setAllPermissions] = useState([]);
    const [userType, setUserType] = useState('');

    const [buttonLoader, setButtonLoader] = useState(false);
    const [refresh, setRefresh] = useState(1);

    const [allSelected, setAllSelected] = useState(false);



    // call getAllPermissions - useEffect
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            if (baseUrl.length > 0 && token.length > 0) {
                getAllPermissions();
            }
        }
    }, [AddUserRoleModal])

    // is update
    useEffect(() => {
        if (modalType === 1) {
            if (Object.keys(selectedRow).length > 0) {
                for (let i = 0; i < allPermissions.length; i++) {
                    for (let j = 0; j < selectedRow.roleFeatures.length; j++) {

                        if (allPermissions[i].id === selectedRow.roleFeatures[j].id) {
                            allPermissions[i].selected = true;
                        }
                    }
                }
                setUserType(selectedRow.role);
                setRefresh(refresh + 1);
                checkAllSelectedOrNot();
            }
        }
    }, [selectedRow])



    // getAllPermissions - function
    const getAllPermissions = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/user-role/get-all-permissions`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let data = res.data.data;

            for (let j = 0; j < data.list.length; j++) {
                data.list[j].selected = false;
            }
            setAllPermissions(data.list);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // addRoleWithPermission - function
    const addRoleWithPermission = () => {

        setButtonLoader(true);

        let selectedPermissions = filterSelectedPermissions();

        let isValidated = validateData(selectedPermissions);

        if (isValidated) {
            axios({
                method: 'POST',
                url: `${baseUrl}/user-role/add`,
                headers: {
                    Authorization: token
                },
                data: {
                    cid: userDetails.cid,
                    role: userType,
                    permissions: selectedPermissions
                }
            }).then((res) => {

                toast.success('Successfully added', {
                    position: 'bottom-center',
                });

                getRollWithPermissions();
                closeModal();

            }).catch((error) => {
                console.log(error);

                if (error.response !== undefined) {
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                    setButtonLoader(false);
                } else {
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                    setButtonLoader(false);
                }
            })
        }
    }

    // updateRoleWithPermissions - function
    const updateRoleWithPermissions = () => {

        setButtonLoader(true);

        let selectedPermissions = filterSelectedPermissions();

        let isValidated = validateData(selectedPermissions);

        if (isValidated) {
            axios({
                method: 'POST',
                url: `${baseUrl}/user-role/update`,
                headers: {
                    Authorization: token
                },
                data: {
                    id: selectedRow.id,
                    role: userType,
                    permissions: selectedPermissions
                }
            }).then((res) => {

                toast.success('Successfully updated', {
                    position: 'bottom-center',
                });

                getRollWithPermissions();
                closeModal();

            }).catch((error) => {
                console.log(error);

                if (error.response !== undefined) {
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                    setButtonLoader(false);
                } else {
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                    setButtonLoader(false);
                }
            })
        }
    }

    // filterSelectedPermissions - function
    const filterSelectedPermissions = () => {
        let selectedPermissions = [];
        for (let i = 0; i < allPermissions.length; i++) {
            if (allPermissions[i].selected === true) {
                selectedPermissions.push(allPermissions[i].id)
            }
        }
        return selectedPermissions;
    }

    // validateData - function
    const validateData = (selectedPermissions) => {
        if (userType.length === 0) {
            toast.error('User type empty', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            return false;
        } else if (selectedPermissions.length === 0) {
            toast.error('Please select permission', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            return false;
        } else if (selectedPermissions.length === 0) {
            toast.error('Please select permission', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            return false;
        } else if (Object.keys(userDetails).length === 0) {
            toast.error('Customer Id empty', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            return false;
        } else {
            return true;
        }
    }

    // closeModal - function
    const closeModal = () => {
        setSelectedRow({})
        setUserType('');
        setButtonLoader(false);
        for (let i = 0; i < allPermissions.length; i++) {
            allPermissions[i].selected = false;
        }
        setAllSelected(false);
        setShowAddUserRoleModal(false);
    }

    // select all roles - function
    const selectAllPermissions = (checked) => {
        if (checked) {
            allPermissions.forEach((element, i) => {
                element.selected = true;
            });
            setAllSelected(true);
        } else {
            allPermissions.forEach((element, i) => {
                element.selected = false;
            });
            setAllSelected(false);
        }
        setRefresh(refresh + 1)
    }

    // check all selected or not - function
    const checkAllSelectedOrNot = () => {

        for (let i = 0; i < allPermissions.length; i++) {
            const element = allPermissions[i];

            if (element.selected === false) {
                setAllSelected(false)
                return;
            } else {
                setAllSelected(true)
            }

        }

    }



    return (
        <>
            <div><Toaster /></div>
            {showAddUserRoleModal ? (
                <div className={`modal fade d-block show modal-container`} id="counter-modal">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    {modalType === 0 ? <>Add New Role</> : <>Edit Role</>}
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className='row clearfix'>
                                    <div className="col-6">
                                        <div className="input-group input-group-sm mb-3">
                                            <p className="input-field-side-text">Role Name : </p>
                                            <input autoFocus type="text" className="form-control" aria-label="Small"
                                                aria-describedby="inputGroup-sizing-sm"
                                                value={userType}
                                                onChange={(e) => {
                                                    setUserType(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="selecte-all-container mb-3 float-right">
                                            <p className="mb-0 m-r-15">Select All : </p>
                                            <label
                                                className="switch custom-swtch" title="Select All">
                                                <input type="checkbox"
                                                    checked={allSelected ? true : false}
                                                    onChange={(e) => {
                                                        selectAllPermissions(e.target.checked);
                                                    }}
                                                />
                                                <span className='slider'
                                                > Select All</span>

                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='p-l-10 p-r-10'>
                                    <div className='row clearfix'>
                                        {
                                            allPermissions.length > 0 ? (

                                                allPermissions.map((permission, i) => (
                                                    <div className='col-lg-4 col-md-4 p-l-5 p-r-5 mb-2' key={i}>
                                                        <div
                                                            className={`list-group-item d-flex justify-content-between align-items-center cursor-pointer ${permission.selected ? 'active' : ''}`}
                                                            title={permission.id}
                                                            onClick={() => {
                                                                // setSelectedPermissionsTest([...selectedPermissionsTest, permission.id]);
                                                                permission.selected = !permission.selected;
                                                                checkAllSelectedOrNot();
                                                                setRefresh(refresh + 1);
                                                            }}
                                                        >
                                                            <p className="modal-lg-select-card-text">{permission.id}</p>

                                                        </div>
                                                    </div>
                                                ))

                                            ) : (
                                                <div className="col-md-12">
                                                    <div className="alert alert-custom alert-dismissible" role="alert">
                                                        <i className="fa fa-info-circle"></i> Empty records
                                                    </div>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-default"
                                    onClick={() => {
                                        closeModal();
                                    }}>Cancel
                                </button>

                                {modalType === 0 ?
                                    <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                        onClick={() => {
                                            addRoleWithPermission();
                                        }}
                                    >Save changes</Button>
                                    :
                                    <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                        onClick={() => {
                                            updateRoleWithPermissions();
                                        }}
                                    >Update changes</Button>
                                }

                            </div>

                        </div>
                    </div>
                </div>
            ) : null
            }

        </>

    )
}

export default AddUserRoleModal