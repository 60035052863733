import React, { useEffect, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import AgentWiseReport from "../ReportsImpl/AgentWiseReportTables/AgentWiseReport";
import AgentAttendanceReport from "./AgentWiseReportTables/AgentAttendanceReport";
import AgentPerformanceReport from "./AgentWiseReportTables/AgentPerformanceReport";
import AgentAttendanceDetailedReport from "./AgentWiseReportTables/AgentAttendanceDetailedReport";
import AgentAttendanceServiceDetailedReport from "./AgentWiseReportTables/AgentAttendanceServiceDetailedReport";
import AgentMattSummaryReport from "./AgentWiseReportTables/AgentMattSummaryReport";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import AgentWisePerformanceSummaryReport from "./AgentWiseReportTables/AgentWisePerformanceSummaryReport";

const AgentWiseReports = ({
  selectedRegionIds,
  changeRegionIds,
  dateFrom,
  dateTo,
  downloadExcel,
  reportUrl,
}) => {
  const baseUrl = localStorage.getItem("API_URL");
  const token = localStorage.getItem("user_token");
  const userDetails = JSON.parse(localStorage.getItem("user_details"));

  const [openAgentWiseReport, setOpenAgentWiseReport] = useState(false);
  const [openAgentAttendanceReport, setOpenAgentAttendanceReport] =
    useState(false);
  const [openAgentPerformanceReport, setOpenAgentPerformanceReport] =
    useState(false);
  const [
    openAgentAttendanceDetailedReport,
    setOpenAgentAttendanceDetailedReport,
  ] = useState(false);
  const [
    openAgentAttendanceServiceDetailedReport,
    setOpenAgentAttendanceServiceDetailedReport,
  ] = useState(false);
  const [openMattSummaryReport, setOpenMattSummaryReport] = useState(false);
  const [
    openAgentWisePerformanceSummaryReport,
    setOpenAgentWisePerformanceSummaryReport,
  ] = useState(false);

  const [reportsAccess, setReportsAccess] = useState([]);

  // call getReportAccess - useEffect
  useEffect(() => {
    if (reportUrl) {
      getReportAccess();
    }
  }, [AgentWiseReports, reportUrl]);

  // get report access - function
  const getReportAccess = () => {
    axios({
      method: "GET",
      url: `${reportUrl}/report-controller/get/report-access/customer/${userDetails.cid}/parent/Agent Wise Reports/get-report-access`,
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        let data = res.data.data;
        console.log(data);
        setReportsAccess(data);
      })
      .catch((error) => {
        console.log(error);

        if (error.response !== undefined) {
          toast.error(error.response.data.message, {
            position: "bottom-center",
          });
        } else {
          toast.remove();
          toast.error("Something went wrong", {
            position: "bottom-center",
          });
        }
      });
  };

  return (
    <>
      <div className="row clearfix">
        <div className="col-lg-12">
          <div className="card">
            <Accordion
              className="accordion report-accordion"
              id="accordion"
              defaultActiveKey=""
            >
              {reportsAccess.map((item, i) => {
                if (item.name === "Agent Wise Report" && item.active) {
                  return (
                    <div>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            className="btn btn-link vertically-align-center-flex"
                            onClick={() => {
                              if (openAgentWiseReport) {
                                setOpenAgentWiseReport(false);
                              } else {
                                setOpenAgentWiseReport(true);
                                setOpenAgentAttendanceReport(false);
                                setOpenAgentPerformanceReport(false);
                                setOpenAgentAttendanceDetailedReport(false);
                                setOpenAgentAttendanceServiceDetailedReport(
                                  false
                                );
                                setOpenMattSummaryReport(false);
                                setOpenAgentWisePerformanceSummaryReport(false);
                              }
                            }}
                          >
                            Agent Wise Report
                            <div className="float-right vertically-align-center-flex-2">
                              {userDetails !== null ? (
                                <>
                                  {/* <a href={`${baseUrl}/real-time/get/xl/agent-wise-report/from/${dateFrom}/to/${dateTo}/${selectedRegionIds.length > 0 ? selectedRegionIds.toString() : userDetails.bid}`}
                                                            target='_blank' className="btn btn-sm btn-default" title="View PDF"
                                                        ><i class="fa fa-file-pdf-o" aria-hidden="true"></i></a> */}

                                  {item.excel ? (
                                    <a
                                      href={`${reportUrl}/real-time/get/xl/agent-wise-report/from/${dateFrom}/to/${dateTo}/${
                                        selectedRegionIds.length > 0
                                          ? selectedRegionIds.toString()
                                          : userDetails.bid
                                      }`}
                                      target="_blank"
                                      className="btn btn-sm btn-default"
                                      title="View PDF"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-excel-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  ) : null}
                                </>
                              ) : null}
                              <span className="p-l-20">
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </h5>
                      </div>
                      <Accordion.Collapse eventKey="0" className="collapse">
                        <AgentWiseReport
                          openAgentWiseReport={openAgentWiseReport}
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          selectedRegionIds={selectedRegionIds}
                          changeRegionIds={changeRegionIds}
                          reportUrl={reportUrl}
                        />
                      </Accordion.Collapse>
                    </div>
                  );
                } else if (
                  item.name === "Agent Attendance Report" &&
                  item.active
                ) {
                  return (
                    <div>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="1"
                            className="btn btn-link vertically-align-center-flex"
                            onClick={() => {
                              if (openAgentAttendanceReport) {
                                setOpenAgentAttendanceReport(false);
                              } else {
                                setOpenAgentAttendanceReport(true);
                                setOpenAgentWiseReport(false);
                                setOpenAgentPerformanceReport(false);
                                setOpenAgentAttendanceDetailedReport(false);
                                setOpenAgentAttendanceServiceDetailedReport(
                                  false
                                );
                                setOpenMattSummaryReport(false);
                                setOpenAgentWisePerformanceSummaryReport(false);
                              }
                            }}
                          >
                            Agent Attendance Report
                            <div className="float-right vertically-align-center-flex-2">
                              {userDetails !== null ? (
                                <>
                                  {item.pdf ? (
                                    <a
                                      href={`${reportUrl}/report-controller/download-pdf/${dateFrom}/${dateTo}/${
                                        selectedRegionIds.length > 0
                                          ? selectedRegionIds.toString()
                                          : userDetails.bid
                                      }/operator-attendance-report`}
                                      target="_blank"
                                      className="btn btn-sm btn-default"
                                      title="View PDF"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-pdf-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  ) : null}

                                  {item.excel ? (
                                    <button
                                      className="btn btn-sm btn-default"
                                      title="View Excel"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        downloadExcel(
                                          "operator-attendance-details-report",
                                          "_ar_aar_table"
                                        );
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-excel-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : null}
                                </>
                              ) : null}
                              <span className="p-l-20">
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </h5>
                      </div>
                      <Accordion.Collapse eventKey="1" className="collapse">
                        <AgentAttendanceReport
                          openAgentAttendanceReport={openAgentAttendanceReport}
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          selectedRegionIds={selectedRegionIds}
                          changeRegionIds={changeRegionIds}
                          reportUrl={reportUrl}
                        />
                      </Accordion.Collapse>
                    </div>
                  );
                } else if (
                  item.name === "Agent Attendance Detailed Report" &&
                  item.active
                ) {
                  return (
                    <div>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="4"
                            className="btn btn-link vertically-align-center-flex"
                            onClick={() => {
                              if (openAgentAttendanceDetailedReport) {
                                setOpenAgentAttendanceDetailedReport(false);
                              } else {
                                setOpenAgentAttendanceDetailedReport(true);
                                setOpenAgentPerformanceReport(false);
                                setOpenAgentAttendanceReport(false);
                                setOpenAgentWiseReport(false);
                                setOpenAgentAttendanceServiceDetailedReport(
                                  false
                                );
                                setOpenMattSummaryReport(false);
                                setOpenAgentWisePerformanceSummaryReport(false);
                              }
                            }}
                          >
                            Agent Attendance Detailed Report
                            <div className="float-right vertically-align-center-flex-2">
                              {userDetails !== null ? (
                                <>
                                  {item.pdf ? (
                                    <a
                                      href={`${reportUrl}/report-controller/download-pdf/${dateFrom}/${dateTo}/${
                                        selectedRegionIds.length > 0
                                          ? selectedRegionIds.toString()
                                          : userDetails.bid
                                      }/detailed-operator-attendance-report`}
                                      target="_blank"
                                      className="btn btn-sm btn-default"
                                      title="View PDF"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-pdf-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  ) : null}

                                  {item.excel ? (
                                    <button
                                      className="btn btn-sm btn-default"
                                      title="View Excel"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        downloadExcel(
                                          "operator-attendance-full-details-report",
                                          "_ar_aadr_table"
                                        );
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-excel-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : null}
                                </>
                              ) : null}
                              <span className="p-l-20">
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </h5>
                      </div>
                      <Accordion.Collapse eventKey="4" className="collapse">
                        <AgentAttendanceDetailedReport
                          openAgentAttendanceDetailedReport={
                            openAgentAttendanceDetailedReport
                          }
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          selectedRegionIds={selectedRegionIds}
                          changeRegionIds={changeRegionIds}
                          reportUrl={reportUrl}
                        />
                      </Accordion.Collapse>
                    </div>
                  );
                } else if (
                  item.name === "Agent Performance Report" &&
                  item.active
                ) {
                  return (
                    <div>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="3"
                            className="btn btn-link vertically-align-center-flex"
                            onClick={() => {
                              if (openAgentPerformanceReport) {
                                setOpenAgentPerformanceReport(false);
                              } else {
                                setOpenAgentPerformanceReport(true);
                                setOpenAgentAttendanceReport(false);
                                setOpenAgentWiseReport(false);
                                setOpenAgentAttendanceDetailedReport(false);
                                setOpenAgentAttendanceServiceDetailedReport(
                                  false
                                );
                                setOpenMattSummaryReport(false);
                                setOpenAgentWisePerformanceSummaryReport(false);
                              }
                            }}
                          >
                            Agent Performance Report
                            <div className="float-right vertically-align-center-flex-2">
                              {userDetails !== null ? (
                                <>
                                  {item.pdf ? (
                                    <a
                                      href={`${reportUrl}/report-controller/download-pdf/${dateFrom}/${dateTo}/${
                                        selectedRegionIds.length > 0
                                          ? selectedRegionIds.toString()
                                          : userDetails.bid
                                      }/operator-performance`}
                                      target="_blank"
                                      className="btn btn-sm btn-default"
                                      title="View PDF"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-pdf-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  ) : null}

                                  {item.excel ? (
                                    <button
                                      className="btn btn-sm btn-default"
                                      title="View Excel"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        downloadExcel(
                                          "operator-performance-details-report",
                                          "_ar_apr_table"
                                        );
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-excel-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : null}
                                </>
                              ) : null}
                              <span className="p-l-20">
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </h5>
                      </div>
                      <Accordion.Collapse eventKey="3" className="collapse">
                        <AgentPerformanceReport
                          openAgentPerformanceReport={
                            openAgentPerformanceReport
                          }
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          selectedRegionIds={selectedRegionIds}
                          changeRegionIds={changeRegionIds}
                          reportUrl={reportUrl}
                        />
                      </Accordion.Collapse>
                    </div>
                  );
                } else if (
                  item.name === "Agent Attendance Service Detailed Report" &&
                  item.active
                ) {
                  return (
                    <div>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="5"
                            className="btn btn-link vertically-align-center-flex"
                            onClick={() => {
                              if (openAgentAttendanceServiceDetailedReport) {
                                setOpenAgentAttendanceServiceDetailedReport(
                                  false
                                );
                              } else {
                                setOpenAgentAttendanceServiceDetailedReport(
                                  true
                                );
                                setOpenAgentAttendanceDetailedReport(false);
                                setOpenAgentPerformanceReport(false);
                                setOpenAgentAttendanceReport(false);
                                setOpenAgentWiseReport(false);
                                setOpenMattSummaryReport(false);
                                setOpenAgentWisePerformanceSummaryReport(false);
                              }
                            }}
                          >
                            Agent Attendance Service Detailed Report
                            <div className="float-right vertically-align-center-flex-2">
                              {userDetails !== null ? (
                                <>
                                  {item.pdf ? (
                                    <a
                                      href={`${reportUrl}/report-controller/download-pdf/${dateFrom}/${dateTo}/${
                                        selectedRegionIds.length > 0
                                          ? selectedRegionIds.toString()
                                          : userDetails.bid
                                      }/detailed-operator-attendance-service`}
                                      target="_blank"
                                      className="btn btn-sm btn-default"
                                      title="View PDF"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-pdf-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  ) : null}

                                  {item.excel ? (
                                    <button
                                      className="btn btn-sm btn-default"
                                      title="View Excel"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        downloadExcel(
                                          "operator-attendance-service-details-report",
                                          "_ar_aasdr_table"
                                        );
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-excel-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : null}
                                </>
                              ) : null}
                              <span className="p-l-20">
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </h5>
                      </div>
                      <Accordion.Collapse eventKey="5" className="collapse">
                        <AgentAttendanceServiceDetailedReport
                          openAgentAttendanceServiceDetailedReport={
                            openAgentAttendanceServiceDetailedReport
                          }
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          selectedRegionIds={selectedRegionIds}
                          changeRegionIds={changeRegionIds}
                          reportUrl={reportUrl}
                        />
                      </Accordion.Collapse>
                    </div>
                  );
                } else if (
                  item.name === "Agent Mattercode Summary Report" &&
                  item.active
                ) {
                  return (
                    <div>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="6"
                            className="btn btn-link"
                            onClick={() => {
                              if (openMattSummaryReport) {
                                setOpenMattSummaryReport(false);
                              } else {
                                setOpenMattSummaryReport(true);
                                setOpenAgentAttendanceServiceDetailedReport(
                                  false
                                );
                                setOpenAgentAttendanceDetailedReport(false);
                                setOpenAgentPerformanceReport(false);
                                setOpenAgentAttendanceReport(false);
                                setOpenAgentWiseReport(false);
                                setOpenAgentWisePerformanceSummaryReport(false);
                              }
                            }}
                          >
                            Agent Mattercode Summary Report
                            <div className="float-right vertically-align-center-flex-2">
                              {userDetails !== null ? (
                                <>
                                  {item.pdf ? (
                                    <a
                                      href={`${reportUrl}/report-controller/download-pdf/${dateFrom}/${dateTo}/${
                                        selectedRegionIds.length > 0
                                          ? selectedRegionIds.toString()
                                          : userDetails.bid
                                      }/operator-mattercodes-summary`}
                                      target="_blank"
                                      className="btn btn-sm btn-default"
                                      title="View PDF"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-pdf-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  ) : null}

                                  {item.excel ? (
                                    <button
                                      className="btn btn-sm btn-default"
                                      title="View Excel"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        downloadExcel(
                                          "operator-matter-code-report",
                                          "_ar_amsr_table"
                                        );
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-excel-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : null}
                                </>
                              ) : null}
                              <span className="p-l-20">
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </h5>
                      </div>
                      <Accordion.Collapse eventKey="6" className="collapse">
                        <AgentMattSummaryReport
                          openMattSummaryReport={openMattSummaryReport}
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          selectedRegionIds={selectedRegionIds}
                          changeRegionIds={changeRegionIds}
                          reportUrl={reportUrl}
                        />
                      </Accordion.Collapse>
                    </div>
                  );
                } else if (
                  item.name === "Agent Wise Performance Summary Report" &&
                  item.active
                ) {
                  return (
                    <div>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="7"
                            className="btn btn-link"
                            onClick={() => {
                              if (openAgentWisePerformanceSummaryReport) {
                                setOpenAgentWisePerformanceSummaryReport(false);
                              } else {
                                setOpenAgentWisePerformanceSummaryReport(true);
                                setOpenAgentAttendanceServiceDetailedReport(
                                  false
                                );
                                setOpenAgentAttendanceDetailedReport(false);
                                setOpenAgentPerformanceReport(false);
                                setOpenAgentAttendanceReport(false);
                                setOpenAgentWiseReport(false);
                                setOpenMattSummaryReport(false);
                              }
                            }}
                          >
                            Agent Wise Performance Summary Report
                            <div className="float-right vertically-align-center-flex-2">
                              {userDetails !== null ? (
                                <>
                                  {item.pdf ? (
                                    <a
                                      href={`${reportUrl}/report-controller/download-pdf/${dateFrom}/${dateTo}/${
                                        selectedRegionIds.length > 0
                                          ? selectedRegionIds.toString()
                                          : userDetails.bid
                                      }/operator-wise-summary-report`}
                                      target="_blank"
                                      className="btn btn-sm btn-default"
                                      title="View PDF"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-pdf-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  ) : null}

                                  {item.excel ? (
                                    <button
                                      className="btn btn-sm btn-default"
                                      title="View Excel"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        downloadExcel(
                                          "operator-wise-performance-report",
                                          "_ar_awpsr_table"
                                        );
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-excel-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : null}
                                </>
                              ) : null}
                              <span className="p-l-20">
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </h5>
                      </div>
                      <Accordion.Collapse eventKey="7" className="collapse">
                        <AgentWisePerformanceSummaryReport
                          openAgentWisePerformanceSummaryReport={
                            openAgentWisePerformanceSummaryReport
                          }
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          selectedRegionIds={selectedRegionIds}
                          changeRegionIds={changeRegionIds}
                          reportUrl={reportUrl}
                        />
                      </Accordion.Collapse>
                    </div>
                  );
                }
              })}
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentWiseReports;
