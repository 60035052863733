import React, { useState, useEffect, useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { MDBDataTable } from "mdbreact";
import AssignUserModal from "../ModalImpl/AssignUserModal";
import AssignUserRoleModal from "../ModalImpl/AssignUserRoleModal";
import AssignUserCategoryModal from '../ModalImpl/AssignUserCategoryModal';
import toast, { Toaster } from 'react-hot-toast';
import { DropdownToggle, DropdownMenu, Card, UncontrolledDropdown, DropdownItem, Progress } from "reactstrap";
import MUIDataTable from 'mui-datatables';
import CircularProgress from "@mui/material/CircularProgress";


const Users = () => {

    let history = useNavigate();

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const selectedBranch = JSON.parse(localStorage.getItem('selected_branch'));

    const [showAssignUserModal, setShowAssignUserModal] = useState(false);
    const [showAssignUserRoleModal, setShowAssignUserRoleModal] = useState(false);
    const [showAddUserCategoryModal, setShowAddUserCategoryModal] = useState(false)

    const [allUsers, setAllUsers] = useState([]);
    const [allUsersForRender, setAllUsersForRender] = useState([]);

    const [searchText, setSearchText] = useState('');
    const [selectedUser, setSelectedUser] = useState({});

    const [unitState, setUnitState] = useState("list");

    const [paginatedUsersList, setpaginatedUsersList] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [isLoading, setLoading] = useState(false);

    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(0);
    const [sortOrder, setSortOrder] = useState(null);
    const [searchKey, setSearchKey] = useState(null);

    // data table
    const [dataTable, setDataTable] = useState({
        page: 0,
        size: 10,
        search: '',
        order: null,
    });

    const setTable = (tableState) => {
        setDataTable({
            page: tableState.page,
            search: tableState.searchText,
            size: tableState.rowsPerPage,
            order: tableState.sortOrder
        });
    };

    const options = {
        tableBodyHeight: '35vh',
        filterType: 'dropdown',
        filter: false,
        responsive: 'vertical',
        serverSide: true,
        count: count,
        download: false,
        jumpToPage: true,
        selectableRows: 'none',
        print: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        },
        onTableChange: (action, tableState) => {

            switch (action) {
                case 'changePage':
                    getUsersWithPagination(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'sort':
                    getUsersWithPagination(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'changeRowsPerPage':
                    setRowsPerPage(tableState.rowsPerPage);
                    getUsersWithPagination(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'search':
                    getUsersWithPagination(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                default:
                    break;
            }
        },
    };

    const columns = [
        {
            label: '#',
            name: 'index',
            options: {
                sort: false,
                filter: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {

                    if (paginatedUsersList === undefined) return;
                    let item = paginatedUsersList.find((item, i) => i === dataIndex);

                    return (
                        <p className='m-0'>{dataIndex + 1}</p>
                    );
                }
            }
        },
        {
            label: 'First Name',
            name: 'firstName',
            options: {
                sort: true,
                filter: false,

            }
        },
        {
            label: "Last Name",
            name: 'lastName',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Employee No',
            name: 'employeeNumber',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'User Type',
            name: 'employeeNumber',
            options: {
                filter: false,
                sort: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {

                    if (paginatedUsersList === undefined) return;
                    let item = paginatedUsersList.find((item, i) => i === dataIndex);

                    return (
                        <p className='m-0'>
                            {item.userType.description}
                        </p>
                    );
                }
            }
        },
        {
            label: 'Role',
            name: 'roleName',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Action',
            name: '',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {

                    if (paginatedUsersList === undefined) return;
                    let item = paginatedUsersList.find((item, i) => i === dataIndex);

                    return (
                        <>

                            <UncontrolledDropdown>

                                <DropdownToggle type="button" className={`btn btn-sm btn-default table-action-btn`} data-toggle="tooltip"
                                    data-placement="top"
                                ><i className="fa fa-angle-right" aria-hidden="true"></i>
                                </DropdownToggle>

                                <DropdownMenu
                                    positionFixed={true}
                                    className="dropdown-menu-xs">
                                    <ul className="link-list-opt">
                                        <li>
                                            <DropdownItem
                                                tag="a"
                                                href="javascript:void(0);"
                                                onClick={() => {
                                                    history(`/user-update?uid=${item.id}`, { state: { breadcrumb: true } })
                                                }}
                                            >
                                                <i className="fa fa-desktop mr-3" aria-hidden="true"></i>
                                                <span>View</span>
                                            </DropdownItem>
                                        </li>
                                        <li>
                                            <DropdownItem
                                                tag="a"
                                                href="javascript:void(0);"
                                                onClick={() => {
                                                    setSelectedUser(item);
                                                    setShowAssignUserRoleModal(true);
                                                }}
                                            >
                                                <i className="fa fa-users mr-3" aria-hidden="true"></i>
                                                <span>Assign Role</span>
                                            </DropdownItem>
                                        </li>
                                        <li>
                                            <DropdownItem
                                                tag="a"
                                                href="javascript:void(0);"
                                                onClick={() => {
                                                    setSelectedUser(item);
                                                    setShowAddUserCategoryModal(true);
                                                }}
                                            >
                                                <i className="fa fa-puzzle-piece mr-3"> </i>
                                                <span>Assign User Category</span>
                                            </DropdownItem>
                                        </li>
                                        <li>
                                            <DropdownItem
                                                tag="a"
                                                href="javascript:void(0);"
                                                onClick={() => {
                                                    removeUserFromBranch(item.id)
                                                }}
                                            >
                                                <i className="fa fa-times mr-3"> </i>
                                                <span>Remove user from branch</span>
                                            </DropdownItem>
                                        </li>
                                    </ul>
                                </DropdownMenu>
                            </UncontrolledDropdown>

                        </>
                    );
                }
            },
        },
    ];



    // call getUsersWithPagination for list view - useEffect
    useEffect(() => {
        if (token !== null) {
            if (baseUrl.length > 0 && token.length > 0 && Object.keys(selectedBranch).length > 0) {
                getUsersWithPagination(0, null, null, rowsPerPage);
            }
        }
    }, [Users])

    // call getAllUsers for grid view - useEffect
    useEffect(() => {
        if (unitState === 'grid' && allUsers.length === 0) {
            getAllUsers();
        }
    }, [unitState])



    // getAllUsers - function
    const getAllUsers = () => {

        axios({
            method: "GET",
            url: `${baseUrl}/user/get-users/branch/${selectedBranch.id}`,
            headers: {
                Authorization: token
            }
        }).then((response) => {

            let data = response.data.data;
            setAllUsers(data);
            setAllUsersForRender(data);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // getUsersWithPagination - function
    const getUsersWithPagination = (page, sortOrder, search, size) => {

        setLoading(true);
        axios({
            method: 'POST',
            url: `${baseUrl}/user/get-users/branch/paginated/${selectedBranch.id}`,
            headers: {
                Authorization: token
            },
            data: {
                page: page,
                size: size,
                sort: sortOrder,
                searchText: search
            }
        }).then((res) => {

            let data = res.data.data;
            setpaginatedUsersList((data.content));

            setPageNumber(page);
            setPageSize(size);
            setSortOrder(sortOrder);
            setSearchKey(search);

            setCount(data.totalElements);
            setLoading(false);

        }).catch((error) => {
            console.log(error);
            setLoading(false);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }



    // search in table filter - function
    const filter = (e) => {
        const keyword = e.target.value;
        setSearchText(keyword);

        if (keyword !== '') {
            const result = allUsers.filter((cat) => {
                return cat.userName.toString().toLowerCase().includes(keyword.toLowerCase());
            });
            setAllUsersForRender(result);
        } else {
            setAllUsersForRender(allUsers);
        }

    };

    // when change data, refresh parent page - function
    const refreshParentPage = () => {
        if (unitState === 'list') {
            getUsersWithPagination(0, null, null, dataTable.size);
        } else {
            getAllUsers();
        }
    }

    const removeUserFromBranch = (userId) => {
        setLoading(true);
        axios({
            method: 'POST',
            url: `${baseUrl}/user/remove/user/${userId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let data = res.data.data;
            toast.remove();
            toast.success(data, {
                position: 'bottom-center',
            });

            console.log("data: ", data);
            setLoading(false);
            getUsersWithPagination(pageNumber, sortOrder, searchKey, pageSize);

        }).catch((error) => {
            console.log(error);
            setLoading(false);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    return (
        <>
            <div><Toaster /></div>
            <div className="container-fluid">

                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h2>Users</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={`/branch-landing?bid=${selectedBranch.id}`}>{selectedBranch.BranchName}</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Users</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-md-6 col-sm-12 text-right hidden-xs">
                            <a href="#" className="btn btn-sm btn-primary" title="Assign New User"
                                onClick={() => {
                                    setShowAssignUserModal(true);
                                }}>Assign New User</a>
                        </div>
                    </div>
                </div>

                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="body">
                                <div className="row">

                                    <div className="col-md-3">
                                        <div className="input-group header-counter-center">

                                            {unitState != "list" ?
                                                <h5 className='mb-0'>
                                                    {
                                                        allUsersForRender !== null && allUsers !== null ?
                                                            <>
                                                                {
                                                                    allUsersForRender.length !== null && allUsers.length !== null ?
                                                                        <>
                                                                            {searchText.length > 0 ? `${allUsersForRender.length} / ` : ''}
                                                                            <small className="text-muted"> {allUsers.length}</small>
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                </h5>
                                                : null}

                                        </div>
                                    </div>

                                    <div className="col-md-4"></div>

                                    <div className="col-md-5">
                                        <div className="input-group" style={{ justifyContent: 'end' }}>
                                            {unitState != "list" ?
                                                <input
                                                    value={searchText}
                                                    onChange={(e) => {
                                                        filter(e);
                                                    }}
                                                    type="text" className="form-control"
                                                    placeholder="Search here..."
                                                />
                                                : null}
                                            <div className="input-group-append">
                                                {unitState != "list" ? (
                                                    <>
                                                        <button className="btn btn-outline-secondary" type="button"
                                                        ><i className="fa fa-search" /></button>
                                                        &nbsp;&nbsp;
                                                    </>
                                                ) : null}

                                                <button
                                                    className={`btn mr-1 ${unitState === 'list' ? 'btn-primary' : 'btn-secondary'}`}
                                                    type="button"
                                                    title="List View"
                                                    onClick={() => {
                                                        setUnitState("list")
                                                    }}
                                                >
                                                    <i className="fa fa-bars"> </i>
                                                </button>

                                                <button
                                                    className={`btn ${unitState === 'grid' ? 'btn-primary' : 'btn-secondary'}`}
                                                    type="button"
                                                    title="Grid View"
                                                    onClick={() => {
                                                        setUnitState("grid")
                                                    }}
                                                    style={{ borderRadius: 0 }}
                                                >
                                                    <i className="fa fa-th-large" />
                                                </button>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row clearfix">

                    {unitState === "list" ?
                        <div className="col-12">

                            <MUIDataTable
                                title={<>
                                    {count}{count === 1 ? ' record' : ' records'}.
                                    {isLoading &&
                                        <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                            size={10} /></>}
                                </>
                                }
                                data={paginatedUsersList}
                                columns={columns}
                                options={options}
                                className='text-center-table'
                            />

                        </div>
                        :
                        <div className="col-lg-12 col-md-8 col-sm-12">
                            <div className="row clearfix">

                                {allUsersForRender.length > 0 ?
                                    <>
                                        {allUsersForRender.map((user, index) =>
                                            <div className="col-lg-3 col-md-6" key={user.id}>
                                                <div className="card c_grid c_yellow">
                                                    <div className="body text-center ribbon" title={`${user.firstName} ${user.lastName}`}>
                                                        {user.userType.description === 'Teller' ?
                                                            <div className="ribbon-box indigo">Teller</div>
                                                            : ""
                                                        }

                                                        <div className="circle">
                                                            {user.image ?
                                                                <img
                                                                    src={`${user.image}?${Date.now()}`}
                                                                    className="personal-avatar-user " alt="avatar" />
                                                                :
                                                                <img src={require('../../assets/images/others/no-profile.jpg')}
                                                                    className="personal-avatar-user " alt="avatar" />
                                                            }
                                                        </div>

                                                        <h6 className="mt-3 mb-0 card-user-no user-card-fit-width" title={`Employee No - ${user.employeeNumber}`}>{user.employeeNumber}</h6>
                                                        <h6 className="mt-2">{user.firstName}</h6>
                                                        <div
                                                            className="text-center text-muted mb-3 user-card-fit-width" title={`Role - ${user.roleName}`}>{user.roleName}</div>

                                                        <button className="btn btn-default btn-sm mr-1"
                                                            title='Profile'
                                                            onClick={() => {
                                                                history(`/user-update?uid=${user.id}`, { state: { breadcrumb: true } })
                                                            }}
                                                        >
                                                            Profile
                                                        </button>
                                                        <button className="btn btn-default btn-sm" title='Assign Role'
                                                            onClick={() => {
                                                                setSelectedUser(user);
                                                                setShowAssignUserRoleModal(true);
                                                            }}
                                                        >Roles
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                    :
                                    <>
                                        <div className="col-lg-12 col-md-12">
                                            <span className='ml-2'>No Users Avaialble</span>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>

            <AssignUserModal showAssignUserModal={showAssignUserModal} setShowAssignUserModal={setShowAssignUserModal}
                refreshParentPage={refreshParentPage} />
            <AssignUserRoleModal showAssignUserRoleModal={showAssignUserRoleModal}
                setShowAssignUserRoleModal={setShowAssignUserRoleModal} selectedUser={selectedUser}
                refreshParentPage={refreshParentPage} />
            <AssignUserCategoryModal showAddUserCategoryModal={showAddUserCategoryModal}
                setShowAddUserCategoryModal={setShowAddUserCategoryModal} selectedUser={selectedUser}
            />

        </>
    )
}
export default Users