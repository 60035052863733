import axios from "axios";
import React, { useEffect, useState } from "react";
import { Donut } from "react-dial-knob";
import toast, { Toaster } from 'react-hot-toast';
import Button from 'react-bootstrap-button-loader';

const AssignUserModal = ({ showAssignUserModal, setShowAssignUserModal, refreshParentPage }) => {

    const [baseUrl, setBaseUrl] = useState('');
    const [token, setToken] = useState('');
    const [selectedBranch, setSelectedBranch] = useState({});
    const [buttonLoader, setButtonLoader] = useState(false);

    const [allUnassignedUsers, setAllUnassignedUsers] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState([]);


    // get base url & token - useEffect
    useEffect(() => {
        setBaseUrl(localStorage.getItem('API_URL'));
        setToken(localStorage.getItem('user_token'));
        setSelectedBranch(JSON.parse(localStorage.getItem('selected_branch')));
    }, [showAssignUserModal])

    // call getUnassignedUsers - useEffect
    useEffect(() => {
        if (baseUrl.length > 0 && token.length > 0 && Object.keys(selectedBranch).length > 0) {
            getUnassignedUsers();
        }
    }, [showAssignUserModal])

    // search input field action - useEffect
    useEffect(() => {
        if (searchText.length > 0) {
            searchUnassignedUsers();
        } else {
            if (baseUrl.length > 0) {
                getUnassignedUsers();
            }
        }
    }, [searchText])


    // getUnassignedUsers - function
    const getUnassignedUsers = () => {
        console.log('getUnassignedUsers funnction start');

        axios({
            method: 'GET',
            url: `${baseUrl}/user/get-unassigned-users/branch/${selectedBranch.id}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            console.log('getUnassignedUsers success');
            let data = res.data.data;

            for (let i = 0; i < data.length; i++) {
                data[i].selected = false;
            }

            console.log(data);
            setAllUnassignedUsers(data);

        }).catch((error) => {
            console.log('getUnassignedUsers error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // assignUser - function
    const assignUser = () => {
        console.log('assignUser function start');

        setButtonLoader(true);

        let ids = [];
        for (let i = 0; i < allUnassignedUsers.length; i++) {
            if (allUnassignedUsers[i].selected === true) {
                ids.push(allUnassignedUsers[i].id);
            }
        }

        if (ids.length > 0) {

            axios({
                method: 'POST',
                url: `${baseUrl}/user/assign-to-branch/branch/${selectedBranch.id}`,
                headers: {
                    Authorization: token
                },
                data: {
                    ids: ids
                }
            }).then((res) => {

                console.log('assignUser success');
                console.log(res.data.data);

                setButtonLoader(false);
                closeModal();
                refreshParentPage();

                toast.remove();
                toast.success('Successfully added', {
                    position: 'bottom-center',
                });

            }).catch((error) => {
                console.log('assignUser error');
                console.log(error);
                setButtonLoader(false);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }
            })

        } else {
            setButtonLoader(false);
            toast.error('Please select one user or more', {
                position: 'bottom-center',
            });
        }

    }

    // searchUnassignedUsers - function
    const searchUnassignedUsers = () => {
        console.log('searchUnassignedUsers function start');

        if (searchText.length > 0) {

            let searchArray = [];
            for (let i = 0; i < allUnassignedUsers.length; i++) {

                let userObject = allUnassignedUsers[i];
                var userFirstName = userObject.firstName.toLowerCase();
                var userLastName = userObject.lastName.toLowerCase();
                var userUsername = userObject.userName.toLowerCase();

                if (userFirstName.includes(searchText)) {
                    searchArray.push(userObject);
                } else if (userLastName.includes(searchText)) {
                    searchArray.push(userObject);
                } else if (userUsername.includes(searchText)) {
                    searchArray.push(userObject);
                }
            }
            setSearchResults(searchArray);
        } else {
            getUnassignedUsers();
        }

    }

    // close modal - function
    const closeModal = () => {
        setShowAssignUserModal(false);

    }


    return (
        <>
            <div><Toaster /></div>
            {showAssignUserModal ?
                <div className={`modal fade  d-block show modal-container`} id="exampleModal">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Assign New User</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        closeModal()
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="row clearfix mb-3">
                                    <div className="col-lg-6 col-md-6"></div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search here..."
                                                onKeyUp={(e) => {
                                                    setSearchText(e.target.value);
                                                }}
                                            />
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="button"
                                                    onClick={() => {
                                                        searchUnassignedUsers();
                                                    }}
                                                ><i className="fa fa-search" /></button>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row clearfix ">
                                    <div className="col-lg-12 col-md-12 col-sm-12">

                                        <div className="body">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-hover selectable-table">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Fist Name</th>
                                                            <th>Last Name</th>
                                                            <th>Employee Number</th>
                                                            <th>Username</th>
                                                            <th>Select</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {searchText.length > 0 ? (
                                                            <>
                                                                {
                                                                    searchResults.length > 0 ?
                                                                        <>
                                                                            {searchResults.map((user, index) =>
                                                                                <tr key={index}>
                                                                                    <td>{index + 1}</td>
                                                                                    <td>{user.firstName}</td>
                                                                                    <td>{user.lastName}</td>
                                                                                    <td>{user.employeeNumber}</td>
                                                                                    <td>{user.userName}</td>
                                                                                    <td>
                                                                                        <label
                                                                                            className="switch custom-swtch">
                                                                                            <input type="checkbox"
                                                                                                onChange={() => {
                                                                                                    user.selected === false ? user.selected = true : user.selected = false
                                                                                                    console.log(user);
                                                                                                }}
                                                                                            />
                                                                                            <span className="slider"></span>
                                                                                        </label>
                                                                                    </td>
                                                                                </tr>
                                                                            )}
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                            </>

                                                        ) : (
                                                            <>
                                                                {
                                                                    allUnassignedUsers.length > 0 ?
                                                                        <>
                                                                            {allUnassignedUsers.map((user, index) =>
                                                                                <tr key={index}>
                                                                                    <td>{index + 1}</td>
                                                                                    <td>{user.firstName}</td>
                                                                                    <td>{user.lastName}</td>
                                                                                    <td>{user.employeeNumber}</td>
                                                                                    <td>{user.userName}</td>
                                                                                    <td>
                                                                                        <label
                                                                                            className="switch custom-swtch">
                                                                                            <input type="checkbox"
                                                                                                onChange={() => {
                                                                                                    user.selected === false ? user.selected = true : user.selected = false
                                                                                                    console.log(user);
                                                                                                }}
                                                                                            />
                                                                                            <span className="slider"></span>
                                                                                        </label>
                                                                                    </td>
                                                                                </tr>
                                                                            )}
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }</>
                                                        )}


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal"
                                    onClick={() => {
                                        closeModal()
                                    }}
                                >Cancel
                                </button>
                                <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                    onClick={() => {
                                        assignUser();
                                    }}
                                >Assign User</Button>
                            </div>
                        </div>
                    </div>
                </div>
                : ''}
        </>
    )
}

export default AssignUserModal;