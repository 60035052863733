import React, { useEffect, useState, useRef } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';
import CircularProgress from "@mui/material/CircularProgress";


const HourlyCategoryPerformanceReport = ({ openHourlyCatePerformanceReport, dateFrom, dateTo, selectedRegionIds, changeRegionIds, reportUrl }) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [allreportsNew, setAllReportsNew] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const timeoutRef = useRef(null);


    const [dataTable, setDataTable] = useState({
        page: 0,
        size: 10,
        search: '',
        order: null,
    });

    const setTable = (tableState) => {
        setDataTable({
            page: tableState.page,
            search: tableState.searchText,
            size: tableState.rowsPerPage,
            order: tableState.sortOrder
        });
    };

    const columns = [
        {
            label: 'Date',
            name: 'issuedDate',
            options: {
                sort: true,
                filter: false,
                customBodyRender: (value) => (
                    <p style={{ margin: 0, width: '100px' }}>{value}</p>
                )
            }
        },
        {
            label: 'Hour',
            name: 'hours',
            options: {
                sort: true,
                filter: false,
                customBodyRender: (value) => (
                    <p style={{ margin: 0, width: '100px' }}>{value}</p>
                )
            }
        },
        {
            label: 'Category',
            name: 'category',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Branch',
            name: 'branchName',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Tickets Issued',
            name: 'issuedTickets',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Tickets Transferred',
            name: 'transferredTickets',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Tickets Served",
            name: 'servedTickets',
            options: {
                sort: true,
                filter: false,
            }
        },

        {
            label: "Pending Tickets",
            name: 'notServedTickets',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Serving Tickets",
            name: 'servingTickets',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "No-show Tickets",
            name: 'noShowTickets',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "% of Total Tickets",
            name: 'ticketPercentage',
            options: {
                sort: false,
                filter: false,
                customBodyRender: (value) => (
                    <p className='m-0'>{value} %</p>
                )
            }
        },
        {
            label: "Target Wait time",
            name: 'targetWaitTime',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Avg Wait Time",
            name: 'averageWaitingTime',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Max Wait Time",
            name: 'maxWaitTime',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Within Accepted Wait",
            name: 'withinAcceptWaitTime',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Wait time Service Level",
            name: 'waitTimeServiceLevel',
            options: {
                sort: false,
                filter: false,
                customBodyRender: (value) => (
                    <p className='m-0'>{value} %</p>
                )
            }
        },

        {
            label: "Target Service time",
            name: 'targetServiceTime',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Avg Service Time",
            name: 'averageServiceTime',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Max Service Time",
            name: 'maxServiceTime',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Within Accepted Service",
            name: 'withinAcceptServiceTime',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Service time Service Level",
            name: 'serviceTimeServiceLevel',
            options: {
                sort: false,
                filter: false,
                customBodyRender: (value) => (
                    <p className='m-0'>{value} %</p>
                )
            }
        },

    ];

    const options = {
        // tableBodyHeight: '80vh',
        filterType: 'dropdown',
        filter: false,
        responsive: 'vertical',
        serverSide: true,
        count: count,
        download: false,
        jumpToPage: true,
        selectableRows: 'none',
        storageKey: '_cr_hcpr_table',
        print: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        },
        onTableChange: (action, tableState) => {

            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want

            switch (action) {
                case 'changePage':
                    getHourlyCatePerformanceReport(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'sort':
                    getHourlyCatePerformanceReport(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'changeRowsPerPage':
                    setRowsPerPage(tableState.rowsPerPage);
                    getHourlyCatePerformanceReport(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'search':
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }
                    timeoutRef.current = setTimeout(() => getHourlyCatePerformanceReport(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage), 2000);
                    setTable(tableState);
                    break;
                default:
                    break;
            }
        },
    };



    // when pick date, call  getHourlyCatePerformanceReport function
    useEffect(() => {
        if (openHourlyCatePerformanceReport) {
            if (dateFrom.length > 0 && dateTo.length > 0) {
                getHourlyCatePerformanceReport(0, null, null, rowsPerPage);
            }
        }
    }, [openHourlyCatePerformanceReport, dateFrom, dateTo])

    // when change selected region array, call getHourlyCatePerformanceReport - useEffect
    useEffect(() => {
        if (openHourlyCatePerformanceReport) {
            getHourlyCatePerformanceReport(0, null, null, rowsPerPage);
        }
    }, [changeRegionIds])


    // getHourlyCatePerformanceReport - function
    const getHourlyCatePerformanceReport = (page, sortOrder, search, size) => {
        setIsLoading(true);

        axios({
            method: 'POST',
            url: `${reportUrl}/report-controller/get/hourly-category-performance-data/paginated/customer/${userDetails.cid}`,
            headers: {
                Authorization: token
            },
            data: {
                "page": page,
                "size": size,
                "dateFrom": dateFrom,
                "dateTo": dateTo,
                "sort": sortOrder,
                "searchText": search,
                branchIds: selectedRegionIds
            }

        }).then((res) => {

            let data = res.data.data;

            setAllReportsNew((data.content))
            setCount(data.totalElements);
            setIsLoading(false);

        }).catch((error) => {
            console.log(error);
            setIsLoading(false);

            if (error.response !== undefined) {
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }


    return (
        <>
            <div><ToastContainer /></div>

            <MUIDataTable
                title={
                    <>
                        {isLoading ?
                            <CircularProgress color="secondary" size={20} /> : <>{count} records.</>}
                    </>
                }
                data={allreportsNew}
                columns={columns}
                options={options}
                className='text-center-table'
            />

        </>
    )
}

export default HourlyCategoryPerformanceReport
