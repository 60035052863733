import React, { useState, useEffect } from 'react';
import { Col, Nav, Row, Tab, Tabs } from 'react-bootstrap';
import BasicBarChart from '../ChartsImpl/DetailsPageCharts/BasicBarChart';
import MUIDataTable from 'mui-datatables';
import { createTheme } from "@mui/material";
import toast from "react-hot-toast";
import axios from 'axios';
import CircularProgress from "@mui/material/CircularProgress";

let myTimeout = null;
const BranchTableServingTicketModal = (props) => {

    // const [donutChartColors, setdonutChartColors] = useState(['#6435c9', '#7d53d6', '#9773e4', '#a28ad6']);
    const [listType, setListType] = useState('all');

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [update, setUpdate] = useState('1s')

    const [tokenData, setTokenData] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (props.showTicketServingData === false) return;
        if (props.servingTicketsInc > 0 || props.messageInc1001 !== '' || props.messageInc1002 !== '' || props.messageInc2001 !== '' || props.messageInc3001 !== '') {
            getServingTicketData();
        }
    }, [props.servingTicketsInc, props.messageInc1002, props.messageInc1001, props.messageInc2001, props.messageInc3001]);

    const columns = [
        {
            label: 'Token No',
            name: 'ticketNumber',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: "Category",
            name: 'category',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Language',
            name: 'lang',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'Operator',
            name: 'firstName',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Counter',
            name: 'counterName',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'Issued time',
            name: 'issuedTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Called time',
            name: 'startTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Serving time',
            name: 'servingTime',
            options: {
                filter: false,
                sort: true,
            }
        }
    ];

    const columns_1 = [
        {
            label: "Branch",
            name: 'branchName',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Token No',
            name: 'ticketNumber',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: "Category",
            name: 'category',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Language',
            name: 'lang',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'Operator',
            name: 'firstName',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Counter',
            name: 'counterName',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'Issued time',
            name: 'issuedTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Called time',
            name: 'startTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Serving time',
            name: 'servingTime',
            options: {
                filter: false,
                sort: true,
            }
        }
    ];
    const options = {
        tableBodyHeight: '50vh',
        filter: false,
        responsive: 'vertical',
        serverSide: false,
        count: count,
        download: false,
        viewColumns: false,
        jumpToPage: true,
        selectableRows: 'none',
        print: false,
        rowsPerPage: rowsPerPage,
        onChangeRowsPerPage: (n) => {
            setRowsPerPage(n)
        },
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (tokenData !== null && tokenData.length > 0) {
                for (let index = 0; index < tokenData.length; index++) {
                    const element = tokenData[index];
                    element.servingTimeToSec = element.servingTimeToSec + 5;

                    let hours = Math.floor(element.servingTimeToSec / 3600);
                    let minutes = Math.floor((element.servingTimeToSec - (hours * 3600)) / 60);
                    let seconds = element.servingTimeToSec - (hours * 3600) - (minutes * 60);

                    if (hours < 10) { hours = "0" + hours; }
                    if (minutes < 10) { minutes = "0" + minutes; }
                    if (seconds < 10) { seconds = "0" + seconds; }

                    element.servingTime = `${hours}:${minutes}:${seconds}`;
                }

                console.log("token data: ", tokenData);

                setTokenData(tokenData);
                setUpdate(new Date().toString());
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [tokenData]);

    const getServingTicketData = () => {
        setLoading(true);

        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/get-serving-ticket-details/inner-data/branch/${props.selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('getServedTicketsData success');
            let data = res.data.data;
            // loopChartData(data.countAndPercentage);

            // setchartData(data.countAndPercentage);
            setTokenData(data);

            setCount(data.length);
            setLoading(false);

        }).catch((error) => {
            console.log('getTicketIssuedDetails error');
            console.log(error);
            setLoading(false);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    return (
        <>
            {props.showTicketServingData ?
                <div id="megamenu" className={`megamenu particles_js details-page open `}>
                    <span
                        onClick={() => {
                            props.setShowTicketServingData(false);
                        }}
                        className="megamenu_toggle btn btn-danger"><i className="icon-close"></i></span>
                    <div className="container">
                        <div className="row clearfix">
                            <div className="col-12">
                                <div className="card">
                                    <div className="body">
                                        <div style={{ textAlign: 'center' }}>
                                            <h4>Serving Tickets Data</h4>
                                            <small>{props.date}</small>
                                        </div>
                                        <br></br>
                                        <div className="row clearfix">
                                            <div className="col-12">
                                                <div className="muidatatablecontainer">
                                                    {props.selectedBranchLevel === 4 ? (
                                                        <MUIDataTable
                                                            title={<>
                                                                {count}{count === 1 ? ' record' : ' records'}.
                                                                {isLoading &&
                                                                    <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                                                        size={20} /></>}
                                                            </>
                                                            }
                                                            data={tokenData}
                                                            columns={columns}
                                                            options={options}
                                                        />
                                                    ) : (
                                                        <MUIDataTable
                                                            title={<>
                                                                {count}{count === 1 ? ' record' : ' records'}.
                                                                {isLoading &&
                                                                    <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                                                        size={20} /></>}
                                                            </>
                                                            }
                                                            data={tokenData}
                                                            columns={columns_1}
                                                            options={options}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div id="particles-js"></div>
                </div>
                : null}
        </>
    )
}

export default BranchTableServingTicketModal