import React, { useState, useEffect } from 'react'
import 'rc-time-picker/assets/index.css';
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Button from 'react-bootstrap-button-loader';
import Swal from "sweetalert2";
import { Tab, Tabs } from 'react-bootstrap';

// used for add and edit
const AddSkillsMasterModal = ({ showAddSkillsMasterModal, setShowAddSkillsMasterModal, selectedRow, }) => {

    const [baseUrl, setBaseUrl] = useState('');
    const [token, setToken] = useState('');
    const [userDetails, setUserDetails] = useState('');

    const [allLanguages, setAllLanguage] = useState([]);
    const [selectedLanguageId, setSelectedLanguageId] = useState('');
    const [skillByLang, setSkillByLang] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState(null)
    const [groupDetails, setGroupDetails] = useState({});
    const [groupSkills, setGroupSkills] = useState([]);

    const [groupName, setGroupName] = useState('')
    const [disable, setDisable] = useState(false)

    const [skillName, setSkillName] = useState('');
    const [buttonLoader, setButtonLoader] = useState(false);
    const [isUpdate, setIsUpdate] = useState(0);
    const [selectedSkill, setSelectedSkill] = useState({});

    const [currentTab, setCurrentTab] = useState("1");
    const [nextTab, setNextTab] = useState();

    const [allGroups, setAllGroups] = useState([])

    //get base url effect
    useEffect(() => {
        if (showAddSkillsMasterModal) {
            setBaseUrl(localStorage.getItem('API_URL'));
            setToken(localStorage.getItem('user_token'));
            setUserDetails(JSON.parse(localStorage.getItem('user_details')))
        }
    }, [showAddSkillsMasterModal])

    useEffect(() => {
        if (currentTab !== '1') {
            getAllGroups();
        }
    }, [currentTab])

    useEffect(() => {

        if (selectedGroupId !== null && selectedLanguageId !== '') {
            getSkillGroupById(selectedGroupId, selectedLanguageId);
        }
    }, [selectedGroupId, selectedLanguageId])


    //call getAllLanguages
    useEffect(() => {
        if (baseUrl.length > 0 && token.length > 0 && Object.keys(userDetails).length > 0) {
            getAllLanguages();
        }
    }, [baseUrl, token, userDetails])


    // selectedLanguageId
    useEffect(() => {
        if (selectedLanguageId.length > 0) {
            console.log('selectedskillid', selectedLanguageId);
            getSkillByLang();
        }
    }, [selectedLanguageId])


    //getAllLanguages - function
    const getAllLanguages = () => {
        console.log('getAllLanguages function start')

        axios({
            method: 'GET',
            url: `${baseUrl}/skill/get-languages/customer/${userDetails.cid}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            console.log('getAllLanguages Success');
            let data = res.data.data
            console.log('getalllanguage', data);
            setAllLanguage(data);

        }).catch((error) => {
            console.log('getAllLanguages Error');
            console.log(error);

            if (error.response !== undefined) {
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }


    //getSkillByLang - function
    const getSkillByLang = () => {
        console.log('getSkillByLang function start');

        axios({
            method: 'GET',
            url: `${baseUrl}/skill/get-all/customer/${userDetails.cid}/lang/${selectedLanguageId}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            console.log('getSkillByLang Success');
            let data = res.data.data;
            console.log('skillbylang', data);
            setSkillByLang(data);


        }).catch((error) => {
            console.log('getSkillByLang Error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    const getAllGroups = () => {
        console.log('getAllGroups function start');

        axios({
            method: 'GET',
            url: `${baseUrl}/skill/get-skill-groups/customer/${userDetails.cid}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            console.log('getSkillByLang Success');
            let data = res.data.data;
            console.log('skillbylang', data);
            setAllGroups(data);


        }).catch((error) => {
            console.log('getSkillByLang Error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    const getSkillGroupById = (id, languageId) => {
        console.log('getSkillGroupById function start');

        axios({
            method: 'GET',
            url: `${baseUrl}/skill/get-skill-group/${id}/language/${languageId}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            console.log('getSkillByLang Success');
            let data = res.data.data;
            console.log('skillbylang', data);
            setGroupDetails(data);
            if (data.skillsList !== undefined) {
                setGroupSkills(data.skillsList);
            } else {
                setGroupSkills([]);
            }

        }).catch((error) => {
            console.log('getSkillByLang Error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    const addNewGroup = () => {
        console.log('addNewGroup function start');

        axios({
            method: 'POST',
            url: `${baseUrl}/skill/create-skill-group`,
            headers: {
                Authorization: token
            },
            data: {
                customer: userDetails.cid,
                name: groupName,
                disable: disable

            }

        }).then((res) => {
            console.log('addNewGroup Success');
            toast.success('Group added successfully', {
                position: 'bottom-center',
            });
            getAllGroups();
            setGroupName('');

        }).catch((error) => {
            console.log('addNewGroup Error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    const deleteSkillGroupById = (id) => {
        console.log('deleteSkillGroupById function start');

        Swal.fire({
            position: 'center',
            text: 'Are you sure you want to delete this group. This will remove every user assigned skills which are related to this group?',
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            width: '400px',
        }).then((result) => {
            if (result.value == true) {
                axios({
                    method: 'POST',
                    url: `${baseUrl}/skill/delete-skill-group/${id}`,
                    headers: {
                        Authorization: token
                    },

                }).then((res) => {
                    console.log('deleteSkillGroupById Success');
                    toast.remove();
                    toast.success('Group deleted successfully', {
                        position: 'bottom-center',
                    });
                    getAllGroups();

                }).catch((error) => {
                    console.log('deleteSkillGroupById Error');
                    console.log(error);

                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                })
            }
        })
    }

    const assignSkillsToGroup = (groupId, skillId) => {

        console.log('addNewGroup function start');
        axios({
            method: 'POST',
            url: `${baseUrl}/skill/add-skills-to-group/group/${groupId}/skill/${skillId}`,
            headers: {
                Authorization: token
            }

        }).then(() => {
            console.log('addNewGroup Success');

            getSkillGroupById(selectedGroupId, selectedLanguageId)

        }).catch((error) => {
            console.log('addNewGroup Error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //addNewSkill - function
    const addNewSkill = () => {
        console.log('addNewSkill function start')
        setButtonLoader(true);

        //validate
        if (skillName.length > 0) {

            axios({
                method: 'POST',
                url: `${baseUrl}/skill/add`,
                headers: {
                    "Authorization": token
                },
                data: {
                    skill: skillName,
                    customerId: userDetails.cid,
                    languageId: selectedLanguageId

                }
            }).then((res) => {
                console.log('addNewSkill Success');
                setButtonLoader(false);
                setSkillName('');

                toast.remove();
                toast.success('Skill Name Successfully added', {
                    position: 'bottom-center',
                });

                let data = res.data;
                console.log(data);

                getSkillByLang();


            }).catch((error) => {
                console.log('addNewSkill Error');
                console.log(error);
                setButtonLoader(false);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                } else {
                    setButtonLoader(false);
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }
            })
        } else {
            setButtonLoader(false);
            toast.remove();
            toast.error('Skill name can not be empty', {
                position: 'bottom-center',
            });
        }
    }


    //UpdateSkill - function
    const updateSkill = () => {
        console.log('updateSkill function start')
        setButtonLoader(true);


        // validate
        if (skillName.length > 0) {

            axios({
                method: 'POST',
                url: `${baseUrl}/skill/update`,
                headers: {
                    "Authorization": token
                },
                data: {
                    id: selectedSkill.id,
                    skill: skillName,
                    customerId: userDetails.cid,
                    languageId: selectedLanguageId

                }
            }).then((res) => {
                console.log('updateSkill Success');
                setButtonLoader(false);
                setSkillName('');
                setIsUpdate(0);


                toast.remove();
                toast.success('Upadte Skill Successfully added', {
                    position: 'bottom-center',
                });

                getSkillByLang();


            }).catch((error) => {
                console.log('updateSkill Error');
                console.log(error);
                setButtonLoader(false);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                } else {
                    setButtonLoader(false);
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }
            })
        } else {
            setButtonLoader(false);
            toast.remove();
            toast.error('Skill name can not be empty', {
                position: 'bottom-center',
            });
        }
    }


    //deleteSkill - function
    const deleteSkill = (item) => {
        console.log('deleteSkill function start')
        console.log('***', selectedSkill);
        setButtonLoader(true);

        Swal.fire({
            position: 'center',
            text: 'Do you want to delete this Skill?',
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            width: '400px',
        }).then((result) => {
            if (result.value == true) {
                axios({
                    method: 'POST',
                    url: `${baseUrl}/skill/delete/customer/${userDetails.cid}/skill/${item.id}`,
                    headers: {
                        "Authorization": token
                    },


                }).then((res) => {
                    console.log('deleteSkill Success');
                    setButtonLoader(false);

                    toast.remove();
                    toast.success('Delete Skill Successfully', {
                        position: 'bottom-center',
                    });

                    getSkillByLang();

                }).catch((error) => {
                    console.log('deleteSkilll Error');
                    console.log(error);
                    setButtonLoader(false);

                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        setButtonLoader(false);
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                })
            }
        })
    }


    //closeModal - function
    const closeModal = () => {
        setSelectedLanguageId('');
        setSkillName('');
        setShowAddSkillsMasterModal(false);
        setSkillByLang([]);
        setIsUpdate(0);
    }


    return (
        <>
            <div><Toaster /></div>
            {showAddSkillsMasterModal ? (
                <div className={`modal fade d-block show modal-container`} id="category-modal">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Skill Management
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        closeModal()
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <Tabs id="uncontrolled-tab-example"
                                activeKey={nextTab}
                                onSelect={(key) => {
                                    setCurrentTab(key);
                                    setNextTab(key);
                                }}
                            >
                                <Tab eventKey="1" title="Add Skills" tabClassName="custom_tab">
                                    <div className="modal-body">
                                        <form className='mt-3'>
                                            <div className="container-fluid mb-3">
                                                <div className="row clearfix">
                                                    <div className="col-md-12 col-sm-12 mb-2">
                                                        <div className="input-group input-group-sm mb-3">
                                                            <p className="input-field-side-text mr-3">Language : </p>
                                                            {Object.keys(selectedRow).length === 0 ? (
                                                                <select className="custom-select modal-select-field" id="inputGroupSelect01"
                                                                    value={selectedLanguageId}
                                                                    onChange={(e) => {
                                                                        setSelectedLanguageId(e.target.value);
                                                                    }}
                                                                >
                                                                    <option defaultValue hidden>Choose...</option>
                                                                    {allLanguages.map((item, index) =>
                                                                        <option value={item.id} key={index} >
                                                                            {item.lang}
                                                                        </option>
                                                                    )}
                                                                </select>
                                                            ) : (
                                                                <input disabled type="text" className="form-control" aria-label="Small"
                                                                    aria-describedby="inputGroup-sizing-sm" value={selectedRow.language} />
                                                            )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row clearfix">
                                                    <div className="col-md-12 col-sm-12">
                                                        <div className="input-group input-group-sm mb-3">
                                                            <p className="input-field-side-text mr-3">Skill name : </p>
                                                            <input type="text" className="form-control" aria-label="Small" style={{ height: "33px" }}
                                                                aria-describedby="inputGroup-sizing-sm"
                                                                value={skillName}
                                                                onChange={(e) => {
                                                                    setSkillName(e.target.value);
                                                                }}
                                                            />

                                                            {isUpdate === 0 ? (
                                                                <Button loading={buttonLoader} type="button" className="btn btn-primary ml-2 l-h-1"
                                                                    onClick={() => {
                                                                        addNewSkill();
                                                                    }}
                                                                >Add </Button>
                                                            ) : (
                                                                <Button loading={buttonLoader} type="button" className="btn btn-primary ml-2 l-h-1"
                                                                    onClick={() => {
                                                                        updateSkill();
                                                                    }}
                                                                > update</Button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="container-fluid">
                                                <div className="row clearfix">
                                                    <div className="col-md-12 col-sm-6 mb-1">
                                                        <h6 className="font300 mb-3">Skill List</h6>
                                                        <div className="card">
                                                            <div className="body taskboard planned_task" style={{ height: '270px', overflowY: 'scroll' }}>
                                                                <div className="dd" data-plugin="nestable">
                                                                    <ol className="dd-list">
                                                                        {
                                                                            skillByLang.length > 0 ? (
                                                                                skillByLang.map((item, i) => (
                                                                                    <div className='col-md-12 col-sm-6 mb-1'
                                                                                        key={i}>
                                                                                        <div
                                                                                            className={`dd-handle d-flex justify-content-between align-items-center `}>
                                                                                            {item.skill}

                                                                                            <div className="action">
                                                                                                <i className="fa fa-edit action-icon" title='Edit'
                                                                                                    onClick={() => {
                                                                                                        setSkillName(item.skill);
                                                                                                        setIsUpdate(1);
                                                                                                        setSelectedSkill(item);
                                                                                                    }}
                                                                                                />
                                                                                                <i className="fa fa-trash-o action-icon" title='Delete'
                                                                                                    onClick={() => {

                                                                                                        deleteSkill(item);
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            ) : (
                                                                                <div class="alert alert-custom alert-dismissible" role="alert">
                                                                                    <i class="fa fa-info-circle"></i> Select a language
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </Tab>
                                <Tab eventKey="2" title="Skill Groups" tabClassName="custom_tab">
                                    <div className="modal-body">
                                        <form className='mt-3'>
                                            <div className="container-fluid mb-3">

                                                <div className="row clearfix">
                                                    <div className="col-md-12 col-sm-12">
                                                        <div className="input-group input-group-sm mb-3">
                                                            <p className="input-field-side-text mr-3">Group name : </p>
                                                            <input type="text" className="form-control" aria-label="Small" style={{ height: "33px" }}
                                                                aria-describedby="inputGroup-sizing-sm"
                                                                value={groupName}
                                                                onChange={(e) => {
                                                                    setGroupName(e.target.value);
                                                                }}
                                                            />

                                                            {isUpdate === 0 ? (
                                                                <Button loading={buttonLoader} type="button" className="btn btn-primary ml-2 l-h-1"
                                                                    onClick={() => {
                                                                        addNewGroup();
                                                                    }}
                                                                >Add </Button>
                                                            ) : (
                                                                <Button loading={buttonLoader} type="button" className="btn btn-primary ml-2 l-h-1"
                                                                    onClick={() => {
                                                                        updateSkill();
                                                                    }}
                                                                > update</Button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="container-fluid">
                                                <div className="row clearfix">
                                                    <div className="col-md-12 col-sm-6 mb-1">
                                                        <h6 className="font300 mb-3">Group List</h6>
                                                        <div className="card">
                                                            <div className="body taskboard planned_task" style={{ height: '270px', overflowY: 'scroll' }}>
                                                                <div className="dd" data-plugin="nestable">
                                                                    <ol className="dd-list">
                                                                        {
                                                                            allGroups.length > 0 ? (
                                                                                allGroups.map((item, i) => (
                                                                                    <div className='col-md-12 col-sm-6 mb-1'
                                                                                        key={i}>
                                                                                        <div
                                                                                            className={`dd-handle d-flex justify-content-between align-items-center `}>
                                                                                            {item.name}

                                                                                            <div className="action">
                                                                                                <i className="fa fa-trash-o action-icon" title='Delete'
                                                                                                    onClick={() => {
                                                                                                        deleteSkillGroupById(item.id)
                                                                                                    }}
                                                                                                />
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            ) : (
                                                                                <div class="alert alert-custom alert-dismissible" role="alert">
                                                                                    <i class="fa fa-info-circle"></i> Select a language
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </Tab>
                                <Tab eventKey="3" title="Edit Groups" tabClassName="custom_tab">
                                    <div className="modal-body">
                                        <form className='mt-3'>
                                            <div className="container-fluid mb-3">
                                                <div className="row clearfix">
                                                    <div className="col-md-12 col-sm-12 mb-2">
                                                        <div className="input-group input-group-sm mb-3">
                                                            <p className="input-field-side-text mr-3">Group : </p>
                                                            {Object.keys(selectedRow).length === 0 ? (
                                                                <select className="custom-select modal-select-field" id="inputGroupSelect01"
                                                                    value={selectedGroupId}
                                                                    onChange={(e) => {
                                                                        setSelectedGroupId(e.target.value);
                                                                    }}
                                                                >
                                                                    <option defaultValue hidden>Choose...</option>
                                                                    {allGroups.map((item, index) =>
                                                                        <option value={item.id} key={index}>
                                                                            {item.name}
                                                                        </option>
                                                                    )}
                                                                </select>
                                                            ) : (
                                                                <input disabled type="text" className="form-control" aria-label="Small"
                                                                    aria-describedby="inputGroup-sizing-sm" value={selectedRow.language} />
                                                            )
                                                            }
                                                        </div>

                                                        <div className="input-group input-group-sm mb-3">
                                                            <p className="input-field-side-text mr-3">Filter by language : </p>
                                                            {Object.keys(selectedRow).length === 0 ? (
                                                                <select className="custom-select modal-select-field" id="inputGroupSelect01"
                                                                    value={selectedLanguageId}
                                                                    onChange={(e) => {
                                                                        setSelectedLanguageId(e.target.value);
                                                                    }}
                                                                >
                                                                    <option defaultValue hidden>Choose...</option>
                                                                    {allLanguages.map((item, index) =>
                                                                        <option value={item.id} key={index} >
                                                                            {item.lang}
                                                                        </option>
                                                                    )}
                                                                </select>
                                                            ) : (
                                                                <input disabled type="text" className="form-control" aria-label="Small"
                                                                    aria-describedby="inputGroup-sizing-sm" value={selectedRow.language} />
                                                            )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="container-fluid">
                                                <div className="row clearfix">
                                                    <div className="col-md-12 col-sm-6 mb-1">
                                                        <h6 className="font300 mb-3">Skill List</h6>
                                                        <div className="card">
                                                            <div className="body taskboard planned_task" style={{ height: '270px', overflowY: 'scroll' }}>
                                                                <div className="dd" data-plugin="nestable">
                                                                    <ol className="dd-list">
                                                                        {
                                                                            groupSkills.length > 0 ? (
                                                                                groupSkills.map((item, i) => (
                                                                                    <div className='col-md-12 col-sm-6 mb-1'
                                                                                        key={i}>
                                                                                        {item.selected === true ? (
                                                                                            <div className={`dd-handle d-flex justify-content-between align-items-center `}
                                                                                                style={{ background: '#6c757d', color: 'black' }}
                                                                                                onClick={() => {assignSkillsToGroup(selectedGroupId, item.id)}}
                                                                                            >
                                                                                                {item.skill}
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div
                                                                                                className={`dd-handle d-flex justify-content-between align-items-center `}
                                                                                                onClick={() => {assignSkillsToGroup(selectedGroupId, item.id)}}
                                                                                            >
                                                                                                {item.skill}

                                                                                                <div className="action">
                                                                                                    {/* <i className="fa fa-trash-o action-icon" title='Delete'
                                                                                                        onClick={() => {

                                                                                                            deleteSkill(item);
                                                                                                        }}
                                                                                                    /> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        )}

                                                                                    </div>
                                                                                ))
                                                                            ) : (
                                                                                <div class="alert alert-custom alert-dismissible" role="alert">
                                                                                    <i class="fa fa-info-circle"></i> Select a language
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </Tab>
                            </Tabs>

                            <div className='modal-footer'>
                                <div className="col-md-3 col-sm-12">
                                    <button type="button" className="btn btn-default"
                                        onClick={() => {
                                            closeModal();
                                        }}
                                    >Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null
            }
        </>
    )
}

export default AddSkillsMasterModal;