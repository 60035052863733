import React, { useEffect, useState } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import AgentReport from '../ReportsImpl/RealTimeReportTables/AgentReport';
import QueueDetailedReport from '../ReportsImpl/RealTimeReportTables/QueueDetailedReport';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';


const RealTimeReports = ({ selectedRegionIds, changeRegionIds }) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [openAgentReport, setOpenAgentReport] = useState(false);
    const [openQueueDetailedReport, setOpenQueueDetailedReport] = useState(false);

    const [reportsAccess, setReportsAccess] = useState([]);


    // call getReportAccess - useEffect
    useEffect(() => {
        getReportAccess();
    }, [RealTimeReports])

    // get report access - function
    const getReportAccess = () => {
        axios({
            method: 'GET',
            url: `${baseUrl}/report-controller/get/report-access/customer/${userDetails.cid}/parent/Real Time Reports/get-report-access`,
            headers: {
                Authorization: token
            },
        }).then((res) => {

            let data = res.data.data;
            setReportsAccess(data);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }


    return (
        <>
            <div className="row clearfix">
                <div className="col-lg-12">

                    <div className="card">

                        <Accordion className="accordion report-accordion" id="accordion" defaultActiveKey="">

                            {reportsAccess.map((item, i) => {

                                if (item.name === 'Agent Report' && item.active) {
                                    return (
                                        <div>
                                            <div className="card-header" id="headingOne">
                                                <h5 className="mb-0">
                                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" className="btn btn-link vertically-align-center-flex"
                                                        onClick={() => {
                                                            if (openAgentReport) {
                                                                setOpenAgentReport(false);
                                                            } else {
                                                                setOpenAgentReport(true);
                                                                setOpenQueueDetailedReport(false)
                                                            }
                                                        }}
                                                    >
                                                        Agent Report
                                                        <div className='float-right vertically-align-center-flex-2'>
                                                            {userDetails !== null ? (
                                                                <>
                                                                    {/* <a href={`${baseUrl}/report-controller/download-pdf/original-ticket-details-report/${dateFrom}/${dateTo}/${selectedRegionIds.length > 0 ? selectedRegionIds.toString() : userDetails.bid}`}
                                                                    target='_blank' className="btn btn-sm btn-default" title="View PDF"
                                                                ><i class="fa fa-file-pdf-o" aria-hidden="true"></i></a> */}

                                                                    {item.excel ? (
                                                                        <a href={`${baseUrl}/real-time/get/xl/agent-report/${selectedRegionIds.length > 0 ? selectedRegionIds.toString() : userDetails.bid}`}
                                                                            target='_blank' className="btn btn-sm btn-default" title="View Excel"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                            }}
                                                                        ><i class="fa fa-file-excel-o" aria-hidden="true"></i></a>
                                                                    ) : null}

                                                                </>
                                                            ) : null}
                                                            <span className="p-l-20"><i className="fa fa-angle-down" aria-hidden="true"></i></span>
                                                        </div>

                                                    </Accordion.Toggle>
                                                </h5>
                                            </div>
                                            <Accordion.Collapse eventKey="0" className="collapse">
                                                <AgentReport openAgentReport={openAgentReport} selectedRegionIds={selectedRegionIds} changeRegionIds={changeRegionIds} />
                                            </Accordion.Collapse>
                                        </div>
                                    );
                                } else if (item.name === 'Queue Detailed Report' && item.active) {
                                    return (
                                        <div>
                                            <div className="card-header" id="headingOne">
                                                <h5 className="mb-0">
                                                    <Accordion.Toggle as={Button} variant="link" eventKey="1" className="btn btn-link vertically-align-center-flex"
                                                        onClick={() => {
                                                            if (openQueueDetailedReport) {
                                                                setOpenQueueDetailedReport(false);
                                                            } else {
                                                                setOpenQueueDetailedReport(true);
                                                                setOpenAgentReport(false)
                                                            }
                                                        }}
                                                    >
                                                        Queue Detailed Report

                                                        <div className='float-right vertically-align-center-flex-2'>
                                                            {userDetails !== null ? (
                                                                <>
                                                                    {/* <a href={`${baseUrl}/report-controller/download-pdf/original-ticket-details-report/${dateFrom}/${dateTo}/${selectedRegionIds.length > 0 ? selectedRegionIds.toString() : userDetails.bid}`}
                                                                    target='_blank' className="btn btn-sm btn-default" title="View PDF"
                                                                ><i class="fa fa-file-pdf-o" aria-hidden="true"></i></a> */}

                                                                    {item.excel ? (
                                                                        <a href={`${baseUrl}/real-time/get/xl/queue-report-detailed/${selectedRegionIds.length > 0 ? selectedRegionIds.toString() : userDetails.bid}`}
                                                                            target='_blank' className="btn btn-sm btn-default" title="View Excel"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                            }}
                                                                        ><i class="fa fa-file-excel-o" aria-hidden="true"></i></a>
                                                                    ) : null}

                                                                </>
                                                            ) : null}
                                                            <span className="p-l-20"><i className="fa fa-angle-down" aria-hidden="true"></i></span>
                                                        </div>

                                                    </Accordion.Toggle>
                                                </h5>
                                            </div>
                                            <Accordion.Collapse eventKey="1" className="collapse">
                                                <QueueDetailedReport openQueueDetailedReport={openQueueDetailedReport} selectedRegionIds={selectedRegionIds} changeRegionIds={changeRegionIds} />
                                            </Accordion.Collapse>
                                        </div>
                                    );
                                }

                            })}

                        </Accordion>

                    </div>

                </div>
            </div>
        </>
    )
}

export default RealTimeReports