import React, { useState, useEffect, useRef } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { Tab, Tabs } from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';
import axios from "axios";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';

import { Stage, Layer, Image, Line } from 'react-konva';
import useImage from 'use-image';
import TextShapeMini from '../ComponentsImpl/ReactKonva/TextShapeMini';
import URLImageMini from '../ComponentsImpl/ReactKonva/URLImageMini';
import RectangleMini from '../ComponentsImpl/ReactKonva/RectangleMini';

import RamChart from '../ChartsImpl/KiosksManagementCharts/RamChart';
import StorageChart from '../ChartsImpl/KiosksManagementCharts/StorageChart';

// import

// let today = new Date();
// const showSecond = true;
// const str = showSecond ? 'HH:mm:ss' : 'HH:mm';
const timeFormat = 'HH:mm:ss';

// used for add and edit
const AddKiosksModal = ({ showAddKioskModal, setShowAddKioskModal, getAllKiosks, selectedRow, setSelectedRow, modalType }) => {

    const car = useRef();
    const stageRef = React.useRef();

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const selectedBranch = JSON.parse(localStorage.getItem('selected_branch'));

    const [openTime, setOpenTime] = useState();
    const [closeTime, setCloseTime] = useState();

    const [buttonLoader, setButtonLoader] = useState(false);
    const [refresh, setRefresh] = useState(0);

    const [showChart, setShowChart] = useState(false);

    const [kiosksname, setKiosksName] = useState('');
    const [type, setType] = useState('');
    const [macAddress, setMacAddress] = useState('');
    const [isnetted, setIsNetted] = useState(false);
    const [kioskModel, setKioskModel] = useState('');

    const [isAllDays, setIsAllDays] = useState(false);
    const [monOpenTime, setMonOpenTime] = useState('');
    const [monCloseTime, setMonCloseTime] = useState('');
    const [tuesOpenTime, setTuesOpenTime] = useState('');
    const [tuesCloseTime, setTuesCloseTime] = useState('');
    const [wedOpenTime, setWedOpenTime] = useState('');
    const [wedCloseTime, setWedCloseTime] = useState('');
    const [thursOpenTime, setThursOpenTime] = useState('');
    const [thursCloseTime, setThursCloseTime] = useState('');
    const [friOpenTime, setFriOpenTime] = useState('');
    const [friCloseTime, setFriCloseTime] = useState('');
    const [satOpenTime, setSatOpenTime] = useState('');
    const [satCloseTime, setSatCloseTime] = useState('');
    const [sunOpenTime, setSunOpenTime] = useState('');
    const [sunCloseTime, setSunCloseTime] = useState('');

    const [dateTimesList, setDateTimesList] = useState([]);
    const [getData, setGetData] = useState({});

    const [kioskDetails, setKioskDetails] = useState({})
    const [storagePercentage, setStoragePercentage] = useState();
    const [ramPercentage, setRamPercentage] = useState();

    // templates
    const [allTemplates, setAllTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(0);

    // for time picker
    var date = "2022-06-02";
    var time = "00:00:00";
    var timeAndDate = moment(date + ' ' + time);
    const [timePickerDefaultValue, setTimePickerDefaultValue] = useState(timeAndDate);

    // useEffect(() => {
    //   setRefresh(refresh + 1);
    // }, [refresh])


    // call getAllTemplatesByBranch - useffect
    useEffect(() => {
        if (showAddKioskModal) {
            if (baseUrl.length > 0 && token.length > 0 && Object.keys(selectedBranch).length > 0) {
                getAllTemplatesByBranch();
            }
        }
    }, [showAddKioskModal])

    //set getDateTime - useEffect
    useEffect(() => {
        if (dateTimesList.length > 0) {
            if (dateTimesList[0].openTime !== undefined) setSunOpenTime(dateTimesList[0].openTime);
            if (dateTimesList[0].closeTime !== undefined) setSunCloseTime(dateTimesList[0].closeTime);
            if (dateTimesList[1].openTime !== undefined) setMonOpenTime(dateTimesList[1].openTime);
            if (dateTimesList[1].closeTime !== undefined) setMonCloseTime(dateTimesList[1].closeTime);
            if (dateTimesList[2].openTime !== undefined) setTuesOpenTime(dateTimesList[2].openTime);
            if (dateTimesList[2].closeTime !== undefined) setTuesCloseTime(dateTimesList[2].closeTime);
            if (dateTimesList[3].openTime !== undefined) setWedOpenTime(dateTimesList[3].openTime);
            if (dateTimesList[3].closeTime !== undefined) setWedCloseTime(dateTimesList[3].closeTime);
            if (dateTimesList[4].openTime !== undefined) setThursOpenTime(dateTimesList[4].openTime);
            if (dateTimesList[4].closeTime !== undefined) setThursCloseTime(dateTimesList[4].closeTime);
            if (dateTimesList[5].openTime !== undefined) setFriOpenTime(dateTimesList[5].openTime);
            if (dateTimesList[5].closeTime !== undefined) setFriCloseTime(dateTimesList[5].closeTime);
            if (dateTimesList[6].openTime !== undefined) setSatOpenTime(dateTimesList[6].openTime);
            if (dateTimesList[6].closeTime !== undefined) setSatCloseTime(dateTimesList[6].closeTime);
        }
        setIsAllDays(getData.allDay);
    }, [dateTimesList])

    //is update data - useffect
    useEffect(() => {
        if (modalType === 1 || modalType === 2) {
            if (Object.keys(selectedRow).length > 0) {
                getKioskById();
                getDateTimeById();
            }
        }
    }, [selectedRow])

    // getKioskById - function
    const getKioskById = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/kiosk/get-kiosk/${selectedRow.id}`,
            headers: {
                Authorization: token
            },
        }).then((res) => {

            let data = res.data.data

            setKiosksName(data.name);
            setMacAddress(data.mac);
            setIsNetted(data.allowNetted);
            setSelectedTemplate(data.designId);
            if (data.brand != undefined)
                setKioskModel(data.brand)

            if (data.freeMemory != undefined) {
                let storagePer = 100 - (Math.round((data.freeMemory / data.totalMemory) * 100))
                console.log('storagePer ', storagePer);
                setStoragePercentage(storagePer);

                let ramPer = 100 - (Math.round((data.freeRam / data.totalRam) * 100))
                console.log('ramPer ', ramPer);
                setRamPercentage(ramPer);

                setShowChart(true);
            }

            setKioskDetails(data);


        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // getAllTemplatesByBranch - function
    const getAllTemplatesByBranch = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/kiosk/kiosk-design/get-all/branch/${selectedBranch.id}`,
            headers: {
                Authorization: token
            },
        }).then((res) => {

            let data = res.data.data
            let newScreenList = resizeComponentsForScreenPreview(data);
            setAllTemplates(newScreenList);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })

    }

    // after get all templates, resize components for screen preview - function
    const resizeComponentsForScreenPreview = (screenList) => {

        for (let j = 0; j < screenList.length; j++) {

            let screenComponentList = screenList[j].components;

            let tempArray = [];

            if (screenList[j].landscape) {
                screenList[j].gridWidth = 630;
                screenList[j].gridHeight = 390;
            } else {
                screenList[j].gridWidth = 390;
                screenList[j].gridHeight = 630;
            }

            for (let i = 0; i < screenComponentList.length; i++) {
                const component = screenComponentList[i];

                if (component.type === 'rect') {

                    var object = {
                        fill: hex2rgba(component.fill),
                        height: component.h / 5,
                        hex: component.fill,
                        id: component.id,
                        name: component.name,
                        rgba: hex2rgbaobject(component.fill),
                        type: component.type,
                        width: component.w / 5,
                        x: component.x / 5,
                        y: component.y / 5
                    }
                    tempArray.push(object);

                } else if (component.type === 'img' || component.type === 'btn') {

                    var object = {
                        height: component.h / 5,
                        // hex: component.fill,
                        id: component.id,
                        imgId: component.imageId,
                        name: component.name,
                        // rgba: hex2rgbaobject(component.fill),
                        src: component.imageUrl,
                        type: component.type,
                        width: component.w / 5,
                        x: component.x / 5,
                        y: component.y / 5
                    }
                    tempArray.push(object);

                } else if (component.type === 'text') {

                    var object = {
                        fill: hex2rgba(component.componentText.color),
                        height: component.h / 5,
                        hex: component.componentText.color,
                        id: component.id,
                        name: component.name,
                        rgba: hex2rgbaobject(component.componentText.color),
                        text: component.componentText.text,
                        textProps: {
                            fontSize: component.componentText.size / 5,
                            align: component.componentText.align,
                            fontFamily: component.componentText.font.name,
                        },
                        type: component.type,
                        width: component.w / 5,
                        x: component.x / 5,
                        y: component.y / 5
                    }
                    tempArray.push(object);
                }

            }

            screenList[j].components = tempArray;

        }

        return screenList;

    }

    // getDateTimeById - function
    const getDateTimeById = () => {
        axios({
            method: 'GET',
            url: `${baseUrl}/kiosk/date-time/kiosk/${selectedRow.id}`,
            headers: {
                "Authorization": token
            }
        }).then((res) => {
            setDateTimesList(res.data.data);
        }).catch((error) => {
            console.log(error)
            if (error.response !== undefined) {
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // addKiosks - function
    const addKiosks = () => {
        setButtonLoader(true);

        let isValidate = validate();

        if (isValidate) {

            axios({
                method: 'POST',
                url: `${baseUrl}/kiosk/add`,
                headers: {
                    "Authorization": token
                },
                data: {
                    "name": kiosksname,
                    "mac": macAddress,
                    "allowNetted": isnetted,
                    "brand": kioskModel,
                    "date": [
                        {
                            "day": 1,
                            "openTime": sunOpenTime,
                            "closeTime": sunCloseTime
                        },

                        {
                            "day": 2,
                            "openTime": monOpenTime,
                            "closeTime": monCloseTime
                        },

                        {
                            "day": 3,
                            "openTime": tuesOpenTime,
                            "closeTime": tuesCloseTime
                        },

                        {
                            "day": 4,
                            "openTime": wedOpenTime,
                            "closeTime": wedCloseTime
                        },

                        {
                            "day": 5,
                            "openTime": thursOpenTime,
                            "closeTime": thursCloseTime
                        },

                        {
                            "day": 6,
                            "openTime": friOpenTime,
                            "closeTime": friCloseTime
                        },

                        {
                            "day": 7,
                            "openTime": satOpenTime,
                            "closeTime": satCloseTime
                        },

                    ],
                    branch: {
                        id: selectedBranch.id
                    },
                    designId: selectedTemplate


                }
            }).then((res) => {
                setButtonLoader(false);
                setKiosksName('');
                closeModal();

                toast.remove();
                toast.success('Kiosks Name Successfully added', {
                    position: 'bottom-center',
                });

                getAllKiosks();

            }).catch((error) => {
                console.log(error);
                setButtonLoader(false);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }
            })
        }
    }

    // update kiosks - function
    const updateKiosks = () => {

        let isValidated = validate();

        if (isValidated) {
            axios({
                method: 'POST',
                url: `${baseUrl}/kiosk/update/${selectedRow.id}`,
                headers: {
                    "Authorization": token
                },
                data: {
                    id: selectedRow.id,
                    branch: {
                        id: selectedBranch.id,
                    },
                    name: kiosksname,
                    mac: macAddress,
                    allowNetted: isnetted,
                    brand: kioskModel,
                    date: [
                        {
                            "day": 1,
                            "openTime": sunOpenTime,
                            "closeTime": sunCloseTime
                        },
                        {
                            "day": 2,
                            "openTime": monOpenTime,
                            "closeTime": monCloseTime
                        },
                        {
                            "day": 3,
                            "openTime": tuesOpenTime,
                            "closeTime": tuesCloseTime
                        },
                        {
                            "day": 4,
                            "openTime": wedOpenTime,
                            "closeTime": wedCloseTime
                        },
                        {
                            "day": 5,
                            "openTime": thursOpenTime,
                            "closeTime": thursCloseTime
                        },
                        {
                            "day": 6,
                            "openTime": friOpenTime,
                            "closeTime": friCloseTime
                        },
                        {
                            "day": 7,
                            "openTime": satOpenTime,
                            "closeTime": satCloseTime
                        },
                    ],
                    designId: selectedTemplate
                }

            }).then((res) => {

                setButtonLoader(false);
                getAllKiosks();
                closeModal();

                toast.remove();
                toast.success('Successfully updated', {
                    position: 'bottom-center',
                });


            }).catch((error) => {
                console.log(error);
                setButtonLoader(false);

                if (error.response !== undefined) {
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                } else {
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }
            })
        }
    }

    // validate - function
    const validate = () => {

        if (kiosksname.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Kiosks name empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (macAddress.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('MAC address empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (selectedTemplate.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Select a kiosk template', {
                position: 'bottom-center',
            });
            return false;
        } else {
            return true;
        }
    }

    // close modal - function
    const closeModal = () => {
        setKiosksName('');
        setType('');
        setMacAddress('');
        setKioskModel('');

        setIsAllDays(false);
        setMonOpenTime('');
        setMonCloseTime('');
        setTuesOpenTime('');
        setTuesCloseTime('');
        setWedOpenTime('');
        setWedCloseTime('');
        setThursOpenTime('');
        setThursCloseTime('');
        setFriOpenTime('');
        setFriCloseTime('');
        setSatOpenTime('');
        setSatCloseTime('');
        setSunOpenTime('');
        setSunCloseTime('');

        setSelectedTemplate('');
        setSelectedRow('');
        setShowChart(false);

        setShowAddKioskModal(false);
    }


    // hex to rgba
    const hex2rgba = (hex) => {
        let c = hex.substring(1).split('');

        if (!/^#(([\dA-Fa-f]{3}){1,2}|([\dA-Fa-f]{4}){1,2})$/.test(hex)) {
            throw new Error('Your hexadecimal color is not correct.');
        }

        switch (c.length) {
            case 3:
                c = [c[0] + c[0], c[1] + c[1], c[2] + c[2], 'ff'];
                break;
            case 4:
                c = [c[0] + c[0], c[1] + c[1], c[2] + c[2], c[3] + c[3]];
                break;
            case 6:
                c = [c[0] + c[1], c[2] + c[3], c[4] + c[5], 'ff'];
                break;
            case 8:
                c = [c[0] + c[1], c[2] + c[3], c[4] + c[5], c[6] + c[7]];
                break;
        }

        c = c.map((char) => parseInt(char, 16).toString());
        c[3] = (Math.round((parseInt(c[3], 10) / 255) * 100) / 100).toString();
        return c[3] === '1'
            ? `rgb( ${c[0]}, ${c[1]}, ${c[2]})`
            : `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${c[3]})`;
    }

    // hex to rgba
    const hex2rgbaobject = (hex) => {
        let c = hex.substring(1).split('');

        if (!/^#(([\dA-Fa-f]{3}){1,2}|([\dA-Fa-f]{4}){1,2})$/.test(hex)) {
            throw new Error('Your hexadecimal color is not correct.');
        }

        switch (c.length) {
            case 3:
                c = [c[0] + c[0], c[1] + c[1], c[2] + c[2], 'ff'];
                break;
            case 4:
                c = [c[0] + c[0], c[1] + c[1], c[2] + c[2], c[3] + c[3]];
                break;
            case 6:
                c = [c[0] + c[1], c[2] + c[3], c[4] + c[5], 'ff'];
                break;
            case 8:
                c = [c[0] + c[1], c[2] + c[3], c[4] + c[5], c[6] + c[7]];
                break;
        }

        c = c.map((char) => parseInt(char, 16).toString());
        c[3] = (Math.round((parseInt(c[3], 10) / 255) * 100) / 100).toString();
        return c[3] === '1'
            ? `rgb( ${c[0]}, ${c[1]}, ${c[2]})`
            : {
                r: c[0],
                g: c[1],
                b: c[2],
                a: c[3]
            }
    }

    const BGImageMini = ({ bgImg, gridWidth, gridHeight }) => {
        const [bgImage] = useImage(bgImg);
        return <Image image={bgImage}
            width={gridWidth / 5}
            height={gridHeight / 5}
        />;
    };


    return (
        <div>
            <div><Toaster /></div>
            {showAddKioskModal ? (
                <div className={`modal fade d-block show modal-container`} id="kiosk-modal">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    {modalType === 0 ? <>Add New Kiosk</> : modalType === 1 ? <>Edit Kiosk</> : <>View Kiosk</>}
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => closeModal()}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">

                                <form className='kiosks-add-form mt-3'>

                                    <div className="row clearfix">
                                        <div className="col-md-6 col-sm-12">
                                            <div className="input-group input-group-sm mb-3 modal-input-group">
                                                <p className="input-field-side-text">Kiosk Name : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    value={kiosksname}
                                                    disabled={modalType === 2 ? true : false}
                                                    onChange={(e) => {
                                                        setKiosksName(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <div className="input-group input-group-sm mb-3 modal-input-group">
                                                <p className="input-field-side-text">Mac Address : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    value={macAddress}
                                                    disabled={modalType === 2 ? true : false}
                                                    onChange={(e) => {
                                                        setMacAddress(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row clearfix">
                                        <div className="col-md-6 col-sm-12">
                                            <div className="input-group input-group-sm mb-3">
                                                <p className="input-field-side-text">Allow Netted Services : </p>

                                                <label
                                                    className="switch custom-swtch">
                                                    <input type="checkbox"
                                                        value={isnetted}
                                                        defaultChecked={selectedRow.allowNetted === true}
                                                        disabled={modalType === 2 ? true : false}
                                                        onChange={(e) => {
                                                            setIsNetted(e.target.checked);
                                                        }} />
                                                    <span className="slider"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row clearfix">

                                        <div className="col-md-6 col-sm-12">
                                            <div className="input-group input-group-sm mb-3">
                                                <p className="input-field-side-text">Kiosk Model : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    value={kioskModel}
                                                    disabled={modalType === 2 ? true : false}
                                                    onChange={(e) => {
                                                        setKioskModel(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        {modalType === 2 && showChart ? (
                                            <>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="input-group input-group-sm mb-3">
                                                        <p className="input-field-side-text">Kiosk Type : </p>
                                                        <input type="text" className="form-control" aria-label="Small"
                                                            aria-describedby="inputGroup-sizing-sm"
                                                            value={kioskDetails.type}
                                                            disabled={modalType === 2 ? true : false}
                                                            onChange={(e) => {
                                                                // setKiosksName(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col-sm-12">
                                                    <div className="input-group input-group-sm mb-3">
                                                        <p className="input-field-side-text">App Version : </p>
                                                        <input type="text" className="form-control" aria-label="Small"
                                                            aria-describedby="inputGroup-sizing-sm"
                                                            value={kioskDetails.appVersion}
                                                            disabled={modalType === 2 ? true : false}
                                                            onChange={(e) => {
                                                                // setKiosksName(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col-sm-12">
                                                    <div className="input-group input-group-sm mb-3">
                                                        <p className="input-field-side-text">IP Address : </p>
                                                        <input type="text" className="form-control" aria-label="Small"
                                                            aria-describedby="inputGroup-sizing-sm"
                                                            value={kioskDetails.ip}
                                                            disabled={modalType === 2 ? true : false}
                                                            onChange={(e) => {
                                                                // setKiosksName(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col-sm-12">
                                                    <div className="input-group input-group-sm mb-3">
                                                        <p className="input-field-side-text">Device Date Time : </p>
                                                        <input type="text" className="form-control" aria-label="Small"
                                                            aria-describedby="inputGroup-sizing-sm"
                                                            value={kioskDetails.deviceDateTime}
                                                            disabled={modalType === 2 ? true : false}
                                                            onChange={(e) => {
                                                                // setKiosksName(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>


                                            </>
                                        ) : null}

                                    </div>

                                    {modalType === 2 && showChart ? (
                                        <div className="row clearfix">
                                            <div className="col-md-6 col-sm-6">
                                                <div className='kiosks-detail-chart'>
                                                    {
                                                        showChart ? <RamChart ramPercentage={ramPercentage} /> : null
                                                    }
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-6">
                                                <div className='kiosks-detail-chart'>
                                                    {
                                                        showChart ? <StorageChart storagePercentage={storagePercentage} /> : null
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    ) : null}


                                    <div className="row clearfix">
                                        <div className='col-md-12 col-sm-12 select-kiosk-design-cont'>
                                            <div className="row clearfix">
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="input-group input-group-sm mt-2 b-3">
                                                        <p className="input-field-side-text">Select Template : </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='kiosk-designs-list mt-3' style={{ padding: '0 50px' }}>
                                                <div className="row clearfix">

                                                    {allTemplates.length > 0 ?
                                                        <>
                                                            {
                                                                allTemplates.map((item, i) =>
                                                                    <div className="col-md-3" key={i}>
                                                                        <div className={`kiosk-design ${selectedTemplate === item.id ? 'active' : ''} text-center`}
                                                                            onClick={() => {
                                                                                if (modalType !== 2) {
                                                                                    setSelectedTemplate(item.id);
                                                                                }
                                                                            }}
                                                                        >
                                                                            <div className="card">
                                                                                <div className="body">
                                                                                    <div className='kiosk-design-thumbnail'>
                                                                                        <Stage
                                                                                            width={item.gridWidth / 5}
                                                                                            height={item.gridHeight / 5}
                                                                                            ref={stageRef}
                                                                                            onContextMenu={e => {
                                                                                                e.evt.preventDefault()
                                                                                            }}
                                                                                            style={{
                                                                                                backgroundColor: 'white',
                                                                                                width: item.gridWidth / 5 + 'px',
                                                                                                height: item.gridHeight / 5 + 'px',
                                                                                                margin: '0px auto 0px',
                                                                                                borderRadius: '0px'
                                                                                            }}
                                                                                        >
                                                                                            <Layer>
                                                                                                <BGImageMini bgImg={item.backgroundImageURL} gridWidth={item.gridWidth} gridHeight={item.gridHeight} />
                                                                                            </Layer>


                                                                                            <Layer
                                                                                                hitGraphEnabled={true}>
                                                                                                {item.components.map((rect, i) => {
                                                                                                    return (

                                                                                                        rect.type === 'text' ?

                                                                                                            <TextShapeMini
                                                                                                                key={rect.id}
                                                                                                                // gridWidth={200}
                                                                                                                // gridHeight={(220 * 9 / 16)}
                                                                                                                text={rect.text}
                                                                                                                textProps={rect.textProps}
                                                                                                                shapeProps={rect}
                                                                                                            />

                                                                                                            :

                                                                                                            rect.type === 'rect' ?

                                                                                                                <RectangleMini key={rect.id}
                                                                                                                    // gridWidth={200}
                                                                                                                    // gridHeight={(220 * 9 / 16)}
                                                                                                                    shapeProps={rect}
                                                                                                                />

                                                                                                                :

                                                                                                                <URLImageMini key={rect.id}
                                                                                                                    image={rect.src}
                                                                                                                    // gridWidth={200}
                                                                                                                    // gridHeight={(220 * 9 / 16)}
                                                                                                                    shapeProps={rect}
                                                                                                                />

                                                                                                    );
                                                                                                })}
                                                                                            </Layer>

                                                                                        </Stage>
                                                                                    </div>
                                                                                    <label className='screen-name mt-2 mb-0 manager-data-ellipsis'>{item.name}</label>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                )
                                                            }
                                                        </>
                                                        :
                                                        <p>No available kiosk templates</p>

                                                    }




                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                </form>
                            </div>

                            {/* <div className="modal-footer">

                                <button type="button" className="btn btn-default"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >Cancel
                                </button>


                                {modalType === 0 ?

                                    <button loading={buttonLoader} type="button" className="btn btn-primary"
                                        onClick={() => {
                                            addKiosks();
                                        }}
                                    >Save</button>
                                    :
                                    <button loading={buttonLoader} type="button" className="btn btn-primary"
                                        onClick={() => {
                                            updateKiosks();
                                        }}
                                    >Update</button>
                                }

                            </div> */}


                            <div className="modal-footer">

                                <button type="button" className="btn btn-default"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >Cancel
                                </button>

                                {modalType === 0 ? (
                                    <>
                                        <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                            onClick={() => {
                                                addKiosks();
                                            }}
                                        >Save</Button>
                                    </>
                                ) : modalType === 1 ? (
                                    <>
                                        <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                            onClick={() => {
                                                updateKiosks();
                                            }}
                                        >Update</Button>
                                    </>)
                                    : (null)
                                }
                            </div>

                        </div>
                    </div>
                </div >
            ) : null
            }
        </div >
    )
}

export default AddKiosksModal