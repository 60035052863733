import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Donut } from "react-dial-knob";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import ReactPaginate from 'react-paginate';


const BranchSummary = () => {

    const history = useNavigate();

    let userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [branchSequence, setBranchSequence] = useState({})
    const [branchLevel, setBranchLevel] = useState(null);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [selectedBranchLevel, setSelectedBranchLevel] = useState(0);
    const [selectedBranchRegion, setselectedBranchRegion] = useState(null);
    const [selectedBranchParent, setselectedBranchParent] = useState(null);
    const [branchType, setBranchType] = useState(null);
    const [country, setCountry] = useState(null);
    const [province, setProvince] = useState(null);
    const [district, setDistrict] = useState(null);
    const [toHome, setToHome] = useState(0);
    const [toBack, setToBack] = useState(0);
    const [allDataLoaded, setAllDataLoaded] = useState(false);
    const [allSelectedDataLoad, setAllSelectedDataLoad] = useState(false);

    // const [baseUrl, setBaseUrl] = useState('');
    const baseUrl = localStorage.getItem('API_URL')
    const token = localStorage.getItem('user_token');
    const [selectCountryView, setSelectCountryView] = useState(false);

    const [selectAreaView, setSelectAreaView] = useState(false);
    const [selectBranchView, setSelectBranchView] = useState(false);

    const [region, setRegions] = useState([]);

    const [area, setAreas] = useState([]);
    const [regionsData, setRegionsData] = useState([]);
    const [regionData, setRegionData] = useState(null);

    const [branchData, setBranchData] = useState([]);

    const [DataInProvince, setDataInProvince] = useState([]);

    const [name, setName] = useState('');


    const [provinceName, setProvinceName] = useState('');
    const [areaName, setAreaName] = useState('');
    const [branchName, setBranchName] = useState('');

    const [postsPerPage] = useState(10);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    // useEffect for getAll
    useEffect(() => {
        if (baseUrl.length > 0 && token.length > 0) {
            // alert('Starter Use effect');
            getBranchSummaryData();
        }
    }, [baseUrl, token]);

    useEffect(() => {
        if (selectedRegion !== null) {
            // alert('Starter Selected Region Use effect')
            getBranchSummaryData();
        }
    }, [selectedRegion])

    const getBranchSummaryData = () => {
        // alert('getBranchSummaryData');
        let branchForUrl = setBranchIdForUrl();

        axios({
            method: 'GET',
            url: `${baseUrl}/branch/summary-data/parent-branch/${branchForUrl}/user/${userDetails['uid']}`,
            headers: {
                Authorization: token
            }
        }).then((response) => {
            let regionList = response.data.data.parentBranches;
            let branchData = response.data.data.branchList;
            // alert("Get data")

            ifBranchIsSelected(regionList);

            ifRegionalManager(regionList);

            // getBranchSequence(userDetails['userType']);

            setRegions(regionList);
            setBranchData(branchData);

            setAllDataLoaded(true);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    const setBranchIdForUrl = () => {

        let branchForUrl = 0;

        if (userDetails['userType'] !== 3) {
            // alert('User')
            if (selectedRegion !== null) {
                // alert('User Select Region')
                branchForUrl = selectedRegion;
            } else {
                // alert('User not selected a region yet')
                branchForUrl = userDetails['bid'];
            }
        } else {
            // alert('Admin')
            if (selectedRegion !== null) {
                // alert('Adimn Select Region')
                branchForUrl = selectedRegion;
            }
        }

        return branchForUrl;
    }

    const ifBranchIsSelected = (regionList) => {
        // alert('ifBranchIsSelected')
        if (selectedRegion === null) {
            // alert('Not select region yet..')
            if (userDetails['userType'] !== 3) {
                setSelectedBranchLevel(userDetails['branchLevel']);
                branchTypeCheck(userDetails['branchLevel'] + 1);
            } else {
                branchTypeCheck(1);
                if (regionList[0] !== undefined) {
                    setSelectedBranchLevel(regionList[0].level);
                }
            }
        } else {

            if (selectedBranchLevel === 4) {
                branchTypeCheck(selectedBranchLevel);
            } else {
                if (regionList[0] !== undefined) {
                    branchTypeCheck(regionList[0].level);
                }
            }
        }

    }

    const ifRegionalManager = (regionList) => {
        // alert('ifRegionalManager')
        if (userDetails['userType'] !== 3
            && userDetails['branchLevel'] !== 4
            && regionList[0] !== undefined) {

            if (regionList[0].level === 2 && regionList[0]) {
                setCountry(regionList[0].region);
            } else if (regionList[0].level === 3) {
                setProvince(regionList[0].region)
            }
        }
    }

    const branchTypeCheck = (branchLevel) => {
        // alert('branchTypeCheck')
        if (branchLevel === 1) {
            setBranchType('Countries');
        } else if (branchLevel === 2) {
            setBranchType('Provinces');
        } else if (branchLevel === 3) {
            setBranchType('Districts');
        } else if (branchLevel === 4) {
            setBranchType('Branches')
        } else {
            setBranchType('Branch');
        }
    }

    const getBranchSequence = (branchForUrl) => {
        // alert('getBranchSequence')
        axios.get(`${baseUrl}/branch/get-sequence/branch/${branchForUrl}`, {
            method: "GET",
            headers: {
                Authorization: token
            }
        }).then((response) => {
            let data = response.data.data;
            if (data.countryId !== undefined) {
                // alert('setCountry')
                setCountry(data.countryId);
            }

            if (data.provinceId !== undefined) {
                // alert('setProvince')
                setProvince(data.provinceId);
            }

            if (data.districtId !== undefined) {
                // alert('setDistrict')
                setDistrict(data.districtId);
            }

            setBranchSequence(data);
            setAllSelectedDataLoad(true);
        }).catch((error) => {
            console.log(error);
        })
    }

    // search in table
    const filter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = branchData.filter((data) => {
                return data.name.toString().toLowerCase().includes(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setBranchData(results);
        } else {
            setBranchData(branchData);
        }

        setName(keyword);
    };

    // search backspace
    const handleKeyDown = event => {
        if (event.key === 'Backspace') {
            if (event.target.value.length == 1) {
                getBranchSummaryData();
            }

        }
    };

    // navigateToDashboard - function
    const navigateToDashboard = (selectedBranchId, selectedBranchName, selectedBranchLevel, selectedBranchQueueType) => {
        history('/branch-dashboard', {
            state: {
                selectedBranchId: selectedBranchId,
                selectedBranchName: selectedBranchName,
                selectedBranchLevel: selectedBranchLevel,
                selectedBranchQueueType: selectedBranchQueueType
            }
        }
        )
    }

    //navigateToBranchTables - function
    const navigateToBranchTables = (selectedBranchId, selectedBranchName, selectedBranchLevel, selectedBranchQueueType) => {
        history('/branch-tables-page', {
            state: {
                selectedBranchId: selectedBranchId,
                selectedBranchName: selectedBranchName,
                selectedBranchLevel: selectedBranchLevel,
                selectedBranchQueueType: selectedBranchQueueType
            }
        });
    }

    return (
        <>
            {allDataLoaded === false ? (<></>) : (
                <>
                    <div className="container-fluid">
                        <div className="block-header">
                            <div className="row clearfix">
                                <div className="col-md-6 col-sm-12">
                                    <h2>Branch Summary</h2>
                                </div>
                            </div>
                        </div>

                        <div className="row clearfix">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="body">
                                        <div className="row">
                                            <div className="col-lg-7 col-md-6">
                                                <div className="input-group header-counter-center1">

                                                    {/* <div className="inline-small">
                                                    {!countryName ? <small></small> : <small>{countryName} </small>}
                                                    {!provinceName ? <small></small> : <small>/ {provinceName} </small>}
                                                    {!areaName ? <small></small> : <small>/ {areaName} </small>}
                                                    {!branchName ? <small></small> : <small>/{branchName} </small>}
    
                                                </div> */}
                                                </div>

                                            </div>


                                            <div className="col-lg-5 col-md-6 ">
                                                <div className="input-group">

                                                    <input type="text" className="form-control" placeholder="Search here..."
                                                        value={name}
                                                        onChange={filter}

                                                        onKeyDown={handleKeyDown}
                                                    />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-outline-secondary" type="button"><i
                                                            className="fa fa-search" /></button>
                                                        &nbsp;&nbsp;&nbsp;


                                                    </div>

                                                </div>

                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row clearfix'>
                            <div className="col-lg-3 col-md-4 col-sm-12">
                                <>

                                    {selectedBranchLevel === 1 ? (
                                        <>
                                            {userDetails['userType'] !== 1 ? (
                                                <>
                                                    <small className="text-muted text-center text-fit-to-button">{branchType}</small>
                                                </>
                                            ) : userDetails['userType'] !== 2 ? (
                                                <>
                                                    <small className="text-muted text-center text-fit-to-button">{branchType}</small>
                                                </>
                                            ) : (null)

                                            }
                                        </>)
                                        : selectedBranchLevel === 2 ? (
                                            <>
                                                {userDetails['userType'] !== 3 ? (
                                                    <>
                                                        <small className="text-muted text-center text-fit-to-button">{branchType}</small>
                                                    </>
                                                ) : (
                                                    <>
                                                        <button type="button" className="btn btn-primary"
                                                            onClick={() => {
                                                                setSelectedRegion(0);
                                                                setSelectedBranchLevel(selectedBranchLevel - 1);
                                                                setRegions([]);
                                                                setBranchData([]);
                                                            }}
                                                        >
                                                            <i className="fa fa-chevron-left" aria-hidden="true"> </i>
                                                        </button>
                                                        &nbsp;&nbsp;
                                                        <small className="text-muted text-center text-fit-to-button">{branchType}</small>
                                                    </>
                                                )}
                                            </>)
                                            : selectedBranchLevel === 3 ? (
                                                <>
                                                    {userDetails['userType'] !== 3 ? (
                                                        <>
                                                            {userDetails['branchLevel'] === 3 ? (
                                                                <><small className="text-muted text-center text-fit-to-button">{branchType}</small></>
                                                            ) : userDetails['branchLevel'] === 2 ?
                                                                (
                                                                    <>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <button type="button" className="btn btn-primary"
                                                                            onClick={() => {
                                                                                setSelectedRegion(country);
                                                                                setSelectedBranchLevel(selectedBranchLevel - 1);
                                                                                setRegions([]);
                                                                                setBranchData([]);
                                                                            }}
                                                                        >
                                                                            <i className="fa fa-chevron-left" aria-hidden="true"> </i>
                                                                        </button>
                                                                        &nbsp;&nbsp;
                                                                        <small className="text-muted text-center text-fit-to-button">{branchType}</small>
                                                                    </>)}

                                                        </>
                                                    ) : (
                                                        <>
                                                            <button type="button" className="btn btn-primary"
                                                                onClick={() => {
                                                                    setSelectedRegion(country);
                                                                    setSelectedBranchLevel(selectedBranchLevel - 1);
                                                                    setRegions([]);
                                                                    setBranchData([]);
                                                                }}
                                                            >
                                                                <i className="fa fa-chevron-left" aria-hidden="true"> </i>
                                                            </button>
                                                            &nbsp;&nbsp;
                                                            <button type="button" className="btn btn-primary"
                                                                onClick={() => {
                                                                    setSelectedRegion(0);
                                                                    setSelectedBranchLevel(userDetails['branchLevel']);
                                                                    setRegions([]);
                                                                    setBranchData([]);
                                                                }}
                                                            ><i
                                                                className="fa fa-home" aria-hidden="true"> </i></button>
                                                            &nbsp;&nbsp;
                                                            <small className="text-muted text-center text-fit-to-button">{branchType}</small>
                                                        </>
                                                    )}
                                                </>
                                            )
                                                : (
                                                    <>
                                                        {userDetails['userType'] !== 3 ? (
                                                            <>
                                                                {userDetails['branchLevel'] === 4 ? (
                                                                    <><small className="text-muted text-center text-fit-to-button">{branchType}</small></>
                                                                ) : userDetails['branchLevel'] === 3 ? (
                                                                    <>
                                                                        <button type="button" className="btn btn-primary"
                                                                            onClick={() => {
                                                                                setSelectedRegion(selectedRegion);
                                                                                setSelectedBranchLevel(selectedBranchLevel - 1);
                                                                                setRegions([]);
                                                                                setBranchData([]);
                                                                            }}
                                                                        >
                                                                            <i className="fa fa-chevron-left" aria-hidden="true"> </i>
                                                                        </button>
                                                                        &nbsp;&nbsp;
                                                                        <small className="text-muted text-center text-fit-to-button">{branchType}</small>
                                                                    </>
                                                                ) : userDetails['branchLevel'] === 2 ? (
                                                                    <>
                                                                        <button type="button" className="btn btn-primary"
                                                                            onClick={() => {
                                                                                setSelectedRegion(selectedBranchRegion);
                                                                                setSelectedBranchLevel(userDetails['branchLevel']);
                                                                                setRegions([]);
                                                                                setBranchData([]);
                                                                            }}
                                                                        >
                                                                            <i className="fa fa-chevron-left" aria-hidden="true"> </i>
                                                                        </button>
                                                                        &nbsp;&nbsp;
                                                                        <small className="text-muted text-center text-fit-to-button">{branchType}</small>
                                                                    </>
                                                                ) : (

                                                                    <>
                                                                        <button type="button" className="btn btn-primary"
                                                                            onClick={() => {
                                                                                setSelectedRegion(province);
                                                                                setSelectedBranchLevel(selectedBranchLevel - 1);
                                                                                setRegions([]);
                                                                                setBranchData([]);
                                                                            }}
                                                                        >
                                                                            <i className="fa fa-chevron-left" aria-hidden="true"> </i>
                                                                        </button>
                                                                        &nbsp;&nbsp;
                                                                        <button type="button" className="btn btn-primary"
                                                                            onClick={() => {
                                                                                setSelectedRegion(userDetails['bid']);
                                                                                setSelectedBranchLevel(userDetails['branchLevel']);
                                                                                setRegions([]);
                                                                                setBranchData([]);
                                                                            }}
                                                                        ><i
                                                                            className="fa fa-home" aria-hidden="true"> </i></button>
                                                                        &nbsp;&nbsp;
                                                                        <small className="text-muted text-center text-fit-to-button">{branchType}</small>
                                                                    </>
                                                                )}

                                                            </>
                                                        ) : (
                                                            <>
                                                                <button type="button" className="btn btn-primary"
                                                                    onClick={() => {
                                                                        setSelectedRegion(selectedBranchRegion);
                                                                        setSelectedBranchLevel(selectedBranchLevel - 1);
                                                                        setRegions([]);
                                                                        setBranchData([]);
                                                                    }}
                                                                >
                                                                    <i className="fa fa-chevron-left" aria-hidden="true"> </i>
                                                                </button>
                                                                &nbsp;&nbsp;
                                                                <button type="button" className="btn btn-primary"
                                                                    onClick={() => {
                                                                        setSelectedRegion(0);
                                                                        setSelectedBranchLevel(userDetails['branchLevel']);
                                                                        setRegions([]);
                                                                        setBranchData([]);
                                                                    }}
                                                                ><i
                                                                    className="fa fa-home" aria-hidden="true"> </i></button>
                                                                &nbsp;&nbsp;
                                                                <small className="text-muted text-center text-fit-to-button">{branchType}</small>
                                                            </>
                                                        )}
                                                    </>
                                                    // <>

                                                    //     <button type="button" className="btn btn-primary"
                                                    //         onClick={() => { test3(district) }}
                                                    //     >
                                                    //         <i className="fa fa-chevron-left" aria-hidden="true"> </i>
                                                    //     </button>
                                                    //     &nbsp;&nbsp;
                                                    //     <button type="button" className="btn btn-primary"
                                                    //         onClick={() => { setToHome(true) }}
                                                    //     ><i
                                                    //         className="fa fa-home" aria-hidden="true"> </i></button>
                                                    //     &nbsp;&nbsp;
                                                    //     <small className="text-muted text-center text-fit-to-button">Branches {selectedBranchLevel}</small>
                                                    // </>
                                                )}


                                    <ul className={selectedBranchLevel < 3 ? `list-group mt-2 mb-4 right-custom-nav` : `list-group mt-2 right-custom-nav`}>
                                        {region.length > 0 ? (
                                            <>
                                                {region.map((data) =>
                                                    <>
                                                        {selectedBranchLevel !== 4 && userDetails['branchLevel'] !== 3 ? (
                                                            <li
                                                                className={`list-group-item d-flex justify-content-between align-items-center country-select cursor-pointer`}

                                                                // className="list-group-item d-flex justify-content-between align-items-center country-select"
                                                                // onClick={(event) => [setSelectProvinceView(true), getProvince(`${item.id}`, `${item.name}`, `${index}`)
                                                                // ]}

                                                                onClick={() => {
                                                                    setSelectedRegion(data.id);
                                                                    setSelectedBranchLevel(data.level + 1);
                                                                    getBranchSequence(data.id);
                                                                    if (data.region === null) {
                                                                        setselectedBranchRegion(0);
                                                                    } else {
                                                                        setselectedBranchRegion(data.region);
                                                                    }
                                                                    setRegions([]);
                                                                    setBranchData([]);
                                                                }}
                                                            >
                                                                <label>{data.name}</label>

                                                                <span className="badge badge-info badge-hover">{data.counts}</span>
                                                            </li>
                                                        ) : (null)}
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            null
                                        )}
                                    </ul>
                                </>
                            </div>

                            {/* className={selectedBranchLevel !== 4 ? `col-lg-9 col-md-8 col-sm-12` : `col-lg-12 col-md-12 col-sm-12`} */}

                            <div className={selectedBranchLevel !== 4 && userDetails['branchLevel'] !== 3 ? `col-lg-9 col-md-8 col-sm-12` : `col-lg-12 col-md-12 col-sm-12`}>
                                <div className="table-responsive">
                                    {branchData.length > 0 ? (
                                        <table className="table table-hover table-custom spacing5">
                                            <thead>
                                                <tr>
                                                    <th>Branch</th>
                                                    <th>Counters</th>
                                                    <th>Waiting</th>
                                                    <th>VIP</th>
                                                    <th>Served</th>
                                                    <th>AUX</th>
                                                    <th>Table</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {branchData.map((data) =>
                                                    <tr key={data.id} 
                                                        // style={{ cursor: 'pointer' }}
                                                        // onClick={() => {
                                                        //     navigateToBranchTables(data.id, data.name, data.level, data.queueTypeId)
                                                        // }}
                                                    >
                                                        <td>
                                                            <div className='link'
                                                                onClick={() => {
                                                                    navigateToDashboard(data.id, data.name, data.level, data.queueTypeId)
                                                                }}
                                                            >{data.name}</div>
                                                            <p className="mb-0 text-muted">Issued Tokens
                                                                - {data.issuedTicket}</p>
                                                            <p className="mb-0 text-muted">Serving Tokens
                                                                - {data.servingTicket}</p>
                                                        </td>
                                                        <td>
                                                            <div className="wrapper1">
                                                                {data.onlineCounters !== 0 ? (
                                                                    <>
                                                                        <a className="button2"></a><span>{data.onlineCounters} / {data.allCounters}</span>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <a className="button3"></a><span>{data.onlineCounters} / {data.allCounters}</span>
                                                                    </>
                                                                )}

                                                            </div>
                                                            {/* <div className="wrapper1">
                                                                <a className="button3"></a><span>13</span>
                                                            </div> */}
                                                        </td>
                                                        <td>
                                                            {data.waitingTickets !== 0 ? (
                                                                <h4 style={{ color: '#d25555', margin: '0' }}>{data.waitingTickets}</h4>
                                                            ) : (
                                                                <h4 style={{ margin: '0' }}>{data.waitingTickets}</h4>
                                                            )}

                                                        </td>
                                                        <td>
                                                            {data.vip !== 0 ? (
                                                                <h4 style={{ color: 'rgb(198 196 69)', margin: '0' }}>{data.vip}</h4>
                                                            ) : (
                                                                <h4 style={{ margin: '0' }}>{data.vip}</h4>)}

                                                        </td>
                                                        <td>
                                                            {data.issuedTicket !== 0 ? (
                                                                <Donut
                                                                    className="knob"
                                                                    diameter={50}
                                                                    min={0}
                                                                    max={data.issuedTicket}
                                                                    step={1}
                                                                    value={data.servedTickets}
                                                                    theme={{
                                                                        donutThickness: 5,
                                                                        donutColor: '#4caf50',
                                                                        centerColor: '#282B2F',
                                                                        bgrColor: '#383b40'
                                                                    }}
                                                                />
                                                            ) : (
                                                                <Donut
                                                                    className="knob"
                                                                    diameter={50}
                                                                    min={0}
                                                                    max={100}
                                                                    step={1}
                                                                    value={data.servedTickets}
                                                                    theme={{
                                                                        donutThickness: 5,
                                                                        donutColor: '#4caf50',
                                                                        centerColor: '#282B2F',
                                                                        bgrColor: '#383b40'
                                                                    }}
                                                                />
                                                            )}

                                                        </td>
                                                        <td>
                                                            <Donut
                                                                className="knob"
                                                                diameter={50}
                                                                min={0}
                                                                max={100}
                                                                step={1}
                                                                value={data.auxCount}
                                                                theme={{
                                                                    donutThickness: 5,
                                                                    donutColor: '#17C2D7',
                                                                    centerColor: '#282B2F',
                                                                    bgrColor: '#383b40'
                                                                }}
                                                            />
                                                        </td>
                                                        {/* <td>
                                                            <div className='parent'>
                                                                <div className='child inline-block-child'>
                                                                    <Donut
                                                                        className="knob"
                                                                        diameter={50}
                                                                        min={0}
                                                                        max={100}
                                                                        step={1}
                                                                        value={data.servedTickets}
                                                                        theme={{
                                                                            donutThickness: 5,
                                                                            donutColor: '#17C2D7',
                                                                            centerColor: '#282B2F',
                                                                            bgrColor: '#383b40'
                                                                        }}
                                                                    />
                                                                </div>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                <div className='child inline-block-child'>
                                                                    <Donut
                                                                        className="knob"
                                                                        diameter={50}
                                                                        min={0}
                                                                        max={100}
                                                                        step={1}
                                                                        value={data.waitExceptions}
                                                                        theme={{
                                                                            donutThickness: 5,
                                                                            donutColor: '#4caf50',
                                                                            centerColor: '#282B2F',
                                                                            bgrColor: '#383b40'
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td> */}


                                                        <td className="btnAction">
                                                            <div>
                                                                {/* <div className="col-lg-12">
                                                                    <button className="btn btn-sm btn-primary mb-2"
                                                                        type="button"
                                                                        title={`${data.name} - Branch Table`}
                                                                        onClick={() => {
                                                                            navigateToDashboard(data.id, data.name, data.level, data.queueTypeId)
                                                                        }}
                                                                    ><i className="fa fa-list" /></button>
                                                                </div> */}
                                                                <div className="col-lg-12">
                                                                    <button className="btn btn-sm btn-primary"
                                                                        type="button"
                                                                        title={`${data.name} - Branch Table`}
                                                                        onClick={() => {
                                                                            navigateToBranchTables(data.id, data.name, data.level, data.queueTypeId)
                                                                        }}
                                                                    ><i className="fa fa-table" /></button>
                                                                </div>

                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    ) : (
                                        null
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );

}
export default BranchSummary