import React, { useState } from 'react'
import { Donut } from 'react-dial-knob';
import toast, { Toaster } from 'react-hot-toast';
import { Col, Nav, Row, Tab, Tabs } from 'react-bootstrap';
import Donutchart from '../ChartsImpl/Analytics/AudianceAnalyticsDountchart';
import Linechart from '../ChartsImpl/Analytics/AudianceAnalyticslinecharts';
import Barchart from '../ChartsImpl/Analytics/AudianceAnalyticsBarchart';
import Splinechart from '../ChartsImpl/Analytics/AudianceAnalyticssplinechart';


const AudianceAnalytics = () => {


    return (
        <>
            <div><Toaster /></div>
            <div className="container-fluid">
                <div className="block-header">
                    <div className="row clearfix">

                        <div className="col-md-12 col-sm-12">
                            <h1>Audiance Analytics</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">TS</a></li>
                                    <li className="breadcrumb-item"><a href="/">Maradana</a></li>
                                    <li className="breadcrumb-item"><a href="/">Branch Management</a></li>
                                    <li className="breadcrumb-item"><a href="/">Analytics page</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Category Analytics page</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>


                <div className="row clearfix">
                    <div className="col-lg-3 col-md-6">
                        <div className="card">
                            <div className="body w_knob">
                                <div className="c_know">
                                    <Donut
                                        className="knob"
                                        diameter={50}
                                        min={0}
                                        max={100}
                                        step={1}
                                        spaceMaxFromZero={false}
                                        value={50}
                                        theme={{
                                            donutThickness: 2,
                                            donutColor: '#17C2D7',
                                            centerColor: '#282B2F',
                                            bgrColor: '#383b40'
                                        }}
                                    />
                                </div>
                                <div className='card-right'>
                                    <h6>Users</h6>
                                    <span>{100} users</span>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-3 col-md-6">
                        <div className="card">
                            <div className="body w_knob">
                                <div className="c_know">
                                    <Donut
                                        className="knob"
                                        diameter={50}
                                        min={0}
                                        max={100}
                                        step={1}
                                        spaceMaxFromZero={false}
                                        value={50}
                                        theme={{
                                            donutThickness: 2,
                                            donutColor: '#17C2D7',
                                            centerColor: '#282B2F',
                                            bgrColor: '#383b40'
                                        }}
                                    />
                                </div>
                                <div className='card-right'>
                                    <h6>Users</h6>
                                    <span>{100} users</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="card">
                            <div className="body w_knob">
                                <div className="c_know">
                                    <Donut
                                        className="knob"
                                        diameter={50}
                                        min={0}
                                        max={100}
                                        step={1}
                                        spaceMaxFromZero={false}
                                        value={50}
                                        theme={{
                                            donutThickness: 2,
                                            donutColor: '#17C2D7',
                                            centerColor: '#282B2F',
                                            bgrColor: '#383b40'
                                        }}
                                    />
                                </div>
                                <div className='card-right'>
                                    <h6>Users</h6>
                                    <span>{100} users</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="card">
                            <div className="body w_knob">
                                <div className="c_know">
                                    <Donut
                                        className="knob"
                                        diameter={50}
                                        min={0}
                                        max={100}
                                        step={1}
                                        spaceMaxFromZero={false}
                                        value={50}
                                        theme={{
                                            donutThickness: 2,
                                            donutColor: '#17C2D7',
                                            centerColor: '#282B2F',
                                            bgrColor: '#383b40'
                                        }}
                                    />
                                </div>
                                <div className='card-right'>
                                    <h6>Users</h6>
                                    <span>{100} users</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row clearfix">
                </div>

                <div className="row clearfix">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="body">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h6 className="mb-0">Customer Base</h6>
                                    </div>
                                    <ul className="nav nav-tabs2">
                                        <li className="nav-item"><a className="nav-link active show" data-toggle="tab" href="/">Today</a></li>
                                        <li className="nav-item"><a className="nav-link" data-toggle="tab" href="/">Week</a></li>
                                        <li className="nav-item"><a className="nav-link" data-toggle="tab" href="/">Month</a></li>
                                    </ul>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-lg-4 col-md-12 col-sm-12">
                                        <small>Count of Customers for all Branches under used languages.</small>
                                        <Donutchart />
                                    </div>
                                    <div className="col-lg-8 col-md-12 col-sm-12">
                                        <Linechart />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

             

                                <div className="row clearfix">
                                    <div className="col-xl-6 col-lg-6 col-md-6">
                                        <div className="card">
                                            <div className="body">
                                                <h6 className="mb-4 font300">Issued tokens per week</h6>
                                                {/* <h3 className="mb-0 mt-3 font300">24,301 <span className="text-green font-15">+3.7%</span></h3>
                                                <small>The total number of Page Impressions within the date range</small> */}
                                                <Barchart />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6">
                                        <div className="card">
                                            <div className="body">
                                                <h6 className="mb-4 font300">Monthly Customer usage</h6>
                                                {/* <div className="d-flex justify-content-start">
                                                    <div className="mr-4">
                                                        <h3 className="mb-0 mt-3 font300">3,095</h3>
                                                        <small>Organic Search</small>
                                                    </div>
                                                    <div className="mr-4">
                                                        <h3 className="mb-0 mt-3 font300">2,763</h3>
                                                        <small>Referral</small>
                                                    </div>
                                                    <div>
                                                        <h3 className="mb-0 mt-3 font300">763</h3>
                                                        <small>Other</small>
                                                    </div>
                                                </div> */}
                                                <Splinechart />
                                            </div>
                                        </div>
                                    </div>
                             </div>
                    
            </div>
        </>
    )
}

export default AudianceAnalytics

