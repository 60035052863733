import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import CreateUserModal from "../ModalImpl/CreateUserModal";
import axios from "axios";
import MainUserProfile from "../ModalImpl/MainUserProfile";
import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';


const MainUser = () => {

    let activeStyle = {
        backgroundColor: "#007bff",
        color: '#fff'
    }
    let normalStyle = {
    }
    let spanNormalStyle = {}
    let spanActiveStyle = {
        backgroundColor: "white"
    }

    let userDetails = JSON.parse(localStorage.getItem("user_details"));

    const [baseUrl, setBaseUrl] = useState('');
    const [token, setToken] = useState('');

    const history = useNavigate();
    const [selectedBranchId, setselectedBranchId] = useState(null)
    const [view, setView] = useState("list");
    const [createUser, setCreateUserModal] = useState(false);
    const [selectCountryView, setSelectCountryView] = useState(false);
    const [selectProvinceView, setSelectProvinceView] = useState(false);
    const [selectAreaView, setSelectAreaView] = useState(false);
    const [selectBranchView, setSelectBranchView] = useState(false);

    const [imageUrl, setImageUrl] = useState('');
    const [user, setUser] = useState([]);
    const [usersForRender, setUsersForRender] = useState([]);
    const [mainUserProfileModal, setMainUserProfileModal] = useState(false);

    const [country, setCountries] = useState([]);
    const [province, setProvince] = useState([]);
    const [area, setArea] = useState([]);
    const [branch, setBranch] = useState([]);

    const [regionID, setRegionId] = useState('');
    const [provinceID, setProvinceId] = useState('');
    const [areaID, setAreaId] = useState('');
    const [branchID, setBranchId] = useState('');

    const [countryName, setCountryName] = useState('');
    const [provinceName, setProvinceName] = useState('');
    const [areaName, setAreaName] = useState('');
    const [branchName, setBranchName] = useState('');

    const [activeIndex, setActiveIndex] = React.useState(-1)
    const [filteredUsers, setFilteredUsers] = useState([]);


    // the value of the search field
    const [name, setName] = useState('');

    // table for list view
    const dataTable = {
        columns: [
            {
                label: '#',
                field: 'index',
                sort: 'asc',
            },
            {
                label: 'First Name',
                field: 'firstName',
                sort: 'asc',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                },
            },
            {
                label: 'Email',
                field: 'email',
                sort: 'asc',
            },
            {
                label: 'Role',
                field: 'role',
                sort: 'asc',
            },
            {
                label: 'Type',
                name: 'description',
                sort: 'asc',
            },
            {
                label: 'Branch',
                field: 'branchName',
                sort: 'asc',
            },
            {
                label: 'Action',
                field: 'action',
                sort: 'disabled',
            }
        ],
        rows: usersForRender
    };

    // baseUrl - useEffect
    useEffect(() => {
        setBaseUrl(localStorage.getItem('API_URL'));
        setToken(localStorage.getItem('user_token'));
    }, [MainUser])

    // get all - useEffect
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            if (baseUrl.length > 0 && token.length > 0) {
                // regions();
                getUsersWithBranches();
            }
        }
    }, [baseUrl, token]);

    // add action and other columns - useEffect
    const addActionColumn = (users) => {
        let postsArray = JSON.parse(JSON.stringify(users));
        let userData = [];
        postsArray.map((item, index) => {

            item.index = (
                <div>
                    {index + 1}
                </div>
            );
            if (item.restricted == true) {

                item.index = (
                    <div className="row-text-diseble">
                        {index + 1}
                    </div>
                );
                item.id = (
                    <div className="row-text-diseble">
                        {item.id}
                    </div>
                );
                item.firstName = (
                    <div className="row-text-diseble">
                        {item.firstName}
                    </div>
                );
                item.lastName = (
                    <div className="row-text-diseble">
                        {item.lastName}
                    </div>
                );

                item.employeeNumber = (
                    <div className="row-text-diseble">
                        {item.employeeNumber}
                    </div>
                );
                item.email = (
                    <div className="row-text-diseble">
                        {item.email}
                    </div>
                );
                item.contact = (
                    <div className="row-text-diseble">
                        {item.contact}
                    </div>
                );
                item.gender = (
                    <div className="row-text-diseble">
                        {item.gender}
                    </div>
                );
                item.action = (
                    <>
                        <button type="button" className="btn btn-sm btn-default" title={`${users.restricted ? 'Active' : 'Restrict'}`} data-toggle="tooltip"
                            data-placement="top"
                            onClick={() => addActions(users[index].id, item.restricted)}
                        ><i className={`fa ${item.restricted ? 'fa-eye' : 'fa-eye-slash'}`}> </i></button>
                        &nbsp;

                        <button type="button" className="btn btn-sm btn-default disabled" title="Edit User" data-toggle="tooltip"
                            data-placement="top" style={{ cursor: 'not-allowed' }}
                        ><i className="fa fa-pencil"> </i></button>
                    </>
                )

            } else {

                item.action = (
                    <>
                        <button type="button" className="btn btn-sm btn-default" title={`${users.restricted ? 'Active' : 'Restrict'}`} data-toggle="tooltip"
                            data-placement="top"
                            onClick={() => addActions(item.id, item.restricted)}
                        ><i className={`fa ${item.restricted ? 'fa-eye' : 'fa-eye-slash'}`}> </i></button>
                        &nbsp;

                        <button type="button" className="btn btn-sm btn-default " title="Edit User" data-toggle="tooltip"
                            data-placement="top" onClick={() => [editMainUser(item.id)]}
                        ><i className="fa fa-pencil"> </i></button>
                    </>
                )
            }
            userData.push(item);
        });
        setUsersForRender(userData);
        setFilteredUsers(userData);
    }

    // search in table - function
    const filter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = filteredUsers.filter((user) => {
                return user.firstName.toString().toLowerCase().includes(keyword.toLowerCase());
            });
            setUsersForRender(results);
        } else {
            setUser(user);
        }
        setName(keyword);
    };

    // get all regions & all users - function
    const regions = () => {
        axios.get(`${baseUrl}/user/get-users/region/${0}`, {
            method: "GET",
            headers: {
                Authorization: token
            }
        }).then((response) => {
            let data = response.data.data.regions;
            let users = response.data.data.users;
            setCountries(data);
            setUser(users);

            addActionColumn(users);
        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    const getUsersWithBranches = () => {
        axios.get(`${baseUrl}/user/get-users/region/${0}/customer/${userDetails['cid']}`, {
            method: "GET",
            headers: {
                Authorization: token
            }
        }).then((response) => {
            let data = response.data.data.regions;
            let users = response.data.data.users;
            let imageUrl = response.data.data.imageUrl;
            setCountries(data);
            setUser(users);
            setImageUrl(imageUrl);

            addActionColumn(users);
        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // active inactive user - function
    const addActions = (id, status) => {

        Swal.fire({
            position: 'center',
            text: 'Do you want to change restriction of this user? ',
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            width: '400px',

        }).then((result) => {
            if (result.value == true) {

                axios({
                    method: 'POST',
                    url: `${baseUrl}/user/active-inactive/user/${id}`,
                    data: {},
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {

                    let userStatus = res.data.data;

                    if (userStatus == false) {
                        toast.remove();
                        toast.success('user active Successfully ', {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.success('user inactive Successfully ', {
                            position: 'bottom-center',
                        });
                    }
                    getUsersWithBranches();

                }).catch((error) => {
                    console.log(error);

                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                })
            }
        })
    }

    // search backspace - function
    const handleKeyDown = event => {
        if (event.key === 'Backspace') {
            if (event.target.value.length == 1) {
                // regions();
                getUsersWithBranches();
            }
        }
    };

    // back to home - function
    const handleMain = () => {
        setSelectCountryView(selectCountryView)
        setselectedBranchId(userDetails['bid'])
        setSelectProvinceView(false)
        setSelectAreaView(false)
        setSelectBranchView(false)
        // regions()    ;
        getUsersWithBranches();
        setCountryName("");
        setProvinceName("");
        setAreaName("");
        setBranchName("");
    }

    // back to region - function
    const backToRegions = () => {
        // regions();
        getUsersWithBranches();
        setCountryName('');
        setProvinceName('');
        setAreaName('');
        setBranchName('');
    }

    // back to province - function
    const backToProvince = () => {
        getProvince(regionID);
        setselectedBranchId(regionID);
        setCountryName(countryName);
        setProvinceName(provinceName);
        setAreaName('');
        setBranchName('');
    }

    // back to area - function
    const getBackToArea = () => {
        getAreasByProvince(provinceID);
        setselectedBranchId(provinceID);
        setCountryName(countryName);
        setProvinceName(provinceName);
        setAreaName(areaName);
        setBranchName('');
    }

    // get province by regions - function
    const getProvince = (RegionId, RegionName) => {
        setCountryName(RegionName);
        getProvincesByRegionId(RegionId);
    }

    const getProvincesByRegionId = (RegionId) => {
        setRegionId(RegionId)

        axios.get(`${baseUrl}/user/get-users/region/${RegionId}/customer/${userDetails['cid']}`, {
            method: "GET",
            headers: {
                Authorization: token
            }
        }).then((response) => {
            let data = response.data.data.regions;
            let user = response.data.data.users;
            setProvince(data)
            addActionColumn(user);
        }).catch((error) => {
            console.log(error);
        })
    }

    // get areas by province - function
    const getAreasByProvince = (provinceId, provinceName) => {
        setProvinceId(provinceId);
        setProvinceName(provinceName);
        axios.get(`${baseUrl}/user/get-users/region/${provinceId}/customer/${userDetails['cid']}`, {
            method: "GET",
            headers: {
                Authorization: token
            }
        }).then((response) => {
            let data = response.data.data.regions;
            let user = response.data.data.users;
            setArea(data);
            addActionColumn(user);
        }).catch((error) => {
            console.log(error);
        })
    }

    // get branches by area - function
    const getBranchesByArea = (areaId, areaName) => {
        setAreaId(areaId)
        axios.get(`${baseUrl}/user/get-users/region/${areaId}/customer/${userDetails['cid']}`, {
            method: "GET",
            headers: {
                Authorization: token
            }
        }).then((response) => {
            let data = response.data.data.regions;
            let user = response.data.data.users;
            setBranch(data);
            setAreaName(areaName)
            addActionColumn(user);
        }).catch((error) => {
            console.log(error);
        })
    }

    // get users by branch - function
    const getUsersByBranch = (branchId, branchName, index) => {

        setActiveIndex(index)
        axios.get(`${baseUrl}/user/get-users/region/${branchId}`, {
            method: "GET",
            headers: {
                Authorization: token
            }
        }).then((response) => {
            let data = response.data.data.regions;
            let user = response.data.data.users;
            setBranchName(branchName);
            setBranchId(branchId);
            addActionColumn(user);
        }).catch((error) => {
            console.log(error);
        })
    }

    // edit User - function
    const editMainUser = (id) => {
        history(`/user-update?uid=${id}`)
    }


    return <>
        <div>
            <Toaster />
        </div>
        <div className="container-fluid">

            <div className="block-header">
                <div className="row clearfix">
                    <div className="col-md-6 col-sm-12">
                        <h2>Main Users Management</h2>
                    </div>

                    {userDetails.userType === 3 ? (
                        <div className="col-md-6 col-sm-12 text-right">
                            <a href="#" className="btn btn-sm btn-primary" title=""
                                onClick={() => {
                                    setCreateUserModal(true);
                                }}
                            >Add New User</a>
                        </div>
                    ) : (null)}

                </div>
            </div>

            <div className="row clearfix">
                <div className="col-md-12">
                    <div className="card">
                        <div className="body">
                            <div className="row">
                                <div className="col-lg-7 col-md-6">
                                    <div className="input-group header-counter-center1">

                                        <div className="inline-small">
                                            {!countryName ? <small></small> : <small>{countryName} </small>}
                                            {!provinceName ? <small></small> : <small> / {provinceName} </small>}
                                            {!areaName ? <small></small> : <small> / {areaName} </small>}
                                            {!branchName ? <small></small> : <small> / {branchName} </small>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-6 ">
                                    <div className="input-group">

                                        <input type="text" className="form-control" placeholder="Search here..."
                                            value={name}
                                            onChange={filter}
                                            onKeyDown={handleKeyDown}
                                        />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" type="button"
                                            ><i
                                                    className="fa fa-search" /></button>
                                            &nbsp;&nbsp;&nbsp;

                                            {view === 'list' ?
                                                <>
                                                    <button className="btn btn-primary mr-1" type="button"
                                                        title="list view"
                                                        onClick={() => setView("list")}>
                                                        <i className="fa fa-bars"> </i>
                                                    </button>

                                                    <button className="btn btn-secondary" type="button"
                                                        title="grid view"
                                                        onClick={() => setView("grid")}
                                                        style={{ borderRadius: 0 }}><i
                                                            className="fa fa-th-large" /></button>
                                                </>
                                                :
                                                <>
                                                    <button className="btn btn-secondary mr-1" type="button"
                                                        title="list view"
                                                        onClick={() => setView("list")}>
                                                        <i className="fa fa-bars"> </i>
                                                    </button>

                                                    <button className="btn btn-primary" type="button"
                                                        title="grid view"
                                                        onClick={() => setView("grid")}
                                                        style={{ borderRadius: 0 }}><i
                                                            className="fa fa-th-large" /></button>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row clearfix">
                <div className="col-lg-3 col-md-4 col-sm-12">

                    {!selectCountryView && !selectProvinceView ?
                        <>
                            <small className="text-muted text-center text-fit-to-button">Country</small>
                            <ul className="list-group mt-2 mb-4 right-custom-nav">
                                {
                                    country.length > 0 ? (
                                        country.map((item, i) => (
                                            <li
                                                className="list-group-item d-flex justify-content-between align-items-center country-select"
                                                onClick={(event) => [setSelectProvinceView(true), getProvince(`${item.id}`, `${item.name}`), setselectedBranchId(item.id)
                                                ]}
                                            >
                                                <label>{item.name}</label>
                                                <span className="badge badge-info badge-hover">{item.usersCount}</span></li>
                                        ))

                                    ) : (

                                        <div>
                                            <li className="list-group-item d-flex justify-content-between align-items-center country-select">
                                                <label> No Regions</label>
                                                <span className="badge badge-info">0</span></li>
                                        </div>
                                    )
                                }
                            </ul>
                        </>
                        :
                        <></>
                    }

                    {selectProvinceView && !selectAreaView ?
                        <div>
                            <button type="button" className="btn btn-primary"
                                onClick={() => [setSelectProvinceView(false), backToRegions()]}>
                                <i className="fa fa-chevron-left" aria-hidden="true"> </i></button>
                            &nbsp;&nbsp;

                            <small className="text-muted">Province</small>
                            <ul className="list-group mt-2 mb-4 right-custom-nav">
                                {
                                    province.length > 0 ? (
                                        province.map((provinces, i) => (
                                            <li
                                                className="list-group-item d-flex justify-content-between align-items-center country-select"
                                                onClick={() => [setSelectAreaView(true), getAreasByProvince(`${provinces.id}`, `${provinces.name}`), setselectedBranchId(provinces.id)]}
                                            >
                                                <label>{provinces.name}</label>

                                                <span className="badge badge-info">{provinces.usersCount}</span></li>
                                        ))

                                    ) : (

                                        <div>
                                            <li className="list-group-item d-flex justify-content-between align-items-center country-select">
                                                <label> No provinces</label>
                                                <span className="badge badge-info">0</span></li>
                                        </div>
                                    )
                                }
                            </ul>
                        </div>
                        :
                        <>
                        </>
                    }

                    {selectAreaView && !selectBranchView ?
                        <>
                            <button type="button" className="btn btn-primary"
                                onClick={() => [setSelectAreaView(false), backToProvince()]}>
                                <i className="fa fa-chevron-left" aria-hidden="true"> </i></button>
                            &nbsp;&nbsp;

                            <button type="button" className="btn btn-primary" onClick={() => handleMain()}><i
                                className="fa fa-home" aria-hidden="true"> </i></button>
                            &nbsp;&nbsp;

                            <small className="text-muted">Area</small>
                            <ul className="list-group mt-2 mb-4 right-custom-nav">

                                {area.length > 0 ?
                                    <> {area.map((areas) => {
                                        return <li
                                            className="list-group-item d-flex justify-content-between align-items-center country-select"
                                            onClick={() => [setSelectBranchView(true), getBranchesByArea(`${areas.id}`, `${areas.name}`), setselectedBranchId(areas.id)]}>
                                            {areas.name}
                                            <span className="badge badge-info">{areas.usersCount}</span></li>
                                    })}</>
                                    :
                                    <div>
                                        <li className="list-group-item d-flex justify-content-between align-items-center country-select">
                                            <h6> No Areas</h6>
                                            <span className="badge badge-info">0</span></li>
                                    </div>
                                }
                            </ul>
                        </>
                        :
                        <></>
                    }

                    {selectBranchView ?
                        <>
                            <button type="button" className="btn btn-primary"
                                onClick={() => [setSelectBranchView(false), getBackToArea()]}>
                                <i className="fa fa-chevron-left" aria-hidden="true"> </i></button>
                            &nbsp;&nbsp;

                            <button type="button" className="btn btn-primary" onClick={() => handleMain()}>
                                <i className="fa fa-home" aria-hidden="true"> </i></button>
                            &nbsp;&nbsp;

                            <small className="text-muted">Branch</small>
                            <ul className="list-group mt-2 mb-4 right-custom-nav">
                                {
                                    branch.length > 0 ? (
                                        branch.map((branches, index) => (
                                            <li
                                                className={`list-group-item d-flex justify-content-between align-items-center country-select ${activeIndex == index ? 'active' : ''} `}
                                                onClick={() => [getUsersByBranch(`${branches.id}`, `${branches.name}`, `${index}`), setselectedBranchId(branches.id)]}
                                                getUsersWithBranches
                                            >
                                                <label>{branches.name}</label>

                                                <span className="badge badge-info">{branches.usersCount}</span></li>
                                        ))

                                    ) : (

                                        <div>
                                            <li className="list-group-item d-flex justify-content-between align-items-center country-select">
                                                <label> No branches</label>
                                                <span className="badge badge-info">0</span></li>
                                        </div>
                                    )
                                }
                            </ul>
                        </>
                        :
                        <></>
                    }
                </div>

                {view === "list" ?
                    <div className="col-lg-9 col-md-8 col-sm-12">
                        <div className="card" style={{ transition: 'none', marginTop: '42px' }}>
                            <div className="body table-responsive">

                                <MDBDataTable
                                    noBottomColumns
                                    className="table table-striped table-hover dataTable js-exportable assign-user-table assign-table"
                                    displayEntries={false}
                                    paging
                                    pagesAmount={15}
                                    data={dataTable}
                                    searching={false}
                                />
                            </div>
                        </div>
                    </div>
                    :
                    <div className="col-lg-9 col-md-8 col-sm-12">
                        <div style={{ marginTop: '42px' }}>
                            <div className="row clearfix">
                                {
                                    usersForRender.length > 0 ? (

                                        usersForRender.map((users, index) => (
                                            <div className="col-lg-4 col-md-6" key={users.id}>

                                                <div className={`card c_grid ${users.restricted ? 'c_red' : 'c_green'}`} >
                                                    <div className="body text-center" title={`${users.firstName} ${users.lastName}`}>

                                                        <div className="circle">
                                                            {users.image ?

                                                                <img
                                                                    src={`${imageUrl + users.image}?${Date.now()}`}
                                                                    className="personal-avatar-user " alt="avatar" />

                                                                :
                                                                <img src={require('../../assets/images/others/no-profile.jpg')}
                                                                    className="personal-avatar-user " alt="avatar" />

                                                            }
                                                        </div>

                                                        <p className="mt-2 mb-0">{users.employeeNumber}</p>
                                                        <h6>{users.firstName}</h6>


                                                        <div className="mt-3 mb-0">
                                                            <button type="button"
                                                                className="btn btn-default btn-sm mr-1"
                                                                title={`${users.restricted ? 'Active' : 'Restrict'}`}
                                                                onClick={() => addActions(`${user[index].id}`, `${user[index].restricted}`)}
                                                            >
                                                                <i className={`fa ${users.restricted ? 'fa-eye' : 'fa-eye-slash'}`}> </i>
                                                            </button>
                                                            &nbsp;
                                                            {users.restricted ?
                                                                <button type="button"
                                                                    className="btn btn-sm btn-default disabled"
                                                                    title="Edit" data-toggle="tooltip"
                                                                    data-placement="top"
                                                                    style={{ cursor: 'not-allowed' }}
                                                                >
                                                                    <i className="fa fa-pencil"> </i>
                                                                </button>
                                                                :
                                                                <button type="button" className="btn btn-sm btn-default"
                                                                    title="Edit" data-toggle="tooltip"
                                                                    data-placement="top"
                                                                    onClick={() => [editMainUser(user[index].id)]}
                                                                >
                                                                    <i className="fa fa-pencil"> </i>
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))

                                    ) : (
                                        <div className="col-md-12">
                                            <div className="alert alert-custom alert-dismissible" role="alert">
                                                <i className="fa fa-info-circle"></i> Empty records
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>

            <CreateUserModal createUser={createUser} setCreateUserModal={setCreateUserModal}
                getUsersWithBranches={getUsersWithBranches} selectedBranchId={selectedBranchId} />

            <MainUserProfile setMainUserProfileModal={setMainUserProfileModal}
                mainUserProfileModal={mainUserProfileModal} />
        </div>
    </>
}
export default MainUser