import React, { useEffect, useState } from "react";
import { Accordion, Button } from "react-bootstrap";

import HistoricalQueueReport from "../ReportsImpl/QueueReportTables/HistoricalQueueReport";
import WaitingTicketDetailsReport from "./QueueReportTables/WaitingTicketDetailsReport";
import OriginalTicketDetailsReport from "./QueueReportTables/OriginalTicketDetailsReport";

import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const QueueReports = ({
  selectedRegionIds,
  changeRegionIds,
  dateFrom,
  dateTo,
  downloadExcel,
  reportUrl,
}) => {
  const baseUrl = localStorage.getItem("API_URL");
  const token = localStorage.getItem("user_token");
  const userDetails = JSON.parse(localStorage.getItem("user_details"));

  const [openHistoricalQueueReport, setOpenHistoricalQueueReport] =
    useState(false);
  const [openWaitingTicketDetailsReport, setOpenWaitingTicketDetailsReport] =
    useState(false);
  const [openOriginalTicketDetailsReport, setOpenOriginalTicketDetailsReport] =
    useState(false);

  const [reportsAccess, setReportsAccess] = useState([]);

  // call getReportAccess - useEffect
  useEffect(() => {
    if (reportUrl) {
      getReportAccess();
    }
  }, [QueueReports, reportUrl]);

  // get report access - function
  const getReportAccess = () => {
    axios({
      method: "GET",
      url: `${reportUrl}/report-controller/get/report-access/customer/${userDetails.cid}/parent/Queue Reports/get-report-access`,
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        let data = res.data.data;
        setReportsAccess(data);
      })
      .catch((error) => {
        console.log(error);

        if (error.response !== undefined) {
          toast.error(error.response.data.message, {
            position: "bottom-center",
          });
        } else {
          toast.remove();
          toast.error("Something went wrong", {
            position: "bottom-center",
          });
        }
      });
  };

  return (
    <>
      <div className="row clearfix">
        <div className="col-lg-12">
          <div className="card">
            <Accordion
              className="accordion report-accordion"
              id="accordion"
              defaultActiveKey=""
            >
              {reportsAccess.map((item, i) => {
                if (item.name === "Queue Report" && item.active) {
                  return (
                    <div key={i}>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            eventKey="0"
                            className="btn btn-link vertically-align-center-flex"
                            onClick={(e) => {
                              if (openHistoricalQueueReport) {
                                setOpenHistoricalQueueReport(false);
                              } else {
                                setOpenHistoricalQueueReport(true);
                                setOpenWaitingTicketDetailsReport(false);
                                setOpenOriginalTicketDetailsReport(false);
                              }
                            }}
                          >
                            Queue Report
                            <div className="float-right vertically-align-center-flex-2">
                              {userDetails !== null ? (
                                <>
                                  {/* <a href={`${baseUrl}/report-controller/download-pdf/original-ticket-details-report/${dateFrom}/${dateTo}/${selectedRegionIds.length > 0 ? selectedRegionIds.toString() : userDetails.bid}`}
                                                                        target='_blank' className="btn btn-sm btn-default" title="View PDF"
                                                                    ><i class="fa fa-file-pdf-o" aria-hidden="true"></i></a> */}

                                  {item.excel ? (
                                    <a
                                      href={`${reportUrl}/real-time/get/xl/historical-report/from/${dateFrom}/to/${dateTo}/${
                                        selectedRegionIds.length > 0
                                          ? selectedRegionIds.toString()
                                          : userDetails.bid
                                      }`}
                                      target="_blank"
                                      className="btn btn-sm btn-default"
                                      title="View Excel"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-excel-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  ) : null}
                                </>
                              ) : null}
                              <span className="p-l-20">
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </h5>
                      </div>
                      <Accordion.Collapse eventKey="0" className="collapse">
                        <HistoricalQueueReport
                          openHistoricalQueueReport={openHistoricalQueueReport}
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          selectedRegionIds={selectedRegionIds}
                          changeRegionIds={changeRegionIds}
                          reportUrl={reportUrl}
                        />
                      </Accordion.Collapse>
                    </div>
                  );
                } else if (
                  item.name === "Waiting Ticket Details Report" &&
                  item.active
                ) {
                  return (
                    <div key={i}>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="1"
                            className="btn btn-link vertically-align-center-flex"
                            onClick={() => {
                              if (openWaitingTicketDetailsReport) {
                                setOpenWaitingTicketDetailsReport(false);
                              } else {
                                setOpenWaitingTicketDetailsReport(true);
                                setOpenHistoricalQueueReport(false);
                                setOpenOriginalTicketDetailsReport(false);
                              }
                            }}
                          >
                            Waiting Ticket Details Report
                            <div className="float-right vertically-align-center-flex-2">
                              {userDetails !== null ? (
                                <>
                                  {item.pdf ? (
                                    <a
                                      href={`${reportUrl}/report-controller/download-pdf/${dateFrom}/${dateTo}/${
                                        selectedRegionIds.length > 0
                                          ? selectedRegionIds.toString()
                                          : userDetails.bid
                                      }/waiting-ticket-details`}
                                      target="_blank"
                                      className="btn btn-sm btn-default"
                                      title="View PDF"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-pdf-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  ) : null}

                                  {item.excel ? (
                                    <button
                                      className="btn btn-sm btn-default"
                                      title="View Excel"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        downloadExcel(
                                          "waiting-tickets-details-report",
                                          "_qr_wtd_table"
                                        );
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-excel-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : null}
                                </>
                              ) : null}
                              <span className="p-l-20">
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </h5>
                      </div>
                      <Accordion.Collapse eventKey="1" className="collapse">
                        <WaitingTicketDetailsReport
                          openWaitingTicketDetailsReport={
                            openWaitingTicketDetailsReport
                          }
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          selectedRegionIds={selectedRegionIds}
                          changeRegionIds={changeRegionIds}
                          reportUrl={reportUrl}
                        />
                      </Accordion.Collapse>
                    </div>
                  );
                } else if (
                  item.name === "Original Ticket Details Report" &&
                  item.active
                ) {
                  return (
                    <div key={i}>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="2"
                            className="btn btn-link vertically-align-center-flex"
                            onClick={() => {
                              if (openOriginalTicketDetailsReport) {
                                setOpenOriginalTicketDetailsReport(false);
                              } else {
                                setOpenOriginalTicketDetailsReport(true);
                                setOpenWaitingTicketDetailsReport(false);
                                setOpenHistoricalQueueReport(false);
                              }
                            }}
                          >
                            Original Ticket Details Report
                            <div className="float-right vertically-align-center-flex-2">
                              {userDetails !== null ? (
                                <>
                                  {item.pdf ? (
                                    <a
                                      href={`${reportUrl}/report-controller/download-pdf/${dateFrom}/${dateTo}/${
                                        selectedRegionIds.length > 0
                                          ? selectedRegionIds.toString()
                                          : userDetails.bid
                                      }/original-ticket-details-report`}
                                      target="_blank"
                                      className="btn btn-sm btn-default"
                                      title="View PDF"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-pdf-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  ) : null}

                                  {item.excel ? (
                                    <button
                                      className="btn btn-sm btn-default"
                                      title="View Excel"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        downloadExcel(
                                          "original-tickets-details-report",
                                          "_qr_otd_table"
                                        );
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-excel-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : null}
                                </>
                              ) : null}

                              <span className="p-l-20">
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </h5>
                      </div>
                      <Accordion.Collapse eventKey="2" className="collapse">
                        <OriginalTicketDetailsReport
                          openOriginalTicketDetailsReport={
                            openOriginalTicketDetailsReport
                          }
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          selectedRegionIds={selectedRegionIds}
                          changeRegionIds={changeRegionIds}
                          reportUrl={reportUrl}
                        />
                      </Accordion.Collapse>
                    </div>
                  );
                }
              })}
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default QueueReports;
