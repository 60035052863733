import React, { useEffect, useState } from 'react';
import AssignCategoryModal from '../ModalImpl/AssignCategoryModal';
import AssignCategorySkillsModal from '../ModalImpl/AssignCategorySkillsModal';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import toast, { Toaster } from 'react-hot-toast';
import AssignCategoryTimeModal from '../ModalImpl/AssignCategoryTimeModal';
import MUIDataTable from 'mui-datatables';
import CircularProgress from "@mui/material/CircularProgress";
import { DropdownToggle, DropdownMenu, Card, UncontrolledDropdown, DropdownItem, Progress } from "reactstrap";
import CloneBranchCategoryModal from '../ModalImpl/CloneBranchCategoryModal';
import AddBranchCategoryTicketDesign from '../ModalImpl/AddBranchCategoryTicketDesign';


const CategoriesManagement = () => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const selectedBranch = JSON.parse(localStorage.getItem('selected_branch'));
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
    const [modalType, setModalType] = useState(0); // 0-add, 1-update
    const [showAssignCategorySkillsModal, setShowAssignCategorySkillsModal] = useState(false);
    const [showAssignCategoryTimeModal, setShowAssignCategoryTimeModal] = useState(false);
    const [showCloneCategoryModal, setShowCloneCategoryModal] = useState(false);
    const [showAddCategoryTicketDesign, setShowAddCategoryTicketDesign] = useState(false);

    const [allCategories, setAllCategories] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [isLoading, setLoading] = useState(false);


    // call getAllCategories - useEffect
    useEffect(() => {
        if (token !== null) {
            if (baseUrl.length > 0 && token.length > 0 && Object.keys(selectedBranch).length > 0) {
                getAllCategoriesByBranch(0, null, null, rowsPerPage);
            }
        }
    }, [CategoriesManagement])


    // getAllCategoriesByBranch - function
    const getAllCategoriesByBranch = (page, sortOrder, search, size) => {
        setLoading(true);

        axios({
            method: 'POST',
            url: `${baseUrl}/category/get-all-paginated/category/${selectedBranch.id}`,
            headers: {
                Authorization: token
            },
            data: {
                page: page,
                size: size,
                sort: sortOrder,
                searchText: search
            }
        }).then((res) => {

            let data = res.data.data;
            setAllCategories((data.content));
            setCount(data.totalElements);
            setLoading(false);

        }).catch((error) => {
            console.log(error);
            setLoading(false);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });

            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //enable/disable category - function
    const handleEnableDisableCategory = (id, disability) => {

        if (disability == false) {
            Swal.fire({
                position: 'center',
                text: 'Do you want to disable this category?',
                showConfirmButton: true,
                confirmButtonText: "Yes",
                showCancelButton: true,
                cancelButtonText: "No",
                width: '400px',

            }).then((result) => {
                if (result.value == true) {

                    axios({
                        method: 'POST',
                        url: `${baseUrl}/category/enable-disable/category/${id}`,
                        headers: {
                            Authorization: token
                        }
                    }).then((res) => {
                        toast.remove();
                        toast.success('Category disabled Successfully ', {
                            position: 'bottom-center',
                        });
                        getAllCategoriesByBranch(dataTable.page, dataTable.order, dataTable.search, dataTable.size);

                    }).catch((error) => {
                        console.log(error);

                        if (error.response !== undefined) {
                            toast.remove();
                            toast.error(error.response.data.message, {
                                position: 'bottom-center',
                            });
                        } else {
                            toast.remove();
                            toast.error('Something went wrong', {
                                position: 'bottom-center',
                            });
                        }
                    })
                }
            })

        } else {

            Swal.fire({
                position: 'center',
                text: 'Do you want to enable this category?',
                showConfirmButton: true,
                confirmButtonText: "Yes",
                showCancelButton: true,
                cancelButtonText: "No",
                width: '400px',
            }).then((result) => {
                if (result.value == true) {

                    axios({
                        method: 'POST',
                        url: `${baseUrl}/category/enable-disable/category/${id}`,
                        headers: {
                            Authorization: token
                        }
                    }).then((res) => {

                        toast.remove();
                        toast.success('Category enabled Successfully', {
                            position: 'bottom-center',
                        });
                        getAllCategoriesByBranch(dataTable.page, dataTable.order, dataTable.search, dataTable.size);

                    }).catch((error) => {
                        console.log(error);

                        if (error.response !== undefined) {
                            toast.remove();
                            toast.error(error.response.data.message, {
                                position: 'bottom-center',
                            });
                        } else {
                            toast.remove();
                            toast.error('Something went wrong', {
                                position: 'bottom-center',
                            });
                        }
                    })
                }
            })
        }
    }



    // data table
    const [dataTable, setDataTable] = useState({
        page: 0,
        size: 10,
        search: '',
        order: null,
    });

    const setTable = (tableState) => {
        setDataTable({
            page: tableState.page,
            search: tableState.searchText,
            size: tableState.rowsPerPage,
            order: tableState.sortOrder
        });
    };

    const options = {
        tableBodyHeight: '50vh',
        filterType: 'dropdown',
        filter: false,
        responsive: 'vertical',
        serverSide: true,
        count: count,
        download: false,
        jumpToPage: true,
        selectableRows: 'none',
        print: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        },
        onTableChange: (action, tableState) => {

            switch (action) {
                case 'changePage':
                    getAllCategoriesByBranch(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'sort':
                    getAllCategoriesByBranch(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'changeRowsPerPage':
                    setRowsPerPage(tableState.rowsPerPage);
                    getAllCategoriesByBranch(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'search':
                    getAllCategoriesByBranch(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                default:
                    break;
            }
        },
    };

    const columns = [
        {
            label: 'Id',
            name: 'id',
            options: {
                sort: true,
                filter: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {

                    if (allCategories === undefined) return;
                    let item = allCategories.find((item, i) => i === dataIndex);

                    return (
                        <>
                            {item.disability ? (
                                <p className='table-text-disable'>{item.id}</p>
                            ) : (
                                <p className='m-0'>{item.id}</p>
                            )}

                        </>
                    );
                }
            }
        },
        {
            label: 'Category Name',
            name: 'category',
            options: {
                sort: true,
                filter: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {

                    if (allCategories === undefined) return;
                    let item = allCategories.find((item, i) => i === dataIndex);

                    return (
                        <>

                            {item.disability ? (
                                <p className='table-text-disable'>
                                    {item.category}
                                    {item.vip ? <span className="vip-icon">VIP</span> : null}
                                </p>
                            ) : (
                                <p className='m-0'>
                                    {item.category}
                                    {item.vip ? <span className="vip-icon">VIP</span> : null}
                                </p>
                            )}

                        </>
                    );
                }
            }
        },
        {
            label: 'Short Category Name',
            name: 'shortCatName',
            options: {
                sort: true,
                filter: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {

                    if (allCategories === undefined) return;
                    let item = allCategories.find((item, i) => i === dataIndex);

                    return (
                        <>

                            {item.disability ? (
                                <p className='table-text-disable'>
                                    {item.shortCatName}
                                </p>
                            ) : (
                                <p className='m-0'>
                                    {item.shortCatName}
                                </p>
                            )}

                        </>
                    );
                }
            }
        },
        {
            label: "Wait Time Exception",
            name: 'waitTime',
            options: {
                sort: true,
                filter: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {

                    if (allCategories === undefined) return;
                    let item = allCategories.find((item, i) => i === dataIndex);

                    return (
                        <>
                            {item.disability ? (
                                <p className='table-text-disable'>{item.waitTime}</p>
                            ) : (
                                <p className='m-0'>{item.waitTime}</p>
                            )}

                        </>
                    );
                }
            }
        },
        {
            label: 'Service Time Exception',
            name: 'serviceTime',
            options: {
                filter: false,
                sort: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {

                    if (allCategories === undefined) return;
                    let item = allCategories.find((item, i) => i === dataIndex);

                    return (
                        <>
                            {item.disability ? (
                                <p className='table-text-disable'>{item.serviceTime}</p>
                            ) : (
                                <p className='m-0'>{item.serviceTime}</p>
                            )}

                        </>
                    );
                }
            }
        },
        {
            label: 'Start Ticket',
            name: 'noRangeMin',
            options: {
                filter: false,
                sort: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {

                    if (allCategories === undefined) return;
                    let item = allCategories.find((item, i) => i === dataIndex);

                    return (
                        <>
                            {item.disability ? (
                                <p className='table-text-disable'>{item.noRangeMin}</p>
                            ) : (
                                <p className='m-0'>{item.noRangeMin}</p>
                            )}

                        </>
                    );
                }
            }
        },
        {
            label: 'End Ticket',
            name: 'noRangeMax',
            options: {
                filter: false,
                sort: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {

                    if (allCategories === undefined) return;
                    let item = allCategories.find((item, i) => i === dataIndex);

                    return (
                        <>
                            {item.disability ? (
                                <p className='table-text-disable'>{item.noRangeMax}</p>
                            ) : (
                                <p className='m-0'>{item.noRangeMax}</p>
                            )}

                        </>
                    );
                }
            },
        },
        {
            label: 'Action',
            name: '',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {

                    if (allCategories === undefined) return;
                    let item = allCategories.find((item, i) => i === dataIndex);

                    return (
                        <>

                            <UncontrolledDropdown>

                                <DropdownToggle type="button" className={`btn btn-sm btn-default table-action-btn`} data-toggle="tooltip"
                                    data-placement="top"
                                ><i className="fa fa-angle-right" aria-hidden="true"></i>
                                </DropdownToggle>

                                <DropdownMenu
                                    positionFixed={true}
                                    className="dropdown-menu-xs">
                                    <ul className="link-list-opt">
                                        <li>
                                            <DropdownItem
                                                tag="a"
                                                href="javascript:void(0);"
                                                className={`${item.disability ? 'table-action-btn-option-disable' : ''}`}
                                                onClick={() => {
                                                    setSelectedRow(item);
                                                    setModalType(2);
                                                    setShowAddCategoryModal(true);
                                                }}
                                            >
                                                <i className="fa fa-desktop mr-3" aria-hidden="true"></i>
                                                <span>View</span>
                                            </DropdownItem>
                                        </li>
                                        <li>
                                            <DropdownItem
                                                tag="a"
                                                href="javascript:void(0);"
                                                className={`${item.disability ? 'table-action-btn-option-disable' : ''}`}
                                                onClick={() => {
                                                    setModalType(1);
                                                    setSelectedRow(item);
                                                    setShowAddCategoryModal(true);
                                                }}
                                            >
                                                <i className="fa fa-pencil-square-o mr-3" aria-hidden="true"></i>
                                                <span>Edit</span>
                                            </DropdownItem>
                                        </li>
                                        <li>
                                            <DropdownItem
                                                tag="a"
                                                href="javascript:void(0);"
                                                className={`${item.disability ? 'table-action-btn-option-disable' : ''}`}
                                                onClick={() => {
                                                    setSelectedRow(item);
                                                    setShowAddCategoryTicketDesign(true);
                                                }}
                                            >
                                                <i className="fa fa-ticket mr-3"></i>
                                                <span>Ticket Design</span>
                                            </DropdownItem>
                                        </li>
                                        <li>
                                            <DropdownItem
                                                tag="a"
                                                href="javascript:void(0);"
                                                className={`${item.disability ? 'table-action-btn-option-disable' : ''}`}
                                                onClick={() => {
                                                    setSelectedRow(item);
                                                    setShowAssignCategorySkillsModal(true);
                                                }}
                                            >
                                                <i className="fa fa-puzzle-piece mr-3"></i>
                                                <span>Assign Category Skills</span>
                                            </DropdownItem>
                                        </li>
                                        <li>
                                            <DropdownItem
                                                tag="a"
                                                href="javascript:void(0);"
                                                className={`${item.disability ? 'table-action-btn-option-disable' : ''}`}
                                                onClick={() => {
                                                    setSelectedRow(item);
                                                    setShowAssignCategoryTimeModal(true);
                                                }}
                                            >
                                                <i className="fa fa-clock-o mr-3"> </i>
                                                <span>Assign Category Time</span>
                                            </DropdownItem>
                                        </li>
                                        <li>
                                            <DropdownItem
                                                tag="a"
                                                href="javascript:void(0);"
                                                onClick={() => {
                                                    handleEnableDisableCategory(item.id, item.disability)
                                                }}
                                            >
                                                {item.disability ? (
                                                    <>
                                                        <i className={`fa fa-eye mr-3`}> </i>
                                                        <span>Enable</span>
                                                    </>

                                                ) : (
                                                    <>
                                                        <i className={`fa fa-eye-slash mr-3`}> </i>
                                                        <span>Disable</span>
                                                    </>
                                                )}

                                            </DropdownItem>
                                        </li>

                                    </ul>
                                </DropdownMenu>
                            </UncontrolledDropdown>

                        </>
                    );
                }
            },
        },
    ];


    return (
        <>
            <div><Toaster /></div>
            <div className="container-fluid">

                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h1>Categories</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    {/* <li className="breadcrumb-item"><Link to="/region-management">Region Management</Link></li> */}
                                    <li className="breadcrumb-item"><Link to={`/branch-landing?bid=${selectedBranch.id}`}>{selectedBranch.BranchName}</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Categories</li>
                                </ol>
                            </nav>
                        </div>

                        <div className="col-md-6 col-sm-12 text-right hidden-xs">

                            {/* {allCategories.length === 0 ? (
                                <div className="btn btn-sm btn-primary mr-1" title="Clone categories from another branch"
                                    onClick={() => {
                                        setShowCloneCategoryModal(true)
                                    }}
                                >Clone Categories
                                </div>
                            ) : null} */}


                            <Link to="/branch-categories-group" className="btn btn-sm btn-primary mr-1" title="Assign sub Category">
                                Category Group
                            </Link>

                            <div className="btn btn-sm btn-primary" title="Assign New Category"
                                onClick={() => {
                                    setModalType(0);
                                    setShowAddCategoryModal(true);
                                }}
                            >Assign category
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row clearfix">
                    <div className="col-lg-12">

                        <MUIDataTable
                            title={<>
                                {count}{count === 1 ? ' record' : ' records'}.
                                {isLoading &&
                                    <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                        size={10} /></>}
                            </>
                            }
                            data={allCategories}
                            columns={columns}
                            options={options}
                            className='text-center-table'
                        />

                    </div>
                </div>

            </div>

            <AssignCategoryModal showAddCategoryModal={showAddCategoryModal}
                setShowAddCategoryModal={setShowAddCategoryModal}
                getAllCategoriesByBranch={getAllCategoriesByBranch}
                modalType={modalType} selectedRow={selectedRow} setSelectedRow={setSelectedRow}
                dataTable={dataTable}
            />

            <AssignCategorySkillsModal showAssignCategorySkillsModal={showAssignCategorySkillsModal}
                setShowAssignCategorySkillsModal={setShowAssignCategorySkillsModal}
                selectedRow={selectedRow} setSelectedRow={setSelectedRow} />

            <AssignCategoryTimeModal
                showAssignCategoryTimeModal={showAssignCategoryTimeModal} setShowAssignCategoryTimeModal={setShowAssignCategoryTimeModal}
                getAllCategoriesByBranch={getAllCategoriesByBranch} selectedRow={selectedRow} setSelectedRow={setSelectedRow} />

            <CloneBranchCategoryModal
                showCloneCategoryModal={showCloneCategoryModal} setShowCloneCategoryModal={setShowCloneCategoryModal}
                getAllCategoriesByBranch={getAllCategoriesByBranch} />


            <AddBranchCategoryTicketDesign showAddCategoryTicketDesign={showAddCategoryTicketDesign}
                setShowAddCategoryTicketDesign={setShowAddCategoryTicketDesign}
                baseUrl={baseUrl} token={token} userDetails={userDetails}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
            />

        </>

    );

}
export default CategoriesManagement
