import React from 'react'
import {Image} from 'react-konva';
import useImage from 'use-image';

const URLImageMini = ({shapeProps, isSelected, image}) => {
    const trRef = React.useRef();
    const shapeRef = React.useRef();
    const [img] = useImage(image);


    React.useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>

            <Image
                ref={shapeRef}
                {...shapeProps}
                image={img}
            />

        </React.Fragment>

    );
};


export default URLImageMini