import React, { useEffect, useState, useRef } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';
import CircularProgress from "@mui/material/CircularProgress";


const TransferredTicketDetailsReport = ({ openTransTicketDetailsReport, dateFrom, dateTo, selectedRegionIds, changeRegionIds, reportUrl }) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [allreportsNew, setAllReportsNew] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const timeoutRef = useRef(null);


    const [dataTable, setDataTable] = useState({
        page: 0,
        size: 10,
        search: '',
        order: null,
    });

    const setTable = (tableState) => {
        setDataTable({
            page: tableState.page,
            search: tableState.searchText,
            size: tableState.rowsPerPage,
            order: tableState.sortOrder
        });
    };

    const columns = [
        {
            label: "Date",
            name: 'issuedDate',
            options: {
                sort: true,
                filter: false,
                customBodyRender: (value) => (
                    <p style={{ margin: 0, width: '100px', display: 'inline-block' }}>{value}</p>
                )
            }
        },
        {
            label: 'Branch',
            name: 'branchName',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Previous Category",
            name: 'previousCategory',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "To Category",
            name: 'toCategory',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Transfer Time",
            name: 'transferTime',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Ticket No",
            name: 'ticketNumber',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Counter",
            name: 'counterName',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Teller",
            name: 'tellerName',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Status",
            name: 'status',
            options: {
                sort: true,
                filter: false,
            }
        },

    ];

    const options = {
        // tableBodyHeight: '55vh',
        filterType: 'dropdown',
        filter: false,
        responsive: 'vertical',
        serverSide: true,
        count: count,
        download: false,
        jumpToPage: true,
        selectableRows: 'none',
        storageKey: '_qr_ttd_table',
        print: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        },
        onTableChange: (action, tableState) => {

            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want

            switch (action) {
                case 'changePage':
                    getTransfTicketDetailsReport(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'sort':
                    getTransfTicketDetailsReport(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'changeRowsPerPage':
                    setRowsPerPage(tableState.rowsPerPage);
                    getTransfTicketDetailsReport(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'search':
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }
                    timeoutRef.current = setTimeout(() => getTransfTicketDetailsReport(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage), 2000);
                    setTable(tableState);
                    break;
                default:
                    break;
            }
        },
    };



    // call getTransfTicketDetailsReport - useEffect
    useEffect(() => {
        if (openTransTicketDetailsReport) {
            if (dateFrom.length > 0 && dateTo.length > 0) {
                getTransfTicketDetailsReport(0, null, null, rowsPerPage);
            }
        }
    }, [openTransTicketDetailsReport, dateFrom, dateTo])

    // when change selected region array, call getTransfTicketDetailsReport - useEffect
    useEffect(() => {
        if (openTransTicketDetailsReport) {
            getTransfTicketDetailsReport(0, null, null, rowsPerPage);
        }
    }, [changeRegionIds])



    // getTransfTicketDetailsReport - function
    const getTransfTicketDetailsReport = (page, sortOrder, search, size) => {
        setIsLoading(true);

        axios({
            method: 'POST',
            url: `${reportUrl}/report-controller/get-all-transferred/ticket-data/branch/paginated/customer/${userDetails.cid}`,
            headers: {
                Authorization: token
            },
            data: {
                "page": page,
                "size": size,
                "dateFrom": dateFrom,
                "dateTo": dateTo,
                "sort": sortOrder,
                "searchText": search,
                branchIds: selectedRegionIds
            }

        }).then((res) => {

            let data = res.data.data;
            setAllReportsNew((data.content))
            setCount(data.totalElements);

            setIsLoading(false)

        }).catch((error) => {
            console.log(error);
            setIsLoading(false)

            if (error.response !== undefined) {
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    return (
        <>
            <div><ToastContainer /></div>

            <MUIDataTable
                title={
                    <>
                        {isLoading ?
                            <CircularProgress color="secondary" size={20} /> : <>{count} records.</>}
                    </>
                }
                data={allreportsNew}
                columns={columns}
                options={options}
                className='text-center-table'
            />

        </>
    )
}

export default TransferredTicketDetailsReport
