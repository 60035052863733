import React, { useState, useEffect } from 'react';
import { Col, Nav, Row, Tab, Tabs } from 'react-bootstrap';
import ServingCategoriesDonutFull from '../../ChartsImpl/DetailsPageCharts/ServingCategoriesDonutFull';
import OperatorStatusCountChart from '../../ChartsImpl/DetailsPageCharts/OperatorStatusCountChart';
import { ShimmerThumbnail, ShimmerCircularImage, ShimmerTitle } from "react-shimmer-effects";
import GuageChart from '../../ChartsImpl/DetailsPageCharts/GuageChart';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import OperatorStatusCount from '../../ChartsImpl/DetailsPageCharts/OperatorStatusCount';
import { data } from 'jquery';

const OperatorStatusDetails = ({ showOperatorStatusDetails, setShowOperatorStatusDetails, selectedBranchLevel,
    selectedBranchId, operatorLoginInc, messageInc9998, messageInc9999, messageInc1001, messageInc1002, messageInc2000, messageInc2001,
    messageInc3000, messageInc3001, date, setSticky }) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');

    const [allDataLoaded, setAllDataLoaded] = useState(false);

    const [operatorsList, setOperatorsList] = useState([]);
    const [operatorDetails, setOperatorDetails] = useState({});
    const [selectedUser, setSelectedUser] = useState(null);
    const [userLoginStatus, setuserLoginStatus] = useState(0)
    const [initialOperatorsList, setInitialOperatorsList] = useState([])
    const [searchKeyword, setSearchKeyword] = useState(null)

    const [chartValue, setChartValue] = useState(0)

    const [categories, setCategories] = useState([])
    const [ratio, setRatio] = useState('')
    const [percentages, setPercentages] = useState([])
    const [searchtext, setSearchText] = useState('')

    const [hourInt, setHourInt] = useState([])
    const [ticketCount, setTicketCount] = useState([])

    const [filterStatus, setFilterStatus] = useState(null);
    const [filterOnlineUsers, setFilterOnlineUsers] = useState([]);
    const [filterOfflineUsers, setFilterOfflineUsers] = useState([]);

    const [update, setUpdate] = useState('1s')

    // Call getAllOperatorsLoggedInBranch according to operatorLoginInc.
    useEffect(() => {

        if (showOperatorStatusDetails === false) return;
        if (operatorLoginInc > 0) {
            getAllOperatorsLoggedInBranch();
        }

    }, [operatorLoginInc]);

    useEffect(() => {

        const interval = setInterval(() => {
            if (operatorDetails !== null) {
                // const element = operatorDetails[index];
                if (userLoginStatus === 1) {
                    operatorDetails.serviceTimeToSec = operatorDetails.serviceTimeToSec + 5;
                    // alert("2 - service")
                    let serviceTimeHours = Math.floor(operatorDetails.serviceTimeToSec / 3600);
                    let serviceTimeMinutes = Math.floor((operatorDetails.serviceTimeToSec - (serviceTimeHours * 3600)) / 60);
                    let serviceTimeSeconds = operatorDetails.serviceTimeToSec - (serviceTimeHours * 3600) - (serviceTimeMinutes * 60);

                    if (serviceTimeHours < 10) {
                        serviceTimeHours = "0" + serviceTimeHours;
                    }
                    if (serviceTimeMinutes < 10) {
                        serviceTimeMinutes = "0" + serviceTimeMinutes;
                    }
                    if (serviceTimeSeconds < 10) {
                        serviceTimeSeconds = "0" + serviceTimeSeconds;
                    }

                    operatorDetails.serviceTime = `${serviceTimeHours}:${serviceTimeMinutes}:${serviceTimeSeconds}`;

                    operatorDetails.servingTimeToSec = operatorDetails.servingTimeToSec + 5;
                    // alert("2 - service")
                    let servingTimeHours = Math.floor(operatorDetails.servingTimeToSec / 3600);
                    let servingTimeMinutes = Math.floor((operatorDetails.servingTimeToSec - (servingTimeHours * 3600)) / 60);
                    let servingTimeSeconds = operatorDetails.servingTimeToSec - (servingTimeHours * 3600) - (servingTimeMinutes * 60);

                    if (servingTimeHours < 10) {
                        servingTimeHours = "0" + servingTimeHours;
                    }
                    if (servingTimeMinutes < 10) {
                        servingTimeMinutes = "0" + servingTimeMinutes;
                    }
                    if (servingTimeSeconds < 10) {
                        servingTimeSeconds = "0" + servingTimeSeconds;
                    }

                    operatorDetails.servingTime = `${servingTimeHours}:${servingTimeMinutes}:${servingTimeSeconds}`;

                    setOperatorDetails(operatorDetails);
                    setUpdate(new Date().toString());
                }

                if (userLoginStatus === 0) {
                    operatorDetails.idleTimeToSec = operatorDetails.idleTimeToSec + 5;

                    // alert("2 - idle")
                    let idleTimeHours = Math.floor(operatorDetails.idleTimeToSec / 3600);
                    let idleTimeMinutes = Math.floor((operatorDetails.idleTimeToSec - (idleTimeHours * 3600)) / 60);
                    let idleTimeSeconds = operatorDetails.idleTimeToSec - (idleTimeHours * 3600) - (idleTimeMinutes * 60);

                    if (idleTimeHours < 10) {
                        idleTimeHours = "0" + idleTimeHours;
                    }
                    if (idleTimeMinutes < 10) {
                        idleTimeMinutes = "0" + idleTimeMinutes;
                    }
                    if (idleTimeSeconds < 10) {
                        idleTimeSeconds = "0" + idleTimeSeconds;
                    }

                    operatorDetails.idleTime = `${idleTimeHours}:${idleTimeMinutes}:${idleTimeSeconds}`;

                    setOperatorDetails(operatorDetails);
                    setUpdate(new Date().toString());
                }

                if (userLoginStatus === 3) {

                    if (operatorDetails.auxModeTimeToSec !== null) {
                        operatorDetails.auxModeTimeToSec = operatorDetails.auxModeTimeToSec + 5;

                        // alert(operatorDetails.auxModeTimeToSec)

                        let auxTimeHours = Math.floor(operatorDetails.auxModeTimeToSec / 3600);
                        let auxTimeMinutes = Math.floor((operatorDetails.auxModeTimeToSec - (auxTimeHours * 3600)) / 60);
                        let auxTimeSeconds = operatorDetails.auxModeTimeToSec - (auxTimeHours * 3600) - (auxTimeMinutes * 60);

                        if (auxTimeHours < 10) {
                            auxTimeHours = "0" + auxTimeHours;
                        }
                        if (auxTimeMinutes < 10) {
                            auxTimeMinutes = "0" + auxTimeMinutes;
                        }
                        if (auxTimeSeconds < 10) {
                            auxTimeSeconds = "0" + auxTimeSeconds;
                        }
                        operatorDetails.auxModeTime = `${auxTimeHours}:${auxTimeMinutes}:${auxTimeSeconds}`;

                        // alert(operatorDetails.auxModeTimeToSec)
                        setOperatorDetails(operatorDetails);
                        setUpdate(new Date().toString());
                    }
                }
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [userLoginStatus, operatorDetails])

    // When a teller login or logout, this effect will work. This will call the getAllOperatorsLoggedInBranch function and
    // update the list of users who are login and logged out today.
    useEffect(() => {
        if (showOperatorStatusDetails === false) return;

        if (messageInc9999 !== '') {
            // alert("3 9999")
            getAllOperatorsLoggedInBranch();
        }
    }, [messageInc9999]);

    useEffect(() => {
        if (messageInc9998 !== '') {
            getOperatorDataById(selectedUser);
        }
    }, [messageInc9998])



    // When a token is served or direct assiging a token, this effect will work. This will call the getOperatorDataById and get user 
    // data by user id. selectedUser is determine which user is selected now. At very first time, selectedUser is first user's id. 
    // If select a user, that user's id is set to selectedUser state.
    useEffect(() => {
        if (showOperatorStatusDetails === false) return;

        if (messageInc1001 !== ''
            || messageInc1002 !== ''
            || messageInc2000 !== ''
            || messageInc2001 !== ''
            || messageInc3000 !== ''
            || messageInc3001 !== '') {
            if (selectedUser !== null) {
                // alert("4 1001 1002")
                getOperatorDataById(selectedUser);
            }
        }
    }, [messageInc1001, messageInc1002,
        messageInc2000, messageInc2001,
        messageInc3000, messageInc3001]);


    // When select a user, this effect will work. Using this state, getOperatorDataById function can find the selected user's data  
    useEffect(() => {
        if (selectedUser !== null) {
            // alert("5 selectedUser")
            getOperatorDataById(selectedUser);
        }
    }, [selectedUser]);

    // Update the chart value.
    useEffect(() => {
        setChartValue(chartValue + 1);
    }, [])

    //getAllOperatorsLoggedInBranch - function
    const getAllOperatorsLoggedInBranch = () => {
        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/operators-logged-in/branch/${selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            let data = res.data.data;

            // alert("6")

            // Set login and logged-out operators data
            setOperatorsList(data);

            // This is use to filter online and offline users.
            setInitialOperatorsList(data);

            setFilterStatus(0);

            // Check messageInc9999. Because, if it is not, while the user login-logout(9999) this function will work and
            // set first user for selectedUser state. Then the useEffect which is took the selectedUser as a parameter will work.
            // That useEffect will run the getOperatorDataById function which is not necessary to run at this time(9999). 
            if (data !== null && data.length !== 0 && messageInc9999 === '') {
                setSelectedUser(data[0].userId);
            }

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }


    //todo - operation login id is not need
    const getOperatorDataById = (userId) => {
        if (userId !== null) {
            axios({
                method: 'GET',
                url: `${baseUrl}/operator-login/operator-details/user/${userId}/branch/${selectedBranchId}`,
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                // alert("7")
                let data = res.data.data;
                setOperatorDetails(data);
                setRatio(data.servicePercent);
                categoryServedPercentageLoop(data.catList);
                setuserLoginStatus(data.loginStatus);
                categoryServedCountLoop(data.hourlyServedView);
                setChartValue(chartValue + 1);
                setAllDataLoaded(true);
            }).catch((error) => {
                console.log(error);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }

            })
        }
    }

    const categoryServedPercentageLoop = (categoryList) => {

        const category = [];
        const percentage = [];

        if (categoryList !== null) {
            categoryList.forEach(element => {
                category.push(element.category);
                percentage.push(element.percentage);
            })
        }

        setCategories(category);
        setPercentages(percentage);

    }

    const categoryServedCountLoop = (categoryList) => {

        const hourIntArray = [];
        const ticketCountArray = [];

        categoryList.forEach(element => {
            hourIntArray.push(element.hourRange);
            ticketCountArray.push(element.ticketCount);
        })

        setHourInt(hourIntArray);
        setTicketCount(ticketCountArray);
    }

    //onlineUsersLoop - function
    const onlineUsersLoop = (onlineUserList) => {
        console.log('onlineUsersLoop function start');

        const onlineUsers = [];

        onlineUserList.forEach(element => {

            if (element.loginStatus === 1 || element.loginStatus === 0 || element.loginStatus === 3) {
                onlineUsers.push(element);
            }

        })
        setFilterOnlineUsers(onlineUsers);
        setOperatorsList(onlineUsers);
    }

    //offlineUserLoop - function
    const offlineUserLoop = (offlineUserList) => {
        console.log('offlineUserLoop function start');

        const offlineUsers = [];

        offlineUserList.forEach(element => {

            if (element.loginStatus === 2 || element.loginStatus === -1) {
                offlineUsers.push(element);
            }
        })
        setFilterOfflineUsers(offlineUsers);
        setOperatorsList(offlineUsers);
    }

    useEffect(() => {
        if (filterStatus !== null) {
            if (filterStatus === 1) {
                if (searchKeyword !== null) {
                    const results = filterOnlineUsers.filter((operators) => {
                        return operators.operatorName.toString().toLowerCase().includes(searchKeyword.toLowerCase());
                    });
                    console.log("resu: ", results);
                    setOperatorsList(results);
                } else {
                    setOperatorsList(filterOnlineUsers);
                }
                setSearchText(searchKeyword);
            } else if (filterStatus === 2) {
                if (searchKeyword !== null) {
                    const results = filterOfflineUsers.filter((operators) => {
                        return operators.operatorName.toString().toLowerCase().includes(searchKeyword.toLowerCase());
                    });
                    console.log("resu: ", results);
                    setOperatorsList(results);
                } else {
                    setOperatorsList(filterOfflineUsers);
                }
                setSearchText(searchKeyword);
            }
        }

    }, [filterStatus])

    const forSearch = (e) => {
        const keyword = e.target.value;
        if (filterStatus !== null) {
            if (filterStatus === 1) {
                if (keyword !== '') {
                    const results = filterOnlineUsers.filter((operators) => {
                        return operators.operatorName.toString().toLowerCase().includes(keyword.toLowerCase());
                    });
                    setOperatorsList(results);
                } else {
                    setOperatorsList(filterOnlineUsers);
                }
                setSearchText(keyword);
            } else if (filterStatus === 2) {
                if (keyword !== '') {
                    const results = filterOfflineUsers.filter((operators) => {
                        return operators.operatorName.toString().toLowerCase().includes(keyword.toLowerCase());
                    });
                    setOperatorsList(results);
                } else {
                    setOperatorsList(filterOfflineUsers);
                }
                setSearchText(keyword);
            } else if (filterStatus === 0) {
                if (keyword !== '') {
                    const results = operatorsList.filter((operators) => {
                        return operators.operatorName.toString().toLowerCase().includes(keyword.toLowerCase());
                    });
                    setOperatorsList(results);
                } else {
                    setOperatorsList(initialOperatorsList);
                }
                setSearchText(keyword);
            }

            setSearchKeyword(keyword);
        }


    };

    const closeModal = () => {
        setSticky(true)
        setShowOperatorStatusDetails(false);
        messageInc9999 = '';
        messageInc1001 = '';
    }

    return (
        <>
            {showOperatorStatusDetails ?
                <div id="megamenu" className={`megamenu particles_js details-page open`}>
                    <span
                        onClick={() => {
                            closeModal()
                        }}
                        className="megamenu_toggle btn btn-danger"><i className="icon-close"></i></span>
                    {allDataLoaded === false ? (
                        //<ShimmerThumbnail>
                        <div className="container" style={{ width: '85%', height: '1500px', color: 'black' }}></div>
                        //</ShimmerThumbnail>

                    ) : (<div className="container">
                        <div className="row clearfix ml-5" style={{ width: '85%' }}>
                            <div className="col-12">
                                <div className="card">
                                    <div className="body">
                                        <div style={{ textAlign: 'center' }}>
                                            <h4>Operator Status</h4>
                                            <small>{date}</small>
                                        </div>

                                        <br></br>
                                        <div className='clearfix'></div>
                                        <Tab.Container id="left-tabs-example" defaultActiveKey="event0">
                                            <Row>
                                                <Col sm={3}>
                                                    <div className="input-group">
                                                        <input
                                                            value={searchtext}
                                                            onChange={forSearch}
                                                            type="text" className="form-control"
                                                            placeholder="Search here....." />

                                                        <div className="input-group-append">
                                                            <button className="btn btn-outline-secondary" type="button" >
                                                                <i className="fa fa-search" /></button>
                                                        </div>
                                                    </div>
                                                    <div className="row clearfix mt-2">

                                                        {filterStatus === 1 ? (
                                                            <div className="col-md-4 col-sm-4 text-right">
                                                                <button className="btn btn-success mb-2"
                                                                    type="button"
                                                                    title={`Online users`}
                                                                    onClick={() => {
                                                                        if (initialOperatorsList.length !== 0) {
                                                                            onlineUsersLoop(initialOperatorsList);
                                                                            setFilterStatus(1);
                                                                        }
                                                                    }}
                                                                >Online</button>
                                                            </div>
                                                        ) : (
                                                            <div className="col-md-4 col-sm-4 text-right">
                                                                <button className="btn btn-outline-success mb-2"
                                                                    type="button"
                                                                    title={`Online users`}
                                                                    onClick={() => {
                                                                        if (initialOperatorsList.length !== 0) {
                                                                            onlineUsersLoop(initialOperatorsList);
                                                                            setFilterStatus(1);
                                                                        }
                                                                    }}
                                                                >Online</button>
                                                            </div>
                                                        )}

                                                        {filterStatus === 2 ? (
                                                            <div className="col-md-4 col-sm-4 text-right">
                                                                <button className="btn btn-warning mb-2"
                                                                    type="button"
                                                                    title={`Offline users`}
                                                                    onClick={() => {
                                                                        if (initialOperatorsList.length !== 0) {
                                                                            offlineUserLoop(initialOperatorsList);
                                                                            setFilterStatus(2);
                                                                        }
                                                                    }}
                                                                >Offline</button>
                                                            </div>
                                                        ) : (
                                                            <div className="col-md-4 col-sm-4 text-right">
                                                                <button className="btn btn-outline-warning mb-2"
                                                                    type="button"
                                                                    title={`Offline users`}
                                                                    onClick={() => {
                                                                        if (initialOperatorsList.length !== 0) {
                                                                            offlineUserLoop(initialOperatorsList);
                                                                            setFilterStatus(2);
                                                                        }
                                                                    }}
                                                                >Offline</button>
                                                            </div>
                                                        )}

                                                        <div className="col-md-4 col-sm-4 text-right">
                                                            <button className="btn btn-sm btn-default mb-2 operator-status-btn"
                                                                type="button"
                                                                // title={`Offline users`}
                                                                onClick={() => {
                                                                    getAllOperatorsLoggedInBranch();
                                                                    setFilterStatus(0);
                                                                }}

                                                            ><i className="fa fa-refresh"></i>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <Nav variant="pills" className="flex-column">
                                                        {
                                                            operatorsList.map((data, i) =>
                                                                <>
                                                                    <Nav.Item key={i}>
                                                                        {data.loginStatus === 1 || data.loginStatus === 0 || data.loginStatus === 3 ? (
                                                                            <Nav.Link eventKey={`event${i}`}
                                                                                className="operator_wizard_tab mb-1"
                                                                                onSelect={() => {
                                                                                    // getOperatorDataByOperationLoginId(data.operatorLoginId);
                                                                                    getOperatorDataById(data.userId);
                                                                                    // setSelectedOperator(data.operatorLoginId);
                                                                                    setSelectedUser(data.userId);
                                                                                }}

                                                                            >{data.operatorName}
                                                                                <i className="fa fa-circle float-right mt-1 text-success" title="Online" data-toggle="tooltip" data-placement="top"></i>
                                                                            </Nav.Link>
                                                                        ) : (
                                                                            <>
                                                                                {data.loginStatus === -1 ? (
                                                                                    <Nav.Link eventKey={`event${i}`}
                                                                                        className="operator_wizard_tab mb-1"
                                                                                        onSelect={() => {
                                                                                            getOperatorDataById(data.userId);
                                                                                            setSelectedUser(data.userId)
                                                                                        }}>{data.operatorName}
                                                                                        <>
                                                                                            <i className="fa fa-sign-out float-right logout-icon" title="Forced logout" data-toggle="tooltip" data-placement="top"></i>
                                                                                            <i className="fa fa-circle-o float-right mt-1 text-warning"></i>
                                                                                        </>
                                                                                    </Nav.Link>
                                                                                ) : (
                                                                                    <Nav.Link eventKey={`event${i}`}
                                                                                        className="operator_wizard_tab mb-1"
                                                                                        onSelect={() => {
                                                                                            getOperatorDataById(data.userId);
                                                                                            setSelectedUser(data.userId)
                                                                                        }}>{data.operatorName}
                                                                                        <i className="fa fa-circle-o float-right mt-1 text-warning" title="Offline" data-toggle="tooltip" data-placement="top"></i>
                                                                                    </Nav.Link>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </Nav.Item>
                                                                </>
                                                            )
                                                        }
                                                    </Nav>
                                                </Col>

                                                <Col sm={9}>
                                                    <Tab.Content>
                                                        <div className='counter-status-right'>
                                                            {operatorDetails.ticketCount !== null || operatorDetails.nowServingToken !== null ? (
                                                                <>

                                                                    <div className="row clearfix">
                                                                        {operatorDetails.logoutTime === null ? (
                                                                            <div className="col-lg-4 col-md-6">
                                                                                <div className="mt-3 mb-0">

                                                                                </div>
                                                                                <div className="card c_grid">
                                                                                    <div className="operator-details-content">
                                                                                        <div className="circle text-center ">
                                                                                            {operatorDetails.imagePath !== null ? (
                                                                                                <img className="rounded-circle"
                                                                                                    style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                                                                                                    src={operatorDetails.imagePath} alt="" />
                                                                                            ) : (
                                                                                                <img className="rounded-circle"
                                                                                                    style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                                                                                                    src={require('../../../assets/images/others/no-profile.jpg')} alt="" />
                                                                                            )}
                                                                                        </div>

                                                                                        <div className='text-center'>
                                                                                            {operatorDetails.loginStatus === 3 ? (
                                                                                                <>
                                                                                                    <p></p>
                                                                                                    <p className='m-0'><span className="badge badge-info">{operatorDetails.matterCode}</span></p>
                                                                                                    <>
                                                                                                        {selectedBranchLevel !== 4 ? (
                                                                                                            <>
                                                                                                                <p>{operatorDetails.branchName}</p>
                                                                                                            </>
                                                                                                        ) :
                                                                                                            (null)}
                                                                                                    </>
                                                                                                </>
                                                                                            ) : (
                                                                                                <div>
                                                                                                    <p></p>
                                                                                                    <p className='m-0'><b><span className="badge badge-success ml-1 mb-2">since {operatorDetails.loggedTime}</span></b></p>
                                                                                                    <>
                                                                                                        {selectedBranchLevel !== 4 ? (
                                                                                                            <>
                                                                                                                <p>{operatorDetails.branchName}</p>
                                                                                                            </>
                                                                                                        ) :
                                                                                                            (null)}
                                                                                                    </>
                                                                                                </div>
                                                                                            )}
                                                                                        </div>

                                                                                        <table id="mainTable" className="table table-hover mt-2">
                                                                                            <tbody>
                                                                                                {
                                                                                                    (() => {
                                                                                                        switch (operatorDetails.loginStatus) {
                                                                                                            case 1:
                                                                                                                return <tr className=' table-co'>
                                                                                                                    <td>Now serving: </td>
                                                                                                                    <td><div className="icon text-success" title="Ticket Number" data-toggle="tooltip" data-placement="top">
                                                                                                                        <i className="fa fa-ticket"></i> {operatorDetails.nowServingToken}
                                                                                                                    </div><div>{operatorDetails.servingTime}</div></td>
                                                                                                                </tr>;
                                                                                                            case 3:
                                                                                                                return <tr className=' table-co'>
                                                                                                                    <td>AUX Timer: </td>
                                                                                                                    <td className='text-center'>
                                                                                                                        {/* <p className='m-0'><span className="badge badge-primary ml-1 mb-2">{operatorDetails.matterCode.substring(0, 10)}</span></p> */}
                                                                                                                        <p className='m-0'>
                                                                                                                            {operatorDetails.auxModeTimeToSec >= operatorDetails.kpiSec / 2 && operatorDetails.auxModeTimeToSec <= (operatorDetails.kpiSec / 4 * 3) ? (<><small style={{ color: '#FFD700' }}>{operatorDetails.auxModeTime}</small></>)
                                                                                                                                : operatorDetails.auxModeTimeToSec >= (operatorDetails.kpiSec / 4 * 3) && operatorDetails.auxModeTimeToSec <= operatorDetails.kpiSec ? (<><small style={{ color: '#FFD700' }}>⚠️{operatorDetails.auxModeTime}</small></>)
                                                                                                                                    : operatorDetails.auxModeTimeToSec >= operatorDetails.kpiSec ? (<><i class="fa-solid fa-circle-question"></i><small style={{ color: '#FF8A6E' }}><i className="fa fa-question-circle"></i> {operatorDetails.auxModeTime}</small></>)
                                                                                                                                        : (
                                                                                                                                            <small>{operatorDetails.auxModeTime}</small>
                                                                                                                                        )}</p>
                                                                                                                    </td>
                                                                                                                </tr>;
                                                                                                            case 0:
                                                                                                                return <tr className=' table-co'>
                                                                                                                    <td>Now : </td>
                                                                                                                    <td><div className="icon text-white" title="Ticket Number" data-toggle="tooltip" data-placement="top">
                                                                                                                        -- idle
                                                                                                                    </div></td>
                                                                                                                </tr>;
                                                                                                            default:
                                                                                                                break;
                                                                                                        }
                                                                                                    })()}

                                                                                                <tr className=' table-co'>
                                                                                                    <td>Service time: </td>
                                                                                                    <td>{operatorDetails.serviceTime}</td>
                                                                                                </tr>
                                                                                                <tr className=' table-co'>
                                                                                                    <td>Idle time: </td>
                                                                                                    <td>{operatorDetails.idleTime}</td>
                                                                                                </tr>
                                                                                                <tr className=' table-co'>
                                                                                                    <td>Served tickets: </td>
                                                                                                    <td>{operatorDetails.ticketCount}</td>
                                                                                                </tr>
                                                                                                {
                                                                                                    operatorDetails.ticketCount < operatorDetails.transactionCount ? (
                                                                                                        <tr className=' table-co'>
                                                                                                            <td className='text-info'>Transactions <i className="fa fa-retweet"></i> </td>
                                                                                                            <td className='text-info'>{operatorDetails.transactionCount}</td>
                                                                                                        </tr>
                                                                                                    ) : (
                                                                                                        null
                                                                                                    )
                                                                                                }
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="col-lg-4 col-md-6">
                                                                                <div className="mt-3 mb-0 text-center">
                                                                                </div>
                                                                                <div className="card c_grid">
                                                                                    <div className="operator-details-content">
                                                                                        <div className="circle text-center">
                                                                                            {operatorDetails.imagePath !== null ? (
                                                                                                <img className="rounded-circle"
                                                                                                    style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                                                                                                    src={operatorDetails.imagePath} alt="" />
                                                                                            ) : (
                                                                                                <img className="rounded-circle"
                                                                                                    style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                                                                                                    src={require('../../../assets/images/others/no-profile.jpg')} alt="" />
                                                                                            )}
                                                                                        </div>
                                                                                        <div className='text-center'>
                                                                                            <>
                                                                                                <p></p>
                                                                                                <p className='m-0'><span className="badge badge-warning">{operatorDetails.offlineStatus}</span></p>
                                                                                                <>
                                                                                                    {selectedBranchLevel !== 4 ? (
                                                                                                        <>
                                                                                                            <p>{operatorDetails.branchName}</p>
                                                                                                        </>
                                                                                                    ) :
                                                                                                        (null)}
                                                                                                </>
                                                                                            </>
                                                                                        </div>
                                                                                        <p><b><small className="text-muted text-warning"></small></b></p>
                                                                                        <table id="mainTable table-co" className="table table-hover">
                                                                                            <tbody>
                                                                                                <tr className=' table-co'>
                                                                                                    <td>Service time: </td>
                                                                                                    <td>{operatorDetails.serviceTime}</td>
                                                                                                </tr>
                                                                                                <tr className=' table-co'>
                                                                                                    <td>Idle time: </td>
                                                                                                    <td>{operatorDetails.idleTime}</td>
                                                                                                </tr>
                                                                                                <tr className=' table-co'>
                                                                                                    <td>Served tickets: </td>
                                                                                                    <td>{operatorDetails.ticketCount}</td>
                                                                                                </tr>
                                                                                                {
                                                                                                    operatorDetails.ticketCount < operatorDetails.transactionCount ? (
                                                                                                        <tr className=' table-co'>
                                                                                                            <td className='text-info'>Transactions <i className="fa fa-retweet"></i> </td>
                                                                                                            <td className='text-info'>{operatorDetails.transactionCount}</td>
                                                                                                        </tr>
                                                                                                    ) : (
                                                                                                        null
                                                                                                    )
                                                                                                }
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        <div className='col-6 ml-5 mt-3 text-center'>
                                                                            {categories.length !== 0 && percentages.length !== 0 && chartValue > 0 ?
                                                                                (<ServingCategoriesDonutFull categories={categories} percentages={percentages} chartValue={chartValue} />)
                                                                                :
                                                                                (null)}
                                                                            <small><b>Category vice served ticket percentages</b></small>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row clearfix text-center">
                                                                        <div className="col-6">
                                                                            {hourInt.length !== 0 && ticketCount.length !== 0 && chartValue > 0 ?
                                                                                (<OperatorStatusCount hourInt={hourInt} ticketCount={ticketCount} chartValue={chartValue} />)
                                                                                :
                                                                                (null)}
                                                                            <small><b>Hourly served tickets count.</b></small>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (<><p className='mt-2'>No tickets served yet.</p></>)}
                                                        </div>
                                                    </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}
                    <div id="particles-js"></div>
                </div>
                : null}
        </>
    );
}


export default OperatorStatusDetails;