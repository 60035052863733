import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import CategoryAnalytics from '../AnalyticsImpl/CategoryAnalytics';
import AudianceAnalytics from '../AnalyticsImpl/AudianceAnalytics';


const AnalyticsPage = () => {

    const [showAddCategoryAnalytics, setShowAddCategoryAnalytics] = useState(false);

    return (
        <>
            <div className="container-fluid">

                <div className="block-header">
                    <div className="row clearfix">

                        <div className="col-md-6 col-sm-12">
                            <h1>Analytics</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">TS</a></li>
                                    <li className="breadcrumb-item"><a href="/">Maradana</a></li>
                                    <li className="breadcrumb-item"><a href="/">Branch Management</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Analytics page</li>
                                </ol>
                            </nav>
                        </div>

                        <div className="col-md-6 col-sm-12 text-right hidden-xs">
                            <Link className="btn btn-sm btn-primary mr-1"
                                title="category Analytics"
                                to='/analytics/category-analytics'
                            >Category Analytics
                            </Link>

                            <Link className="btn btn-sm btn-primary mr-1"
                             title="Audiance Analytics"
                             to='/analytics/audiance-analytics'
                            >Audiance Analytics
                            </Link>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default AnalyticsPage
