import React, { useState, useEffect } from 'react';
import toast, { Toaster } from "react-hot-toast";
import axios from 'axios';
import Swal from "sweetalert2";
import AddSmsGroupModal from '../ModalImpl/AddSmsGroupModal';
import { Link } from 'react-router-dom';


const SmsGroup = () => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));
    const selectedBranch = JSON.parse(localStorage.getItem('selected_branch'));

    const [showAddSmsGroupModal, setShowAddSmsGroupModal] = useState(false);
    const [modalType, setModalType] = useState(0); // 0-add, 1-edit

    const [allSmsGroupList, setAllSmsGroupList] = useState('');
    const [selectedSmsGroup, setSelectedSmsGroup] = useState({});
    const [selectedSmsGroupInfo, setSelectedSmsGroupInfo] = useState({});

    const [selectedSmsGroupId, setSelectedSmsGroupId] = useState('');


    // call getAllSmsGroups - useeffect
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            if (baseUrl.length > 0 && token.length > 0) {
                getAllSmsGroups();
            }
        }
    }, [SmsGroup])

    //call getSmsGroupInfoById when select a group - useEffect
    useEffect(() => {
        if (Object.keys(selectedSmsGroup).length > 0) {
            getSmsGroupInfoById();
        }
    }, [selectedSmsGroup])


    // getAllSmsGroups - function
    const getAllSmsGroups = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/alert/alert-group/get-list/branchId/${selectedBranch.id}`,
            headers: {
                Authorization: token
            },
        }).then((res) => {

            let data = res.data.data;
            setAllSmsGroupList(data);


        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // getSmsGroupInfoById - function
    const getSmsGroupInfoById = () => {
        axios({
            method: 'GET',
            url: `${baseUrl}/alert/alert-group/${selectedSmsGroup.id}/branchId/${selectedBranch.id}`,
            headers: {
                Authorization: token
            },
        }).then((res) => {

            let data = res.data.data;
            setSelectedSmsGroupInfo(data);


        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // deleteSMSGroup - function
    const deleteSMSGroup = () => {

        Swal.fire({
            position: 'center',
            text: 'Do you want to delete this SMS Group?',
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            width: '400px',

        }).then((result) => {
            if (result.value == true) {

                axios({
                    method: 'POST',
                    url: `${baseUrl}/alert/alert-group/delete/group/${selectedSmsGroup.id}`,
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {

                    toast.remove();
                    toast.success('Deleted Successfully', {
                        position: 'bottom-center',
                    });

                    setSelectedSmsGroupInfo({});
                    getAllSmsGroups();


                }).catch((error) => {
                    console.log(error);

                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                })
            }

        })

    };


    return (
        <>
            <div className="container-fluid">
                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h1>SMS Groups</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    {/* <li className="breadcrumb-item"><Link to="/region-management">Region Management</Link></li> */}
                                    <li className="breadcrumb-item"><Link to={`/branch-landing?bid=${selectedBranch.id}`}>{selectedBranch.BranchName}</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">SMS Groups</li>
                                </ol>
                            </nav>
                        </div>

                        <div className="col-md-6 col-sm-12 text-right hidden-xs">
                            <div className="btn btn-sm btn-primary mr-1" title="Add New SMS Group"
                                onClick={() => {
                                    setModalType(0);
                                    setShowAddSmsGroupModal(true);
                                }}
                            >Add New Group</div>
                        </div>

                    </div>
                </div>

                <div className="row clearfix">
                    <div className="col-lg-3 col-md-3">
                        <ul className="list-group mb-4 right-custom-nav">
                            {
                                allSmsGroupList.length > 0 ? (
                                    allSmsGroupList.map((group, i) => (
                                        <li className={group.id === selectedSmsGroup.id ? "list-group-item d-flex justify-content-between align-items-center country-select active" : "list-group-item d-flex justify-content-between align-items-center country-select"}
                                            key={i}
                                            onClick={() => {
                                                setSelectedSmsGroup(group);
                                            }}
                                        >{group.name}</li>

                                    ))
                                ) : (
                                    <li className="list-group-item d-flex justify-content-between align-items-center">No Data</li>
                                )
                            }
                        </ul>
                    </div>


                    <div className="col-lg-9 col-md-9">

                        <div className="card">

                            {
                                Object.keys(selectedSmsGroupInfo).length > 0 ? (
                                    <div className="body top_counter">
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='sms-group-view-header vertically-align-center-flex m-b-30'>
                                                    <div className="header m-0 p-0">
                                                        <h2 className='m-0'>{selectedSmsGroupInfo.groupName}
                                                            <small className="text-muted">Type :&nbsp;&nbsp; {selectedSmsGroupInfo.typeName}</small>
                                                        </h2>
                                                    </div>
                                                    <div className=''>
                                                        <button type="button" className="btn btn-sm btn-default"
                                                            title="Edit"
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            onClick={() => {
                                                                setSelectedSmsGroupId(selectedSmsGroupInfo.groupId);
                                                                setModalType(1);
                                                                setShowAddSmsGroupModal(true);
                                                            }}
                                                        >
                                                            <i className="fa fa-pencil-square-o"> </i>
                                                        </button>
                                                        &nbsp;
                                                        <button type="button" className="btn btn-sm btn-default "
                                                            title="Delete"
                                                            data-toggle="tooltip" data-placement="top"
                                                            onClick={() => {
                                                                deleteSMSGroup();
                                                            }}
                                                        ><i className="icon-trash"> </i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>


                                            {
                                                selectedSmsGroupInfo.users.length > 0 ? (
                                                    <>
                                                        {
                                                            selectedSmsGroupInfo.users.map((item, i) =>
                                                                <div className="col-lg-3 col-md-6">
                                                                    <div className="card c_grid c_yellow" style={{ marginBottom: '0' }}>
                                                                        <div className="body text-center ribbon" style={{ borderTop: 'none' }}>

                                                                            <div className="circle sms-group-users" title={`${item.firstName} ${item.lastName}`}>

                                                                                {item.image ?
                                                                                    <img
                                                                                        src={`${item.image}?${Date.now()}`}
                                                                                        className="personal-avatar-user " alt="avatar" />
                                                                                    :
                                                                                    <img src={require('../../assets/images/others/no-profile.jpg')}
                                                                                        className="personal-avatar-user " alt="avatar" />
                                                                                }
                                                                            </div>

                                                                            <h6 className="mt-2" title={`${item.firstName} ${item.lastName}`}>{item.firstName}</h6>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                ) : (
                                                    <div className="col-12">
                                                        <p>No Assigned users.</p>
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </div>
                                ) :
                                    <p>Select a group</p>
                            }
                        </div>
                    </div>

                </div>
            </div>

            <AddSmsGroupModal
                setShowAddSmsGroupModal={setShowAddSmsGroupModal}
                showAddSmsGroupModal={showAddSmsGroupModal}
                modalType={modalType}
                getAllSmsGroups={getAllSmsGroups}
                setSelectedSmsGroup={setSelectedSmsGroup}
                selectedSmsGroupId={selectedSmsGroupId}
            />
        </>
    );

}
export default SmsGroup
