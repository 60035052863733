import React, { useEffect, useState } from 'react';
import MattercodeModal from '../ModalImpl/MattercodeModal';
import axios from "axios";
import toast from "react-hot-toast";
import Swal from "sweetalert2";

const MattercodeManagement = () => {

    const [baseUrl, setBaseUrl] = useState('');
    const [token, setToken] = useState('');
    const [userDetails, setUserDetails] = useState({});

    const [showMattercode, setShowMattercode] = useState(false);
    const [modalType, setModalType] = useState(); // 0-add, 1-edit
    const [selectedRow, setSelectedRow] = useState({});

    const [data, setData] = useState([]);
    const [matteCodeList, setMatterCodeList] = useState([]);

    const [selectedMCodeType, setSelectedMCodeType] = useState({});

    // for alerts
    const [acNoshowAlert, setAcNoshowAlert] = useState(false);
    const [acAfterCallAlert, setAcAfterCallAlert] = useState(false);
    const [acWaitAlert, setAcWaitAlert] = useState(false);
    const [acTransferAlert, setTransferAlert] = useState(false);


    // get base url - useEffect
    useEffect(() => {
        setBaseUrl(localStorage.getItem('API_URL'));
        setToken(localStorage.getItem('user_token'));
        setUserDetails(JSON.parse(localStorage.getItem('user_details')));

    }, [MattercodeManagement])

    // call getAllMattercodeTypes - useEffect
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            if (baseUrl.length > 0 && token.length > 0) {
                getAllMatterCodesType();
                getAllMatterCodes();
            }
        }
    }, [baseUrl, token]);


    // get all mattercode types - function
    const getAllMatterCodesType = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/customer/getAll/matter-code-type`,
            headers: {
                Authorization: token
            },

        }).then((response) => {
            setMatterCodeList(response.data.data.list);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //getAllMatterCodes - function
    const getAllMatterCodes = () => {

        axios({
            method: 'POST',
            url: `${baseUrl}/customer/get-matter-codes`,
            headers: {
                Authorization: token
            },

        }).then((response) => {
            setData(response.data.data)

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //getMatterCodesByMatterCodeTypeId - function
    const getMatterCodesByMatterCodeTypeId = (mType) => {

        axios({
            method: 'GET',
            url: `${baseUrl}/customer/get-matter-codes-list/customer/${userDetails.cid}/type/${mType.id}`,
            headers: {
                Authorization: token
            },

        }).then((response) => {
            let data = response.data.data;
            setData(data);
            handleAlert(mType, data);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // handle alert - function 
    const handleAlert = (mType, dataArray) => {
        if (mType.description === 'aux mode') {

            let acNoShow = false;
            let ac = false;
            let acWait = false;
            let acTransfer = false;

            for (let i = 0; i < dataArray.length; i++) {
                const element = dataArray[i];

                if (element.reason === 'After Call - NOSHOW') {
                    acNoShow = true;
                }
                if (element.reason === 'After Call') {
                    ac = true;
                }
                if (element.reason === 'After Call - WAIT') {
                    acWait = true;
                }
                if (element.reason === 'After Call - TRANSFER') {
                    acTransfer = true;
                }
            }

            if (!acNoShow) {
                setAcNoshowAlert(true);
            } else {
                setAcNoshowAlert(false);
            }

            if (!ac) {
                setAcAfterCallAlert(true);
            } else {
                setAcAfterCallAlert(false);
            }

            if (!acWait) {
                setAcWaitAlert(true);
            } else {
                setAcWaitAlert(false);
            }

            if (!acTransfer) {
                setTransferAlert(true);
            } else {
                setTransferAlert(false);
            }

        } else {
            setAcNoshowAlert(false);
            setAcAfterCallAlert(false);
            setAcWaitAlert(false);
            setTransferAlert(false);
        }
    }


    //deleteMattercode - function
    const deleteMattercode = (id) => {

        Swal.fire({
            position: 'center',
            text: 'Do you want to delete this Mattercode?',
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            width: '400px',

        }).then((result) => {
            if (result.value == true) {

                axios({
                    method: 'POST',
                    url: `${baseUrl}/customer/delete/matter-code/${id}`,
                    data: {},
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {

                    toast.remove();
                    toast.success('Deleted Successfully ', {
                        position: 'bottom-center',
                    });

                    if (Object.keys(selectedMCodeType).length === 0) {
                        getAllMatterCodes();
                    } else {
                        getMatterCodesByMatterCodeTypeId(selectedMCodeType);
                    }

                }).catch((error) => {
                    console.log(error);

                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                })
            }

        })

    };

    return (
        <>
            <div className="container-fluid">

                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h1>Mattercode Management</h1>
                        </div>
                        <div className="col-md-6 col-sm-12 text-right hidden-xs">
                            <div className="btn btn-sm btn-primary mr-1" title="Add New Sms Template"
                                onClick={() => {
                                    setModalType(0);
                                    setShowMattercode(true)
                                }}
                            >Add New
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row clearfix">

                    <div className="col-lg-3 col-md-3">

                        <ul className="list-group mb-4 right-custom-nav" style={{ paddingTop: '5px' }}>
                            {
                                matteCodeList.length > 0 ? (
                                    matteCodeList.map((mat, i) => (
                                        <li className={mat.id === selectedMCodeType.id ? "list-group-item d-flex justify-content-between align-items-center country-select active" : "list-group-item d-flex justify-content-between align-items-center country-select"}
                                            key={i}
                                            onClick={() => {
                                                setSelectedMCodeType(mat);
                                                getMatterCodesByMatterCodeTypeId(mat);
                                            }}
                                        >{mat.description}</li>
                                    ))
                                ) : (
                                    <li className="list-group-item d-flex justify-content-between align-items-center">No
                                        Data</li>
                                )
                            }

                        </ul>

                    </div>


                    <div className="col-lg-9 col-md-9">

                        {acNoshowAlert ?
                            <div className="alert alert-custom alert-dismissible" role="alert">
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close"
                                    onClick={() => {
                                        setAcNoshowAlert(false);
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <i className="fa fa-info-circle"></i>   Define after call mode as "After Call - NOSHOW"
                            </div>
                            : null}

                        {acAfterCallAlert ?
                            <div className="alert alert-custom alert-dismissible" role="alert">
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close"
                                    onClick={() => {
                                        setAcAfterCallAlert(false);
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <i className="fa fa-info-circle"></i>   Define after call mode as "After Call"
                            </div>
                            : null}

                        {acWaitAlert ?
                            <div className="alert alert-custom alert-dismissible" role="alert">
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close"
                                    onClick={() => {
                                        setAcWaitAlert(false);
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <i className="fa fa-info-circle"></i>   Define after call mode as "After Call - WAIT"
                            </div>
                            : null}

                        {acTransferAlert ?
                            <div className="alert alert-custom alert-dismissible" role="alert">
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close"
                                    onClick={() => {
                                        setTransferAlert(false);
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                                <i className="fa fa-info-circle"></i>   Define after call mode as "After Call - TRANSFER"
                            </div>
                            : null}



                        <div className="table-responsive">
                            <table className="table table-hover table-custom spacing5">
                                <tbody>

                                    {data.length > 0 ? (

                                        data.map((d, index) => (
                                            <tr key={index}>
                                                <td>{d.reason} </td>

                                                <td className="smsIcons">
                                                    <div className='mtt-time'>{d.duration}</div>
                                                    <button type="button" className="btn btn-sm btn-default"
                                                        title="Edit"
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        onClick={() => {
                                                            setSelectedRow(d);
                                                            setModalType(1);
                                                            setShowMattercode(true)
                                                        }}>
                                                        <i className="fa fa-pencil-square-o"> </i>
                                                    </button>


                                                    &nbsp;
                                                    <button type="button" className="btn btn-sm btn-default "
                                                        title="Delete"
                                                        data-toggle="tooltip" data-placement="top"
                                                        onClick={() => {
                                                            deleteMattercode(d.id);
                                                        }}
                                                    ><i className="icon-trash"> </i>
                                                    </button>
                                                    &nbsp;
                                                </td>
                                            </tr>
                                        ))

                                    ) : (
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="alert alert-custom alert-dismissible" role="alert">
                                                <i className="fa fa-info-circle"></i>   No Data
                                            </div>
                                        </div>
                                    )
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <MattercodeModal
                showMattercode={showMattercode} setShowMattercode={setShowMattercode}
                modalType={modalType}
                selectedMCodeType={selectedMCodeType} getAllMatterCodes={getAllMatterCodes}
                getMatterCodesByMatterCodeTypeId={getMatterCodesByMatterCodeTypeId}
                setSelectedRow={setSelectedRow} selectedRow={selectedRow}
            />
        </>

    );

}
export default MattercodeManagement
