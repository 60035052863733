import React, {useRef, useState} from 'react'
import {Link, useNavigate} from "react-router-dom";
import tsLogo from "../../assets/images/TS-Logo.png"
import axios from "axios";
import toast, {Toaster} from 'react-hot-toast';
import Button from 'react-bootstrap-button-loader';


const Login = () => {

    const passwordInput = useRef(null);

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('TS_GEN_TOKEN');
    const appVersion = localStorage.getItem('APP_VERSION');

    let navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [passWord, setPassWord] = useState('');
    const [rememberMe, setIsRememberMe] = useState(false);

    const [buttonLoader, setButtonLoader] = useState(false);


    // user login - function
    const userLogin = () => {

        setButtonLoader(true);

        axios({
            method: 'POST',
            url: `${baseUrl}/user/login`,
            headers: {
                Authorization: token
            },
            data: {
                "username": email,
                "password": passWord,
                "rememberMe": rememberMe
            }

        }).then(response => {
            let data = response.data.data;
            setButtonLoader(false);
            configurationData();
            toast.remove();
            // toast.success('User successfully login!', {
            //     position: 'bottom-center',
            // });

            const userToken = "Bearer " + data.token;

            localStorage.setItem('user_token', userToken);
            delete data.token;
            localStorage.setItem('user_details', JSON.stringify(data));
            // history.push('/landing-page')
            // navigate("/home");
            navigate("/branch-dashboard");

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
                setButtonLoader(false);
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                })
                setButtonLoader(false);
            }
        })
    }

    const configurationData = () => {
        setButtonLoader(true);
        axios({
            method: 'POST',
            url: `${baseUrl}/branch/get-configs`,
            headers: {
                Authorization: token
            },
            data: {
                response: [
                    "mq-password", "mqtt", "web-socket", "mq-username", "ip", "port", "use-ssl"
                ]
            }

        }).then((res) => {
            setButtonLoader(false);
            
            let data = res.data.data;

            //  !!!!! read before edit this !!!!! 

            //we can simply set the whole object to the local storage like this. 
            localStorage.setItem('configuration_data', JSON.stringify(data));

            //Also I did this. Looks like below part is not nessessory. But there is a reason for this.
            //If you remove below code lines, then the notification part will not work. Why? because somehow-
            //-I can't get the values from the 'configuration_data'(Local storage) object in 'Header.js' component. Why is that.?
            //Good question. I don't no. But you can find a solution for that. Until that, please do not remove below code lines.


        })
    }

    return (
        <>
            <div><Toaster /></div>

            <div className="pattern">
                <span className="red"></span>
                <span className="indigo"></span>
                <span className="blue"></span>
                <span className="green"></span>
                <span className="orange"></span>
            </div>
            <div><Toaster /></div>
            <div className="auth-main particles_js" style={{ height: '0%' }}>
                <div className="auth_div vivify popIn">
                    <div className="auth_brand">
                        <Link className="navbar-brand" to="/">
                            <img src={tsLogo} width="80" height="80"
                                className="d-inline-block align-top mr-2" alt="Ts logo" />
                        </Link>
                    </div>
                    <div className="card">
                        <div className="body">
                            <p className="lead">Welcome Back</p>
                            <label>Please enter your details</label>
                            <form className="form-auth-small m-t-20" action="/">
                                <div className="form-group">
                                    <label htmlFor="signin-email" className="control-label sr-only">Email</label>
                                    <input type="email" className="form-control round" id="signin-email"
                                        value={email}
                                        placeholder="Email"
                                        onChange={(e) => {
                                            setEmail(e.target.value)
                                        }}
                                        onKeyUp={(e) => {
                                            if (e.key === 'Enter') {
                                                // alert('ENETR')
                                                passwordInput.current.focus();
                                            }
                                        }}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="signin-password" className="control-label sr-only">Password</label>
                                    <input type="password" className="form-control round" id="signin-password"
                                        ref={passwordInput}
                                        value={passWord}
                                        placeholder="Password"
                                        onChange={(e) => {
                                            setPassWord(e.target.value)
                                        }}
                                        onKeyUp={(e) => {
                                            if (e.key === 'Enter') {
                                                userLogin();
                                            }
                                        }}
                                    />
                                </div>
                                <div className="form-group clearfix">
                                    <label className="fancy-checkbox element-left">
                                        <input type="checkbox" value={rememberMe}
                                            onChange={(e) => {
                                                setIsRememberMe(true)
                                            }}
                                        />
                                        <span>Keep Logged in</span>
                                    </label>
                                </div>
                                <Button loading={buttonLoader}
                                    className="btn btn-primary btn-round btn-block"
                                    onClick={() => {
                                        userLogin()
                                    }}
                                >LOGIN
                                </Button>
                                <div className="bottom">
                                    <span className="helper-text m-b-10"><i className="fa fa-lock"></i> <Link
                                        to="/forgotpassword">Forgot password?</Link></span>
                                    <span>Don't have an account? <Link to="/register">Register</Link></span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <label className='version-label'>
                    {
                        localStorage.getItem('APP_VERSION') != undefined ?
                            <>Version {localStorage.getItem('APP_VERSION')}</>
                            : null
                    }
                </label>
                <div id="particles-js"></div>
            </div>

        </>
    )
}

export default Login;