import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import axios from "axios";
import LocalWaitTicketModal from '../ModalImpl/LocalWaitModal';

import CircularProgress from "@mui/material/CircularProgress";
import TicketIssuedDetails from '../DetailsPages/BranchDashboard/TicketIssuedDetails';
import TicketServedDetails from '../DetailsPages/BranchDashboard/TicketServedDetails';
import NoShowTicketsDetails from '../DetailsPages/BranchDashboard/NoShowTicketsDetails';
import TicketServingDetails from '../DetailsPages/BranchDashboard/TicketServingDetails';
import MUIDataTable from 'mui-datatables';

import BranchTableIssuedTicketModal from '../ModalImpl/BranchTableIssuedTicketModal';
import BranchTableTicketServedModal from '../ModalImpl/BranchTableTicketServedModal';
import BranchTableServingTicketModal from '../ModalImpl/BranchTableServingTicketModal';
import BranchTableLocalWaitingTicketDetails from '../ModalImpl/BranchTableLocalWaitingTicketDetails';

//mqtt conf
import Paho from 'paho-mqtt';

let messageCount = 0;

const BranchTables = () => {
    let client = null;

    let configs = JSON.parse(localStorage.getItem("configuration_data"));
    const location = useLocation();
    const history = useNavigate();

    const current = new Date();
    const date = `${current.getFullYear()} / ${current.getMonth() + 1} / ${current.getDate()}`;

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const selectedBranchId = location.state.selectedBranchId;
    const selectedBranchName = location.state.selectedBranchName;
    const selectedBranchLevel = location.state.selectedBranchLevel;
    const selectedBranchQueueType = location.state.selectedBranchQueueType;

    const [update, setUpdate] = useState('1s')
    const [showAddWaitTicketModal, setShowAddWaitTicketModal] = useState(false);

    const [accordion, setAccordion] = useState(0); //0 - operator, 1 - waiting, 2 - category
    const [accordionU, setAccordionU] = useState('----'); //0 - operator, 1 - waiting, 2 - category

    const [branchData, setBranchData] = useState(null);
    const [operatorStatus, setOperatorStatus] = useState([]);
    const [waitingTicketDetails, setWaitingTicketDetails] = useState([]);
    const [categoryStatus, setCategoryStatus] = useState([]);

    const [allOperatorStatusList, setAllOperatorStatusList] = useState([]);
    const [operatorUpdate, setoperatorUpdate] = useState(new Date().getMilliseconds + '');
    const [allWaitingTicketDetails, setAllWaitingTicketDetails] = useState([]);


    const [showTicketIssuedDetails, setShowTicketIssuedDetails] = useState(false);
    const [showTicketIssuedData, setShowTicketIssuedData] = useState(false);
    const [showServedTicketsDetails, setShowServedTicketsDetails] = useState(false);
    const [showTicketServedDetails, setShowTicketServedDetails] = useState(false);
    const [showNoShowTicketsDetails, setShowNoShowTicketsDetails] = useState(false);
    const [showTicketServingData, setShowTicketServingData] = useState(false);
    const [noShowInc, setNoShowInc] = useState(0);
    const [showLocalWaitTicketData, setShowLocalWaitTicketData] = useState(false);
    const [showTicketServingDetails, setShowTicketServingDetails] = useState(false);

    const [servedTicketsInc, setServedTicketsInc] = useState(0);
    const [ticketIssuedInc, setTicketIssuedInc] = useState(0);

    const [loading1, setLoading1] = useState(false);

    //mqtt
    const [messageInc1000, setMessageInc1000] = useState('')
    const [messageInc1001, setMessageInc1001] = useState('')
    const [messageInc2001, setMessageInc2001] = useState('')
    const [messageInc2000, setMessageInc2000] = useState('')
    const [messageInc3000, setMessageInc3000] = useState('')
    const [messageInc3001, setMessageInc3001] = useState('')
    const [messageInc9998, setMessageInc9998] = useState('')
    const [messageInc9999, setMessageInc9999] = useState('')


    //OperatorStatus - useeffect
    useEffect(() => {
        let postsArray = JSON.parse(JSON.stringify(operatorStatus));

        setAllOperatorStatusList(postsArray);
        setoperatorUpdate(new Date().toString())
    }, [operatorStatus, operatorUpdate]);


    //OperatorStatus - useeffect
    useEffect(() => {
        console.info("selected band " + accordion);
        getBranchSummaryOperatorStatus();
        getBranchSummaryWaitingTicketDetails();
        getBranchSummaryCategoryStatus();
    }, [accordion, accordionU]);

    //getBranchSummary - useEffect
    useEffect(() => {
        if (baseUrl.length > 0 && token.length > 0) {
            executeFunctions();
            // getBranchSummary();
            // getBranchSummaryWaitingTicketDetails();
        }
    }, [baseUrl, token]);

    //mqtt config - useEffect
    useEffect(() => {
        client = new Paho.Client(configs['ip'], Number(configs['port']), 'mqttjs_' + Math.random().toString(16).substr(2, 8));

        //set callback handlers (mqtt)
        client.onConnectionLost = onConnectionLost;
        client.onMessageArrived = onMessageArrived;

        // connect the client (mqtt)
        client.connect({
            onSuccess: onConnect,
            userName: configs['mq-username'],
            password: configs['mq-password'],
            reconnect: true,
            useSSL: configs['use-ssl'] === 'true'
        });
        return () => {
            if (client !== null && client.isConnected()) {
                client.unsubscribe(`ticket/dashboard/branch/${selectedBranchId}`);
                client.unsubscribe(`user/dashboard/branch/${selectedBranchId}`);
                client.disconnect();
                client = null;
            }
        }
    }, [BranchTables]);


    useEffect(() => {
        // returned function will be called on component unmount
        return () => {
            if (client !== null && client.isConnected()) {
                client.unsubscribe(`ticket/dashboard/branch/${selectedBranchId}`);
                client.unsubscribe(`user/dashboard/branch/${selectedBranchId}`);
                client.disconnect();
                client = null;
            }
        }
    }, []);

    // navigateToDashboard - function
    const navigateToDashboard = (selectedBranchId, selectedBranchName, selectedBranchLevel, selectedBranchQueueType) => {
        // history.push(`/branch-dashboard`);
        history('/branch-dashboard', {
            state: {
                selectedBranchId: selectedBranchId,
                selectedBranchName: selectedBranchName,
                selectedBranchLevel: selectedBranchLevel,
                selectedBranchQueueType: selectedBranchQueueType
            }
        }
        )
    };

    //called when the client connects (mqtt)
    const onConnect = () => {
        client.subscribe(`ticket/dashboard/branch/${selectedBranchId}`);
        client.subscribe(`user/dashboard/branch/${selectedBranchId}`);
    };

    // called when the client loses its connection (mqtt)
    const onConnectionLost = (responseObject) => {
        if (responseObject.errorCode !== 0) {
            console.log("onConnectionLost:" + responseObject.errorMessage);
        }
        // toast.error('Connection Lost', {
        //     position: 'bottom-center',
        // });
    };

    // called when a message arrives (mqtt)
    const onMessageArrived = (message) => {

        executeFunctions(message.payloadString);
    };

    useEffect(() => {

        const interval = setInterval(() => {
            if (accordion === 1 && waitingTicketDetails !== null && waitingTicketDetails.length > 0) {
                for (let index = 0; index < waitingTicketDetails.length; index++) {
                    const element = waitingTicketDetails[index];
                    element.exceptionTimeFromSec = element.exceptionTimeFromSec + 5;

                    let hours = Math.floor(element.exceptionTimeFromSec / 3600);
                    let minutes = Math.floor((element.exceptionTimeFromSec - (hours * 3600)) / 60);
                    let seconds = element.exceptionTimeFromSec - (hours * 3600) - (minutes * 60);

                    if (hours < 10) {
                        hours = "0" + hours;
                    }
                    if (minutes < 10) {
                        minutes = "0" + minutes;
                    }
                    if (seconds < 10) {
                        seconds = "0" + seconds;
                    }

                    element.waitTime = `${hours}:${minutes}:${seconds}`;
                }
                setWaitingTicketDetails(waitingTicketDetails);
                setUpdate(new Date().toString());
            }
            if (accordion === 0 && operatorStatus !== null && operatorStatus.length > 0) {
                for (let index = 0; index < operatorStatus.length; index++) {
                    const element = operatorStatus[index];
                    element.totalTimeSecs = element.totalTimeSecs + 5;
                    if (element.status === 1)
                        element.totalServiceTimeSecs = element.totalServiceTimeSecs + 5;
                    if (element.status === 0)
                        element.idleTimeSecs = element.idleTimeSecs + 5;

                    let totalTimeHours = Math.floor(element.totalTimeSecs / 3600);
                    let totalTimeMinutes = Math.floor((element.totalTimeSecs - (totalTimeHours * 3600)) / 60);
                    let totalTimeSeconds = element.totalTimeSecs - (totalTimeHours * 3600) - (totalTimeMinutes * 60);

                    let totalServiceTimeHours = Math.floor(element.totalServiceTimeSecs / 3600);
                    let totalServiceTimeMinutes = Math.floor((element.totalServiceTimeSecs - (totalServiceTimeHours * 3600)) / 60);
                    let totalServiceTimeSeconds = element.totalServiceTimeSecs - (totalServiceTimeHours * 3600) - (totalServiceTimeMinutes * 60);

                    let idleHours = Math.floor(element.idleTimeSecs / 3600);
                    let idleMinutes = Math.floor((element.idleTimeSecs - (idleHours * 3600)) / 60);
                    let idleSeconds = element.idleTimeSecs - (idleHours * 3600) - (idleMinutes * 60);

                    if (totalTimeHours < 10) {
                        totalTimeHours = "0" + totalTimeHours;
                    }
                    if (totalTimeMinutes < 10) {
                        totalTimeMinutes = "0" + totalTimeMinutes;
                    }
                    if (totalTimeSeconds < 10) {
                        totalTimeSeconds = "0" + totalTimeSeconds;
                    }
                    if (totalServiceTimeHours < 10) {
                        totalServiceTimeHours = "0" + totalServiceTimeHours;
                    }
                    if (totalServiceTimeMinutes < 10) {
                        totalServiceTimeMinutes = "0" + totalServiceTimeMinutes;
                    }
                    if (totalServiceTimeSeconds < 10) {
                        totalServiceTimeSeconds = "0" + totalServiceTimeSeconds;
                    }
                    if (idleHours < 10) {
                        idleHours = "0" + idleHours;
                    }
                    if (idleMinutes < 10) {
                        idleMinutes = "0" + idleMinutes;
                    }
                    if (idleSeconds < 10) {
                        idleSeconds = "0" + idleSeconds;
                    }

                    element.totalTime = `${totalTimeHours}:${totalTimeMinutes}:${totalTimeSeconds}`;

                    if (element.status === 1)
                        element.totalServiceTime = `${totalServiceTimeHours}:${totalServiceTimeMinutes}:${totalServiceTimeSeconds}`;
                    if (element.status === 0)
                        element.idleTime = `${idleHours}:${idleMinutes}:${idleSeconds}`;
                }
                setOperatorStatus(operatorStatus);
                setoperatorUpdate(new Date().toString());
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [waitingTicketDetails, operatorStatus]);

    // useEffect(() => {
    //     const timer = setTimeout(() => getWaitingTicketDetails(), 10000);
    // }, []);


    //execute all functions
    const executeFunctions = (messageData) => {
        if (messageData === null || messageData === '' || messageData === undefined) {
            getBranchSummary();
            getBranchSummaryWaitingTicketDetails();
            getBranchSummaryCategoryStatus();
            //getBranchSummaryLocalWaittingTickets();
            getBranchSummaryOperatorStatus();
        } else {
            console.info(messageData);
            // setMessages(messageData);

            if (messageData === '1000' || messageData === '1001' || messageData === '2000' || messageData === '2001'
                || messageData === '3000' || messageData === '3001' || messageData === '4000' || messageData === '9999' || messageData === '9998') {
                getBranchSummary();
            }

            if (messageData === '1000' || messageData === '1001' || messageData === '9999' || messageData === '9998') {
                setAccordionU(new Date().toString());
                // getBranchSummaryWaitingTicketDetails();
            }

            // if (messageData === '1000' || messageData === '1001') {
            //     setAccordionU(new Date().toString());
            //     // getBranchSummaryCategoryStatus();
            // }
            //
            // // if (messageData === '1000' || '1001' || '3000' || '3001') {
            // //     getBranchSummaryLocalWaittingTickets();
            // // }
            //
            // if (messageData === '1001' ) {
            //     setAccordionU(new Date().toString());
            //     // getBranchSummaryOperatorStatus();
            // }

        }
    }


    //getBranchSummary-function
    const getBranchSummary = () => {
        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/get-branch-summary/branch/${selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            let data = res.data.data;
            setBranchData(data);
        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });

            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    };

    //getBranchSummaryOperatorStatus - function
    const getBranchSummaryOperatorStatus = () => {
        setLoading1(true);
        if (accordion !== 0) return;
        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/get-branch-summary/operators-data/branch/${selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            let dataList = res.data.data;
            setOperatorStatus(dataList);
            setLoading1(false);

            setoperatorUpdate(new Date().toString())
        }).catch((error) => {
            console.log(error);
            setLoading1(false);
            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });

            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    };

    //getBranchSummaryWaitingTicketDetails - function
    const getBranchSummaryWaitingTicketDetails = () => {
        if (accordion !== 1) return;
        //alert(accordion)
        setLoading1(true);
        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/get-branch-summary/waiting-tickets/branch/${selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            setLoading1(false);
            let dataList = res.data.data;
            setWaitingTicketDetails(dataList);
            setUpdate(new Date().toString() + res.data.message);

        }).catch((error) => {
            setLoading1(false);
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });

            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })

    };

    //getBranchSummaryCategoryStatus - function
    const getBranchSummaryCategoryStatus = () => {
        setLoading1(true);
        if (accordion !== 2) return;
        //alert(accordion)
        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/get-branch-summary/category-status/branch/${selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            let dataList = res.data.data;
            setCategoryStatus(dataList)
            setLoading1(false);
        }).catch((error) => {
            console.log(error);
            setLoading1(false);
            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });

            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //vipnew - useEffect
    useEffect(() => {
        let postsArray = JSON.parse(JSON.stringify(waitingTicketDetails));
        let userData = [];
        postsArray.map((item, index) => {


            item.vipnew = (
                <>
                    {item.vip ? 'True' : 'False'}
                </>
            );
            userData.push(item);
        });

        setAllWaitingTicketDetails(userData);

    }, [update]);


    const columns = [
        {
            label: 'Employee ID',
            name: 'employeeId',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: "Name",
            name: 'name',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Operator Status',
            name: 'id',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (id) => {
                    if (allOperatorStatusList.length < 1) return;
                    let item = allOperatorStatusList.find(item => item.id === id);
                    return (<>
                        {item.status === 0 ? (
                            <p className='mb-0'>Idle</p>
                        ) : item.status === 1 ? (
                            <p className='mb-0'>Serving</p>
                        ) : item.status === 1 ? (
                            <p className='mb-0'>Serving</p>
                        ) :
                            <p className='mb-0'>{item.lastAux !== undefined && item.lastAux !== null ? item.lastAux : 'AUX'}</p>
                        }
                    </>
                    )
                }
            }
        },
        {
            label: 'Counter No',
            name: 'counterNo',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Now serving',
            name: 'nowServing',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Tickets served',
            name: 'ticketsServed',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Total time log',
            name: 'totalTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Time in serving',
            name: 'totalServiceTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Login hits',
            name: 'loginHits',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Time in idle',
            name: 'idleTime',
            options: {
                filter: false,
                sort: true
            }
        },
    ];

    const options = {
        tableBodyHeight: '45vh',
        filter: false,
        responsive: 'vertical',
        serverSide: false,
        count: operatorStatus.length,
        download: false,
        viewColumns: false,
        jumpToPage: true,
        selectableRows: 'none',
        fixedHeader: true,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        }
    };

    const columns1 = [
        {
            label: 'Token',
            name: 'ticketNumber',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: "Category",
            name: 'category',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Language',
            name: 'language',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'Issued Time',
            name: 'issuedTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Waiting Time',
            name: 'waitTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'KPI',
            name: 'kpi',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Method',
            name: 'method',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'VIP',
            name: 'ticketId',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    if (allWaitingTicketDetails.length < 1) return;
                    let item = allWaitingTicketDetails.find(item => item.ticketId === value);
                    return (<>
                        {item.vip + '' === 'true' ? '⭐' : 'NO'}
                    </>);
                }
            }
        },
    ];

    const options1 = {
        tableBodyHeight: '45vh',
        filter: false,
        responsive: 'vertical',
        serverSide: false,
        count: waitingTicketDetails.length,
        download: false,
        viewColumns: false,
        jumpToPage: true,
        selectableRows: 'none',
        fixedHeader: true,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        }
    };

    const columns2 = [
        {
            label: 'Category',
            name: 'category',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: 'Tickets issued',
            name: 'ticketsIssued',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Tickets to be served',
            name: 'ticketsToBeServed',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'Tickets served',
            name: 'ticketsServed',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Avg. wait time',
            name: 'avgWaitTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Max wait time',
            name: 'maxWaitTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Target wait time',
            name: 'targetWaitTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Avg. service time',
            name: 'avgServiceTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Max service time',
            name: 'maxServiceTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Target service time',
            name: 'targetServiceTime',
            options: {
                filter: false,
                sort: true,
            }
        }
    ];

    const options2 = {
        tableBodyHeight: '45vh',
        filter: false,
        responsive: 'vertical',
        serverSide: false,
        count: categoryStatus.length,
        download: false,
        viewColumns: false,
        jumpToPage: true,
        selectableRows: 'none',
        fixedHeader: true,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        }
    };

    return (
        <>
            <div><Toaster /></div>
            <div className="container-fluid">

                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h2>Branch Tables</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to='/branch-summary'>Branch Summary</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">{selectedBranchName}</li>
                                </ol>
                            </nav>
                        </div>

                        <div className="col-md-6 col-sm-12 text-right hidden-xs">
                            <button className="btn btn-sm btn-primary mb-2"
                                type="button"
                                title={`View Branch Dashboard`}
                                // title={`${branch.name} - Branch Table`}
                                onClick={() => {
                                    navigateToDashboard(selectedBranchId, selectedBranchName, selectedBranchLevel, selectedBranchQueueType)
                                }}
                            >Branch Dashboard
                            </button>
                        </div>

                    </div>
                </div>

                <div className='branch-summary-top pb-3'>
                    <div className="row clearfix">
                        <div className="col-lg-12 col-md-12">
                            <div className="card ">
                                <div className="header">
                                    <h2>Branch Summary</h2>
                                </div>


                                <div className="body">
                                    <div className="row text-center">

                                        <div className="col-lg-3 border-right">

                                            <div className="summary-items localWT"
                                                onClick={() => {
                                                    if (branchData !== null && branchData.ticketsIssued !== 0) {
                                                        setShowTicketIssuedData(true);
                                                        setTicketIssuedInc(ticketIssuedInc + 1);
                                                    } else {
                                                        toast.remove();
                                                        toast("No tickets issued yet.", {
                                                            position: 'bottom-center',
                                                            icon: '⛔'
                                                        });
                                                    }

                                                }}
                                            >
                                                <p className="mb-0">Tickets issused </p>
                                                {
                                                    branchData !== null ? <p>{branchData.ticketsIssued}</p> : <p>0</p>
                                                }
                                            </div>


                                            <div className="summary-items">
                                                <p className="mb-0">Transferred tickets </p>
                                                {
                                                    branchData !== null ? <p>{branchData.transferredTickets}</p> :
                                                        <p>0</p>
                                                }
                                            </div>


                                            <div className="summary-items localWT"
                                                onClick={() => {
                                                    if (branchData !== null && branchData.ticketsServed !== 0) {
                                                        setShowTicketServedDetails(true);
                                                        setServedTicketsInc(servedTicketsInc + 1)
                                                    } else {
                                                        toast.remove();
                                                        toast("No tickets served yet.", {
                                                            position: 'bottom-center',
                                                            icon: '⛔'
                                                        });
                                                    }
                                                }}
                                            >
                                                <p className="mb-0">Tickets served</p>
                                                {
                                                    branchData !== null ? <p>{branchData.ticketsServed}</p> : <p>0</p>
                                                }
                                            </div>

                                            <div className="summary-items"
                                                onClick={() => {
                                                    if (branchData !== null && branchData.noShowCount !== 0) {
                                                        setShowNoShowTicketsDetails(true)
                                                        setNoShowInc(noShowInc + 1);
                                                    } else {
                                                        toast.remove();
                                                        toast("No tickets yet.", {
                                                            position: 'bottom-center',
                                                            icon: '⛔'
                                                        });
                                                    }
                                                }}
                                            >
                                                <p className="mb-0">Tickets no show</p>
                                                {
                                                    branchData !== null ? <p>{branchData.noShowCount}</p> : <p>0</p>
                                                }
                                            </div>

                                        </div>


                                        <div className="col-lg-3 border-right">

                                            <div className="summary-items localWT"
                                                onClick={() => {
                                                    setShowLocalWaitTicketData(true);
                                                    //getBranchSummaryLocalWaittingTickets();
                                                }}
                                            >
                                                <p className="mb-0">Local waiting ticket </p>
                                                {
                                                    branchData !== null ? <p>{branchData.localWaitingCount}</p> :
                                                        <p>0</p>
                                                }
                                            </div>

                                            <div className="summary-items">
                                                <p className="mb-0">Tickets to be served</p>
                                                {
                                                    branchData !== null ? <p>{branchData.ticketsToServed}</p> : <p>0</p>
                                                }
                                            </div>

                                            <div className="summary-items localWT"
                                                onClick={() => {
                                                    setShowTicketServingData(true)
                                                }}

                                            >
                                                <p className="mb-0">Ticket serving</p>
                                                {
                                                    branchData !== null ? <p>{branchData.ticketsServing}</p> : <p>0</p>
                                                }
                                            </div>

                                            <div className="summary-items">
                                                <p className="mb-0">Active counter</p>
                                                {
                                                    branchData !== null ? <p>{branchData.activeCounters}</p> :
                                                        <p>0</p>
                                                }
                                            </div>

                                        </div>


                                        <div className="col-lg-3 border-right">

                                            <div className="summary-items">
                                                <p className="mb-0">Total counter</p>
                                                {
                                                    branchData !== null ? <p>{branchData.counters}</p> : <p>0</p>
                                                }
                                            </div>

                                            <div className="summary-items">
                                                <p className="mb-0">Avg.serving time</p>
                                                {
                                                    branchData !== null ? <p>{branchData.averageServingTime}</p> :
                                                        <p>0</p>
                                                }
                                            </div>

                                            <div className="summary-items">
                                                <p className="mb-0">Avg.waiting time</p>

                                                {
                                                    branchData !== null ? <p>{branchData.averageWaitingTime}</p> :
                                                        <p>0</p>

                                                }

                                            </div>

                                            <div className="summary-items">
                                                <p className="mb-0">Max.serving time</p>
                                                {
                                                    branchData !== null ? <p>{branchData.maxServiceTime}</p> :
                                                        <p>0</p>
                                                }
                                            </div>

                                        </div>


                                        <div className="col-lg-3 ">
                                            <div className="summary-items">
                                                <p className="mb-0">Max.waiting time</p>
                                                {
                                                    branchData !== null ? <p>{branchData.maxWaitTime}</p> : <p>0</p>
                                                }
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='branch-tables-container'>

                    <Accordion className="accordion" id="accordion" defaultActiveKey="0">
                        <div className='accordion-item'>
                            <div className="card-header" id="headingOne">
                                <h5 className="mb-0">
                                    <Accordion.Toggle variant="link" eventKey="0" className="btn btn-link"
                                        onClick={() => {
                                            if (accordion !== 0)
                                                setAccordion(0);
                                            else setAccordion(-1);
                                        }}>
                                        <div className='accordion-header'>
                                            <h2>Operator Status</h2>
                                            <div className='fa fa-sort-desc ml-1'
                                            />
                                        </div>
                                    </Accordion.Toggle>
                                </h5>
                            </div>

                            <Accordion.Collapse eventKey="0" className="collapse">
                                <div className="accordion-body">
                                    <div className="table-responsive muidatatablecontainer">
                                        {
                                            operatorUpdate.length &&
                                            (<>
                                                <input value={operatorUpdate} type={'hidden'} />
                                                <MUIDataTable
                                                    title={<>
                                                        {allOperatorStatusList.length}{" online "}{allOperatorStatusList.length === 1 ? 'user' : ' users'}
                                                        {loading1 &&
                                                            <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                                                size={20} /></>
                                                        }
                                                    </>
                                                    }
                                                    data={allOperatorStatusList}
                                                    columns={columns}
                                                    options={options}
                                                />
                                            </>)
                                        }

                                    </div>
                                </div>
                            </Accordion.Collapse>
                        </div>

                        <div className='accordion-item'>
                            <div className="card-header" id="headingOne">
                                <h5 className="mb-0">
                                    <Accordion.Toggle variant="link" eventKey="1" className="btn btn-link"
                                        onClick={() => {
                                            if (accordion !== 1)
                                                setAccordion(1);
                                            else setAccordion(-1);
                                        }}>
                                        <div className='accordion-header'>
                                            <h2>Waiting Tickets</h2>
                                            <div className='fa fa-sort-desc ml-1'

                                            />
                                        </div>
                                    </Accordion.Toggle>
                                </h5>
                            </div>
                            <Accordion.Collapse eventKey="1" className="collapse">
                                <div className="accordion-body">
                                    <div className="table-responsive muidatatablecontainer">
                                        <MUIDataTable
                                            title={<>
                                                {allWaitingTicketDetails.length}{" waiting "}{allWaitingTicketDetails.length === 1 ? 'ticket' : ' tickets'}
                                                {loading1 &&
                                                    <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                                        size={20} /></>
                                                }
                                            </>
                                            }
                                            data={allWaitingTicketDetails}
                                            columns={columns1}
                                            options={options1}
                                        />
                                    </div>
                                </div>
                            </Accordion.Collapse>

                        </div>

                        <div className='accordion-item'>
                            <div className="card-header" id="headingOne">
                                <h5 className="mb-0">
                                    <Accordion.Toggle variant="link" eventKey="2" className="btn btn-link"
                                        onClick={() => {
                                            if (accordion !== 2)
                                                setAccordion(2);
                                            else setAccordion(-1);
                                        }}>
                                        <div className='accordion-header'>
                                            <h2>Category Status</h2>
                                            <div className='fa fa-sort-desc ml-1'
                                            />
                                        </div>
                                    </Accordion.Toggle>
                                </h5>
                            </div>
                            <Accordion.Collapse eventKey="2" className="collapse">
                                <div className="accordion-body">
                                    <div className="table-responsive muidatatablecontainer">

                                        <MUIDataTable
                                            title={<>
                                                {categoryStatus.length}{categoryStatus.length === 1 ? ' category' : ' categories'}
                                                {loading1 &&
                                                    <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                                        size={20} /></>
                                                }
                                            </>
                                            }
                                            data={categoryStatus}
                                            columns={columns2}
                                            options={options2}
                                        />
                                    </div>
                                </div>
                            </Accordion.Collapse>
                        </div>

                    </Accordion>

                </div>


            </div>
            {
                showLocalWaitTicketData &&
                <BranchTableLocalWaitingTicketDetails
                    showLocalWaitTicketData={showLocalWaitTicketData}
                    setShowLocalWaitTicketData={setShowLocalWaitTicketData}
                    branchData={branchData}
                    selectedBranchId={selectedBranchId}
                // userBid={userBid}
                />
            }

            {
                showTicketIssuedData &&
                <BranchTableIssuedTicketModal
                    ticketIssuedInc={ticketIssuedInc}
                    showTicketIssuedData={showTicketIssuedData}
                    setShowTicketIssuedData={setShowTicketIssuedData}
                    selectedBranchId={selectedBranchId}
                    messageInc1000={messageInc1000}
                    messageInc1001={messageInc1001}
                    date={date}
                />
            }


            {
                showTicketServedDetails &&
                <BranchTableTicketServedModal
                    showTicketServedDetails={showTicketServedDetails}
                    setShowTicketServedDetails={setShowTicketServedDetails}
                    selectedBranchId={selectedBranchId} messageInc1001={messageInc1001}
                    servedTicketsInc={servedTicketsInc}
                    date={date}
                />
            }


            {
                showNoShowTicketsDetails &&
                <NoShowTicketsDetails
                    showNoShowTicketsDetails={showNoShowTicketsDetails}
                    setShowNoShowTicketsDetails={setShowNoShowTicketsDetails}
                    selectedBranchId={selectedBranchId} noShowInc={noShowInc} messageInc1001={messageInc1001}
                    messageInc2000={messageInc2000}
                    messageInc2001={messageInc2001} messageInc3001={messageInc3001} date={date}
                />
            }


            {
                showTicketServingData &&
                <BranchTableServingTicketModal
                    showTicketServingData={showTicketServingData}
                    setShowTicketServingData={setShowTicketServingData}
                    selectedBranchId={selectedBranchId}
                />
            }

        </>
    )
}

export default BranchTables