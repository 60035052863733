import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap-button-loader';
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";


const MainUserProfile = ({ companyProfileModal, updatePropValue }) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');

    const [buttonLoader, setButtonLoader] = useState(false);

    const [selectedImage, setSelectedImage] = useState({});
    const [image, setImage] = useState('')


    // call getCompanyLogo function - useEffect
    useEffect(() => {
        if (companyProfileModal) {
            getCompanyLogo();
        }
    }, [companyProfileModal])


    // get company logo - function
    const getCompanyLogo = () => {

        axios({
            method: 'POST',
            url: `${baseUrl}/branch/get-configs`,
            headers: {
                Authorization: token
            },
            data: {
                response: [
                    "logo"
                ]
            }
        }).then((res) => {

            let data = res.data.data;
            if (data.logo === undefined) {
                setImage('');
            } else {
                setImage(data.logo);
            }

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })

    }

    // update company details - function
    const updateCompanyDetails = () => {
        setButtonLoader(true);

        let formData = new FormData();
        if (selectedImage.pictureAsFile === undefined) {
            formData.append("image", image);
        } else {
            formData.append("image", selectedImage.pictureAsFile);
        }

        axios({
            method: 'POST',
            url: `${baseUrl}/branch/add-icon`,
            headers: {
                'content-type': 'multipart/form-data',
                "Authorization": token
            },
            data: formData

        }).then((res) => {

            toast.remove();
            toast.success('Successfully updated', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            closeModal();

        }).catch((error) => {
            console.log(error);

            setButtonLoader(false);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })

    }

    // imageChange - function
    const imageChange = (e) => {
        setSelectedImage({
            picturePreview: URL.createObjectURL(e.target.files[0]),
            pictureAsFile: e.target.files[0],
        });

    }

    // close modal - function
    const closeModal = () => {
        updatePropValue();
    }

    return (
        <>
            {companyProfileModal ?
                <>
                    <div className={`modal fade  d-block show modal-container`} id="exampleModal">
                        <div className="modal-dialog " role="document">
                            <form className='user-form'>
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Company Details</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                            onClick={() => {
                                                closeModal()
                                            }}
                                        >
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">

                                        <div className="">
                                            <div className="circle text-center">


                                                <div className="personal-image">
                                                    <label className="img-input-label">
                                                        <input type="file" id="fileInput" name="image"
                                                            onChange={imageChange}
                                                            accept="image/*" />
                                                        <div className="personal-figure">

                                                            {selectedImage.picturePreview ?
                                                                <>
                                                                    <img src={selectedImage.picturePreview}
                                                                        className="personal-avatar" alt="avatar"
                                                                        style={{ width: '80px', height: '80px' }} />
                                                                    <div className="personal-figcaption">
                                                                        <img src={require('../../assets/images/others/camera-white.png')}
                                                                            alt="avatar"
                                                                            style={{ width: '25px', height: '25px' }} />
                                                                    </div>
                                                                </> : <>

                                                                    {image.length > 0 ?
                                                                        <img
                                                                            src={`${image}?${Date.now()}`}
                                                                            className="personal-avatar " alt="avatar" style={{ width: '80px', height: '80px' }} />
                                                                        :
                                                                        <img src={require('../../assets/images/others/no-profile.jpg')}
                                                                            className="personal-avatar " alt="avatar" style={{ width: '80px', height: '80px' }} />
                                                                    }
                                                                    <div className="personal-figcaption">
                                                                        <img src={require('../../assets/images/others/camera-white.png')}
                                                                            alt="avatar"
                                                                            style={{ width: '25px', height: '25px' }} />
                                                                    </div>
                                                                </>
                                                            }


                                                        </div>
                                                    </label>
                                                </div>


                                            </div>
                                        </div>


                                        {/* <div className="row clearfix">

                                            <div className="container">
                                                <div className="controls">
                                                    <div className="row">
                                                        <div className="col-md-6">

                                                            <div
                                                                className="form-group input-group-sm mb-3 modal-input-group">
                                                                <label htmlFor="form_name">Company Name
                                                                    *</label>
                                                                <input type="text" className="form-control"
                                                                    aria-label="Small"
                                                                    aria-describedby="inputGroup-sizing-sm"

                                                                />

                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div
                                                                className="form-group input-group-sm mb-3 modal-input-group">
                                                                <label htmlFor="form_lastname">Email
                                                                    *</label>
                                                                <input id="form_lastname"
                                                                    type="text" name="surname" aria-label="Small"
                                                                    aria-describedby="inputGroup-sizing-sm"
                                                                    className="form-control"
                                                                    required="required"
                                                                    data-error="Email is required."
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>


                                            </div>
                                        </div> */}


                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-default" data-dismiss="modal"
                                            onClick={() => {
                                                closeModal()
                                            }}
                                        >Cancel
                                        </button>
                                        <Button loading={buttonLoader} className="btn btn-primary" onClick={() => {
                                            updateCompanyDetails();
                                        }}>Update Details
                                        </Button>
                                    </div>
                                </div>
                            </form>


                        </div>
                    </div>


                </>
                : ''}
        </>
    )

}
export default MainUserProfile