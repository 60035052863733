import React, { useState, useEffect } from 'react'

import toast from "react-hot-toast";
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import { Tooltip } from 'react-bootstrap';
import { Donut } from "react-dial-knob";
import CircularProgress from "@mui/material/CircularProgress";

const KioskDetails = (props) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);

    const [tokenData, setTokenData] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (props.showKioskDetails === false) return;
        if (props.kioskInc > 0) {
            getKioskData();
        }
    }, [props.kioskInc])

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {props}
        </Tooltip>
    );

    const columns = [
        {
            label: 'Name',
            name: 'name',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: 'MAC',
            name: 'mac',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: 'Type',
            name: 'type',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: 'Design',
            name: 'kioskDesignName',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: 'Ram/Memory',
            name: 'id',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    if (tokenData.length < 1) return;
                    let data = tokenData.find(item => item.id === value);
                    return (
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                <Donut
                                    className="knob"
                                    diameter={50}
                                    min={0}
                                    max={100}
                                    step={1}
                                    value={data.useRamPercentage}
                                    theme={{
                                        donutThickness: 5,
                                        donutColor: '#17C2D7',
                                        centerColor: '#282B2F',
                                        bgrColor: '#383b40'
                                    }}
                                />
                                <Donut
                                    className="knob"
                                    diameter={50}
                                    min={0}
                                    max={100}
                                    step={1}
                                    value={data.useMemoryPercentage}
                                    theme={{
                                        donutThickness: 5,
                                        donutColor: '#4caf50',
                                        centerColor: '#282B2F',
                                        bgrColor: '#383b40'
                                    }}
                                />
                            </div>
                        </>
                    );
                }
            }
        },
    ];

    const columns_1 = [
        {
            label: 'Branch',
            name: 'branchName',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: 'Name',
            name: 'name',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: 'MAC',
            name: 'mac',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: 'Type',
            name: 'type',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: 'Design',
            name: 'kioskDesignName',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: 'Ram usage %',
            name: 'id',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    if (tokenData.length < 1) return;
                    let data = tokenData.find(item => item.id === value);
                    return (
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                <Donut
                                    className="knob"
                                    diameter={50}
                                    min={0}
                                    max={100}
                                    step={1}
                                    value={data.useRamPercentage}
                                    theme={{
                                        donutThickness: 5,
                                        donutColor: '#17C2D7',
                                        centerColor: '#282B2F',
                                        bgrColor: '#383b40'
                                    }}
                                />
                                <Donut
                                    className="knob"
                                    diameter={50}
                                    min={0}
                                    max={100}
                                    step={1}
                                    value={data.useMemoryPercentage}
                                    theme={{
                                        donutThickness: 5,
                                        donutColor: '#4caf50',
                                        centerColor: '#282B2F',
                                        bgrColor: '#383b40'
                                    }}
                                />
                            </div>
                        </>
                    );
                }
            }
        }
    ];
    const options = {
        tableBodyHeight: '50vh',
        filter: false,
        responsive: 'vertical',
        serverSide: false,
        count: count,
        download: false,
        viewColumns: false,
        jumpToPage: true,
        selectableRows: 'none',
        print: false,
        rowsPerPage: rowsPerPage,
        onChangeRowsPerPage: (n) => {
            setRowsPerPage(n)
        },
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        }
    };

    const getKioskData = () => {
        setLoading(true);

        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/get-kiosk-details/branch/${props.selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('getServedTicketsData success');
            let data = res.data.data;
            // loopChartData(data.countAndPercentage);

            // setchartData(data.countAndPercentage);
            setTokenData(data);

            setCount(data.length);
            setLoading(false);

        }).catch((error) => {
            console.log('getTicketIssuedDetails error');
            console.log(error);
            setLoading(false);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    return (
        <>
            {props.showKioskDetails ?
                <div id="megamenu" className={`megamenu particles_js details-page open `}>
                    <span
                        onClick={() => {
                            props.setSticky(true)
                            props.setShowKioskDetails(false);
                        }}
                        className="megamenu_toggle btn btn-danger"><i className="icon-close"></i></span>
                    <div className="container">
                        <div className="row clearfix">
                            <div className="col-12">
                                <div className="card">
                                    <div className="body">
                                        <div style={{ textAlign: 'center' }}>
                                            <h4>Kiosk details</h4>
                                            <small>{props.date}</small>
                                        </div>
                                        <br></br>
                                        <div className="row clearfix">
                                            <div className="col-12">
                                                <div className="muidatatablecontainer">
                                                    {props.selectedBranchLevel === 4 ? (
                                                        <MUIDataTable
                                                            title={<>
                                                                {count}{count === 1 ? ' record' : ' records'}.
                                                                {isLoading &&
                                                                    <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                                                        size={20} /></>}
                                                            </>
                                                            }
                                                            data={tokenData}
                                                            columns={columns}
                                                            options={options}
                                                        />
                                                    ) : (
                                                        <MUIDataTable
                                                            title={<>
                                                                {count}{count === 1 ? ' record' : ' records'}.
                                                                {isLoading &&
                                                                    <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                                                        size={20} /></>}
                                                            </>
                                                            }
                                                            data={tokenData}
                                                            columns={columns_1}
                                                            options={options}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div id="particles-js"></div>
                </div>
                : null}
        </>
    )
}

export default KioskDetails