import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap-button-loader';
import axios from 'axios';
import toast, { Toaster } from "react-hot-toast";


const AddSmsTemplateModal = ({ setShowAddSmsModal, ShowAddSmsModal, modalType, selectedSmsTemplate, setSelectedSmsTemplate, getallsmstemplateByBranch, selectedBranchId }) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [templateName, setTemplateName] = useState('');
    const [smsContent, setSmsContent] = useState('');
    const [selectedSmsTypeId, setSelectedSmsTypeId] = useState('');

    const [allSmsType, setAllSmsType] = useState([]);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [selectedTag, setSelectedTag] = useState('');

    const tags = [
        {
            id: "1",
            label: "#TOKEN",
            value: "#TOKEN"
        },
        {
            id: "2",
            label: "#BOOKING_URL",
            value: "#BOOKING_URL"
        },
        {
            id: "3",
            label: "#CATEGORY",
            value: "#CATEGORY"
        },
        {
            id: "4",
            label: "#NETTED_SERVICES",
            value: "#NETTED_SERVICES"
        },
        {
            id: "5",
            label: "#DATE",
            value: "#DATE"
        },
        {
            id: "6",
            label: "#TIME",
            value: "#TIME"
        },
        {
            id: "7",
            label: "#BRANCH",
            value: "#BRANCH"
        },
    ];


    // combineTwoStrings - useeffect
    useEffect(() => {
        if (selectedTag.length > 0) {
            combineTwoStrings();
        }
    }, [selectedTag])


    //callgetallSMStypesbycustomer - useeffect
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            if (baseUrl.length > 0 && token.length > 0) {
                getAllSmsTypesByCustomer();
            }
        }
    }, [AddSmsTemplateModal]);

    //is update - useeffect
    useEffect(() => {
        if (ShowAddSmsModal) {
            if (modalType === 1) {
                if (Object.keys(selectedSmsTemplate).length > 0) {
                    console.log('xxxxxx ', selectedSmsTemplate);
                    setTemplateName(selectedSmsTemplate.name);
                    setSmsContent(selectedSmsTemplate.content);
                    setSelectedSmsTypeId(selectedSmsTemplate.typeId)
                }
            }
        }
    }, [ShowAddSmsModal])


    // addSmsTemplate - function
    const addSmsTemplate = () => {
        console.log('addSmsTemplate function start');

        setButtonLoader(true);

        let isValidate = validate();

        if (isValidate) {

            axios({
                method: 'POST',
                url: `${baseUrl}/alert/add/sms-template`,
                headers: {
                    Authorization: token
                },
                data: {
                    name: templateName,
                    content: smsContent,
                    customerId: userDetails.cid,
                    smsType: selectedSmsTypeId,
                    branchId: selectedBranchId
                }
            }).then((res) => {
                console.log('addSmsTemplate success');
                let data = res.data.data;

                // getallsmstemplate();
                getallsmstemplateByBranch();
                setSelectedSmsTemplate(data);

                toast.remove();
                toast.success('Successfully added', {
                    position: 'bottom-center',
                });
                setButtonLoader(false);
                closeModal();

            }).catch((error) => {
                console.log('addSmsTemplate error');
                console.log(error);

                setButtonLoader(false);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                    setButtonLoader(false);
                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                    setButtonLoader(false);
                }
            })
        }
    }

    //getAllSmsTypesByCustomer - function
    const getAllSmsTypesByCustomer = () => {
        console.log('getAllSmsTypesByCustomer function start');

        axios({
            method: 'GET',
            url: `${baseUrl}/alert/sms-type/get-list/customer/${userDetails.cid}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            console.log('getAllSmsTypesByCustomer success');
            console.log(res.data.data);

            setAllSmsType(res.data.data);

        }).catch((error) => {
            console.log('getAllSmsTypesByCustomer error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //updateSMStemplate - function
    const updateSMStemplate = () => {
        console.log('update SMS template function start');

        axios({
            method: 'POST',
            url: `${baseUrl}/alert/sms-template/update/template/${selectedSmsTemplate.id}`,
            headers: {
                Authorization: token
            },
            data: {
                name: templateName,
                content: smsContent
            }

        }).then((res) => {
            console.log('update SMS template success');
            console.log(res.data.data);

            toast.remove();
            toast.success('Successfully added', {
                position: 'bottom-center',
            });

            // getallsmstemplate();
            getallsmstemplateByBranch();
            closeModal();

        }).catch((error) => {
            console.log('update SMS template error');
            console.log(error);

            setButtonLoader(false);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
                setButtonLoader(false);
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
                setButtonLoader(false);
            }
        })
    }


    //validate - function
    const validate = () => {
        if (templateName.length === 0) {
            toast.remove();
            toast.error('Template name empty', {
                position: "bottom-center",
            });
            setButtonLoader(false);
            return false;
        } else if (smsContent.length === 0) {
            toast.remove();
            toast.error('Template content empty', {
                position: "bottom-center",
            });
            setButtonLoader(false);
            return false;
        } else if (selectedSmsTypeId.length === 0) {
            toast.remove();
            toast.error('SMS type empty', {
                position: "bottom-center",
            });
            setButtonLoader(false);
            return false;
        } else {
            return true;
        }
    };


    //combineTwoStrings - function
    const combineTwoStrings = () => {
        console.log('combineTwoStringsfunctionstart');

        console.log(smsContent);
        console.log(selectedTag);

        var String_3 = smsContent.concat("", selectedTag);
        console.log(String_3);
        setSmsContent(String_3)
    }


    //closemodal- function
    const closeModal = () => {
        setTemplateName('');
        setSmsContent('');
        setSelectedTag('');
        setShowAddSmsModal(false);
        setSelectedSmsTypeId('');
    }

    return (
        <>
            {ShowAddSmsModal ? (
                <div className={`modal fade d-block show modal-container`} id="category-modal">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{modalType === 0 ?
                                    <>Add new SMS template</> : <>Edit SMS template</>}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        closeModal()
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">

                                {/* {modalType === 0 ? ( */}

                                <form className='sms-template-add-form '>

                                    <div className="row clearfix">
                                        <div className="col-lg-5 col-md-5">
                                            <div className="input-group input-group-sm mb-2">
                                                <p className="input-field-side-text">Sms Template Name :</p>
                                            </div>
                                            <div className="input-group input-group-sm mb-3">
                                                <input autoFocus type="text" className="form-control"
                                                    aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                    value={templateName}
                                                    onChange={(e) => {
                                                        setTemplateName(e.target.value);
                                                    }}
                                                />
                                            </div>


                                            <div className="input-group input-group-sm mb-2">
                                                <p className="input-field-side-text">Sms Type :</p>
                                            </div>
                                            <div className="input-group input-group-sm mb-3">
                                                <select className="custom-select modal-select-field " id="inputGroupSelect01"
                                                    value={selectedSmsTypeId}
                                                    defaultChecked={selectedSmsTemplate.typeId === true}
                                                    disabled={modalType === 1 ? true : false}
                                                    onChange={(e) => {
                                                        setSelectedSmsTypeId(e.target.value);
                                                    }}
                                                >
                                                    <option defaultValue hidden>Choose... </option>
                                                    {allSmsType.map((item, index) =>
                                                        <option value={item.id} key={index}>
                                                            {item.type}
                                                        </option>
                                                    )}
                                                </select>
                                            </div>


                                            <div className="input-group input-group-sm mb-2">
                                                <p className="input-field-side-text"> Select Tag : </p>
                                            </div>

                                            {tags.map((item, i) => (
                                                <div className='badge badge-default'
                                                    style={{ cursor: 'pointer' }}
                                                    key={i}
                                                    onClick={() => {
                                                        setSelectedTag(item.value)
                                                    }}
                                                >
                                                    {item.value}
                                                </div>
                                            ))}

                                        </div>


                                        <div className="col-lg-7 col-md-7">
                                            <div className="form-group">
                                                <textarea rows={10} className="form-control no-resize"
                                                    placeholder="Please type what you want..."
                                                    value={smsContent}
                                                    onChange={(e) => {
                                                        setSmsContent(e.target.value);
                                                    }}
                                                />
                                            </div>

                                        </div>

                                    </div>

                                </form>


                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >Cancel
                                </button>



                                {modalType === 0 ?
                                    <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                        onClick={() => {
                                            addSmsTemplate();
                                        }}
                                    >Save changes</Button>
                                    : modalType === 1 ?
                                        <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                            onClick={() => {
                                                updateSMStemplate();
                                            }}
                                        >Update changes</Button>
                                        :
                                        (null)
                                }
                            </div>
                        </div>
                    </div>
                </div >
            ) : null
            }

        </>
    )
}

export default AddSmsTemplateModal;