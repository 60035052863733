import React, { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";
import Button from 'react-bootstrap-button-loader';


const AssignCategorySkillsModal = ({ showAssignCategorySkillsModal, setShowAssignCategorySkillsModal, selectedRow, }) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [buttonLoader, setButtonLoader] = useState(false);
    const [refresh, setRefresh] = useState(1)

    const [allSkillsByLanguage, setAllSkillsByLanguage] = useState([]);

    const [searchSkillsName, setSearchSkillsName] = useState('');
    const [searchAllSkillsByLanguage, setSearchAllSkillsByLanguage] = useState('');


    // call getCategorySkillsWithLanguage
    useEffect(() => {
        if (showAssignCategorySkillsModal) {
            if (baseUrl.length > 0 && token.length > 0 && Object.keys(userDetails).length > 0) {
                getCategorySkillsWithLanguage();
            }
        }
    }, [showAssignCategorySkillsModal])

    // call searchSkills - useEffect
    useEffect(() => {
        if (searchSkillsName.length > 0)
            searchSkills();

    }, [searchSkillsName])


    // getCategorySkillsWithLanguage - function
    const getCategorySkillsWithLanguage = () => {
        console.log('getCategorySkillsWithLanguage function start')

        axios({
            method: 'GET',
            url: `${baseUrl}/skill/get-category-skills/customer/${userDetails.cid}/category/${selectedRow.id}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            console.log('getCategorySkillsWithLanguage Success');
            let data = res.data.data
            console.log(data);
            setAllSkillsByLanguage(data);


        }).catch((error) => {
            console.log('getCategorySkillsWithLanguage Error');
            console.log(error);

            if (error.response !== undefined) {
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // assignSkillsToCategory - function
    const assignSkillsToCategory = () => {
        console.log('assignSkillsToCategory function start');

        let allSelectedSkills = filterSelectedSkills();

        // //validate
        if (allSelectedSkills.length > 0) {
            axios({
                method: 'POST',
                url: `${baseUrl}/skill/add-category-skills`,
                headers: {
                    Authorization: token
                },
                data: {
                    skills: allSelectedSkills,
                    cid: selectedRow.id,
                    customerId: userDetails.cid
                }
            }).then((res) => {
                console.log('assignSkillsToCategory Success');
                setButtonLoader(false);
                closeModal();

                toast.remove();
                toast.success('Skill successfully Save', {
                    position: 'bottom-center',
                });

                setRefresh(refresh + 1);

            }).catch((error) => {
                console.log('assignSkillsToCategory error');
                console.log(error);
                setButtonLoader(false);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });

                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }
            })
        } else {
            setButtonLoader(false);
            toast.remove();
            toast.error('Language can not be empty', {
                position: 'bottom-center',
            });
        }
    }


    // filterSelectedSkills - function
    const filterSelectedSkills = () => {
        let allSelectedSkills = [];
        for (let i = 0; i < allSkillsByLanguage.length; i++) {

            let skills = allSkillsByLanguage[i].skills;
            for (let j = 0; j < skills.length; j++) {
                if (allSkillsByLanguage[i].skills[j].selected === true) {
                    allSelectedSkills.push(skills[j].id)
                }
            }
        }
        return allSelectedSkills;
    }

    // searchSkills - function
    const searchSkills = () => {
        console.log('searchSkillsFunctionStart');

        let searchedSkillsWithLang = [];

        for (let i = 0; i < allSkillsByLanguage.length; i++) {

            let language = allSkillsByLanguage[i].language;
            let skills = allSkillsByLanguage[i].skills;
            let searchedSkillList = [];

            for (let j = 0; j < skills.length; j++) {

                let oneSkill = skills[j].skill.toLowerCase();

                if (oneSkill.includes(searchSkillsName)) {
                    searchedSkillList.push(skills[j]);
                }
            }

            if (searchedSkillList.length > 0) {
                searchedSkillsWithLang.push({
                    language: language,
                    skills: searchedSkillList
                }
                )
            }
        }
        setSearchAllSkillsByLanguage(searchedSkillsWithLang);
    }

    // handleWhenClickSkill - function
    const handleWhenClickSkill = (skillsByLan, selectedSkillId) => {

        for (let i = 0; i < skillsByLan.length; i++) {
            const element = skillsByLan[i];

            if (element.id === selectedSkillId) {
                element.selected = true;
            } else {
                element.selected = false;
            }

        }
        setRefresh(refresh + 1);
    }

    // close the add modal - function
    const closeModal = () => {
        setButtonLoader(false);
        for (let i = 0; i < allSkillsByLanguage.length; i++) {
            allSkillsByLanguage[i].selected = false;
        }
        setSearchSkillsName('');
        setShowAssignCategorySkillsModal(false);
    }


    return (
        <>
            <div><Toaster /></div>
            {showAssignCategorySkillsModal ? (
                <div className={`modal fade d-block show modal-container`} id="counter-modal">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Assign Category Skills - {selectedRow.category}
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => closeModal()}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">

                                <div className="row clearfix">
                                    <div className="col-lg-6 col-md-6">
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control"
                                                placeholder="Search here..."
                                                onKeyUp={(e) => {
                                                    setSearchSkillsName(e.target.value);
                                                }}
                                            />

                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="button"
                                                    onClick={() => {

                                                    }}>
                                                    <i className="fa fa-search" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row clearfix">
                                    {
                                        searchSkillsName.length === 0 ?
                                            <>
                                                {
                                                    allSkillsByLanguage.length > 0 ? (
                                                        allSkillsByLanguage.map((item, i) => (
                                                            <div className="col-lg-12 col-md-12" key={i}>

                                                                <div className="card">
                                                                    <div className="body">
                                                                        <div class="header skill-header">
                                                                            <h2>{item.language}
                                                                            </h2>
                                                                        </div>

                                                                        <div className="user-skill-list">
                                                                            {
                                                                                item.skills.map((skill, i) => (
                                                                                    <span class={`badge ${skill.selected ? "badge-secondary " : "badge-default"}`}
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        key={i}
                                                                                        onClick={() => {
                                                                                            handleWhenClickSkill(item.skills, skill.id);
                                                                                        }}
                                                                                    >{skill.skill}
                                                                                    </span>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className='col-md-12'>
                                                            <div class="alert alert-custom alert-dismissible" role="alert">
                                                                <i class="fa fa-info-circle"></i> Empty records
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                            :
                                            <>
                                                {
                                                    searchAllSkillsByLanguage.length > 0 ? (
                                                        searchAllSkillsByLanguage.map((item, i) => (
                                                            <div className="col-lg-12 col-md-12" key={i}>

                                                                <div className="card">
                                                                    <div className="body">
                                                                        <div class="header"><h2>{item.language}</h2></div>
                                                                        <div className="user-skill-list">
                                                                            {
                                                                                item.skills.map((skill, i) => (
                                                                                    <span class={`badge ${skill.selected ? "badge-secondary " : "badge-default"}`}
                                                                                        key={i}
                                                                                        onClick={() => {
                                                                                            skill.selected = !skill.selected;
                                                                                            setRefresh(refresh + 1);
                                                                                        }}
                                                                                    >{skill.skill}
                                                                                    </span>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className='col-md-12'>
                                                            <div class="alert alert-custom alert-dismissible" role="alert">
                                                                <i class="fa fa-info-circle"></i> Empty records
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                    }
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-default"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >Cancel
                                </button>

                                <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                    onClick={() => {
                                        assignSkillsToCategory();
                                    }}
                                >Save
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null
            }
        </>
    )
}

export default AssignCategorySkillsModal