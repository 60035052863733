import Login from './Authentication/login';
import Signup from './Authentication/signup';
import ForgotPassword from './Authentication/forgotpassword';
import NotFound from './Authentication/404';
import Maintenance from './Authentication/maintenance';
import Dashboard from './Dashboard/dashboard';
import Dashboard2 from './Dashboard/dashboard2';
import Dashboard3 from './Dashboard/dashboard3';
import Dashboard4 from './Dashboard/dashboard4';
import Dashboard5 from './Dashboard/dashboard5';
import Dashboard6 from './Dashboard/dashboard6';
import Dashboard7 from './Dashboard/dashboard7';
import Dashboard8 from './Dashboard/dashboard8';
import Dashboard9 from './Dashboard/dashboard9';
import Dashboard10 from './Dashboard/dashboard10';
import Dashboard11 from './Dashboard/dashboard11';
import Dashboard12 from './Dashboard/dashboard12';
import FormAdvanced from './Form/formAdvanced';
import FormBasic from './Form/formsBasic';
import FormCropping from './Form/formsCropping';
import FormDragdropupload from './Form/formDragdropupload';
import FormsEditors from './Form/formsEditors';
import FormSummernote from './Form/formSummernote';
import FormValidation from './Form/formValidation';
import FormWizard from './Form/formWizard';
import ListView from './Contacts/ListView';
import GridView from './Contacts/GridView';
import Inbox from './Email/Inbox';
import Compose from './Email/Compose';
import Details from './Email/Details';
import Chat from './Messenger/chat';
import ProjectList from './Projects/ProjectList';
import TicketList from './Projects/TicketList';
import TicketDetails from './Projects/TicketDetails';
import Taskboard from './Projects/Taskboard';
import TodoList from './Projects/TodoList';
import Clients from './Projects/Clients';
import Icons from './UI/Icons';
import SimpleLine from './UI/SimpleLine';
import Themify from './UI/Themify';
import Bootstrap from './Components/Bootstrap';
import Typography from './Components/Typography';
import RangeSliders from './Components/RangeSliders';
import Tabs from './Components/Tabs';
import ProgressBars from './Components/ProgressBars';
import Notifications from './Components/Notifications';
import Modals from './Components/Modals';
import Calendar from './Calendar/Calendar';
import ApexChart from './Chart/apexChart';
import jVector from './Map/jVector';
import PageSocial from './Pages/pageSocial';
import PageNews from './Pages/pageNews';
import PageBlogPost from './Pages/pageBlogPost';
import PageBlog from './Pages/pageBlog';
import pageBlank from './Pages/pageBlank';
import pageProfile from './Pages/pageProfile';
import pageUserList from './Pages/pageUserList';
import PageTestimonials from './Pages/pageTestimonials';
import PageInvoiceDetails from './Pages/pageInvoiceDetails';
import PageInvoice from './Pages/pageInvoice';
import PageTimeline from './Pages/pageTimeline';
import PageSearchResult from './Pages/pageSearchResult';
import PageGallery from './Pages/pageGallery';
import PagePricing from './Pages/pagePricing';
import PageComingSoon from './Pages/pageComingSoon';
import Widgets from './Widgets/Widgets';
import TableColor from './Table/tableColor';
import TableDragger from './Table/tableDragger';
import TableEditable from './Table/tableEditable';
import TableFilter from './Table/tableFilter';
import TableJqueryDatatable from './Table/tableJqueryDatatable';
import TableNormal from './Table/tableNormal';
import Colors from './Components/Colors';
import Buttons from './Components/Buttons';
import Dialogs from './Components/Dialogs';
import ListGroup from './Components/ListGroup';
import MediaObject from './Components/MediaObject';
import Nestable from './Components/Nestable';
//impl
import Users from './PagesImpl/Users';
import RegionManagement from './PagesImpl/RegionManagement';
import BranchLandingPage from './PagesImpl/BranchLandingPage';
import CountersManagement from './PagesImpl/CountersManagement';
import MainUser from "./PagesImpl/MainUser";
import CategoriesManagement from './PagesImpl/CategoriesManagement';
import Workflow from "./PagesImpl/Workflow";
import MainDisplayManagement from './PagesImpl/MainDisplayManagement';
import NoSkill from './PagesImpl/NoSkill';

import CategoriesMaster from './PagesImpl/CategoriesMaster';
import SubCategories from './PagesImpl/SubCategories'
import UserRolesManagement from './PagesImpl/UserRolesManagement';
import UserProfile from "./PagesImpl/UserProfile";
import EditMainUser from "./PagesImpl/EditMainUser";
import LoginPage from "./PagesImpl/LoginPage";
import Register from "./PagesImpl/Register";
import PageNotFound from './PagesImpl/PageNotFound';
import SmsTemplate from "./PagesImpl/SmsTemplate";
import BranchSummary from "./PagesImpl/BranchSummary";
import MattercodeManagement from "./PagesImpl/MattercodeManagement";
import BranchDashboard from "./PagesImpl/BranchDashboard";
import KiosksTemplates from './PagesImpl/KiosksTemplates';
import KiosksManagement from './PagesImpl/KiosksManagement';
import DesignKiosksTemplate from './PagesImpl/DesignKiosksTemplate';
import BranchCategoriesGroup from './PagesImpl/BranchCategoriesGroup';
import AnalyticsPage from './PagesImpl/AnalyticsPage';
import CategoryAnalytics from './AnalyticsImpl/CategoryAnalytics';
import AudianceAnalytics from './AnalyticsImpl/AudianceAnalytics';
import CategoryAssignPage from './PagesImpl/CategoryAssignPage';
import RealtimeUsersBySkill from './PagesImpl/RealtimeUsersBySkill';
import ReactKonva from './ComponentsImpl/ReactKonva/ReactKonva';
import BranchTables from './PagesImpl/BranchTables';
import TsuProfile from './PagesImpl/TsuProfile';
import NotificationTemplate from './PagesImpl/NotificationTemplate';
import NotificationManager from './PagesImpl/NotificationManager'
import HomePage from './PagesImpl/HomePage';


import Report from './PagesImpl/Report'
import SmsGroup from './PagesImpl/SmsGroup';
import Audit from "./PagesImpl/Audit";
import AppointmentBookingPage from './PagesImpl/AppointmentBookingPage';
import { AppointmentUserListPage } from './PagesImpl/AppointmentUserListPage';
import { AppointmentUserActivityPage } from './PagesImpl/AppointmentUserActivityPage';

const Routes = [
    {
        path: "/ui-nestable",
        name: 'Nestable',
        exact: true,
        pageTitle: "Nestable",
        component: <Nestable />
    },
    {
        path: "/ui-media-object",
        name: 'MediaObject',
        exact: true,
        pageTitle: "MediaObject",
        component: <MediaObject />
    },
    {
        path: "/ui-list-groups",
        name: 'ListGroup',
        exact: true,
        pageTitle: "ListGroup",
        component: <ListGroup />
    },
    {
        path: "/ui-dialogs",
        name: 'Dialogs',
        exact: true,
        pageTitle: "Dialogs",
        component: <Dialogs />
    },
    {
        path: "/ui-buttons",
        name: 'Buttons',
        exact: true,
        pageTitle: "Buttons",
        component: <Buttons />
    },
    {
        path: "/ui-colors",
        name: 'Colors',
        exact: true,
        pageTitle: "Colors",
        component: <Colors />
    },
    {
        path: "/table/table-normal",
        name: 'tablenormal',
        exact: true,
        pageTitle: "TableNormal",
        component: <TableNormal />
    },
    {
        path: "/table/table-jquery-datatable",
        name: 'tablejquerydatatable',
        exact: true,
        pageTitle: "TableJqueryDatatable",
        component: <TableJqueryDatatable />
    },
    {
        path: "/table/table-filter",
        name: 'tablefilter',
        exact: true,
        pageTitle: "TableFilter",
        component: <TableFilter />
    },
    {
        path: "/table/table-editable",
        name: 'tableeditable',
        exact: true,
        pageTitle: "TableEditable",
        component: <TableEditable />
    },
    {
        path: "/table/table-dragger",
        name: 'tabledragger',
        exact: true,
        pageTitle: "TableDragger",
        component: <TableDragger />
    },
    {
        path: "/table/table-color",
        name: 'tablecolor',
        exact: true,
        pageTitle: "TableColor",
        component: <TableColor />
    },
    {
        path: "/page-coming-soon",
        name: 'Page Coming Soon',
        exact: true,
        pageTitle: "Page Coming Soon",
        component: <PageComingSoon />
    },
    {
        path: "/widgets",
        name: 'Widgets',
        exact: true,
        pageTitle: "Widgets",
        component: <Widgets />
    },
    {
        path: "/page-pricing",
        name: 'Page Pricing',
        exact: true,
        pageTitle: "Page Gallery",
        component: <PagePricing />
    },
    {
        path: "/dashboard12",
        name: 'dashboard12',
        exact: true,
        pageTitle: "Dashboard12",
        component: <Dashboard12 />
    },
    {
        path: "/task-board",
        name: 'Taskboard',
        exact: true,
        pageTitle: "Taskboard",
        component: <Taskboard />
    },
    {
        path: "/notifications",
        name: 'Notifications',
        exact: true,
        pageTitle: "Notifications",
        component: <Notifications />
    },
    {
        path: "/tabs",
        name: 'Tabs',
        exact: true,
        pageTitle: "Tabs",
        component: <Tabs />
    },
    {
        path: "/progressbars",
        name: 'ProgressBars',
        exact: true,
        pageTitle: "ProgressBars",
        component: <ProgressBars />
    },
    {
        path: "/modals",
        name: 'Modals',
        exact: true,
        pageTitle: "Modals",
        component: <Modals />
    },
    {
        path: "/ui-range-sliders",
        name: 'RangeSliders',
        exact: true,
        pageTitle: "RangeSliders",
        component: <RangeSliders />
    },
    {
        path: "/page-gallery",
        name: 'Page Gallery',
        exact: true,
        pageTitle: "Page Gallery",
        component: <PageGallery />
    },
    {
        path: "/page-search-results",
        name: 'Page Search Results',
        exact: true,
        pageTitle: "Page Search Results",
        component: <PageSearchResult />
    },
    {
        path: "/page-timeline",
        name: 'Page Timeline',
        exact: true,
        pageTitle: "Page Timeline",
        component: <PageTimeline />
    },
    {
        path: "/page-invoices",
        name: 'Page Invoice',
        exact: true,
        pageTitle: "Page Invoice",
        component: <PageInvoice />
    },
    {
        path: "/page-invoices-details",
        name: 'Page Invoice Details',
        exact: true,
        pageTitle: "Page Invoice Details",
        component: <PageInvoiceDetails />
    },
    {
        path: "/page-testimonials",
        name: 'Page Testimonials',
        exact: true,
        pageTitle: "Page Testimonials",
        component: <PageTestimonials />
    },
    {
        path: "/page-user-list",
        name: 'Page User List',
        exact: true,
        pageTitle: "Page User List",
        component: <pageUserList />
    },
    {
        path: "/page-profile",
        name: 'Page Profile',
        exact: true,
        pageTitle: "Page Profile",
        component: <pageProfile />
    },
    {
        path: "/page-blank",
        name: 'Page Blank',
        exact: true,
        pageTitle: "Page Blank",
        component: <pageBlank />
    },
    {
        path: "/page-blog-post",
        name: 'Page Blog post',
        exact: true,
        pageTitle: "Page Blog Post",
        component: <PageBlogPost />
    },
    {
        path: "/page-blog",
        name: 'Page Blog',
        exact: true,
        pageTitle: "Page Blog",
        component: <PageBlog />
    },
    {
        path: "/app-clients",
        name: 'Clients',
        exact: true,
        pageTitle: "Clients",
        component: <Clients />
    },
    {
        path: "/app-todo",
        name: 'TodoList',
        exact: true,
        pageTitle: "TodoList",
        component: <TodoList />
    },
    {
        path: "/page-news",
        name: 'Page News',
        exact: true,
        pageTitle: "Page News",
        component: <PageNews />
    },
    {
        path: "/page-social",
        name: 'Page Social',
        exact: true,
        pageTitle: "Page Social",
        component: <PageSocial />
    },
    {
        path: "/app-jVector-map",
        name: 'JVector',
        exact: true,
        pageTitle: "JVector",
        component: <jVector />
    },
    {
        path: "/chart/chart-apex",
        name: 'ApexChart',
        exact: true,
        pageTitle: "ApexChart",
        component: <ApexChart />
    },
    {
        path: "/app-calendar",
        name: 'Calendar',
        exact: true,
        pageTitle: "Calendar",
        component: <Calendar />
    },
    {
        path: "/ui-typography",
        name: 'Typography',
        exact: true,
        pageTitle: "Typography",
        component: <Typography />
    },
    {
        path: "/ui-bootstrap",
        name: 'Bootstrap',
        exact: true,
        pageTitle: "Bootstrap",
        component: <Bootstrap />
    },
    {
        path: "/ui-icons-themify",
        name: 'Themify',
        exact: true,
        pageTitle: "Themify",
        component: <Themify />
    },
    {
        path: "/ui-icons-line",
        name: 'simpleLine',
        exact: true,
        pageTitle: "SimpleLine",
        component: <SimpleLine />
    },
    {
        path: "/ui-icons",
        name: 'icons',
        exact: true,
        pageTitle: "Icons",
        component: <Icons />
    },
    {
        path: "/app-ticket-details",
        name: 'ticketDetails',
        exact: true,
        pageTitle: "TicketDetails",
        component: <TicketDetails />
    },
    {
        path: "/app-ticket",
        name: 'ticketList',
        exact: true,
        pageTitle: "TicketList",
        component: <TicketList />
    },
    {
        path: "/app-project-list",
        name: 'projectList',
        exact: true,
        pageTitle: "ProjectList",
        component: <ProjectList />
    },
    {
        path: "/app-chat",
        name: 'chat',
        exact: true,
        pageTitle: "Chat",
        component: <Chat />
    },
    {
        path: "/app-inbox-detail",
        name: 'details',
        exact: true,
        pageTitle: "Details",
        component: <Details />
    },
    {
        path: "/app-compose",
        name: 'compose',
        exact: true,
        pageTitle: "Compose",
        component: <Compose />
    },
    {
        path: "/app-inbox",
        name: 'inbox',
        exact: true,
        pageTitle: "Inbox",
        component: <Inbox />
    },
    {
        path: "/app-contact2",
        name: 'gridView',
        exact: true,
        pageTitle: "Grid View",
        component: <GridView />
    },
    {
        path: "/app-contact",
        name: 'listView',
        exact: true,
        pageTitle: "List View",
        component: <ListView />
    },
    {
        path: "/form/form-wizard",
        name: 'formwizard',
        exact: true,
        pageTitle: "FormWizard",
        component: <FormWizard />
    },
    {
        path: "/form/form-validation",
        name: 'formvalidation',
        exact: true,
        pageTitle: "FormValidation",
        component: <FormValidation />
    },
    {
        path: "/form/form-summernote",
        name: 'formsummernote',
        exact: true,
        pageTitle: "FormSummernote",
        component: <FormSummernote />
    },
    {
        path: "/form/form-editors",
        name: 'formseditors',
        exact: true,
        pageTitle: "FormsEditors",
        component: <FormsEditors />
    },
    {
        path: "/form/form-dragdropupload",
        name: 'formdragdropupload',
        exact: true,
        pageTitle: "FormDragdropupload",
        component: <FormDragdropupload />
    },
    {
        path: "/form/form-cropping",
        name: 'formscropping',
        exact: true,
        pageTitle: "FormsCropping",
        component: <FormCropping />
    },
    {
        path: "/form/form-basic",
        name: 'formbasic',
        exact: true,
        pageTitle: "FormBasic",
        component: <FormBasic />
    },
    {
        path: "/form/form-advanced",
        name: 'formadvanced',
        exact: true,
        pageTitle: "FormAdvanced",
        component: <FormAdvanced />
    },
    {
        path: "/dashboard11",
        name: 'dashboard11',
        exact: true,
        pageTitle: "Dashboard11",
        component: <Dashboard11 />
    },
    {
        path: "/dashboard10",
        name: 'dashboard10',
        exact: true,
        pageTitle: "Dashboard10",
        component: <Dashboard10 />
    },
    {
        path: "/dashboard9",
        name: 'dashboard9',
        exact: true,
        pageTitle: "Dashboard9",
        component: <Dashboard9 />
    },
    {
        path: "/dashboard8",
        name: 'dashboard8',
        exact: true,
        pageTitle: "Dashboard8",
        component: <Dashboard8 />
    },
    {
        path: "/dashboard7",
        name: 'dashboard7',
        exact: true,
        pageTitle: "Dashboard7",
        component: <Dashboard7 />
    },
    {
        path: "/dashboard6",
        name: 'dashboard6',
        exact: true,
        pageTitle: "Dashboard6",
        component: <Dashboard6 />
    },
    {
        path: "/dashboard5",
        name: 'dashboard5',
        exact: true,
        pageTitle: "Dashboard5",
        component: <Dashboard5 />
    },
    {
        path: "/dashboard4",
        name: 'dashboard4',
        exact: true,
        pageTitle: "Dashboard4",
        component: <Dashboard4 />
    },
    {
        path: "/dashboard3",
        name: 'dashboard3',
        exact: true,
        pageTitle: "Dashboard3",
        component: <Dashboard3 />
    },
    {
        path: "/dashboard2",
        name: 'dashboard2',
        exact: true,
        pageTitle: "Dashboard2",
        component: <Dashboard2 />
    },
    {
        path: "/dashboard",
        name: 'dashboard',
        exact: true,
        pageTitle: "Dashboard",
        component: <Dashboard />
    },
    // {
    //     path: "/customer",
    //     name: 'Customer',
    //     exact: true,
    //     pageTitle: "Customer",
    //     component: Customer
    // },
    // {
    //     path: "/customer-add",
    //     name: 'AddCustomer',
    //     exact: true,
    //     pageTitle: "AddCustomer",
    //     component: AddCustomer
    // },
    // {
    //     path: "/customer-list",
    //     name: 'CustomerList',
    //     exact: true,
    //     pageTitle: "CustomerList",
    //     component: CustomerList
    // },
    // {
    //     path: "/customer-update",
    //     name: 'UpdateCustomer',
    //     exact: true,
    //     pageTitle: "UpdateCustomer",
    //     component: UpdateCustomer
    // },
    // {
    //     path: "/branch",
    //     name: 'Branch',
    //     exact: true,
    //     pageTitle: "Branch",
    //     component: Branch
    // },
    // {
    //     path: "/branch-list",
    //     name: 'BranchList',
    //     exact: true,
    //     pageTitle: "BranchList",
    //     component: BranchList
    // },
    // {
    //     path: "/branch-add",
    //     name: 'BranchAdd',
    //     exact: true,
    //     pageTitle: "BranchAdd",
    //     component: BranchAdd
    // },
    // {
    //     path: "/branch-update",
    //     name: 'BranchUpdate',
    //     exact: true,
    //     pageTitle: "BranchUpdate",
    //     component: BranchUpdate
    // },

    // {
    //     path: "/category",
    //     name: 'Category',
    //     exact: true,
    //     pageTitle: "Category",
    //     component: Category
    // },
    // {
    //     path: "/category-list",
    //     name: 'CategoryList',
    //     exact: true,
    //     pageTitle: "CategoryList",
    //     component: CategoryList
    // },
    // {
    //     path: "/category-add",
    //     name: 'CategoryAdd',
    //     exact: true,
    //     pageTitle: "CategoryAdd",
    //     component: CategoryAdd
    // },
    // {
    //     path: "/category-update",
    //     name: 'CategoryUpdate',
    //     exact: true,
    //     pageTitle: "CategoryUpdate",
    //     component: CategoryUpdate
    // },

    // {
    //     path: "/counter",
    //     name: 'Counter',
    //     exact: true,
    //     pageTitle: "Counter",
    //     component: Counter
    // },
    // {
    //     path: "/counter-list",
    //     name: 'CounterList',
    //     exact: true,
    //     pageTitle: "CounterList",
    //     component: CounterList
    // },
    // {
    //     path: "/counter-add",
    //     name: 'CounterAdd',
    //     exact: true,
    //     pageTitle: "CounterAdd",
    //     component: CounterAdd
    // },
    // {
    //     path: "/counter-update",
    //     name: 'CounterUpdate',
    //     exact: true,
    //     pageTitle: "CounterUpdate",
    //     component: CounterUpdate
    // },

    {
        path: "/login1",
        name: 'login',
        exact: true,
        pageTitle: "Tables",
        component: <Login />
    },
    {
        path: "/signup",
        name: 'signup',
        exact: true,
        pageTitle: "Tables",
        component: <Signup />
    },
    {
        path: "/forgotpassword",
        name: 'forgotpassword',
        exact: true,
        pageTitle: "Tables",
        component: <ForgotPassword />
    },
    {
        path: "/maintenance",
        name: 'maintenance',
        exact: true,
        pageTitle: "Maintenance",
        component: <Maintenance />
    },
    {
        path: "/notfound",
        name: 'notfound',
        exact: true,
        pageTitle: "Tables",
        component: <NotFound />
    },
    {
        path: "/region-management",
        name: 'Region Management',
        exact: true,
        pageTitle: "Region Management",
        component: <RegionManagement />
    },
    {
        path: "/branch-management/users",
        name: 'Users',
        exact: true,
        pageTitle: "Users",
        component: <Users />
    },
    {
        path: "/user-management/main-users",
        name: 'Main Users',
        exact: true,
        pageTitle: "Main Users",
        component: <MainUser />
    },

    {
        path: "/branch-landing",
        name: 'Branch Landing Page',
        exact: true,
        pageTitle: "Branch Landing Page",
        component: <BranchLandingPage />
    },
    {
        path: "/branch-management/counters",
        name: 'Counters Management',
        exact: true,
        pageTitle: "Counters Management",
        component: <CountersManagement />,
    },
    {
        path: "/branch-management/categories",
        name: 'Categories Management',
        exact: true,
        pageTitle: "Categories Management",
        component: <CategoriesManagement />,
    },
    {
        path: "/branch-management/workflow",
        name: 'Workflow Management',
        exact: true,
        pageTitle: "Workflow Management",
        component: <Workflow />,
    },
    {
        path: "/branch-management/main-displays",
        name: 'Main Display',
        exact: true,
        pageTitle: "Main Display",
        component: <MainDisplayManagement />,
    },
    {
        path: "/branch-management/sms-group",
        name: 'SMS Group',
        exact: true,
        pageTitle: "SMS Group",
        component: <SmsGroup />
    },
    {
        path: "/master-categories",
        name: 'Master Categories',
        exact: true,
        pageTitle: "Master Categories",
        component: <CategoriesMaster />,
    },
    {
        path: "/category-management/sub-categories",
        name: 'Sub Categories',
        exact: true,
        pageTitle: "Sub Categories",
        component: <SubCategories />,
    },
    {
        path: "/user-management/user-roles",
        name: 'User Roles Management',
        exact: true,
        pageTitle: "User Roles Management",
        component: <UserRolesManagement />,
    },
    {
        path: "/user-profile",
        name: 'User Profile',
        exact: true,
        pageTitle: "User Profile",
        component: <UserProfile />,
    },
    {
        path: "/user-update",
        name: 'EditMainUser',
        exact: true,
        pageTitle: "EditMainUser",
        component: <EditMainUser />
    },
    {
        path: "/",
        name: 'LoginPage',
        exact: true,
        pageTitle: "LoginPage",
        component: <LoginPage />
    },
    {
        path: "/register",
        name: 'Register',
        exact: true,
        pageTitle: "Register",
        component: <Register />
    },
    {
        path: "/not-found",
        name: 'Not Found',
        exact: true,
        pageTitle: "Not Found",
        component: <PageNotFound />,
    },
    {
        path: "/alert/sms-template",
        name: 'SMS Template',
        exact: true,
        pageTitle: "SMS Template",
        component: <SmsTemplate />,
    },
    {
        path: "/alert/notification-template",
        name: 'Notification Template',
        exact: true,
        pageTitle: "Notification Template",
        component: <NotificationTemplate />
    },
    {
        path: "/branch-summary",
        name: 'Branch Summary',
        exact: true,
        pageTitle: "Branch Summary",
        component: <BranchSummary />,
    },
    {
        path: "/audit",
        name: 'Audit Log',
        exact: true,
        pageTitle: "Audit Log",
        component: <Audit/>
    },
    {
        path: "/branch-dashboard",
        name: 'Branch Dashboard',
        exact: true,
        pageTitle: "Branch Dashboard",
        component: <BranchDashboard />,
    },
    {
        path: "/branch-dashboard/realtime-users-by-skill",
        name: 'User Skills Page',
        exact: true,
        pageTitle: "User Skills Page",
        component: <RealtimeUsersBySkill />,
    },
    {
        path: "/matter-code",
        name: 'Mattercode',
        exact: true,
        pageTitle: "Mattercode",
        component: <MattercodeManagement />,
    },
    {
        path: "/kiosks-templates",
        name: 'Design Page',
        exact: true,
        pageTitle: "Design Page",
        component: <KiosksTemplates />,
    },
    {
        path: "/kiosks-management",
        name: 'Kiosks Page',
        exact: true,
        pageTitle: "Kiosks Page",
        component: <KiosksManagement />,
    },
    {
        path: "/design-kiosks-template",
        name: 'Design Kiosks Template',
        exact: true,
        pageTitle: "Design Kiosks Template",
        component: <DesignKiosksTemplate />,
    },
    {
        path: "/branch-categories-group",
        name: 'BranchCategoriesGroup Page',
        exact: true,
        pageTitle: "BranchCategoriesGroup Page",
        component: <BranchCategoriesGroup />,
    },
    {
        path: "/analytics",
        name: 'Analytics Page',
        exact: true,
        pageTitle: "Analytics Page",
        component: <AnalyticsPage />,
    },
    {
        path: "/analytics/category-analytics",
        name: 'Category Analytics Page',
        exact: true,
        pageTitle: "Category Analytics Page",
        component: <CategoryAnalytics />,
    },
    {
        path: "/analytics/audiance-analytics",
        name: 'Audiance Analytics Page',
        exact: true,
        pageTitle: "Audiance Analytics Page",
        component: <AudianceAnalytics />,
    },
    {
        path: "/category-assign-page",
        name: 'Category Assign Page',
        exact: true,
        pageTitle: "Category Assign Page",
        component: <CategoryAssignPage />,
    },
    {
        path: "/react-konva",
        name: 'React Konva',
        exact: true,
        pageTitle: "React Konva'",
        component: <ReactKonva />,
    },
    {
        path: "/branch-tables-page",
        name: 'Branch Tables Page',
        exact: true,
        pageTitle: "Branch Tables Page",
        component: <BranchTables />,
    },
    {
        path: "/tsu-profile",
        name: 'Tsu Profile Page',
        exact: true,
        pageTitle: "Tsu Profile Page",
        component: <TsuProfile />,
    },
    {
        path: "/notification-manager",
        name: 'Notification Manager',
        exact: true,
        pageTitle: "Notification Manager",
        component: <NotificationManager />,
    },
    {
        path: "/home",
        name: 'Home Page',
        exact: true,
        pageTitle: "Home Page",
        component: <HomePage />
    },
    {
        path: "/reports/real-time",
        name: 'Real-time Report',
        exact: true,
        pageTitle: "Real-time Report",
        component: <Report />
    },
    {
        path: "/reports/queue",
        name: 'Queue Report',
        exact: true,
        pageTitle: "Queue Report",
        component: <Report />
    },
    {
        path: "/reports/token-wise",
        name: 'Token Wise Report',
        exact: true,
        pageTitle: "Token Wise Report",
        component: <Report />
    },
    {
        path: "/reports/skill-wise",
        name: 'Skill Wise Report',
        exact: true,
        pageTitle: "Skill Wise Report",
        component: <Report />
    },
    {
        path: "/reports/agent-wise",
        name: 'Agent Wise Report',
        exact: true,
        pageTitle: "Agent Wise Report",
        component: <Report />
    },
    {
        path: "/reports/branch",
        name: 'Branch Report',
        exact: true,
        pageTitle: "Branch Report",
        component: <Report />
    },
    {
        path: "/reports/category",
        name: 'Category Report',
        exact: true,
        pageTitle: "Category Report",
        component: <Report />
    },
    {
        path: "/no-skill",
        name: 'No Skill',
        exact: true,
        pageTitle: "No Skill",
        component: <NoSkill />
    },
    {
        path: "/appointments/booking",
        name: 'Appointment Booking',
        exact: true,
        pageTitle: "Appointment Booking",
        component: <AppointmentBookingPage />
    },
    {
        path: "/appointments/user-list",
        name: 'Appointment User List',
        exact: true,
        pageTitle: "Appointment User List",
        component: <AppointmentUserListPage />
    },
    {
        path: "/appointments/user-list/user-activity",
        name: 'Appointment User Activity',
        exact: true,
        pageTitle: "Appointment User Activity",
        component: <AppointmentUserActivityPage />
    }


];

export default Routes;

