import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import MapPicker from 'react-google-map-picker';
import Button from 'react-bootstrap-button-loader';

const DefaultZoom = 12;


// used for add and edit
const AddBranchModal = ({ addBranchModal, setAddBranchModal, getAll, selectedProvince, selectedDistrict, modalType, branchData, getBranchDetails, getManagerProfileImage, allowClone }) => {

    let clone = allowClone;

    const branchNameRef = useRef(null);
    const branchCodeRef = useRef(null);
    const branchAddressRef = useRef(null);
    const branchContactNoRef = useRef(null);
    const branchEmailRef = useRef(null);
    const seatingCapacityRef = useRef(null);
    const openTimeRef = useRef(null);
    const closeTimeRef = useRef(null);
    const managerNameRef = useRef(null);
    const managerContactNoRef = useRef(null);
    const managerEmailRef = useRef(null);
    const latitudeRef = useRef(null);
    const longitudeRef = useRef(null);

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const [buttonLoader, setButtonLoader] = useState(false);

    const [branchId, setBranchId] = useState('');
    const [branchName, setBranchName] = useState('');
    const [branchCode, setBranchCode] = useState('');
    const [branchAddress, setBranchAddress] = useState('');
    const [branchContactNo, setbranchContactNo] = useState('');
    const [branchEmail, setBranchEmail] = useState('');
    const [seatingCapacity, setSeatingCapacity] = useState('');
    const [openTime, setOpenTime] = useState('');
    const [closeTime, setcloseTime] = useState('');
    const [managerName, setManagerName] = useState('');
    const [managerProfile, setManagerProfile] = useState();
    const [managerContactNo, setManagerContactNo] = useState('');
    const [managerEmail, setManagerEmail] = useState('');
    const [allowedClone, setAllowedClone] = useState(false)

    const [latitude, setLatitude] = useState(6.8820465439362515);
    const [longitude, setLongitude] = useState(79.86331874562012);

    const [defaultLocation, setDefaultLocation] = useState({ lat: latitude, lng: longitude });
    const [zoom, setZoom] = useState(DefaultZoom);

    const [smsUrl, setSmsUrl] = useState('');
    const [smsAlias, setAlias] = useState('');
    const [smsUsername, setUsername] = useState('');
    const [smsPassword, setPassword] = useState('');
    const [showSmsPassword, setShowSmsPassword] = useState(false)
    const [smsGateway, setGateway] = useState('');


    // when change location, set default location - useEffect
    useEffect(() => {
        setDefaultLocation({ lat: parseFloat(latitude), lng: parseFloat(longitude) })
    }, [latitude, longitude])

    // is update - useEffect
    useEffect(() => {
        if (addBranchModal) {
            if (modalType === 1) {
                setBranchId(branchData.branch_id);
                setBranchName(branchData.branch_name);
                setBranchCode(branchData.feature_branch_code);
                setBranchAddress(branchData.feature_branch_address);
                setbranchContactNo(branchData.feature_branch_contact);
                setBranchEmail(branchData.feature_branch_email);
                setSeatingCapacity(branchData.feature_seat_capacity);
                setOpenTime(branchData.feature_open);
                setcloseTime(branchData.feature_close);
                setManagerName(branchData.feature_manager_name);
                setManagerContactNo(branchData.feature_manager_mobile);
                setManagerEmail(branchData.feature_manager_email);
                setLatitude(branchData.feature_latitude);
                setLongitude(branchData.feature_longitude);

                setSmsUrl(branchData.feature_sms_url);
                setAlias(branchData.feature_sms_alias);
                setUsername(branchData.feature_sms_username);
                // setPassword(branchData.feature_sms_password);
                setGateway(branchData.feature_sms_gateway);
            }
        }

    }, [addBranchModal])

    useEffect(() => {
        if (modalType !== 0) {
            setAllowedClone(clone)
        }
    }, [modalType, addBranchModal])


    // addNewBranch - function
    const addNewBranch = () => {
        console.log('addNewBranch function start');
        setButtonLoader(true);

        // validate
        let isValidate = validate();

        if (isValidate) {
            axios({
                url: `${baseUrl}/branch/add/branch/parent/${selectedDistrict.id}`,
                method: 'POST',
                headers: {
                    Authorization: token
                },
                data: {
                    name: branchName,
                    allowCloning: allowedClone,
                    branch: {
                        'branch-code': branchCode,
                        'branch-address': branchAddress,
                        'branch-contact': branchContactNo,
                        'branch-email': branchEmail,
                        'seat-capacity': seatingCapacity,
                        'open': openTime,
                        'close': closeTime,
                        'latitude': latitude,
                        'longitude': longitude,
                        'manager-name': managerName,
                        'manager-mobile': managerContactNo,
                        'manager-email': managerEmail,
                    },
                    response: ['id', 'name']
                }
            }).then((res) => {

                let data = res.data.data;
                console.log(data);

                // add manager profile image
                if (managerProfile !== undefined)
                    addManagerProfileImage(data.id);

                setButtonLoader(false);

                closeModal();
                toast.remove();
                toast.success('Successfully added', {
                    position: 'bottom-center',
                });

                getAll(3, selectedProvince.id);
                getAll(4, selectedDistrict.id);

            }).catch((error) => {
                console.log(error);
                setButtonLoader(false);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }
            })
        }

    }

    // addManagerProfileImage - function
    const addManagerProfileImage = (branchId) => {
        let formData = new FormData();
        formData.append('branchId', branchId);
        formData.append('image', managerProfile);

        axios({
            method: 'POST',
            url: `${baseUrl}/branch/add-update/manager-pro-pic/branch/${branchId}`,
            headers: {
                Authorization: token
            },
            data: formData
        }).then((res) => {
            console.log('addManagerProfileImage success');

            if (modalType === 1) {
                getManagerProfileImage();
            }

        }).catch((error) => {
            console.log(error);
        })
    }

    // updateNewBranch - function
    const updateBranch = () => {
        console.log('updateBranch function start');
        setButtonLoader(true);

        // validate
        let isValidate = validate();

        if (isValidate) {
            axios({
                url: `${baseUrl}/branch/update/branch`,
                method: 'POST',
                headers: {
                    Authorization: token
                },
                data: {
                    id: branchData.branch_id,
                    name: branchName,
                    allowCloning: allowedClone,
                    branchFeature: {
                        'branch-code': branchCode,
                        'branch-address': branchAddress,
                        'branch-contact': branchContactNo,
                        'branch-email': branchEmail,
                        'seat-capacity': seatingCapacity,
                        'open': openTime,
                        'close': closeTime,
                        'latitude': latitude,
                        'longitude': longitude,
                        'manager-name': managerName,
                        'manager-mobile': managerContactNo,
                        'manager-email': managerEmail,
                        'sms-alias': smsAlias,
                        'sms-gateway': smsGateway,
                        'sms-password': smsPassword,
                        'sms-url': smsUrl,
                        'sms-username': smsUsername,
                    }
                }
            }).then((res) => {

                // upadate manager profile image
                if (managerProfile !== undefined)
                    addManagerProfileImage(branchData.branch_id);

                console.log('>> updateBranch success');
                setButtonLoader(false);

                closeModal();
                toast.remove();
                toast.success('Successfully updated', {
                    position: 'bottom-center',
                });

                getBranchDetails();

            }).catch((error) => {
                console.log('>> updateBranch error');
                console.log(error);
                setButtonLoader(false);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }
            })
        }
    }

    // validate - function
    const validate = () => {
        if (branchName.length === 0) {
            branchNameRef.current && branchNameRef.current.focus();
            toast.remove();
            toast.error('Branch name can not be empty', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            return false;
        } else if (branchCode.length === 0) {
            branchCodeRef.current && branchCodeRef.current.focus();
            toast.remove();
            toast.error('Branch code can not be empty', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            return false;
        } else if (branchAddress.length === 0) {
            branchAddressRef.current && branchAddressRef.current.focus();
            toast.remove();
            toast.error('Branch address can not be empty', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            return false;
        } else if (branchContactNo.length === 0) {
            branchContactNoRef.current && branchContactNoRef.current.focus();
            toast.remove();
            toast.error('Branch contact no can not be empty', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            return false;
        } else if (branchEmail.length === 0) {
            branchEmailRef.current && branchEmailRef.current.focus();
            toast.remove();
            toast.error('Branch email can not be empty', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            return false;
        } else if (seatingCapacity.length === 0) {
            seatingCapacityRef.current && seatingCapacityRef.current.focus();
            toast.remove();
            toast.error('Seating capacity can not be empty', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            return false;
        } else if (openTime.length === 0) {
            openTimeRef.current && openTimeRef.current.focus();
            toast.remove();
            toast.error('Open time can not be empty', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            return false;
        } else if (closeTime.length === 0) {
            closeTimeRef.current && closeTimeRef.current.focus();
            toast.remove();
            toast.error('Close time can not be empty', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            return false;
        } else if (latitude.length === 0) {
            latitudeRef.current && latitudeRef.current.focus();
            toast.remove();
            toast.error('Latitude can not be empty', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            return false;
        } else if (longitude.length === 0) {
            longitudeRef.current && longitudeRef.current.focus();
            toast.remove();
            toast.error('Longitude can not be empty', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            return false;
        } else if (managerName.length === 0) {
            managerNameRef.current && managerNameRef.current.focus();
            toast.remove();
            toast.error('Manager Name can not be empty', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            return false;
        } else if (managerContactNo.length === 0) {
            managerContactNoRef.current && managerContactNoRef.current.focus();
            toast.remove();
            toast.error('Manager contact no can not be empty', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            return false;
        } else if (managerEmail.length === 0) {
            managerEmailRef.current && managerEmailRef.current.focus();
            toast.remove();
            toast.error('Manager email can not be empty', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            return false;
        } else {
            return true;
        }

    }

    // map location change handle - function
    function handleChangeLocation(lat, lng) {
        setLatitude(lat);
        setLongitude(lng);
    }

    // map zoom change handle - function
    function handleChangeZoom(newZoom) {
        setZoom(newZoom);
    }

    // close modal - function
    function closeModal() {
        setBranchName('');
        setBranchCode('');
        setBranchAddress('');
        setbranchContactNo('');
        setBranchEmail('');
        setSeatingCapacity('');
        setOpenTime('');
        setcloseTime('');
        setManagerName('');
        setManagerContactNo('');
        setManagerEmail('');

        setSmsUrl('');
        setAlias('');
        setUsername('');
        setPassword('');
        setGateway('');

        setButtonLoader(false);
        setAddBranchModal(false);
    }

    return (
        <>
            <div><Toaster /></div>
            {addBranchModal ?
                <div className={`modal fade bd-example-modal-lg d-block show modal-container`} id="exampleModal">
                    <div className="modal-dialog modal-lg add-branch-modal" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    {modalType === 0 ? "Add Branch" : 'Edit Branch Details'}
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        closeModal()
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body lg-modal-body-scroll">

                                <div className="container">

                                    <div className="row clearfix">
                                        <div className="col-12">
                                            <div className="input-group input-group-sm mb-3 modal-input-group modal-body-section-title">
                                                <p>Branch Details</p>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        modalType === 1 ?
                                            <div className="row clearfix">

                                                <div className="col-6">
                                                    <div className="input-group input-group-sm mb-3 modal-input-group">
                                                        <p className='input-field-side-text'>Branch Id : </p>
                                                        <input type="text" className="form-control" aria-label="Small"
                                                            aria-describedby="inputGroup-sizing-sm"
                                                            disabled={true}
                                                            value={branchId}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    }

                                    <div className="row clearfix">

                                        <div className="col-6">
                                            <div className="input-group input-group-sm mb-3 modal-input-group">
                                                <p className='input-field-side-text'>Branch Name : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    value={branchName}
                                                    onChange={(e) => {
                                                        setBranchName(e.target.value);
                                                    }}
                                                    ref={branchNameRef}
                                                />
                                            </div>
                                        </div>

                                        {
                                            modalType === 0 ? (
                                                <div className="col-6">
                                                    <div className="input-group input-group-sm mb-3 modal-input-group">
                                                        <p className='input-field-side-text display-inline-block float-left'>Allow Clone : </p>
                                                        <div className="display-inline-block">
                                                            <label className="switch">
                                                                <input type="checkbox"
                                                                    checked={allowedClone ? true : false}
                                                                    onChange={(e) => {
                                                                        setAllowedClone(e.target.checked);
                                                                    }} />
                                                                <span className="slider"></span>
                                                            </label>
                                                        </div>
                                                        <span className='text-info'>
                                                            <i className='fa fa-info-circle mr-1'></i>
                                                            <small style={{ fontSize: "80%" }}>Click on the toggle if the branch will be used to clone later.</small>
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="col-6">
                                                    <div className="input-group input-group-sm mb-3 modal-input-group">
                                                        <p className='input-field-side-text display-inline-block float-left'>Allow Clone : </p>
                                                        <div className="display-inline-block">
                                                            <label className="switch">
                                                                <input type="checkbox"
                                                                    checked={allowedClone}
                                                                    onChange={(e) => {
                                                                        {
                                                                            setAllowedClone(e.target.checked);
                                                                        }
                                                                    }} />
                                                                <span className="slider"></span>
                                                            </label>
                                                        </div>
                                                        <span className='text-info'>
                                                            <i className='fa fa-info-circle mr-1'></i>
                                                            <small style={{ fontSize: "80%" }}>Click on the toggle if the branch will be used to clone later.</small>
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        }



                                        <div className="col-6">
                                            <div className="input-group input-group-sm mb-3 modal-input-group">
                                                <p className='input-field-side-text'>Branch Code : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    value={branchCode}
                                                    onChange={(e) => {
                                                        setBranchCode(e.target.value);
                                                    }}
                                                    ref={branchCodeRef}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="input-group input-group-sm mb-3 modal-input-group">
                                                <p className='input-field-side-text'>Branch Address : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    value={branchAddress}
                                                    onChange={(e) => {
                                                        setBranchAddress(e.target.value);
                                                    }}
                                                    ref={branchAddressRef}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="input-group input-group-sm mb-3 modal-input-group">
                                                <p className='input-field-side-text'>Branch Contact No : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    value={branchContactNo}
                                                    onChange={(e) => {
                                                        setbranchContactNo(e.target.value);
                                                    }}
                                                    ref={branchContactNoRef}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="input-group input-group-sm mb-3 modal-input-group">
                                                <p className='input-field-side-text'>Branch Email : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    value={branchEmail}
                                                    onChange={(e) => {
                                                        setBranchEmail(e.target.value);
                                                    }}
                                                    ref={branchEmailRef}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="input-group input-group-sm mb-3 modal-input-group">
                                                <p className='input-field-side-text'>Seating Capacity : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    value={seatingCapacity}
                                                    onChange={(e) => {
                                                        setSeatingCapacity(e.target.value);
                                                    }}
                                                    ref={seatingCapacityRef}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="input-group input-group-sm mb-3 modal-input-group">
                                                <p className='input-field-side-text'>Open Time : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    value={openTime}
                                                    onChange={(e) => {
                                                        setOpenTime(e.target.value);
                                                    }}
                                                    ref={openTimeRef}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="input-group input-group-sm mb-3 modal-input-group">
                                                <p className='input-field-side-text'>Close Time : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    value={closeTime}
                                                    onChange={(e) => {
                                                        setcloseTime(e.target.value);
                                                    }}
                                                    ref={closeTimeRef}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="input-group input-group-sm mb-3 modal-input-group">
                                                <p className='input-field-side-text'>Latitute : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    value={latitude}
                                                    onChange={(e) => {
                                                        setLatitude(e.target.value);
                                                    }}
                                                    ref={latitudeRef}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="input-group input-group-sm mb-3 modal-input-group">
                                                <p className='input-field-side-text'>Longitute : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    value={longitude}
                                                    onChange={(e) => {
                                                        setLongitude(e.target.value);
                                                    }}
                                                    ref={longitudeRef}
                                                />
                                            </div>
                                        </div>

                                        {
                                            modalType === 0 ?
                                                <div className="col-12">
                                                    <div className='map-container mb-3'>
                                                        <MapPicker
                                                            defaultLocation={defaultLocation}
                                                            zoom={zoom}
                                                            style={{ height: '250px' }}
                                                            onChangeLocation={handleChangeLocation}
                                                            onChangeZoom={handleChangeZoom}
                                                            apiKey='AIzaSyDqMfd3HWb4jkAqxyJgprzAzxnuele0tKc' />
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }
                                    </div>

                                    <div className="row clearfix">
                                        <div className="col-12">
                                            <div className="input-group input-group-sm mt-4 mb-3 modal-input-group modal-body-section-title">
                                                <p>Branch Manager Details</p>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="input-group input-group-sm mb-3 modal-input-group">
                                                <p className='input-field-side-text'>Manager Name : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    value={managerName}
                                                    onChange={(e) => {
                                                        setManagerName(e.target.value);
                                                    }}
                                                    ref={managerNameRef}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="input-group input-group-sm mb-3 modal-input-group">
                                                <p className='input-field-side-text'>Manager Profile : </p>
                                                <input type="file" accept=".png, .jpg, .jpeg" className="form-control img-input-field" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    onChange={(e) => {
                                                        setManagerProfile(e.target.files[0]);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="input-group input-group-sm mb-3 modal-input-group">
                                                <p className='input-field-side-text'>Manager Contact No : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    value={managerContactNo}
                                                    onChange={(e) => {
                                                        setManagerContactNo(e.target.value);
                                                    }}
                                                    ref={managerContactNoRef}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="input-group input-group-sm mb-3 modal-input-group">
                                                <p className='input-field-side-text'>Manager Email : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    value={managerEmail}
                                                    onChange={(e) => {
                                                        setManagerEmail(e.target.value);
                                                    }}
                                                    ref={managerEmailRef}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    {modalType === 1 ? (
                                        <div className="row clearfix">
                                            <div className="col-12">
                                                <div className="input-group input-group-sm mt-4 mb-3 modal-input-group modal-body-section-title">
                                                    <p>SMS Gateway Details</p>
                                                </div>
                                            </div>

                                            <div className="col-6">
                                                <div className="input-group input-group-sm mb-3 modal-input-group">
                                                    <p className='input-field-side-text'>URL : </p>
                                                    <input type="text" className="form-control" aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        value={smsUrl}
                                                        onChange={(e) => {
                                                            setSmsUrl(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-6">
                                                <div className="input-group input-group-sm mb-3 modal-input-group">
                                                    <p className='input-field-side-text'>Alias : </p>
                                                    <input type="text" className="form-control" aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        value={smsAlias}
                                                        onChange={(e) => {
                                                            setAlias(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-6">
                                                <div className="input-group input-group-sm mb-3 modal-input-group">
                                                    <p className='input-field-side-text'>Username : </p>
                                                    <input type="text" className="form-control" aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        autoComplete="off"
                                                        value={smsUsername}
                                                        onChange={(e) => {
                                                            setUsername(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-6">
                                                <div className="input-group input-group-sm mb-3 modal-input-group"
                                                    autoComplete="new-password"
                                                >
                                                    <p className='input-field-side-text'>Password : </p>
                                                    <input type={showSmsPassword ? 'text' : 'password'} className="form-control" aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        autoComplete="new-password"
                                                        value={smsPassword}
                                                        onChange={(e) => {
                                                            setPassword(e.target.value);
                                                        }}
                                                    />
                                                    <span className='mt-1 ml-1' style={{ cursor: 'pointer' }}
                                                        onClick={() => setShowSmsPassword(!showSmsPassword)}
                                                    >
                                                        {
                                                            showSmsPassword ? (
                                                                <i className='fa fa-eye-slash'></i>
                                                            ) : (
                                                                <i className='fa fa-eye'></i>
                                                            )
                                                        }

                                                    </span>
                                                </div>
                                            </div>

                                            <div className="col-6">
                                                <div className="input-group input-group-sm mb-3 modal-input-group">
                                                    <p className='input-field-side-text'>Gateway : </p>
                                                    <select className="custom-select modal-select-field" id="inputGroupSelect01"
                                                        value={smsGateway}
                                                        onChange={(e) => {
                                                            setGateway(e.target.value);
                                                        }}
                                                    >
                                                        <option hidden>Choose...</option>
                                                        <option value='hnb'>H1</option>
                                                        <option value='smswebservice'>SMS Web Service</option>
                                                        <option value='tstpl'>TSTPL</option>
                                                        <option value='mobitel'>M1</option>
                                                        <option value='combank'>C1</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                    ) : null}
                                </div>



                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal"
                                    onClick={() => {
                                        closeModal()
                                    }}>Cancel</button>

                                {
                                    modalType === 0 ?
                                        <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                            onClick={() => {
                                                addNewBranch();
                                            }}
                                        >Add Branch</Button>
                                        :
                                        <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                            onClick={() => {
                                                updateBranch();
                                            }}
                                        >Update Branch</Button>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                : ''}
        </>
    )
}

export default AddBranchModal;