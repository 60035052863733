import React, {useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';
import axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';


import MUIDataTable from 'mui-datatables';
import DatePicker from "react-datepicker";
import moment from 'moment';
import CircularProgress from "@mui/material/CircularProgress";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import DialogTitle from "@mui/material/DialogTitle";


const Audit = () => {

    const location = useLocation();
    const [count, setCount] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState(null);
    const [search, setSearch] = useState(null);
    const [bid, setBid] = useState(0);
    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [body, setBody] = useState('');
    const [sig, setSig] = useState('');
    const [status, setStatus] = useState('');
    const [res, setRes] = useState('');
    const [url, setUrl] = useState('');
    const [open, setOpen] = useState(false);
    const [allBranchesList, setAllBranchesList] = useState([]);
    const [list, setList] = useState([]);

    const timeoutRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const columns = [
        {
            label: "Date Time",
            name: 'createdAt',
            options: {
                sort: true,
                filter: false,
                customBodyRender: (value) => (
                    <p style={{margin: 0, width: '100px', display: 'inline-block'}}>{value}</p>
                )
            }
        },
        {
            label: "Description",
            name: 'description',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'First Name',
            name: 'firstName',
            options: {
                sort: false,
                filter: false,
            }
        },
        {
            label: "Last Name",
            name: 'lastName',
            options: {
                sort: false,
                filter: false,
            }
        },
        {
            label: "Branch",
            name: 'branchName',
            options: {
                sort: false,
                filter: false,
            }
        },
        {
            label: "Status",
            name: 'httpStatusCode',
            options: {
                sort: false,
                filter: false,
            }
        },
        {
            label: 'Action',
            name: 'id',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    let item = list.find((item, i) => i === dataIndex);
                    return (
                        <>
                            <UncontrolledDropdown>
                                <DropdownToggle type="button" className={`btn btn-sm btn-default table-action-btn`}
                                                data-toggle="tooltip"
                                                data-placement="top"
                                ><i className="fa   ellipsis-v fa-ellipsis-v    " aria-hidden="true"/>
                                </DropdownToggle>

                                <DropdownMenu
                                    positionFixed={true}
                                    className="dropdown-menu-xs">
                                    <ul className="link-list-opt">
                                        <li>
                                            <DropdownItem
                                                tag="a"
                                                href="javascript:void(0);"
                                                onClick={() => {
                                                    getById(item.id);
                                                }}
                                            >
                                                <i className="fa fa-code mr-3" aria-hidden="true"/>
                                                <span>Stats</span>
                                            </DropdownItem>
                                        </li>

                                    </ul>
                                </DropdownMenu>
                            </UncontrolledDropdown>

                        </>
                    );
                }
            },
        },
    ];

    const options = {
        tableBodyHeight: '52vh',
        filterType: 'dropdown',
        filter: false,
        responsive: 'vertical',
        serverSide: true,
        count: count,
        download: false,
        jumpToPage: true,
        selectableRows: 'none',
        print: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        },
        onTableChange: (action, tableState) => {

            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want

            switch (action) {
                case 'changePage':
                    setPage(tableState.page);
                    break;
                case 'sort':
                    setSort(tableState.sortOrder);
                    break;
                case 'changeRowsPerPage':
                    setRowsPerPage(tableState.rowsPerPage);
                    break;
                case 'search':
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }
                    timeoutRef.current = setTimeout(() =>
                        setSearch(tableState.searchText), 1300);
                    break;
                default:
                    break;
            }
        },
    };

    // set today date
    useEffect(() => {
        setDateFrom(moment(startDate).format("YYYY-MM-DD"));
        setDateTo(moment(endDate).format("YYYY-MM-DD"));
    }, [Audit]);

    // call start up functions - useEffect
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            getAllRegion();
        }
    }, [Audit])
    // call start up functions - useEffect
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            get();
        }
    }, [dateFrom, dateTo, bid, page, sort, page, search, rowsPerPage])

    // get all countries - function
    const getAllRegion = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/branch/get-branches/customer/${userDetails.cid}`,
            headers: {
                Authorization: localStorage.getItem('TS_GEN_TOKEN')
            },
        }).then((res) => {

            const data = res.data.data;
            setAllBranchesList(data);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })

    };

    const get = () => {
        if (dateFrom === '') return;
        setIsLoading(true);
        axios({
            method: 'POST',
            url: bid == 0 ? `${baseUrl}/audit/all/start/${dateFrom}/end/${dateTo}` : `${baseUrl}/audit/all/start/${dateFrom}/end/${dateTo}/branch/${bid}`,
            headers: {
                Authorization: token
            },
            data: {
                page: page,
                size: rowsPerPage,
                sort: sort,
                searchText: search
            }
        }).then((res) => {

            setIsLoading(false)
            const data = res.data.data;
            setList(data.content);
            setCount(data.totalElements);

        }).catch((error) => {
            console.log(error);

            setIsLoading(false)
            if (error.response !== undefined) {
                toast.remove();
                toast.error("No data", {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })

    };
    const getById = (id) => {
        setIsLoading(true);
        axios({
            method: 'GET',
            url: `${baseUrl}/audit/get/${id}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            setIsLoading(false);
            const data = res.data.data;
            setBody(data.body);
            setSig(data.signature);
            setRes(data.responseData);
            setStatus(data.httpStatusCode);
            setUrl(data.url);
            setOpen(true)
        }).catch((error) => {
            console.log(error);

            setIsLoading(false);
            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })

    };

    const handleClose = () => {
        setOpen(false);
        setBody('')
        setSig('')
        setStatus('')
        setRes('')
        setUrl('')
    }


    return (
        <>

            <div className="container-fluid">

                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h2>Audit Log</h2>

                        </div>
                    </div>

                </div>


                {/* date selector */}
                {location.pathname.includes('/real-time') ?
                    null
                    : (
                        <div className="row clearfix">
                            <div className="col-lg-12 text-right">

                                <div className='card'>
                                    <div className='body text-right'>

                                        <div className='d-flex row horizontal-align-center-flex text-right'>
                                            <select
                                                className="custom-select modal-select-field mr-1 select-branch-dropdown"
                                                id="inputGroupSelect01"
                                                value={bid}
                                                onChange={(e) => {
                                                    setBid(e.target.value)
                                                }}
                                            >
                                                <option value={0} defaultValue>All</option>
                                                {allBranchesList.map((item, index) =>
                                                    <option value={item.id} key={index}>
                                                        {item.name}
                                                    </option>
                                                )}

                                            </select>

                                            <div className='col-lg-6 col-md-6'>
                                                <div>

                                                    <div className="input-daterange input-group"
                                                         data-provide="datepicker">

                                                        <DatePicker className="input-sm form-control"
                                                                    selected={startDate}
                                                                    onChange={(date) => {
                                                                        const dateFrom = moment(date).format("YYYY-MM-DD");
                                                                        setStartDate(date)
                                                                        setDateFrom(dateFrom)
                                                                    }}/>


                                                        <span className="input-group-addon range-to">to</span>

                                                        <DatePicker className="input-sm form-control"
                                                                    selected={endDate}
                                                                    onChange={(date) => {
                                                                        const dateTo = moment(date).format("YYYY-MM-DD");
                                                                        setEndDate(date)
                                                                        setDateTo(dateTo)
                                                                    }}/>

                                                    </div>

                                                </div>

                                            </div>


                                        </div>


                                    </div>
                                </div>

                            </div>
                        </div>
                    )}
                <MUIDataTable
                    title={
                        <>
                            {isLoading ?
                                <CircularProgress color="secondary" size={20}/> : <>{count} records.</>}
                        </>
                    }
                    data={list}
                    columns={columns}
                    options={options}
                    className='text-center-table'
                />

                <Dialog
                    open={open}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{url} - {status}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <p className="text-light">
                                {body}
                            </p>
                            <br/>
                            {res}

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className={"secondary"}
                                onClick={handleClose}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        </>
    )
}

export default Audit
