import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Donut } from 'react-dial-knob';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import AddBranchModal from '../ModalImpl/AddBranchModal';

import { connect } from 'react-redux';
import { setUserPermissions } from '../../actions/settingsAction';
import NoOfTicketsChart from '../ChartsImpl/HomePageCharts/NoOfTicketsChart';
import BranchCloneModal from '../ModalImpl/BranchCloneModal';



const BranchLandingPage = ({ userPermissions }) => {

    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    // let branchId = params.get('bid');
    const [branchId, setBranchId] = useState(null);
    let selectedBranch = JSON.parse(localStorage.getItem('selected_branch'));
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [data, setData] = useState({});
    const [managerProfile, setManagerProfile] = useState();

    const [modalType, setmodalType] = useState(); // 0-add, 1-edit
    const [addBranchModal, setAddBranchModal] = useState(false);

    const [queueTypesList, setQueueTypesList] = useState([]);

    // access permissions
    const [userPermissionsList, setUserPermissionsList] = useState([]);
    const [userAccess, setUserAccess] = useState(false);
    const [categoryAccess, setCategoryAccess] = useState(false);
    const [counterAccess, setCounterAccess] = useState(false);
    const [kiosksAccess, setKiosksAccess] = useState(false);
    const [mainDisplayAccess, setMainDisplayAccess] = useState(false);
    const [workFlowAccess, setWorkFlowAccess] = useState(false);
    const [kiosksDesignAccess, setKiosksDesignAccess] = useState(false);
    const [smsGroupAccess, setSmsGroupAccess] = useState(false);
    const [openBranchCloneModal, setOpenBranchCloneModal] = useState(false)

    const [allnotificationAccessibility, setllnotificationAccessibility] = useState('');
    const [refresh, setRefresh] = useState(0);

    const [showChart, setShowChart] = useState(false);
    const [tokenCount, setTokenCount] = useState([]);
    const [xLabel, setXLabel] = useState([]);
    const [issuedTokenCount, setIssuedTokenCount] = useState('');
    const [branchInfo, setBranchInfo] = useState({});

    const [allBranchesList, setAllBranchesList] = useState([]);
    const [branchDropDValue, setBranchDropDValue] = useState('');


    // get all level 4 branches, if user = admin
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            if (userDetails.branchLevel === 1) {

                axios({
                    method: 'GET',
                    url: `${baseUrl}/branch/get-branches/customer/${userDetails.cid}`,
                    headers: {
                        Authorization: localStorage.getItem('TS_GEN_TOKEN')
                    },
                }).then((res) => {

                    const data = res.data.data;
                    setAllBranchesList(data);

                }).catch((error) => {
                    console.log(error);

                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                })

            } else {
                setAllBranchesList([]);
            }
        }

    }, [BranchLandingPage])


    // if navigate directly - useEffect
    useEffect(() => {

        if (token !== null && userDetails !== null) {
            let branchIdX = params.get('bid');
            if (branchIdX === null) {

                if (userDetails.branchLevel === 4) {
                    setBranchId(userDetails.bid)

                    let selectedBranchX = {
                        id: userDetails.bid,
                        BranchName: userDetails.branchName
                    }
                    selectedBranch = selectedBranchX
                    localStorage.setItem('selected_branch', JSON.stringify(selectedBranchX));
                } else {
                    setBranchDropDValue(0);

                    if (allBranchesList.length > 0)
                        handleSelectBranch(allBranchesList[0]);
                }
            } else {
                setBranchId(branchIdX);
            }
        }
    }, [allBranchesList])


    // check permissions - useEffect
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            if (userPermissions.length === 0) {
                getUserPermissions();
            } else {
                setUserPermissionsList(userPermissions);
            }
        }
    }, [BranchLandingPage])


    // if user permissions null, get user permissions
    const getUserPermissions = async () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/user/role-features/get-list/${userDetails.uid}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            let data = res.data.data;

            // set userPermissions for gloabal
            setUserPermissions(data);
            // set userPermissions for component state
            setUserPermissionsList(data);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })

    }

    // handle permissions - useEffect
    useEffect(() => {
        if (userPermissionsList.length > 0) {

            for (let i = 0; i < userPermissionsList.length; i++) {
                const element = userPermissionsList[i];

                if (element.roleFeature === 'branch_user' && element.hasFeature === true) {
                    setUserAccess(true);
                } else if (element.roleFeature === 'branch_category' && element.hasFeature === true) {
                    setCategoryAccess(true);
                } else if (element.roleFeature === 'branch_counter' && element.hasFeature === true) {
                    setCounterAccess(true);
                } else if (element.roleFeature === 'branch_kiosk' && element.hasFeature === true) {
                    setKiosksAccess(true);
                } else if (element.roleFeature === 'branch_main_display' && element.hasFeature === true) {
                    setMainDisplayAccess(true);
                } else if (element.roleFeature === 'branch_wf' && element.hasFeature === true) {
                    setWorkFlowAccess(true);
                } else if (element.roleFeature === 'branch_kiosk_design' && element.hasFeature === true) {
                    setKiosksDesignAccess(true);
                } else if (element.roleFeature === 'branch_sms_group' && element.hasFeature === true) {
                    setSmsGroupAccess(true);
                }
            }

        }
    }, [userPermissionsList])


    // call getBranchDetails and getqueuetypebycustomer - useEffect
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            if (baseUrl.length > 0 && token.length > 0 && branchId != null) {
                getBranchDetails();
                getManagerProfileImage();
                getqueuetypebycustomer();
                getAllConfigsByBranch();
                getHomePageData();
            }
        }
    }, [branchId])


    // getBranchDetails - function
    const getBranchDetails = () => {

        axios({
            method: 'POST',
            url: `${baseUrl}/branch/get/branch/${branchId}`,
            headers: {
                Authorization: token
            },
            data: {
                "response": [
                    "branch.id",
                    "branch.name",
                    "usersCount", "limit.usersCount",
                    "mainDisplayCount", "limit.mainDisplayCount",
                    "kioskCount", "limit.kioskCount",
                    "countersCount", "limit.countersCount",
                    "categoriesCount", "limit.categoriesCount",
                    "workflowCount",
                    "feature.manager-name", "feature.manager-mobile", "feature.manager-email",
                    "feature.branch-address", "feature.branch-contact", "feature.branch-email",
                    "feature.latitude", "feature.longitude",
                    "feature.open", "feature.close", "feature.seat-capacity",
                    "feature.branch-code",
                    "kioskDesignCount", "SMSGroupCount",
                    "feature.sms-alias", "feature.sms-gateway", "feature.sms-password", "feature.sms-url", "feature.sms-username",
                    "branch.cloneableBranch"
                ]
            }
        }).then((res) => {

            const data = res.data.data;
            setData(data);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // getManagerProfileImage - function
    const getManagerProfileImage = () => {
        setManagerProfile(undefined)

        axios({
            method: 'GET',
            url: `${baseUrl}/branch/get/manager-pro-pic/branch/${branchId}`,
            headers: {
                Authorization: token
            },
        }).then((res) => {

            const data = res.data.data;

            if (data.value !== null)
                setManagerProfile(data.value);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //get queuetypebyCustomer - function
    const getqueuetypebycustomer = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/queue/branch/${branchId}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {

            const data = res.data.data;
            setQueueTypesList(data);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //update QueueTypeofthebranch - function
    const updateQueueTypeofthebranch = (id) => {

        axios({
            method: 'POST',
            url: `${baseUrl}/queue/update/branch/${branchId}/queueType/${id}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            getqueuetypebycustomer();

            toast.remove();
            toast.success('Upadte Queue Type Successfully', {
                position: 'bottom-center',
            });

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //getAllConfigsByBranch - function
    const getAllConfigsByBranch = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/alert/notification/get-list/configs/branch/${branchId}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            let dataList = res.data.data;
            setllnotificationAccessibility(dataList);


        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //enabledisableconfig - function
    const enabledisableconfig = (id) => {

        axios({
            method: 'Post',
            url: `${baseUrl}/alert/notification/config/${id}/enable-disable`,
            headers: {
                Authorization: token
            },
        }).then((res) => {
            let data = res.data;

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //getHomePage - function
    const getHomePageData = () => {
        setShowChart(false)

        axios({
            method: 'GET',
            url: `${baseUrl}/branch/get/home-page-data/branch/${branchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let data = res.data.data;

            let tokenCountArray = []
            let XLabelArray = []
            data.chartData.forEach((element, i) => {
                // let tokenCountSubArray = 

                tokenCountArray.push([i + 1, element.tokenCount])
                XLabelArray.push(element.date);

            });
            setTokenCount(tokenCountArray)
            setXLabel(XLabelArray)

            setShowChart(true);

            setIssuedTokenCount(data.tokensIssued);
            setBranchInfo(data);



        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }


    // handleSelectBranch - function
    const handleSelectBranch = (branch) => {

        setBranchId(branch.id)

        let selectedBranchX = {
            id: branch.id,
            BranchName: branch.name
        }
        selectedBranch = selectedBranchX
        localStorage.setItem('selected_branch', JSON.stringify(selectedBranchX));
    }

    return (
        <>
            <div><Toaster /></div>
            <div className="container-fluid">

                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h2>Branch Management</h2>
                            <nav aria-label="breadcrumb">
                                {selectedBranch != null || selectedBranch != undefined ? (
                                    <ol className="breadcrumb">
                                        {/* <li className="breadcrumb-item"><Link to="/region-management">Region Management</Link></li> */}
                                        <li className="breadcrumb-item active" aria-current="page">{selectedBranch.BranchName}</li>
                                    </ol>
                                ) : null}

                            </nav>
                        </div>
                        <div className="col-md-6 col-sm-12 text-right">

                            {userDetails != null ? (
                                <>
                                    {userDetails.branchLevel === 1 ? (
                                        <select className="custom-select modal-select-field mr-2 select-branch-dropdown" id="inputGroupSelect01"
                                            value={branchDropDValue}
                                            onChange={(e) => {
                                                setBranchDropDValue(e.target.value)
                                                handleSelectBranch(allBranchesList[e.target.value]);
                                            }}
                                        >
                                            <option defaultValue hidden>Select Branch...</option>
                                            {allBranchesList.map((item, index) =>
                                                <option value={index} key={index}>
                                                    {item.name}
                                                </option>
                                            )}

                                        </select>
                                    ) : null}

                                    <>
                                        {userDetails.userType === 3 ? (
                                            <>
                                                <a href="#" className="btn btn-sm btn-primary" title="Edit Branch Details"
                                                    onClick={() => {
                                                        setmodalType(1);
                                                        setAddBranchModal(true);
                                                    }}
                                                >Edit Branch Details</a>

                                                {!data.branch_cloneableBranch ?
                                                    <a href="#" className="btn btn-sm btn-primary ml-1" title="Clone Branch"
                                                        onClick={() => {
                                                            setOpenBranchCloneModal(true);
                                                        }}
                                                    >Clone Branch</a> : null
                                                }
                                            </>
                                        ) : (
                                            null
                                        )}
                                    </>
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>

                <div className="row clearfix">

                    <div className="col-lg-3 col-md-6">

                        {data != null ?

                            <Link className={`card landing-page-main-cards  ${userAccess ? '' : 'card-disable'}`} to='/branch-management/users'>
                                <div className="body w_knob">
                                    <div className="c_know">
                                        <Donut
                                            className="knob"
                                            diameter={50}
                                            // min={0}
                                            // max={data.limit_usersCount}
                                            step={1}
                                            spaceMaxFromZero={false}
                                            value={data.usersCount === null ? 0 : data.usersCount}
                                            theme={{
                                                donutThickness: 2,
                                                donutColor: '#17C2D7',
                                                centerColor: '#282B2F',
                                                bgrColor: '#383b40'
                                            }}
                                        />
                                    </div>
                                    <div className='card-right'>
                                        <h6>Users</h6>
                                    </div>
                                </div>
                            </Link>

                            :

                            <div className="card landing-page-main-cards card-disable">
                                <div className="body w_knob">
                                    <div className="c_know">
                                        <Donut
                                            className="knob"
                                            diameter={50}
                                            min={0}
                                            max={100}
                                            step={1}
                                            value={0}
                                            theme={{
                                                donutThickness: 2,
                                                donutColor: '#17C2D7',
                                                centerColor: '#282B2F',
                                                bgrColor: '#383b40'
                                            }}
                                        />
                                    </div>
                                    <div className='card-right'>
                                        <h6>Users</h6>
                                    </div>
                                </div>
                            </div>

                        }

                    </div>

                    <div className="col-lg-3 col-md-6">
                        {data != null ?

                            <Link className={`card landing-page-main-cards  ${categoryAccess ? '' : 'card-disable'}`} to='/branch-management/categories'>
                                <div className="body w_knob">
                                    <div className="c_know">
                                        <Donut
                                            className="knob"
                                            diameter={50}
                                            // min={0}
                                            // max={data.categoriesCount}
                                            step={1}
                                            value={data.categoriesCount}
                                            theme={{
                                                donutThickness: 2,
                                                donutColor: '#DD7DFF',
                                                centerColor: '#282B2F',
                                                bgrColor: '#383b40'
                                            }}
                                        />
                                    </div>

                                    <div className='card-right'>
                                        <h6>Categories</h6>
                                    </div>
                                </div>
                            </Link>

                            :

                            <div className="card landing-page-main-cards card-disable">
                                <div className="body w_knob">
                                    <div className="c_know">
                                        <Donut
                                            className="knob"
                                            diameter={50}
                                            min={0}
                                            max={100}
                                            step={1}
                                            value={0}
                                            theme={{
                                                donutThickness: 2,
                                                donutColor: '#DD7DFF',
                                                centerColor: '#282B2F',
                                                bgrColor: '#383b40'
                                            }}
                                        />
                                    </div>
                                    <div className='card-right'>
                                        <h6>Categories</h6>
                                    </div>
                                </div>
                            </div>

                        }
                    </div>

                    <div className="col-lg-3 col-md-6">
                        {data != null ?

                            <Link className={`card landing-page-main-cards  ${counterAccess ? '' : 'card-disable'}`} to='/branch-management/counters'>
                                <div className="body w_knob">
                                    <div className="c_know">
                                        <Donut
                                            className="knob"
                                            diameter={50}
                                            min={0}
                                            max={data.limit_countersCount}
                                            step={1}
                                            spaceMaxFromZero={false}
                                            value={data.countersCount}
                                            theme={{
                                                donutThickness: 2,
                                                donutColor: '#4caf50',
                                                centerColor: '#282B2F',
                                                bgrColor: '#383b40'
                                            }}
                                        />
                                    </div>
                                    <div className='card-right'>
                                        <h6>Counters</h6>
                                        <span>{data.limit_countersCount} counters</span>
                                    </div>
                                </div>
                            </Link>

                            :

                            <div className="card landing-page-main-cards card-disable">
                                <div className="body w_knob">
                                    <div className="c_know">
                                        <Donut
                                            className="knob"
                                            diameter={50}
                                            min={0}
                                            max={100}
                                            step={1}
                                            value={0}
                                            theme={{
                                                donutThickness: 2,
                                                donutColor: '#4caf50',
                                                centerColor: '#282B2F',
                                                bgrColor: '#383b40'
                                            }}
                                        />
                                    </div>
                                    <div className='card-right'>
                                        <h6>Counters</h6>
                                        <span>0 counters</span>
                                    </div>
                                </div>
                            </div>

                        }
                    </div>

                    <div className="col-lg-3 col-md-6">
                        {data != null ?

                            <Link className={`card landing-page-main-cards  ${kiosksDesignAccess ? '' : 'card-disable'}`} to='/kiosks-templates'>
                                <div className="body w_knob">
                                    <div className="c_know">
                                        <Donut
                                            className="knob"
                                            diameter={50}
                                            step={1}
                                            value={data.kioskDesignCount}
                                            theme={{
                                                donutThickness: 2,
                                                donutColor: '#c940b2',
                                                centerColor: '#282B2F',
                                                bgrColor: '#383b40'
                                            }}
                                        />
                                    </div>
                                    <div className='card-right'>
                                        <h6>Kiosks Design</h6>
                                    </div>
                                </div>
                            </Link>

                            :

                            <div className="card landing-page-main-cards card-disable">
                                <div className="body w_knob">
                                    <div className="c_know">
                                        <Donut
                                            className="knob"
                                            diameter={50}
                                            step={1}
                                            value={data.kioskDesignCount}
                                            theme={{
                                                donutThickness: 2,
                                                donutColor: '#c940b2',
                                                centerColor: '#282B2F',
                                                bgrColor: '#383b40'
                                            }}
                                        />
                                    </div>
                                    <div className='card-right'>
                                        <h6>Kiosks Design</h6>
                                    </div>
                                </div>
                            </div>

                        }
                    </div>

                    <div className="col-lg-3 col-md-6">
                        {data != null ?

                            <Link className={`card landing-page-main-cards  ${kiosksAccess ? '' : 'card-disable'}`} to='/kiosks-management'>
                                <div className="body w_knob">
                                    <div className="c_know">
                                        <Donut
                                            className="knob"
                                            diameter={50}
                                            min={0}
                                            max={data.limit_kioskCount}
                                            step={data.limit_kioskCount === data.kioskCount ? 0 : .5}
                                            value={data.kioskCount}
                                            theme={{
                                                donutThickness: 2,
                                                donutColor: '#f44336',
                                                centerColor: '#282B2F',
                                                bgrColor: '#383b40'
                                            }}
                                        />
                                    </div>
                                    <div className='card-right'>
                                        <h6>Kiosks</h6>
                                        <span>{data.limit_kioskCount} kiosks</span>
                                    </div>
                                </div>
                            </Link>

                            :

                            <div className="card landing-page-main-cards card-disable">
                                <div className="body w_knob">
                                    <div className="c_know">
                                        <Donut
                                            className="knob"
                                            diameter={50}
                                            min={0}
                                            max={100}
                                            step={1}
                                            value={0}
                                            theme={{
                                                donutThickness: 2,
                                                donutColor: '#f44336',
                                                centerColor: '#282B2F',
                                                bgrColor: '#383b40'
                                            }}
                                        />
                                    </div>
                                    <div className='card-right'>
                                        <h6>Kiosks</h6>
                                        <span>0 kiosks</span>
                                    </div>
                                </div>
                            </div>

                        }
                    </div>

                    <div className="col-lg-3 col-md-6">
                        {data != null ?

                            <Link className={`card landing-page-main-cards  ${mainDisplayAccess ? '' : 'card-disable'}`} to='/branch-management/main-displays'>
                                <div className="body w_knob">
                                    <div className="c_know">
                                        <Donut
                                            className="knob"
                                            diameter={50}
                                            min={0}
                                            max={data.limit_mainDisplayCount}
                                            step={1}
                                            spaceMaxFromZero={false}
                                            value={data.mainDisplayCount}
                                            theme={{
                                                donutThickness: 2,
                                                donutColor: '#2196f3',
                                                centerColor: '#282B2F',
                                                bgrColor: '#383b40'
                                            }}
                                        />
                                    </div>
                                    <div className='card-right'>
                                        <h6>Main Displays</h6>
                                        <span>{data.limit_mainDisplayCount} main displays</span>
                                    </div>
                                </div>
                            </Link>

                            :

                            <div className="card landing-page-main-cards card-disable">
                                <div className="body w_knob">
                                    <div className="c_know">
                                        <Donut
                                            className="knob"
                                            diameter={50}
                                            min={0}
                                            max={100}
                                            step={1}
                                            value={0}
                                            theme={{
                                                donutThickness: 2,
                                                donutColor: '#2196f3',
                                                centerColor: '#282B2F',
                                                bgrColor: '#383b40'
                                            }}
                                        />
                                    </div>
                                    <div className='card-right'>
                                        <h6>Main Displays</h6>
                                        <span>0 main displays</span>
                                    </div>
                                </div>
                            </div>

                        }
                    </div>

                    <div className="col-lg-3 col-md-6">
                        {data != null ?

                            <Link className={`card landing-page-main-cards  ${smsGroupAccess ? '' : 'card-disable'}`} to='/branch-management/sms-group'>
                                <div className="body w_knob">
                                    <div className="c_know">
                                        <Donut
                                            className="knob"
                                            diameter={50}
                                            step={1}
                                            value={data.SMSGroupCount}
                                            theme={{
                                                donutThickness: 2,
                                                donutColor: '#ff8f1d',
                                                centerColor: '#282B2F',
                                                bgrColor: '#383b40'
                                            }}
                                        />
                                    </div>
                                    <div className='card-right'>
                                        <h6>SMS Group</h6>
                                    </div>
                                </div>
                            </Link>

                            :

                            <div className="card landing-page-main-cards card-disable">
                                <div className="body w_knob">
                                    <div className="c_know">
                                        <Donut
                                            className="knob"
                                            diameter={50}
                                            step={1}
                                            value={data.SMSGroupCount}
                                            theme={{
                                                donutThickness: 2,
                                                donutColor: '#ff8f1d',
                                                centerColor: '#282B2F',
                                                bgrColor: '#383b40'
                                            }}
                                        />
                                    </div>
                                    <div className='card-right'>
                                        <h6>SMS Group</h6>
                                    </div>
                                </div>
                            </div>

                        }
                    </div>


                </div>

                <div className="row clearfix">
                    <div className="col-lg-5 col-md-12">
                        <div className="card branch-details-card">
                            <div className="body">
                                <div className="header">
                                    <h2>Manager Details</h2>
                                </div>
                                <div className="media">
                                    {
                                        managerProfile !== undefined ?
                                            <img className="rounded mr-3 branch-details-img" src={`${managerProfile}?${Date.now()}`}
                                                alt="" />
                                            :
                                            <img className="rounded mr-3 branch-details-img" src={require('../../assets/images/others/no-profile.jpg')}
                                                alt="" />
                                    }

                                    <div className="media-body">
                                        <h5 className="mb-1">{data !== null ? data.feature_manager_name : ''}</h5>
                                        <p className="text-muted mb-0">{data !== null ? data.feature_manager_mobile : ''}</p>
                                        <p className="text-muted mb-0">{data !== null ? data.feature_manager_email : ''}</p>
                                    </div>
                                </div>
                                <div className="header">
                                    <h2>Branch Details</h2>
                                </div>
                                <table className="table card-table mb-0 branch-details-card-content">
                                    <tbody>
                                        <tr>
                                            <td className="font-weight-bold">Address</td>
                                            <td className="text-right manager-data-ellipsis" title={data.feature_branch_address}>
                                                {data !== null ? data.feature_branch_address : ''}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">Contact No</td>
                                            <td className="text-right">{data !== null ? data.feature_branch_contact : ''}</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">E-mail Address</td>
                                            <td className="text-right manager-data-ellipsis" title={data.feature_branch_email}>
                                                {data !== null ? data.feature_branch_email : ''}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-7 col-md-12">

                        <div className="card home-chart-card">
                            <div className="body" style={{ minHeight: '301px' }}>
                                <div className='m-b-5'>Served tickets count in last 30 days </div>
                                {/* <p className="text-muted">The global and country campaign stats show how popular your ad is relative to your other ads.</p> */}

                                {showChart ?
                                    <NoOfTicketsChart tokenCount={tokenCount} xLabel={xLabel} chartHeight={306} />

                                    : null
                                }
                            </div>
                        </div>

                        {/* <div className="map-card card">
                            <img src={`https://maps.googleapis.com/maps/api/staticmap?zoom=18&center=${data.feature_latitude},${data.feature_longitude}&size=1100x392&markers=color:red%7Clabel:B%7C${data.feature_latitude},${data.feature_longitude}&key=AIzaSyCpglH1YDwAoTcklFikRAOFqtUoXjcT1G0`} />
                        </div> */}
                    </div>

                </div>

                <div className="row clearfix">
                    <div className="col-lg-12 col-md-12">
                        <div className="card">
                            <div className="body">

                                <div className="row text-center">
                                    <div className="col-lg-4 border-right">
                                        <label className="mb-0">Open Time</label>
                                        <h4 className="font-20">{data !== null ? data.feature_open : ''}</h4>
                                    </div>
                                    <div className="col-lg-4 border-right">
                                        <label className="mb-0">Close Time </label>
                                        <h4 className="font-20">{data !== null ? data.feature_close : ''}</h4>
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="mb-0">Seating Capacity </label>
                                        <h4 className="font-20">{data !== null ? data.feature_seat_capacity : ''}</h4>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                <div className="row clearfix">

                    <div className="col-lg-12 col-md-12">
                        <div className='card mt-2'>
                            <div className="header">
                                <h2>Queue Type</h2>
                            </div>

                            <div className="queue-type-cards">
                                <div className="row clearfix">

                                    {
                                        queueTypesList.map((item, i) => (
                                            <div className="col-lg-3 col-md-3" key={i}>
                                                <div className={`card ${item.selected ? 'active' : ''}`}
                                                // style={{ cursor: 'pointer' }}
                                                // onClick={() => {
                                                //     updateQueueTypeofthebranch(item.id)
                                                // }}
                                                >
                                                    <div className="body" >
                                                        <h6 className='m-0 f-w-400 text-center'>{item.name}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row clearfix'>
                    <div className="col-lg-12 col-md-12">
                        <div className='card'>

                            <div className="header">
                                <h2>Notification Watch list</h2>
                            </div>

                            <div className='body'>
                                <div className="row clearfix">
                                    <div className="col-md-12">
                                        <div className='accessibility-list'>
                                            {
                                                allnotificationAccessibility.length > 0 ?
                                                    <>
                                                        {
                                                            allnotificationAccessibility.map((item, i) =>
                                                                <span className={`badge ${item.disability ? "badge-default" : "badge-secondary"}`}
                                                                    // style={{ cursor: 'pointer' }}
                                                                    key={i}
                                                                // onClick={() => {
                                                                //     enabledisableconfig(item.id);
                                                                //     item.disability = !item.disability;
                                                                //     setRefresh(refresh + 1);

                                                                // }}
                                                                >{item.typeName}
                                                                </span>
                                                            )
                                                        }
                                                    </>
                                                    :
                                                    <p>No features</p>
                                            }

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AddBranchModal addBranchModal={addBranchModal} setAddBranchModal={setAddBranchModal} modalType={modalType}
                branchData={data} getBranchDetails={getBranchDetails} getManagerProfileImage={getManagerProfileImage}
                allowClone={data.branch_cloneableBranch} />

            {
                openBranchCloneModal &&
                <BranchCloneModal openBranchCloneModal={openBranchCloneModal} setOpenBranchCloneModal={setOpenBranchCloneModal}
                    customerId={userDetails.cid} branch={selectedBranch.id} getBranchDetails={getBranchDetails} />
            }
        </>

    )

}


const mapStateToProps = state => ({
    userPermissions: state.settings.userPermissions,
})

const mapDispatchToProps = dispatch => ({
    setUserPermissions: (e) => dispatch(setUserPermissions(e)),
})
export default connect(mapStateToProps, mapDispatchToProps)(BranchLandingPage);
