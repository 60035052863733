import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class CategoryAnalyticsDountchart extends Component {
    constructor(props) {
        console.log('PROPS >> ', props.donutChartColors);
        super(props);

        this.state = {
            // series: [20, 30, 25, 25,],
            options: {
                chart: {
                    height: 300,
                    type: 'donut',
                    // toolbar: {
                    //     show: false
                    // }
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center',
                    show: true,
                },
                labels: ['Issued', 'Served', 'Local Wait', 'No Show'],
                fill: {
                    colors:props.donutChartColors,
                    //  ['#9237B3', '#45F073', '#09E6E6', '#4D1CEF'],
                    // strokeColor: ['##9237B3', '#45F073', '#09E6E6', '#4D1CEF'],

                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                stroke: {
                    show: false,
                    width: 0
                },
            },
            series: [45, 40, 10, 5],
        };
    }
    render() {
        return (
            <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type="donut"
                height={250}
            />
        );
    }
}

export default CategoryAnalyticsDountchart;
