import React, { useState, useEffect } from 'react'
import { Col, Nav, Row, Tab, Tabs } from 'react-bootstrap';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import toast, { Toaster } from 'react-hot-toast';
import Button from 'react-bootstrap-button-loader';
import axios from 'axios';

let today = new Date();
const showSecond = true;
const str = showSecond ? 'HH:mm:ss' : 'HH:mm';
const timeFormat = 'HH:mm:ss';

const AssignCategoryGroupModal = ({ showAssignCategoryGroupModal, setShowAssignCategoryGroupModal, modalType, selectedGroup, setSelectedGroup, getAllCategoryGroups }) => {

    const [baseUrl, setBaseUrl] = useState('');
    const [token, setToken] = useState('');
    const [userDetails, setUserDetails] = useState({});
    const [selectedBranch, setSelectedBranch] = useState({})
    const [buttonLoader, setButtonLoader] = useState(false);

    const [allCategories, setAllCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({});

    const [selectedCategoryWaitTime, setSelectedCategoryWaitTime] = useState('');
    const [selectedCategoryServTime, setSelectedCategoryServTime] = useState('');

    const [selectedCategoryTicketRangeMin, setSelectedCategoryTicketRangeMin] = useState('');
    const [selectedCategoryTicketRangeMax, setSelectedCategoryTicketRangeMax] = useState('');
    const [selectedCategoryQueueSize, setSelectedCategoryQueueSize] = useState(0);

    const [documentButton, setDocumentButton] = useState(false);
    const [onlineBookingButton, setOnlineBookingButton] = useState(false);
    const [showOriginalTicketButton, setShowOriginalTicketButton] = useState(false);

    // for time picker
    var date = "2022-06-02";
    var time = "00:00:00";
    var timeAndDate = moment(date + ' ' + time);
    const [timePickerDefaultValue, setTimePickerDefaultValue] = useState(timeAndDate);


    // get base url & token - useEffect
    useEffect(() => {
        if (showAssignCategoryGroupModal) {
            setBaseUrl(localStorage.getItem('API_URL'));
            setToken(localStorage.getItem('user_token'));
            setUserDetails(JSON.parse(localStorage.getItem('user_details')));
            setSelectedBranch(JSON.parse(localStorage.getItem('selected_branch')))
            console.log("Selected Cat: ", selectedGroup);
        }
    }, [showAssignCategoryGroupModal])

    // call getRemainingCategoriesByCustomer - useEffect
    useEffect(() => {
        if (modalType === 0) {
            if (baseUrl.length > 0 && token.length > 0 && Object.keys(userDetails).length > 0) {
                getRemainingCategoriesByBranch();
            }
        }
    }, [baseUrl, token, userDetails])

    // is update
    useEffect(() => {
        if (modalType === 1) {
            console.log('SELECTED ROW : ', selectedGroup);
            if (Object.keys(selectedGroup).length > 0) {
                setSelectedCategoryWaitTime(selectedGroup.waitTime);
                setSelectedCategoryServTime(selectedGroup.serviceTime);
                setSelectedCategoryTicketRangeMin(selectedGroup.noRangeMin);
                setSelectedCategoryTicketRangeMax(selectedGroup.noRangeMax);
            }
        }
    }, [selectedGroup])


    // getRemainingCategoriesByCustomer - function
    const getRemainingCategoriesByBranch = () => {
        console.log('getRemainingCategoriesByCustomer function start');

        axios({
            method: 'GET',
            url: `${baseUrl}/category/get-remaining-categories/branch/${selectedBranch.id}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('getRemainingCategoriesByCustomer success');
            let dataList = res.data.data;
            console.log("Data List: ",dataList);
            setAllCategories(dataList);

        }).catch((error) => {

            console.log('getRemainingCategoriesByCustomer error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    // addNewCategoryGroup - function
    const addNewCategoryGroup = () => {
        console.log('addNewCategoryGroup function start');

        setButtonLoader(true);

        console.log('seleted category : ', selectedCategory);
        console.log('seleted category id: ', selectedCategory.id);
        console.log('seleted category name: ', selectedCategory.category);
        console.log('noRangeMin : ', selectedCategoryTicketRangeMin);
        console.log('noRangeMax : ', selectedCategoryTicketRangeMax);
        console.log('waitTime : ', selectedCategoryWaitTime);
        console.log('serviceTime : ', selectedCategoryServTime);
        // console.log('userDetails : ', 61);

        const isValidate = validate();

        if (isValidate) {

            axios({
                method: 'POST',
                url: `${baseUrl}/category/create-category-group/branch/${selectedBranch.id}`,
                headers: {
                    Authorization: token
                },
                data: {
                    id: selectedCategory.id,
                    categoryName: selectedCategory.category,
                    waitTime: selectedCategoryWaitTime,
                    serviceTime: selectedCategoryServTime,
                    noRangeMin: selectedCategoryTicketRangeMin,
                    noRangeMax: selectedCategoryTicketRangeMax,
                    branch: {
                        id: userDetails.bid
                    }
                }
            }).then((res) => {

                console.log('addNewCategoryGroup success');

                let data = res.data.data;
                console.log(data);

                setButtonLoader(false);
                closeModal();
                getAllCategoryGroups();
                toast.success('Category group successfully added', {
                    position: 'bottom-center',
                });


            }).catch((error) => {

                console.log('addNewCategoryGroup error');
                console.log(error);

                setButtonLoader(false);

                if (error.response !== undefined) {
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                } else {
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }

            })
        }

    }

    // updateCategoryGroup - function
    const updateCategoryGroup = () => {
        console.log('updateCategoryGroup function start');

        console.log('SELECTED CATEGORY : ', selectedGroup.category);
        console.log('SELECTED CATEGORY WAITING TIME : ', selectedCategoryWaitTime);
        console.log('SELECTED CATEGORY SERVING TIME : ', selectedCategoryServTime);
        console.log('SELECTED CATEGORY TICKET RANGE MIN : ', selectedCategoryTicketRangeMin);
        console.log('SELECTED CATEGORY TICKET RANGE MAX : ', selectedCategoryTicketRangeMax);

        axios({
            method: 'POST',
            url: `${baseUrl}/category-master/update`,
            headers: {
                Authorization: token
            },
            data: {
                id: selectedGroup.id,
                noRangeMin: selectedCategoryTicketRangeMin,
                noRangeMax: selectedCategoryTicketRangeMax,
                waitTime: selectedCategoryWaitTime,
                serviceTime: selectedCategoryServTime,
                branch: {
                    id: userDetails.bid
                }

            }
        }).then((res) => {

            console.log('updateCategoryGroup success');
            let data = res.data.data;
            console.log(data);

            setButtonLoader(false);
            closeModal();
            getAllCategoryGroups();

            toast.remove();
            toast.success('Category group successfully updated', {
                position: 'bottom-center',
            });

        }).catch((error) => {
            console.log('updateCategoryGroup error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // validate - function
    const validate = () => {
        if (selectedCategory.category === undefined) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Please select category', {
                position: 'bottom-center',
            });
            return false;
        } else if (selectedCategoryWaitTime.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Wait time empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (selectedCategoryServTime.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Service time empty', {
                position: 'bottom-center',
            });
            return false;
        } else {
            console.log("Validated.");
            return true;
        }
    }

    // categoryDropdownOnClick - function
    const categoryDropdownOnClick = (cid) => {
        console.log('categoryDropdownOnClick start');
        axios({
            method: 'GET',
            url: `${baseUrl}/category/get-category/m/${cid}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('categoryDropdownOnClick success');
            let data = res.data.data;
            console.log("Data List: ",data);
            setSelectedCategory(data);
            setSelectedCategoryWaitTime(data.waitTime);
            setSelectedCategoryServTime(data.serviceTime);

            setSelectedCategoryTicketRangeMin(data.noRangeMin);
            setSelectedCategoryTicketRangeMax(data.noRangeMax);

            setSelectedCategoryQueueSize(data.queueSize);

        })
    }

    // closeModal - function
    const closeModal = () => {
        setSelectedCategory({});
        setSelectedCategoryWaitTime('');
        setSelectedCategoryServTime('');
        setSelectedCategoryTicketRangeMin('');
        setSelectedCategoryTicketRangeMax('');
        setSelectedCategoryQueueSize()
        setSelectedGroup({});

        setShowAssignCategoryGroupModal(false);
    }


    return (
        <>
            <div><Toaster /></div>
            {showAssignCategoryGroupModal ?
                <div className={`modal fade d-block show modal-container`} id="category-modal">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Assign Category Group</h5>

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">

                                <form className='category-add-form mt-3'>

                                    <div className="container-fluid">

                                        <div className="row clearfix">
                                            <div className="col-md-12 col-sm-12">
                                                <div className="input-group mb-3">
                                                    <p className="input-field-side-text">Category : </p>

                                                    {Object.keys(selectedGroup).length === 0 ? (
                                                        <select className="custom-select modal-select-field"
                                                            id="inputGroupSelect01"
                                                            onChange={(e) => {
                                                                categoryDropdownOnClick(e.target.value);
                                                            }}
                                                        >
                                                            <option defaultValue hidden>Choose...</option>
                                                            {allCategories.map((item, index) =>
                                                                <option value={item.id} key={index}>
                                                                    {item.categoryName}
                                                                </option>
                                                            )}
                                                        </select>

                                                    ) : (
                                                        <input disabled type="text" className="form-control" aria-label="Small"
                                                            aria-describedby="inputGroup-sizing-sm" value={selectedGroup.category} />
                                                    )
                                                    }


                                                </div>
                                            </div>
                                        </div>

                                        <div className="row clearfix">

                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group mb-3">
                                                    <p className="input-field-side-text">Wait Time Exception
                                                        : </p>

                                                    {/* {Object.keys(selectedGroup).length === 0 ? ( */}
                                                        <TimePicker
                                                            value={selectedCategoryWaitTime !== null ? moment(selectedCategoryWaitTime, timeFormat) : ''}
                                                            showSecond={true}
                                                            placeholder="HH:mm:ss"
                                                            className="custom-select modal-select-field"
                                                            defaultOpenValue={timePickerDefaultValue}
                                                            onChange={(value) => {
                                                                if (value === null) {
                                                                    setSelectedCategoryWaitTime('');
                                                                } else {
                                                                    setSelectedCategoryWaitTime(value.format("HH:mm:ss"));
                                                                }
                                                            }}
                                                        />
                                                    {/* ) : (
                                                        <TimePicker
                                                            value={moment(selectedCategoryWaitTime, timeFormat)}
                                                            showSecond={showSecond}
                                                            placeholder="HH:mm:ss"
                                                            className="custom-select modal-select-field"
                                                            defaultOpenValue={timePickerDefaultValue}
                                                            onChange={(value) => {
                                                                setSelectedCategoryWaitTime(value.format("HH:mm:ss"));
                                                            }}
                                                        />
                                                    )} */}


                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group mb-3">
                                                    <p className="input-field-side-text">Service Time Exception
                                                        : </p>
                                                    {/* {Object.keys(selectedGroup).length === 0 ? ( */}
                                                        <TimePicker
                                                            value={selectedCategoryServTime.length > 0 ? moment(selectedCategoryServTime, timeFormat) : ''}
                                                            showSecond={true}
                                                            placeholder="HH:mm:ss"
                                                            className="custom-select modal-select-field"
                                                            defaultOpenValue={timePickerDefaultValue}
                                                            onChange={(value) => {
                                                                if (value === null) {
                                                                    setSelectedCategoryServTime('');
                                                                } else {
                                                                    setSelectedCategoryServTime(value.format("HH:mm:ss"));
                                                                }
                                                            }}

                                                        />

                                                    {/* ) : (
                                                        <TimePicker
                                                            value={moment(selectedCategoryServTime, timeFormat)}
                                                            showSecond={showSecond}
                                                            placeholder="HH:mm:ss"
                                                            className="custom-select modal-select-field"
                                                            defaultOpenValue={timePickerDefaultValue}
                                                            onChange={(value) => {
                                                                setSelectedCategoryServTime(value.format("HH:mm:ss"));
                                                            }}
                                                        />
                                                    )} */}
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row clearfix">

                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group input-group-sm mb-3">
                                                    <p className="input-field-side-text">Default Start Ticket
                                                        : </p>

                                                    <input type="number" className="form-control"
                                                        aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        value={selectedCategoryTicketRangeMin}
                                                        onChange={(e) => {
                                                            setSelectedCategoryTicketRangeMin(e.target.value);
                                                        }} />
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group input-group-sm mb-3">
                                                    <p className="input-field-side-text">Default End Ticket : </p>

                                                    <input type="number" className="form-control"
                                                        aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        value={selectedCategoryTicketRangeMax}
                                                        onChange={(e) => {
                                                            setSelectedCategoryTicketRangeMax(e.target.value);
                                                        }} />
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group input-group-sm mb-3">
                                                    <p className="input-field-side-text">Queue Size : </p>

                                                    <input type="number" className="form-control"
                                                        aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        value={selectedCategoryQueueSize}
                                                        onChange={(e) => {
                                                            setSelectedCategoryQueueSize(e.target.value);
                                                        }} />
                                                </div>
                                            </div>


                                        </div>

                                    </div>

                                </form>

                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-default"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >Cancel
                                </button>

                                {modalType == 0 ?
                                    <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                        onClick={() => {
                                            addNewCategoryGroup();
                                        }}
                                    >Save changes</Button>

                                    :
                                    <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                        onClick={() => {
                                            updateCategoryGroup();
                                        }}
                                    >Update</Button>
                                }

                            </div>

                        </div>
                    </div>
                </div>
                : ''}
        </>
    )
}

export default AssignCategoryGroupModal;