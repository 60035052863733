import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import TimeAgo from "react-timeago";
import MUIDataTable from 'mui-datatables';
import { createTheme } from '@mui/material/styles';

const NotificationManager = () => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [allNotifications, setAllNotifications] = useState([])
    const [filteredNotifications, setFilteredNotifications] = useState([])
    const [allNotificationTypes, setAllNotificationTypes] = useState([])
    const [selectedTypeId, setSelectedTypeId] = useState(null);
    const [onlyUnread, setOnlyUnread] = useState(false)

    const [allNotificationsNew, setAllNotificationsNew] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);

    const [refresh, setRefresh] = useState(0);



    const [dataTable, setDataTable] = useState({
        page: 0,
        size: 10,
        order: null,
    });

    const setTable = (tableState) => {
        setDataTable({
            page: tableState.page,
            size: tableState.rowsPerPage,
            order: tableState.sortOrder
        });
    };

    const columns = [
        {
            label: 'Notification',
            name: 'notificationType',
            options: {
                sort: false,
                filter: false,
            }
        },
        {
            label: 'Description',
            name: 'content',
            options: {
                sort: false,
                filter: false,
            }
        },
        {
            label: "Time",
            name: 'timeAgo',
            options: {
                sort: false,
                filter: false,
            }
        },
        {
            label: "Read Status",
            name: 'readStatus',
            options: {
                sort: true,
                filter: false,
                display: false,
                viewColumns: false,
            }
        },
    ];

    const options = {
        tableBodyHeight: '50vh',
        filterType: 'dropdown',
        filter: false,
        responsive: 'vertical',
        search: false,
        serverSide: true,
        count: count,
        download: false,
        jumpToPage: true,
        selectableRows: 'none',
        print: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setRowProps: row => {
            if (row[3] === false) {
                return {
                    style: { background: "#4e618333" }
                };
            }
        },
        onTableChange: (action, tableState) => {

            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want

            switch (action) {
                case 'changePage':
                    getNotificationsByUser(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'sort':
                    getNotificationsByUser(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'changeRowsPerPage':
                    setRowsPerPage(tableState.rowsPerPage);
                    getNotificationsByUser(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'search':
                    getNotificationsByUser(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                default:
                    console.log('action not handled.');
            }
        },
    };


    // useEffect for getAll
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            if (baseUrl.length > 0 && token.length > 0) {
                getAllNotificationTypes();
                getNotificationsByUser(0, null, rowsPerPage);
            }
        }
    }, [NotificationManager]);

    // when click type, get notifications - useEffect
    useEffect(() => {
        getNotificationsByUser(0, null, rowsPerPage);
    }, [selectedTypeId])




    // get all notifiction types - function
    const getAllNotificationTypes = () => {
        axios({
            method: 'GET',
            url: `${baseUrl}/alert/notification-types/get-list/customer/${userDetails.cid}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            let dataList = res.data.data;
            setAllNotificationTypes(dataList)

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // get notifictions by user - function
    const getNotificationsByUser = (page, sortOrder, size) => {
        axios({
            method: 'POST',
            url: `${baseUrl}/alert/notification/get-list/user/${userDetails.uid}`,
            headers: {
                Authorization: token
            },
            data: {
                type: selectedTypeId,
                page: page,
                size: size,
                sort: sortOrder
            }
        }).then((res) => {

            let data = res.data.data;

            setAllNotificationsNew((data.content))
            setCount(data.totalElements);

        }).catch((error) => {
            console.log(error);
            if (error.response !== undefined) {

                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // mark all unread notifications as read - function
    const markAsRead = () => {

        let notiIds = [];
        allNotificationsNew.forEach(element => {
            if (element.readStatus === false) {
                notiIds.push(element.id)
            }
        });

        axios({
            method: 'POST',
            url: `${baseUrl}/alert/update/notifications`,
            headers: {
                Authorization: token
            },
            data: {
                ids: notiIds,
                userId: userDetails.uid
            }
        }).then((res) => {

            allNotificationsNew.forEach(element => {
                if (element.readStatus === false) {
                    element.readStatus = true
                }
            });
            setRefresh(refresh + 1)

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })

    }


    return (
        <>
            <ToastContainer />
            <div className="container-fluid">
                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h1>Notification Manager</h1>
                        </div>
                    </div>
                </div>
                <div className="row clealfix">
                    <div className="col-md-12 ">
                        <div className='fancy-checkbox-end'>

                            {/* <div className="fancy-checkbox ">
                                <label style={{ marginBottom: '0px' }} className='mr-1'>
                                    <input type="checkbox" name="checkbox" required data-parsley-errors-container="#error-checkbox"
                                        // onChange={(e) => {
                                        //     setOnlyUnread(prev => !prev);
                                        //     // filter(selectedType, e.target.checked);
                                        // }}
                                    />
                                    <span>Unread Notifications</span>
                                </label>
                            </div> */}

                            <button className='btn btn-sm btn-primary' onClick={() => {
                                markAsRead()
                            }} >Mark All as Read</button>
                        </div>
                    </div>
                </div>
                <div className="row clealfix">


                    <div className="col-lg-3 col-md-4 col-sm-12">
                        <>

                            <ul className="list-group mt-2 mb-4 right-custom-nav">


                                {
                                    allNotificationTypes.length > 0 ? (
                                        <>

                                            <li
                                                className={`list-group-item d-flex justify-content-between align-items-center country-select cursor-pointer ${selectedTypeId === null ? 'active' : ''}`}
                                                onClick={() => {
                                                    setSelectedTypeId(null);
                                                }}
                                            >
                                                <label>All</label>
                                            </li>

                                            {allNotificationTypes.map((item, index) =>


                                                <li key={item.id}
                                                    className={`list-group-item d-flex justify-content-between align-items-center country-select cursor-pointer ${item.typeId === selectedTypeId ? 'active' : ''}`}
                                                    onClick={() => {
                                                        setSelectedTypeId(item.typeId);
                                                    }}
                                                >
                                                    <label>{item.type}</label>

                                                </li>


                                            )}
                                        </>
                                    ) : (
                                        <li className="list-group-item d-flex justify-content-between align-items-center country-select">
                                            <label> No Types</label>

                                        </li>
                                    )
                                }


                            </ul>

                        </>
                    </div>


                    <div className="col-lg-9 mt-2 col-md-8 col-sm-12">


                        {/* <div className="card">
                            <div className="body">
                                <ul style={{ listStyleType: 'none', paddingInlineStart: '0px' }} className=" feeds_widget vivify fadeIn">
                                    {
                                        selectedType !== 'all' | onlyUnread === true ? (
                                            filteredNotifications.length > 0 ? (
                                                filteredNotifications.map((item, index) => (

                                                    <li key={index} >
                                                        <a href="#">
                                                            <div className={`feeds-left icon-notificationtype${item.notificationType}`} ><i className="fa fa-check"></i></div>
                                                            <div className="feeds-body">
                                                                <h4 className={`title text-notificationtype${item.notificationType}`}>{item.notificationType}<small
                                                                    className="float-right text-muted">{item.timeAgo}</small></h4>
                                                                <small>{item.content}</small>
                                                            </div>
                                                        </a>
                                                    </li>
                                                ))
                                            ) : (

                                                <p>No Notifications</p>

                                            )

                                        ) : (allNotifications.length > 0 ? (

                                            allNotifications.map((item, index) => (



                                                <li key={index}>
                                                    <a href="#">
                                                        <div className={`feeds-left icon-notificationtype${item.notificationType}`}><i className="fa fa-check"></i></div>
                                                        <div className="feeds-body">
                                                            <h4 className={`title text-notificationtype${item.notificationType}`}>{item.notificationType}<small
                                                                className="float-right text-muted">{item.timeAgo}</small></h4>
                                                            <small>{item.content}</small>
                                                        </div>
                                                    </a>
                                                </li>
                                            ))

                                        ) : (
                                            <p>No Notifications</p>
                                        ))
                                    }

                                </ul>


                            </div>
                        </div> */}
                        {/*<ThemeProvider theme={darkTheme}>*/}
                        {/*<CssBaseline />*/}
                        <MUIDataTable
                            title={<>
                                {count} records.
                                {/* {isLoading &&
                                    <CircularProgress color="secondary" size={20} />} */}
                            </>
                            }
                            data={allNotificationsNew}
                            columns={columns}
                            options={options}
                            className='text-center-table notification-table'
                        />
                        {/*</ThemeProvider>*/}



                    </div>



                </div>


            </div>
        </>
    )
}

export default NotificationManager;