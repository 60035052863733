import React, { useEffect, useState, useRef } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';
import CircularProgress from "@mui/material/CircularProgress";


const HistoricalQueueReport = ({ openHistoricalQueueReport, dateFrom, dateTo, selectedRegionIds, changeRegionIds, reportUrl }) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [allreportsNew, setAllReportsNew] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const timeoutRef = useRef(null);


    const [dataTable, setDataTable] = useState({
        page: 0,
        size: 10,
        search: '',
        order: null,
    });

    const setTable = (tableState) => {
        setDataTable({
            page: tableState.page,
            search: tableState.searchText,
            size: tableState.rowsPerPage,
            order: tableState.sortOrder
        });
    };

    const columns = [
        {
            label: 'Date',
            name: 'date',
            options: {
                sort: true,
                filter: false,
                customBodyRender: (value) => (
                    <p style={{ margin: 0, width: '100px' }}>{value}</p>
                )
            }
        },
        {
            label: "Branch",
            name: 'branchName',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Region",
            name: 'regionName',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Tokens created",
            name: 'tokenCount',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Tokens Completed",
            name: 'tokenCompleted',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "No tokens on hold",
            name: 'tokensOnHold',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "No abandoned tokens",
            name: 'abandonedTokens',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "No waiting tokens",
            name: 'waitingTokens',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Service level",
            name: 'serviceLevel',
            options: {
                sort: true,
                filter: false,
                customBodyRender: (value) => (
                    <p className='m-0'>
                        {value !== null ? (
                            <>{value} %</>
                        ) : <>-</>}
                    </p>
                )
            }
        },
        {
            label: "Abandonment Rate",
            name: 'abandonmentRate',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Average Waiting time",
            name: 'avgWaitTime',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Max Waiting time",
            name: 'maxWaitingTime',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: "Average handled time",
            name: 'avgServiceTime',
            options: {
                sort: true,
                filter: false,
            }
        },


    ];

    const options = {
        // tableBodyHeight: '55vh',
        viewColumns: false,
        filterType: 'dropdown',
        filter: false,
        responsive: 'vertical',
        serverSide: true,
        count: count,
        download: false,
        jumpToPage: true,
        selectableRows: 'none',
        // storageKey: '_qr_qr_table',
        print: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        },
        onTableChange: (action, tableState) => {

            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want

            switch (action) {
                case 'changePage':
                    getHistoricalQueueReport(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'sort':
                    getHistoricalQueueReport(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'changeRowsPerPage':
                    setRowsPerPage(tableState.rowsPerPage);
                    getHistoricalQueueReport(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'search':
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }
                    timeoutRef.current = setTimeout(() => getHistoricalQueueReport(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage), 2000);
                    setTable(tableState);
                    break;
                default:
                    break;
            }
        },
    };



    // getHistoricalQueueReport - useEffect
    useEffect(() => {
        if (openHistoricalQueueReport) {
            if (dateFrom.length > 0 && dateTo.length > 0) {
                getHistoricalQueueReport(0, null, null, rowsPerPage);
            }
        }
    }, [openHistoricalQueueReport, dateFrom, dateTo])

    // when change selected region array, call getHistoricalQueueReport - useEffect
    useEffect(() => {
        if (openHistoricalQueueReport) {
            getHistoricalQueueReport(0, null, null, rowsPerPage);
        }
    }, [changeRegionIds])



    // getHistoricalQueueReport - function
    const getHistoricalQueueReport = (page, sortOrder, search, size) => {
        setIsLoading(true);

        axios({
            method: 'POST',
            url: `${reportUrl}/real-time/historical-report/inner-data/customer/${userDetails.cid}`,
            headers: {
                Authorization: token
            },
            data: {
                "page": page,
                "size": size,
                "dateFrom": dateFrom,
                "dateTo": dateTo,
                "sort": sortOrder,
                "searchText": search,
                branchIds: selectedRegionIds
            }

        }).then((res) => {

            let data = res.data.data;
            setAllReportsNew((data.content))
            setCount(data.totalElements);

            setIsLoading(false)

        }).catch((error) => {
            console.log(error);
            setIsLoading(false)

            if (error.response !== undefined) {
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    return (
        <>
            <div><ToastContainer /></div>

            <MUIDataTable
                title={
                    <>
                        {isLoading ?
                            <CircularProgress color="secondary" size={20} /> : <>{count} records.</>}
                    </>
                }
                data={allreportsNew}
                columns={columns}
                options={options}
                className='text-center-table'
            />

        </>
    )
}

export default HistoricalQueueReport
