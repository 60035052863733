import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import MUIDataTable from "mui-datatables";
import { AppointmentUserActivityViewModal } from "../ModalImpl/AppointmentUserActivityViewModal";
import moment from "moment";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

export const AppointmentUserActivityPage = () => {
  const { state } = useLocation();
  console.log(state);
  const [showTicketDetailsModal, setShowTicketDetailsModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [fromDateSearchKey, setFromDateSearchKey] = useState(
    new Date() - 864e5
  );
  const [toDateSearchKey, setToDateSearchKey] = useState(new Date());
  const [customerActivities, setCustomerActivities] = useState(null);
  const [ticketList, setTicketList] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [sortOrder, setSortOrder] = useState(null);
  const [searchKey, setSearchKey] = useState("");

  const baseUrl = localStorage.getItem("API_URL");
  const token = localStorage.getItem("user_token");
  const userDetails = JSON.parse(localStorage.getItem("user_details"));

  const [dataTable, setDataTable] = useState({
    page: 0,
    size: 10,
    search: "",
    order: null,
  });

  const setTable = (tableState) => {
    setDataTable({
      page: tableState.page,
      search: tableState.searchText,
      size: tableState.rowsPerPage,
      order: tableState.sortOrder,
    });
  };

  const columns = [
    {
      label: "ID",
      name: "id",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      label: "Date",
      name: "issuedDate",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      label: "Branch",
      name: "branchName",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      label: "Category",
      name: "categoryName",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      label: "Token No.",
      name: "token",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      label: "Start Time",
      name: "startTime",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      label: "End Time",
      name: "endTime",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      label: "Ref. No.",
      name: "referenceNo",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      label: "Issued Time",
      name: "issuedTime",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      label: "Status",
      name: "statusName",
      options: {
        sort: false,
        filter: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          if (ticketList === undefined) return;
          let item = ticketList.find((item, i) => i === dataIndex);
          let buttonColor = "";

          switch (item.status) {
            case 0:
              buttonColor = "btn-outline-secondary";
              break;
            case 1:
              buttonColor = "btn-outline-primary";
              break;
            case 2:
              buttonColor = "btn-outline-warning";
              break;
            case 3:
              buttonColor = "btn-outline-warning";
              break;
            case 4:
              buttonColor = "btn-outline-success";
              break;
            case -1:
              buttonColor = "btn-outline-info";
              break;
            default:
              buttonColor = "btn-outline-secondary";
              break;
          }

          return (
            <>
              <button
                className={`btn btn-sm py-0 ${buttonColor} hover-none disabled`}
                onClick={() => {
                  setSelectedItem(item);
                  setShowTicketDetailsModal(true);
                }}
              >
                {item.statusName}
              </button>
            </>
          );
        },
      },
    },
    {
      label: "From",
      name: "originName",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      label: "Actions",
      name: "actions",
      options: {
        sort: false,
        filter: false,

        customBodyRenderLite: (dataIndex, rowIndex) => {
          if (ticketList === undefined) return;
          let item = ticketList.find((item, i) => i === dataIndex);

          return (
            <>
              <button
                className="btn btn-sm btn-default"
                onClick={() => {
                  setSelectedItem(item);
                  setShowTicketDetailsModal(true);
                }}
              >
                <i className="fa fa-eye text-center"></i>
              </button>
            </>
          );
        },
      },
    },
  ];

  const options = {
    tableBodyHeight: "55vh",
    filterType: "dropdown",
    filter: false,
    responsive: "vertical",
    serverSide: true,
    count: count,
    download: false,
    jumpToPage: true,
    search: false,
    selectableRows: "none",
    print: false,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 20, 30, 50, 100],
    setTableProps: () => {
      return {
        // size: "small",
        size: "medium",
      };
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          getTicketsWithPagination(
            tableState.page,
            tableState.sortOrder,
            tableState.searchText,
            tableState.rowsPerPage
          );
          setTable(tableState);
          break;
        case "sort":
          getTicketsWithPagination(
            tableState.page,
            tableState.sortOrder,
            tableState.searchText,
            tableState.rowsPerPage
          );
          setTable(tableState);
          break;
        case "changeRowsPerPage":
          setRowsPerPage(tableState.rowsPerPage);
          getTicketsWithPagination(
            tableState.page,
            tableState.sortOrder,
            tableState.searchText,
            tableState.rowsPerPage
          );
          setTable(tableState);
          break;
        case "search":
          getTicketsWithPagination(
            tableState.page,
            tableState.sortOrder,
            tableState.searchText,
            tableState.rowsPerPage
          );
          setTable(tableState);
          break;
        default:
          break;
      }
    },
  };

  useEffect(() => {
    axios({
      method: "GET",
      url: `${baseUrl}/booking/get-ticket-customer-activities/ticket-customer/${state?.id}`,
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        const data = res.data.data;
        setCustomerActivities(data);
        getTicketsWithPagination(
          pageNumber,
          sortOrder,
          searchKey,
          dataTable.size
        );
      })
      .catch((error) => {
        console.log(error);

        if (error.response !== undefined) {
          toast.remove();
          toast.error(error.response.data.message, {
            position: "bottom-center",
          });
        } else {
          toast.remove();
          toast.error("Something went wrong", {
            position: "bottom-center",
          });
        }
      });
  }, []);

  useEffect(() => {
    getTicketsWithPagination(pageNumber, sortOrder, searchKey, dataTable.size);
  }, [fromDateSearchKey, toDateSearchKey]);

  const onSearch = () => {
    getTicketsWithPagination(pageNumber, sortOrder, searchKey, dataTable.size);
  };

  const getTicketsWithPagination = (page, sortOrder, search, size) => {
    setLoading(true);
    axios({
      method: "POST",
      url: `${baseUrl}/booking/get-ticket-customer-reservations/ticket-customer/${state?.id}`,
      headers: {
        Authorization: token,
      },
      data: {
        page: page,
        size: size,
        dateFrom: moment(fromDateSearchKey).format("DD-MM-YYYY"),
        dateTo: moment(toDateSearchKey).format("DD-MM-YYYY"),
        sort: sortOrder,
        searchText: search,
      },
    })
      .then((res) => {
        const data = res.data.data;

        setCount(data.totalElements);
        setTicketList(data.content);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);

        if (error.response !== undefined) {
          toast.remove();
          toast.error(error.response.data.message, {
            position: "bottom-center",
          });
        } else {
          toast.remove();
          toast.error("Something went wrong", {
            position: "bottom-center",
          });
        }
      });
  };

  return (
    <>
      <div>
        <Toaster />
      </div>
      <div className="container-fluid">
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1> User Activity</h1>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="card">
            <div className="body">
              <div className="row">
                <div className=" col-lg-12">
                  <h6 className="">
                    User Name: <span className="ml-2">{state?.mobileNo} </span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row clearfix">
          <div className=" col-lg-2 mb-4 pl-0">
            <div className="card m-0 h-100">
              <div className="body user-activity-card h-100">
                <i className="fa fa-sign-in"></i>
                <h6 className="">User Login</h6>
                <p>User Login Count</p>
                <h4 className="text-light-green">
                  {customerActivities?.userLogin !== undefined
                    ? customerActivities.userLogin
                    : "-"}
                </h4>
              </div>
            </div>
          </div>

          <div className=" col-lg-2 mb-4 pl-0">
            <div className="card m-0 h-100">
              <div className="body user-activity-card h-100">
                <i className="fa fa-ticket"></i>
                <h6 className="">All Reservations</h6>
                <p>Reservations Count</p>
                <h4 className="text-light-green">
                  {customerActivities?.allReservations !== undefined
                    ? customerActivities.allReservations
                    : "-"}
                </h4>
              </div>
            </div>
          </div>
          <div className=" col-lg-2 mb-4 pl-0">
            <div className="card m-0 h-100">
              <div className="body user-activity-card h-100">
                <i className="fa fa-clock-o"></i>
                <h6 className="">Upcoming Reservations</h6>
                <p>Reservation Count</p>
                <h4 className="text-light-green">
                  {customerActivities?.upcomingReservations !== undefined
                    ? customerActivities.upcomingReservations
                    : "-"}
                </h4>
              </div>
            </div>
          </div>
          <div className=" col-lg-2 mb-4 pl-0">
            <div className="card m-0 h-100">
              <div className="body user-activity-card h-100">
                <i className="fa fa-check"></i>
                <h6 className="">Completed Reservations</h6>
                <p>Reservations Count</p>
                <h4 className="text-light-green">
                  {customerActivities?.completedReservations !== undefined
                    ? customerActivities.completedReservations
                    : "-"}
                </h4>
              </div>
            </div>
          </div>
          <div className=" col-lg-2 mb-4 pl-0">
            <div className="card m-0 h-100">
              <div className="body user-activity-card h-100">
                <i className="fa fa-times"></i>
                <h6 className="">Cancellations</h6>
                <p>Cancelled Reservation Count</p>
                <h4 className="text-light-green">
                  {customerActivities?.cancelledReservations !== undefined
                    ? customerActivities.cancelledReservations
                    : "-"}
                </h4>
              </div>
            </div>
          </div>
          <div className=" col-lg-2 mb-4 pl-0">
            <div className="card m-0  h-100">
              <div className="body user-activity-card h-100">
                <i className="fa fa-smile-o"></i>
                <h6 className="">Feedback</h6>
                <p>Feedback Count</p>
                <h4 className="text-light-green">
                  {customerActivities?.feedbackCount !== undefined
                    ? customerActivities.feedbackCount
                    : "-"}
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="row clearfix">
          <div className="card">
            <div className="body">
              <div className="row">
                <div className="col-lg-5 col-md-6">
                  <div className="input-group">
                    <input
                      value={searchKey}
                      onChange={(e) => {
                        let inputText = e.target.value;
                        setSearchKey(inputText);

                        if (e.target.value === "") {
                          getTicketsWithPagination(
                            pageNumber,
                            sortOrder,
                            inputText,
                            dataTable.size
                          );
                        }
                      }}
                      type="text"
                      className="form-control"
                      placeholder="Type Token Number Here..."
                    />

                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary px-5"
                        type="button"
                        onClick={() => onSearch()}
                      >
                        <span>Search</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-6">
                  <div className="d-flex justify-content-end">
                    <div
                      className="input-daterange input-group d-flex justify-content-end"
                      data-provide="datepicker"
                    >
                      <DatePicker
                        className="input-sm form-control"
                        selected={fromDateSearchKey}
                        dateFormat="dd-MM-yyyy"
                        onChange={(date) => {
                          setFromDateSearchKey(date);
                        }}
                      />

                      <span className="input-group-addon range-to">to</span>

                      <DatePicker
                        className="input-sm form-control"
                        selected={toDateSearchKey}
                        minDate={fromDateSearchKey}
                        dateFormat="dd-MM-yyyy"
                        onChange={(date) => {
                          setToDateSearchKey(date);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row clearfix">
          <div className="card">
            <div className="body p-0 appointment-userlist-table-container">
              {/* <MDBDataTable
                noBottomColumns
                className="table table-hover js-basic-example dataTable table-custom spacing5 action-col-align categories-table overflow-scroll "
                displayEntries={false}
                searching={false}
                paging
                pagesAmount={15}
                responsive
                data={tableData}
              /> */}

              <MUIDataTable
                title={
                  <>
                    {count}
                    {count === 1 ? " record" : " records"}
                    {isLoading && (
                      <>
                        &nbsp;&nbsp;&nbsp;
                        <CircularProgress color="primary" size={10} />
                      </>
                    )}
                  </>
                }
                data={ticketList}
                columns={columns}
                options={options}
                className="text-center-table"
              />
            </div>
          </div>
        </div>
      </div>

      <AppointmentUserActivityViewModal
        showTicketDetailsModal={showTicketDetailsModal}
        setShowTicketDetailsModal={setShowTicketDetailsModal}
        data={selectedItem}
      />
    </>
  );
};
