import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import AddMainUserEditRoles from "../ModalImpl/AddMainUserEditRoles";
import AssignUserSkillsModal from "../ModalImpl/AssignUserSkillsModal";
import AssignTransferCategoryModal from "../ModalImpl/AssignTransferCategoryModal";
import AssignNotificationAccessibilityModal from '../ModalImpl/AssignNotificationAccessibilityModal';

import IconButton from '@mui/material/IconButton';
import TooltipMu from '@mui/material/Tooltip';

import { connect } from 'react-redux';
import { setUserPermissions } from '../../actions/settingsAction';


const EditMainUser = ({ userPermissions }) => {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    let userId = params.get('uid');

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [id, setId] = useState('')
    const [selectedImage, setSelectedImage] = useState({});
    const [image, setImage] = useState('')
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [empId, setEmpId] = useState('');
    const [email, setEmail] = useState('');
    const [genderType, setGenderType] = useState('');

    const [userType, setUserType] = useState({});
    const [userTypeId, setUserTypeId] = useState('');

    const [userName, setUserName] = useState(''); // frontend - display name || backend username
    const [contactNo, setContactNo] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [userBid, setUserBid] = useState(null);

    const [buttonLoader, setButtonLoader] = useState(false);

    const [showUserRolesModal, setShowUserRolesModal] = useState(false);

    const [roleName, setRoleName] = useState('');
    const [showAssignUserSkillsModal, setShowAssignUserSkillsModal] = useState('');

    const [userRolesPermission, setUserRolesPermission] = useState([]);
    const [userData, setUserData] = useState([]);
    const [userSkillList, setUserSkillList] = useState({});

    const [allTsuProfiles, setAllTsuProfiles] = useState([]);
    const [selectedTsuProfile, setSelectedTsuProfile] = useState();

    const [showAssignTransferCategory, setShowAssignTransferCategory] = useState('');
    const [userCategoryList, setUserCategoryList] = useState('');

    const [showAssignNotificationAccessibility, setShowAssignNotificationAccessibility] = useState('');
    const [allnotificationAccessibility, setllnotificationAccessibility] = useState({});

    const [loggedUserPermissionsList, setLoggedUserPermissionsList] = useState([]); // for edit btns enable / disable
    const [personalDetailsEditAccess, setPersonalDetailsEditAccess] = useState(false);
    const [passwordEditAccess, setPasswordEditAccess] = useState(false);
    const [userRolesEditAccess, setUserRolesEditAccess] = useState(false);
    const [userSkillsEditAccess, setUserSkillsEditAccess] = useState(false);
    const [transferCategoryEditAccess, setTransferCategoryEditAccess] = useState(false);
    const [notifiAccessibilityEditAccess, setNotifiAccessibilityEditAccess] = useState(false);


    const headers = {
        "Content-Type": "application/json",
        "Authorization": token
    }

    const config = {
        headers: {
            'content-type': 'multipart/form-data',
            "Authorization": token
        }
    }

    const genderOptions = [
        {
            id: "1",
            label: "Male",
            value: "Male",
        },
        {
            id: "2",
            label: "Female",
            value: "Female",
        },
    ];

    const userTypeOptions = [

        {
            id: "1",
            value: "Teller",
        },
        // {
        //     id: "2",
        //     value: "Admin",
        // },
        // {
        //     id: "3",
        //     value: "Super Admin",
        // },
        {
            id: "4",
            value: "User",
        },
        // {
        //     id: "5",
        //     value: "Super User",
        // },

    ];

    // renderTooltip - function
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {props}
        </Tooltip>
    );



    // call startup function - useEffect
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            if (baseUrl.length > 0 && token.length > 0) {
                getAllTsuProfiles();
                getMainUserById();
                getSkillsByUserId();
                getTellerTransferCategories();
                getassignedaccesibiltiesbyuser();
            }
        }
    }, [EditMainUser]);

    useEffect(() => {
        getSkillsByUserId();
    }, [showAssignUserSkillsModal])


    // get logged user permissions - useEffect
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            if (userPermissions.length === 0) {
                getLoggedUserPermissions();
            } else {
                setLoggedUserPermissionsList(userPermissions);
            }
        }
    }, [EditMainUser])

    // check logged user permissions - useEffect
    useEffect(() => {
        if (loggedUserPermissionsList.length > 0) {

            for (let i = 0; i < loggedUserPermissionsList.length; i++) {
                const element = loggedUserPermissionsList[i];

                if (element.roleFeature === 'edit_user_personal_detail' && element.hasFeature === true) {
                    setPersonalDetailsEditAccess(true);
                } else if (element.roleFeature === 'edit_user_password' && element.hasFeature === true) {
                    setPasswordEditAccess(true);
                } else if (element.roleFeature === 'edit_user_roles' && element.hasFeature === true) {
                    setUserRolesEditAccess(true);
                } else if (element.roleFeature === 'edit_user_skills' && element.hasFeature === true) {
                    setUserSkillsEditAccess(true);
                } else if (element.roleFeature === 'edit_user_transfer_category' && element.hasFeature === true) {
                    setTransferCategoryEditAccess(true);
                } else if (element.roleFeature === 'edit_user_notification_access' && element.hasFeature === true) {
                    setNotifiAccessibilityEditAccess(true);
                }
            }

        }
    }, [loggedUserPermissionsList])



    // getMainUserById - function
    const getMainUserById = () => {

        axios.get(`${baseUrl}/user/get-user-by-id/${userId}`, {
            headers: headers
        }).then((response) => {

            let data = response.data.data;

            setUserData(data);

            setId(data.id);
            setFirstName(data.firstName);
            setLastName(data.lastName);
            setUserName(data.userName);
            setEmpId(data.employeeNumber);
            setGenderType(data.gender);
            setContactNo(data.contact);
            setEmail(data.email);
            setRoleName(data.roleName);

            if (data.image === undefined) {
                setImage('');
            } else {
                setImage(data.image);
            }

            setUserType(data.userType);
            setUserTypeId(data.userType.id);

            setUserBid(data.bid);

            if (data.callingPadProfileBean !== undefined)
                setSelectedTsuProfile(data.callingPadProfileBean.id);


            if (data.roleFeatureData !== undefined && data.roleFeatureData !== null) {
                let feat = data.roleFeatureData;
                let selectedRoles = [];
                for (let i = 0; i < feat.length; i++) {
                    selectedRoles.push(feat[i].feature)
                }
                setUserRolesPermission(selectedRoles);
            }

        }).catch(error => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        });
    }

    // reset personal data fields - function
    const setUserDataForFields = () => {
        setId(userData.id);
        setFirstName(userData.firstName);
        setLastName(userData.lastName);
        setUserName(userData.userName);
        setEmpId(userData.employeeNumber);
        setGenderType(userData.gender);
        setContactNo(userData.contact);
        setEmail(userData.email);
        setRoleName(userData.roleName);

        if (userData.image === undefined) {
            setImage('');
        } else {
            setImage(userData.image);
        }

        setUserType(userData.userType);
        setUserTypeId(userData.userType.id);

        setUserBid(userData.bid);

        if (userData.callingPadProfileBean !== undefined)
            setSelectedTsuProfile(userData.callingPadProfileBean.id);
    }

    // getAllTsuProfiles - function
    const getAllTsuProfiles = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/branch/calling-pad-profile/get-list/customer/${userDetails.cid}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let data = res.data.data;
            setAllTsuProfiles(data);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })

    }

    // getSkillsByUserId - function
    const getSkillsByUserId = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/user/get-skills/${userId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let data = res.data.data;
            setUserSkillList(data)

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    };

    // if logged user permissions null, get user permissions
    const getLoggedUserPermissions = async () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/user/role-features/get-list/${userDetails.uid}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            let data = res.data.data;

            // set userPermissions for gloabal
            setUserPermissions(data);
            // set userPermissions for component state
            setLoggedUserPermissionsList(data);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })

    }


    // update user details - function
    let handleSubmit = async (e) => {
        e.preventDefault();
        setButtonLoader(true);

        let isValidate = validation();

        if (isValidate) {

            let formData = new FormData();
            formData.append('id', id)
            formData.append('userTypeId', userTypeId);
            formData.append('username', userName);
            formData.append('firstName', firstName);
            formData.append('lastName', lastName);
            formData.append('employeeNumber', empId);
            formData.append('email', email);
            formData.append('contactNumber', contactNo);
            formData.append('gender', genderType);

            if (userTypeId === 1 || userTypeId === '1')
                formData.append('CPPId', selectedTsuProfile);

            if (selectedImage.pictureAsFile === undefined) {
                formData.append("profilePicture", image);
            } else {
                formData.append("profilePicture", selectedImage.pictureAsFile);
            }

            axios.post(`${baseUrl}/user/update`, formData, config)
                .then(response => {
                    toast.remove();
                    toast.success('User Successfully Updated!', {
                        position: 'bottom-center',
                    });
                    getMainUserById();
                    setButtonLoader(false);

                })
                .catch(error => {
                    console.log(error);

                    setButtonLoader(false);

                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                });

        }
    };

    // validation - function
    const validation = () => {

        if (firstName.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('First name empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (lastName.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Last name empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (empId.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Employee id empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (genderType.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Gender empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (contactNo.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Contact number empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (email.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Email empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (userTypeId.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('User type empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (userTypeId === '1' && selectedTsuProfile.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('TSU profile empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (userName.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Username empty', {
                position: 'bottom-center',
            });
            return false;
        } else {
            return true;
        }
    }

    // imageChange - function
    const imageChange = (e) => {

        setSelectedImage({
            /* contains the preview, if you want to show the picture to the user
                 you can access it with this.state.currentPicture
             */
            picturePreview: URL.createObjectURL(e.target.files[0]),
            /* this contains the file we want to send */
            pictureAsFile: e.target.files[0],
        });

    };

    //resetPasswordByUser - function
    const resetPasswordByUser = () => {
        setButtonLoader(true);

        let isValidate = passwordValidate(true);

        if (isValidate) {

            axios({
                method: 'POST',
                url: `${baseUrl}/user/reset-password`,
                headers: headers,
                data: {
                    "oldPassword": oldPassword,
                    "newPassword": newPassword
                }
            }).then((res) => {

                let data = res.data.data.list;
                toast.remove();
                toast.success('Reset Password Successfully ', {
                    position: 'bottom-center',
                });
                setOldPassword('');
                setNewPassword('');
                setConfirmNewPassword('');


            }).catch((error) => {
                console.log(error);
                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }
            })
        }

    };

    //resetPasswordByAdmin - function
    const resetPasswordByAdmin = () => {
        setButtonLoader(true);

        let isValidate = passwordValidate(false);

        if (isValidate) {
            axios({
                method: 'POST',
                url: `${baseUrl}/user/reset-password/admin`,
                headers: headers,
                data: {
                    "userId": id,
                    "newPassword": newPassword
                }
            }).then((res) => {
                let data = res.data.data.list;
                toast.remove();
                toast.success('Password Changed Successfully ', {
                    position: 'bottom-center',
                });
                setOldPassword('');
                setNewPassword('');
                setConfirmNewPassword('');


            }).catch((error) => {
                console.log(error);
                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }
            })
        }
    }

    //handlePasswordReset - function
    const handlePasswordReset = () => {

        if (userDetails.uid == id) {
            resetPasswordByUser('');
        } else {
            resetPasswordByAdmin('');
        }
    }

    // passwordValidate - function
    const passwordValidate = (isUser) => {

        if (isUser && oldPassword.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Current password empty!', {
                position: 'bottom-center',
            });
            return false;
        } else if (newPassword.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('New password empty!', {
                position: 'bottom-center',
            });
            return false;
        } else if (confirmNewPassword.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Confirm password empty!', {
                position: 'bottom-center',
            });
            return false;
        } else if (newPassword != confirmNewPassword) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Please check confirm password!', {
                position: 'bottom-center',
            });
            return false;
        } else {
            return true;
        }
    }

    //getTellerTransferCategories - function
    const getTellerTransferCategories = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/user/transfer/get-list/user/${userId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let data = res.data.data;
            setUserCategoryList(data)


        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }


    //getassignedaccesibiltiesbyuser - function
    const getassignedaccesibiltiesbyuser = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/alert/notification/get-list/accessibility/user/${userId}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            let dataList = res.data.data;
            setllnotificationAccessibility(dataList);


        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    const disableUserSkills = (skillId) => {

        axios({
            method: 'POST',
            url: `${baseUrl}/skill/disable/skill/${skillId}/user/${userId}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            getSkillsByUserId();

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //clear password fields - function
    const clearPasswordFields = () => {
        setOldPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
    }


    return (
        <>
            <div><Toaster /></div>
            <div className="container-fluid">
                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h2>Edit User</h2>
                        </div>

                    </div>
                </div>
                <div className="row clearfix">

                    <form className="user-edit-form" onSubmit={handleSubmit} encType="multipart/form-data">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                            <div className="card social" style={{ marginBottom: '10px' }}>
                                <div className="profile-header d-flex justify-content-between justify-content-center"
                                    style={{ padding: '6px' }}>
                                    <div className="d-flex">
                                        <div className="circle text-center ml-4 mr-2">
                                            <div>

                                                <div className="personal-image">
                                                    <label className="img-input-label">
                                                        <input type="file" id="fileInput" name="image"
                                                            onChange={imageChange}
                                                            accept="image/*" />
                                                        <div className="personal-figure">

                                                            {selectedImage.picturePreview ?
                                                                <>
                                                                    <img src={selectedImage.picturePreview}
                                                                        className="personal-avatar" alt="avatar"
                                                                        style={{ width: '80px', height: '80px' }} />
                                                                    <div className="personal-figcaption">
                                                                        <img src={require('../../assets/images/others/camera-white.png')}
                                                                            alt="avatar"
                                                                            style={{ width: '25px', height: '25px' }} />
                                                                    </div>
                                                                </> : <>

                                                                    {image.length > 0 ?
                                                                        <img
                                                                            src={`${image}?${Date.now()}`}
                                                                            className="personal-avatar " alt="avatar" style={{ width: '80px', height: '80px' }} />
                                                                        :
                                                                        <img src={require('../../assets/images/others/no-profile.jpg')}
                                                                            className="personal-avatar " alt="avatar" style={{ width: '80px', height: '80px' }} />
                                                                    }
                                                                    <div className="personal-figcaption">
                                                                        <img src={require('../../assets/images/others/camera-white.png')}
                                                                            alt="avatar"
                                                                            style={{ width: '25px', height: '25px' }} />
                                                                    </div>
                                                                </>
                                                            }


                                                        </div>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="details center-div">
                                            <div className="with-name">
                                                <h5 className="">{userData.firstName} {userData.lastName}</h5>
                                                <span className="">{userType.description}</span>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-xl-12 col-lg-12 col-md-12">
                            <div className="card">
                                <div className="header">
                                    <h2>Basic Information</h2>
                                </div>

                                <div className="body">
                                    <div className="row clearfix">
                                        <div className="col-lg-4 col-md-12">
                                            <div className="form-group">
                                                <p className="input-field-side-text">First Name : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    readOnly={personalDetailsEditAccess === false}
                                                    value={firstName}
                                                    onChange={(e) => setFirstName(e.target.value)} />
                                            </div>

                                        </div>
                                        <div className="col-lg-4 col-md-12">
                                            <div className="form-group">
                                                <p className="input-field-side-text">Last Name : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    readOnly={personalDetailsEditAccess === false}
                                                    value={lastName}
                                                    onChange={(e) => setLastName(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-12">
                                            <div className="form-group">
                                                <p className="input-field-side-text">User Name : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    readOnly={personalDetailsEditAccess === false}
                                                    value={userName}
                                                    onChange={(e) => setUserName(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-12">
                                            <div className="form-group">
                                                <p className="input-field-side-text">Employee No : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    readOnly={personalDetailsEditAccess === false}
                                                    value={empId}
                                                    onChange={(e) => setEmpId(e.target.value)} />
                                            </div>
                                        </div>


                                        <div className="col-lg-4 col-md-12">
                                            <div className="form-group">
                                                <p className="input-field-side-text">Email : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                    disabled={true}
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    readOnly={personalDetailsEditAccess === false}
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-12">
                                            <div className="form-group">
                                                <p className="input-field-side-text">Contact No : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    readOnly={personalDetailsEditAccess === false}
                                                    value={contactNo}
                                                    onChange={(e) => setContactNo(e.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-12">
                                            <div className="form-group">
                                                <p className="input-field-side-text">Gender : </p>
                                                <select className="form-control"
                                                    disabled={personalDetailsEditAccess === false}
                                                    value={genderType}
                                                    onChange={(e) => setGenderType(e.target.value)}>
                                                    {genderOptions.map((option) => (
                                                        <option key={option.value}>
                                                            {option.value}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        {
                                            userTypeId === 3 || userTypeId === '3' ? (
                                                null
                                            ) : (
                                                <div className="col-lg-4 col-md-12">
                                                    <div className="form-group">
                                                        <p className="input-field-side-text">User Type : </p>
                                                        <select className="form-control"
                                                            disabled={personalDetailsEditAccess === false}
                                                            onChange={(e) => setUserTypeId(e.target.value)}
                                                            value={userTypeId}>
                                                            {userTypeOptions.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.value}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            )
                                        }


                                        {
                                            userTypeId === 1 || userTypeId === '1' ? (
                                                <div className="col-lg-4 col-md-12">
                                                    <div className="form-group">
                                                        <p className="input-field-side-text">TSU Profile : </p>
                                                        <select className="form-control"
                                                            disabled={personalDetailsEditAccess === false}
                                                            onChange={(e) => setSelectedTsuProfile(e.target.value)}
                                                            value={selectedTsuProfile}>
                                                            <option hidden>Select TSU Profile</option>
                                                            {allTsuProfiles.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            ) : null

                                        }

                                    </div>

                                    {personalDetailsEditAccess ? (
                                        <div className="row clearfix">
                                            <div className="col-md-6 col-sm-12">

                                            </div>
                                            <div className="col-md-6 col-sm-12 text-right ">
                                                <Button loading={buttonLoader} type="submit"
                                                    className="btn btn-primary mr-1 text-right">Update
                                                </Button>
                                                &nbsp;&nbsp;
                                                <button type="button" className="btn btn-default text-right"
                                                    onClick={() => {
                                                        setUserDataForFields();
                                                    }}
                                                >Cancel
                                                </button>
                                            </div>
                                        </div>
                                    ) : null}


                                </div>
                            </div>
                        </div>
                    </form>

                    {passwordEditAccess ? (
                        <div className='col-xl-12 col-lg-12 col-md-12'>
                            <div className="card">
                                <div className='header'>
                                    <h2>Reset Password</h2>
                                </div>

                                <div className='body'>
                                    <div className="row clearfix">

                                        {userDetails.uid === id ? (
                                            <div className="col-lg-4 col-md-12">
                                                <div className="form-group">
                                                    <p className="input-field-side-text">Current Password : </p>
                                                    <input type="password" className="form-control" aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        value={oldPassword}
                                                        onChange={(e) => setOldPassword(e.target.value)} />
                                                </div>
                                            </div>
                                        ) : null
                                        }

                                        <div className="col-lg-4 col-md-12">
                                            <div className="form-group">
                                                <p className="input-field-side-text">New Password : </p>
                                                <input type="password" className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    value={newPassword}
                                                    onChange={(e) => setNewPassword(e.target.value)} />
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-12">
                                            <div className="form-group">
                                                <p className="input-field-side-text">Confirm New Password : </p>
                                                <input type="password" className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    value={confirmNewPassword}
                                                    onChange={(e) => setConfirmNewPassword(e.target.value)} />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row clearfix">
                                        <div className="col-md-6 col-sm-12">

                                        </div>
                                        <div className="col-md-6 col-sm-12 text-right ">
                                            <Button loading={buttonLoader} type="submit"
                                                className="btn btn-primary mr-1 text-right"
                                                onClick={() => {
                                                    handlePasswordReset();
                                                }}
                                            >Reset </Button>
                                            &nbsp;&nbsp;
                                            <button type="button" className="btn btn-default text-right"
                                                onClick={() => {
                                                    clearPasswordFields();
                                                }}
                                            >Cancel
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    ) : null}


                    {userTypeId !== 1 ? (
                        <div className="col-xl-12 col-lg-12 col-md-12">
                            <div className="card">
                                <div className="header">
                                    <h2>User Role - <span style={{ color: 'rgb(23, 194, 215)' }}>{roleName}</span> </h2>
                                </div>
                                <div className="body">
                                    <div className="row clearfix">
                                        <div className="col-lg-11">

                                            {userRolesPermission.length > 0 ?

                                                userRolesPermission.map((urp, i) => (
                                                    <span className="badge badge-secondary">{urp}</span>
                                                )
                                                ) : (
                                                    <p className="mb-0">No roles assigned</p>
                                                )
                                            }


                                        </div>

                                        {userRolesEditAccess ? (
                                            <div className="col-lg-1">
                                                <button type="button" className="btn btn-sm btn-default" title=""
                                                    data-toggle="tooltip"
                                                    data-placement="top" onClick={() => {
                                                        setShowUserRolesModal(true);
                                                    }}
                                                ><i className="fa fa-pencil"> </i></button>
                                            </div>
                                        ) : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (null)}

                    {
                        selectedTsuProfile !== undefined ? (
                            <>
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="card">
                                        <div className="header">
                                            <h2>User Skills
                                                <TooltipMu title="Click on a skill to enable or disable that skill. If you disable a skill for this user, that skill related tickets will not route to this user. ">
                                                    <IconButton size="small">
                                                        {/* <DeleteIcon /> */}
                                                        <i className='fa fa-question-circle text-info' />
                                                    </IconButton>
                                                </TooltipMu>
                                            </h2>
                                        </div>

                                        <div className="body">
                                            <div className="row clearfix">
                                                <div className="col-lg-11">

                                                    {userSkillList.length > 0 ?

                                                        userSkillList.map((usl, i) => (
                                                            <>
                                                                {usl.disable === false ? (
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={renderTooltip(usl.lang)}
                                                                    >
                                                                        <span className="badge badge-secondary user-skill-badge"
                                                                            onClick={() => disableUserSkills(usl.skillId)}
                                                                        >
                                                                            {usl.skillName}
                                                                            <div className='skill-level-span'>
                                                                                {usl.skillLevel}
                                                                            </div>
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                ) : (
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={renderTooltip(usl.lang)}
                                                                    >
                                                                        <span className="badge badge-default user-skill-badge"
                                                                            onClick={() => disableUserSkills(usl.skillId)}
                                                                        >
                                                                            {usl.skillName}
                                                                            <div className='skill-disable-span'>
                                                                                {usl.skillLevel}
                                                                            </div>
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                )}
                                                            </>
                                                        )
                                                        ) : (
                                                            <p className='mb-0'>No skills assigned</p>
                                                        )
                                                    }

                                                </div>

                                                {userSkillsEditAccess ? (
                                                    <div className="col-lg-1">
                                                        <button type="button" className="btn btn-sm btn-default" title=""
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            onClick={() => {
                                                                setShowAssignUserSkillsModal(true);

                                                            }}
                                                        ><i className="fa fa-pencil"> </i></button>
                                                    </div>
                                                ) : null}

                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className='card'>
                                        <div className="header">
                                            <h2>Transfer Category</h2>
                                        </div>

                                        <div className='body'>
                                            <div className="row clearfix">
                                                <div className="col-lg-11">

                                                    {userCategoryList.length > 0 ?

                                                        userCategoryList.map((categories, i) => (
                                                            <>
                                                                <span
                                                                    className="badge badge-secondary" >{categories.category}
                                                                </span>
                                                            </>

                                                        )
                                                        ) : (
                                                            <p className="mb-0">No transfer category assigned</p>
                                                        )
                                                    }
                                                </div>

                                                {transferCategoryEditAccess ? (
                                                    <div className="col-lg-1">
                                                        <button type="button" className="btn btn-sm btn-default" title=""
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            onClick={() => {
                                                                setShowAssignTransferCategory(true);
                                                            }}
                                                        ><i className="fa fa-pencil"> </i></button>
                                                    </div>
                                                ) : null}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null
                    }


                    {
                        userType.description === 'User' ? (
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className='card'>
                                    <div className="header">
                                        <h2>Notification Accessibility</h2>
                                    </div>


                                    <div className='body'>
                                        <div className="row clearfix">

                                            {
                                                userTypeId === 2 || userTypeId === '2' ? (
                                                    null
                                                ) : (
                                                    <div className="col-lg-11">

                                                        {allnotificationAccessibility.length > 0 ?

                                                            allnotificationAccessibility.map((accessibility, i) => (
                                                                <>
                                                                    <span
                                                                        className="badge badge-secondary" >{accessibility.typeName}
                                                                    </span>
                                                                </>

                                                            )
                                                            ) : (
                                                                <p className="mb-0"> No Assigned Accessibility For User</p>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }


                                            <div className="col-lg-1">
                                                <button type="button" className="btn btn-sm btn-default" title=""
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    onClick={() => {
                                                        setShowAssignNotificationAccessibility(true);
                                                    }}
                                                ><i className="fa fa-pencil"> </i></button>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        ) : null
                    }


                    {/*</div>*/}


                </div >
            </div >

            <AddMainUserEditRoles setShowUserRolesModal={setShowUserRolesModal} showUserRolesModal={showUserRolesModal} userData={userData}
                getMainUserById={getMainUserById} />
            <AssignUserSkillsModal setShowAssignUserSkillsModal={setShowAssignUserSkillsModal} showAssignUserSkillsModal={showAssignUserSkillsModal}
                getSkillsByUserId={getSkillsByUserId} userSkillList={userSkillList} userId={userId} />
            <AssignTransferCategoryModal showAssignTransferCategory={showAssignTransferCategory} setShowAssignTransferCategory={setShowAssignTransferCategory}
                userId={userId} getTellerTransferCategories={getTellerTransferCategories} userBid={userBid} />
            <AssignNotificationAccessibilityModal
                showAssignNotificationAccessibility={showAssignNotificationAccessibility}
                setShowAssignNotificationAccessibility={setShowAssignNotificationAccessibility}
                userId={userId} getassignedaccesibiltiesbyuser={getassignedaccesibiltiesbyuser} userBid={userBid} />
        </>
    )
}

// export default EditMainUser;

const mapStateToProps = state => ({
    userPermissions: state.settings.userPermissions,
})

const mapDispatchToProps = dispatch => ({
    setUserPermissions: (e) => dispatch(setUserPermissions(e)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditMainUser);