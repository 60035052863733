import React, { useState } from 'react'
import axios from 'axios';
import toast from "react-hot-toast";
import Button from 'react-bootstrap-button-loader';

import { useEffect } from 'react';

const AddTsuProfileModal = ({ showAddTsuProfileModal, setShowAddTsuProfileModal, getAllCallingPadProfileByCustomer, selectedRow, setSelectedRow, modalType }) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [buttonLoader, setButtonLoader] = useState(false);

    const [name, setName] = useState('');



    // if modal type is edit, set value
    useEffect(() => {
        if (modalType === 1) {
            setName(selectedRow.name)
        }
    }, [selectedRow]);


    // AddCallingPadProfile - function
    const AddCallingPadProfile = () => {

        setButtonLoader(true);

        let isValidate = validate();

        if (isValidate) {
            axios({
                method: 'POST',
                url: `${baseUrl}/branch/calling-pad-profile/add/customer/${userDetails.cid}`,
                headers: {
                    Authorization: token
                },
                data: {
                    name: name,
                }
            }).then((res) => {

                console.log('AddCallingPadProfile success');
                let data = res.data.data;

                toast.remove();
                toast.success('Calling Pad Profile added', {
                    position: 'bottom-center',
                });

                getAllCallingPadProfileByCustomer();
                setButtonLoader(false);
                closeModal();

            }).catch((error) => {
                console.log(error);
                setButtonLoader(false);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }
            })
        }
    }

    //valiadte - function
    const validate = () => {

        if (name.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Profile name empty', {
                position: 'bottom-center',
            });
            return false;
        } else {
            return true;
        }
    }

    //RenameCallingPadProfile - function
    const RenameCallingPadProfile = () => {

        setButtonLoader(true);

        let isValidate = validate();

        if (isValidate) {
            axios({
                method: 'POST',
                url: `${baseUrl}/branch/calling-pad-profile/update/profile/${selectedRow.id}`,
                headers: {
                    Authorization: token
                },
                data: {
                    name: name
                }

            }).then((res) => {

                let dataList = res.data;
                console.log(dataList);

                getAllCallingPadProfileByCustomer();
                setButtonLoader(false);
                closeModal();

            }).catch((error) => {
                console.log(error);
                setButtonLoader(false);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });

                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }
            })
        }

    }

    // close modal
    const closeModal = () => {
        setName('');
        setSelectedRow({});
        setShowAddTsuProfileModal(false);
    }

    return (
        <>
            {showAddTsuProfileModal ?
                <div className={`modal fade bd-example-modal-sm d-block show`} >
                    <div className="modal-dialog modal-sm" role="document">
                        <div className="modal-content">

                            <div className="modal-header">

                                {
                                    modalType === 0 ? (
                                        <h5 className="modal-title" id="exampleModalLabel">Add TSU Profile</h5>
                                    ) : (
                                        <h5 className="modal-title" id="exampleModalLabel">Edit TSU Profile</h5>
                                    )
                                }

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="input-group input-group-sm mb-3 modal-input-group">

                                    {
                                        modalType === 0 ? (
                                            <p className='input-field-side-text'>Profile Name : </p>
                                        ) : (
                                            <p className='input-field-side-text'>Edit Name : </p>
                                        )
                                    }

                                    <input autoFocus type="text" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value);
                                        }}
                                    />

                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >Close</button>

                                {
                                    modalType === 0 ? (
                                        <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                            onClick={() => {
                                                AddCallingPadProfile();
                                            }}
                                        >Add New</Button>
                                    ) : (
                                        <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                            onClick={() => {
                                                RenameCallingPadProfile();
                                            }}
                                        >Edit Name</Button>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
                : ''
            }
        </>
    )
}

export default AddTsuProfileModal