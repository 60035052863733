import axios from "axios";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';


const AssignTransferCategoryModal = ({ showAssignTransferCategory, setShowAssignTransferCategory, userId, getTellerTransferCategories, userBid }) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');

    const [refresh, setRefresh] = useState(1);
    const [allcategories, setAllCategories] = useState([]);

    const [allSelected, setAllSelected] = useState(false);


    //getUserTransferCategory - useeffect
    useEffect(() => {
        if (showAssignTransferCategory) {
            getUserTransferCategory();
        }
    }, [showAssignTransferCategory])


    //when get all categoies call checkAllSelectedOrNot - useEffect
    useEffect(() => {
        if (allcategories.length > 0) {
            checkAllSelectedOrNot()
        }
    }, [allcategories])



    //getUserTransferCategory - function
    const getUserTransferCategory = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/user/transfer/get-list/branch/${userBid}/user/${userId}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {

            let dataList = res.data.data;
            setAllCategories(dataList);




        }).catch((error) => {

            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //addUserTransfer - function
    const addUserTransfer = () => {

        let selectedCategoryId = filterSelectedTransferCategories();

        axios({
            method: 'POST',
            url: `${baseUrl}/user/transfer/${userId}`,
            headers: {
                Authorization: token,
            },
            data: {
                categoryList: selectedCategoryId,
            }

        }).then((res) => {

            toast.remove();
            toast.success('Transfer Category successfully Save!', {
                position: 'bottom-center',
            });
            getTellerTransferCategories();
            console.log('-----', selectedCategoryId);
            closeModal();

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //filterSelectedTransferCategories - function
    const filterSelectedTransferCategories = () => {

        let selectedCategoryId = [];
        for (let i = 0; i < allcategories.length; i++) {
            if (allcategories[i].selected === true) {
                selectedCategoryId.push(allcategories[i].categoryId)
            }
        }
        return selectedCategoryId;

    }

    //closeModal - function
    const closeModal = () => {
        allcategories.forEach((element, i) => {
            element.selected = false;
        });
        setAllSelected(false);
        setShowAssignTransferCategory(false)
    }

    //select all categories - function
    const selectAllCategories = (checked) => {
        if (checked) {
            allcategories.forEach((element, i) => {
                element.selected = true;
            });
            setAllSelected(true);
        } else {
            allcategories.forEach((element, i) => {
                element.selected = false;
            });
            setAllSelected(false);
        }
        setRefresh(refresh + 1)
    }

    //check all selected or not - function
    const checkAllSelectedOrNot = () => {

        for (let i = 0; i < allcategories.length; i++) {
            const element = allcategories[i];

            if (element.selected === false) {
                setAllSelected(false)
                return;
            } else {
                setAllSelected(true)
            }

        }

    }


    return (
        <>
            <div><Toaster /></div>
            {showAssignTransferCategory ?
                <div className={`modal fade  d-block show modal-container`} id="exampleModal">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Assign Transfer Category</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        closeModal()
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                {
                                    allcategories.length > 0 ? (
                                        <div className="row">
                                            <div className="col-12">
                                                <div class="selecte-all-container mb-3 float-right">
                                                    <p className="mb-0 m-r-15">Select All : </p>
                                                    <label
                                                        className="switch custom-swtch" title="Select All">
                                                        <input type="checkbox"
                                                            checked={allSelected ? true : false}
                                                            onChange={(e) => {
                                                                selectAllCategories(e.target.checked);
                                                            }}

                                                        />
                                                        <span className='slider'
                                                        > Select All</span>

                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null
                                }

                                <div className="row clearfix">
                                    {
                                        allcategories.length > 0 ? (
                                            allcategories.map((categories, i) => (
                                                <div className='col-lg-6 col-md-6 mb-2' key={i}>
                                                    <div
                                                        className={`list-group-item d-flex justify-content-between align-items-center cursor-pointer ${categories.selected ? 'active' : ''}`}
                                                        onClick={() => {
                                                            categories.selected = !categories.selected;
                                                            setRefresh(refresh + 1);
                                                            checkAllSelectedOrNot();
                                                        }}
                                                    >
                                                        {categories.category}
                                                    </div>
                                                </div>
                                            ))

                                        ) : (
                                            <div className="col-md-12">
                                                <div className="alert alert-custom alert-dismissible" role="alert">
                                                    <i className="fa fa-info-circle"></i> Empty records
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>

                            </div>
                            <div className="modal-footer">

                                <button type="button" className="btn btn-default"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >Cancel
                                </button>

                                <button type="button" className="btn btn-primary"
                                    onClick={() => {
                                        console.log('88888');
                                        addUserTransfer();
                                    }}
                                >Save
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
                : ''}
        </>
    )
}

export default AssignTransferCategoryModal