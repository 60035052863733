import React, { useState, useEffect } from 'react'
import 'rc-time-picker/assets/index.css';


// used for add and edit
const SubCategoriesDetailsViewModal = ({ showSubCategoriesModal, setShowSubCategoriesModal, selectedSubCategory, }) => {

    const [baseUrl, setBaseUrl] = useState('');
    const [token, setToken] = useState('');
    const [userDetails, setUserDetails] = useState('');


    //get base url effect
    useEffect(() => {
        if (showSubCategoriesModal) {
            setBaseUrl(localStorage.getItem('API_URL'));
            setToken(localStorage.getItem('user_token'));
            setUserDetails(JSON.parse(localStorage.getItem('user_details')))
        }
    }, [showSubCategoriesModal])

    //closeModal - function
    const closeModal = () => {
        setShowSubCategoriesModal(false);
    }

    return (
        <>
            {showSubCategoriesModal ? (
                <div className={`modal fade d-block show modal-container`} id="category-modal">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Select sub category
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        closeModal()
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>


                            <div className="modal-body">
                                {Object.keys(selectedSubCategory).length > 0 ? (
                                    <div className="">
                                        <div className="body">
                                            <div className="row text-center">
                                                <div className="col-6 border-right pb-4 pt-4">
                                                    <label className="mb-0">Wait time exception</label>
                                                    <h4 className="font-24 font-weight-bold text-col-blue">{selectedSubCategory.waitTime}</h4>
                                                </div>
                                                <div className="col-6 pb-4 pt-4">
                                                    <label className="mb-0">Service time exception</label>
                                                    <h4 className="font-24 font-weight-bold text-col-blue">{selectedSubCategory.serviceTime}</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="body">
                                            <div className="form-group">
                                                <label className="d-block">Category Code <span
                                                    className="float-right">{selectedSubCategory.categoryCode}</span></label>
                                                <div className="progress progress-xxs">
                                                    <div className="progress-bar progress-bar-success" role="progressbar"
                                                        aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                                                        style={{ width: "100%" }}></div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="d-block">Category Name <span
                                                    className="float-right">{selectedSubCategory.category}</span></label>
                                                <div className="progress progress-xxs">
                                                    <div className="progress-bar progress-bar-warning" role="progressbar"
                                                        aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                                                        style={{ width: "100%" }}></div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="d-block">Short Category Name <span
                                                    className="float-right">{selectedSubCategory.shortCatName}</span></label>
                                                <div className="progress progress-xxs">
                                                    <div className="progress-bar progress-bar-success" role="progressbar"
                                                        aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                                                        style={{ width: "100%" }}></div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="d-block">Default start ticket <span
                                                    className="float-right">{selectedSubCategory.noRangeMin}</span></label>
                                                <div className="progress progress-xxs">
                                                    <div className="progress-bar progress-bar-warning" role="progressbar"
                                                        aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                                                        style={{ width: "100%" }}></div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="d-block">Default end ticket
                                                    <span className="float-right">{selectedSubCategory.noRangeMax}</span>
                                                </label>
                                                <div className="progress progress-xxs">
                                                    <div className="progress-bar progress-bar-success" role="progressbar"
                                                        aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                                                        style={{ width: "100%" }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                        <div className="card">
                                            <div className="body">
                                                <div className="row text-center">
                                                    <div className="col-12 pb-4 pt-4">
                                                        <label className="mb-0">View sub category details</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </div>

                            <div className='modal-footer'>
                                <div className="col-md-3 col-sm-12">
                                    <button type="button" className="btn btn-default"
                                        onClick={() => {
                                            closeModal();
                                        }}
                                    >Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null
            }
        </>
    )
}

export default SubCategoriesDetailsViewModal;