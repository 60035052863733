import React, { useState, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import PersentageDonutChartWithBottomLegend from '../ChartsImpl/DetailsPageCharts/PersentageDonutChartWithBottomLegend';
import ProcessChartWithBottomLegend from '../ChartsImpl/DetailsPageCharts/ProcessChartWithBottomLegend';
import { MDBDataTable } from 'mdbreact';
import toast, { Toaster } from "react-hot-toast";
import MUIDataTable from 'mui-datatables';
import CircularProgress from "@mui/material/CircularProgress";
import axios from 'axios';

const BranchTableTicketServedModal = (props) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');

    const [chartValue, setChartValue] = useState(0);

    const [tokenData, settokenData] = useState([]);
    const [servedCountData, setServedCountData] = useState([]);
    const [chartData, setchartData] = useState([]);

    const [tokenTableData, setTokenTableData] = useState([]);
    const [categoiesForRender, setCategoiesForRender] = useState([])

    const [categoryList, setcategoryList] = useState([]);
    const [percentageList, setpercentageList] = useState([]);

    const [searchCategoryText, setSearchCategoryText] = useState('');

    const [isLoading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        let postsArray = JSON.parse(JSON.stringify(tokenData));
        let categoryData = [];
        postsArray.map((item, index) => {

            item.index = (
                <div>
                    {index + 1}
                </div>
            )

            if (item.status === 0) {
                item.status = "Waiting"
            } else if (item.status === 1) {
                item.status = "Serving"
            } else if (item.status === 2) {
                item.status = "No show"
            } else if (item.status === 3) {
                item.status = "Local waiting"
            } else if (item.status === 4) {
                item.status = "Served"
            }

            categoryData.push(item);
        });
        setTokenTableData(categoryData);
        setCategoiesForRender(categoryData);
    }, [tokenData]);

    useEffect(() => {
        if (props.showOperatorStatusDetails === false) return;
        if (props.servedTicketsInc > 0 || props.messageInc1001 !== '') {
            getServedTicketsData();
        }
    }, [props.servedTicketsInc, props.messageInc1001]);

    useEffect(() => {
        setChartValue(chartValue + 1);
    }, []);

    const getServedTicketsData = () => {
        console.log('getServedTicketsData function start');

        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/get-category-served/inner-data/branch/${props.selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('getServedTicketsData success');
            let data = res.data.data;
            loopChartData(data.percentages);

            if (data.tokenData !== []) {
                settokenData(data.tokenData);
                setCount(data.tokenData.length);
            }

            if (data.chartData !== []) {
                setchartData(data.percentages);
            }

            if (data.percentages !== []) {
                setServedCountData(data.percentages)
            }

            setChartValue(chartValue + 1);

        }).catch((error) => {
            console.log('getTicketIssuedDetails error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    const loopChartData = (data) => {
        const categories = [];
        const percentages = [];

        console.log('+++++', data);
        data.forEach(element => {
            categories.push(element.category);
            percentages.push(element.percentage);
        });

        setcategoryList(categories);
        setpercentageList(percentages);
    }

    const options = {
        tableBodyHeight: '50vh',
        filter: false,
        responsive: 'vertical',
        serverSide: false,
        count: count,
        download: false,
        viewColumns: false,
        jumpToPage: true,
        selectableRows: 'none',
        print: false,
        rowsPerPage: rowsPerPage,
        onChangeRowsPerPage: (n) => {
            setRowsPerPage(n)
        },
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        }
    };

    const columns = [
        {
            label: 'Token No',
            name: 'tokenNo',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: "Category",
            name: 'category',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Language',
            name: 'language',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'Operator',
            name: 'operator',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Called time',
            name: 'calledTime',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'Service time',
            name: 'serviceTime',
            options: {
                filter: false,
                sort: true,
            }
        }
    ];

    const columns_1 = [
        {
            label: "Branch",
            name: 'branchName',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Token No',
            name: 'tokenNo',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: "Category",
            name: 'category',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Language',
            name: 'language',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'Operator',
            name: 'operator',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Called time',
            name: 'calledTime',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'Service time',
            name: 'serviceTime',
            options: {
                filter: false,
                sort: true,
            }
        }
    ];

    return (
        <>
            {props.showTicketServedDetails ?
                <div id="megamenu" className={`megamenu particles_js details-page open `}>
                    <span
                        onClick={() => {
                            props.setShowTicketServedDetails(false);
                        }}
                        className="megamenu_toggle btn btn-danger"><i className="icon-close"></i></span>
                    <div className="container">
                        <div className="row clearfix">
                            <div className="col-12">
                                <div className="card">
                                    <div className="body">
                                        <div style={{ textAlign: 'center' }}>
                                            <h4>Served Tickets Data</h4>
                                            <small>{props.date}</small>
                                        </div>
                                        <br></br>
                                        <div className="row clearfix">
                                            <div className="col-4">

                                                {
                                                    chartData.length !== 0 && chartValue > 0 ? <PersentageDonutChartWithBottomLegend
                                                        lable={categoryList} percentage={percentageList} chartValue={chartValue} />
                                                        :
                                                        <PersentageDonutChartWithBottomLegend
                                                            lable={[]} percentage={[]} chartValue={0} />
                                                }

                                                <div className='mt-4'>
                                                    <div style={{ overflowY: 'scroll', height: '200px' }}>
                                                        <table className="table table-hover table-custom">
                                                            <tbody>
                                                                {servedCountData.map((data, i) =>
                                                                    <tr key={i}>
                                                                        <td>{data.category}</td>
                                                                        <td>{data.ticketCount}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-8">
                                                <div className="muidatatablecontainer">
                                                    {props.selectedBranchLevel === 4 ? (
                                                        <MUIDataTable
                                                            title={<>
                                                                {count}{count === 1 ? ' record' : ' records'}.
                                                                {isLoading &&
                                                                    <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                                                        size={20} /></>}
                                                            </>
                                                            }
                                                            data={tokenData}
                                                            columns={columns}
                                                            options={options}
                                                        />
                                                    ) : (
                                                        <MUIDataTable
                                                            title={<>
                                                                {count}{count === 1 ? ' record' : ' records'}.
                                                                {isLoading &&
                                                                    <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                                                        size={20} /></>}
                                                            </>
                                                            }
                                                            data={tokenData}
                                                            columns={columns_1}
                                                            options={options}
                                                        />
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div id="particles-js"></div>
                </div>
                : null}
        </>
    )
}

export default BranchTableTicketServedModal