import React from 'react'

import Barchart from '../../ChartsImpl/Analytics/CategoryAnalyticsBarchart';

const TokenExceptionBehavior = () => {
    return (
        <div className="col-xl-6 col-lg-6 col-md-6">
            <div className="card">
                <div className="body">
                    <h6 className="mb-4 font300">Weekly Token-Exception Behavior (This Week)</h6>
                    {/* <h3 className="mb-0 mt-3 font300">24,301 <span className="text-green font-15">+3.7%</span></h3>
                                                <small>The total number of Page Impressions within the date range</small> */}
                    <Barchart />
                </div>
            </div>
        </div>
    )
}

export default TokenExceptionBehavior