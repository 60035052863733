import React, { useState, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import PersentageDonutChartWithBottomLegend from '../ChartsImpl/DetailsPageCharts/PersentageDonutChartWithBottomLegend';
import ProcessChartWithBottomLegend from '../ChartsImpl/DetailsPageCharts/ProcessChartWithBottomLegend';
import MUIDataTable from 'mui-datatables';
import CircularProgress from "@mui/material/CircularProgress";
import { MDBDataTable } from 'mdbreact';
import toast, { Toaster } from "react-hot-toast";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import DoNotStepIcon from '@mui/icons-material/DoNotStep';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';

const BranchTableIssuedTicketModal = (props) => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');

    const [chartValue, setChartValue] = useState(0);

    const [tokenData, settokenData] = useState([]);

    const [isLoading, setLoading] = useState(false);
    const [showTimeLine, setShowTimeLine] = useState(false)

    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [tokenTableData, setTokenTableData] = useState([]);
    const [categoiesForRender, setCategoiesForRender] = useState([])

    const [ticketNumber, setTicketNumber] = useState(null)

    const [issuedCountData, setissuedCountData] = useState([]);
    const [chartData, setchartData] = useState([]);

    const [categoryList, setcategoryList] = useState([]);
    const [percentageList, setpercentageList] = useState([]);

    const [list, setList] = useState([]);

    const [update, setUpdate] = useState('1s')

    useEffect(() => {
        let postsArray = JSON.parse(JSON.stringify(tokenData));
        let categoryData = [];
        postsArray.map((item, index) => {

            if (item.category !== null) {
                item.index = (
                    <div>
                        {index + 1}
                    </div>
                )

                if (item.status === 0) {
                    item.status = "Waiting"
                } else if (item.status === 1) {
                    item.status = "Serving"
                } else if (item.status === 2) {
                    item.status = "No show"
                } else if (item.status === 3) {
                    item.status = "Local waiting"
                } else if (item.status === 4) {
                    item.status = "Served"
                }

                categoryData.push(item);
            }
        });
        setTokenTableData(categoryData);
        setCategoiesForRender(categoryData);
    }, [tokenData]);

    //getticketIssued - useeffect
    useEffect(() => {
        if (props.showOperatorStatusDetails === false) return;
        if (props.ticketIssuedInc > 0 || props.messageInc1000 !== ''
            || props.messageInc1001 !== '' || props.messageInc2000 !== '' || props.messageInc2001 !== ''
            || props.messageInc3000 !== '' || props.messageInc3001 !== '' || props.message4000 !== ''
            || props.messageInc9999 !== '' || props.messageInc1002 !== '' || props.messageInc1011 !== '') {
            getticketIssued();
        }
    }, [props.ticketIssuedInc, props.messageInc1001, props.messageInc1000, props.messageInc2000, props.messageInc2001, props.messageInc1011,
    props.messageInc3000, props.messageInc3001, props.message4000, props.messageInc9999, props.messageInc1002]);


    //chartvalue - useeffect
    useEffect(() => {
        setChartValue(chartValue + 1);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (tokenData !== null && tokenData.length > 0) {
                for (let index = 0; index < tokenData.length; index++) {
                    const element = tokenData[index];

                    if (element.status === 1) {
                        element.timeSpendingToSec = element.timeSpendingToSec + 5;

                        let hours = Math.floor(element.timeSpendingToSec / 3600);
                        let minutes = Math.floor((element.timeSpendingToSec - (hours * 3600)) / 60);
                        let seconds = element.timeSpendingToSec - (hours * 3600) - (minutes * 60);

                        if (hours < 10) { hours = "0" + hours; }
                        if (minutes < 10) { minutes = "0" + minutes; }
                        if (seconds < 10) { seconds = "0" + seconds; }

                        element.timeSpending = `${hours}:${minutes}:${seconds}`;
                    }

                    if (element.status === -1) {
                        element.timeSpendingToSecTransfer = element.timeSpendingToSecTransfer + 5;

                        let hours = Math.floor(element.timeSpendingToSecTransfer / 3600);
                        let minutes = Math.floor((element.timeSpendingToSecTransfer - (hours * 3600)) / 60);
                        let seconds = element.timeSpendingToSecTransfer - (hours * 3600) - (minutes * 60);

                        if (hours < 10) { hours = "0" + hours; }
                        if (minutes < 10) { minutes = "0" + minutes; }
                        if (seconds < 10) { seconds = "0" + seconds; }

                        element.timeSpendingTransfer = `${hours}:${minutes}:${seconds}`;
                    }
                }

                console.log("token data: ", tokenData);

                settokenData(tokenData);
                setUpdate(new Date().toString());
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [tokenData]);

    //getticketIssued - function
    const getticketIssued = () => {
        console.log('getticketIssued function start');

        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/get-category-issued/inner-data/branch/${props.selectedBranchId}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            console.log('getticketIssued success');
            let data = res.data.data;
            console.log('======', data);
            loopChartData(data.percentages);

            if (data.count !== []) {
                settokenData(data.count);
                setCount(data.count.length);
            }

            if (data.chartData !== []) {
                setchartData(data.percentages)
            }
            if (data.percentages !== []) {
                setissuedCountData(data.percentages)
            }

            setChartValue(chartValue + 1);

        }).catch((error) => {
            console.log('getticketIssued error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    const timeline = (tid) => {

        axios({
            method: 'POST',
            url: `${baseUrl}/ticket/timeline/${tid}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            const data = res.data.data;
            setList(data)

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    };

    //loopChartData - function
    const loopChartData = (data) => {
        const categories = [];
        const percentages = [];

        console.log(']]]]]]', data);
        data.forEach(element => {
            if (element.category !== null) {
                categories.push(element.category);
                percentages.push(element.percentage);
            }
        });

        setcategoryList(categories);
        setpercentageList(percentages);
    }


    //closeModal - function
    const closeModal = () => {
        props.setShowTicketIssuedData(false);

        settokenData([]);
        setchartData([]);
    }

    const options = {
        tableBodyHeight: '50vh',
        filter: false,
        responsive: 'vertical',
        serverSide: false,
        count: count,
        download: false,
        viewColumns: false,
        jumpToPage: true,
        selectableRows: 'none',
        print: false,
        rowsPerPage: rowsPerPage,
        onChangeRowsPerPage: (n) => {
            setRowsPerPage(n)
        },
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        }
    };

    const columns = [
        {
            label: 'Token No',
            name: 'ticketNumber',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: "Category",
            name: 'category',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Language',
            name: 'language',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'Issued Time',
            name: 'issuedTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Status',
            name: 'id',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    if (tokenData === undefined) return;
                    let item = tokenData.find(item => item.id === value);
                    return (<>
                        {item.category !== null ? (
                            <>
                                {item.status === 1 ? (<span className="badge badge-info ml-1 mb-2">{item.timeSpending}</span>)
                                    : item.status === 2 ? (<span className="badge badge-warning ml-1 mb-2">no show</span>)
                                        : item.status === 3 ? (<span className="badge badge-warning ml-1 mb-2">waiting</span>)
                                            : item.status === 4 ? (<span className="badge badge-success ml-1 mb-2">served</span>)
                                                : item.status === -1 ? (<span className="badge badge-primary ml-1 mb-2">transfer</span>)
                                                    : item.status === 5 ? (<div><span className="badge badge-success ml-1 mb-2"><small className="text-info">t</small>- served</span></div>)
                                                        : item.status === 6 ? (<div><span className="badge badge-info ml-1 mb-2"><small className="text-info">t</small>- {item.timeSpending}</span></div>)
                                                            : (<span className="badge badge-default ml-1 mb-2">pending</span>)}
                            </>
                        ) : (null)}
                    </>
                    );
                }
            },
        },
        {
            label: 'Time Line',
            name: 'id',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    let item = tokenData.find(item => item.id === value);
                    console.log("item: ", item);
                    return (
                        <div style={{ cursor: 'pointer' }} onClick={() => { timeline(item.ticketId); setTicketNumber(item.ticketNumber); setShowTimeLine(true) }}>
                            <i className="fa fa-long-arrow-right" aria-hidden="true" />
                        </div>
                    )
                }
            }
        }
    ];

    const columns_1 = [
        {
            label: "Branch",
            name: 'branchName',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Token No',
            name: 'ticketNumber',
            options: {
                sort: true,
                filter: false
            }
        },
        {
            label: "Category",
            name: 'category',
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            label: 'Language',
            name: 'language',
            options: {
                filter: false,
                sort: true
            }
        },
        {
            label: 'Issued Time',
            name: 'issuedTime',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            label: 'Status',
            name: 'id',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    if (tokenData === undefined) return;
                    let item = tokenData.find(item => item.id === value);
                    return (<>
                        {item.category !== null ? (
                            <>
                                {item.status === 1 ? (<span className="badge badge-info ml-1 mb-2">{item.timeSpending}</span>)
                                    : item.status === 2 ? (<span className="badge badge-warning ml-1 mb-2">no show</span>)
                                        : item.status === 3 ? (<span className="badge badge-warning ml-1 mb-2">waiting</span>)
                                            : item.status === 4 ? (<span className="badge badge-success ml-1 mb-2">served</span>)
                                                : item.status === -1 ? (<span className="badge badge-primary ml-1 mb-2">transfer</span>)
                                                    : item.status === 5 ? (<div><span className="badge badge-success ml-1 mb-2"><small className="text-info">t</small>- served</span></div>)
                                                        : (<span className="badge badge-default ml-1 mb-2">pending</span>)}
                            </>
                        ) : (null)}
                    </>
                    );
                }
            },
        },
        {
            label: 'Time Line',
            name: 'id',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    let item = tokenData.find(item => item.id === value);
                    console.log("item: ", item);
                    return (
                        <div style={{ cursor: 'pointer' }} onClick={() => { timeline(item.ticketId); setTicketNumber(item.ticketNumber); setShowTimeLine(true) }}>
                            <i className="fa fa-long-arrow-right" aria-hidden="true" />
                        </div>
                    )
                }
            }
        }
    ];
    return (
        <>
            {props.showTicketIssuedData ?
                <div id="megamenu" className={`megamenu particles_js details-page open`}>
                    <span
                        onClick={() => {
                            props.setShowTicketIssuedData(false);
                            setShowTimeLine(false);
                        }}
                        className="megamenu_toggle btn btn-danger"><i className="icon-close"></i></span>
                    <div className="container">
                        <div className="row clearfix">
                            <div className="col-12">
                                <div className="card">
                                    <div className="body">

                                        <div style={{ textAlign: 'center' }}>
                                            <h4>Issued Tickets Data</h4>
                                            <small>{props.date}</small>
                                        </div>

                                        <br></br>

                                        <div className="row clearfix">
                                            <div className="col-4">

                                                {
                                                    chartData.length !== 0 && chartValue > 0 ? <PersentageDonutChartWithBottomLegend
                                                        lable={categoryList} percentage={percentageList} chartValue={chartValue} />
                                                        :
                                                        <PersentageDonutChartWithBottomLegend
                                                            lable={[]} percentage={[]} chartValue={0} />
                                                }

                                                <div className='mt-4'>
                                                    <div style={{ overflowY: 'scroll', height: '200px' }}>
                                                        <table className="table table-hover table-custom">
                                                            <tbody>
                                                                {issuedCountData.map((data, i) =>
                                                                    <>
                                                                        {data.category !== null ? (
                                                                            <tr key={i}>
                                                                                <td>{data.category}</td>
                                                                                <td>{data.ticketCount}</td>
                                                                            </tr>
                                                                        ) :
                                                                            (null)}
                                                                    </>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-8">

                                                {showTimeLine ? (
                                                    <div className="muidatatablecontainer">
                                                        <h6 className='text-center'>Ticket Number: {ticketNumber}</h6>
                                                        <Timeline position="">
                                                            {
                                                                list.length > 0 ?
                                                                    (<TimelineItem>
                                                                        <TimelineOppositeContent
                                                                            sx={{ m: 'auto 0' }}
                                                                            align="right"
                                                                            variant="body2"
                                                                        >
                                                                            <b>{list[0].issuedTime}</b>
                                                                        </TimelineOppositeContent>
                                                                        <TimelineSeparator style={{ height: '95px' }}>
                                                                            <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                                                                            <TimelineDot color={"secondary"}>
                                                                                <ConfirmationNumberIcon />
                                                                            </TimelineDot>
                                                                            <TimelineConnector />
                                                                        </TimelineSeparator>
                                                                        <TimelineContent sx={{ py: '1px', px: 2, m: 'auto 0' }}>
                                                                            <Typography>{list[0].category}</Typography>
                                                                        </TimelineContent>
                                                                    </TimelineItem>
                                                                    ) : '.'
                                                            }
                                                            {
                                                                list.map((item) =>
                                                                    <TimelineItem>
                                                                        <TimelineOppositeContent
                                                                            sx={{ m: 'auto 0' }}
                                                                            align="right"
                                                                            variant="body2"
                                                                        >
                                                                            {item.counterNo !== undefined ? (
                                                                                <Typography>Counter {item.counterNo}</Typography>
                                                                            ) : (
                                                                                <Typography>{item.issuedTime}</Typography>
                                                                            )}
                                                                            <b>{item.startTime}</b> {item.endTime !== undefined ? <>- <b>{item.endTime}</b></> : ''}
                                                                        </TimelineOppositeContent>
                                                                        <TimelineSeparator style={{ height: '100px' }}>
                                                                            <TimelineConnector />
                                                                            <TimelineDot
                                                                                color={item.status === undefined ? "grey" : item.status === 1 ? "primary" : item.status === 2 ? "error" : item.status === 3 ? "info" : "success"}>
                                                                                {item.status === undefined ? <ConfirmationNumberIcon /> :
                                                                                    item.status === 1 ? <RoomServiceIcon /> :
                                                                                        item.status === 2 ? <DoNotStepIcon /> :
                                                                                            item.status === 3 ? <PauseCircleFilledIcon /> :
                                                                                                item.status === 4 ? <CheckCircleIcon /> : ''}
                                                                            </TimelineDot>
                                                                            <TimelineConnector />
                                                                        </TimelineSeparator>
                                                                        <TimelineContent sx={{ py: '1px', px: 2, m: 'auto 0' }}>
                                                                            <Typography variant="h6" component="span">
                                                                                {item.category}
                                                                            </Typography>
                                                                            <Typography>{item.teller}</Typography>
                                                                        </TimelineContent>
                                                                    </TimelineItem>
                                                                )
                                                            }
                                                        </Timeline>
                                                        <button className='btn btn-default float-right' onClick={() => setShowTimeLine(false)}> back to table </button>
                                                    </div>
                                                ) : (
                                                    <div className="muidatatablecontainer">
                                                        {props.selectedBranchLevel === 4 ? (
                                                            <MUIDataTable
                                                                title={<>
                                                                    {count}{count === 1 ? ' record' : ' records'}.
                                                                    {isLoading &&
                                                                        <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                                                            size={10} /></>}
                                                                </>
                                                                }
                                                                data={tokenData}
                                                                columns={columns}
                                                                options={options}
                                                            />
                                                        ) : (
                                                            <MUIDataTable
                                                                title={<>
                                                                    {count}{count === 1 ? ' record' : ' records'}.
                                                                    {isLoading &&
                                                                        <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                                                            size={10} /></>}
                                                                </>
                                                                }
                                                                data={tokenData}
                                                                columns={columns_1}
                                                                options={options}
                                                            />
                                                        )}
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div id="particles-js"></div>
                </div>
                : null}
        </>
    )
}

export default BranchTableIssuedTicketModal