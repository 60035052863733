import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import toast, { Toaster } from "react-hot-toast";
import Accordion from 'react-bootstrap/Accordion';
import Nestable from 'react-nestable';
import Swal from "sweetalert2";
import { Link } from 'react-router-dom';

import { Stage, Layer, Image, Line } from 'react-konva';
import useImage from 'use-image';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss'
import URLImage from '../ComponentsImpl/ReactKonva/URLImage';
import Rectangle from '../ComponentsImpl/ReactKonva/Rectangle';
import TextShape from '../ComponentsImpl/ReactKonva/TextShape';
import TextShapeMini from '../ComponentsImpl/ReactKonva/TextShapeMini';
import URLImageMini from '../ComponentsImpl/ReactKonva/URLImageMini';
import RectangleMini from '../ComponentsImpl/ReactKonva/RectangleMini';

import { setMiniSidebar, setMiniSidebarMenuOn } from '../../actions/settingsAction';
import { connect } from 'react-redux';
import AddKiosksScreenModal from '../ModalImpl/AddKiosksScreenModal';
import { legacy_createStore } from 'redux';
import { data } from 'jquery';

let intervalID = null;

const DesignKiosksTemplate = ({ setMiniSidebar, setHorizontalMenu, miniSidebar, setMiniSidebarMenuOn, miniSideMenuOn, }) => {

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  let screenRenameText = '';

  const baseUrl = localStorage.getItem('API_URL');
  const token = localStorage.getItem('user_token');
  const userDetails = JSON.parse(localStorage.getItem('user_details'));
  const selectedBranch = JSON.parse(localStorage.getItem('selected_branch'));
  const templateId = params.get('t_id');

  // template
  const [template, setTemplate] = useState({});

  // for screens list
  const [allScreensList, setAllScreensList] = useState([]);
  const [allScreensForRender, setAllScreensForRender] = useState([]);
  const [selectedScreen, setSelectedScreen] = useState();

  const [newScreenIndex, setNewScreenIndex] = useState();
  const [showAddScreenModal, setShowAddKiosksScreenModal] = useState(false);

  // for canvas
  const [dropCount, setDropCount] = useState(0);
  const [savingProgressCount, setSavingProgressCount] = useState(false);


  // for tool menu
  const [backgroundImagesList, setBackgroundImagesList] = useState([]);
  const [btnShapesImagesList, setBtnShapesImages] = useState([]);
  const [labelImagesList, setLabelImagesList] = useState([]);
  const [logoImagesList, setLogoImagesList] = useState([]);

  const [languagesList, setLanguagesList] = useState([]);
  const [selectedLang, setSelectedLang] = useState('');

  const [layersListForRender, setLayersListForRender] = useState([]);
  const [showAdvanceView, setShowAdvanceView] = useState(false);

  const [bgimgIsFirst, setBgimgIsFirst] = useState(true);
  const [btnShapesIsFirst, setBtnShapesIsFirst] = useState(true);
  const [labelImgIsFirst, setLabelImgIsFirst] = useState(true);
  const [logoIsFirst, setLogoIsFirst] = useState(true);

  const [showProgress, setShowProgress] = useState(false);
  const [progressCount, setProgressCount] = useState('');
  const [progressType, setProgressType] = useState();   // 0 - add, 1 - delete

  const [btnActionPagesList, setBtnActionPagesList] = useState([]);
  const [btnActionCateList, setBtnActionCateList] = useState([]);
  const [btnActionRadio, setBtnActionRadio] = useState('page');
  const [selectedOtherAction, setSelectedOtherAction] = useState();


  // others
  const [refresh, setRefresh] = useState(0);
  const renderItemsPlanned = ({ item }) => item.text;


  // ------------- react konva management -------------

  const grid = 2;
  const [gridWidth, setGridWidth] = useState();
  const [gridHeight, setGridHeight] = useState();

  const linesA = [];
  const linesB = [];

  const dragUrl = React.useRef();
  const dragDimens = React.useRef();
  const dragType = React.useRef();
  const dragImgId = React.useRef();
  const dragImgType = React.useRef();
  const stageRef = React.useRef();

  const [rectangles, setRectangles] = React.useState([]);
  const [selectedComponentId, setSelectedComponentId] = React.useState(null);
  const [selectedComponent, setSelectedComponent] = React.useState(null);
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
  const [selectedColorHex, setColorHex] = React.useState('#F17013');
  const [selectedColor, setColor] = React.useState({
    r: '241',
    g: '112',
    b: '19',
    a: '1'
  });
  const [selectedBgImg, setSelectedBgImg] = React.useState(null);
  const [copiedComponent, setCopiedComponent] = useState(null);

  const [xCoordinate, setX] = React.useState(0);
  const [yCoordinate, setY] = React.useState(0);
  const [width, setW] = React.useState(0);
  const [height, setH] = React.useState(0);

  const [text, setText] = React.useState('');
  const [size, setTextSize] = React.useState(8);
  const [font, setFont] = React.useState('');

  const styles = reactCSS({
    'default': {
      color: {
        width: '55px',
        height: '18px',
        borderRadius: '2px',
        background: `rgba(${selectedColor.r}, ${selectedColor.g}, ${selectedColor.b}, ${selectedColor.a})`,
      },
      swatch: {
        borderRadius: '1',
        display: 'block',
        cursor: 'pointer'
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
        right: '0'
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  for (let i = 0; i < gridWidth / grid; i++) {
    linesA.push(
      <Line
        strokeWidth={0.5}
        stroke={'#e3e3e3'}
        points={[i * grid, 0, i * grid, gridHeight]}
      />
    );
  }

  for (let i = 0; i < gridHeight / grid; i++) {

    linesB.push(
      <Line
        strokeWidth={0.5}
        stroke={'#e3e3e3'}
        points={[0, i * grid, gridWidth, i * grid]}
      />
    )
  }

  const checkDeselect = (e) => {
    // deselect when clicked on empty area
    // const clickedOnEmpty = e.target === e.target.getStage();
    // if (clickedOnEmpty) {
    setSelectedComponentId(null);
    setSelectedComponent(null);
    // }
  };

  const BGImage = () => {
    const [bgImage] = useImage(selectedBgImg);
    return <Image image={bgImage}
      width={gridWidth}
      height={gridHeight}
    />;
  };

  const BGImageMini = ({ bgImg }) => {
    const [bgImage] = useImage(bgImg);
    return <Image image={bgImage}
      width={gridWidth / 3.5}
      height={gridHeight / 4}
    />;
  };

  const onChangeX = event => {
    let x = parseInt(event.target.value === '' ? 0 : event.target.value);

    let xCord = Math.max(Math.round(x / grid) * grid, 0);

    //calc min x for boundary exceeds
    if (xCord + selectedComponent.width > gridWidth)
      xCord = gridWidth - selectedComponent.width;

    if (xCord < 0)
      xCord = 0;

    setX(xCord);
  };

  const onChangeY = event => {
    let y = parseInt(event.target.value === '' ? 0 : event.target.value);

    let yCord = Math.max(Math.round(y / grid) * grid, 0);

    //calc min x for boundary exceeds
    if (yCord + selectedComponent.height > gridHeight)
      yCord = gridHeight - selectedComponent.height;

    if (yCord < 0)
      yCord = 0;

    setY(yCord);
  };

  const onChangeW = event => {
    let w = parseInt(event.target.value === '' ? grid : event.target.value);

    let width = Math.max(Math.round(Math.max(grid, w) / grid) * grid, grid);

    if (selectedComponent.x + width > gridWidth)
      return;

    setW(w);
  };

  const onChangeH = event => {
    let h = parseInt(event.target.value === '' ? grid : event.target.value);

    let height = Math.max(Math.round(Math.max(grid, h) / grid) * grid, grid);

    if (selectedComponent.y + height > gridHeight)
      return;

    setH(h);
  };

  const onChangeText = event => {
    setText(event.target.value);
  };

  const onChangeSize = event => {
    setTextSize(parseInt(event.target.value));
  };

  const onChangeFont = event => {
    setFont(event.target.value);
  };

  const changeTextAlign = (align) => {
    let tempRectangles = [...rectangles];
    const selectedText = tempRectangles.find(shape => {
      return shape.id === selectedComponentId;
    });
    if (selectedText !== undefined) {
      selectedText.textProps.align = align;

      setRectangles(tempRectangles)
    }
  };

  // handleCanvaKeyDown
  const handleCanvaKeyDown = (e) => {
    e.preventDefault();
    let charCode = String.fromCharCode(e.which).toLowerCase();

    if (e.keyCode === 46 || e.keyCode === 8) {
      deleteShape();
    } else if ((e.ctrlKey || e.metaKey) && charCode === 'c') {
      copyShape();
    } else if ((e.ctrlKey || e.metaKey) && charCode === 'v') {
      pasteShape();
    }
  }

  // delete shape
  const deleteShape = (e) => {
    if (selectedComponentId === null) return;

    const newList = rectangles.filter(shape => {
      return shape.id !== selectedComponentId;
    });
    setRectangles(newList);
    setSelectedComponent(null);

    setShowAdvanceView(false);
    checkDeselect();
    setSelectedComponentId(null);

  };

  let copiedShape = {}

  // copy shape
  const copyShape = (e) => {
    if (selectedComponentId === null) return;

    let tempRectangles = [...rectangles];
    const shape = tempRectangles.find(shape => {
      return shape.id === selectedComponentId;
    });

    copiedShape = {
      ...shape,
      id: shape.type + Math.floor(Math.random() * 999999999),
      x: shape.x + grid,
      y: shape.y + grid,
    };

    setCopiedComponent(copiedShape);
  };

  // paste shape
  const pasteShape = (e) => {
    console.log('copiedShape ', copiedShape);
    console.log('copiedComponent ', copiedComponent);
    setRectangles(
      rectangles.concat([copiedComponent])
    );
  };

  //color picker
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  //color picker
  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  //color picker
  const handleChange = (color) => {
    setColor(color.rgb);
    setColorHex(color.hex);
    console.info(color);

    if (selectedComponentId === null) return;

    let tempRectangles = [...rectangles];
    const shape = tempRectangles.find(shape => {
      return shape.id === selectedComponentId;
    });
    shape.fill = toRGBA(color.rgb);
    shape.hex = color.hex;
    shape.rgba = color.rgb;
    setRectangles(
      tempRectangles
    );
  };

  const toRGBA = (color) => {
    return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
  };


  React.useEffect(() => {
    if (selectedComponent !== null && selectedComponent !== undefined) {

      setShowAdvanceView(true);

      setX(parseInt(selectedComponent.x));
      setY(parseInt(selectedComponent.y));
      setW(parseInt(selectedComponent.width));
      setH(parseInt(selectedComponent.height));

      if (selectedComponent.rgba !== null && selectedComponent.rgba !== undefined)
        setColor(selectedComponent.rgba);
      if (selectedComponent.hex !== null && selectedComponent.hex !== undefined)
        setColorHex(selectedComponent.hex);
      if (selectedComponent.text !== null && selectedComponent.text !== undefined)
        setText(selectedComponent.text);
      if (selectedComponent.textProps !== null && selectedComponent.textProps !== undefined) {
        setTextSize(selectedComponent.textProps.fontSize);
        setFont(selectedComponent.textProps.fontFamily);
      }
    } else {
      setX(0);
      setY(0);
      setW(grid);
      setText('');
      setFont('');
      setTextSize(8);
      setH(grid);
    }
  }, [selectedComponent]);

  React.useEffect(() => {

    let tempRectangles = [...rectangles];
    const selected = tempRectangles.find(shape => {
      return shape.id === selectedComponentId;
    });
    if (selected !== undefined) {
      selected.x = parseInt(xCoordinate);

      setRectangles(tempRectangles);
      setSelectedComponent(selected);
    }

  }, [xCoordinate]);

  React.useEffect(() => {

    let tempRectangles = [...rectangles];
    const selected = tempRectangles.find(shape => {
      return shape.id === selectedComponentId;
    });
    if (selected !== undefined) {
      selected.y = parseInt(yCoordinate);

      setRectangles(tempRectangles);
      setSelectedComponent(selected);
    }

  }, [yCoordinate]);

  React.useEffect(() => {

    let tempRectangles = [...rectangles];
    const selected = tempRectangles.find(shape => {
      return shape.id === selectedComponentId;
    });
    if (selected !== undefined) {
      selected.width = parseInt(width);

      setRectangles(tempRectangles);
      setSelectedComponent(selected);
    }

  }, [width]);

  React.useEffect(() => {

    let tempRectangles = [...rectangles];
    const selected = tempRectangles.find(shape => {
      return shape.id === selectedComponentId;
    });
    if (selected !== undefined) {
      selected.height = parseInt(height);

      setRectangles(tempRectangles);
      setSelectedComponent(selected);
    }

  }, [height]);

  React.useEffect(() => {

    let tempRectangles = [...rectangles];
    const selectedText = tempRectangles.find(shape => {
      return shape.id === selectedComponentId;
    });
    if (selectedText !== undefined) {
      selectedText.text = text;

      setRectangles(tempRectangles)
    }

  }, [text]);

  React.useEffect(() => {

    let tempRectangles = [...rectangles];
    const selectedText = tempRectangles.find(shape => {
      return shape.id === selectedComponentId;
    });
    if (selectedText !== undefined && selectedComponent.textProps !== undefined) {
      selectedComponent.textProps.fontSize = size;

      setRectangles(tempRectangles)
    }

  }, [size]);

  React.useEffect(() => {

    let tempRectangles = [...rectangles];
    const selectedText = tempRectangles.find(shape => {
      return shape.id === selectedComponentId;
    });
    if (selectedText !== undefined && selectedComponent.textProps !== undefined) {
      selectedComponent.textProps.fontFamily = font;

      setRectangles(tempRectangles)
    }

  }, [font]);




  // get base url - useEffect
  useEffect(() => {
    if (token !== null && userDetails !== null) {
      getTemplateById();
      getAllScreensByTemplate();
      getAllLanguages();
    }

    setMiniSidebar(true);
    setMiniSidebarMenuOn(true);

    // console.log('hex2rgba >> ', hex2rgba('#af64f576'))
    // console.log('rgba2hex >> ', rgba2hex('rgba(175, 100, 245, 0.46)'));
  }, [DesignKiosksTemplate])


  // ------------- template management -------------
  const getTemplateById = () => {

    axios({
      method: 'GET',
      url: `${baseUrl}/kiosk/kiosk-design/get/${templateId}`,
      headers: {
        Authorization: token
      }
    }).then((res) => {

      let data = res.data.data;
      setTemplate(data);

      if (data.landscape) {
        setGridWidth(630);
        setGridHeight(390);
      } else {
        setGridWidth(390);
        setGridHeight(630);
      }

    }).catch((error) => {
      console.log(error);

      if (error.response !== undefined) {
        toast.remove();
        toast.error(error.response.data.message, {
          position: 'bottom-center',
        });
      } else {
        toast.remove();
        toast.error('Something went wrong', {
          position: 'bottom-center',
        });
      }
    })

  }


  // ------------- screens list management -------------

  // getAllScreensByTemplate - function
  const getAllScreensByTemplate = (setDefaultSelect) => {

    axios({
      method: 'GET',
      url: `${baseUrl}/kiosk/screen/get-all/design/${templateId}`,
      headers: {
        Authorization: token
      }
    }).then((res) => {

      let data = res.data.data;

      let newScreenList = resizeComponentsForScreenPreview(data);

      setAllScreensList(newScreenList);

      if (setDefaultSelect !== false)
        setSelectedScreen(data[0]);

    }).catch((error) => {
      console.log(error);

      if (error.response !== undefined) {
        toast.remove();
        toast.error(error.response.data.message, {
          position: 'bottom-center',
        });
      } else {
        toast.remove();
        toast.error('Something went wrong', {
          position: 'bottom-center',
        });
      }
    })

  }

  // after get all screens, resize components for screen preview - function
  const resizeComponentsForScreenPreview = (screenList) => {

    for (let j = 0; j < screenList.length; j++) {

      let screenComponentList = screenList[j].components;

      let tempArray = [];

      for (let i = 0; i < screenComponentList.length; i++) {
        const component = screenComponentList[i];

        if (component.type === 'rect') {

          var object = {
            fill: hex2rgba(component.fill),
            height: component.h / 4,
            hex: component.fill,
            id: component.id,
            name: component.name,
            rgba: hex2rgbaobject(component.fill),
            type: component.type,
            width: component.w / 3.5,
            x: component.x / 3.5,
            y: component.y / 4
          }
          tempArray.push(object);

        } else if (component.type === 'img' || component.type === 'btn') {

          var object = {
            height: component.h / 4,
            // hex: component.fill,
            id: component.id,
            imgId: component.imageId,
            name: component.name,
            // rgba: hex2rgbaobject(component.fill),
            src: component.imageUrl,
            type: component.type,
            width: component.w / 3.5,
            x: component.x / 3.5,
            y: component.y / 4
          }
          tempArray.push(object);

        } else if (component.type === 'text') {

          var object = {
            fill: hex2rgba(component.componentText.color),
            height: component.h / 4,
            hex: component.componentText.color,
            id: component.id,
            name: component.name,
            rgba: hex2rgbaobject(component.componentText.color),
            text: component.componentText.text,
            textProps: {
              fontSize: component.componentText.size / 3,
              align: component.componentText.align,
              fontFamily: component.componentText.font.name,
            },
            type: component.type,
            width: component.w / 3.5,
            x: component.x / 3.5,
            y: component.y / 4
          }
          tempArray.push(object);
        }

      }

      screenList[j].components = tempArray;

    }

    return screenList;

  }

  // when get all screens, build for render
  useEffect(() => {
    if (allScreensList.length > 0 || selectedScreen !== undefined) {
      createScreensListWithJSX();
    }
  }, [allScreensList, selectedScreen])

  // create screens list for nestable - function
  const createScreensListWithJSX = (isRename) => {
    console.log('start createScreensListWithJSX  ');

    let dataList = JSON.parse(JSON.stringify(allScreensList));
    let newDataList = [];

    dataList.map((item, i) => {

      let screen = {
        id: item.id,
        text: <>
          <div className={`screen-list-item text-center mb-2 
          ${selectedScreen != undefined ? selectedScreen.id === item.id ? 'active' : '' : null} 
          `}
            onClick={() => {
              if (item.id !== selectedScreen.id) {
                getAllScreensByTemplate(false);
                setShowAdvanceView(false);
                checkDeselect();
                setSelectedScreen(item)
              }
            }}
          >
            {/* <div className='screen-list-item-img-cont mb-1'>
              <img src={item.thumbnailURL} />
            </div> */}

            <div className='screen-list-item-img-cont mb-1'>
              <Stage
                width={gridWidth / 3.5}
                height={gridHeight / 4}
                ref={stageRef}
                onContextMenu={e => {
                  e.evt.preventDefault()
                }}
                style={{
                  backgroundColor: 'white',
                  width: gridWidth / 3.5 + 'px',
                  height: gridHeight / 4 + 'px',
                  margin: '0px auto 0px',
                  borderRadius: '5px'
                }}
              >
                <Layer>
                  <BGImageMini bgImg={item.backgroundImageURL} />
                </Layer>


                <Layer
                  hitGraphEnabled={true}>
                  {
                    item.components != undefined ?
                      <>
                        {item.components.map((rect, i) => {
                          return (

                            rect.type === 'text' ?

                              <TextShapeMini
                                key={rect.id}
                                // gridWidth={200}
                                // gridHeight={(220 * 9 / 16)}
                                text={rect.text}
                                textProps={rect.textProps}
                                shapeProps={rect}
                              />

                              :

                              rect.type === 'rect' ?

                                <RectangleMini key={rect.id}
                                  // gridWidth={200}
                                  // gridHeight={(220 * 9 / 16)}
                                  shapeProps={rect}
                                />

                                :

                                <URLImageMini key={rect.id}
                                  image={rect.src}
                                  // gridWidth={200}
                                  // gridHeight={(220 * 9 / 16)}
                                  shapeProps={rect}
                                />

                          );
                        })}
                      </>

                      : null
                  }

                </Layer>

              </Stage>
            </div>


            {isRename ? (
              <>
                {item.id === selectedScreen.id ?
                  <input className="input-kiosks-temp-rename" title="Double click to rename"
                    defaultValue={screenRenameText}
                    autoFocus
                    onChange={(e) => {
                      screenRenameText = e.target.value;
                    }}
                    onBlur={(e) => {
                      renameScreen();
                    }}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        renameScreen();
                      }
                    }}
                  />
                  :
                  <p className='screen-name'>{item.name}</p>
                }
              </>
            ) : <p className='screen-name'>{item.name}</p>}


          </div>
        </>
      }
      newDataList.push(screen);
    })

    setAllScreensForRender(newDataList);
  }

  // when select screen - useEffect
  useEffect(() => {
    if (selectedScreen === undefined) return

    setSelectedBgImg('');
    getScreenById();
    getScreenComponentsByScreen();

  }, [selectedScreen])

  // getScreenComponentsByScreen - function
  const getScreenComponentsByScreen = () => {

    axios({
      method: 'GET',
      url: `${baseUrl}/kiosk/screen-component/get-all/screen/${selectedScreen.id}`, // ** hard code
      headers: {
        Authorization: token
      }
    }).then((res) => {

      let data = res.data.data;
      createComponentDataForRectState(data);

    }).catch((error) => {
      console.log(error);

      if (error.response !== undefined) {
        toast.remove();
        toast.error(error.response.data.message, {
          position: 'bottom-center',
        });
      } else {
        toast.remove();
        toast.error('Something went wrong', {
          position: 'bottom-center',
        });
      }
    })


  }

  // addNewScreen - function
  const addNewScreen = () => {

    if (allScreensList.length > 0) {
      setNewScreenIndex((allScreensList[allScreensList.length - 1].index) + 1);
    } else {
      setNewScreenIndex(0);
    }

    setShowAddKiosksScreenModal(true);

  }

  // handleScreenRenameAction - function
  const handleScreenRenameAction = () => {
    if (selectedScreen === undefined) {
      toast.remove();
      toast.error('Select screen', {
        position: 'bottom-center',
      });

    } else {
      screenRenameText = selectedScreen.name;
      createScreensListWithJSX(true);
    }
  }

  // renameScreen - function
  const renameScreen = () => {

    if (screenRenameText.length === 0) {

      createScreensListWithJSX(false);

      toast.remove();
      toast.error('Screen name can not be empty', {
        position: 'bottom-center',
      });

    } else if (selectedScreen.name === screenRenameText) {

      createScreensListWithJSX(false);

    } else {

      axios({
        method: 'POST',
        url: `${baseUrl}/kiosk/screen/${selectedScreen.id}/rename`,
        headers: {
          Authorization: token
        },
        data: {
          name: screenRenameText
        },
      }).then((res) => {
        let data = res.data.data;
        console.log('1');
        toast.remove();
        toast.success('Screen renamed', {
          position: 'bottom-center',
        });

        // update selectedScreen state
        setSelectedScreen(data);
        console.log('2');
        // update allScreensList array
        for (let i = 0; i < allScreensList.length; i++) {
          const element = allScreensList[i];

          if (element.id === selectedScreen.id) {
            allScreensList[i] = data
          }
        }
        console.log('3');
        // setScreenNameText('');
        // createScreensListWithJSX(false);

      }).catch((error) => {
        console.log(error);

        createScreensListWithJSX(false);

        if (error.response !== undefined) {
          toast.remove();
          toast.error(error.response.data.message, {
            position: 'bottom-center',
          });
        } else {
          toast.remove();
          toast.error('Something went wrong', {
            position: 'bottom-center',
          });
        }
      })

    }
  }

  // deleteScreen - function
  const deleteScreen = () => {

    if (selectedScreen != undefined) {

      Swal.fire({
        position: 'center',
        text: 'Do you want to delete this Screen?',
        showConfirmButton: true,
        confirmButtonText: "Yes",
        showCancelButton: true,
        cancelButtonText: "No",
        width: '400px',

      }).then((result) => {
        if (result.value == true) {

          axios({
            method: 'POST',
            url: `${baseUrl}/kiosk/screen/${selectedScreen.id}/delete`,
            headers: {
              Authorization: token
            },
          }).then((res) => {

            let data = res.data.data;
            removeDeletedScreenFromState();

            toast.remove();
            toast.success('Screen deleted', {
              position: 'bottom-center',
            });


          }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
              toast.remove();
              toast.error(error.response.data.message, {
                position: 'bottom-center',
              });
            } else {
              toast.remove();
              toast.error('Something went wrong', {
                position: 'bottom-center',
              });
            }
          })

        }
      })


    } else {
      toast.remove();
      toast.error('Select a screen', {
        position: 'bottom-center',
      });
    }

  }

  // when deleted screen remove it from state array
  const removeDeletedScreenFromState = () => {


    for (let i = 0; i < allScreensList.length; i++) {
      if (allScreensList[i].id === selectedScreen.id) {
        allScreensList.splice(i, 1);
        console.log('allScreensList length after deleting', allScreensList.length);

        if (allScreensList.length > 0) {

          if (i === allScreensList.length) {
            setSelectedScreen(allScreensList[allScreensList.length - 1]);
          }
          else {
            setSelectedScreen(allScreensList[i]);
          }

        } else {
          setAllScreensForRender([])
          setSelectedScreen(undefined);
        }

        return;
      }
    }

  }

  // clone screen - function
  const cloneScreen = () => {

    if (selectedScreen != undefined) {

      Swal.fire({
        position: 'center',
        text: 'Do you want to clone this Screen?',
        showConfirmButton: true,
        confirmButtonText: "Clone",
        showCancelButton: true,
        cancelButtonText: "No",
        width: '400px',

      }).then((result) => {
        if (result.value == true) {

          axios({
            method: 'POST',
            url: `${baseUrl}/kiosk/screen/${selectedScreen.id}/clone`,
            headers: {
              Authorization: token
            },
          }).then((res) => {
            let data = res.data.data;
            getAllScreensByTemplate();

            toast.remove();
            toast.success('Screen cloned', {
              position: 'bottom-center',
            });


          }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
              toast.remove();
              toast.error(error.response.data.message, {
                position: 'bottom-center',
              });
            } else {
              toast.remove();
              toast.error('Something went wrong', {
                position: 'bottom-center',
              });
            }
          })

        }
      })


    } else {
      toast.remove();
      toast.error('Select a screen', {
        position: 'bottom-center',
      });
    }

  }

  // get screen by id - function
  const getScreenById = () => {

    axios({
      method: 'GET',
      url: `${baseUrl}/kiosk/screen/get/${selectedScreen.id}`,
      headers: {
        Authorization: token
      },
    }).then((res) => {
      let data = res.data.data;

      if (data.backgroundImageURL !== undefined)
        setSelectedBgImg(data.backgroundImageURL);

      if (data.languageId !== undefined)
        setSelectedLang(data.languageId);

      if (data.languagePage)
        setSelectedLang('1000');

    }).catch((error) => {
      console.log(error);

      if (error.response !== undefined) {
        toast.remove();
        toast.error(error.response.data.message, {
          position: 'bottom-center',
        });
      } else {
        toast.remove();
        toast.error('Something went wrong', {
          position: 'bottom-center',
        });
      }
    })

  }

  // change screen index - function
  const changeScreenIndex = (currentList) => {

    let newArray = [];

    currentList.forEach((element, i) => {
      let object = {
        'id': element.id,
        'index': i
      }
      newArray.push(object);
    });

    axios({
      method: 'POST',
      url: `${baseUrl}/kiosk/screen/update-index`,
      headers: {
        Authorization: token
      },
      data: {
        screens: newArray
      }
    }).then((res) => {

      getAllScreensByTemplate();

    }).catch((error) => {
      console.log(error);

      if (error.response !== undefined) {
        toast.remove();
        toast.error(error.response.data.message, {
          position: 'bottom-center',
        });
      } else {
        toast.remove();
        toast.error('Something went wrong', {
          position: 'bottom-center',
        });
      }
    })

  }


  // ------------- canvas management -------------

  // when get screen components, create component data for rectangle state - function
  const createComponentDataForRectState = (dataArray) => {

    let tempArray = [];

    for (let i = 0; i < dataArray.length; i++) {
      const component = dataArray[i];

      if (component.type === 'rect') {

        var object = {
          fill: hex2rgba(component.fill),
          height: component.h,
          hex: component.fill,
          id: component.id,
          name: component.name,
          rgba: hex2rgbaobject(component.fill),
          type: component.type,
          width: component.w,
          x: component.x,
          y: component.y
        }
        tempArray.push(object);

      } else if (component.type === 'img' || component.type === 'btn') {

        var object = {
          height: component.h,
          // hex: component.fill,
          id: component.id,
          imgId: component.imageId,
          name: component.name,
          // rgba: hex2rgbaobject(component.fill),
          src: component.imageUrl,
          type: component.type,
          width: component.w,
          x: component.x,
          y: component.y
        }
        tempArray.push(object);

      } else if (component.type === 'text') {

        var object = {
          fill: hex2rgba(component.componentText.color),
          height: component.h,
          hex: component.componentText.color,
          id: component.id,
          name: component.name,
          rgba: hex2rgbaobject(component.componentText.color),
          text: component.componentText.text,
          textProps: {
            fontSize: component.componentText.size,
            align: component.componentText.align,
            fontFamily: component.componentText.font.name,
          },
          type: component.type,
          width: component.w,
          x: component.x,
          y: component.y
        }
        tempArray.push(object);
      }

    }

    setRectangles(tempArray);
  }

  // create data array for addNewScreenComponents service - function
  const createDataArray = () => {

    let dataArray = []

    for (let i = 0; i < rectangles.length; i++) {

      let component = rectangles[i];

      if (component.type === 'img' || component.type === 'btn') {

        let newObject = {
          id: component.id,
          name: component.name,
          // fill: component.hex,
          index: i,
          w: component.width,
          h: component.height,
          x: component.x,
          y: component.y,
          image: {
            id: component.imgId
          },
          type: component.type
        }
        dataArray.push(newObject);

      } else if (component.type === 'text') {

        let fontId = '';
        switch (component.textProps.fontFamily) {
          case 'arial':
            fontId = 1
            break;
          case 'barlow':
            fontId = 2
            break;
          case 'sans':
            fontId = 3
            break;
          case 'blinker':
            fontId = 4
            break;
          case 'cabin':
            fontId = 5
            break;
          case 'darkerGrotesque':
            fontId = 6
            break;
          case 'jetBrainsMono':
            fontId = 7
            break;
          case 'josefinSans':
            fontId = 8
            break;
          case 'montserrat':
            fontId = 9
            break;
          case 'quicksand':
            fontId = 10
            break;
          case 'sono':
            fontId = 11
            break;
        }

        let newObject = {
          id: component.id,
          name: component.name,
          index: i,
          w: component.width,
          h: component.height,
          x: component.x,
          y: component.y,
          type: component.type,
          componentText: {
            text: component.text,
            font: {
              id: fontId
            },
            size: component.textProps.fontSize,
            color: rgba2hex(component.fill),
            align: component.textProps.align,
          }

        }
        dataArray.push(newObject);

      } else if (component.type === 'rect') {

        let newObject = {
          id: component.id,
          name: component.name,
          fill: rgba2hex(component.fill),
          index: i,
          w: component.width,
          h: component.height,
          x: component.x,
          y: component.y,
          type: component.type
        }
        dataArray.push(newObject);

      }


    }

    // addNewScreenComponents(dataArray);
    return dataArray;

  }

  // auto save - when change rectangles state, save it - useEffect
  useEffect(() => {
    console.log('rectangles state changed');
    if (rectangles.length > 0) {
      if (intervalID !== null)
        clearTimeout(intervalID);
      intervalID = setTimeout(() => {
        updateScreenComponents();
      }, 3000);

    }
  }, [rectangles])


  // auto save - when drop component to Stage, save it - useEffect
  useEffect(() => {
    if (dropCount > 0) {
      updateScreenComponents();
    }
  }, [dropCount])

  // add new screen components - function
  const updateScreenComponents = () => {

    if (selectedScreen !== undefined) {

      setSavingProgressCount(true);

      var dataArray = createDataArray();

      axios({
        method: 'POST',
        url: `${baseUrl}/kiosk/screen-component/update/screen/${selectedScreen.id}`,
        headers: {
          Authorization: token
        },
        data: {
          screenComponents: dataArray,
        },
      }).then((res) => {

        setSavingProgressCount(false);
        // getAllScreensByTemplate();

      }).catch((error) => {
        console.log(error);

        if (error.response !== undefined) {
          toast.remove();
          toast.error(error.response.data.message, {
            position: 'bottom-center',
          });
        } else {
          toast.remove();
          toast.error('Something went wrong', {
            position: 'bottom-center',
          });
        }
      })

    } else {
      toast.remove();
      toast.error('Add new screen or select screen', {
        position: 'bottom-center',
      });
    }



  }

  // add background img to screen - function
  const addScreenBackgroundImg = (id) => {

    axios({
      method: 'POST',
      url: `${baseUrl}/kiosk/screen/add-bg/screen/${selectedScreen.id}/image/${id}`,
      headers: {
        Authorization: token
      },
    }).then((res) => {

    }).catch((error) => {
      console.log(error);

      if (error.response !== undefined) {
        toast.remove();
        toast.error(error.response.data.message, {
          position: 'bottom-center',
        });
      } else {
        toast.remove();
        toast.error('Something went wrong', {
          position: 'bottom-center',
        });
      }
    })

  }

  // remove background img from scrren - function
  const removeScreenBackgroundImg = () => {

    axios({
      method: 'POST',
      url: `${baseUrl}/kiosk/screen/delete-bg/screen/${selectedScreen.id}`,
      headers: {
        Authorization: token
      },
    }).then((res) => {

      setSelectedBgImg('');

    }).catch((error) => {
      console.log(error);

      if (error.response !== undefined) {
        toast.remove();
        toast.error(error.response.data.message, {
          position: 'bottom-center',
        });
      } else {
        toast.remove();
        toast.error('Something went wrong', {
          position: 'bottom-center',
        });
      }
    })

  }

  // when select component, get pages and cat by component
  useEffect(() => {
    if (selectedComponent !== null) {

      setSelectedOtherAction(undefined);

      if (selectedComponent.type === 'btn') {
        getActionPagesAndCategories();
      }

    }
  }, [selectedComponent])



  // ------------- tool menu management -------------

  // getAllLanguages - function
  const getAllLanguages = () => {

    axios({
      method: 'GET',
      url: `${baseUrl}/skill/get-languages/customer/${userDetails.cid}`,
      headers: {
        Authorization: token
      }
    }).then((res) => {

      let data = res.data.data;
      setLanguagesList(data);

      // set default selected lang
      setSelectedLang(data[0].id)

    }).catch((error) => {
      console.log(error);

      if (error.response !== undefined) {
        toast.remove();
        toast.error(error.response.data.message, {
          position: 'bottom-center',
        });
      } else {
        toast.remove();
        toast.error('Something went wrong', {
          position: 'bottom-center',
        });
      }
    })

  }

  // changeScreenLanguage - function
  const changeScreenLanguage = (id) => {

    if (selectedScreen !== undefined) {

      axios({
        method: 'POST',
        url: `${baseUrl}/kiosk/screen/${selectedScreen.id}/language/${id}`,
        headers: {
          Authorization: token
        }
      }).then((res) => {

        let data = res.data.data;
        setSelectedLang(id);

      }).catch((error) => {
        console.log(error);

        if (error.response !== undefined) {
          toast.remove();
          toast.error(error.response.data.message, {
            position: 'bottom-center',
          });
        } else {
          toast.remove();
          toast.error('Something went wrong', {
            position: 'bottom-center',
          });
        }
      })

    } else {
      toast.remove();
      toast.error('Select a screen to change language', {
        position: 'bottom-center',
      });
    }

  }

  // when change rectangle state, create layers list - useEffect
  useEffect(() => {
    if (rectangles.length > 0) {
      createLayersListWithJSX();
    } else {
      let newDataList = [];
      let screen = {
        id: 1,
        type: 'empty',
        text: <>
          <p className='kionsks-empty-value-para'>No layers</p>
        </>
      }
      newDataList.push(screen);
      setLayersListForRender(newDataList);
    }
  }, [rectangles])

  // create layers list for nestable
  const createLayersListWithJSX = () => {

    let dataList = JSON.parse(JSON.stringify(rectangles));
    let newDataList = [];

    dataList.reverse().map((item, i) => {

      let screen = {
        id: dataList.length - i - 1,
        type: item.type,
        text: <>
          {item.type === 'img' || item.type === 'btn' ? (
            <li className="list-group-item kiosk-template-label vertically-align-center-flex ">
              <i className="fa fa-picture-o" aria-hidden="true"></i>
              <label className='m-0'>Image</label>
              <label className='m-0'>{item.name}</label>
            </li>
          ) : item.type === 'text' ? (
            <li className="list-group-item kiosk-template-label vertically-align-center-flex ">
              <i className="fa fa-font" aria-hidden="true"></i>
              <label className='m-0'>Text</label>
              <label className='m-0'>{item.name}</label>
            </li>
          ) : (
            <li className="list-group-item kiosk-template-label vertically-align-center-flex ">
              <i className="fa fa-square-o" aria-hidden="true"></i>
              <label className='m-0'>Shape</label>
              <label className='m-0'>{item.name}</label>
            </li>
          )
          }

        </>
      }
      newDataList.push(screen);
    })

    setLayersListForRender(newDataList);
  }

  // getActionPagesAndCategories - function
  const getActionPagesAndCategories = () => {

    axios({
      method: 'GET',
      url: `${baseUrl}/kiosk/screen/get-list/branch/${selectedBranch.id}/screen/${selectedScreen.id}/component/${selectedComponent.id}`,
      headers: {
        Authorization: token
      }
    }).then((res) => {

      let data = res.data.data;

      checkBtnActionType(data); // page or category

      setBtnActionPagesList(data.screenList);
      setBtnActionCateList(data.categoryList);

    }).catch((error) => {
      console.log(error);

      if (error.response !== undefined) {
        toast.remove();
        toast.error(error.response.data.message, {
          position: 'bottom-center',
        });
      } else {
        toast.remove();
        toast.error('Something went wrong', {
          position: 'bottom-center',
        });
      }
    })

  }

  // checkBtnActionType - function
  const checkBtnActionType = (data) => {

    let pagesList = data.screenList;
    let categoryList = data.categoryList;

    for (let i = 0; i < pagesList.length; i++) {
      const element = pagesList[i];
      if (element.selected === true) {
        setBtnActionRadio('page')
        return;
      }

    }

    for (let i = 0; i < categoryList.length; i++) {
      const element = categoryList[i];
      if (element.selected === true) {
        setBtnActionRadio('category')
        return;
      }

    }

    if (data.backOrHome !== undefined) {
      setBtnActionRadio('others')
      if (data.backOrHome.id === 4) {
        setSelectedOtherAction(4)
      } else if (data.backOrHome.id === 3) {
        setSelectedOtherAction(3)
      }
    }

  }

  // add component action - function
  const addComponentAction = (actionType, actionId) => {

    axios({
      method: 'POST',
      url: `${baseUrl}/kiosk/screen-component/add-action`,
      headers: {
        Authorization: token
      },
      data: {
        componentId: selectedComponent.id,
        actionId: actionType, // 1-page, 2-category
        linkId: actionId
      }
    }).then((res) => {

      getActionPagesAndCategories();

    }).catch((error) => {
      console.log(error);

      if (error.response !== undefined) {
        toast.remove();
        toast.error(error.response.data.message, {
          position: 'bottom-center',
        });
      } else {
        toast.remove();
        toast.error('Something went wrong', {
          position: 'bottom-center',
        });
      }
    })

  }


  // ------------- tool menu images management -------------

  // getImagesByType - function
  const getImagesByType = (type) => {

    axios({
      method: 'GET',
      url: `${baseUrl}/kiosk/kiosk-design/get-all/bg-images/type/${type}`,
      headers: {
        Authorization: token
      }
    }).then((res) => {
      let data = res.data.data;

      if (type === 1) {
        setBackgroundImagesList(data);
        setBgimgIsFirst(false);
      } else if (type === 2) {
        setBtnShapesImages(data);
        setBtnShapesIsFirst(false);
      } else if (type === 3) {
        setLabelImagesList(data);
        setLabelImgIsFirst(false);
      } else if (type === 4) {
        setLogoImagesList(data);
        setLogoIsFirst(false);
      }

    }).catch((error) => {
      console.log(error);

      if (error.response !== undefined) {
        toast.remove();
        toast.error(error.response.data.message, {
          position: 'bottom-center',
        });
      } else {
        toast.remove();
        toast.error('Something went wrong', {
          position: 'bottom-center',
        });
      }
    })

  }

  // addNewImages - function
  const addNewImages = (imgType, filesArray) => {

    let formData = new FormData();
    formData.append('type', imgType);
    for (let i = 0; i < filesArray.length; i++) {
      formData.append('files', filesArray[i]);
    }

    setShowProgress(true);
    setProgressType(0);

    const onUploadProgress = event => {
      const percentCompleted = Math.round((event.loaded * 100) / event.total);
      setProgressCount(percentCompleted);
    };

    axios({
      method: 'POST',
      url: `${baseUrl}/kiosk/bg-images/add`,
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: token
      },
      data: formData,
      onUploadProgress: onUploadProgress
    }).then((res) => {

      let data = res.data.data;

      setShowProgress(false);
      setProgressType(undefined);
      setProgressCount(0);

      addToStateArray(imgType, data);

    }).catch((error) => {
      console.log(error);

      setShowProgress(false);
      setProgressType(undefined);
      setProgressCount(0);

      if (error.response !== undefined) {
        toast.remove();
        toast.error(error.response.data.message, {
          position: 'bottom-center',
        });
      } else {
        toast.remove();
        toast.error('Something went wrong', {
          position: 'bottom-center',
        });
      }
    })

  }

  // browse new image - function
  const handleImageChange = (e, imgType) => {
    addNewImages(imgType, e.target.files);
  };

  // delete image - function
  const deleteImg = (id, type) => {

    Swal.fire({
      position: 'center',
      text: 'Do you want to delete this Image?',
      showConfirmButton: true,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
      width: '400px',

    }).then((result) => {
      if (result.value == true) {

        setShowProgress(true);
        setProgressType(0);

        axios({
          method: 'POST',
          url: `${baseUrl}/kiosk/kiosk-design/image/${id}/delete`,
          headers: {
            Authorization: token
          }
        }).then((res) => {

          let data = res.data.data;

          removeFromStateArray(id, type);

          setShowProgress(false);
          setProgressType(undefined);
          setProgressCount(0);

        }).catch((error) => {
          console.log(error);

          setShowProgress(false);
          setProgressType(undefined);
          setProgressCount(0);

          if (error.response !== undefined) {
            toast.remove();
            toast.error(error.response.data.message, {
              position: 'bottom-center',
            });
          } else {
            toast.remove();
            toast.error('Something went wrong', {
              position: 'bottom-center',
            });
          }
        })

      }
    })


  }

  // when deleted image remove it from state array
  const removeFromStateArray = (id, type) => {
    if (type === 1) {
      for (let i = 0; i < backgroundImagesList.length; i++) {
        if (backgroundImagesList[i].id === id) {
          backgroundImagesList.splice(i, 1);
        }
      }
    } else if (type === 2) {
      for (let i = 0; i < btnShapesImagesList.length; i++) {
        if (btnShapesImagesList[i].id === id) {
          btnShapesImagesList.splice(i, 1);
        }
      }
    } else if (type === 3) {
      for (let i = 0; i < labelImagesList.length; i++) {
        if (labelImagesList[i].id === id) {
          labelImagesList.splice(i, 1);
        }
      }
    } else if (type === 4) {
      for (let i = 0; i < logoImagesList.length; i++) {
        if (logoImagesList[i].id === id) {
          logoImagesList.splice(i, 1);
        }
      }
    }
    setRefresh(refresh + 1);
  }

  // when added new image/images appent it to state array
  const addToStateArray = (type, data) => {
    if (type === 1) {
      data.forEach(element => {
        setBackgroundImagesList(backgroundImagesList => [...backgroundImagesList, element]);
      });
    } else if (type === 2) {
      data.forEach(element => {
        setBtnShapesImages(btnShapesImagesList => [...btnShapesImagesList, element]);
      });
    } else if (type === 3) {
      data.forEach(element => {
        setLabelImagesList(labelImagesList => [...labelImagesList, element]);
      });
    } else if (type === 4) {
      data.forEach(element => {
        setLogoImagesList(logoImagesList => [...logoImagesList, element]);
      });
    }
    setRefresh(refresh + 1);
  }



  // ------------- utility functions -------------

  // hex to rgba
  const hex2rgba = (hex) => {
    let c = hex.substring(1).split('');

    if (!/^#(([\dA-Fa-f]{3}){1,2}|([\dA-Fa-f]{4}){1,2})$/.test(hex)) {
      throw new Error('Your hexadecimal color is not correct.');
    }

    switch (c.length) {
      case 3:
        c = [c[0] + c[0], c[1] + c[1], c[2] + c[2], 'ff'];
        break;
      case 4:
        c = [c[0] + c[0], c[1] + c[1], c[2] + c[2], c[3] + c[3]];
        break;
      case 6:
        c = [c[0] + c[1], c[2] + c[3], c[4] + c[5], 'ff'];
        break;
      case 8:
        c = [c[0] + c[1], c[2] + c[3], c[4] + c[5], c[6] + c[7]];
        break;
    }

    c = c.map((char) => parseInt(char, 16).toString());
    c[3] = (Math.round((parseInt(c[3], 10) / 255) * 100) / 100).toString();
    return c[3] === '1'
      ? `rgb( ${c[0]}, ${c[1]}, ${c[2]})`
      : `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${c[3]})`;
  }

  // hex to rgba
  const hex2rgbaobject = (hex) => {
    let c = hex.substring(1).split('');

    if (!/^#(([\dA-Fa-f]{3}){1,2}|([\dA-Fa-f]{4}){1,2})$/.test(hex)) {
      throw new Error('Your hexadecimal color is not correct.');
    }

    switch (c.length) {
      case 3:
        c = [c[0] + c[0], c[1] + c[1], c[2] + c[2], 'ff'];
        break;
      case 4:
        c = [c[0] + c[0], c[1] + c[1], c[2] + c[2], c[3] + c[3]];
        break;
      case 6:
        c = [c[0] + c[1], c[2] + c[3], c[4] + c[5], 'ff'];
        break;
      case 8:
        c = [c[0] + c[1], c[2] + c[3], c[4] + c[5], c[6] + c[7]];
        break;
    }

    c = c.map((char) => parseInt(char, 16).toString());
    c[3] = (Math.round((parseInt(c[3], 10) / 255) * 100) / 100).toString();
    return c[3] === '1'
      ? `rgb( ${c[0]}, ${c[1]}, ${c[2]})`
      : {
        r: c[0],
        g: c[1],
        b: c[2],
        a: c[3]
      }
  }

  // rgba to hex
  const rgba2hex = (orig) => {
    var a, isPercent,
      rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
      alpha = (rgb && rgb[4] || "").trim(),
      hex = rgb ?
        (rgb[1] | 1 << 8).toString(16).slice(1) +
        (rgb[2] | 1 << 8).toString(16).slice(1) +
        (rgb[3] | 1 << 8).toString(16).slice(1) : orig;

    if (alpha !== "") {
      a = alpha;
    } else {
      a = 1;
    }
    // multiply before convert to HEX
    a = ((a * 255) | 1 << 8).toString(16).slice(1)
    hex = '#' + hex + a;

    return hex;
  }

  // component will unmount - useEffect
  useEffect(() => {
    return () => {
      setRectangles([]);
      setMiniSidebar(false);
      setMiniSidebarMenuOn(false);
    }
  }, [])






  return (
    <>
      <div><Toaster /></div>
      <div className="container-fluid kiosks-build-tool pt-2">

        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1>Design Kiosk</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  {/* <li className="breadcrumb-item"><Link to="/region-management">Region Management</Link></li> */}
                  <li className="breadcrumb-item"><Link to={`/branch-landing?bid=${selectedBranch.id}`}>{selectedBranch.BranchName}</Link></li>
                  <li className="breadcrumb-item"><Link to={`/kiosks-templates`}>Kiosks Templates</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">Design Kiosk</li>
                </ol>
              </nav>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className='float-right'>
                <div className='save-btn-container vertically-align-center-flex-2'>
                  {savingProgressCount === true ?
                    <div className='vertically-align-center-flex-2 screen-name ml-2'>
                      <i className="fa fa-refresh mr-1" aria-hidden="true"></i>
                      <p className='m-0'>saving...</p>
                    </div>
                    : null

                  }

                  <button type="button" className="btn btn-primary"
                    onClick={() => {
                      updateScreenComponents();
                    }}
                  >Save Change</button>



                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row'>

          <div className='col-md-2 kiosks-custom-col-2'>
            <div className='create-kio-temp-left'>

              <div className='left-action-con mb-3'>
                <div className='icon-container-item' title='New'
                  onClick={() => {
                    addNewScreen();

                  }}
                >
                  <img src={require('../../assets/images/icons/add.png')} className='btn-align-icon' />
                </div>

                {selectedScreen != undefined ? (
                  <>
                    {rectangles.length > 0 ?
                      <div className='icon-container-item' title='Clone'
                        onClick={() => {
                          cloneScreen();
                        }}
                      >
                        <img src={require('../../assets/images/icons/copying.png')} className='btn-align-icon' />
                      </div>
                      : null
                    }

                    <div className='icon-container-item' title='Rename'
                      onClick={() => {
                        handleScreenRenameAction();
                      }}
                    >
                      <img src={require('../../assets/images/icons/edit.png')} className='btn-align-icon' />
                    </div>
                    <div className='icon-container-item' title='Delete'
                      onClick={() => {
                        deleteScreen();
                      }}
                    >
                      <img src={require('../../assets/images/icons/delete.png')} className='btn-align-icon' />
                    </div></>
                ) : null}



              </div>

              {/* <div className={`screen-list scroll-styled-y ${template.landscape ? 'horizontal' : 'vertically'} text-center`}> horizontal */}
              <div className={`screen-list scroll-styled-y text-center`}>
                {
                  allScreensForRender.length === 0 ? (
                    <span className='no-values-text mt-3'>No Screens</span>
                  ) : (

                    <Nestable
                      threshold={1300}
                      items={allScreensForRender}
                      renderItem={renderItemsPlanned}
                      onChange={(x) => {
                        changeScreenIndex(x);
                      }}
                    />

                  )
                }
              </div>

            </div>
          </div>

          <div className='col-md-7 kiosks-custom-col-7'>
            {
              selectedScreen != undefined ?

                <div className='create-kio-temp-center scroll-styled-y'>

                  {/* <div className='save-btn-container vertically-align-center-flex-2 mb-4'>
                    <button type="button" className="btn btn-primary"
                      onClick={() => {
                        updateScreenComponents();
                      }}
                    >Save Change</button>

                    {savingProgressCount === true ?
                      <div className='vertically-align-center-flex-2 screen-name ml-2'>
                        <i className="fa fa-refresh mr-1" aria-hidden="true"></i>
                        <p className='m-0'>saving...</p>
                      </div>
                      : null

                    }

                  </div> */}

                  <div className='canvas'>

                    {/* <ReactKonva/> */}
                    <div
                      style={{ outline: 'none' }}
                      tabIndex={0}
                      onKeyDown={(e) => {
                        handleCanvaKeyDown(e);
                      }}
                      onDrop={(e) => {
                        e.preventDefault();
                        // register event position
                        stageRef.current.setPointersPositions(e);

                        if (dragType.current === 'rect') {

                          let xCord = Math.max(Math.round(stageRef.current.getPointerPosition().x / grid) * grid, 0);
                          let yCord = Math.max(Math.round(stageRef.current.getPointerPosition().y / grid) * grid, 0);

                          let w = 160;
                          let h = 90;

                          //calc min x for boundary exceeds
                          if (xCord + w > gridWidth)
                            xCord = gridWidth - w;
                          //calc min y for boundary exceeds
                          if (yCord + h > gridHeight)
                            yCord = gridHeight - h;


                          if (xCord + w > gridWidth)
                            w = gridWidth - xCord;
                          if (h + yCord > gridHeight)
                            h = gridHeight - yCord;


                          // getName
                          let count = 0;
                          for (let i = 0; i < rectangles.length; i++) {
                            const element = rectangles[i];

                            if (dragType.current === 'rect') {
                              if (element.type === 'rect') {
                                count += 1;
                              }
                            }
                          }
                          let name = 'Shape ' + (count + 1);


                          // add shape
                          setRectangles(
                            rectangles.concat([
                              {
                                x: xCord,
                                y: yCord,
                                width: w,
                                height: h,
                                fill: toRGBA(selectedColor),
                                rgba: selectedColor,
                                hex: selectedColorHex,
                                id: dragType.current + Math.floor(Math.random() * 999999999),
                                type: dragType.current,
                                name: name
                              },
                            ])
                          );
                        } else if (dragType.current === 'text') {

                          // getName
                          let count = 0;
                          for (let i = 0; i < rectangles.length; i++) {
                            const element = rectangles[i];

                            if (dragType.current === 'text') {
                              if (element.type === 'text') {
                                count += 1;
                              }
                            }
                          }
                          let name = 'Text ' + (count + 1);

                          setRectangles(
                            rectangles.concat([
                              {
                                x: stageRef.current.getPointerPosition().x,
                                y: stageRef.current.getPointerPosition().y,
                                width: 260,
                                height: 50,
                                text: 'Lorem ipsum dolor sit amet',
                                rgba: selectedColor,
                                hex: selectedColorHex,
                                fill: toRGBA(selectedColor),
                                id: dragType.current + Math.floor(Math.random() * 999999999),
                                type: dragType.current,
                                textProps: {
                                  fontSize: 20,
                                  align: 'center',
                                  fontFamily: 'sans'
                                },
                                name: name
                              }
                            ])
                          );

                        } else if (dragType.current === 'bgimg') {

                          setSelectedBgImg(dragUrl.current);
                          addScreenBackgroundImg(dragImgId.current)

                        } else {

                          let xCord = Math.max(Math.round(stageRef.current.getPointerPosition().x / grid) * grid, 0);
                          let yCord = Math.max(Math.round(stageRef.current.getPointerPosition().y / grid) * grid, 0);

                          let w = dragDimens.width * 2;
                          let h = dragDimens.height * 2;

                          //calc min x for boundary exceeds
                          if (xCord + w > gridWidth)
                            xCord = gridWidth - w;
                          //calc min y for boundary exceeds
                          if (yCord + h > gridHeight)
                            yCord = gridHeight - h;


                          if (xCord + w > gridWidth)
                            w = gridWidth - xCord;
                          if (h + yCord > gridHeight)
                            h = gridHeight - yCord;

                          // getName
                          let count = 0;
                          for (let i = 0; i < rectangles.length; i++) {
                            const element = rectangles[i];

                            if (dragType.current === 'img') {
                              if (element.type === 'img') {
                                count += 1;
                              }
                            } else {
                              if (element.type === 'btn') {
                                count += 1;
                              }
                            }
                          }

                          let name = '';

                          if (dragType.current === 'img') {
                            name = 'Image ' + (count + 1);
                          } else {
                            name = 'Button ' + (count + 1);
                          }

                          // add image
                          setRectangles(
                            rectangles.concat([
                              {
                                // rgba: selectedColor,
                                // hex: selectedColorHex,
                                x: xCord,
                                y: yCord,
                                width: w,
                                height: h,
                                id: dragType.current + Math.floor(Math.random() * 999999999),
                                type: dragType.current,
                                src: dragUrl.current,
                                name: name,
                                imgId: dragImgId.current,
                              }
                            ])
                          );
                        }

                        // saveComponent when change this state
                        setDropCount(dropCount + 1)

                        setSelectedComponentId(null);
                        setSelectedComponent(null);
                      }}
                      onDragOver={(e) => e.preventDefault()}
                    >

                      <Stage
                        width={gridWidth}
                        height={gridHeight}
                        style={{
                          // border: '1px solid lightgray',
                          backgroundColor: 'white',
                          width: gridWidth + 'px',
                          height: gridHeight + 'px',
                          margin: '0px auto 0'
                        }}
                        ref={stageRef}
                        onContextMenu={e => {
                          e.evt.preventDefault()
                        }}
                      >
                        <Layer
                          style={{ objectFit: 'cover' }}
                        >
                          <BGImage
                            onMouseDown={checkDeselect}
                            onTouchStart={checkDeselect}
                            style={{ objectFit: 'cover' }}
                          />
                        </Layer>


                        <Layer>
                          {linesA}
                          {linesB}
                        </Layer>

                        <Layer
                          hitGraphEnabled={true}>
                          {rectangles.map((rect, i) => {
                            return (

                              rect.type === 'text' ?

                                <TextShape
                                  key={rect.id}
                                  grid={grid}
                                  gridWidth={gridWidth}
                                  gridHeight={gridHeight}
                                  text={rect.text}
                                  textProps={rect.textProps}
                                  shapeProps={rect}
                                  isSelected={rect.id === selectedComponentId}
                                  onSelect={() => {
                                    setSelectedComponentId(rect.id);
                                    setSelectedComponent(rect);
                                  }}
                                  onChange={(newAttrs) => {
                                    const rects = rectangles.slice();
                                    rects[i] = newAttrs;
                                    setRectangles(rects);
                                    if (rects[i].id === selectedComponentId)
                                      setSelectedComponent(rects[i]);
                                  }}
                                />

                                :

                                rect.type === 'rect' ?

                                  <Rectangle key={rect.id}
                                    grid={grid}
                                    gridWidth={gridWidth}
                                    gridHeight={gridHeight}
                                    shapeProps={rect}
                                    isSelected={rect.id === selectedComponentId}
                                    onSelect={() => {
                                      setSelectedComponentId(rect.id);
                                      setSelectedComponent(rect);
                                    }}
                                    onChange={(newAttrs) => {
                                      const rects = rectangles.slice();
                                      rects[i] = newAttrs;
                                      setRectangles(rects);
                                      if (rects[i].id === selectedComponentId)
                                        setSelectedComponent(rects[i]);
                                    }}
                                  />

                                  :

                                  <URLImage key={rect.id}
                                    image={rect.src}
                                    grid={grid}
                                    gridWidth={gridWidth}
                                    gridHeight={gridHeight}
                                    shapeProps={rect}
                                    isSelected={rect.id === selectedComponentId}
                                    onSelect={() => {
                                      setSelectedComponentId(rect.id);
                                      setSelectedComponent(rect);
                                    }}

                                    onChange={(newAttrs) => {
                                      const rects = rectangles.slice();
                                      rects[i] = newAttrs;
                                      setRectangles(rects);
                                      if (rects[i].id === selectedComponentId)
                                        setSelectedComponent(rects[i]);
                                    }} />

                            );
                          })}
                        </Layer>
                      </Stage>

                    </div>

                  </div>

                </div>

                : null
            }


          </div>

          {/* {!showAdvanceView ? ( */}
          <div className='col-md-3 kiosks-custom-col-3'>
            <div className={`create-kio-temp-right`}>

              <div className={`scroll-styled-y components ${!showAdvanceView ? 'active' : ''}`}>

                {showProgress ? (
                  <div className='progress-cont mb-2'>
                    <div className="progress progress-xs">
                      <div className="progress-bar" role="progressbar" aria-valuenow={10} aria-valuemin={0} aria-valuemax={100} style={{ width: `${progressCount}%`, backgroundColor: `${progressType === 0 ? '#5CB65F' : '#e13f3f'}` }}>
                      </div>
                    </div>
                  </div>
                ) : null}


                <Accordion defaultActiveKey={['0']} alwaysOpen className='kiosk-design-right-accordion'>

                  <div className='img-collection-cont'>
                    <Accordion.Toggle eventKey="0" className=""
                      onClick={() => {
                        if (bgimgIsFirst) {
                          getImagesByType(1);
                        }
                      }}
                    >
                      <div className='img-collection-title-cont'>
                        <p>Background Images</p>
                        <div className='icon-cont'>
                          <label htmlFor="browseBackgroundImg">
                            <img src={require('../../assets/images/icons/add.png')} />
                          </label>
                          <input type="file" accept=".png, .jpg, .jpeg" id="browseBackgroundImg" multiple
                            onChange={(e) => {
                              handleImageChange(e, 1);
                            }}
                            style={{ display: 'none' }} />
                        </div>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0" className="collapse">
                      <div className='img-collection scroll-styled-y'>
                        <div className='row'>

                          {
                            backgroundImagesList.length > 0 ? (
                              <>
                                {
                                  backgroundImagesList.map((img, i) => (

                                    <div className='col-md-4 custom-col' key={i}>
                                      <div className='background-img-container' title={img.name}>
                                        <div className='close-i-cont'>
                                          <i className="fa fa-times-circle" aria-hidden="true"
                                            onClick={() => {
                                              deleteImg(img.id, 1);
                                            }}
                                          ></i>
                                        </div>
                                        <div className='background-img'>
                                          <img src={img.url} alt=""
                                            draggable="true"
                                            onDragStart={(e) => {
                                              dragUrl.current = img.url;
                                              dragType.current = 'bgimg';
                                              dragImgId.current = img.id;
                                            }}
                                          />
                                        </div>
                                        <p className='background-img-name'>{img.name}</p>
                                      </div>
                                    </div>

                                  ))
                                }
                              </>
                            ) : (
                              <p className='no-values-text kiosks-img'>No Images</p>
                            )
                          }

                        </div>
                      </div>
                    </Accordion.Collapse>
                  </div>

                  <div className='img-collection-cont'>
                    <Accordion.Toggle eventKey="1" className=""
                      onClick={() => {
                        if (btnShapesIsFirst) {
                          getImagesByType(2);
                        }
                      }}
                    >

                      <div className='img-collection-title-cont'>
                        <p>Button Shapes</p>
                        <div className='icon-cont'>
                          <label htmlFor="browseBtnShapesImg">
                            <img src={require('../../assets/images/icons/add.png')} />
                          </label>
                          <input type="file" accept=".png, .jpg, .jpeg" id="browseBtnShapesImg" multiple
                            onChange={(e) => {
                              handleImageChange(e, 2);
                            }}
                            style={{ display: 'none' }} />
                        </div>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1" className="collapse">
                      <div className='img-collection scroll-styled-y'>
                        <div className='row'>

                          {
                            btnShapesImagesList.length > 0 ? (
                              <>
                                {
                                  btnShapesImagesList.map((img, i) => (

                                    <div className='col-md-4 custom-col' key={i}>
                                      <div className='background-img-container' title={img.name}>
                                        <div className='close-i-cont'>
                                          <i className="fa fa-times-circle" aria-hidden="true"
                                            onClick={() => {
                                              deleteImg(img.id, 2);
                                            }}></i>
                                        </div>
                                        <div className='background-img'>
                                          <img src={img.url} alt=""
                                            draggable="true"
                                            onDragStart={(e) => {
                                              dragUrl.current = e.target.src;
                                              dragType.current = 'btn';
                                              dragImgId.current = img.id;
                                              dragDimens.height = e.target.height;
                                              dragDimens.width = e.target.width;
                                            }}
                                          />
                                        </div>
                                        <p className='background-img-name'>{img.name}</p>
                                      </div>
                                    </div>

                                  ))
                                }
                              </>
                            ) : (
                              <p className='no-values-text kiosks-img'>No Images</p>
                            )
                          }



                        </div>
                      </div>
                    </Accordion.Collapse>
                  </div>

                  <div className='img-collection-cont'>
                    <Accordion.Toggle eventKey="2" className=""
                      onClick={() => {
                        if (logoIsFirst) {
                          getImagesByType(4);
                        }
                      }}
                    >
                      <div className='img-collection-title-cont'>
                        <p>Logos</p>
                        <div className='icon-cont'>
                          <label htmlFor="logosImg">
                            <img src={require('../../assets/images/icons/add.png')} />
                          </label>
                          <input type="file" accept=".png, .jpg, .jpeg" id="logosImg" multiple
                            onChange={(e) => {
                              handleImageChange(e, 4);
                            }}
                            style={{ display: 'none' }} />
                        </div>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2" className="collapse">
                      <div className='img-collection scroll-styled-y'>
                        <div className='row'>

                          {
                            logoImagesList.length > 0 ? (
                              <>
                                {
                                  logoImagesList.map((img, i) => (

                                    <div className='col-md-4 custom-col' key={i}>
                                      <div className='background-img-container' title={img.name}>
                                        <div className='close-i-cont'>
                                          <i className="fa fa-times-circle" aria-hidden="true"
                                            onClick={() => {
                                              deleteImg(img.id, 4);
                                            }}
                                          ></i>
                                        </div>
                                        <div className='background-img'>
                                          <img src={img.url} alt=""
                                            draggable="true"
                                            onDragStart={(e) => {
                                              dragUrl.current = e.target.src;
                                              dragType.current = 'img';
                                              dragImgId.current = img.id;
                                              dragDimens.height = e.target.height;
                                              dragDimens.width = e.target.width;
                                            }}
                                          />
                                        </div>
                                        <p className='background-img-name'>{img.name}</p>
                                      </div>
                                    </div>

                                  ))
                                }

                              </>
                            ) : (
                              <p className='no-values-text kiosks-img'>No Images</p>
                            )
                          }


                        </div>
                      </div>
                    </Accordion.Collapse>
                  </div>

                  <div className='img-collection-cont'>
                    <Accordion.Toggle eventKey="3" className="">
                      <div className='img-collection-title-cont'>
                        <p>Labels</p>
                        {/* <div className='icon-cont'>
                          <label for="imgBrowse">
                            <img src={require('../../assets/images/icons/add.png')} />
                          </label>
                          <input type="file" accept=".png, .jpg, .jpeg" id="imgBrowse" multiple
                            onChange={(e) => {
                              handleImageChange(e, 3);
                            }}
                            style={{ display: 'none' }} />
                        </div> */}
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3" className="collapse">
                      <div className='img-collection scroll-styled-y'>
                        <div className='row'>

                          <div className='col-md-4 custom-col'>
                            <div className='background-img-container' title='Text'>
                              <div className='background-img'>
                                <img src={require("../../assets/images/shapes/text-label.png")}
                                  draggable="true"
                                  onDragStart={(e) => {
                                    dragUrl.current = e.target.src;
                                    dragType.current = 'text';
                                  }}
                                />
                              </div>
                              <p className='background-img-name'>Text</p>
                            </div>
                          </div>

                          <div className='col-md-4 custom-col'>
                            <div className='background-img-container' title='Rectangle'>
                              {/* <div className='close-i-cont'>
                                          <i className="fa fa-times-circle" aria-hidden="true"
                                            onClick={() => {
                                              deleteImg(img.id, 3);
                                            }}
                                          ></i>
                                        </div> */}
                              <div className='background-img'>
                                <img src={require("../../assets/images/shapes/blue-rectangle.jpg")}
                                  draggable="true"
                                  onDragStart={(e) => {
                                    dragUrl.current = e.target.src;
                                    dragType.current = 'rect';
                                  }}
                                />
                              </div>
                              <p className='background-img-name'>Rectangle</p>
                            </div>
                          </div>

                        </div>
                      </div>
                    </Accordion.Collapse>
                  </div>

                  <div className='img-collection-cont'>
                    <Accordion.Toggle eventKey="4" className="">
                      <div className='img-collection-title-cont'>
                        <p>Languages</p>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="4" className="collapse">
                      <div className='img-collection scroll-styled-y'>
                        <div className='row'>

                          <div className='col-12 custom-col'>
                            <div className='pb-2'>

                              {languagesList.length > 0 ? (
                                <>
                                  {
                                    languagesList.map((item, i) =>
                                      <span key={i} style={{ cursor: 'pointer' }}
                                        className={`badge ${selectedLang === item.id ? 'badge-secondary' : 'badge-default'} kiosks-badge`}
                                        onClick={() => {
                                          changeScreenLanguage(item.id)
                                          // setSelectedLang(item.id);
                                        }}
                                      >
                                        {item.lang}
                                      </span>
                                    )
                                  }
                                  <span style={{ cursor: 'pointer' }}
                                    className={`badge ${selectedLang === '1000' ? 'badge-secondary' : 'badge-default'} kiosks-badge`}
                                    onClick={() => {
                                      changeScreenLanguage('1000');
                                    }}
                                  >
                                    All
                                  </span>
                                </>
                              ) : <p className='kionsks-empty-value-para'>No languages</p>}



                              {/* <span className="badge badge-secondary kiosks-badge" style={{ cursor: 'pointer' }}>English</span>
                              <span className="badge badge-default kiosks-badge" style={{ cursor: 'pointer' }}>සිංහල</span>
                              <span className="badge badge-default kiosks-badge" style={{ cursor: 'pointer' }}>தமிழ்</span> */}

                            </div>
                          </div>

                        </div>
                      </div>
                    </Accordion.Collapse>
                  </div>

                  <div className='img-collection-cont'>
                    <Accordion.Toggle eventKey="5" className="">
                      <div className='img-collection-title-cont'>
                        <p>Layers</p>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="5" className="collapse">
                      <div className='img-collection no-fix-height pb-3'>
                        <div className='row'>

                          <div className='col-12 custom-col'>
                            {selectedBgImg !== null ?
                              <>
                                {selectedBgImg.length > 0 ?
                                  <>
                                    <div className="list-group-item kiosk-template-label vertically-align-center-flex ">
                                      <i className="fa fa-picture-o" aria-hidden="true"></i>
                                      <label className='m-0'>Background Image</label>
                                      <label className='m-0 remove-text' onClick={() => { removeScreenBackgroundImg() }}>Remove</label>
                                      {/* <i className="fa fa-trash" aria-hidden="true"></i> */}
                                    </div>

                                    <div className='layers-custom-separeter'></div>
                                  </>
                                  : null}
                              </>

                              : null}

                            <Nestable
                              threshold={1300}
                              items={layersListForRender}
                              renderItem={renderItemsPlanned}
                              margin={0}
                              onChange={(x) => {

                                let tempRect = [];
                                x = x.reverse();
                                for (let i = 0; i < x.length; i++) {

                                  tempRect.push(rectangles[(x[i].id)])
                                }

                                setRectangles(tempRect)
                              }}
                            />

                          </div>
                        </div>
                      </div>


                    </Accordion.Collapse>
                  </div>




                </Accordion>



              </div>

              <div className={`scroll-styled-y styles ${showAdvanceView ? 'active' : ''}`}>
                <div className='container'>

                  <div className='props-container'>
                    <div className='row'>
                      <div className='col-12 p-l-10 p-r-10'>
                        <i className="fa fa-times-circle float-right pb-2 advance-view-close" aria-hidden="true" title='Close Advance View'
                          onClick={() => {
                            setShowAdvanceView(false);
                            checkDeselect();
                          }}
                        ></i>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12 p-l-10 p-r-10'>
                        <div className='icon-container text-center mb-4'>

                          <div className='icon-container-item' title='Align left'
                            onClick={() => {
                              if (selectedComponent === null)
                                return;
                              setX(0);
                            }}
                          >
                            <img src={require("../../assets/images/icons/left-align.png")} className='btn-align-icon' />
                          </div>

                          <div className='icon-container-item' title='Align vertical center'
                            onClick={() => {
                              if (selectedComponent === null)
                                return;
                              setX((gridWidth / 2) - (width / 2));
                            }}
                          >
                            <img src={require("../../assets/images/icons/align-center.png")} className='btn-align-icon' />
                          </div>

                          <div className='icon-container-item' title='Align right'
                            onClick={() => {
                              if (selectedComponent === null)
                                return;
                              setX(gridWidth - width);
                            }}
                          >
                            <img src={require("../../assets/images/icons/right-align.png")} className='btn-align-icon' />
                          </div>

                          {/* <div className='icon-container-item'>
                            <img src={require("../../assets/images/icons/right-align.png")} className='btn-align-icon' />
                          </div> */}

                          <div className='icon-container-item' title='Align top'
                            onClick={() => {
                              if (selectedComponent === null)
                                return;
                              setY(0);
                            }}
                          >
                            <img src={require("../../assets/images/icons/upper-alignment.png")} className='btn-align-icon' />
                          </div>

                          <div className='icon-container-item' title='Align horizontal center'
                            onClick={() => {
                              if (selectedComponent === null)
                                return;
                              setY((gridHeight / 2) - (height / 2));
                            }}
                          >
                            <img src={require("../../assets/images/icons/horizontal-alignment.png")} className='btn-align-icon' />
                          </div>

                          <div className='icon-container-item' title='Align bottom'
                            onClick={() => {
                              if (selectedComponent === null)
                                return;
                              setY(gridHeight - height);
                            }}
                          >
                            <img src={require("../../assets/images/icons/bottom-alignment .png")} className='btn-align-icon' />
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-5 p-l-10 p-r-10'>
                        <div className="input-group input-group-sm mb-3 kiosk-template-input">
                          <p className="input-label mb-0 mr-2">X : </p>
                          <input type="number" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                            onChange={onChangeX} min={0} step={grid} value={xCoordinate}
                          />
                        </div>
                      </div>
                      <div className='col-7 p-l-10 p-r-10'>
                        <div className="input-group input-group-sm mb-3 kiosk-template-input">
                          <p className="input-label mb-0 mr-2">Width : </p>
                          <input type="number" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                            onChange={onChangeW} step={grid} min={grid} value={width}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-5 p-l-10 p-r-10'>
                        <div className="input-group input-group-sm mb-3 kiosk-template-input">
                          <p className="input-label mb-0 mr-2">Y : </p>
                          <input type="number" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                            onChange={onChangeY} min={0} step={grid} value={yCoordinate}
                          />
                        </div>
                      </div>
                      <div className='col-7 p-l-10 p-r-10'>
                        <div className="input-group input-group-sm mb-3 kiosk-template-input">
                          <p className="input-label mb-0 mr-1">Height : </p>
                          <input type="number" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                            onChange={onChangeH} step={grid} min={grid} value={height}
                          />
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className='props-container pt-4'>
                    <div className='row'>

                      {/* <div className='col-12 p-l-10 p-r-10'>
                        <div className={`input-group input-group-sm mb-3 kiosk-template-input
                        ${selectedComponent != null ?
                            selectedComponent.type != 'text' ? 'disable-kiosks-temp-tool' : ''
                            : ""
                          }`}
                        >
                          <p className="input-label mb-0 mr-2">Show Text : </p>
                          <input type="checkbox" aria-label="Checkbox for following text input" />
                          <label className="switch sm-switch">
                            <input type="checkbox" />
                            <span className="slider"></span>
                          </label>
                        </div>
                      </div> */}

                      <div className='col-12 p-l-10 p-r-10'>
                        <div className={`input-group input-group-sm mb-3 kiosk-template-input
                        ${selectedComponent != null ?
                            selectedComponent.type != 'text' ? 'disable-kiosks-temp-tool' : ''
                            : ""
                          }`}
                        >
                          <p className="input-label mb-0 mr-3">Text : </p>
                          <input type="text" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                            value={text} onChange={onChangeText}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-8 p-l-10 p-r-10'>
                        <div className={`input-group input-group-sm mb-3 kiosk-template-input
                        ${selectedComponent != null ?
                            selectedComponent.type != 'text' ? 'disable-kiosks-temp-tool' : ''
                            : ""
                          }`}
                        >
                          <select className="custom-select modal-select-field" id="inputGroupSelect01"
                            value={font} onChange={onChangeFont}
                          >
                            <option value="" hidden>- select font -</option>
                            <option value="sans">Sans</option>
                            <option value="arial">Arial</option>
                            <option value="barlow">Barlow</option>
                            <option value="blinker">Blinker</option>
                            <option value="cabin">Cabin</option>
                            <option value="darkerGrotesque">Darker Grotesque</option>
                            <option value="jetBrainsMono">JetBrains Mono</option>
                            <option value="josefinSans">Josefin Sans</option>
                            <option value="montserrat">Montserrat</option>
                            <option value="quicksand">Quicksand</option>
                            <option value="sono">Sono</option>

                          </select>
                        </div>
                      </div>
                      <div className='col-4 p-l-10 p-r-10'>
                        <div className={`input-group input-group-sm mb-3 kiosk-template-input
                        ${selectedComponent != null ?
                            selectedComponent.type != 'text' ? 'disable-kiosks-temp-tool' : ''
                            : ""
                          }`}
                        >
                          <input type="number" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                            min={8} max={100} value={size} onChange={onChangeSize} />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-8 p-l-10 p-r-10'>
                        <div className={`icon-container text-center mb-2
                        ${selectedComponent != null ?
                            selectedComponent.type != 'text' ? 'disable-kiosks-temp-tool' : ''
                            : ""
                          }`}
                        >
                          <div className='icon-container-item mr-3' title='Text align left'
                            onClick={() => {
                              changeTextAlign('left')
                            }}
                          >
                            <img src={require("../../assets/images/icons/text-align-left.png")} className='btn-align-icon' />
                          </div>

                          <div className='icon-container-item mr-3' title='Text align center'
                            onClick={() => {
                              changeTextAlign('center')
                            }}
                          >
                            <img src={require("../../assets/images/icons/text-center-align.png")} className='btn-align-icon' />
                          </div>

                          <div className='icon-container-item' title='Text align right'
                            onClick={() => {
                              changeTextAlign('right')
                            }}
                          >
                            <img src={require("../../assets/images/icons/text-align-right.png")} className='btn-align-icon' />
                          </div>

                        </div>
                      </div>
                      <div className='col-4 p-l-10 p-r-10'>

                        <div style={{ padding: '5px 0' }}
                          className={`${selectedComponent != null ?
                            selectedComponent.type === 'img' || selectedComponent.type === 'btn' ? 'disable-kiosks-temp-tool' : ''
                            : ""
                            }`}
                        >
                          <div style={styles.swatch} onClick={handleClick}>
                            <div style={styles.color} className='color-view-box' />
                          </div>
                          {displayColorPicker ? <div style={styles.popover}>
                            <div style={styles.cover} onClick={handleClose} />
                            <SketchPicker color={selectedColor} onChange={handleChange} />
                          </div> : null}
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className={`props-container pt-4 pb-4
                  ${selectedComponent != null ?
                      selectedComponent.type === 'btn' ? '' : 'disable-kiosks-temp-tool'
                      : ""
                    }
                  `}>
                    <div className='row'>
                      <div className='col-12'>
                        <p className='advance-view-title mb-2'>Button Actions</p>
                        <div className='mb-2'>
                          <div className='row'>
                            <div className='col-12'>
                              <div className='btn-action-types-container'>

                                <div className="fancy-radio custom">
                                  <label>
                                    <input name="gender" value="page" type="radio"
                                      checked={btnActionRadio === 'page'}
                                      onChange={(e) => {
                                        setBtnActionRadio(e.target.value)
                                      }}
                                    />
                                    <span className='kiosk-template-label'><i></i>Pages</span></label>
                                </div>

                                <div className="fancy-radio custom">
                                  <label>
                                    <input name="gender" value="category" type="radio"
                                      checked={btnActionRadio === 'category'}
                                      onChange={(e) => {
                                        setBtnActionRadio(e.target.value)
                                      }}
                                    />
                                    <span className='kiosk-template-label'><i></i>Category</span></label>
                                </div>

                                <div className="fancy-radio custom">
                                  <label>
                                    <input name="gender" value="others" type="radio"
                                      checked={btnActionRadio === 'others'}
                                      onChange={(e) => {
                                        setBtnActionRadio(e.target.value)
                                      }}
                                    />
                                    <span className='kiosk-template-label'><i></i>Others</span></label>
                                </div>

                              </div>

                            </div>

                          </div>


                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12'>

                        {btnActionRadio === 'page' ? (
                          <div className='img-collection scroll-styled-y'>
                            <ul className="list-group">

                              {btnActionPagesList.map((item, i) =>
                                <li className={`list-group-item kiosk-template-label clickable ${item.selected ? 'active' : ''} `}
                                  key={i}
                                  onClick={() => {
                                    addComponentAction(1, item.id);
                                  }}
                                >{item.name}</li>
                              )}

                            </ul>
                          </div>
                        ) :
                          btnActionRadio === 'category' ? (
                            <div className='img-collection scroll-styled-y'>
                              <ul className="list-group">

                                {btnActionCateList.map((item, i) =>
                                  <li className={`list-group-item kiosk-template-label clickable ${item.selected ? 'active' : ''} `}
                                    key={i}
                                    onClick={() => {
                                      addComponentAction(2, item.id);
                                    }}
                                  >{item.category}</li>
                                )}

                              </ul>
                            </div>
                          ) : (
                            <div className='img-collection scroll-styled-y'>
                              <ul className="list-group">

                                <li className={`list-group-item kiosk-template-label clickable ${selectedOtherAction === 4 ? 'active' : ''} `}
                                  onClick={() => {
                                    addComponentAction(4, '');
                                  }}
                                >Home</li>

                                <li className={`list-group-item kiosk-template-label clickable ${selectedOtherAction === 3 ? 'active' : ''}   `}
                                  onClick={() => {
                                    addComponentAction(3, '');
                                  }}
                                >Back</li>

                              </ul>
                            </div>
                          )
                        }

                      </div>
                    </div>
                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

      </div >


      {/* kiosks screen add modal */}
      <AddKiosksScreenModal
        showAddScreenModal={showAddScreenModal} setShowAddKiosksScreenModal={setShowAddKiosksScreenModal}
        newScreenIndex={newScreenIndex} selectedLang={selectedLang} templateId={templateId} getAllScreensByTemplate={getAllScreensByTemplate} />
    </>
  )
}


const mapStateToProps = state => ({
  miniSidebar: state.settings.miniSidebar,
  miniSideMenuOn: state.settings.miniSideMenuOn,
})

const mapDispatchToProps = dispatch => ({
  setMiniSidebar: (e) => dispatch(setMiniSidebar(e)),
  setMiniSidebarMenuOn: (e) => dispatch(setMiniSidebarMenuOn(e))
})

// export default DesignKiosksTemplate
export default connect(mapStateToProps, mapDispatchToProps)(DesignKiosksTemplate);