import React, { useEffect, useState } from 'react';
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Button from 'react-bootstrap-button-loader';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';

let today = new Date();
const showSecond = true;
const str = showSecond ? 'HH:mm:ss' : 'HH:mm';
const timeFormat = 'HH:mm:ss';

// used for add and edit
const MattercodeModal = ({ showMattercode, setShowMattercode, modalType, selectedMCodeType, getAllMatterCodes, getMatterCodesByMatterCodeTypeId, selectedRow, setSelectedRow }) => {

    // for time picker
    var date = "2022-06-02";
    var time = "00:00:00";
    var timeAndDate = moment(date + ' ' + time);
    const [timePickerDefaultValue, setTimePickerDefaultValue] = useState(timeAndDate);

    const [buttonLoader, setButtonLoader] = useState(false);
    const [baseUrl, setBaseUrl] = useState('');
    const [token, setToken] = useState('');
    const [userDetails, setUserDetails] = useState({});

    const [selectedMatType, setSelectedMatType] = useState('');
    const [duration, setDuration] = useState('');
    const [matterReason, setMatterReason] = useState('');

    const [allMattercodeTypes, setAllMattercodeTypes] = useState([]);
    const [matterReasonEdit, setMatterReasonEdit] = useState('');
    const [durationEdit, setDurationEdit] = useState('');


    const [isActive, setIsActive] = useState('');
    const [active, setActive] = useState(null);
    const [refresh, setRefresh] = useState(1);


    // get base url & token - useEffect
    useEffect(() => {
        if (showMattercode) {
            setBaseUrl(localStorage.getItem('API_URL'));
            setToken(localStorage.getItem('user_token'));
            setUserDetails(JSON.parse(localStorage.getItem('user_details')));
        }
    }, [showMattercode])

    // call getAllMattercodeTypes - useEffect
    useEffect(() => {
        if (baseUrl.length > 0 && token.length > 0) {
            getAllMatterCodesTypes();
        }
    }, [baseUrl, token]);


    // is update - useeffect
    useEffect(() => {
        if (modalType === 1) {
            if (Object.keys(selectedRow).length > 0) {
                console.log('++++++++++', selectedRow);
                setSelectedMatType(selectedRow.matterCodeType.id);
                setMatterReason(selectedRow.reason);
                
                if (selectedRow.duration !== undefined) {
                    setDuration(selectedRow.duration) 
                } else {
                    setDuration('');
                }
               
            } 
        }
    }, [selectedRow])


    //getAllMatterCodesTypes - function
    const getAllMatterCodesTypes = () => {
        console.log('getAllMatterCodesTypes function start');

        axios({
            method: 'GET',
            url: `${baseUrl}/customer/getAll/matter-code-type`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            console.log('getAllMatterCodesTypes success');
            console.log(res.data.data);

            setAllMattercodeTypes(res.data.data.list);


        }).catch((error) => {
            console.log('getAllMatterCodesTypes error');
            console.log(error);

            setButtonLoader(false);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
                setButtonLoader(false);
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
                setButtonLoader(false);
            }
        })

    }


    //addMatterCode - function
    const addMatterCode = () => {
        console.log('addMatterCode function Start');
        // alert('selectedMatType')

        let isValidate = validateData();
        if (isValidate) {

            axios({
                method: 'POST',
                url: `${baseUrl}/customer/add/matter-code`,
                headers: {
                    Authorization: token
                },
                data: {
                    customer: {
                        id: userDetails.cid,
                    },
                    duration: duration,
                    reason: matterReason,
                    matterCodeType: {
                        id: selectedMatType
                    }
                }

            }).then((res) => {
                console.log('addMatterCode success');
                console.log(res.data.data);

                toast.remove();
                toast.success('Successfully added', {
                    position: 'bottom-center',
                });

                setButtonLoader(false);

                if (Object.keys(selectedMCodeType).length === 0) {
                    getAllMatterCodes();
                } else {
                    getMatterCodesByMatterCodeTypeId(selectedMCodeType);
                }

                closeModal();

            }).catch((error) => {
                console.log('addMatterCode error');
                console.log(error);

                setButtonLoader(false);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                    setButtonLoader(false);
                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                    setButtonLoader(false);
                }
            })
        }
    }

    // validateData - function
    const validateData = () => {
        if (selectedMatType.length === 0) {
            toast.error('type empty', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            return false;
        } else if (matterReason.length === 0) {
            toast.error('reason empty', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            return false;
        } else if (Object.keys(userDetails).length === 0) {
            toast.error('Customer Id empty', {
                position: 'bottom-center',
            });
            setButtonLoader(false);
            return false;

        } else {
            return true;
        }
    };

    //updateMatterCode - function
    const updateMatterCode = () => {
        console.log('updateMatterCode function Start');
        setButtonLoader(true);

        axios({
            method: 'POST',
            url: `${baseUrl}/customer/update/matter-code`,
            headers: {
                Authorization: token
            },
            data: {
                "id": selectedRow.id,
                "reason": matterReason,
                "duration": duration,
                "deleted": false,
                "customerId": userDetails.cid,
                "matterCodeTypeId": selectedMatType
            }

        }).then((res) => {
            console.log('updateMatterCode success');
            console.log(res.data.data);

            toast.remove();
            toast.success('Successfully added', {
                position: 'bottom-center',
            });

            // getAllMatterCodes();
            // // getAllMatterCodesTypes();
            setButtonLoader(false);

            if (Object.keys(selectedMCodeType).length === 0) {
                getAllMatterCodes();
            } else {
                getMatterCodesByMatterCodeTypeId(selectedMCodeType);
            }

            closeModal();

        }).catch((error) => {
            console.log('updateMatterCode error');
            console.log(error);

            setButtonLoader(false);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
                setButtonLoader(false);
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
                setButtonLoader(false);
            }
        })
    }

    //closemodal - function
    const closeModal = () => {
        setSelectedMatType('');
        setDuration('');
        setMatterReason("");
        setShowMattercode(false);
        setIsActive("");
        setActive("");
        setSelectedRow('');

        // setIsActive(selectedRow.matterCodeType.id);
    };

    return (
        <>
            <div><Toaster /></div>
            {showMattercode ? (
                <div className={`modal fade d-block show modal-container`} id="category-modal">
                    <div className="modal-dialog " role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{modalType === 0 ? <>Add new
                                    Mattercode</> : <>Edit Mattercode</>}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        closeModal()
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">

                                <form className='matter-code-add-form '>
                                    <div className="row clearfix">
                                        <div className="col-lg-12 col-md-12">

                                            {allMattercodeTypes.map((matType, i) => (
                                                <li
                                                    key={i}
                                                    className={matType.id === selectedMatType ? "badge badge-secondary" : "badge badge-primary"}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setSelectedMatType(matType.id);
                                                        // console.log('_________',matType.id);
                                                    }}
                                                >
                                                    {matType.description}
                                                </li>
                                            ))}
                                        </div>
                                    </div>
                                    <br />

                                    <div className="row clearfix mb-4">
                                        <div className='col-md-12'>
                                            <div class="input-group mb-3">
                                                <p class="input-field-side-text">Duration : </p>

                                                {/* {Object.keys(selectedRow).length === 0 ? ( */}

                                                <TimePicker
                                                    value={duration.length > 0 ? moment(duration, timeFormat) : ''}
                                                    showSecond={true}
                                                    placeholder="HH:mm:ss"
                                                    className="custom-select modal-select-field"
                                                    defaultOpenValue={timePickerDefaultValue}
                                                    onChange={(value) => {

                                                        if (value === null) {
                                                            setDuration('');
                                                        } else {
                                                            setDuration(value.format("HH:mm:ss"));
                                                        }

                                                    }}
                                                />
                                                {/* // ) : (

                                                //     <TimePicker
                                                //         value={duration.length > 0 ? moment(duration, timeFormat) : ''}
                                                //         showSecond={true}
                                                //         placeholder="HH:mm:ss"
                                                //         className="custom-select modal-select-field"
                                                //         defaultOpenValue={timePickerDefaultValue}
                                                //         onChange={(value) => {
                                                //             setDuration(value.format("HH:mm:ss"));
                                                //         }}
                                                //     />
                                                // )} */}
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row clearfix">
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <textarea rows={10} className="form-control no-resize"
                                                    placeholder="Please type what you want..."
                                                    value={matterReason}
                                                    onChange={(e) => setMatterReason(e.target.value)}
                                                />
                                            </div>

                                        </div>
                                    </div>

                                </form>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-default"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >Cancel
                                </button>

                                {modalType === 0 ?
                                    <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                        onClick={() => {
                                            addMatterCode();
                                        }}
                                    >Save changes</Button>
                                    : modalType === 1 ?
                                        <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                            onClick={() => {
                                                updateMatterCode();
                                            }}
                                        >Update changes</Button>
                                        :
                                        (null)
                                }

                            </div>
                            
                        </div>
                    </div>
                </div>
            ) : null
            }


        </>

    );

}
export default MattercodeModal
