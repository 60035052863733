import React from 'react'

import Donutchart from '../../ChartsImpl/Analytics/CategoryAnalyticsDountchart';
import Linechart from '../../ChartsImpl/Analytics/CategoryAnalyticslinecharts';
import Datepicker from '../../common/datepicker';

const TokenBehavior = () => {
    return (
        <div className="row clearfix">
            <div className="col-sm-12">
                <div className="card">
                    <div className="body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <h6 className="mb-0">Tokens</h6>
                            </div>
                        </div>
                        <div className="row clearfix">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <small>Weekley or Monthly token behavior.</small>
                                <p><b>2023 July 2nd Week</b></p>
                                <Donutchart />
                            </div>
                            <div className="col-lg-8 col-md-12 col-sm-12">
                                <Linechart />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TokenBehavior