import React, { useEffect, useState } from 'react'
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import toast from 'react-hot-toast';
import Button from 'react-bootstrap-button-loader';
import axios from 'axios';

let today = new Date();
const showSecond = true;
const str = showSecond ? 'HH:mm:ss' : 'HH:mm';
const timeFormat = 'HH:mm:ss';

// used for add and edit
const AssignCategoryModal = ({ showAddCategoryModal, setShowAddCategoryModal, getAllCategoriesByBranch, modalType, selectedRow, setSelectedRow, dataTable }) => {


    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const selectedBranch = JSON.parse(localStorage.getItem('selected_branch'));

    const [buttonLoader, setButtonLoader] = useState(false);
    const [allUnassignedCategories, setAllUnassignedCategories] = useState([]);
    const [allCounterSounds, setAllCounterSounds] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState({});
    const [selectedCategoryWaitTime, setSelectedCategoryWaitTime] = useState('');
    const [selectedCategoryServTime, setSelectedCategoryServTime] = useState('');
    const [selectedCategoryMandatoryTime, setSelectedCategoryMandatoryTime] = useState('');
    const [selectedCategoryTicketRangeMin, setSelectedCategoryTicketRangeMin] = useState('');
    const [selectedCategoryTicketRangeMax, setSelectedCategoryTicketRangeMax] = useState('');
    const [selectedCategoryQSize, setSelectedCategoryQSize] = useState('');
    const [selectedCategoryVirtual, setSelectedCategoryVirtual] = useState(false);
    const [selectedCategoryShortCatName, setSelectedCategoryShortCatName] = useState('');
    const [selectedSound, setSelectedSound] = useState('');
    const [selectedSMSTemplate, setSelectedSMSTemplate] = useState('');


    // for time picker
    var date = "2022-06-02";
    var time = "00:00:00";
    var timeAndDate = moment(date + ' ' + time);
    const [timePickerDefaultValue, setTimePickerDefaultValue] = useState(timeAndDate);


    // call getUnassignedUsers - useEffect
    useEffect(() => {
        if (showAddCategoryModal) {
            if (baseUrl.length > 0 && token.length > 0 && Object.keys(selectedBranch).length > 0) {
                if (modalType === 0) {
                    unassignedCategories();
                }
                getCounterSounds();
            }
        }
    }, [showAddCategoryModal])

    // is update
    useEffect(() => {
        if (modalType === 1 || modalType === 2) {
            if (Object.keys(selectedRow).length > 0) {
                setSelectedCategoryWaitTime(selectedRow.waitTime);
                setSelectedCategoryServTime(selectedRow.serviceTime);

                if (selectedRow.mandatoryTime !== undefined) {
                    setSelectedCategoryMandatoryTime(selectedRow.mandatoryTime);
                } else {
                    setSelectedCategoryMandatoryTime('');
                }
                setSelectedCategoryTicketRangeMin(selectedRow.noRangeMin);
                setSelectedCategoryTicketRangeMax(selectedRow.noRangeMax);
                setSelectedCategoryQSize(selectedRow.queueSize);
                setSelectedCategoryShortCatName(selectedRow.shortCatName);
                setSelectedSound(selectedRow.sound);
                setSelectedCategoryVirtual(selectedRow.virtual);
            }
        }
    }, [selectedRow])


    // get unassignedCategories - function
    const unassignedCategories = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/category-master/get-remaining-categories/branch/${selectedBranch.id}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let dataList = res.data.data.list;
            setAllUnassignedCategories(dataList);

        }).catch((error) => {

            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // getCounterSounds - function
    const getCounterSounds = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/counter-voice/get-all/type/1`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let dataList = res.data.data.list;
            setAllCounterSounds(dataList);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // categoryDropdownOnClick - function
    const categoryDropdownOnClick = (e) => {
        if (e.target.value !== 'Choose...') {
            setSelectedCategory(allUnassignedCategories[e.target.value]);
            setSelectedCategoryWaitTime(allUnassignedCategories[e.target.value].waitTime);
            setSelectedCategoryServTime(allUnassignedCategories[e.target.value].serviceTime);
            setSelectedCategoryTicketRangeMin(allUnassignedCategories[e.target.value].noRangeMin);
            setSelectedCategoryTicketRangeMax(allUnassignedCategories[e.target.value].noRangeMax);
            setSelectedCategoryQSize(allUnassignedCategories[e.target.value].queueSize);
        }
    }

    // assignNewCategory - function
    const assignNewCategory = () => {

        let isValidate = validate();
        if (isValidate) {

            axios({
                method: 'POST',
                url: `${baseUrl}/category/add`,
                headers: {
                    Authorization: token
                },
                data: {
                    category: selectedCategory.category,
                    noRangeMin: selectedCategoryTicketRangeMin,
                    noRangeMax: selectedCategoryTicketRangeMax,
                    categoryCode: selectedCategory.categoryCode,
                    counterVoice: selectedSound,
                    waitTime: selectedCategoryWaitTime,
                    serviceTime: selectedCategoryServTime,
                    mandatoryTime: selectedCategoryMandatoryTime,
                    smsTemplate: selectedSMSTemplate,
                    shortCatName: selectedCategoryShortCatName,
                    queueSize: selectedCategoryQSize,
                    branch: {
                        id: selectedBranch.id
                    }
                }
            }).then((res) => {

                let data = res.data.data;

                setButtonLoader(false);
                closeModal();
                getAllCategoriesByBranch(0, null, null, dataTable.size);
                toast.remove();
                toast.success('Category successfully assigned', {
                    position: 'bottom-center',
                });

            }).catch((error) => {
                console.log(error);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }
            })
        }
    }

    // validate - function
    const validate = () => {

        if (selectedCategory.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error(' category Name empty', {
                position: 'bottom-center',
            });
            return false;

        } else if (selectedCategoryWaitTime.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('waitTime exception empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (selectedCategoryServTime.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Server Time exception empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (selectedCategoryMandatoryTime.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Category Mandatory Time empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (selectedCategoryTicketRangeMin.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Start Tickets empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (selectedCategoryTicketRangeMax.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('End Tickets empty', {
                position: 'bottom-center',
            });
            return false;

        } else if (selectedCategoryQSize === undefined) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Q Size  empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (selectedSound.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Sound name empty', {
                position: 'bottom-center',
            });
            return false;
        } else {
            return true;
        }
    }

    // updateCategory - function
    const updateCategory = () => {

        let isValidate = validate();
        if (isValidate) {

            axios({
                method: 'POST',
                url: `${baseUrl}/category/update`,
                headers: {
                    Authorization: token
                },
                data: {
                    id: selectedRow.id,
                    noRangeMin: selectedCategoryTicketRangeMin,
                    noRangeMax: selectedCategoryTicketRangeMax,
                    counterVoice: {
                        id: selectedSound
                    },
                    waitTime: selectedCategoryWaitTime,
                    serviceTime: selectedCategoryServTime,
                    mandatoryTime: selectedCategoryMandatoryTime,
                    queueSize: selectedCategoryQSize,
                    branch: {
                        id: selectedBranch.id
                    }
                }
            }).then((res) => {
                let data = res.data.data;

                setButtonLoader(false);
                closeModal();
                getAllCategoriesByBranch(dataTable.page, dataTable.order, dataTable.search, dataTable.size);
                toast.remove();
                toast.success('Category successfully updated', {
                    position: 'bottom-center',
                });

            }).catch((error) => {
                console.log(error);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }
            })

        }
    }

    // closeModal - function
    const closeModal = () => {
        setSelectedCategory({});
        setSelectedCategoryWaitTime('');
        setSelectedCategoryServTime('');
        setSelectedCategoryMandatoryTime('');
        setSelectedCategoryTicketRangeMin('');
        setSelectedCategoryTicketRangeMax('');
        setSelectedCategoryQSize('');
        setSelectedSound('');
        setSelectedSMSTemplate('');

        setSelectedRow({});

        setShowAddCategoryModal(false);
    }

    return (
        <>
            {showAddCategoryModal ? (
                <div className={`modal fade d-block show modal-container`} id="category-modal">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">

                            <div className="modal-header">

                                <h5 className='modal-title' id="exampleModalLabel">
                                    {modalType === 0 ? <>Assign Category</> : modalType === 1 ? <>Edit Assigned Category</> : <>View Assign Category</>}
                                </h5>

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className={`modal-body ${modalType === 2 ? 'no-click' : ''}`}>

                                {/* {modalType === 0 ? ( */}
                                <form className='category-add-form mt-3'>

                                    <div className="container-fluid">

                                        <div className="row clearfix">
                                            <div className="col-md-12 col-sm-12">
                                                <div className="input-group mb-3">
                                                    <p className="input-field-side-text">Category : </p>
                                                    {Object.keys(selectedRow).length === 0 ? (
                                                        <select className="custom-select modal-select-field" id="inputGroupSelect01"
                                                            onChange={(e) => {
                                                                categoryDropdownOnClick(e);
                                                            }}
                                                        >
                                                            <option defaultValue hidden>Choose...</option>
                                                            {allUnassignedCategories.map((item, index) =>
                                                                <option value={index} key={index}>
                                                                    {item.category}
                                                                </option>
                                                            )}

                                                        </select>

                                                    ) : (
                                                        <input disabled type="text" className="form-control" aria-label="Small"
                                                            aria-describedby="inputGroup-sizing-sm" value={selectedRow.category} />
                                                    )
                                                    }


                                                </div>
                                            </div>
                                        </div>

                                        <div className="row clearfix">

                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group mb-3">
                                                    <p className="input-field-side-text">Wait Time Exception
                                                        : </p>

                                                    <TimePicker
                                                        value={selectedCategoryWaitTime.length > 0 ? moment(selectedCategoryWaitTime, timeFormat) : ''}
                                                        showSecond={true}
                                                        placeholder="HH:mm:ss"
                                                        className="custom-select modal-select-field"
                                                        defaultOpenValue={timePickerDefaultValue}
                                                        onChange={(value) => {
                                                            if (value === null) {
                                                                setSelectedCategoryWaitTime('');
                                                            } else {
                                                                setSelectedCategoryWaitTime(value.format("HH:mm:ss"));
                                                            }
                                                        }}
                                                    />

                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group mb-3">
                                                    <p className="input-field-side-text">Service Time Exception
                                                        : </p>
                                                    <TimePicker
                                                        value={selectedCategoryServTime.length > 0 ? moment(selectedCategoryServTime, timeFormat) : ''}
                                                        showSecond={true}
                                                        placeholder="HH:mm:ss"
                                                        className="custom-select modal-select-field"
                                                        defaultOpenValue={timePickerDefaultValue}
                                                        onChange={(value) => {
                                                            if (value === null) {
                                                                setSelectedCategoryServTime('');
                                                            } else {
                                                                setSelectedCategoryServTime(value.format("HH:mm:ss"));
                                                            }
                                                        }}
                                                    />

                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group mb-3">
                                                    <p className="input-field-side-text">Mandatory Time : </p>

                                                    <TimePicker
                                                        value={selectedCategoryMandatoryTime.length > 0 ? moment(selectedCategoryMandatoryTime, timeFormat) : ''}
                                                        showSecond={true}
                                                        placeholder="HH:mm:ss"
                                                        className="custom-select modal-select-field"
                                                        defaultOpenValue={timePickerDefaultValue}
                                                        onChange={(value) => {
                                                            if (value === null) {
                                                                setSelectedCategoryMandatoryTime('');
                                                            } else {
                                                                setSelectedCategoryMandatoryTime(value.format("HH:mm:ss"));
                                                            }
                                                        }}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">

                                                <div className="input-group input-group-sm mb-3">
                                                    <div className="input-group">
                                                        <p className="input-field-side-text">Default Start Ticket : </p>
                                                        <input type='number' className="form-control" aria-label="Small"
                                                            value={selectedCategoryTicketRangeMin}
                                                            aria-describedby="inputGroup-sizing-sm"
                                                            onChange={(e) => {
                                                                setSelectedCategoryTicketRangeMin(e.target.value);
                                                            }}
                                                        >
                                                        </input>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="row clearfix">


                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group input-group-sm mb-3">
                                                    <div className="input-group">
                                                        <p className="input-field-side-text">Default End Ticket : </p>
                                                        <input type='number' className="form-control" aria-label="Small"
                                                            value={selectedCategoryTicketRangeMax}
                                                            aria-describedby="inputGroup-sizing-sm"
                                                            onChange={(e) => {
                                                                setSelectedCategoryTicketRangeMax(e.target.value);
                                                            }}
                                                        >
                                                        </input>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group input-group-sm mb-3">
                                                    <div className="input-group">
                                                        <p className="input-field-side-text">Default Queue Size : </p>
                                                        <input type='number' className="form-control" aria-label="Small"
                                                            value={selectedCategoryQSize}
                                                            aria-describedby="inputGroup-sizing-sm"
                                                            onChange={(e) => {
                                                                setSelectedCategoryQSize(e.target.value);
                                                            }}
                                                        >
                                                        </input>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row clearfix">
                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group mb-3">
                                                    <p className="input-field-side-text">Sound : </p>
                                                    <select className="custom-select modal-select-field"
                                                        id="inputGroupSelect01"
                                                        value={selectedSound}
                                                        onChange={(e) => {
                                                            setSelectedSound(e.target.value)
                                                        }}
                                                    >
                                                        <option hidden>Choose...</option>
                                                        {
                                                            allCounterSounds.map((item, i) => (
                                                                <option value={item.id}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className='input-group mb-3'>
                                                    <div className="input-group mb-3">
                                                        <p className="input-field-side-text">Virtual : </p>
                                                        <label
                                                            className="switch custom-swtch" title="Select All">
                                                            <input type="checkbox"
                                                                checked={selectedCategoryVirtual ? true : false}
                                                                onChange={(e) => {
                                                                    setSelectedCategoryVirtual(e.target.checked);
                                                                }}

                                                            />
                                                            <span className='slider'
                                                            >Virtual : </span>

                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-default"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >Cancel
                                </button>

                                {modalType === 0 ?
                                    (<Button loading={buttonLoader} type="button" className="btn btn-primary"
                                        onClick={() => {
                                            assignNewCategory();
                                        }}
                                    >Save changes</Button>)

                                    : modalType === 1 ?
                                        (<Button loading={buttonLoader} type="button" className="btn btn-primary"
                                            onClick={() => {
                                                updateCategory();
                                            }}
                                        >Update</Button>)

                                        :
                                        (null)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            ) : null
            }
        </>
    )
}

export default AssignCategoryModal