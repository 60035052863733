import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddBranchModal from '../ModalImpl/AddBranchModal';
import AddCountryModal from '../ModalImpl/AddCountryModal';
import AddDistrictModal from '../ModalImpl/AddDistrictModal';
import AddProvinceModal from '../ModalImpl/AddProvinceModal';
import toast, { Toaster } from 'react-hot-toast';
import Swal from "sweetalert2";

const RegionManagement = () => {

  const history = useNavigate();

  const baseUrl = localStorage.getItem('API_URL');
  const token = localStorage.getItem('user_token');
  let userDetails = JSON.parse(localStorage.getItem('user_details'));

  //add modals
  const [addCountryModal, setAddCountryModal] = useState(false);
  const [addProvinceModal, setAddProvinceModal] = useState(false);
  const [addDistrictModal, setAddDistrictModal] = useState(false);
  const [addBranchModal, setAddBranchModal] = useState(false);

  const [allCountries, setAllCountries] = useState([]);
  const [allProvince, setAllProvince] = useState([]);
  const [allDistrict, setAllDistrict] = useState([]);
  const [allBranches, setAllBranches] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedProvince, setSelectedProvince] = useState({});
  const [selectedDistrict, setSelectedDistrict] = useState({});
  const [selectedBranch, setSelectedBranch] = useState({});

  const [displayTipCountry, setDisplayTipCountry] = useState("Add new country");
  const [displayTipProvince, setDisplayTipProvince] = useState("Please select a country");
  const [displayTipDistrict, setDisplayTipDistrict] = useState("Please select a province");
  const [displayTipBranch, setDisplayTipBranch] = useState("Please select a district");
  const [modalType, setmodalType] = useState(); // 0-add, 1-edit


  //get base url - useEffect
  useEffect(() => {
    if (token !== null && userDetails !== null) {
      if (userDetails['branchLevel'] === 4) {
        localStorage.setItem('selected_branch', JSON.stringify({ 'id': userDetails.bid, 'BranchName': userDetails.branchName }));
        history(`/branch-landing?bid=${userDetails['bid']}`)
      }
    }
  }, [RegionManagement])

  //call getAll to get countries
  useEffect(() => {
    if (token !== null && userDetails !== null) {
      if (baseUrl.length > 0 && token.length > 0)
        getAll(1, 0);
    }
  }, [baseUrl, token])

  //when click one country, get relavant provices - useEffect
  useEffect(() => {
    if (Object.keys(selectedCountry).length > 0) {
      getAll(2, selectedCountry.id)
    }
  }, [selectedCountry])

  //when click one province, get relavant districts - useEffect
  useEffect(() => {
    if (Object.keys(selectedProvince).length > 0) {
      getAll(3, selectedProvince.id)
    }
  }, [selectedProvince])

  //when click one district, get relavant branches - useEffect
  useEffect(() => {
    if (Object.keys(selectedDistrict).length > 0) {
      getAll(4, selectedDistrict.id)
    }
  }, [selectedDistrict])

  //when select branch, view branch landing page - useEffect
  useEffect(() => {
    if (Object.keys(selectedBranch).length > 0) {
      // save selected branch details in local storage
      let local = {
        id: selectedBranch.id,
        BranchName: selectedBranch.name
      }
      localStorage.setItem('selected_branch', JSON.stringify(local));
      history(`/branch-landing?bid=${selectedBranch.id}`)
    }
  }, [selectedBranch])

  //getAll - function
  function getAll(level, parentId) {

    axios({
      url: `${baseUrl}/branch/get-all/parent-branch/${parentId}`,
      method: 'GET',
      headers: {
        Authorization: token
      }
    }).then((res) => {

      const result = res.data.data

      if (level === 1) {

        if (result !== null) {
          setAllCountries(result);
        }

      } else if (level === 2) {

        if (result === null) {
          setAllProvince([]);
          setDisplayTipProvince("Add new province")
        } else {
          setAllProvince(result);
        }

      } else if (level === 3) {

        if (result === null) {
          setAllDistrict([]);
          setDisplayTipDistrict("Add new district")
        } else {
          setAllDistrict(result);
        }

      } else if (level === 4) {

        if (result === null) {
          setAllBranches([]);
          setDisplayTipBranch("Add new branch")
        } else {
          setAllBranches(result);
        }

      }

    }).catch((error) => {
      console.log(error);

      if (error.response !== undefined) {
        toast.remove();
        toast.error(error.response.data.message, {
          position: 'bottom-center',
        });
      } else {
        toast.remove();
        toast.error('Something went wrong', {
          position: 'bottom-center',
        });
      }
    })
  }

  //clearStates - function
  const clearStates = (level) => {
    if (level === 1) {
      setAllProvince([]);
      setAllDistrict([]);
      setAllBranches([]);

      setSelectedProvince({});
      setSelectedDistrict({});
      setSelectedBranch({});
    } else if (level === 2) {
      setAllDistrict([]);
      setAllBranches([]);

      setSelectedDistrict({});
      setSelectedBranch({});
    }
  }

  //deleteRegion - function
  const deleteRegion = (id, level) => {

    Swal.fire({
      position: 'center',
      text: 'Do you want to delete this Country?',
      showConfirmButton: true,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
      width: '400px',

    }).then((result) => {
      if (result.value == true) {

        axios({
          method: 'POST',
          url: `${baseUrl}/branch/restrict-branch/branch/${id}/delete`,
          headers: {
            Authorization: token
          }
        }).then((res) => {

          toast.remove();
          toast.success('Country deleted Successfully ', {
            position: 'bottom-center',
          });
          // getAll();
          if (level === 1) {
            const newArray = allCountries.filter((country) => country.id !== id);
            setAllCountries(newArray);
          } else if (level === 2) {
            const newArray = allProvince.filter((provice) => provice.id !== id);
            setAllProvince(newArray);
          } else if (level === 3) {
            const newArray = allDistrict.filter((district) => district.id !== id);
            setAllDistrict(newArray);
          }

        }).catch((error) => {
          console.log(error);

          if (error.response !== undefined) {
            toast.remove();
            toast.error(error.response.data.message, {
              position: 'bottom-center',
            });
          } else {
            toast.remove();
            toast.error('Something went wrong', {
              position: 'bottom-center',
            });
          }
        })
      }
    })
  }


  return (
    <>
      <div><Toaster /></div>
      <div className="container-fluid">

        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h2>Region Management</h2>
            </div>
          </div>
        </div>

        <div className="row clearfix">

          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card">
              <div className="body taskboard b-cyan progress_task">
                <h6 className="font300 mb-3">Country
                  <span className="float-right">
                    <i className="fa fa-plus-circle custom-icon" title='Add New County'
                      onClick={() => {
                        setmodalType(0);
                        setAddCountryModal(true);
                      }}
                    ></i>
                  </span>
                </h6>
                <div className="dd" data-plugin="nestable">
                  {allCountries.length > 0 ? (
                    <>
                      {allCountries.map((country, i) => (
                        <div
                          className={`dd-handle align-items-center ${selectedCountry.id === country.id ? 'active' : ''}`}
                          key={country.id}
                          onClick={() => {
                            if (selectedCountry !== country) {
                              clearStates(1);
                              setDisplayTipDistrict('Please select a province')
                              setDisplayTipBranch('Please select a district')
                              setSelectedCountry(country);
                            }
                          }}
                        >
                          <div className='d-flex justify-content-between'>
                            <div> {country.name} </div>
                            <div className="action">
                              <i className="fa fa-edit action-icon" title='Edit'
                                onClick={() => {
                                  setmodalType(1);
                                  setAddCountryModal(true);
                                }}
                              />
                              <i className="fa fa-trash-o action-icon" title='Delete'
                                onClick={() => {
                                  deleteRegion(country.id, 1);
                                }}
                              />
                            </div>

                          </div>
                          <span href="/" className="text-muted"><i
                            className="zmdi zmdi-check-square" /> {country.count} </span>
                        </div>
                      ))}
                    </>

                  ) : (

                    <div className="dd-handle align-items-center text-center">
                      <div className='d-flex justify-content-between'>
                        <div> {displayTipCountry} </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card">
              <div className="body taskboard b-orange completed_task">
                <h6 className="font300 mb-3">Province
                  <span className="float-right">
                    <i className="fa fa-plus-circle custom-icon" title='Add New Province'
                      style={Object.keys(selectedCountry).length === 0 ? { display: 'none' } : {}}
                      onClick={() => {
                        setmodalType(0);
                        setAddProvinceModal(true);
                      }}
                    ></i>
                  </span>
                </h6>
                <div className="dd" data-plugin="nestable">

                  {allProvince.length > 0 ? (
                    <>
                      {
                        allProvince.map((provice, i) => (

                          <div
                            className={`dd-handle align-items-center ${selectedProvince.id === provice.id ? 'active' : ''}`}
                            key={provice.id}
                            onClick={() => {

                              if (selectedProvince !== provice) {
                                clearStates(2);

                                setSelectedProvince(provice);
                              }
                            }}
                          >
                            <div className='d-flex justify-content-between'>
                              <div> {provice.name} </div>
                              <div className="action">
                                <i className="fa fa-edit action-icon" title='Edit'
                                  onClick={() => {
                                    setmodalType(1);
                                    setAddProvinceModal(true);
                                  }}
                                />
                                <i className="fa fa-trash-o action-icon" title='Delete'
                                  onClick={() => {
                                    deleteRegion(provice.id, 2);
                                  }}
                                />
                              </div>
                            </div>

                            <span href="/" className="text-muted"><i
                              className="zmdi zmdi-check-square" /> {provice.count} </span>
                          </div>
                        ))
                      }
                    </>

                  ) : (

                    <div className="dd-handle align-items-center text-center">
                      <div className='d-flex justify-content-between'>
                        <div> {displayTipProvince} </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card">
              <div className="body taskboard b-green incompleted_task">
                <h6 className="font300 mb-3"> District
                  <span className="float-right">
                    <i className="fa fa-plus-circle custom-icon" title='Add New Distric'
                      style={Object.keys(selectedProvince).length === 0 ? { display: 'none' } : {}}
                      onClick={() => {
                        setmodalType(0);
                        setAddDistrictModal(true);
                      }}
                    ></i>
                  </span>
                </h6>

                <div className="dd" data-plugin="nestable">
                  {allDistrict.length > 0 ? (
                    <>
                      {
                        allDistrict.map((district, i) => (

                          <div
                            className={`dd-handle align-items-center ${selectedDistrict.id === district.id ? 'active' : ''}`}
                            key={district.id}
                            onClick={() => {
                              setSelectedDistrict(district);
                            }}
                          >
                            <div className='d-flex justify-content-between'>
                              <div> {district.name} </div>
                              <div className="action">
                                <i className="fa fa-edit action-icon" title='Edit'
                                  onClick={() => {
                                    setmodalType(1);
                                    setAddDistrictModal(true);
                                  }}
                                />
                                <i className="fa fa-trash-o action-icon" title='Delete'
                                  onClick={() => {
                                    deleteRegion(district.id, 3);
                                  }}
                                />
                              </div>
                            </div>
                            <span href="/" className="text-muted"><i
                              className="zmdi zmdi-check-square" /> {district.count} </span>
                          </div>
                        ))
                      }
                    </>

                  ) : (

                    <div className="dd-handle align-items-center text-center">
                      <div className='d-flex justify-content-between'>
                        <div> {displayTipDistrict} </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card">
              <div className="body taskboard b-red incompleted_task">
                <h6 className="font300 mb-3"> Branch
                  <span className="float-right">
                    <i className="fa fa-plus-circle custom-icon" title='Add New Branch'
                      style={Object.keys(selectedDistrict).length === 0 ? { display: 'none' } : {}}
                      onClick={() => {
                        setmodalType(0);
                        setAddBranchModal(true);
                      }}
                    ></i>
                  </span>
                </h6>
                <div className="dd" data-plugin="nestable">
                  {allBranches.length > 0 ? (
                    <>
                      {
                        allBranches.map((branch, i) => (

                          <div
                            className={`dd-handle align-items-center ${selectedBranch.id === branch.id ? 'active' : ''}`}
                            title={`View - ${branch.name}`}
                            key={branch.id}
                            onClick={() => {
                              setmodalType(0);
                              setSelectedBranch(branch);
                            }}
                          >
                            <div className='d-flex justify-content-between'>
                              <div> {branch.name} </div>
                              <div className="action">
                                <i className="fa fa fa-eye action-icon" title='View Branch Details' />
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </>

                  ) : (

                    <div className="dd-handle align-items-center text-center">
                      <div className='d-flex justify-content-between'>
                        <div> {displayTipBranch} </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <AddCountryModal addCountryModal={addCountryModal} setAddCountryModal={setAddCountryModal} getAll={getAll}
          selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry}
          clearStates={clearStates}
          modalType={modalType} />
        <AddProvinceModal addProvinceModal={addProvinceModal} setAddProvinceModal={setAddProvinceModal}
          getAll={getAll} selectedCountry={selectedCountry} selectedProvince={selectedProvince}
          clearStates={clearStates}
          setSelectedProvince={setSelectedProvince} modalType={modalType} />
        <AddDistrictModal addDistrictModal={addDistrictModal} setAddDistrictModal={setAddDistrictModal}
          getAll={getAll} selectedCountry={selectedCountry} selectedProvince={selectedProvince}
          selectedDistrict={selectedDistrict} setSelectedDistrict={setSelectedDistrict}
          modalType={modalType} />
        <AddBranchModal addBranchModal={addBranchModal} setAddBranchModal={setAddBranchModal} getAll={getAll}
          selectedProvince={selectedProvince} selectedDistrict={selectedDistrict}
          selectedBranch={selectedBranch} modalType={modalType} />

      </div>
    </>
  )
}

export default RegionManagement;