import axios from "axios";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';


const AssignNotificationAccessibilityModal = ({ showAssignNotificationAccessibility, setShowAssignNotificationAccessibility, userBid, userId, getassignedaccesibiltiesbyuser}) => {


    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');

    const [refresh, setRefresh] = useState(1);
    const [id, setid] = useState('');
    const [allnotificationAccessibility, setllnotificationAccessibility] = useState('');



    //getAccessibilitiesByUser - useeffect
    useEffect(() => {
        if (showAssignNotificationAccessibility) {
            getAccessibilitiesByUser();
        }
    }, [showAssignNotificationAccessibility])


    //getAccessibilitiesByUser - function
    const getAccessibilitiesByUser = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/alert/notification/get-list/accessibility/branch/${userBid}/user/${userId}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            let dataList = res.data.data;
            setllnotificationAccessibility(dataList);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }


    //addUserNotificationAccessibility - function
    const addUserNotificationAccessibility = () => {

        let id = filterSelectedAccessibility();

        axios({
            method: 'POST',
            url: `${baseUrl}/alert/add/notification-accissibility/user/${userId}`,
            headers: {
                Authorization: token,
            },
            data: {
                configs: id,
            }

        }).then((res) => {

            toast.remove();
            toast.success('successfully Save!', {
                position: 'bottom-center',
            });
            getassignedaccesibiltiesbyuser();
            closeModal();

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //filterSelectedAccessibility - function
    const filterSelectedAccessibility = () => {

        let selectedId = [];
        for (let i = 0; i < allnotificationAccessibility.length; i++) {
            if (allnotificationAccessibility[i].selected === true) {
                selectedId.push(allnotificationAccessibility[i].id)
            }
        }
        return selectedId;

    }


    //closeModal - function
    const closeModal = () => {
        setShowAssignNotificationAccessibility(false)
    }


    return (
        <>
            <div><Toaster /></div>
            {showAssignNotificationAccessibility ?
                <div className={`modal fade  d-block show modal-container`} id="exampleModal">
                    <div className="modal-dialog " role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Assign Notification Accessibility</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        closeModal()
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row clearfix">
                                    {
                                        allnotificationAccessibility.length > 0 ? (
                                            allnotificationAccessibility.map((accessibility, i) => (
                                                <div className='col-lg-6 col-md-6 mb-2' key={i}>
                                                    <div
                                                        className={`list-group-item d-flex justify-content-between align-items-center cursor-pointer ${accessibility.selected ? 'active' : ''}`}
                                                        onClick={() => {
                                                            console.log("i: ", i);
                                                            console.log('++++++++++++++', accessibility.selected);
                                                            //get category id first
                                                            setid(accessibility.selected);
                                                            accessibility.selected = !accessibility.selected;
                                                            setRefresh(refresh + 1);

                                                        }}
                                                    >
                                                        {accessibility.typeName}
                                                    </div>
                                                </div>
                                            ))

                                        ) : (
                                            <div className="col-md-12">
                                                <div className="alert alert-custom alert-dismissible" role="alert">
                                                    <i className="fa fa-info-circle"></i> Empty records
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary"
                                    onClick={() => {
                                        addUserNotificationAccessibility();
                                    }}
                                >Update
                                </button>

                            </div>

                        </div>
                    </div>
                </div>
                : ''}
        </>
    )
}

export default AssignNotificationAccessibilityModal