import React from 'react';
import {Text} from 'react-konva';

const TextShapeMini = ({shapeProps, isSelected, text, textProps}) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>
            <Text
                verticalAlign={'middle'}
                fontFamily={textProps.fontFamily}
                align={textProps.align}
                fontSize={textProps.fontSize}
                text={text}
                ref={shapeRef}
                {...shapeProps}

            />

        </React.Fragment>
    );
};

export default TextShapeMini