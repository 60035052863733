import axios from 'axios';
import React, { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Button from 'react-bootstrap-button-loader';

// used for add and edit
const AddDistrictModal = ({addDistrictModal, setAddDistrictModal, getAll, selectedCountry, selectedProvince, selectedDistrict, setSelectedDistrict, modalType}) => {

  const [baseUrl, setBaseUrl] = useState('');
  const [token, setToken] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const [buttonLoader, setButtonLoader] = useState(false);

  const [districtName, setDistrictName] = useState('');

  // if modal type is edit, set value
  useEffect(() => {
    if (modalType === 1) {
      setDistrictName(selectedDistrict.name)
    }
  }, [selectedDistrict]);

  // get base url & token
  useEffect(() => {
    setBaseUrl(localStorage.getItem('API_URL'));
    setToken(localStorage.getItem('user_token'));
    setUserDetails(JSON.parse(localStorage.getItem('user_details')));
  }, [AddDistrictModal])

  // addNewDistrict - function
  const addNewDistrict = () => {
    console.log('addNewDistrict function start');
    setButtonLoader(true);

    // validate
    if (districtName.length > 0) {

      axios({
        url: `${baseUrl}/branch/add/secondary/parent/${selectedProvince.id}`,
        method: 'POST',
        headers: {
          Authorization: token
        },
        data: {
          name: districtName,
          level: 3
        }
      }).then((res) => {

        console.log('>> addNewDistrict success');
        setSelectedDistrict(res.data.data);
        setButtonLoader(false);
        closeModal();
        toast.remove();
        toast.success('Successfully added', {
          position: 'bottom-center',
        });

        getAll(2, selectedCountry.id);
        getAll(3, selectedProvince.id);

      }).catch((error) => {
        console.log('>> addNewDistrict error');
        console.log(error);
        setButtonLoader(false);

        if (error.response !== undefined) {
          toast.remove();
          toast.error(error.response.data.message, {
            position: 'bottom-center',
          });
        } else {
          toast.remove();
          toast.error('Something went wrong', {
            position: 'bottom-center',
          });
        }
      })

    } else {
      setButtonLoader(false);
      toast.remove();
      toast.error('District name can not be empty', {
        position: 'bottom-center',
      });
    }
  }

  // editDistrict - function
  const editDistrict = () => {
    console.log('editDistrict function working');
    setButtonLoader(true);

    // validate
    if (districtName.length > 0) {

      if (districtName === selectedDistrict.name) {
        setButtonLoader(false);
        toast.remove();
        toast.error('Same district name. No need to update', {
          position: 'bottom-center',
        });

      } else {

        axios({
          url: `${baseUrl}/branch/update/region`,
          method: 'POST',
          headers: {
            Authorization: token
          },
          data: {
            id: selectedDistrict.id,
            name: districtName,
            customer: {
              id: userDetails.cid
            }
          }
        }).then((res) => {

          console.log('>> editDistrict success');
          setButtonLoader(false);
          closeModal();
          toast.remove();
          toast.success('Successfully updated', {
            position: 'bottom-center',
          });

          getAll(2, selectedCountry.id);
          getAll(3, selectedProvince.id);

        }).catch((error) => {
          console.log('>> editDistrict error');
          console.log(error);
          setButtonLoader(false);

          if (error.response !== undefined) {
            toast.remove();
            toast.error(error.response.data.message, {
              position: 'bottom-center',
            });
          } else {
            toast.remove();
            toast.error('Something went wrong', {
              position: 'bottom-center',
            });
          }
        })

      }

    } else {
      setButtonLoader(false);
      toast.remove();
      toast.error('District name can not be empty', {
        position: 'bottom-center',
      });
    }

  }

  // closeModal - function
  function closeModal() {
    setAddDistrictModal(false);
  }

  // when click esc, close modal
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setAddDistrictModal(false);
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  return (
    <>
      {addDistrictModal ?
        <div className={`modal fade  d-block show modal-container`} id="exampleModal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">

                {modalType === 0 ? (
                  <h5 className="modal-title" id="exampleModalLabel"> Add New District</h5>
                ) : (
                  <h5 className="modal-title" id="exampleModalLabel"> Edit District</h5>
                )}

                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                  onClick={() => {
                    closeModal()
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">

                <div className="input-group input-group-sm mb-3 modal-input-group">
                  <p className='input-field-side-text'>District Name : </p>

                  {modalType === 0 ? (
                    // add text field
                    <input autoFocus type="text" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                      onChange={(e) => {
                        setDistrictName(e.target.value);
                      }}
                    />
                  ) : (
                    // edit text field
                    <input autoFocus type="text" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                      value={districtName}
                      onChange={(e) => {
                        setDistrictName(e.target.value);
                      }}
                    />
                  )}

                </div>

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal"
                        onClick={() => {
                    closeModal()
                  }}>Cancel</button>

                {modalType === 0 ? (
                    <Button loading={buttonLoader} type="button" className="btn btn-primary"
                            onClick={() => {
                      addNewDistrict();
                    }}
                    >Add District</Button>
                ) : (
                    <Button loading={buttonLoader} type="button" className="btn btn-primary"
                            onClick={() => {
                      editDistrict();
                    }}
                    >Edit District</Button>
                )}

              </div>
            </div>
          </div>
        </div>
        : ''}
    </>
  )
}

export default AddDistrictModal;