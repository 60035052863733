import React, { useState, useEffect, useRef } from 'react'
import AddKiosksTemplateModal from '../ModalImpl/AddKiosksTemplateModal';
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { Stage, Layer, Image, Line } from 'react-konva';
import useImage from 'use-image';
import TextShapeMini from '../ComponentsImpl/ReactKonva/TextShapeMini';
import URLImageMini from '../ComponentsImpl/ReactKonva/URLImageMini';
import RectangleMini from '../ComponentsImpl/ReactKonva/RectangleMini';

import { DropdownToggle, DropdownMenu, Card, UncontrolledDropdown, DropdownItem, Progress } from "reactstrap";
import ExistingKiosksTemplateModal from '../ModalImpl/ExistingKiosksTemplateModal';


const KiosksTemplates = () => {

  const renameRef = useRef(null);
  const history = useNavigate();

  const baseUrl = localStorage.getItem('API_URL');
  const token = localStorage.getItem('user_token');
  const selectedBranch = JSON.parse(localStorage.getItem('selected_branch'));

  const [modalType, setModalType] = useState();
  const [showAddKiosksTemplateModal, setShowAddKiosksTemplateModal] = useState(false);
  const [selectedTemplateId, setselectedTemplateId] = useState('');

  const [existingKiosksTemplatesModal, setExistingKiosksTemplatesModal] = useState(false);

  const [allTemplates, setAllTemplates] = useState([]);

  const [templateName, setTemplateName] = useState('');

  const [renameTemplateId, setRenameTemplateId] = useState('');
  const [refresh, setRefresh] = useState(0);

  const stageRef = React.useRef();


  //call getAllTemplatesByBranch
  useEffect(() => {
    if (token !== null) {
      if (baseUrl.length > 0 && token.length > 0 && Object.keys(selectedBranch).length > 0) {
        getAllTemplatesByBranch();
      }
    }
  }, [KiosksTemplates])


  // getAllTemplatesByBranch - function
  const getAllTemplatesByBranch = () => {

    axios({
      method: 'GET',
      url: `${baseUrl}/kiosk/kiosk-design/get-all/branch/${selectedBranch.id}`,
      headers: {
        Authorization: token
      },
    }).then((res) => {

      let data = res.data.data;

      let newScreenList = resizeComponentsForScreenPreview(data);
      setAllTemplates(newScreenList);

    }).catch((error) => {
      console.log(error);

      if (error.response !== undefined) {
        toast.remove();
        toast.error(error.response.data.message, {
          position: 'bottom-center',
        });
      } else {
        toast.remove();
        toast.error('Something went wrong', {
          position: 'bottom-center',
        });
      }
    })

  }

  // after get all templates, resize components for screen preview - function
  const resizeComponentsForScreenPreview = (screenList) => {

    for (let j = 0; j < screenList.length; j++) {

      let screenComponentList = screenList[j].components;

      let tempArray = [];

      if (screenList[j].landscape) {
        screenList[j].gridWidth = 630;
        screenList[j].gridHeight = 390;
      } else {
        screenList[j].gridWidth = 390;
        screenList[j].gridHeight = 630;
      }

      for (let i = 0; i < screenComponentList.length; i++) {
        const component = screenComponentList[i];

        if (component.type === 'rect') {

          var object = {
            fill: hex2rgba(component.fill),
            height: component.h / 4,
            hex: component.fill,
            id: component.id,
            name: component.name,
            rgba: hex2rgbaobject(component.fill),
            type: component.type,
            width: component.w / 4,
            x: component.x / 4,
            y: component.y / 4
          }
          tempArray.push(object);

        } else if (component.type === 'img' || component.type === 'btn') {

          var object = {
            height: component.h / 4,
            // hex: component.fill,
            id: component.id,
            imgId: component.imageId,
            name: component.name,
            // rgba: hex2rgbaobject(component.fill),
            src: component.imageUrl,
            type: component.type,
            width: component.w / 4,
            x: component.x / 4,
            y: component.y / 4
          }
          tempArray.push(object);

        } else if (component.type === 'text') {

          var object = {
            fill: hex2rgba(component.componentText.color),
            height: component.h / 4,
            hex: component.componentText.color,
            id: component.id,
            name: component.name,
            rgba: hex2rgbaobject(component.componentText.color),
            text: component.componentText.text,
            textProps: {
              fontSize: component.componentText.size / 4,
              align: component.componentText.align,
              fontFamily: component.componentText.font.name,
            },
            type: component.type,
            width: component.w / 4,
            x: component.x / 4,
            y: component.y / 4
          }
          tempArray.push(object);
        }

      }

      screenList[j].components = tempArray;

    }

    return screenList;

  }

  // editTemplate - function
  const editTemplate = (id) => {
    history(`/design-kiosks-template?t_id=${id}`)
  }

  // deleteTemplate - function
  const deleteTemplate = (id) => {

    Swal.fire({
      position: 'center',
      text: 'Do you want to delete this template?',
      showConfirmButton: true,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
      width: '400px',

    }).then((result) => {
      if (result.value == true) {

        axios({
          method: 'POST',
          url: `${baseUrl}/kiosk/kiosk-design/${id}/delete`,
          headers: {
            Authorization: token
          },
        }).then((res) => {

          // removeFromStateArray(id);
          getAllTemplatesByBranch();

        }).catch((error) => {
          console.log(error);

          if (error.response !== undefined) {
            toast.remove();
            toast.error(error.response.data.message, {
              position: 'bottom-center',
            });
          } else {
            toast.remove();
            toast.error('Something went wrong', {
              position: 'bottom-center',
            });
          }
        })

      }
    })
  }

  // cloneTemplate - function
  const cloneTemplate = (id) => {

    Swal.fire({
      position: 'center',
      text: 'Do you want to clone this template?',
      showConfirmButton: true,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
      width: '400px',

    }).then((result) => {
      if (result.value == true) {

        axios({
          method: 'POST',
          url: `${baseUrl}/kiosk/kiosk-design/${id}/clone`,
          headers: {
            Authorization: token
          },
        }).then((res) => {

          getAllTemplatesByBranch();

        }).catch((error) => {
          console.log(error);

          if (error.response !== undefined) {
            toast.remove();
            toast.error(error.response.data.message, {
              position: 'bottom-center',
            });
          } else {
            toast.remove();
            toast.error('Something went wrong', {
              position: 'bottom-center',
            });
          }
        })

      }
    })
  }

  // makeTemplateGlobal - function
  const makeTemplateGlobal = (id) => {

    Swal.fire({
      position: 'center',
      text: 'Do you want to make this template public?',
      showConfirmButton: true,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
      width: '400px',

    }).then((result) => {
      if (result.value == true) {

        axios({
          method: 'POST',
          url: `${baseUrl}/kiosk/kiosk-design/${id}/make-global`,
          headers: {
            Authorization: token
          },
        }).then((res) => {

          getAllTemplatesByBranch();

        }).catch((error) => {
          console.log(error);

          if (error.response !== undefined) {
            toast.remove();
            toast.error(error.response.data.message, {
              position: 'bottom-center',
            });
          } else {
            toast.remove();
            toast.error('Something went wrong', {
              position: 'bottom-center',
            });
          }
        })

      }
    })
  }

  // renameTemplate - function
  const renameTemplate = (e, id, currentName) => {

    if (templateName.length === 0) {
      toast.remove();
      toast.error('Template name cannot be empty', {
        position: 'bottom-center',
      });
      e.target.select();

    } else if (templateName === currentName) {
      // setRenameTemplateId('');
      // toast.remove();
      // toast.error('No changes to update', {
      //   position: 'bottom-center',
      // })
    } else {

      axios({
        method: 'POST',
        url: `${baseUrl}/kiosk/kiosk-design/${id}/rename`,
        headers: {
          Authorization: token
        },
        data: {
          name: templateName
        }
      }).then((res) => {

        setRenameTemplateId('');
        getAllTemplatesByBranch();
        // removeFromStateArray(id);

        toast.remove();
        toast.success('Successfully renamed', {
          position: 'bottom-center',
        })

      }).catch((error) => {
        console.log(error);

        if (error.response !== undefined) {
          toast.remove();
          toast.error(error.response.data.message, {
            position: 'bottom-center',
          });
        } else {
          toast.remove();
          toast.error('Something went wrong', {
            position: 'bottom-center',
          });
        }

        e.target.select();
      })

    }
  }

  // when deleted image remove it from state array
  const removeFromStateArray = (id) => {
    for (let i = 0; i < allTemplates.length; i++) {
      if (allTemplates[i].id === id) {
        allTemplates.splice(i, 1);
      }
    }
    setRefresh(refresh + 1);
  }

  // hex to rgba
  const hex2rgba = (hex) => {
    let c = hex.substring(1).split('');

    if (!/^#(([\dA-Fa-f]{3}){1,2}|([\dA-Fa-f]{4}){1,2})$/.test(hex)) {
      throw new Error('Your hexadecimal color is not correct.');
    }

    switch (c.length) {
      case 3:
        c = [c[0] + c[0], c[1] + c[1], c[2] + c[2], 'ff'];
        break;
      case 4:
        c = [c[0] + c[0], c[1] + c[1], c[2] + c[2], c[3] + c[3]];
        break;
      case 6:
        c = [c[0] + c[1], c[2] + c[3], c[4] + c[5], 'ff'];
        break;
      case 8:
        c = [c[0] + c[1], c[2] + c[3], c[4] + c[5], c[6] + c[7]];
        break;
    }

    c = c.map((char) => parseInt(char, 16).toString());
    c[3] = (Math.round((parseInt(c[3], 10) / 255) * 100) / 100).toString();
    return c[3] === '1'
      ? `rgb( ${c[0]}, ${c[1]}, ${c[2]})`
      : `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${c[3]})`;
  }

  // hex to rgba
  const hex2rgbaobject = (hex) => {
    let c = hex.substring(1).split('');

    if (!/^#(([\dA-Fa-f]{3}){1,2}|([\dA-Fa-f]{4}){1,2})$/.test(hex)) {
      throw new Error('Your hexadecimal color is not correct.');
    }

    switch (c.length) {
      case 3:
        c = [c[0] + c[0], c[1] + c[1], c[2] + c[2], 'ff'];
        break;
      case 4:
        c = [c[0] + c[0], c[1] + c[1], c[2] + c[2], c[3] + c[3]];
        break;
      case 6:
        c = [c[0] + c[1], c[2] + c[3], c[4] + c[5], 'ff'];
        break;
      case 8:
        c = [c[0] + c[1], c[2] + c[3], c[4] + c[5], c[6] + c[7]];
        break;
    }

    c = c.map((char) => parseInt(char, 16).toString());
    c[3] = (Math.round((parseInt(c[3], 10) / 255) * 100) / 100).toString();
    return c[3] === '1'
      ? `rgb( ${c[0]}, ${c[1]}, ${c[2]})`
      : {
        r: c[0],
        g: c[1],
        b: c[2],
        a: c[3]
      }
  }

  const BGImageMini = ({ bgImg, gridWidth, gridHeight }) => {
    const [bgImage] = useImage(bgImg);
    return <Image image={bgImage}
      width={gridWidth / 4}
      height={gridHeight / 4}
    />;
  };


  return (
    <>
      <div><Toaster /></div>
      <div className="container-fluid">

        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1>Kiosks Templates</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  {/* <li className="breadcrumb-item"><Link to="/region-management">Region Management</Link></li> */}
                  <li className="breadcrumb-item"><Link to={`/branch-landing?bid=${selectedBranch.id}`}>{selectedBranch.BranchName}</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">Kiosks Templates</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="row clearfix">

          <div className="col-md-3 ">
            <div className='kiosks-canva add'>
              <div className="card">

                <div className="body">
                  <img src={require("../../assets/images/211602_plus_empty_icon.png")}
                    alt="" />
                </div>
                <div className='template-options'>
                  <div className='icons-cont'>

                    <div className='icons-cont-item'
                      onClick={() => {
                        setModalType(0);
                        setShowAddKiosksTemplateModal(true);
                      }}
                    >
                      <p className='icons-cont-item-link'>New</p>
                    </div>

                    <div className='icons-cont-item'
                      onClick={() => {
                        setExistingKiosksTemplatesModal(true);
                      }}
                    >
                      <p className='icons-cont-item-link mb-1'>Existing</p>
                    </div>

                  </div>
                </div>
              </div>
              <h7>New Template</h7>
            </div>
          </div>

          {
            allTemplates.map((template, i) => (
              <div className="col-md-3 " key={i}>
                <div className='kiosks-canva'>
                  <div className="card">
                    <div className="body">

                      <div className='kiosk-design-options'>
                        <UncontrolledDropdown>
                          <DropdownToggle>
                            {/* <div className="kiosk-design-options-btn" title="Options"> */}
                            <i className="fa fa-caret-down kiosk-design-options-icon" aria-hidden="true"></i>
                            {/* </div> */}
                          </DropdownToggle>
                          <DropdownMenu
                            positionFixed={true}
                            className="dropdown-menu-xs">
                            <ul className="link-list-opt">
                              <li>
                                <DropdownItem
                                  tag="a"
                                  href="javascript:void(0);"
                                  onClick={() => {
                                    editTemplate(template.id);
                                  }}
                                >
                                  <i className="fa fa-pencil-square-o mr-3" aria-hidden="true"></i>
                                  <span>Edit Design</span>
                                </DropdownItem>
                              </li>
                              <li>
                                <DropdownItem
                                  tag="a"
                                  href="javascript:void(0);"
                                  onClick={() => {
                                    deleteTemplate(template.id);
                                  }}
                                >
                                  <i className="fa fa-trash mr-3" aria-hidden="true"></i>
                                  <span>Delete</span>
                                </DropdownItem>
                              </li>
                              <li>
                                <DropdownItem
                                  tag="a"
                                  href="javascript:void(0);"
                                  onClick={() => {
                                    setselectedTemplateId(template.id);
                                    setModalType(1);
                                    setShowAddKiosksTemplateModal(true);
                                  }}
                                >
                                  <i className="fa fa-exclamation-circle mr-3" aria-hidden="true"></i>
                                  <span>View / Edit Info</span>
                                </DropdownItem>
                              </li>
                              {/* <li>
                                <DropdownItem
                                  tag="a"
                                  href="javascript:void(0);"
                                  onClick={() => {
                                    cloneTemplate(template.id);
                                  }}
                                >
                                  <i className="fa fa-clone mr-3" aria-hidden="true"></i>
                                  <span>Clone</span>
                                </DropdownItem>
                              </li>
                              <li>
                                <DropdownItem
                                  tag="a"
                                  href="javascript:void(0);"
                                  onClick={() => {
                                    makeTemplateGlobal(template.id);
                                  }}
                                >
                                  <i className="fa fa-globe mr-3" aria-hidden="true"></i>
                                  <span>Make Public</span>
                                </DropdownItem>
                              </li> */}

                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>

                      {/* <img src="https://ssl.gstatic.com/docs/templates/thumbnails/1wyFqxsRmKm9q--7j4WRmBMn694YdhV6hmNrfh4rVm2E_400.png"
                        alt="" /> */}

                      <Stage
                        width={template.gridWidth / 4}
                        height={template.gridHeight / 4}
                        ref={stageRef}
                        onContextMenu={e => {
                          e.evt.preventDefault()
                        }}
                        style={{
                          backgroundColor: 'white',
                          width: template.gridWidth / 4 + 'px',
                          height: template.gridHeight / 4 + 'px',
                          margin: '0px auto 0px',
                          borderRadius: '0px'
                        }}
                      >
                        <Layer>
                          <BGImageMini bgImg={template.backgroundImageURL} gridWidth={template.gridWidth} gridHeight={template.gridHeight} />
                        </Layer>


                        <Layer
                          hitGraphEnabled={true}>
                          {template.components.map((rect, i) => {
                            return (

                              rect.type === 'text' ?

                                <TextShapeMini
                                  key={rect.id}
                                  // gridWidth={200}
                                  // gridHeight={(220 * 9 / 16)}
                                  text={rect.text}
                                  textProps={rect.textProps}
                                  shapeProps={rect}
                                />

                                :

                                rect.type === 'rect' ?

                                  <RectangleMini key={rect.id}
                                    // gridWidth={200}
                                    // gridHeight={(220 * 9 / 16)}
                                    shapeProps={rect}
                                  />

                                  :

                                  <URLImageMini key={rect.id}
                                    image={rect.src}
                                    // gridWidth={200}
                                    // gridHeight={(220 * 9 / 16)}
                                    shapeProps={rect}
                                  />

                            );
                          })}
                        </Layer>

                      </Stage>

                    </div>

                    {/* <div className='template-options'>
                      <div className='icons-cont'>
                        <i className="fa fa-pencil-square-o mr-4" aria-hidden="true"
                          title='Edit template design'
                          onClick={() => {
                            editTemplate(template.id);
                          }}
                        ></i>
                        <i className="fa fa-trash mr-4" aria-hidden="true"
                          title='Delete template'
                          onClick={() => {
                            deleteTemplate(template.id);
                          }}
                        ></i>
                        <i className="fa fa-exclamation-circle" aria-hidden="true"
                          title='View / Edit template info'
                          onClick={() => {
                            setselectedTemplateId(template.id);
                            setModalType(1);
                            setShowAddKiosksTemplateModal(true);
                          }}
                        ></i>
                      </div>
                    </div> */}

                  </div>

                  <input className='input-kiosks-temp-rename'
                    title='Double click to rename'
                    // defaultValue={template.name}
                    value={renameTemplateId === template.id ? templateName : template.name}
                    readOnly={renameTemplateId === template.id ? false : true}

                    onDoubleClick={(e) => {
                      setRenameTemplateId(template.id);
                      setTemplateName(template.name);
                      e.target.select();
                    }}

                    onChange={(e) => {
                      setTemplateName(e.target.value);
                    }}

                    onBlur={(e) => {
                      if (renameTemplateId === template.id) {
                        renameTemplate(e, template.id, template.name);
                      }
                    }}

                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        renameTemplate(e, template.id, template.name);
                      }
                    }}

                  />
                </div>
              </div>
            ))
          }


        </div>
      </div>

      <AddKiosksTemplateModal showAddKiosksTemplateModal={showAddKiosksTemplateModal}
        setShowAddKiosksTemplateModal={setShowAddKiosksTemplateModal}
        modalType={modalType} selectedTemplateId={selectedTemplateId}
      />

      <ExistingKiosksTemplateModal
        existingKiosksTemplatesModal={existingKiosksTemplatesModal} setExistingKiosksTemplatesModal={setExistingKiosksTemplatesModal}
        getAllTemplatesByBranchParent={getAllTemplatesByBranch}
      />

    </>
  );
}

export default KiosksTemplates
