import React, {Component} from 'react';
import {Route, Routes, Switch} from 'react-router-dom';
import Menu from './Menu';
import Header from './Header';
import ThemeSetting from './ThemeSetting';
import Searchbar from './Searchbar';
import Rightbar from './Rightbar';
import Megamenu from './Megamenu';
import RoutesJson from '../Route';


export default class Layout extends Component {
    render() {
        return (
            <>
                <ThemeSetting/>
                <div className="overlay"/>
                <div id="wrapper">
                    <Header/>
                    <Searchbar/>
                    <Megamenu/>

                    <Rightbar/>
                    <Menu {...this.props} />
                    <div id="main-content">
                        <Routes>
                            {RoutesJson.map((layout, i) => {
                                return <Route key={`r${i}`} exact={layout.exact} path={layout.path}
                                              element={layout.component}/>
                            })}
                        </Routes>
                    </div>
                </div>
            </>
        );
    }
}
