import React from 'react'
import { Image, Layer, Line, Stage } from 'react-konva';
import useImage from 'use-image';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss'

import URLImage from './URLImage';
import Rectangle from './Rectangle';
import TextShape from './TextShape';


import { ContextMenu, ContextMenuItem, ContextMenuTrigger } from 'rctx-contextmenu';

const ReactKonva = () => {
    const grid = 10;
    const gridWidth = 800;
    const gridHeight = 450;

    const checkDeselect = (e) => {
        // deselect when clicked on empty area
        // const clickedOnEmpty = e.target === e.target.getStage();
        // if (clickedOnEmpty) {
        selectShape(null);
        setShape(null);
        // }
    };
    const linesA = [];
    const linesB = [];
    for (let i = 0; i < gridWidth / grid; i++) {
        linesA.push(
            <Line
                strokeWidth={0.5}
                stroke={'#e3e3e3'}
                points={[i * grid, 0, i * grid, gridWidth]}
            />
        );

        linesB.push(
            <Line
                strokeWidth={0.5}
                stroke={'#e3e3e3'}
                points={[0, i * grid, gridWidth, i * grid]}
            />
        )
    }

    const dragUrl = React.useRef();
    const dragDimens = React.useRef();
    const dragType = React.useRef();
    const stageRef = React.useRef();

    const [rectangles, setRectangles] = React.useState([]);
    const [selectedId, selectShape] = React.useState(null);
    const [selectedShape, setShape] = React.useState(null);
    const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
    const [selectedColorHex, setColorHex] = React.useState('#F17013');
    const [selectedColor, setColor] = React.useState({
        r: '241',
        g: '112',
        b: '19',
        a: '1'
    });

    const [xCoordinate, setX] = React.useState(0);
    const [yCoordinate, setY] = React.useState(0);
    const [width, setW] = React.useState(0);
    const [height, setH] = React.useState(0);

    const [text, setText] = React.useState('');
    const [size, setTextSize] = React.useState(8);
    const [font, setFont] = React.useState('');
    const [f, setF] = React.useState('https://media.istockphoto.com/vectors/geometric-background-of-squares-and-dots-vector-id1338579925?k=20&m=1338579925&s=170667a&w=0&h=PNGtY6sXQ5_FdygTIOWEf1MZLI-tO3GZjWio5L0Wd38=');


    const BGImage = () => {
        const [bgImage] = useImage(f);
        return <Image image={bgImage}
            width={gridWidth}
            height={gridHeight}
        />;
    };

    const onChangeX = event => {
        let x = parseInt(event.target.value === '' ? 0 : event.target.value);

        let xCord = Math.max(Math.round(x / grid) * grid, 0);

        //calc min x for boundary exceeds
        if (xCord + selectedShape.width > gridWidth)
            xCord = gridWidth - selectedShape.width;

        if (xCord < 0)
            xCord = 0;

        setX(xCord);
    };
    const onChangeY = event => {
        let y = parseInt(event.target.value === '' ? 0 : event.target.value);

        let yCord = Math.max(Math.round(y / grid) * grid, 0);

        //calc min x for boundary exceeds
        if (yCord + selectedShape.height > gridHeight)
            yCord = gridHeight - selectedShape.height;

        if (yCord < 0)
            yCord = 0;

        setY(yCord);
    };
    const onChangeW = event => {
        let w = parseInt(event.target.value === '' ? grid : event.target.value);

        let width = Math.max(Math.round(Math.max(grid, w) / grid) * grid, grid);

        if (selectedShape.x + width > gridWidth)
            return;

        setW(w);
    };
    const onChangeH = event => {
        let h = parseInt(event.target.value === '' ? grid : event.target.value);

        let height = Math.max(Math.round(Math.max(grid, h) / grid) * grid, grid);

        if (selectedShape.y + height > gridHeight)
            return;

        setH(h);
    };

    const onChangeText = event => {
        setText(event.target.value);
    };
    const onChangeSize = event => {
        setTextSize(parseInt(event.target.value));
    };

    const onChangeFont = event => {
        setFont(event.target.value);
    };

    // delete shape
    const deleteShape = (e) => {
        if (selectedId === null) return;

        const newList = rectangles.filter(shape => {
            return shape.id !== selectedId;
        });
        setRectangles(newList);
        setShape(null);
        checkDeselect(e);

        selectShape(null);

    };

    let newShape = {}

    // clone shape
    const cloneShape = (e) => {
        if (selectedId === null) return;

        let tempRectangles = [...rectangles];
        const shape = tempRectangles.find(shape => {
            return shape.id === selectedId;
        });

        newShape = {
            ...shape,
            id: shape.type + Math.floor(Math.random() * 999999999),
            x: shape.x + grid,
            y: shape.y + grid,
        };
        // setRectangles(
        //     rectangles.concat([newShape])
        // );
    };

    const pasteShape = (e) => {
        // if (selectedId === null) return;

        // let tempRectangles = [...rectangles];
        // const shape = tempRectangles.find(shape => {
        //     return shape.id === selectedId;
        // });
        // let newShape = {
        //     ...shape,
        //     id: shape.type + Math.floor(Math.random() * 999999999),
        //     x: shape.x + grid,
        //     y: shape.y + grid,
        // };
        setRectangles(
            rectangles.concat([newShape])
        );
    };




    React.useEffect(() => {
        if (selectedShape !== null && selectedShape !== undefined) {
            setX(parseInt(selectedShape.x));
            setY(parseInt(selectedShape.y));
            setW(parseInt(selectedShape.width));
            setH(parseInt(selectedShape.height));
            setColor(selectedShape.rgba);
            setColorHex(selectedShape.hex);
            if (selectedShape.text !== null && selectedShape.text !== undefined)
                setText(selectedShape.text);
            if (selectedShape.textProps !== null && selectedShape.textProps !== undefined) {
                setTextSize(selectedShape.textProps.fontSize);
                setFont(selectedShape.textProps.fontFamily);
            }
        } else {
            setX(0);
            setY(0);
            setW(grid);
            setText('');
            setFont('');
            setTextSize(8);
            setH(grid);
        }
    }, [selectedShape]);

    React.useEffect(() => {

        let tempRectangles = [...rectangles];
        const selected = tempRectangles.find(shape => {
            return shape.id === selectedId;
        });
        if (selected !== undefined) {
            selected.x = parseInt(xCoordinate);

            setRectangles(tempRectangles);
            setShape(selected);
        }

    }, [xCoordinate]);

    React.useEffect(() => {

        let tempRectangles = [...rectangles];
        const selected = tempRectangles.find(shape => {
            return shape.id === selectedId;
        });
        if (selected !== undefined) {
            selected.y = parseInt(yCoordinate);

            setRectangles(tempRectangles);
            setShape(selected);
        }

    }, [yCoordinate]);

    React.useEffect(() => {

        let tempRectangles = [...rectangles];
        const selected = tempRectangles.find(shape => {
            return shape.id === selectedId;
        });
        if (selected !== undefined) {
            selected.width = parseInt(width);

            setRectangles(tempRectangles);
            setShape(selected);
        }

    }, [width]);
    React.useEffect(() => {

        let tempRectangles = [...rectangles];
        const selected = tempRectangles.find(shape => {
            return shape.id === selectedId;
        });
        if (selected !== undefined) {
            selected.height = parseInt(height);

            setRectangles(tempRectangles);
            setShape(selected);
        }

    }, [height]);

    React.useEffect(() => {

        let tempRectangles = [...rectangles];
        const selectedText = tempRectangles.find(shape => {
            return shape.id === selectedId;
        });
        if (selectedText !== undefined) {
            selectedText.text = text;

            setRectangles(tempRectangles)
        }

    }, [text]);
    React.useEffect(() => {

        let tempRectangles = [...rectangles];
        const selectedText = tempRectangles.find(shape => {
            return shape.id === selectedId;
        });
        if (selectedText !== undefined && selectedShape.textProps !== undefined) {
            selectedShape.textProps.fontSize = size;

            setRectangles(tempRectangles)
        }

    }, [size]);

    React.useEffect(() => {

        let tempRectangles = [...rectangles];
        const selectedText = tempRectangles.find(shape => {
            return shape.id === selectedId;
        });
        if (selectedText !== undefined && selectedShape.textProps !== undefined) {
            selectedShape.textProps.fontFamily = font;

            setRectangles(tempRectangles)
        }

    }, [font]);

    //color picker
    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    //color picker
    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    //color picker
    const handleChange = (color) => {
        setColor(color.rgb);
        setColorHex(color.hex);
        console.info(color);

        if (selectedId === null) return;

        let tempRectangles = [...rectangles];
        const shape = tempRectangles.find(shape => {
            return shape.id === selectedId;
        });
        shape.fill = toRGBA(color.rgb);
        shape.hex = color.hex;
        shape.rgba = color.rgb;
        setRectangles(
            tempRectangles
        );
    };

    const toRGBA = (color) => {
        return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
    };

    const changeTextAlign = (align) => {
        let tempRectangles = [...rectangles];
        const selectedText = tempRectangles.find(shape => {
            return shape.id === selectedId;
        });
        if (selectedText !== undefined) {
            selectedText.textProps.align = align;

            setRectangles(tempRectangles)
        }
    };

    const styles = reactCSS({
        'default': {
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: `rgba(${selectedColor.r}, ${selectedColor.g}, ${selectedColor.b}, ${selectedColor.a})`,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    return (
        <div>
            <div>
                <div style={styles.swatch} onClick={handleClick}>
                    <div style={styles.color} />
                </div>
                {displayColorPicker ? <div style={styles.popover}>
                    <div style={styles.cover} onClick={handleClose} />
                    <SketchPicker color={selectedColor} onChange={handleChange} />
                </div> : null}

            </div>
            <div className="app">
                <ContextMenuTrigger
                    id="my-context-menu-1"
                >
                    Right Click On Me
                </ContextMenuTrigger>

                <ContextMenu id="my-context-menu-1">
                    <ContextMenuItem
                        appendTo="body"
                        animation="zoom"
                        onClick={() => alert(123)}
                    >Menu Item 1</ContextMenuItem>
                    <ContextMenuItem>Menu Item 2</ContextMenuItem>
                    <ContextMenuItem>Menu Item 3</ContextMenuItem>
                    <ContextMenuItem>Menu Item 4</ContextMenuItem>
                </ContextMenu>
            </div>
            Try to drag and image into the stage:
            <>
                <img
                    width={50}
                    alt="rectangle"
                    src="../img/k_rect.png"
                    draggable="true"
                    onDragStart={(e) => {
                        dragUrl.current = e.target.src;
                        dragType.current = 'rect';
                    }}
                />
                {/*<img*/}
                {/*width={50}*/}
                {/*alt="circle"*/}
                {/*src="../img/k_circle.png"*/}
                {/*draggable="true"*/}
                {/*onDragStart={(e) => {*/}
                {/*dragUrl.current = e.target.src;*/}
                {/*dragType.current = 'circle';*/}
                {/*}}*/}
                {/*/>*/}

                <img
                    width={50}
                    alt="circle"
                    src="../img/k_abc.png"
                    draggable="true"
                    onDragStart={(e) => {
                        dragUrl.current = e.target.src;
                        dragType.current = 'text';
                    }}
                />
                {/*<img*/}
                {/*width={50}*/}
                {/*alt="ellipse"*/}
                {/*src="../img/k_eclipse.png"*/}
                {/*draggable="true"*/}
                {/*onDragStart={(e) => {*/}
                {/*dragUrl.current = e.target.src;*/}
                {/*dragType.current = 'ellipse';*/}
                {/*}}*/}
                {/*/>*/}
                <img
                    width={100}
                    alt="btn round"
                    src="https://assets.webiconspng.com/uploads/2017/09/Buttons-PNG-Image-65451.png"
                    draggable="true"
                    onDragStart={(e) => {
                        dragUrl.current = e.target.src;
                        dragType.current = 'img';
                        dragDimens.height = e.target.height;
                        dragDimens.width = e.target.width;
                    }}
                />
                <img
                    alt="btn rounded"
                    src="https://www.freeiconspng.com/uploads/login-button-icon-png-15.png"
                    draggable="true"
                    onDragStart={(e) => {
                        dragUrl.current = e.target.src;
                        dragType.current = 'img';
                        dragDimens.height = e.target.height;
                        dragDimens.width = e.target.width;
                    }}
                    width={'100px'}
                />
                <img
                    id='img'
                    alt="btn rectangle"
                    src="https://www.seekpng.com/png/full/137-1378320_13-glass-rectangle-button-icons-png-images-button.png"
                    draggable="true"
                    onDragStart={(e) => {
                        dragUrl.current = e.target.src;
                        dragType.current = 'img';
                        dragDimens.height = e.target.height;
                        dragDimens.width = e.target.width;
                    }}
                    width={'100px'}
                />
            </>

            <div style={{ position: 'absolute', marginTop: '50px', marginLeft: '10px' }}>
                {/* x -> */}
                <input
                    onChange={onChangeX} min={0} step={grid} type={"number"}
                    value={xCoordinate} />
                {/* y -> */}
                <input
                    onChange={onChangeY} min={0} step={grid} type={"number"}
                    value={yCoordinate} />
                {/* w -> */}
                <input
                    onChange={onChangeW} step={grid} type={"number"} min={grid}
                    value={width} />
                {/* h -> */}
                <input
                    onChange={onChangeH} step={grid} type={"number"} min={grid}
                    value={height} />

            </div>

            <div style={{ backgroundColor: 'gray', position: 'absolute', right: '10px' }}>
                <ul>
                    {rectangles.map((rect, i) => {
                        return <li key={rect.id + i}>
                            {i + 1} - {rect.id}
                            {i === 0
                                ?
                                <></>
                                :
                                <button
                                    onClick={() => {
                                        let tempRectangles = [...rectangles];

                                        let itemAbove = tempRectangles[i - 1];
                                        tempRectangles[i - 1] = rect;
                                        tempRectangles[i] = itemAbove;

                                        setRectangles(tempRectangles);
                                    }}
                                >
                                    up
                                </button>}
                            {rectangles.length - 1 === (i)
                                ?
                                <></>
                                :
                                <button onClick={() => {
                                    let tempRectangles = [...rectangles];

                                    let itemBelow = tempRectangles[i + 1];
                                    tempRectangles[i + 1] = rect;
                                    tempRectangles[i] = itemBelow;

                                    setRectangles(tempRectangles);
                                }}>
                                    down
                                </button>}

                        </li>
                    })}
                </ul>
            </div>

            <button onClick={(e) => {
                deleteShape(e);
            }}>
                delete
            </button>

            <button onClick={(e) => {
               setF('https://konvajs.github.io/assets/yoda.jpg')
            }}>
                xxxx
            </button>
            <button onClick={(e) => {
                cloneShape(e);
            }}>
                copy
            </button>
            <button onClick={(e) => {
                pasteShape(e);
            }}>
                paste
            </button>
            <button onClick={(e) => {
                checkDeselect(e);
            }}>
                deselect
            </button>

            <br />
            {/*//font stuff*/}
            <button onClick={() => {
                changeTextAlign('left')
            }}>
                left
            </button>
            <button onClick={() => {
                changeTextAlign('center')
            }}>
                center
            </button>
            <button onClick={() => {
                changeTextAlign('right')
            }}>
                right
            </button>
            text
            <input type={"text"} value={text} onChange={onChangeText} />
            size
            <input type={"number"} min={8} max={100} value={size} onChange={onChangeSize} />
            font family
            <select value={font} onChange={onChangeFont}>
                <option value="">- select font -</option>
                <option value="sans">sans</option>
                <option value="arial">arial</option>
                <option value="digital">Digital</option>
                <option value="aquire">Aquire</option>
            </select>

            <br />

            <button onClick={() => {
                if (selectedShape === null)
                    return;
                setX(0);
            }}>
                left-align
            </button>

            <button onClick={() => {
                if (selectedShape === null)
                    return;
                setX(gridWidth - width);
            }}>
                right-align
            </button>

            <button onClick={() => {
                if (selectedShape === null)
                    return;
                setX((gridWidth / 2) - (width / 2));
            }}>
                center
            </button>

            <button onClick={() => {
                if (selectedShape === null)
                    return;
                setY((gridHeight / 2) - (height / 2));
            }}>
                middle
            </button>
            <button onClick={() => {
                if (selectedShape === null)
                    return;
                setY(0);
            }}>
                top-align
            </button>

            <button onClick={() => {
                if (selectedShape === null)
                    return;
                setY(gridHeight - height);
            }}>
                bottom-align
            </button>

            <div onDrop={(e) => {
                e.preventDefault();
                // register event position
                stageRef.current.setPointersPositions(e);
                if (dragType.current === 'rect') {

                    let xCord = Math.max(Math.round(stageRef.current.getPointerPosition().x / grid) * grid, 0);
                    let yCord = Math.max(Math.round(stageRef.current.getPointerPosition().y / grid) * grid, 0);

                    let w = 160;
                    let h = 90;

                    //calc min x for boundary exceeds
                    if (xCord + w > gridWidth)
                        xCord = gridWidth - w;
                    //calc min y for boundary exceeds
                    if (yCord + h > gridHeight)
                        yCord = gridHeight - h;


                    if (xCord + w > gridWidth)
                        w = gridWidth - xCord;
                    if (h + yCord > gridHeight)
                        h = gridHeight - yCord;

                    // add shape
                    setRectangles(
                        rectangles.concat([
                            {
                                x: xCord,
                                y: yCord,
                                width: w,
                                height: h,
                                fill: toRGBA(selectedColor),
                                rgba: selectedColor,
                                hex: selectedColorHex,
                                id: dragType.current + Math.floor(Math.random() * 999999999),
                                type: dragType.current
                            },
                        ])
                    );
                } else if (dragType.current === 'text') {
                    setRectangles(
                        rectangles.concat([
                            {
                                x: stageRef.current.getPointerPosition().x,
                                y: stageRef.current.getPointerPosition().y,
                                width: 260,
                                height: 50,
                                text: 'Lorem ipsum dolor sit amet',
                                rgba: selectedColor,
                                hex: selectedColorHex,
                                fill: toRGBA(selectedColor),
                                id: dragType.current + Math.floor(Math.random() * 999999999),
                                type: dragType.current,
                                textProps: {
                                    fontSize: 20,
                                    align: 'center',
                                    fontFamily: 'sans'
                                }
                            }
                        ])
                    );
                } else {

                    let xCord = Math.max(Math.round(stageRef.current.getPointerPosition().x / grid) * grid, 0);
                    let yCord = Math.max(Math.round(stageRef.current.getPointerPosition().y / grid) * grid, 0);

                    let w = dragDimens.width * 2;
                    let h = dragDimens.height * 2;

                    //calc min x for boundary exceeds
                    if (xCord + w > gridWidth)
                        xCord = gridWidth - w;
                    //calc min y for boundary exceeds
                    if (yCord + h > gridHeight)
                        yCord = gridHeight - h;


                    if (xCord + w > gridWidth)
                        w = gridWidth - xCord;
                    if (h + yCord > gridHeight)
                        h = gridHeight - yCord;


                    // add image
                    setRectangles(
                        rectangles.concat([
                            {
                                rgba: selectedColor,
                                hex: selectedColorHex,
                                x: xCord,
                                y: yCord,
                                width: w,
                                height: h,
                                id: dragType.current + Math.floor(Math.random() * 999999999),
                                type: dragType.current,
                                src: dragUrl.current
                            }
                        ])
                    );
                }

            }}
                onDragOver={(e) => e.preventDefault()}
            >
                <Stage
                    width={gridWidth}
                    height={gridHeight}
                    style={{
                        marginTop: '70px',
                        border: '1px solid lightgray',
                        backgroundColor: 'white',
                        width: '800px',
                        height: '450px'
                    }}
                    ref={stageRef}
                    onContextMenu={e => {
                        e.evt.preventDefault()
                    }}
                >
                    <Layer>
                        <BGImage />
                    </Layer>


                    <Layer>
                        {linesA}
                        {linesB}
                    </Layer>

                    <Layer
                        hitGraphEnabled={true}>
                        {rectangles.map((rect, i) => {
                            return (

                                rect.type === 'img' ?

                                    <URLImage key={rect.id}
                                        image={rect.src}
                                        grid={grid}
                                        gridWidth={gridWidth}
                                        gridHeight={gridHeight}
                                        shapeProps={rect}
                                        isSelected={rect.id === selectedId}
                                        onSelect={() => {
                                            selectShape(rect.id);
                                            setShape(rect);
                                        }}

                                        onChange={(newAttrs) => {
                                            const rects = rectangles.slice();
                                            console.log(newAttrs);
                                            rects[i] = newAttrs;
                                            setRectangles(rects);
                                            if (rects[i].id === selectedId)
                                                setShape(rects[i]);
                                        }} />


                                    :
                                    rect.type === 'rect' ?


                                        <Rectangle key={rect.id}
                                            grid={grid}
                                            gridWidth={gridWidth}
                                            gridHeight={gridHeight}
                                            shapeProps={rect}
                                            isSelected={rect.id === selectedId}
                                            onSelect={() => {
                                                selectShape(rect.id);
                                                setShape(rect);
                                            }}
                                            onChange={(newAttrs) => {
                                                const rects = rectangles.slice();
                                                rects[i] = newAttrs;
                                                setRectangles(rects);
                                                if (rects[i].id === selectedId)
                                                    setShape(rects[i]);
                                            }}
                                        />
                                        :
                                        <TextShape
                                            key={rect.id}
                                            grid={grid}
                                            gridWidth={gridWidth}
                                            gridHeight={gridHeight}
                                            text={rect.text}
                                            textProps={rect.textProps}
                                            shapeProps={rect}
                                            isSelected={rect.id === selectedId}
                                            onSelect={() => {
                                                selectShape(rect.id);
                                                setShape(rect);
                                            }}
                                            onChange={(newAttrs) => {
                                                const rects = rectangles.slice();
                                                rects[i] = newAttrs;
                                                setRectangles(rects);
                                                if (rects[i].id === selectedId)
                                                    setShape(rects[i]);
                                            }}
                                        />
                            );
                        })}
                    </Layer>
                </Stage>


                {rectangles.length}
            </div>
        </div>
    );
};

export default ReactKonva