import React, { useState, useEffect } from "react";
import { Accordion, Button } from "react-bootstrap";
import BranchPerformanceReport from "./BranchReportTables/BranchPerformanceReport";
import HourlyBranchPerformanceReport from "./BranchReportTables/HourlyBranchPerformanceReport";
import BranchWiseCategoryReport from "./BranchReportTables/BranchWiseCategoryReport";
import BranchWiseExceptionReport from "./BranchReportTables/BranchWiseExceptionReport";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const BranchReports = ({
  selectedRegionIds,
  changeRegionIds,
  dateFrom,
  dateTo,
  downloadExcel,
  reportUrl,
}) => {
  const baseUrl = localStorage.getItem("API_URL");
  const token = localStorage.getItem("user_token");
  const userDetails = JSON.parse(localStorage.getItem("user_details"));

  const [openBranchPerformanceReport, setOpenBranchPerformanceReport] =
    useState(false);
  const [
    openHourlyBranchPerformanceReport,
    setOpenHourlyBranchPerformanceReport,
  ] = useState(false);
  const [openBranchWiseCategoryReport, setOpenBranchWiseCategoryReport] =
    useState(false);
  const [openBranchWiseExceptionReport, setOpenBranchWiseExceptionReport] =
    useState(false);

  const [reportsAccess, setReportsAccess] = useState([]);

  // call getReportAccess - useEffect
  useEffect(() => {
    if (reportUrl) {
      getReportAccess();
    }
  }, [BranchReports, reportUrl]);

  // get report access - function
  const getReportAccess = () => {
    axios({
      method: "GET",
      url: `${reportUrl}/report-controller/get/report-access/customer/${userDetails.cid}/parent/Branch Reports/get-report-access`,
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        let data = res.data.data;
        setReportsAccess(data);
      })
      .catch((error) => {
        console.log(error);

        if (error.response !== undefined) {
          toast.error(error.response.data.message, {
            position: "bottom-center",
          });
        } else {
          toast.remove();
          toast.error("Something went wrong", {
            position: "bottom-center",
          });
        }
      });
  };

  return (
    <>
      <div className="row clearfix">
        <div className="col-lg-12">
          <div className="card">
            <Accordion
              className="accordion report-accordion"
              id="accordion"
              defaultActiveKey=""
            >
              {reportsAccess.map((item, i) => {
                if (item.name === "Branch Performance Report" && item.active) {
                  return (
                    <div>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            className="btn btn-link vertically-align-center-flex"
                            onClick={() => {
                              if (openBranchPerformanceReport) {
                                setOpenBranchPerformanceReport(false);
                              } else {
                                setOpenBranchPerformanceReport(true);
                                setOpenHourlyBranchPerformanceReport(false);
                                setOpenBranchWiseCategoryReport(false);
                                setOpenBranchWiseCategoryReport(false);
                              }
                            }}
                          >
                            Branch Performance Report
                            <div className="float-right vertically-align-center-flex-2">
                              {userDetails !== null ? (
                                <>
                                  {item.pdf ? (
                                    <a
                                      href={`${reportUrl}/report-controller/download-pdf/${dateFrom}/${dateTo}/${
                                        selectedRegionIds.length > 0
                                          ? selectedRegionIds.toString()
                                          : userDetails.bid
                                      }/branch-perfomance-report`}
                                      target="_blank"
                                      className="btn btn-sm btn-default"
                                      title="View PDF"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-pdf-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  ) : null}

                                  {item.excel ? (
                                    <button
                                      className="btn btn-sm btn-default"
                                      title="View Excel"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        downloadExcel(
                                          "branch-performance-report",
                                          "_br_bpr_table"
                                        );
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-excel-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : null}
                                </>
                              ) : null}
                              <span className="p-l-20">
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </h5>
                      </div>
                      <Accordion.Collapse eventKey="0" className="collapse">
                        <BranchPerformanceReport
                          openBranchPerformanceReport={
                            openBranchPerformanceReport
                          }
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          selectedRegionIds={selectedRegionIds}
                          changeRegionIds={changeRegionIds}
                          reportUrl={reportUrl}
                        />
                      </Accordion.Collapse>
                    </div>
                  );
                } else if (
                  item.name === "Hourly Branch Performance Report" &&
                  item.active
                ) {
                  return (
                    <div>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="1"
                            className="btn btn-link vertically-align-center-flex"
                            onClick={() => {
                              if (openHourlyBranchPerformanceReport) {
                                setOpenHourlyBranchPerformanceReport(false);
                              } else {
                                setOpenHourlyBranchPerformanceReport(true);
                                setOpenBranchPerformanceReport(false);
                                setOpenBranchWiseCategoryReport(false);
                                setOpenBranchWiseCategoryReport(false);
                              }
                            }}
                          >
                            Hourly Branch Performance Report
                            <div className="float-right vertically-align-center-flex-2">
                              {userDetails !== null ? (
                                <>
                                  {item.pdf ? (
                                    <a
                                      href={`${reportUrl}/report-controller/download-pdf/${dateFrom}/${dateTo}/${
                                        selectedRegionIds.length > 0
                                          ? selectedRegionIds.toString()
                                          : userDetails.bid
                                      }/hourly-branch-perfomance-report`}
                                      target="_blank"
                                      className="btn btn-sm btn-default"
                                      title="View PDF"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-pdf-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  ) : null}

                                  {item.excel ? (
                                    <button
                                      className="btn btn-sm btn-default"
                                      title="View Excel"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        downloadExcel(
                                          "hourly-branch-performance-report",
                                          "_br_hbpr_table"
                                        );
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-excel-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : null}
                                </>
                              ) : null}
                              <span className="p-l-20">
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </h5>
                      </div>
                      <Accordion.Collapse eventKey="1" className="collapse">
                        <HourlyBranchPerformanceReport
                          openHourlyBranchPerformanceReport={
                            openHourlyBranchPerformanceReport
                          }
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          selectedRegionIds={selectedRegionIds}
                          changeRegionIds={changeRegionIds}
                          reportUrl={reportUrl}
                        />
                      </Accordion.Collapse>
                    </div>
                  );
                } else if (
                  item.name === "Branch Wise Category Report" &&
                  item.active
                ) {
                  return (
                    <div>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="3"
                            className="btn btn-link vertically-align-center-flex"
                            onClick={() => {
                              if (openBranchWiseCategoryReport) {
                                setOpenBranchWiseCategoryReport(false);
                              } else {
                                setOpenBranchWiseCategoryReport(true);
                                setOpenBranchPerformanceReport(false);
                                setOpenHourlyBranchPerformanceReport(false);
                                setOpenBranchWiseExceptionReport(false);
                              }
                            }}
                          >
                            Branch Wise Category Report
                            <div className="float-right vertically-align-center-flex-2">
                              {userDetails !== null ? (
                                <>
                                  {item.pdf ? (
                                    <a
                                      href={`${reportUrl}/report-controller/download-pdf/${dateFrom}/${dateTo}/${
                                        selectedRegionIds.length > 0
                                          ? selectedRegionIds.toString()
                                          : userDetails.bid
                                      }/branch-wise-category-report`}
                                      target="_blank"
                                      className="btn btn-sm btn-default"
                                      title="View PDF"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-pdf-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  ) : null}

                                  {item.excel ? (
                                    <button
                                      className="btn btn-sm btn-default"
                                      title="View Excel"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        downloadExcel(
                                          "branch-wise-category-report",
                                          "_br_bwcr_table"
                                        );
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-excel-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : null}
                                </>
                              ) : null}
                              <span className="p-l-20">
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </h5>
                      </div>
                      <Accordion.Collapse eventKey="3" className="collapse">
                        <BranchWiseCategoryReport
                          openBranchWiseCategoryReport={
                            openBranchWiseCategoryReport
                          }
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          selectedRegionIds={selectedRegionIds}
                          changeRegionIds={changeRegionIds}
                          reportUrl={reportUrl}
                        />
                      </Accordion.Collapse>
                    </div>
                  );
                } else if (
                  item.name === "Branch Wise Exception Report" &&
                  item.active
                ) {
                  return (
                    <div>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="4"
                            className="btn btn-link vertically-align-center-flex"
                            onClick={() => {
                              if (openBranchWiseExceptionReport) {
                                setOpenBranchWiseCategoryReport(false);
                              } else {
                                setOpenBranchWiseExceptionReport(true);
                                setOpenBranchWiseCategoryReport(false);
                                setOpenBranchPerformanceReport(false);
                                setOpenHourlyBranchPerformanceReport(false);
                              }
                            }}
                          >
                            Branch Wise Exception Report
                            <div className="float-right vertically-align-center-flex-2">
                              {userDetails !== null ? (
                                <>
                                  {item.pdf ? (
                                    <a
                                      href={`${reportUrl}/report-controller/download-pdf/${dateFrom}/${dateTo}/${
                                        selectedRegionIds.length > 0
                                          ? selectedRegionIds.toString()
                                          : userDetails.bid
                                      }/branch-wise-exception`}
                                      target="_blank"
                                      className="btn btn-sm btn-default"
                                      title="View PDF"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-pdf-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  ) : null}

                                  {item.excel ? (
                                    <button
                                      className="btn btn-sm btn-default"
                                      title="View Excel"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        downloadExcel(
                                          "branch-wise-exception-report",
                                          "_br_bwer_table"
                                        );
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-excel-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : null}
                                </>
                              ) : null}
                              <span className="p-l-20">
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </h5>
                      </div>
                      <Accordion.Collapse eventKey="4" className="collapse">
                        <BranchWiseExceptionReport
                          openBranchWiseExceptionReport={
                            openBranchWiseExceptionReport
                          }
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          selectedRegionIds={selectedRegionIds}
                          changeRegionIds={changeRegionIds}
                          reportUrl={reportUrl}
                        />
                      </Accordion.Collapse>
                    </div>
                  );
                }
              })}
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default BranchReports;
