import React, { Component } from 'react';
import { connect } from 'react-redux';
import MetisMenu from 'react-metismenu';
import { Link } from 'react-router-dom';

import { setMiniHover, setMiniSidebarMenuOn, setOffcanvas, setUserPermissions } from '../../actions/settingsAction';
import metisMenu from './metisMenu';
import DefaultLink from './DefaultLink';
import { Dropdown } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import CompanyProfileModal from '../ModalImpl/CompanyProfileModal';


class Menu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            baseUrl: localStorage.getItem('API_URL'),
            token: localStorage.getItem('user_token'),
            userDetails: JSON.parse(localStorage.getItem('user_details')),
            newMenu: [],
            companyProfileModal: false
        }
        this.toggleSubMenu = this.toggleSubMenu.bind(this);
        this.minisidebarMouseOver = this.minisidebarMouseOver.bind(this);
        this.minisidebarMouseOut = this.minisidebarMouseOut.bind(this);
    }

    toggleSubMenu1(e) {
        // let menucClass = ''
        return "sdsdfszd";
    }

    toggleSubMenu(e) {
        let menucClass = ''
        if (e.itemId) {
            const subClass = e.items.map((menuItem) => {
                if (menuItem.id === 'main' || menuItem.id === 'app' || menuItem.id === 'extra' || menuItem.id === 'ui' || menuItem.id === 'services') {
                    menucClass = "header";
                }
                if (this.props.location !== undefined && menuItem.to === this.props.location.pathname) {
                    menucClass = "";
                } else {
                    menucClass = "collapse";
                }
                return menucClass;
            })
            return subClass
            // return "collapse";
        } else {
            return e.visible ? "collapse" : "metismenu";
        }
    }

    minisidebarMouseOver() {
        this.props.setMiniSidebarMenuOn(false);
        this.props.setMiniHover(true);
    }

    minisidebarMouseOut() {
        this.props.setMiniSidebarMenuOn(true);
        this.props.setMiniHover(false);
    }

    componentDidMount() {
        const getLitext = document.querySelectorAll('li');
        getLitext.forEach(function (el) {
            if (el.innerText === 'Main' || el.innerText === "App" || el.innerText === "UI Elements" || el.innerText === "Extra" || el.innerText === "Services") {
                el.className = "header"
            }
        });

        if (this.state.userDetails !== null) {
            this.getUserRoleFeatures();
        }
    }

    async getUserRoleFeatures() {

        axios({
            method: 'GET',
            url: `${this.state.baseUrl}/user/role-features/get-list/${this.state.userDetails.uid}`,
            headers: {
                Authorization: this.state.token
            }
        }).then((res) => {
            let data = res.data.data;

            // set userPermissions for gloabal
            this.props.setUserPermissions(data);
            // call rebuildMenu function
            this.rebuildMenu(data);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    async rebuildMenu(userPermissions) {

        let metisMenuTemp = [];
        let allowdPermission = userPermissions.filter(e => e.hasFeature);

        // get default menu item
        // const element1 = metisMenu.find(e => 'home' === e.id);
        // if (element1 != null) metisMenuTemp.push(element1);

        const element2 = metisMenu.find(e => 'dashboard' === e.id);
        if (element2 != null) metisMenuTemp.push(element2);

        // get menu items by permissions
        for (let i = 0; i < metisMenu.length; i++) {
            const element = allowdPermission.find(e => metisMenu[i].id === e.roleFeature);
            if (element != null) metisMenuTemp.push(metisMenu[i]);
        }

        this.setState({ newMenu: metisMenuTemp });

    }


    updatePropValue = () => {
        this.setState({ companyProfileModal: false });
    };

    render() {
        if (document.getElementById('left-sidebar') && this.props.miniSidebar) {
            document.getElementById('left-sidebar').addEventListener('mouseover', this.minisidebarMouseOver);
        }
        if (document.getElementById('left-sidebar') && this.props.miniHover) {
            document.getElementById('left-sidebar').addEventListener('mouseout', this.minisidebarMouseOut);
        }
        return (
            <>
                <div><Toaster /></div>
                <div id="left-sidebar" className={`sidebar${this.props.miniSideMenuOn ? ' mini_sidebar_on' : ''}`}>
                    <div className="navbar-brand">
                        <Link to="/home">
                            <img src={'../../assets/images/TS-Logo.png'} alt="TS Logo" className=""
                                style={{ width: '25px' }} />
                            <span>Queue</span>
                        </Link>
                        <button type="button" className="btn-toggle-offcanvas btn btn-sm float-right"
                            onClick={() => this.props.setOffcanvas(!this.props.offcanvas)}><i
                                className="lnr lnr-menu icon-close"></i></button>
                    </div>
                    <div className="sidebar-scroll">
                        <div className="user-account d-flex" style={{ alignItems: 'center' }}>
                            {
                                this.state.userDetails != null ? (
                                    <Link to={`/user-profile?uid=${this.state.userDetails.uid}`} className="user_div">

                                        {this.state.userDetails.image !== undefined ?
                                            <img src={`${this.state.userDetails.image}?${Date.now()}`} className="user-photo" alt="User Profile" />
                                            :
                                            <img src={require('../../assets/images/others/no-profile.jpg')} className="user-photo" alt="User Profile" />
                                        }

                                    </Link>
                                ) : null
                            }

                            <div className="dropdown">
                                <span>Welcome,</span>
                                <br />
                                {
                                    this.state.userDetails != null ? (
                                        <div className='vertically-align-center-flex'>
                                            <Link to={`/user-profile?uid=${this.state.userDetails.uid}`} variant="success" id="dropdown-basic"
                                                title={`${this.state.userDetails.userFirstName} ${this.state.userDetails.userLastName}`}
                                                className="user-name left_dropdown_btn m-r-10">
                                                <strong>{this.state.userDetails.userFirstName}</strong>
                                            </Link>

                                            {this.state.userDetails.userType === 3 ? (
                                                <Dropdown className="ml-0">
                                                    <Dropdown.Toggle variant="success" id="dropdown-basic"
                                                        className="user-name left_dropdown_btn">
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className='menu-dropdown-menu'>
                                                        <Link className="dropdown-item" to={`/user-profile?uid=${this.state.userDetails.uid}`}><i className="icon-user"></i>User
                                                            Profile</Link>
                                                        <Link className="dropdown-item" to="#"
                                                            onClick={(e) => {
                                                                this.setState({ companyProfileModal: true });
                                                            }}>
                                                            <i className="icon-settings"></i>Edit Company</Link>
                                                    </Dropdown.Menu>

                                                </Dropdown>
                                            ) : null
                                            }


                                        </div>
                                    ) : null
                                }

                                {/* <Dropdown className="ml-0">
                                    <Dropdown.Toggle variant="success" id="dropdown-basic"
                                        className="user-name left_dropdown_btn">
                                        <strong>{this.state.userDetails.userFirstName} {this.state.userDetails.userLastName}</strong>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Link className="dropdown-item" to="/page-profile"><i className="icon-user"></i>My
                                            Profile</Link>
                                        <Link className="dropdown-item" to="/app-inbox"><i
                                            className="icon-envelope-open"></i>Messages</Link>
                                        <Link className="dropdown-item" to="/"><i className="icon-settings"></i>Settings</Link>
                                        <li className="divider" />
                                        <Link className="dropdown-item" to="/login"><i className="icon-power"></i>Logout</Link>
                                    </Dropdown.Menu>

                                </Dropdown> */}

                                <ul className="dropdown-menu dropdown-menu-right account vivify flipInY"> </ul>
                            </div>
                        </div>
                        <nav id="left-sidebar-nav" className="sidebar-nav">

                            <MetisMenu
                                content={this.state.newMenu}
                                // content={metisMenu}
                                noBuiltInClassNames={true}
                                classNameItemActive="active"
                                classNameContainer={(e) => this.toggleSubMenu(e)}
                                classNameContainerVisible="in"
                                iconNamePrefix=""
                                LinkComponent={(e) => <DefaultLink itemProps={e} />}
                            />


                        </nav>
                    </div>
                </div>


                <CompanyProfileModal companyProfileModal={this.state.companyProfileModal} updatePropValue={this.updatePropValue} />

            </>
        )
    }
}

const mapStateToProps = state => ({
    offcanvas: state.settings.offcanvas,
    miniSidebar: state.settings.miniSidebar,
    miniSideMenuOn: state.settings.miniSideMenuOn,
    miniHover: state.settings.miniHover,
})

const mapDispatchToProps = dispatch => ({
    setOffcanvas: (e) => dispatch(setOffcanvas(e)),
    setMiniSidebarMenuOn: (e) => dispatch(setMiniSidebarMenuOn(e)),
    setMiniHover: (e) => dispatch(setMiniHover(e)),
    setUserPermissions: (e) => dispatch(setUserPermissions(e)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Menu);