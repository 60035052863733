import axios from 'axios';
import React, {useEffect, useState} from 'react';
import AssignCategoryGroupModal from '../ModalImpl/AssignCategoryGroupModal';
import AssignSubCategoryModal from '../ModalImpl/AssignSubCategoryModal';
import toast, {Toaster} from 'react-hot-toast';

const SubCategories = () => {

    const [baseUrl, setBaseUrl] = useState('');
    const [token, setToken] = useState('');
    const [userDetails, setUserDetails] = useState({});

    //add modals
    const [showAssignCategoryGroupModal, setShowAssignCategoryGroupModal] = useState(false);
    const [showAssignSubCategoryModal, setShowAssignSubCategoryModal] = useState(false);

    const [allGroups, setAllGroups] = useState([]);
    const [allSubCategories, setAllSubCategories] = useState([]);

    const [selectedGroup, setSelectedGroup] = useState({});
    const [selectedSubCategory, setSelectedSubCategory] = useState({});

    const [displayTipGroup, setDisplayTipGroup] = useState("Add new group");
    const [displayTipSubCategory, setDisplayTipSubCategory] = useState("Please select a group");

    const [modalType, setmodalType] = useState(); // 0-add, 1-edit


    //get base url - useEffect
    useEffect(() => {
        setBaseUrl(localStorage.getItem('API_URL'));
        setToken(localStorage.getItem('user_token'));
        setUserDetails(JSON.parse(localStorage.getItem('user_details')));
    }, [SubCategories])

    //call getAllCategoryGroups
    useEffect(() => {
        if (baseUrl.length > 0 && token.length > 0 && Object.keys(userDetails).length > 0) {
            getAllCategoryGroups();
        }
    }, [baseUrl, token, userDetails])

    //when click one group, call getAllSubCategoryiesByGroup
    useEffect(() => {
        if (Object.keys(selectedGroup).length > 0) {
            getAllSubCategoryiesByGroup()
        }
    }, [selectedGroup])

    //when click one province, get relavant districts
    useEffect(() => {
        if (Object.keys(selectedSubCategory).length > 0) {
            // getAll(3, selectedSubCategory.id)
        }
    }, [selectedSubCategory])


    //getAllCategoryGroups - function
    const getAllCategoryGroups = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/category-master/get-all-groups/customer/${userDetails.cid}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let dataList = res.data.data
            setAllGroups(dataList);
            setAllSubCategories([]);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }

    //getAllSubCategoryiesByGroup - function
    const getAllSubCategoryiesByGroup = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/category-master/get-sub-categories/category-group/${selectedGroup.id}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            const dataList = res.data.data.list

            if (dataList.length === 0) {
                setAllSubCategories([]);
                setDisplayTipSubCategory("Add new sub category")
            } else {
                setAllSubCategories(dataList);
            }

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }

        })
    }


    return (

        <div className="container-fluid">
            <div><Toaster /></div>

            <div className="block-header">
                <div className="row clearfix">
                    <div className="col-md-6 col-sm-12">
                        <h2>Sub Categories</h2>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">TS</a></li>
                                <li className="breadcrumb-item"><a href="/">Category Management</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Sub Categories</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div className="row clearfix">

                <div className="col-lg-4 col-md-12">
                    <div className="card">
                        <div className="body taskboard b-cyan progress_task">
                            <h6 className="font300 mb-3">Category Groups
                                <span className="float-right">
                                    <i className="fa fa-plus-circle custom-icon" title='Assign New Category Group'
                                        onClick={() => {
                                            setmodalType(0);
                                            setShowAssignCategoryGroupModal(true);
                                        }}
                                    ></i>
                                </span>
                            </h6>
                            <div className="dd" data-plugin="nestable">
                                {allGroups.length > 0 ? (
                                    <>
                                        {allGroups.map((group, i) => (

                                            <div
                                                className={`dd-handle align-items-center ${selectedGroup === group ? 'active' : ''}`}
                                                key={group.id}
                                                onClick={() => {
                                                    if (selectedGroup !== group) {
                                                        setAllSubCategories([]);
                                                        setSelectedSubCategory([]);
                                                        
                                                        setSelectedGroup(group);
                                                    }
                                                }}
                                            >
                                                <div className='d-flex justify-content-between mb-2'>
                                                    <div> {group.category} </div>
                                                    <div className="action">
                                                        <i className="fa fa-edit action-icon" title='Edit'
                                                            onClick={() => {
                                                                setmodalType(1);
                                                                setShowAssignCategoryGroupModal(true);
                                                            }}
                                                        />
                                                    </div>

                                                </div>

                                                <div className='category-group-down'>
                                                    <span className="badge badge-secondary mr-1"> <i
                                                        className="zmdi zmdi-check-square" />{group.serviceTime}</span>

                                                    <span className="badge badge-secondary"> <i
                                                        className="zmdi zmdi-check-square" />{group.noRangeMin} - {group.noRangeMax}</span>

                                                    <span className="text-muted float-right mr-1"> <i
                                                        className="zmdi zmdi-check-square" />{group.subCategoriesCount}</span>
                                                </div>
                                            </div>

                                        ))}
                                    </>
                                ) : (
                                    <div className="dd-handle align-items-center text-center">
                                        <div className='d-flex justify-content-between'>
                                            <div> {displayTipGroup} </div>
                                        </div>
                                    </div>
                                )}


                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-12">
                    <div className="card">
                        <div className="body taskboard b-orange completed_task">
                            <h6 className="font300 mb-3">Sub Categories
                                <span className="float-right">
                                    <i className="fa fa-plus-circle custom-icon" title='Assign New Sub Category'
                                        style={Object.keys(selectedGroup).length === 0 ? { display: 'none' } : {}}
                                        onClick={() => {
                                            setmodalType(0);
                                            setShowAssignSubCategoryModal(true);
                                        }}
                                    ></i>
                                </span>
                            </h6>
                            <div className="dd" data-plugin="nestable">

                                {allSubCategories.length > 0 ? (
                                    <>
                                        {
                                            allSubCategories.map((subCategory, i) => (

                                                <div
                                                    className={`dd-handle align-items-center ${selectedSubCategory === subCategory ? 'active' : ''}`}
                                                    key={subCategory.id}
                                                    onClick={() => {

                                                        if (selectedSubCategory !== subCategory) {
                                                            setSelectedSubCategory(subCategory);
                                                        }
                                                    }}
                                                >
                                                    <div className='d-flex justify-content-between'>
                                                        <div> {subCategory.category} </div>
                                                        <div className="action">
                                                            <i className="fa fa-eye action-icon" />
                                                        </div>

                                                    </div>
                                                    <span href="/" className="text-muted"><i
                                                        className="zmdi zmdi-check-square" /> {subCategory.count} </span>
                                                </div>

                                            ))
                                        }
                                    </>
                                ) : (
                                    <div className="dd-handle align-items-center text-center">
                                        <div className='d-flex justify-content-between'>
                                            <div> {displayTipSubCategory} </div>
                                        </div>
                                    </div>
                                )}

                            </div>

                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-12">

                    {Object.keys(selectedSubCategory).length > 0 ? (
                        <div className="card">
                            <div className="body">
                                <div className="row text-center">
                                    <div className="col-6 border-right pb-4 pt-4">
                                        <label className="mb-0">Wait time exception</label>
                                        <h4 className="font-24 font-weight-bold text-col-blue">{selectedSubCategory.waitTime}</h4>
                                    </div>
                                    <div className="col-6 pb-4 pt-4">
                                        <label className="mb-0">Service time exception</label>
                                        <h4 className="font-24 font-weight-bold text-col-blue">{selectedSubCategory.serviceTime}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="body">
                                <div className="form-group">
                                    <label className="d-block">Category Code <span
                                        className="float-right">{selectedSubCategory.categoryCode}</span></label>
                                    <div className="progress progress-xxs">
                                        <div className="progress-bar progress-bar-success" role="progressbar"
                                            aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                                            style={{ width: "100%" }}></div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="d-block">Category Name <span
                                        className="float-right">{selectedSubCategory.category}</span></label>
                                    <div className="progress progress-xxs">
                                        <div className="progress-bar progress-bar-warning" role="progressbar"
                                            aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                                            style={{ width: "100%" }}></div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="d-block">Short Category Name <span
                                        className="float-right">{selectedSubCategory.shortCatName}</span></label>
                                    <div className="progress progress-xxs">
                                        <div className="progress-bar progress-bar-success" role="progressbar"
                                            aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                                            style={{ width: "100%" }}></div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="d-block">Default start ticket <span
                                        className="float-right">{selectedSubCategory.noRangeMin}</span></label>
                                    <div className="progress progress-xxs">
                                        <div className="progress-bar progress-bar-warning" role="progressbar"
                                            aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                                            style={{ width: "100%" }}></div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="d-block">Default end ticket
                                        <span className="float-right">{selectedSubCategory.noRangeMax}</span>
                                    </label>
                                    <div className="progress progress-xxs">
                                        <div className="progress-bar progress-bar-success" role="progressbar"
                                            aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                                            style={{ width: "100%" }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="card">
                            <div className="body">
                                <div className="row text-center">
                                    <div className="col-12 pb-4 pt-4">
                                        <label className="mb-0">Select sub category</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>

            </div>

            <AssignCategoryGroupModal showAssignCategoryGroupModal={showAssignCategoryGroupModal}
                setShowAssignCategoryGroupModal={setShowAssignCategoryGroupModal}
                modalType={modalType} selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup}
                getAllCategoryGroups={getAllCategoryGroups} />
            <AssignSubCategoryModal showAssignSubCategoryModal={showAssignSubCategoryModal}
                setShowAssignSubCategoryModal={setShowAssignSubCategoryModal}
                selectedGroup={selectedGroup}
                getAllSubCategoryiesByGroup={getAllSubCategoryiesByGroup} />

        </div>
    )
}

export default SubCategories;