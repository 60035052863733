import React, { useState, useEffect, useRef } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import Button from 'react-bootstrap-button-loader';
import axios from "axios";
import { useNavigate } from 'react-router-dom';

import { Stage, Layer, Image, Line } from 'react-konva';
import useImage from 'use-image';
import TextShapeMini from '../ComponentsImpl/ReactKonva/TextShapeMini';
import URLImageMini from '../ComponentsImpl/ReactKonva/URLImageMini';
import RectangleMini from '../ComponentsImpl/ReactKonva/RectangleMini';



// used for add 
const ExistingKiosksTemplateModal = ({ existingKiosksTemplatesModal, setExistingKiosksTemplatesModal, getAllTemplatesByBranchParent }) => {

    const car = useRef();
    const stageRef = React.useRef();
    const history = useNavigate();

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const selectedBranch = JSON.parse(localStorage.getItem('selected_branch'));

    const [buttonLoader, setButtonLoader] = useState(false);

    // templates
    const [allTemplates, setAllTemplates] = useState([]);
    const [name, setname] = useState('')
    const [selectedTemplate, setSelectedTemplate] = useState(0);



    // call getAllTemplatesByBranch - useffect
    useEffect(() => {
        if (existingKiosksTemplatesModal) {
            if (baseUrl.length > 0 && token.length > 0 && Object.keys(selectedBranch).length > 0) {
                getAllTemplatesByBranch();
            }
        }
    }, [existingKiosksTemplatesModal])



    // getAllTemplatesByBranch - function
    const getAllTemplatesByBranch = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/kiosk/kiosk-design/get-list/global-designs`,
            headers: {
                Authorization: token
            },
        }).then((res) => {

            let data = res.data.data
            let newScreenList = resizeComponentsForScreenPreview(data);
            setAllTemplates(newScreenList);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })

    }

    // after get all templates, resize components for screen preview - function
    const resizeComponentsForScreenPreview = (screenList) => {

        for (let j = 0; j < screenList.length; j++) {

            let screenComponentList = screenList[j].components;

            let tempArray = [];

            if (screenList[j].landscape) {
                screenList[j].gridWidth = 630;
                screenList[j].gridHeight = 390;
            } else {
                screenList[j].gridWidth = 390;
                screenList[j].gridHeight = 630;
            }

            for (let i = 0; i < screenComponentList.length; i++) {
                const component = screenComponentList[i];

                if (component.type === 'rect') {

                    var object = {
                        fill: hex2rgba(component.fill),
                        height: component.h / 5,
                        hex: component.fill,
                        id: component.id,
                        name: component.name,
                        rgba: hex2rgbaobject(component.fill),
                        type: component.type,
                        width: component.w / 5,
                        x: component.x / 5,
                        y: component.y / 5
                    }
                    tempArray.push(object);

                } else if (component.type === 'img' || component.type === 'btn') {

                    var object = {
                        height: component.h / 5,
                        // hex: component.fill,
                        id: component.id,
                        imgId: component.imageId,
                        name: component.name,
                        // rgba: hex2rgbaobject(component.fill),
                        src: component.imageUrl,
                        type: component.type,
                        width: component.w / 5,
                        x: component.x / 5,
                        y: component.y / 5
                    }
                    tempArray.push(object);

                } else if (component.type === 'text') {

                    var object = {
                        fill: hex2rgba(component.componentText.color),
                        height: component.h / 5,
                        hex: component.componentText.color,
                        id: component.id,
                        name: component.name,
                        rgba: hex2rgbaobject(component.componentText.color),
                        text: component.componentText.text,
                        textProps: {
                            fontSize: component.componentText.size / 5,
                            align: component.componentText.align,
                            fontFamily: component.componentText.font.name,
                        },
                        type: component.type,
                        width: component.w / 5,
                        x: component.x / 5,
                        y: component.y / 5
                    }
                    tempArray.push(object);
                }

            }

            screenList[j].components = tempArray;

        }

        return screenList;

    }



    // addKiosks - function
    const addExistingKioskTemplate = () => {
        setButtonLoader(true);

        let isValidate = validate();

        if (isValidate) {

            axios({
                method: 'POST',
                url: `${baseUrl}/kiosk/kiosk-design/add`,
                headers: {
                    "Authorization": token
                },
                data: {
                    name: name,
                    branchId: selectedBranch.id,
                    globalDesignId: selectedTemplate
                }
            }).then((res) => {

                setButtonLoader(false);
                closeModal();

                // console.log('addKiosksDesign success');
                // let data = res.data.data;

                // history(`/design-kiosks-template?t_id=${data.id}`)

                getAllTemplatesByBranchParent();
                

            }).catch((error) => {
                console.log(error);
                setButtonLoader(false);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }
            })
        }
    }



    // validate - function
    const validate = () => {

        if (name.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Template name empty', {
                position: 'bottom-center',
            });
            return false;
        } else if (selectedTemplate.length === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Select a kiosk template', {
                position: 'bottom-center',
            });
            return false;
        } else {
            return true;
        }
    }

    // close modal - function
    const closeModal = () => {
        setname('');
        setSelectedTemplate(0);
        setExistingKiosksTemplatesModal(false);
    }


    // hex to rgba
    const hex2rgba = (hex) => {
        let c = hex.substring(1).split('');

        if (!/^#(([\dA-Fa-f]{3}){1,2}|([\dA-Fa-f]{4}){1,2})$/.test(hex)) {
            throw new Error('Your hexadecimal color is not correct.');
        }

        switch (c.length) {
            case 3:
                c = [c[0] + c[0], c[1] + c[1], c[2] + c[2], 'ff'];
                break;
            case 4:
                c = [c[0] + c[0], c[1] + c[1], c[2] + c[2], c[3] + c[3]];
                break;
            case 6:
                c = [c[0] + c[1], c[2] + c[3], c[4] + c[5], 'ff'];
                break;
            case 8:
                c = [c[0] + c[1], c[2] + c[3], c[4] + c[5], c[6] + c[7]];
                break;
        }

        c = c.map((char) => parseInt(char, 16).toString());
        c[3] = (Math.round((parseInt(c[3], 10) / 255) * 100) / 100).toString();
        return c[3] === '1'
            ? `rgb( ${c[0]}, ${c[1]}, ${c[2]})`
            : `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${c[3]})`;
    }

    // hex to rgba
    const hex2rgbaobject = (hex) => {
        let c = hex.substring(1).split('');

        if (!/^#(([\dA-Fa-f]{3}){1,2}|([\dA-Fa-f]{4}){1,2})$/.test(hex)) {
            throw new Error('Your hexadecimal color is not correct.');
        }

        switch (c.length) {
            case 3:
                c = [c[0] + c[0], c[1] + c[1], c[2] + c[2], 'ff'];
                break;
            case 4:
                c = [c[0] + c[0], c[1] + c[1], c[2] + c[2], c[3] + c[3]];
                break;
            case 6:
                c = [c[0] + c[1], c[2] + c[3], c[4] + c[5], 'ff'];
                break;
            case 8:
                c = [c[0] + c[1], c[2] + c[3], c[4] + c[5], c[6] + c[7]];
                break;
        }

        c = c.map((char) => parseInt(char, 16).toString());
        c[3] = (Math.round((parseInt(c[3], 10) / 255) * 100) / 100).toString();
        return c[3] === '1'
            ? `rgb( ${c[0]}, ${c[1]}, ${c[2]})`
            : {
                r: c[0],
                g: c[1],
                b: c[2],
                a: c[3]
            }
    }

    const BGImageMini = ({ bgImg, gridWidth, gridHeight }) => {
        const [bgImage] = useImage(bgImg);
        return <Image image={bgImage}
            width={gridWidth / 5}
            height={gridHeight / 5}
        />;
    };


    return (
        <div>
            <div><Toaster /></div>
            {existingKiosksTemplatesModal ? (
                <div className={`modal fade d-block show modal-container`} id="kiosk-modal">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Add Existing Kiosk Template
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => closeModal()}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">

                                <form className='kiosks-add-form mt-3'>


                                    <div className="row clearfix">

                                        <div className="col-md-6 col-sm-12">
                                            <div className="input-group input-group-sm mb-3">
                                                <p className="input-field-side-text">Template Name  : </p>
                                                <input type="text" className="form-control" aria-label="Small"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    value={name}
                                                    onChange={(e) => {
                                                        setname(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                    </div>


                                    <div className="row clearfix">
                                        <div className='col-md-12 col-sm-12 select-kiosk-design-cont'>
                                            <div className="row clearfix">
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="input-group input-group-sm mt-2 b-3">
                                                        <p className="input-field-side-text">Select Template : </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='kiosk-designs-list mt-3' style={{ padding: '0 50px' }}>
                                                <div className="row clearfix">

                                                    {allTemplates.length > 0 ?
                                                        <>
                                                            {
                                                                allTemplates.map((item, i) =>
                                                                    <div className="col-md-3" key={i}>
                                                                        <div className={`kiosk-design ${selectedTemplate === item.id ? 'active' : ''} text-center`}
                                                                            onClick={() => {
                                                                                setSelectedTemplate(item.id);
                                                                            }}
                                                                        >
                                                                            <div className="card">
                                                                                <div className="body" title={item.name}>
                                                                                    <div className='kiosk-design-thumbnail'>
                                                                                        <Stage
                                                                                            width={item.gridWidth / 5}
                                                                                            height={item.gridHeight / 5}
                                                                                            ref={stageRef}
                                                                                            onContextMenu={e => {
                                                                                                e.evt.preventDefault()
                                                                                            }}
                                                                                            style={{
                                                                                                backgroundColor: 'white',
                                                                                                width: item.gridWidth / 5 + 'px',
                                                                                                height: item.gridHeight / 5 + 'px',
                                                                                                margin: '0px auto 0px',
                                                                                                borderRadius: '0px'
                                                                                            }}
                                                                                        >
                                                                                            <Layer>
                                                                                                <BGImageMini bgImg={item.backgroundImageURL} gridWidth={item.gridWidth} gridHeight={item.gridHeight} />
                                                                                            </Layer>


                                                                                            <Layer
                                                                                                hitGraphEnabled={true}>
                                                                                                {item.components.map((rect, i) => {
                                                                                                    return (

                                                                                                        rect.type === 'text' ?

                                                                                                            <TextShapeMini
                                                                                                                key={rect.id}
                                                                                                                // gridWidth={200}
                                                                                                                // gridHeight={(220 * 9 / 16)}
                                                                                                                text={rect.text}
                                                                                                                textProps={rect.textProps}
                                                                                                                shapeProps={rect}
                                                                                                            />

                                                                                                            :

                                                                                                            rect.type === 'rect' ?

                                                                                                                <RectangleMini key={rect.id}
                                                                                                                    // gridWidth={200}
                                                                                                                    // gridHeight={(220 * 9 / 16)}
                                                                                                                    shapeProps={rect}
                                                                                                                />

                                                                                                                :

                                                                                                                <URLImageMini key={rect.id}
                                                                                                                    image={rect.src}
                                                                                                                    // gridWidth={200}
                                                                                                                    // gridHeight={(220 * 9 / 16)}
                                                                                                                    shapeProps={rect}
                                                                                                                />

                                                                                                    );
                                                                                                })}
                                                                                            </Layer>

                                                                                        </Stage>
                                                                                    </div>
                                                                                    <label className='screen-name mt-2 mb-0 manager-data-ellipsis'>{item.name}</label>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                )
                                                            }
                                                        </>
                                                        :
                                                        <p>No available kiosk templates</p>

                                                    }




                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                </form>
                            </div>


                            <div className="modal-footer">

                                <button type="button" className="btn btn-default"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >Cancel
                                </button>

                                <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                    onClick={() => {
                                        addExistingKioskTemplate();
                                    }}
                                >Create</Button>

                            </div>

                        </div>
                    </div>
                </div >
            ) : null
            }
        </div >
    )
}

export default ExistingKiosksTemplateModal