import React, {Component} from 'react';
import ReactApexChart from "react-apexcharts";

class OperatorStatusCountChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        columnWidth: 20,
                        width: 5
                    },
                },
                fill: {
                    colors: ['#6666FF']
                },
                yaxis: [{
                    title: {
                        text: 'Ticket Count',
                    },

                }], 
                xaxis: {

                    title: {
                        text: 'Time'
                    },
                    legend: {
                        position: 'bottom'
                    },
                    axisTicks: {show: false},
                    axisBorder: {show: false},
                    categories: this.props.hourInt
                },
                legend: {
                    show: false
                },
                grid: {
                    bottom: 20,
                    top: 0,
                    left: 6
                }
            },
            series: [
                {
                    name: "Income",
                    type: 'column',
                    data: this.props.ticketCount
                }
            ]
        };
    }

    componentDidUpdate(prevProps,prevState) {
        if(prevProps.chartValue !== this.props.chartValue) {
            this.setState({
                options: {
                    chart: {
                        type: 'bar',
                        height: 350,
                        stacked: true,
                        toolbar: {
                            show: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: 20,
                            width: 5
                        },
                    },
                    fill: {
                        colors: ['#6666FF']
                    },
                    yaxis: [{
                        title: {
                            text: 'Ticket Count',
                        },
    
                    }], 
                    xaxis: {
    
                        title: {
                            text: 'Time'
                        },
                        legend: {
                            position: 'bottom'
                        },
                        axisTicks: {show: false},
                        axisBorder: {show: false},
                        categories: this.props.hourInt
                    },
                    legend: {
                        show: false
                    },
                    grid: {
                        bottom: 20,
                        top: 0,
                        left: 6
                    }
                },
                series: [
                    {
                        name: "Income",
                        type: 'column',
                        data: this.props.ticketCount
                    }
                ] 
            })
        }
    }

    render() {
        return (
            <div>
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="bar"
                    height="300px"
                    width="400px"
                />
            </div>
        );
    }
}

export default OperatorStatusCountChart;
