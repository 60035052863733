import React, { useState, useEffect } from 'react';
import AddNotificationTemplateModal from '../ModalImpl/AddNotificationTemplateModal';
import toast, { Toaster } from "react-hot-toast";
import axios from 'axios';
import Swal from "sweetalert2";

const NotificationTemplate = () => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [ShowAddNotificationModal, setShowAddNotificationModal] = useState(false);
    const [modalType, setModalType] = useState(); // 0-add, 1-edit

    const [allNotificationTemplateNameList, setAllNotificationTemplateNameList] = useState('');
    const [selectedNotificationTemplate, setSelectedNotificationTemplate] = useState({});


    //call getallsmstemplate - useeffect
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            if (baseUrl.length > 0 && token.length > 0) {
                getallnotificationtemplate();
            }
        }
    }, [NotificationTemplate])


    //getallnotificationtemplate - function
    const getallnotificationtemplate = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/alert/get-list/notification/templates/customer/${userDetails.cid}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            let data = res.data.data;
            setAllNotificationTemplateNameList(data);


        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //deleteNotificationTemplate - function
    const deleteNotificationTemplate = () => {

        Swal.fire({
            position: 'center',
            text: 'Do you want to delete this Notification template?',
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            width: '400px',

        }).then((result) => {
            if (result.value == true) {

                axios({
                    method: 'POST',
                    url: `${baseUrl}/alert/notification/template/${selectedNotificationTemplate.id}/delete`,
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {

                    toast.remove();
                    toast.success('Deleted Successfully ', {
                        position: 'bottom-center',
                    });

                    getallnotificationtemplate();

                }).catch((error) => {
                    console.log(error);

                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                })
            }

        })
    }


    return (
        <>
            <div className="container-fluid">
                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h1>Notification Template Management</h1>
                        </div>

                        <div className="col-md-6 col-sm-12 text-right hidden-xs">
                            <div className="btn btn-sm btn-primary mr-1" title="Add New Sms Template"
                                onClick={() => {
                                    setModalType(0);
                                    setShowAddNotificationModal(true);
                                }}
                            >Add New </div>
                        </div>

                    </div>
                </div>

                <div className='row clearfix'>
                    <div className='col-lg-3 col-md-3'>
                        <ul className="list-group mb-4 right-custom-nav">
                            {
                                allNotificationTemplateNameList.length > 0 ? (
                                    allNotificationTemplateNameList.map((notification, i) => (
                                        <li className={notification.id === selectedNotificationTemplate.id ? "list-group-item d-flex justify-content-between align-items-center country-select active" : "list-group-item d-flex justify-content-between align-items-center country-select"}
                                            key={i}
                                            onClick={() => {
                                                setSelectedNotificationTemplate(notification);
                                            }}
                                        >{notification.name}</li>
                                    ))
                                ) : (
                                    <li className="list-group-item d-flex justify-content-between align-items-center">No Data</li>
                                )
                            }
                        </ul>
                    </div>


                    <div className="col-lg-9 col-md-9">
                        <div className="card">
                            {
                                Object.keys(selectedNotificationTemplate).length > 0 ? (
                                    <div className="body top_counter">
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='float-right m-b-15'>

                                                    <button type="button" className="btn btn-sm btn-default"
                                                        title="Edit"
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        onClick={() => {
                                                            setModalType(1);
                                                            setShowAddNotificationModal(true);
                                                        }}
                                                    >
                                                        <i className="fa fa-pencil-square-o"> </i>
                                                    </button>
                                                    &nbsp;
                                                    <button type="button" className="btn btn-sm btn-default "
                                                        title="Delete"
                                                        data-toggle="tooltip" data-placement="top"
                                                        onClick={() => {
                                                            deleteNotificationTemplate();
                                                        }}
                                                    ><i className="icon-trash"> </i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-12'>
                                                <p>{selectedNotificationTemplate.content}</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : <p>Select a template</p>
                            }
                        </div>
                    </div>
                </div>
            </div>



            < AddNotificationTemplateModal
                ShowAddNotificationModal={ShowAddNotificationModal}
                setShowAddNotificationModal={setShowAddNotificationModal}
                modalType={modalType}
                getallnotificationtemplate={getallnotificationtemplate}
                selectedNotificationTemplate={selectedNotificationTemplate}
                setSelectedNotificationTemplate={setSelectedNotificationTemplate}
            />

        </>
    );

}
export default NotificationTemplate
