import React, { useEffect, useState } from 'react';
import AddTsuProfileModal from '../ModalImpl/AddTsuProfileModal';
import axios from "axios";
import toast from 'react-hot-toast';


const TsuProfile = () => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const userDetails = JSON.parse(localStorage.getItem('user_details'));

    const [showAddTsuProfileModal, setShowAddTsuProfileModal] = useState(false);
    const [allProfiles, setAllProfiles] = useState([])
    const [selectedProfiles, setSelectedProfiles] = useState({})
    const [allfeatures, setAllfeatures] = useState({})

    const [modalType, setmodalType] = useState(0); // 0-add, 1-edit
    const [selectedRow, setSelectedRow] = useState({});


    //getAllCallingPadProfileByCustomer - useeffect
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            getAllCallingPadProfileByCustomer();
        }
    }, [TsuProfile])

    //getAllCallingPadProfileByCustomer - function
    const getAllCallingPadProfileByCustomer = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/branch/calling-pad-profile/get-list/customer/${userDetails.cid}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let dataList = res.data.data;
            setAllProfiles(dataList);

            if (dataList.length > 0) {
                setSelectedProfiles(dataList[0])
                getAllCallingPadFeatures(dataList[0])
            }

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });

            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //getAllCallingPadFeatures - function
    const getAllCallingPadFeatures = (profile) => {

        axios({
            method: 'GET',
            url: `${baseUrl}/branch/calling-pad-profile/get-features/profile/${profile.id}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {

            let dataList = res.data.data.list;
            setAllfeatures(dataList);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });

            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //AssignCallingPadProfileFeatures - function
    const AssignCallingPadProfileFeatures = (featureId) => {

        axios({
            method: 'POST',
            url: `${baseUrl}/branch/calling-pad-profile/assign-unassign-features/profile/${selectedProfiles.id}/feature/${featureId}`,
            headers: {
                Authorization: token
            }

        }).then((res) => {

            getAllCallingPadFeatures(selectedProfiles)

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });

            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }


    return (
        <>
            {/* <Toaster /> */}
            <div className="container-fluid">
                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h1>TSU Profiles</h1>
                            <nav aria-label="breadcrumb">
                            </nav>
                        </div>

                    </div>
                </div>
                <div>

                </div>


                <div className='row clearfix'>
                    <div className="col-lg-3 col-md-4 col-sm-12">

                        <div className="btn btn-sm btn-primary btn-block"
                            onClick={() => {
                                setmodalType(0);
                                setShowAddTsuProfileModal(true);
                            }}
                        >Add New Tsu Profile
                        </div>

                        <div className='body'>
                            <ul className="list-group mt-2 mb-4 right-custom-nav">
                                {allProfiles.map((item, i) =>
                                    <li
                                        className={`list-group-item d-flex justify-content-between align-items-center ${item.id === selectedProfiles.id ? 'active' : ''}`} key={i}
                                        onClick={() => {
                                            setSelectedProfiles(item);
                                            getAllCallingPadFeatures(item);
                                        }}

                                    >
                                        <label >{item.name}</label>
                                        <div className='action'>
                                            <i className="fa fa-edit action-icon" title='Edit'
                                                onClick={() => {
                                                    setmodalType(1);
                                                    setSelectedRow(item)
                                                    setShowAddTsuProfileModal(true);

                                                }}
                                            />
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>


                    <div className="col-lg-9 col-md-9">
                        <div className="card">
                            <div className="header card-header-align">
                                <h2>Calling Pad Features</h2>
                            </div>


                            <div className='body'>
                                <div className='features-list'>
                                    {
                                        allfeatures.length > 0 ?
                                            <>
                                                {
                                                    allfeatures.map((feature, i) =>
                                                        <span className={`badge ${feature.selected ? "badge-secondary" : "badge-default"}`}
                                                            style={{ cursor: 'pointer' }}
                                                            key={i}
                                                            onClick={() => {
                                                                AssignCallingPadProfileFeatures(feature.id);
                                                            }}
                                                        >{feature.id}
                                                        </span>
                                                    )
                                                }

                                            </> : <p>No features</p>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <AddTsuProfileModal
                showAddTsuProfileModal={showAddTsuProfileModal}
                setShowAddTsuProfileModal={setShowAddTsuProfileModal}
                getAllCallingPadProfileByCustomer={getAllCallingPadProfileByCustomer}
                modalType={modalType}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
            />


        </>
    );

}
export default TsuProfile
