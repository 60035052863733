import React, { useState, useEffect } from 'react';
import AddCategoryMasterModal from '../ModalImpl/AddCategoryMasterModal';
import AddSkillsMasterModal from '../ModalImpl/AddSkillsMasterModal';
import AddMasterCategoryTicketDesign from '../ModalImpl/AddMasterCategoryTicketDesign';
import axios from "axios";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import MUIDataTable from 'mui-datatables';
import CircularProgress from "@mui/material/CircularProgress";
import { DropdownToggle, DropdownMenu, Card, UncontrolledDropdown, DropdownItem, Progress } from "reactstrap";


const CategoriesMaster = () => {

    const baseUrl = localStorage.getItem('API_URL')
    const token = localStorage.getItem('user_token')
    const userDetails = JSON.parse(localStorage.getItem('user_details'))

    const [showAddCategoryMasterModal, setShowAddCategoryMasterModal] = useState(false);
    const [showAddSkillsMasterModal, setShowAddSkillsMasterModal] = useState(false);
    const [showAddCategoryTicketDesign, setShowAddCategoryTicketDesign] = useState(false)
    const [modalType, setmodalType] = useState(); // 0-add, 1-edit

    const [allCategoryList, setAllCategoryList] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [isLoading, setLoading] = useState(false);



    // data table
    const [dataTable, setDataTable] = useState({
        page: 0,
        size: 10,
        search: '',
        order: null,
    });

    const setTable = (tableState) => {
        setDataTable({
            page: tableState.page,
            search: tableState.searchText,
            size: tableState.rowsPerPage,
            order: tableState.sortOrder
        });
    };

    const options = {
        tableBodyHeight: '50vh',
        filterType: 'dropdown',
        filter: false,
        responsive: 'vertical',
        serverSide: true,
        count: count,
        download: false,
        jumpToPage: true,
        selectableRows: 'none',
        print: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 20, 30, 50, 100],
        setTableProps: () => {
            return {
                size: 'small',
            };
        },
        onTableChange: (action, tableState) => {

            switch (action) {
                case 'changePage':
                    getAllCategories(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'sort':
                    getAllCategories(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'changeRowsPerPage':
                    setRowsPerPage(tableState.rowsPerPage);
                    getAllCategories(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                case 'search':
                    getAllCategories(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    setTable(tableState);
                    break;
                default:
                    break;
            }
        },

    };

    const columns = [
        {
            label: 'Id',
            name: 'id',
            options: {
                sort: true,
                filter: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {

                    if (allCategoryList === undefined) return;
                    let item = allCategoryList.find((item, i) => i === dataIndex);

                    return (
                        <>
                            {item.disability ? (
                                <p className='table-text-disable'>{item.id}</p>
                            ) : (
                                <p className='m-0'>{item.id}</p>
                            )}

                        </>
                    );
                }
            }
        },
        {
            label: 'Category Name',
            name: 'category',
            options: {
                sort: true,
                filter: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {

                    if (allCategoryList === undefined) return;
                    let item = allCategoryList.find((item, i) => i === dataIndex);

                    return (
                        <>
                            {item.disability ? (
                                <p className='table-text-disable'>
                                    {item.category}
                                    {/* {item.vip ? <i className="fa fa-star vip-icon" aria-hidden="true"></i> : null} */}
                                    {item.vip ? <span className="vip-icon">VIP</span> : null}
                                </p>
                            ) : (
                                <p className='m-0'>
                                    {item.category}
                                    {/* {item.vip ? <i className="fa fa-star vip-icon" aria-hidden="true"></i> : null} */}
                                    {item.vip ? <span className="vip-icon">VIP</span> : null}
                                </p>
                            )}

                        </>
                    );
                }
            }
        },
        {
            label: 'Short Category Name',
            name: 'shortCatName',
            options: {
                sort: true,
                filter: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {

                    if (allCategoryList === undefined) return;
                    let item = allCategoryList.find((item, i) => i === dataIndex);

                    return (
                        <>
                            {item.disability ? (
                                <p className='table-text-disable'>
                                    {item.categoryCode}
                                </p>
                            ) : (
                                <p className='m-0'>
                                    {item.categoryCode}
                                </p>
                            )}

                        </>
                    );
                }
            }
        },
        {
            label: "Wait Time Exception",
            name: 'waitTime',
            options: {
                sort: true,
                filter: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {

                    if (allCategoryList === undefined) return;
                    let item = allCategoryList.find((item, i) => i === dataIndex);

                    return (
                        <>
                            {item.disability ? (
                                <p className='table-text-disable'>{item.waitTime}</p>
                            ) : (
                                <p className='m-0'>{item.waitTime}</p>
                            )}

                        </>
                    );
                }
            }
        },
        {
            label: 'Service Time Exception',
            name: 'serviceTime',
            options: {
                filter: false,
                sort: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {

                    if (allCategoryList === undefined) return;
                    let item = allCategoryList.find((item, i) => i === dataIndex);

                    return (
                        <>
                            {item.disability ? (
                                <p className='table-text-disable'>{item.serviceTime}</p>
                            ) : (
                                <p className='m-0'>{item.serviceTime}</p>
                            )}

                        </>
                    );
                }
            }
        },
        {
            label: 'Start Ticket',
            name: 'noRangeMin',
            options: {
                filter: false,
                sort: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {

                    if (allCategoryList === undefined) return;
                    let item = allCategoryList.find((item, i) => i === dataIndex);

                    return (
                        <>
                            {item.disability ? (
                                <p className='table-text-disable'>{item.noRangeMin}</p>
                            ) : (
                                <p className='m-0'>{item.noRangeMin}</p>
                            )}

                        </>
                    );
                }
            }
        },
        {
            label: 'End Ticket',
            name: 'noRangeMax',
            options: {
                filter: false,
                sort: true,
                customBodyRenderLite: (dataIndex, rowIndex) => {

                    if (allCategoryList === undefined) return;
                    let item = allCategoryList.find((item, i) => i === dataIndex);

                    return (
                        <>
                            {item.disability ? (
                                <p className='table-text-disable'>{item.noRangeMax}</p>
                            ) : (
                                <p className='m-0'>{item.noRangeMax}</p>
                            )}

                        </>
                    );
                }
            },
        },
        {
            label: 'Action',
            name: '',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {

                    if (allCategoryList === undefined) return;
                    let item = allCategoryList.find((item, i) => i === dataIndex);

                    return (
                        <>

                            <UncontrolledDropdown>

                                <DropdownToggle type="button" className={`btn btn-sm btn-default table-action-btn`} data-toggle="tooltip"
                                    data-placement="top"
                                ><i className="fa fa-angle-right" aria-hidden="true"></i>
                                </DropdownToggle>

                                <DropdownMenu
                                    positionFixed={true}
                                    className="dropdown-menu-xs">
                                    <ul className="link-list-opt">
                                        <li>
                                            <DropdownItem
                                                tag="a"
                                                href="javascript:void(0);"
                                                className={`${item.disability ? 'table-action-btn-option-disable' : ''}`}
                                                onClick={() => {
                                                    setSelectedRow(item);
                                                    setmodalType(2);
                                                    setShowAddCategoryMasterModal(true);
                                                }}
                                            >
                                                <i className="fa fa-desktop mr-3" aria-hidden="true"></i>
                                                <span>View</span>
                                            </DropdownItem>
                                        </li>
                                        <li>
                                            <DropdownItem
                                                tag="a"
                                                href="javascript:void(0);"
                                                className={`${item.disability ? 'table-action-btn-option-disable' : ''}`}
                                                onClick={() => {
                                                    setSelectedRow(item);
                                                    setmodalType(1);
                                                    setShowAddCategoryMasterModal(true);
                                                }}
                                            >
                                                <i className="fa fa-pencil-square-o mr-3" aria-hidden="true"></i>
                                                <span>Edit</span>
                                            </DropdownItem>
                                        </li>
                                        <li>
                                            <DropdownItem
                                                tag="a"
                                                href="javascript:void(0);"
                                                className={`${item.disability ? 'table-action-btn-option-disable' : ''}`}
                                                onClick={() => {
                                                    setShowAddCategoryTicketDesign(true);
                                                    setSelectedRow(item);
                                                }}
                                            >
                                                <i className="fa fa-ticket mr-3"></i>
                                                <span>Ticket Design</span>
                                            </DropdownItem>
                                        </li>
                                        <li>
                                            <DropdownItem
                                                tag="a"
                                                href="javascript:void(0);"
                                                className={`${item.disability ? 'table-action-btn-option-disable' : ''}`}
                                                onClick={() => {
                                                    deleteCategory(item.id)
                                                }}
                                            >
                                                <i className="icon-trash mr-3"> </i>
                                                <span>Delete</span>
                                            </DropdownItem>
                                        </li>
                                        <li>
                                            <DropdownItem
                                                tag="a"
                                                href="javascript:void(0);"
                                                onClick={() => {
                                                    handleEnableDisableCategory(item.id, item.disability)
                                                }}
                                            >
                                                {item.disability ? (
                                                    <>
                                                        <i className={`fa fa-eye mr-3`}> </i>
                                                        <span>Enable</span>
                                                    </>

                                                ) : (
                                                    <>
                                                        <i className={`fa fa-eye-slash mr-3`}> </i>
                                                        <span>Disable</span>
                                                    </>
                                                )}

                                            </DropdownItem>
                                        </li>

                                    </ul>
                                </DropdownMenu>
                            </UncontrolledDropdown>

                        </>
                    );
                }
            },
        },
    ];


    // call getAllCategories - useEffect
    useEffect(() => {
        if (token !== null && userDetails !== null) {
            if (baseUrl.length > 0 && token.length > 0) {
                getAllCategories(0, null, null, rowsPerPage);
            }
        }
    }, [CategoriesMaster]);


    // get all categories for table - function
    const getAllCategories = (page, sortOrder, search, size) => {
        setLoading(true);

        axios({
            method: 'POST',
            url: `${baseUrl}/category-master/get-all-paginated/customer/${userDetails.cid}`,
            headers: {
                Authorization: token
            },
            data: {
                page: page,
                size: size,
                sort: sortOrder,
                searchText: search
            }
        }).then((res) => {

            let data = res.data.data;
            setAllCategoryList((data.content));
            setCount(data.totalElements);
            setLoading(false);

        }).catch((error) => {
            console.log(error);
            setLoading(false);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    };

    // enable / disable category - function
    const handleEnableDisableCategory = (id, disability) => {

        if (disability == false) {
            Swal.fire({
                position: 'center',
                text: 'Do you want to disable this category?',
                showConfirmButton: true,
                confirmButtonText: "Yes",
                showCancelButton: true,
                cancelButtonText: "No",
                width: '400px',

            }).then((result) => {
                if (result.value == true) {

                    axios({
                        method: 'POST',
                        url: `${baseUrl}/category-master/enable-disable/${id}`, data: {},
                        headers: {
                            Authorization: token
                        }
                    }).then((res) => {

                        toast.remove();
                        toast.success('Category disabled Successfully ', {
                            position: 'bottom-center',
                        });
                        getAllCategories(dataTable.page, dataTable.order, dataTable.search, dataTable.size);

                    }).catch((error) => {
                        console.log(error);

                        if (error.response !== undefined) {
                            toast.remove();
                            toast.error(error.response.data.message, {
                                position: 'bottom-center',
                            });
                        } else {
                            toast.remove();
                            toast.error('Something went wrong', {
                                position: 'bottom-center',
                            });
                        }
                    })
                }
            })

        } else {

            Swal.fire({
                position: 'center',
                text: 'Do you want to enable this category?',
                showConfirmButton: true,
                confirmButtonText: "Yes",
                showCancelButton: true,
                cancelButtonText: "No",
                width: '400px',
            }).then((result) => {
                if (result.value === true) {

                    axios({
                        method: 'POST',
                        url: `${baseUrl}/category-master/enable-disable/${id}`, data: {},
                        headers: {
                            Authorization: token
                        }
                    }).then((res) => {

                        toast.remove();
                        toast.success('Category enabled Successfully', {
                            position: 'bottom-center',
                        });
                        getAllCategories(dataTable.page, dataTable.order, dataTable.search, dataTable.size);

                    }).catch((error) => {
                        console.log(error);

                        if (error.response !== undefined) {
                            toast.remove();
                            toast.error(error.response.data.message, {
                                position: 'bottom-center',
                            });
                        } else {
                            toast.remove();
                            toast.error('Something went wrong', {
                                position: 'bottom-center',
                            });
                        }
                    })
                }
            })
        }
    };

    // delete category - function
    const deleteCategory = (id) => {
        Swal.fire({
            position: 'center',
            text: 'Do you want to delete this category?',
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            width: '400px',

        }).then((result) => {
            if (result.value === true) {

                axios({
                    method: 'POST',
                    url: `${baseUrl}/category-master/delete/${id}`, data: {},
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {

                    toast.remove();
                    toast.success('Category deleted Successfully ', {
                        position: 'bottom-center',
                    });
                    getAllCategories(dataTable.page, dataTable.order, dataTable.search, dataTable.size);

                }).catch((error) => {
                    console.log(error);

                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                })
            }
        })
    };





    return (
        <>
            <div className="container-fluid">

                <div className="block-header">
                    <div className="row clearfix">

                        <div className="col-md-6 col-sm-12">
                            <h1>Master Categories Management</h1>
                        </div>

                        <div className="col-md-6 col-sm-12 text-right hidden-xs">
                            <div className="btn btn-sm btn-primary mr-1" title="Add New Skills"
                                onClick={() => {
                                    setmodalType(0);
                                    setShowAddSkillsMasterModal(true);
                                }}
                            >Skill Management
                            </div>

                            <div className="btn btn-sm btn-primary mr-1" title="Add New Category"
                                onClick={() => {
                                    setmodalType(0);
                                    setShowAddCategoryMasterModal(true);
                                }}
                            >Add New
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row clearfix">
                    <div className="col-lg-12">

                        <MUIDataTable
                            title={<>
                                {count}{count === 1 ? ' record' : ' records'}.
                                {isLoading &&
                                    <>&nbsp;&nbsp;&nbsp;<CircularProgress color="primary"
                                        size={10} /></>}
                            </>
                            }
                            data={allCategoryList}
                            columns={columns}
                            options={options}
                            className='text-center-table'
                        />

                    </div>
                </div>

            </div>

            <AddCategoryMasterModal showAddCategoryMasterModal={showAddCategoryMasterModal}
                setShowAddCategoryMasterModal={setShowAddCategoryMasterModal}
                getAllCategories={getAllCategories}
                modalType={modalType}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                dataTable={dataTable}
            />

            <AddSkillsMasterModal showAddSkillsMasterModal={showAddSkillsMasterModal}
                setShowAddSkillsMasterModal={setShowAddSkillsMasterModal}
                modalType={modalType}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
            />

            <AddMasterCategoryTicketDesign showAddCategoryTicketDesign={showAddCategoryTicketDesign}
                setShowAddCategoryTicketDesign={setShowAddCategoryTicketDesign}
                baseUrl={baseUrl} token={token} userDetails={userDetails}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
            />

        </>
    );
}
export default CategoriesMaster
