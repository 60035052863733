import React, { useEffect, useState } from "react";
import { Accordion, Button } from "react-bootstrap";

import TokenWiseReport from "./TokenWiseReportTables/TokenWiseReport";
import TicketDetailsReport from "./TokenWiseReportTables/TicketDetailsReport";
import NoshowTicketDetailsReport from "./TokenWiseReportTables/NoshowTicketDetailsReport";
import TransferredTicketDetailsReport from "./TokenWiseReportTables/TransferredTicketDetailsReport";

import axios from "axios";
import { toast } from "react-toastify";
import CustomerDataReport from "./TokenWiseReportTables/CustomerDataReport";

const TokenWiseReports = ({
  selectedRegionIds,
  changeRegionIds,
  dateFrom,
  dateTo,
  downloadExcel,
  reportUrl,
}) => {
  const baseUrl = localStorage.getItem("API_URL");
  const token = localStorage.getItem("user_token");
  const userDetails = JSON.parse(localStorage.getItem("user_details"));

  const [openTokenWiseReport, setOpenTokenWiseReport] = useState(false);

  const [openTicketDetailsReport, setOpenTicketDetailsReport] = useState(false);
  const [openNoshowTicketDetailsReport, setOpenNoshowTicketDetailsReport] =
    useState(false);
  const [openCustomerDetailsReport, setOpenCustomerDetailsReport] =
    useState(false);
  const [openTransTicketDetailsReport, setOpenTransTicketDetailsReport] =
    useState(false);

  const [reportsAccess, setReportsAccess] = useState([]);

  // call getReportAccess - useEffect
  useEffect(() => {
    if (reportUrl) {
      getReportAccess();
    }
  }, [TokenWiseReports, reportUrl]);

  // get report access - function
  const getReportAccess = () => {
    axios({
      method: "GET",
      url: `${reportUrl}/report-controller/get/report-access/customer/${userDetails.cid}/parent/Token Wise Reports/get-report-access`,
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        let data = res.data.data;
        setReportsAccess(data);
      })
      .catch((error) => {
        console.log(error);

        if (error.response !== undefined) {
          toast.error(error.response.data.message, {
            position: "bottom-center",
          });
        } else {
          toast.remove();
          toast.error("Something went wrong", {
            position: "bottom-center",
          });
        }
      });
  };

  return (
    <>
      <div className="row clearfix">
        <div className="col-lg-12">
          <div className="card">
            <Accordion
              className="accordion report-accordion"
              id="accordion"
              defaultActiveKey=""
            >
              {reportsAccess.map((item, i) => {
                if (item.name === "Token Wise Report" && item.active) {
                  return (
                    <div>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            className="btn btn-link vertically-align-center-flex"
                            onClick={() => {
                              if (openTokenWiseReport) {
                                setOpenTokenWiseReport(false);
                              } else {
                                setOpenTokenWiseReport(true);
                                setOpenTicketDetailsReport(false);
                                setOpenNoshowTicketDetailsReport(false);
                                setOpenCustomerDetailsReport(false);
                                setOpenTransTicketDetailsReport(false);
                              }
                            }}
                          >
                            Token Wise Report
                            <div className="float-right vertically-align-center-flex-2">
                              {userDetails !== null ? (
                                <>
                                  {/* <a href={`${baseUrl}/report-controller/download-pdf/original-ticket-details-report/${dateFrom}/${dateTo}/${selectedRegionIds.length > 0 ? selectedRegionIds.toString() : userDetails.bid}`}
                                    target='_blank' className="btn btn-sm btn-default" title="View PDF"
                                ><i class="fa fa-file-pdf-o" aria-hidden="true"></i></a> */}

                                  {item.excel ? (
                                    <a
                                      href={`${reportUrl}/real-time/get/xl/token-report/from/${dateFrom}/to/${dateTo}/${
                                        selectedRegionIds.length > 0
                                          ? selectedRegionIds.toString()
                                          : userDetails.bid
                                      }`}
                                      target="_blank"
                                      className="btn btn-sm btn-default"
                                      title="View Excel"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-excel-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  ) : null}
                                </>
                              ) : null}
                              <span className="p-l-20">
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </h5>
                      </div>
                      <Accordion.Collapse eventKey="0" className="collapse">
                        <TokenWiseReport
                          openTokenWiseReport={openTokenWiseReport}
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          selectedRegionIds={selectedRegionIds}
                          changeRegionIds={changeRegionIds}
                          reportUrl={reportUrl}
                        />
                      </Accordion.Collapse>
                    </div>
                  );
                } else if (
                  item.name === "Ticket Details Report" &&
                  item.active
                ) {
                  return (
                    <div key={i}>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="1"
                            className="btn btn-link vertically-align-center-flex"
                            onClick={() => {
                              if (openTicketDetailsReport) {
                                setOpenTicketDetailsReport(false);
                              } else {
                                setOpenTicketDetailsReport(true);
                                setOpenNoshowTicketDetailsReport(false);
                                setOpenCustomerDetailsReport(false);
                                setOpenTransTicketDetailsReport(false);
                                setOpenTokenWiseReport(false);
                              }
                            }}
                          >
                            Ticket Details Report
                            <div className="float-right vertically-align-center-flex-2">
                              {userDetails !== null ? (
                                <>
                                  {item.pdf ? (
                                    <a
                                      href={`${reportUrl}/report-controller/download-pdf/${dateFrom}/${dateTo}/${
                                        selectedRegionIds.length > 0
                                          ? selectedRegionIds.toString()
                                          : userDetails.bid
                                      }/ticket-details-report`}
                                      target="_blank"
                                      className="btn btn-sm btn-default"
                                      title="View PDF"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-pdf-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  ) : null}

                                  {item.excel ? (
                                    <button
                                      className="btn btn-sm btn-default"
                                      title="View Excel"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        downloadExcel(
                                          "tickets-details-report",
                                          "_qr_td_table"
                                        );
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-excel-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : null}
                                </>
                              ) : null}
                              <span className="p-l-20">
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </h5>
                      </div>
                      <Accordion.Collapse eventKey="1" className="collapse">
                        <TicketDetailsReport
                          openTicketDetailsReport={openTicketDetailsReport}
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          selectedRegionIds={selectedRegionIds}
                          changeRegionIds={changeRegionIds}
                          reportUrl={reportUrl}
                        />
                      </Accordion.Collapse>
                    </div>
                  );
                } else if (
                  item.name === "No-show Ticket Details Report" &&
                  item.active
                ) {
                  return (
                    <div key={i}>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="2"
                            className="btn btn-link vertically-align-center-flex"
                            onClick={() => {
                              if (openNoshowTicketDetailsReport) {
                                setOpenNoshowTicketDetailsReport(false);
                              } else {
                                setOpenNoshowTicketDetailsReport(true);
                                setOpenTicketDetailsReport(false);
                                setOpenCustomerDetailsReport(false);
                                setOpenTransTicketDetailsReport(false);
                                setOpenTokenWiseReport(false);
                              }
                            }}
                          >
                            No-show Ticket Details Report
                            <div className="float-right vertically-align-center-flex-2">
                              {userDetails !== null ? (
                                <>
                                  {item.pdf ? (
                                    <a
                                      href={`${reportUrl}/report-controller/download-pdf/${dateFrom}/${dateTo}/${
                                        selectedRegionIds.length > 0
                                          ? selectedRegionIds.toString()
                                          : userDetails.bid
                                      }/no-show-tickets-report`}
                                      target="_blank"
                                      className="btn btn-sm btn-default"
                                      title="View PDF"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-pdf-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  ) : null}

                                  {item.excel ? (
                                    <button
                                      className="btn btn-sm btn-default"
                                      title="View Excel"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        downloadExcel(
                                          "noshow-tickets-details-report",
                                          "_qr_nstd_table"
                                        );
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-excel-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : null}
                                </>
                              ) : null}
                              <span className="p-l-20">
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </h5>
                      </div>
                      <Accordion.Collapse eventKey="2" className="collapse">
                        <NoshowTicketDetailsReport
                          openNoshowTicketDetailsReport={
                            openNoshowTicketDetailsReport
                          }
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          selectedRegionIds={selectedRegionIds}
                          changeRegionIds={changeRegionIds}
                          reportUrl={reportUrl}
                        />
                      </Accordion.Collapse>
                    </div>
                  );
                  // }
                  // else if (item.name === 'Customer Details Report' && item.active) {
                  //     return (
                  //         <div key={i}>
                  //             <div className="card-header" id="headingOne">
                  //                 <h5 className="mb-0">
                  //                     <Accordion.Toggle as={Button} variant="link" eventKey="3" className="btn btn-link vertically-align-center-flex"
                  //                         onClick={() => {
                  //                             if (openCustomerDetailsReport) {
                  //                                 setOpenCustomerDetailsReport(false);
                  //                             } else {
                  //                                 setOpenCustomerDetailsReport(true);
                  //                                 setOpenNoshowTicketDetailsReport(false);
                  //                                 setOpenTicketDetailsReport(false);
                  //                                 setOpenTransTicketDetailsReport(false);
                  //                                 setOpenTokenWiseReport(false);
                  //                             }
                  //                         }}
                  //                     >
                  //                         Customer Details Report
                  //
                  //                         <div className='float-right vertically-align-center-flex-2'>
                  //                             {userDetails !== null ? (
                  //                                 <>
                  //                                     {item.pdf ? (
                  //                                         <a href={`${baseUrl}/report-controller/download-pdf/${dateFrom}/${dateTo}/${selectedRegionIds.length > 0 ? selectedRegionIds.toString() : userDetails.bid}/customer-detail`}
                  //                                             target='_blank' className="btn btn-sm btn-default" title="View PDF"
                  //                                             onClick={(e) => {
                  //                                                 e.stopPropagation();
                  //                                             }}
                  //                                         ><i class="fa fa-file-pdf-o" aria-hidden="true"></i></a>
                  //                                     ) : null}
                  //
                  //                                     {item.excel ? (
                  //                                         <button className="btn btn-sm btn-default" title="View Excel"
                  //                                             onClick={(e) => {
                  //                                                 e.stopPropagation();
                  //                                                 downloadExcel('customer-details-report', '_qr_cd_table');
                  //                                             }}
                  //                                         >
                  //                                             <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                  //                                         </button>
                  //                                     ) : null}
                  //                                 </>
                  //                             ) : null}
                  //                             <span className="p-l-20"><i className="fa fa-angle-down" aria-hidden="true"></i></span>
                  //                         </div>
                  //
                  //                     </Accordion.Toggle>
                  //                 </h5>
                  //             </div>
                  //             <Accordion.Collapse eventKey="3" className="collapse">
                  //                 <CustomerDetailsReport openCustomerDetailsReport={openCustomerDetailsReport}
                  //                     dateFrom={dateFrom} dateTo={dateTo}
                  //                     selectedRegionIds={selectedRegionIds} changeRegionIds={changeRegionIds}
                  //                 />
                  //             </Accordion.Collapse>
                  //         </div>
                  //     );
                } else if (
                  item.name === "Customer Details Report" &&
                  item.active
                ) {
                  return (
                    <div key={i}>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="3"
                            className="btn btn-link vertically-align-center-flex"
                            onClick={() => {
                              if (openCustomerDetailsReport) {
                                setOpenCustomerDetailsReport(false);
                              } else {
                                setOpenCustomerDetailsReport(true);
                                setOpenNoshowTicketDetailsReport(false);
                                setOpenTicketDetailsReport(false);
                                setOpenTransTicketDetailsReport(false);
                                setOpenTokenWiseReport(false);
                              }
                            }}
                          >
                            Customer Details Report
                            <div className="float-right vertically-align-center-flex-2">
                              {userDetails !== null ? (
                                <>
                                  {/*{item.pdf ? (*/}
                                  {/*<a href={`${baseUrl}/report-controller/download-pdf/${dateFrom}/${dateTo}/${selectedRegionIds.length > 0 ? selectedRegionIds.toString() : userDetails.bid}/customer-detail`}*/}
                                  {/*target='_blank' className="btn btn-sm btn-default" title="View PDF"*/}
                                  {/*onClick={(e) => {*/}
                                  {/*e.stopPropagation();*/}
                                  {/*}}*/}
                                  {/*><i class="fa fa-file-pdf-o" aria-hidden="true"></i></a>*/}
                                  {/*) : null}*/}

                                  {item.excel ? (
                                    <button
                                      className="btn btn-sm btn-default"
                                      title="View Excel"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        downloadExcel(
                                          "customer-data-report",
                                          "_qr_cd_table"
                                        );
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-excel-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : null}
                                </>
                              ) : null}
                              <span className="p-l-20">
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </h5>
                      </div>
                      <Accordion.Collapse eventKey="3" className="collapse">
                        <CustomerDataReport
                          openCustomerDetailsReport={openCustomerDetailsReport}
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          selectedRegionIds={selectedRegionIds}
                          changeRegionIds={changeRegionIds}
                          reportUrl={reportUrl}
                        />
                      </Accordion.Collapse>
                    </div>
                  );
                } else if (
                  item.name === "Transferred Ticket Details Report" &&
                  item.active
                ) {
                  return (
                    <div key={i}>
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="4"
                            className="btn btn-link vertically-align-center-flex"
                            onClick={() => {
                              if (openTransTicketDetailsReport) {
                                setOpenTransTicketDetailsReport(false);
                              } else {
                                setOpenTransTicketDetailsReport(true);
                                setOpenCustomerDetailsReport(false);
                                setOpenNoshowTicketDetailsReport(false);
                                setOpenTicketDetailsReport(false);
                                setOpenTokenWiseReport(false);
                              }
                            }}
                          >
                            Transferred Ticket Details Report
                            <div className="float-right vertically-align-center-flex-2">
                              {userDetails !== null ? (
                                <>
                                  {item.pdf ? (
                                    <a
                                      href={`${reportUrl}/report-controller/download-pdf/${dateFrom}/${dateTo}/${
                                        selectedRegionIds.length > 0
                                          ? selectedRegionIds.toString()
                                          : userDetails.bid
                                      }/tickets-transferred`}
                                      target="_blank"
                                      className="btn btn-sm btn-default"
                                      title="View PDF"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-pdf-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  ) : null}

                                  {item.excel ? (
                                    <button
                                      className="btn btn-sm btn-default"
                                      title="View Excel"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        downloadExcel(
                                          "transferred-tickets-report",
                                          "_qr_ttd_table"
                                        );
                                      }}
                                    >
                                      <i
                                        class="fa fa-file-excel-o"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  ) : null}
                                </>
                              ) : null}
                              <span className="p-l-20">
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </h5>
                      </div>
                      <Accordion.Collapse eventKey="4" className="collapse">
                        <TransferredTicketDetailsReport
                          openTransTicketDetailsReport={
                            openTransTicketDetailsReport
                          }
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          selectedRegionIds={selectedRegionIds}
                          changeRegionIds={changeRegionIds}
                          reportUrl={reportUrl}
                        />
                      </Accordion.Collapse>
                    </div>
                  );
                }
              })}
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default TokenWiseReports;
