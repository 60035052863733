import axios from 'axios';
import React, { useState, useEffect } from 'react';
import AddKiosksModal from '../ModalImpl/AddKiosksModal';
import toast, { Toaster } from 'react-hot-toast';
import { MDBDataTable } from 'mdbreact';
import Swal from "sweetalert2";
import { Link } from 'react-router-dom';

const KiosksManagement = () => {

    const baseUrl = localStorage.getItem('API_URL');
    const token = localStorage.getItem('user_token');
    const selectedBranch = JSON.parse(localStorage.getItem('selected_branch'));

    const [showAddKioskModal, setShowAddKioskModal] = useState(false);

    const [modalType, setmodalType] = useState(); // 0-add, 1-edit, 2-view
    const [selectedRow, setSelectedRow] = useState({});
    const [allKiosksList, setAllKiosksList] = useState([]);

    const [kioskTableBody, setKioskTableBody] = useState([]);
    const [kioskForRender, setKioskForRender] = useState([]);
    const [searchText, setSearchText] = useState('');


    // table body
    const body = {
        columns: [
            {
                label: 'Kiosk Id',
                field: 'id',
                width: 10
            },
            {
                label: 'Name',
                field: 'nameNew',
                width: 200,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                },
            },
            {
                label: 'Mac Address',
                field: 'macNew',
                width: 500,
            },
            {
                label: 'Allow Netted',
                field: 'allowNettedNew',
                width: 0,
            },
            {
                label: 'Status',
                field: 'status',
                with: 0,
            },
            {
                label: 'Action',
                field: 'action',
                width: 0,
            }
        ],
        rows: kioskTableBody
    };


    // call getAllKiosks - useEffect
    useEffect(() => {
        if (token !== null) {
            if (baseUrl.length > 0 && token.length > 0 && Object.keys(selectedBranch).length > 0) {
                getAllKiosks();

            }
        }
    }, [KiosksManagement]);


    // addActionColumn - useEffect
    useEffect(() => {
        let postsArray = JSON.parse(JSON.stringify(allKiosksList));
        let newDataArray = [];
        postsArray.map((item, index) => {
            item.index = (
                <div className={`${item.restricted ? 'row-text-diseble' : ''}`}>
                    {index + 1}
                </div>
            )
            item.nameNew = (
                <div className={`${item.restricted ? 'row-text-diseble' : ''}`}>
                    {item.name}
                </div>
            )
            item.macNew = (
                <div className={`${item.restricted ? 'row-text-diseble' : ''}`}>
                    {item.mac}
                </div>
            )
            item.allowNettedNew = (
                <div className={`${item.restricted ? 'row-text-diseble' : ''}`}>
                    {item.allowNetted ? 'Yes' : 'No'}
                </div>
            )
            item.status = (
                <>
                    {item.active ? <span className="badge badge-success">Online</span> : <span className="badge badge-danger">Offline</span>}
                </>

            )
            item.action = (
                <>
                    {
                        item.restricted ? (
                            <button type="button" className={`btn btn-sm btn-default disabled`} title="Message for restart" data-toggle="tooltip"
                                data-placement="top" style={{ cursor: 'not-allowed' }}
                            ><i className="fa fa-commenting-o "></i></button>
                        ) : (
                            <button type="button" className={`btn btn-sm btn-default`} title="Message for restart" data-toggle="tooltip"
                                data-placement="top"
                                onClick={() => {
                                    checkKioskDesign(item.id);
                                }}
                            ><i className="fa fa-commenting-o "></i></button>
                        )
                    }
                    &nbsp;
                    {
                        item.restricted ? (
                            <button type="button" className={`btn btn-sm btn-default disabled`} title="View" data-toggle="tooltip"
                                data-placement="top" style={{ cursor: 'not-allowed' }}
                            ><i className="fa fa-desktop"></i></button>
                        ) : (
                            <button type="button" className={`btn btn-sm btn-default`} title="View" data-toggle="tooltip"
                                data-placement="top"
                                onClick={() => {
                                    setSelectedRow(item);
                                    setmodalType(2);
                                    setShowAddKioskModal(true);
                                }}
                            ><i className="fa fa-desktop"></i></button>
                        )
                    }
                    &nbsp;
                    {
                        item.restricted ? (
                            <button type="button" className={`btn btn-sm btn-default disabled`} title="Edit" data-toggle="tooltip"
                                data-placement="top" style={{ cursor: 'not-allowed' }}
                            ><i className="fa fa-pencil-square-o"></i></button>
                        ) : (
                            <button type="button" className={`btn btn-sm btn-default`} title="Edit" data-toggle="tooltip"
                                data-placement="top"
                                onClick={() => {
                                    setSelectedRow(item);
                                    setmodalType(1);
                                    setShowAddKioskModal(true);
                                }}
                            ><i className="fa fa-pencil-square-o"></i></button>
                        )
                    }
                    &nbsp;
                    {
                        item.restricted ? (
                            <button type="button" className="btn btn-sm btn-default disabled" title="Delete" data-toggle="tooltip"
                                data-placement="top" style={{ cursor: 'not-allowed' }}
                            ><i className="icon-trash"></i></button>
                        ) : (
                            <button type="button" className="btn btn-sm btn-default" title="Delete" data-toggle="tooltip"
                                data-placement="top"
                                onClick={() => {
                                    deleteKiosk(item.id);
                                }}
                            ><i className="icon-trash"></i></button>
                        )
                    }
                    &nbsp;

                    <button type="button" className="btn btn-sm btn-default" title={`${item.restricted ? 'Enable' : 'Disable'}`} data-toggle="tooltip"
                        data-placement="top"
                        onClick={() => {
                            enableOrDisableK(item);
                        }}
                    >
                        {item.restricted ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                    </button>
                </>
            );
            newDataArray.push(item);
        });

        setKioskTableBody(newDataArray);
        setKioskForRender(newDataArray);

    }, [allKiosksList])


    //getAllKiosks - function
    const getAllKiosks = () => {
        axios({
            method: 'GET',
            url: `${baseUrl}/kiosk/get-all/branch/${selectedBranch.id}`,
            headers: {
                Authorization: token
            }
        }).then((res) => {
            let dataList = res.data.data;
            setAllKiosksList(dataList);

        }).catch((error) => {
            console.log(error);
            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    //filter search - function
    const filter = (e) => {
        const keyword = e.target.value;

        if (keyword !== '') {
            const results = kioskForRender.filter((cat) => {
                return cat.name.toString().toLowerCase().includes(keyword.toLowerCase());
            });
            setKioskTableBody(results);
        } else {
            setKioskTableBody(kioskForRender);
        }
        setSearchText(keyword);
    };

    //enableOrDisableK - function
    const enableOrDisableK = (kiosk) => {

        Swal.fire({
            position: 'center',
            text: `${kiosk.restricted ? 'Do you want to enable this Kiosk ?' : 'Do you want to disable this Kiosk ?'} `,
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            width: '400px',
        }).then((result) => {
            if (result.value == true) {
                axios({
                    method: 'POST',
                    url: `${baseUrl}/kiosk/${kiosk.id}/activate-deactivate`,
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    toast.remove();
                    toast.success(`${kiosk.restricted ? 'Kiosk enable success' : 'Kiosk disable success'} `, {
                        position: 'bottom-center',
                    });
                    getAllKiosks();

                }).catch((error) => {
                    console.log(error);

                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                })
            }
        })
    };

    //deleteKiosk - function
    const deleteKiosk = (id) => {
        Swal.fire({
            position: 'center',
            text: 'Do you want to delete this Kiosks?',
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            width: '400px',
        }).then((result) => {
            if (result.value === true) {
                axios({
                    method: 'POST',
                    url: `${baseUrl}/kiosk/${id}/delete`,
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    toast.remove();
                    toast.success('Kiosks deleted Successfully ', {
                        position: 'bottom-center',
                    });
                    getAllKiosks();
                }).catch((error) => {

                    console.log(error);
                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                })
            }
        })
    }

    //checkKioskDesign - function
    const checkKioskDesign = (id) => {

        Swal.fire({
            position: 'center',
            title: 'By Clicking this button it will automatically revoke ongoing operations.',
            text: ' Are You Sure to Restart the application ? ',
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            width: '400px',
        }).then((result) => {
            if (result.value === true) {
                axios({
                    method: 'GET',
                    url: `${baseUrl}/kiosk/check-design/${id}`,
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    
                    let data = res.data.data;
                    if (res.data.message === 'success') {
                        toast.remove();
                        toast.success(res.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.error(res.data.message, {
                            position: 'bottom-center',
                        });
                    }

                }).catch((error) => {
                    console.log(error);
                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                })
            }
        })
    }


    return (
        <>
            <div><Toaster /></div>
            <div className="container-fluid">

                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h1>Kiosks</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    {/* <li className="breadcrumb-item"><Link to="/region-management">Region Management</Link></li> */}
                                    <li className="breadcrumb-item"><Link to={`/branch-landing?bid=${selectedBranch.id}`}>{selectedBranch.BranchName}</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Kiosks</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-md-6 col-sm-12 text-right hidden-xs">
                            <div className="btn btn-sm btn-primary" title="Add New Kiosks"
                                onClick={() => {
                                    setmodalType(0);
                                    setShowAddKioskModal(true);
                                }}>Add New
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="body">
                                <div className="row">

                                    <div className="col-lg-3 col-md-6">
                                        <div className="input-group header-counter-center">
                                            <h5 className="mb-0">
                                                {
                                                    kioskTableBody !== null && allKiosksList !== null ?
                                                        <>
                                                            {
                                                                kioskTableBody.length !== null && allKiosksList.length !== null ?
                                                                    <>
                                                                        {searchText.length > 0 ? `${kioskTableBody.length} /` : ''}
                                                                        <small className="text-muted">{allKiosksList.length}</small>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </h5>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <div className="input-group">
                                        </div>
                                    </div>

                                    <div className="col-lg-5 col-md-6 ">
                                        <div className="input-group">
                                            <input
                                                value={searchText}
                                                onChange={filter}
                                                type="text" className="form-control"
                                                placeholder="Search here..."
                                            />

                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="button"
                                                ><i className="fa fa-search" /></button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row clearfix">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="body">

                                <div className="table-responsive stop-overflow-x">

                                    <MDBDataTable
                                        noBottomColumns
                                        className="table table-hover js-basic-example dataTable table-custom spacing5 action-col-align kiosks-table"
                                        displayEntries={false}
                                        searching={false}
                                        paging
                                        pagesAmount={15}
                                        data={body}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AddKiosksModal
                showAddKioskModal={showAddKioskModal}
                setShowAddKioskModal={setShowAddKioskModal}
                getAllKiosks={getAllKiosks}

                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                modalType={modalType}

            />
        </>
    )
}
export default KiosksManagement