import React, { useEffect, useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

export const AppointmentUserActivityViewModal = ({
  showTicketDetailsModal,
  setShowTicketDetailsModal,
  data,
}) => {
  const closeModal = () => {
    setShowTicketDetailsModal(false);
  };

  const baseUrl = localStorage.getItem("API_URL");
  const token = localStorage.getItem("user_token");

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    setUserData("");
    if (showTicketDetailsModal) {
      setUserData(data);
    }
  }, [data, showTicketDetailsModal]);

  return (
    <>
      {showTicketDetailsModal ? (
        <div className={`modal fade  d-block show modal-container`}>
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Ticket No. {data?.id} </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>

              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group input-group-sm mb-3">
                      <label htmlFor="form_name">Branch</label>
                      <input
                        type="text"
                        className="form-control"
                        value={userData?.branchName || "-"}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group input-group-sm mb-3">
                      <label htmlFor="form_name">Category</label>
                      <input
                        type="text"
                        className="form-control"
                        value={userData?.categoryName || "-"}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group input-group-sm mb-3">
                      <label htmlFor="form_name">Issued Date</label>
                      <input
                        type="text"
                        className="form-control"
                        value={userData?.issuedDate || "-"}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group input-group-sm mb-3">
                      <label htmlFor="form_name">Issued Time</label>
                      <input
                        type="text"
                        className="form-control"
                        value={userData?.issuedTime || "-"}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group input-group-sm mb-3">
                      <label htmlFor="form_name">Token No.</label>
                      <input
                        type="text"
                        className="form-control"
                        value={userData?.token || "-"}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group input-group-sm mb-3">
                      <label htmlFor="form_name">Reference No.</label>
                      <input
                        type="text"
                        className="form-control"
                        value={userData?.referenceNo || "-"}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group input-group-sm mb-3">
                      <label htmlFor="form_name">Start Time</label>
                      <input
                        type="text"
                        className="form-control"
                        value={userData?.startTime || "-"}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group input-group-sm mb-3">
                      <label htmlFor="form_name">End Time</label>
                      <input
                        type="text"
                        className="form-control"
                        value={userData?.endTime || "-"}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group input-group-sm mb-3">
                      <label htmlFor="form_name">Counter</label>
                      <input
                        type="text"
                        className="form-control"
                        value={userData?.counter || "-"}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group input-group-sm mb-3">
                      <label htmlFor="form_name">Teller</label>
                      <input
                        type="text"
                        className="form-control"
                        value={userData?.teller || "-"}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group input-group-sm mb-3">
                      <label htmlFor="form_name">Customer Reference</label>
                      <input
                        type="text"
                        className="form-control"
                        value={userData?.customerReference || "-"}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group input-group-sm mb-3">
                      <label htmlFor="form_name">Preferred Contact</label>
                      <input
                        type="text"
                        className="form-control"
                        value={userData?.preferredContact || "-"}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
