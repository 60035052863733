import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

const MainUserProfile = ({mainUserProfileModal, setMainUserProfileModal}) => {

    const URL = 'http://localhost:8080/tq/application/images/'
    const [genderType, setGenderType] = useState('');
    const [groupType, setGroupType] = useState('');

    const history = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);


    const closeModal = () => {
        setMainUserProfileModal(false)
        history(`/main-user`)
    };

    const options = [
        {
            label: "Select a gender",
            value: "Select a gender",
        },
        {
            label: "Male",
            value: "Male",
        },
        {
            label: "Female",
            value: "Female",
        },

    ];

    const groupOptions = [
        {
            label: "0",
            value: "Select a User Type",
        },
        {
            id: "1",
            value: "Teller",
        },
        {
            id: "2",
            value: "Admin",
        },
        {
            id: "3",
            value: "super_admin",
        },
        {
            id: "4",
            value: "user",
        },
        {
            id: "5",
            value: "super_user",
        },

    ]

    const headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJTVVBFUl9BRE1JTiIsIm5hbWUiOiJUU1RQTCIsImN1cyI6IjEiLCJpYXQiOjE2MzgwODI0ODAsImF1dGhvciI6InZpaGFuZ2F3aWNrcyIsImV4cCI6MjIwODk2OTAwMH0.yFwz8PbH59ReoutS_OcmJdBD3YzEf0Ar2uPtLeDJUUI"
    }

    useEffect(() => {

        let userId = params.get('uid');
        let userName = params.get('name');
        let img = params.get('img');
        if (userId !== null) {
            alert("----" + userId + "---" + userName + "----" + img)
            history.push(`/main-user`)
        }


        // axios.get(`http://localhost:8383/api/v1/customer/get-customer/${userId}`,{
        //     headers: headers
        // }).then((response) => {
        //     console.log(response.data.data)
        //     // console.log("id: ",response.data.data.id);
        //     // console.log("Name: ",response.data.data.name);
        //     // setId(response.data.data.id)
        //     // setName(response.data.data.name)
        //     // console.log("Expected id: ", id);
        //     // console.log("Expected name: ", name);
        // })
    }, [])
    return (
        <>
            {mainUserProfileModal ?
                <>
                    <div className={`modal fade  d-block show modal-container`} id="exampleModal">
                        <div className="modal-dialog " role="document">
                            <form className='user-form' encType="multipart/form-data">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Update User</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                                onClick={() => {
                                                    closeModal()
                                                }}
                                        >
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        {/*<form >*/}
                                        {/*<div className="body ">*/}
                                        <div>
                                            <div className="circle text-center">
                                                <div className="personal-image">
                                                    <label className="label">
                                                        <input type="file" id="fileInput" name="image"
                                                               accept="image/*"/>
                                                        <div className="personal-figure">

                                                            <img
                                                                src={(URL + params.get('name') + '/user/' + params.get('img'))}
                                                                className="personal-avatar-user" alt="avatar"/>
                                                            {/*<img*/}
                                                            {/*src="https://www.pngmart.com/files/22/User-Avatar-Profile-Download-PNG-Isolated-Image.png"*/}
                                                            {/*className="personal-avatar" alt="avatar"/>*/}
                                                            <div className="personal-figcaption">
                                                            <img src={require('../../assets/images/others/camera-white.png')} 
                                                                    alt="avatar"/>
                                                            </div>


                                                        </div>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="row clearfix" style={{height: '350px', overflow: 'auto'}}>

                                            <div className="container">
                                                <div className="controls">
                                                    <div className="row">
                                                        <div className="col-md-6">

                                                            <div
                                                                className="form-group input-group-sm mb-3 modal-input-group">
                                                                <label htmlFor="form_name">First Name
                                                                    *</label>
                                                                <input type="text" className="form-control"
                                                                       aria-label="Small"
                                                                       aria-describedby="inputGroup-sizing-sm"

                                                                />

                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div
                                                                className="form-group input-group-sm mb-3 modal-input-group">
                                                                <label htmlFor="form_lastname">Last Name
                                                                    *</label>
                                                                <input id="form_lastname"
                                                                       type="text" name="surname" aria-label="Small"
                                                                       aria-describedby="inputGroup-sizing-sm"
                                                                       className="form-control"
                                                                       required="required"
                                                                       data-error="Last name is required."

                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div
                                                                className="form-group input-group-sm mb-3 modal-input-group">
                                                                <label htmlFor="form_name">Employee ID
                                                                    *</label>
                                                                <input id="form_name" type="text"
                                                                       name="name"
                                                                       className="form-control" aria-label="Small"
                                                                       aria-describedby="inputGroup-sizing-sm"
                                                                       required="required"
                                                                       data-error="Employee ID is required."

                                                                />

                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div
                                                                className="form-group input-group-sm mb-3 modal-input-group">
                                                                <label htmlFor="form_lastname">Email
                                                                    *</label>
                                                                <input id="form_lastname"
                                                                       type="text" name="surname" aria-label="Small"
                                                                       aria-describedby="inputGroup-sizing-sm"
                                                                       className="form-control"
                                                                       required="required"
                                                                       data-error="Email is required."

                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div
                                                                className="form-group input-group-sm mb-3 modal-input-group">
                                                                <label htmlFor="form_name">Contact Number
                                                                    *</label>
                                                                <input id="form_name" type="text" aria-label="Small"
                                                                       aria-describedby="inputGroup-sizing-sm"
                                                                       name="name"
                                                                       className="form-control"
                                                                       required="required"
                                                                       data-error="Contact Number is required."


                                                                />

                                                            </div>
                                                        </div>

                                                    </div>


                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div
                                                                className="form-group input-group-sm mb-3 modal-input-group">
                                                                <label htmlFor="form_need">Gender</label>
                                                                <select
                                                                    className="custom-select modal-select-field form-control"
                                                                    id="inputGroupSelect01"
                                                                    data-error="Please specify your need."
                                                                    value={genderType}
                                                                    onChange={(e) => setGenderType(e.target.value)}>
                                                                    {options.map((option) => (
                                                                        <option key={option.value}>
                                                                            {option.value}
                                                                        </option>
                                                                    ))}

                                                                    {/*<option defaultValue>Choose...</option>*/}
                                                                    {/*<option defaultValue="1">Male</option>*/}
                                                                    {/*<option defaultValue="2">Female</option>*/}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div
                                                                className="form-group input-group-sm mb-3 modal-input-group">
                                                                <label htmlFor="form_need">Group</label>
                                                                <select
                                                                    className="custom-select modal-select-field form-control"
                                                                    id="inputGroupSelect01"
                                                                    data-error="Please specify your need."
                                                                    onChange={(e) => setGroupType(e.target.value)}
                                                                    value={groupType}>
                                                                    {groupOptions.map((m, ix) => {
                                                                        return <option
                                                                            key={m.id}
                                                                            value={m.id}
                                                                        >
                                                                            {m.value}
                                                                        </option>
                                                                    })};

                                                                    {/*<option defaultValue>Choose...</option>*/}
                                                                    {/*<option defaultValue="1">Admin</option>*/}
                                                                    {/*<option defaultValue="2">Teller</option>*/}
                                                                    {/*<option defaultValue="3">User</option>*/}
                                                                </select>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div
                                                                className="form-group input-group-sm mb-3 modal-input-group">
                                                                <label htmlFor="form_name">Password
                                                                    *</label>
                                                                <input type="text" aria-label="Small"
                                                                       aria-describedby="inputGroup-sizing-sm"
                                                                       name="pw"
                                                                       className="form-control"
                                                                       required="required"
                                                                       data-error="Password is required."


                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div
                                                                className="form-group input-group-sm mb-3 modal-input-group">
                                                                <label htmlFor="form_lastname">Confirm Password
                                                                    *</label>
                                                                <input type="text" name="cP" aria-label="Small"
                                                                       aria-describedby="inputGroup-sizing-sm"
                                                                       className="form-control"
                                                                       required="required"
                                                                       data-error="Password is required."


                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        {/*</div>*/}
                                        {/*</form>*/}


                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-round btn-default" data-dismiss="modal"
                                                onClick={() => {
                                                    closeModal()
                                                }}
                                        >Cancel
                                        </button>
                                        <button type="submit" className="btn btn-round btn-primary"
                                            // onClick={(e) => addUser(e)}
                                        >Add User
                                        </button>
                                    </div>
                                </div>
                            </form>


                        </div>
                    </div>


                </>
                : ''}
        </>
    )

}
export default MainUserProfile