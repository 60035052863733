import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
// import { OverlayTrigger } from 'react-bootstrap';
// import {renderTooltip} from 'react-bootstrap';
import { Link } from 'react-router-dom';

const CategoryAssignPage = () => {

    const location = useLocation();
    const history = useNavigate();

    const baseUrl = localStorage.getItem('API_URL')
    const token = localStorage.getItem('user_token')
    const userDetails = JSON.parse(localStorage.getItem('user_details'));
    const selectedBranchId = location.state.selectedBranchId;

    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({});

    const [counterList, setCounterList] = useState([]);

    const [refresh, setRefresh] = useState(0);




    //call getAllCounters - useeffect
    useEffect(() => {
        if (baseUrl.length > 0 && token.length > 0 && Object.keys(userDetails).length > 0) {
            getCategoriesByBranch();
        }
    }, [CategoryAssignPage])


    //getCategoriesByBranch - function
    const getCategoriesByBranch = () => {

        axios({
            method: 'GET',
            url: `${baseUrl}/real-time/category-load-optimizing/branch/${selectedBranchId}`,
            headers: {
                Authorization: token
            },
        }).then((res) => {
            let data = res.data.data;
            setCategoryList(data);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }


    //getCountersByCategory
    const getCountersByCategory = (selectedCategory) => {

        axios({
            method: 'GET',
            url: `${baseUrl}/category/assigned-counters/branch/${selectedBranchId}/category/${selectedCategory.id}`,
            headers: {
                Authorization: token
            },

        }).then((res) => {
            let data = res.data.data
            setCounterList(data);

        }).catch((error) => {
            console.log(error);

            if (error.response !== undefined) {
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }



    // assignCounterToCategory - function
    const assignCounterToCategory = (counter) => {

        Swal.fire({
            position: 'center',
            text: `${counter.selected ? 'Do you want to unassigned this Counter ? ' : 'Do you want to assigned this Counter ? '}`,
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            width: '400px',

        }).then((result) => {
            if (result.value === true) {
                axios({
                    method: 'POST',
                    url: `${baseUrl}/category/assign-counters`,
                    headers: {
                        Authorization: token
                    },
                    data: {
                        categoryId: selectedCategory.id,
                        counterId: counter.counterId
                    }
                }).then((res) => {

                    toast.remove();
                    toast.success(`${counter.selected ? 'Counter successfully unassigned' : 'Counter successfully assigned'} `, {
                        position: 'bottom-center',
                    });

                    for (let i = 0; i < counterList.length; i++) {
                        const counterObject = counterList[i];

                        if (counterObject.counterId === counter.counterId) {

                            if (counterObject.selected === true) {
                                counterObject.selected = false;
                                setRefresh(refresh + 1)
                            } else {
                                counterObject.selected = true;
                                setRefresh(refresh + 1)
                            }
                            setRefresh(refresh + 1)
                        }
                    }

                }).catch((error) => {
                    console.log(error);

                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });

                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                })
            }
        })
    }

    return (
        <>
            <div className="container-fluid">

                <div className="block-header">
                    <div className="row clearfix">

                        <div className="col-md-6 col-sm-12">
                            <h1>Assign Category Page</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">


                                    <li className="breadcrumb-item"><Link to='/branch-summary'>Branch Summary</Link></li>
                                    <li className="breadcrumb-item link" aria-current="page"
                                        onClick={() => {
                                            history('/branch-dashboard', {
                                                state: {
                                                    selectedBranchId: selectedBranchId,
                                                }
                                            }
                                            )
                                        }}>Branch Dashboard</li>
                                    <li className="breadcrumb-item active" aria-current="page">Category Assign page</li>

                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>


                <div className="row clearfix">
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className='assign-cat-left click-card-list'>
                            {categoryList.length > 0 ?
                                <>
                                    {
                                        categoryList.map((item, i) =>
                                            <div className={`card  ${item.id === selectedCategory.id ? 'active' : ''} `}
                                                onClick={() => {
                                                    setSelectedCategory(item);
                                                    getCountersByCategory(item);
                                                }}
                                            >
                                                <div className='body'>
                                                    <p>{item.categoryName}</p>
                                                    <div>
                                                        <div className="progress progress-xs" >
                                                            <div className="progress-bar bg-azura" style={{ width: `${item.percentage}%` }}>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </>
                                :
                                <>
                                    <span>No Category avaialble</span>
                                </>
                            }
                        </div>
                    </div>


                    <div className='col-lg-8 col-md-8 col-sm-12'>
                        <div className='assign-cat-right'>
                            <div className="row clearfix">
                                {
                                    counterList.map((item, i) =>
                                        <div className='col-lg-3 col-md-3 col-sm-4 from-counter' key={i}>
                                            <div className='card'>
                                                <div className={`card-counter ${item.selected ? 'active' : ''}`}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        assignCounterToCategory(item);
                                                    }}
                                                >
                                                    <div className="body text-center">
                                                        <h6 className='f-w-400'>Counter</h6>
                                                        <h3 className='f-w-400'>{item.counterNo}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>


                </div>


            </div>


        </>
    );

}
export default CategoryAssignPage


