import React, { useEffect, useState } from 'react'
import axios from 'axios';
import toast, { Toaster } from "react-hot-toast";
import Button from 'react-bootstrap-button-loader';
import Swal from "sweetalert2";
import cameraImage from '../../assets/images/camera-white.png';
import emptyImage from '../../assets/images/empty-img.jpeg'
import { height } from '@mui/system';

const AddBranchCategoryTicketDesign = (props) => {

    const [languages, setLanguages] = useState([]);
    const [ticketDesign, setTicketDesign] = useState(null);

    const [selectedHeaderImage, setSelectedHeaderImage] = useState({});
    const [headerImage, setHeaderImage] = useState({});
    const [headerImageId, setHeaderImageId] = useState(0);
    const [selectedFooterImage, setSelectedFooterImage] = useState({});
    const [footerImage, setFooterImage] = useState({});
    const [footerImageId, setFooterImageId] = useState(0)
    const [selectedLang, setSelectedLang] = useState(0);
    const [ticketCount, setTicketCount] = useState(0);
    const [prefixText, setPrefixText] = useState('');
    const [footerText, setFooterText] = useState('');
    const [printName, setPrintName] = useState('');
    const [qrCode, setQrCode] = useState(false);
    const [barCode, setBarCode] = useState(false)
    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null)
    const [footerHeight, setFooterHeight] = useState(null);
    const [footerWidth, setFooterWidth] = useState(null)

    const [identification, setIdentification] = useState('')
    const [identificationCount, setIdentificationCount] = useState(0);

    const [buttonLoader, setButtonLoader] = useState(false);

    useEffect(() => {
        if (props.baseUrl !== '' && props.userDetails !== undefined && props.token !== '' && props.showAddCategoryTicketDesign) {
            getLanguagesByCustomer();
            if (ticketDesign !== null) {
                ticketDesignData();
                identificationDataCheck(ticketDesign.qrCode, ticketDesign.barCode);
            } else {
                setTicketCount(0);
                setPrintName('');
                setPrefixText('');
                setFooterText('');
            }
        }

    }, [props.baseUrl, props.userDetails, props.token, props.showAddCategoryTicketDesign, ticketDesign])


    useEffect(() => {
        if (identificationCount > 0) {
            if (identification === 'qr') {
                setQrCode(true);
                setBarCode(false);
            }

            if (identification === 'bar') {
                setQrCode(false)
                setBarCode(true);
            }

            if (identification === 'none') {
                setQrCode(false);
                setBarCode(false);
            }
        }
    }, [identification, identificationCount, barCode, qrCode])


    // get languages by customer - function
    const getLanguagesByCustomer = () => {
        axios({
            method: 'GET',
            url: `${props.baseUrl}/skill/get-languages/customer/${props.userDetails.cid}`,
            headers: {
                Authorization: props.token
            }
        }).then((res) => {

            let data = res.data.data;
            setLanguages(data);


        }).catch((error) => {

            console.log('get languages error');
            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // add ticket design - function
    const addTicketDesign = () => {
        setButtonLoader(true);

        let isValidate = validate();

        if (isValidate) {

            let formData = new FormData();
            if (selectedHeaderImage.pictureAsFile !== undefined) {
                formData.append('header', selectedHeaderImage.pictureAsFile);
            }
            if (selectedFooterImage.pictureAsFile !== undefined) {
                formData.append('footer', selectedFooterImage.pictureAsFile);
            }
            formData.append('language', selectedLang);
            formData.append('ticketCount', ticketCount);
            formData.append('qrCode', qrCode);
            formData.append('barCode', barCode);
            formData.append('printName', printName);
            formData.append('prefixText', prefixText);
            formData.append('footerText', footerText);


            axios({
                method: 'POST',
                url: `${props.baseUrl}/category/add/ticket-image/category/${props.selectedRow.id}`,
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': props.token
                },
                data: formData
            }).then((res) => {

                setButtonLoader(false);
                toast.remove();
                toast.success('Ticket design successfully added!', {
                    position: 'bottom-center',
                });
                closeModal();

            }).catch((error) => {
                console.log(error);
                setButtonLoader(false);

                if (error.response !== undefined) {
                    toast.remove();
                    toast.error(error.response.data.message, {
                        position: 'bottom-center',
                    });
                } else {
                    toast.remove();
                    toast.error('Something went wrong', {
                        position: 'bottom-center',
                    });
                }

            });

        }

    }

    // update ticket design - function
    const updateTicketDesign = () => {

        setButtonLoader(true);

        if (ticketDesign !== null) {

            let isValidate = validate();
            if (isValidate) {

                let formData = new FormData();
                formData.append('ticketCount', ticketCount);
                formData.append('qrCode', qrCode);
                formData.append('barCode', barCode);
                formData.append('printName', printName);
                formData.append('prefixText', prefixText);
                formData.append('footerText', footerText);

                if (selectedHeaderImage.pictureAsFile === undefined) {
                    formData.append("header", headerImage);
                } else {
                    formData.append("header", selectedHeaderImage.pictureAsFile);
                }

                if (selectedFooterImage.pictureAsFile === undefined) {
                    formData.append('footer', footerImage);
                } else {
                    formData.append('footer', selectedFooterImage.pictureAsFile);
                }

                axios({
                    method: 'POST',
                    url: `${props.baseUrl}/category/update/category/ticket-image/${ticketDesign.id}`,
                    headers: {
                        'content-type': 'multipart/form-data',
                        'Authorization': props.token
                    },
                    data: formData
                }).then((res) => {

                    setButtonLoader(false);
                    toast.remove();
                    toast.success('Ticket design successfully updated!', {
                        position: 'bottom-center',
                    });
                    closeModal();

                }).catch((error) => {
                    console.log(error);
                    setButtonLoader(false);

                    if (error.response !== undefined) {
                        toast.remove();
                        toast.error(error.response.data.message, {
                            position: 'bottom-center',
                        });
                    } else {
                        toast.remove();
                        toast.error('Something went wrong', {
                            position: 'bottom-center',
                        });
                    }
                });

            }
        }
    }

    // get ticket design - function
    const getTicketDesign = (language) => {
        axios({
            method: 'GET',
            url: `${props.baseUrl}/category/get/ticket-image/category/${props.selectedRow.id}/language/${language}`,
            headers: {
                Authorization: props.token
            }
        }).then((res) => {

            let data = res.data.data;
            setTicketDesign(data);

            if (data !== null) {
                if (data.headerId !== undefined) {
                    setHeaderImageId(data.headerId);
                } else {
                    setHeaderImageId(0);
                }

                if (data.footerId !== undefined) {
                    setFooterImageId(data.footerId);
                } else {
                    setFooterImageId(0);
                }
            } else {
                setHeaderImageId(0);
                setFooterImageId(0);
            }

        }).catch((error) => {

            console.log(error);

            if (error.response !== undefined) {
                toast.remove();
                toast.error(error.response.data.message, {
                    position: 'bottom-center',
                });
            } else {
                toast.remove();
                toast.error('Something went wrong', {
                    position: 'bottom-center',
                });
            }
        })
    }

    // delete ticket image - function
    const deleteImage = (headerId, footerId) => {
        if (ticketDesign !== null) {

            Swal.fire({
                position: 'center',
                text: 'Do you want to delete this image..?',
                showConfirmButton: true,
                confirmButtonText: "Yes",
                showCancelButton: true,
                cancelButtonText: "No",
                width: '400px',
            }).then((result) => {
                if (result.value == true) {
                    if (headerId !== 0) {
                        axios({
                            method: 'POST',
                            url: `${props.baseUrl}/category/delete/branch/ticket-image/${ticketDesign.id}/image/${headerId}`,
                            headers: {
                                Authorization: props.token
                            }
                        }).then((res) => {

                            setHeaderImageId(0);

                            toast.remove();
                            toast.success('Image successfully deleted.', {
                                position: 'bottom-center',
                            });
                            getTicketDesign(ticketDesign.languageId);


                        }).catch((error) => {
                            console.log(error);

                            if (error.response !== undefined) {
                                toast.remove();
                                toast.error(error.response.data.message, {
                                    position: 'bottom-center',
                                });
                            } else {
                                toast.remove();
                                toast.error('Something went wrong', {
                                    position: 'bottom-center',
                                });
                            }
                        })
                    } else {
                        axios({
                            method: 'POST',
                            url: `${props.baseUrl}/category/delete/ticket-image/${ticketDesign.id}/image/${footerId}`,
                            headers: {
                                Authorization: props.token
                            }
                        }).then((res) => {

                            setFooterImageId(0);

                            toast.remove();
                            toast.success('Image successfully deleted.', {
                                position: 'bottom-center',
                            });
                            getTicketDesign(ticketDesign.languageId);


                        }).catch((error) => {
                            console.log(error);

                            if (error.response !== undefined) {
                                toast.remove();
                                toast.error(error.response.data.message, {
                                    position: 'bottom-center',
                                });
                            } else {
                                toast.remove();
                                toast.error('Something went wrong', {
                                    position: 'bottom-center',
                                });
                            }
                        })
                    }
                }
            })
        }
    }


    // validate - function
    const validate = () => {

        if (props.selectedRow.id === undefined) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Cannot find category id', {
                position: 'bottom-center',
            });
            return false;
        } else if (selectedLang === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Please select a language', {
                position: 'bottom-center',
            });
            return false;
        } else if (ticketCount === 0) {
            setButtonLoader(false);
            toast.remove();
            toast.error('The ticket count cannot be 0', {
                position: 'bottom-center',
            });
            return false;
        } else if (width > 630) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Header width must less than or equal to 630px', {
                position: 'bottom-center',
            });
        } else if ( footerWidth > 630) {
            setButtonLoader(false);
            toast.remove();
            toast.error('Footer width must less than or equal to 630px', {
                position: 'bottom-center',
            });
        } else {
            return true;
        }

    }

    const ticketDesignData = () => {
        if (ticketDesign !== {}) {
            setHeaderImage(ticketDesign.headerURL);
            setFooterImage(ticketDesign.footerURL);
            setTicketCount(ticketDesign.ticketCount);
            setSelectedLang(ticketDesign.languageId);
            setBarCode(ticketDesign.barCode);
            setQrCode(ticketDesign.qrCode);

            if (ticketDesign.printName !== undefined) {
                setPrintName(ticketDesign.printName);
            } else {
                setPrintName('');
            }
            if (ticketDesign.prefixText !== undefined) {
                setPrefixText(ticketDesign.prefixText);
            } else {
                setPrefixText('');
            }
            if (ticketDesign.footerText !== undefined) {
                setFooterText(ticketDesign.footerText);
            } else {
                setFooterText('');
            }
        }
    }

    const identificationDataCheck = (qr, barcode) => {
        if (qr) {
            setIdentification('qr');
        } else if (barcode) {
            setIdentification('bar');
        } else {
            setIdentification('none');
        }

    }

    const languageDropdownOnClick = (e) => {
        e.preventDefault();

        if (e.target.value !== 'Choose...') {
            getTicketDesign(languages[e.target.value].id);
            setSelectedLang(languages[e.target.value].id);
        }
    }

    const headerImageChangeHandle = (e) => {
        console.log("e: ", e);

        const selectedImage = e.target.files[0];
        if (selectedImage) {
            const imageReader = new FileReader();
            imageReader.onload = (e) => {
                const img = new Image();
                img.src = e.target.result;
                img.onload = () => {
                    const width = img.width;
                    const height = img.height;
                    setWidth(width);
                    setHeight(height)
                };
            };
            imageReader.readAsDataURL(selectedImage);
        }

        setSelectedHeaderImage({
            picturePreview: URL.createObjectURL(e.target.files[0]),
            pictureAsFile: e.target.files[0],
        });
    };

    const footerImageChangeHandle = (e) => {

        const selectedImage = e.target.files[0];
        if (selectedImage) {
            const imageReader = new FileReader();
            imageReader.onload = (e) => {
                const img = new Image();
                img.src = e.target.result;
                img.onload = () => {
                    const width = img.width;
                    const height = img.height;
                    setFooterWidth(width);
                    setFooterHeight(height)
                };
            };
            imageReader.readAsDataURL(selectedImage);
        }

        setSelectedFooterImage({
            picturePreview: URL.createObjectURL(e.target.files[0]),
            pictureAsFile: e.target.files[0],
        });
    };

    const closeModal = () => {
        props.setShowAddCategoryTicketDesign(false);
        setTicketDesign(null);
        setSelectedHeaderImage('');
        setSelectedFooterImage('');
        setSelectedLang(0);
        setTicketCount(0);
        setQrCode(false);
        setBarCode(false);
        setPrintName('');
        setPrefixText('');
        setFooterText('');
        if (headerImageId !== undefined) {
            setHeaderImageId(0);
        }
        if (footerImageId !== undefined) {
            setFooterImageId(0);
        }
        setButtonLoader(false);
        props.setSelectedRow({});
    };

    return (
        <>
            <div><Toaster /></div>
            {props.showAddCategoryTicketDesign ? (
                <div className={`modal fade d-block show modal-container`} id="category-modal">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                {ticketDesign === null ? (
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        Add Ticket Design</h5>
                                ) : (
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        Update Ticket Design</h5>
                                )}
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        closeModal()
                                    }}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className='modal-body'>

                                <form className='ticket-design-add-form mt-3'>

                                    <div className="container-fluid">

                                        <div className="row clearfix">
                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group mb-3">
                                                    <p className="input-field-side-text">Language : </p>
                                                    <select className="custom-select modal-select-field"
                                                        onChange={(e) => {
                                                            languageDropdownOnClick(e);
                                                        }}
                                                    >
                                                        <option defaultValue hidden>Choose...</option>
                                                        {languages !== [] && languages.length !== 0 && languages !== null ? (
                                                            <>{languages.map((item, index) =>
                                                                <option value={index} key={index}>
                                                                    {item.lang}
                                                                </option>
                                                            )}</>
                                                        ) : (<option>
                                                            Choose...
                                                        </option>)}

                                                    </select>
                                                </div>
                                            </div>

                                            <div className={`col-md-6 col-sm-12 ${selectedLang === 0 ? 'no-click' : ''}`}>
                                                <div className="input-group input-group-sm mb-3">
                                                    <p className="input-field-side-text">No. of Tickets : </p>
                                                    <input type='number' className="form-control" aria-label="Small"
                                                        value={ticketCount}
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        onChange={(e) => {
                                                            setTicketCount(e.target.value);
                                                        }}
                                                    >
                                                    </input>
                                                </div>
                                            </div>

                                        </div>

                                        <div className={`row clearfix ${selectedLang === 0 ? 'no-click' : ''} `}>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group input-group-sm mb-3 ">
                                                    <p className="input-field-side-text">Header Text : </p>

                                                    <input type="text" className="form-control disable disabled"
                                                        aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        // readOnly={modalType === 2 ? true : false}
                                                        value={printName}
                                                        onChange={(e) => {
                                                            setPrintName(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group input-group-sm mb-3 ">
                                                    <p className="input-field-side-text">Prefix Text : </p>

                                                    <input type="text" className="form-control disable disabled"
                                                        aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        // readOnly={modalType === 2 ? true : false}
                                                        value={prefixText}
                                                        onChange={(e) => {
                                                            setPrefixText(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="input-group input-group-sm mb-3 ">
                                                    <p className="input-field-side-text">Footer Text : </p>

                                                    <input type="text" className="form-control disable disabled"
                                                        aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        // readOnly={modalType === 2 ? true : false}
                                                        value={footerText}
                                                        onChange={(e) => {
                                                            setFooterText(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`row clearfix ${selectedLang === 0 ? 'no-click' : ''} `}>
                                            <div className="col-12">
                                                <div className="input-group input-group-sm mb-3 ">
                                                    <p className="input-field-side-text">Identification : </p>

                                                    {ticketDesign === null ? (
                                                        <div className="" style={{ padding: '7px 0 0' }} onChange={(e) => { setIdentification(e.target.value); setIdentificationCount(identificationCount + 1); }}>
                                                            <label className="fancy-radio">
                                                                <input type="radio" name="indenty" value="none" defaultChecked />
                                                                <span><i></i>None</span>
                                                            </label>
                                                            <label className="fancy-radio">
                                                                <input type="radio" name="indenty" value="qr" />
                                                                <span><i></i>QRcode</span>
                                                            </label>
                                                            <label className="fancy-radio">
                                                                <input type="radio" name="indenty" value="bar" />
                                                                <span><i></i>Barcode</span>
                                                            </label>
                                                        </div>
                                                    ) : (
                                                        <div className="" style={{ padding: '7px 0 0' }} onChange={(e) => { setIdentification(e.target.value); setIdentificationCount(identificationCount + 1); }}>
                                                            <label className="fancy-radio">
                                                                <input type="radio" name="indenty" value="none" checked={identification === 'none'} />
                                                                <span><i></i>None</span>
                                                            </label>
                                                            <label className="fancy-radio">
                                                                <input type="radio" name="indenty" value="qr" checked={identification === 'qr'} />
                                                                <span><i></i>QRcode</span>
                                                            </label>
                                                            <label className="fancy-radio">
                                                                <input type="radio" name="indenty" value="bar" checked={identification === 'bar'} />
                                                                <span><i></i>Barcode</span>
                                                            </label>
                                                        </div>
                                                    )}

                                                </div>


                                            </div>

                                        </div>

                                        <div className={`row clearfix ${selectedLang === 0 ? 'no-click' : ''} `}>
                                            <div className="col-6">
                                                <div className='ticket-img-label'>
                                                    <label>
                                                        Header image <small>(recommended width 630px)</small>
                                                    </label>
                                                    {headerImageId !== 0 && headerImageId !== undefined ?
                                                        <i className="fa fa-times-circle text-danger scale-1"
                                                            title='Delete Image' type='button'
                                                            onClick={() => {
                                                                deleteImage(headerImageId, 0);
                                                            }}
                                                        >
                                                        </i> : ''}
                                                </div>
                                                <div className="circle">
                                                    <div className="personal-image">
                                                        <label>
                                                            <input type="file" id="fileInput" name="image"
                                                                onChange={headerImageChangeHandle}
                                                                accept="image/*" />
                                                            <div className="ticket-design-figure">
                                                                {selectedHeaderImage.picturePreview ? (
                                                                    <>
                                                                        {selectedHeaderImage.picturePreview ?
                                                                            <>
                                                                                <img
                                                                                    // src={URL.createObjectURL(selectedImage)}
                                                                                    src={selectedHeaderImage.picturePreview}
                                                                                    className="headerImage-avatar" alt="avatar" />

                                                                                <div className="headerImage-figcaption">
                                                                                    <img
                                                                                        src={cameraImage}
                                                                                        alt="avatar" />
                                                                                </div>
                                                                            </> : <>
                                                                                <img
                                                                                    src={emptyImage}
                                                                                    className="headerImage-avatar" alt="avatar" />
                                                                                <div className="headerImage-figcaption">
                                                                                    <img
                                                                                        src={cameraImage}
                                                                                        alt="avatar" />
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {ticketDesign !== null ?
                                                                            <>
                                                                                {
                                                                                    headerImage !== undefined ? (
                                                                                        <>
                                                                                            <img
                                                                                                // src={URL.createObjectURL(selectedImage)}
                                                                                                // src={headerImage}
                                                                                                src={`${headerImage}?${Date.now()}`}
                                                                                                className="headerImage-avatar" alt="avatar" />
                                                                                            <div className="headerImage-figcaption">
                                                                                                <img
                                                                                                    src={cameraImage}
                                                                                                    alt="avatar" />
                                                                                            </div>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <img
                                                                                                // src={URL.createObjectURL(selectedImage)}
                                                                                                // src={headerImage}
                                                                                                src={emptyImage}
                                                                                                className="headerImage-avatar empty-ticket-design-img" alt="avatar" />
                                                                                            <div className="headerImage-figcaption">
                                                                                                <img
                                                                                                    src={cameraImage}
                                                                                                    alt="avatar" />
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </> : <>
                                                                                <img
                                                                                    src={emptyImage}
                                                                                    className="headerImage-avatar empty-ticket-design-img" alt="avatar" />
                                                                                <div className="headerImage-figcaption">
                                                                                    <img
                                                                                        src={cameraImage}
                                                                                        alt="avatar" />
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </>
                                                                )}
                                                            </div>
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="col-6">
                                                <div className='ticket-img-label'>
                                                    <label>
                                                        Footer image <small>(recommended width 630px)</small>
                                                    </label>
                                                    {footerImageId !== 0 && footerImageId !== undefined ?
                                                        <i className="fa fa-times-circle text-danger" title='Delete Image' type='button'
                                                            onClick={() => {
                                                                deleteImage(0, footerImageId);
                                                            }}
                                                        >
                                                        </i> : ''}
                                                </div>
                                                <div className="circle">
                                                    <div className="personal-image">
                                                        <label>
                                                            <input type="file" id="fileInput" name="image"
                                                                onChange={footerImageChangeHandle}
                                                                accept="image/*" />
                                                            <div className="ticket-design-figure">

                                                                {selectedFooterImage.picturePreview ? (
                                                                    <>
                                                                        {selectedFooterImage.picturePreview ?
                                                                            <>
                                                                                <img
                                                                                    // src={URL.createObjectURL(selectedImage)}
                                                                                    src={selectedFooterImage.picturePreview}
                                                                                    className="footerImage-avatar ticket-design-img" alt="avatar" />
                                                                                <div className="footerImage-figcaption">
                                                                                    <img
                                                                                        src={cameraImage}
                                                                                        alt="avatar" />
                                                                                </div>
                                                                            </> : <>
                                                                                <img
                                                                                    src={emptyImage}
                                                                                    className="footerImage-avatar empty-ticket-design-img" alt="avatar" />
                                                                                <div className="footerImage-figcaption">
                                                                                    <img
                                                                                        src={cameraImage}
                                                                                        alt="avatar" />
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {ticketDesign !== null ?
                                                                            <>
                                                                                {footerImage !== undefined ? (
                                                                                    <>
                                                                                        <img
                                                                                            // src={URL.createObjectURL(selectedImage)}
                                                                                            // src={footerImage}
                                                                                            src={`${footerImage}?${Date.now()}`}
                                                                                            className="footerImage-avatar" alt="avatar" />
                                                                                        <div className="footerImage-figcaption">
                                                                                            <img
                                                                                                src={cameraImage}
                                                                                                alt="avatar" />
                                                                                        </div>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <img
                                                                                            // src={URL.createObjectURL(selectedImage)}
                                                                                            // src={footerImage}
                                                                                            src={emptyImage}
                                                                                            className="footerImage-avatar empty-ticket-design-img" alt="avatar" />
                                                                                        <div className="footerImage-figcaption">
                                                                                            <img
                                                                                                src={cameraImage}
                                                                                                alt="avatar" />
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </> : <>
                                                                                <img
                                                                                    src={emptyImage}
                                                                                    className="footerImage-avatar empty-ticket-design-img" alt="avatar" />
                                                                                <div className="footerImage-figcaption">
                                                                                    <img
                                                                                        src={cameraImage}
                                                                                        alt="avatar" />
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </>
                                                                )}

                                                            </div>
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>

                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-default"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >Cancel
                                </button>

                                {ticketDesign === null ? (
                                    <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                        onClick={() => addTicketDesign()}
                                    >Save changes</Button>
                                ) : (
                                    <Button loading={buttonLoader} type="button" className="btn btn-primary"
                                        onClick={() => updateTicketDesign()}
                                    >Update</Button>
                                )}
                            </div>

                        </div>
                    </div>
                </div >
            ) : null}
        </>

    )
}

export default AddBranchCategoryTicketDesign